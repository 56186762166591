import React, { useRef } from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { DefaultSpacerForTabbedContent } from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import { AnomalyPage } from 'features/tools/AnomalyPage'
import { CampaignMonitorsTab } from 'features/tools/CampaignMonitorsTab'
import { ToolsTab } from 'features/tools/ToolsTab'

export const useToolsTabs = () => {
  return useRef<EuiTabbedContentTab[]>([
    {
      id: 'tools',
      name: 'Tools',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <ToolsTab />
        </React.Fragment>
      )
    },
    {
      id: 'anomalies',
      name: 'Anomalies',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <AnomalyPage />
        </React.Fragment>
      )
    },
    {
      id: 'campaignMonitor',
      name: 'Campaign Monitors',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <CampaignMonitorsTab />
        </React.Fragment>
      )
    }
  ])
}
