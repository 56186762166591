import moment from 'moment-timezone'
import React, { FC } from 'react'

import { EuiComment, EuiSpacer, EuiText } from '@elastic/eui'

import { Organization } from 'api'

export interface OrganizationSignupDetailsProps {
  organization: Organization
}

const OrganizationSignupDetails: FC<OrganizationSignupDetailsProps> = ({ organization }) => {
  if (!organization?.signupDetails) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiText size='m'>No signup details found.</EuiText>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {organization.signupDetails.steps.map((step, i) => {
        return (
          <React.Fragment>
            <EuiComment key={i} username={step.name} timestamp={moment.utc(step.completed).utc().tz(moment.tz.guess()).fromNow()}>
              <EuiText size='s'>Version : {step.version}</EuiText>
              <EuiText size='s'>Difference: {moment.utc(step.completed).utc().tz(moment.tz.guess()).diff(moment.utc(step.started).utc().tz(moment.tz.guess()), 'seconds')} seconds</EuiText>
              <EuiText size='s'>Started: {moment.utc(step.started).utc().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</EuiText>
              <EuiText size='s'>Completed: {moment.utc(step.completed).utc().tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</EuiText>
              <EuiText size='s'>Data : {step.data}</EuiText>
            </EuiComment>
            <EuiSpacer />
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

export default OrganizationSignupDetails
