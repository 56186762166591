import React, { useEffect } from 'react'

import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiInMemoryTable, EuiLink, EuiSwitch, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign, CampaignStatus, Organization } from 'api'
import { useGetCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { utcDate } from 'utils/dateUtils'

export interface IOrganizationCampaignsTab {
  organization: Organization
}

export const OrganizationCampaignsTab: React.FC<IOrganizationCampaignsTab> = props => {
  const campaignQuery = useGetCampaignsQuery({ organizationId: props.organization.organizationId })
  const [filteredCampaigns, setFilteredCampaigns] = React.useState<Campaign[]>([])
  const [searchValue, setSearchValue] = React.useState<string>('')
  const [showEnded, setShowEnded] = React.useState<boolean>(true)
  const [showArchived, setShowArchived] = React.useState<boolean>(false)

  useEffect(() => {
    if (campaignQuery.data) {
      const filtered = campaignQuery.data
        .filter(c => c.campaignName.toLowerCase().includes(searchValue.toLowerCase()) || c.accountName.toLowerCase().includes(searchValue.toLowerCase()))
        .filter(c => showEnded || c.status !== CampaignStatus.CampaignEnded)
        .filter(c => showArchived || c.status !== CampaignStatus.Archived)
      setFilteredCampaigns(filtered)
    }
  }, [campaignQuery.data, searchValue, showArchived, showEnded])

  const onSearchChange = (search: string) => {
    setSearchValue(search)
  }

  const onShowEndedChange = () => {
    setShowEnded(!showEnded)
  }

  const onShowArchivedChange = () => {
    setShowArchived(!showArchived)
  }

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/campaigns/${c.campaignId}`} target={'_blank'}>
          {c.campaignName}
        </EuiLink>
      ),
      sortable: (c: Campaign) => c.campaignName.toLowerCase()
    },
    {
      name: 'Account',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/accounts/${c.accountId}`} target={'_blank'}>
          {c.accountName}
        </EuiLink>
      ),
      sortable: (c: Campaign) => c.accountName.toLowerCase()
    },
    {
      name: 'Type',
      field: 'campaignType',
      sortable: true
    },
    {
      name: 'Budget',
      render: (c: Campaign) => (c.endDate ? `$${c.budget} (${c.startDate ? utcDate(c.startDate) : ''} to ${utcDate(c.endDate)})` : `$${c.budget} (${c.budgetFrequency})`),
      sortable: (c: Campaign) => c.budget
    },
    {
      name: 'Status',
      field: 'status',
      render: (status: string) => status.replace('Campaign', ''),
      sortable: true
    },
    {
      name: 'Activation Date',
      render: (c: Campaign) => (c.activated ? utcDate(c.activated) : undefined),
      sortable: (c: Campaign) => (c.activated ? utcDate(c.activated) : undefined)
    },
    {
      name: 'Plan',
      render: (c: Campaign) => (c.isMatching ? 'Matching' : c.bundleId ? c.bundleType : ''),
      sortable: (c: Campaign) => (c.isMatching ? 'Matching' : c.bundleId ? c.bundleType : '')
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'accountName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 25,
    pageSizeOptions: [10, 25, 50]
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <EuiFlexGroup wrap>
          <EuiFlexItem grow={false}>
            <EuiFieldSearch style={{ minWidth: 300 }} placeholder='Filter' value={searchValue} onChange={e => onSearchChange(e.target.value)} isClearable={true} aria-label='Filter Campaigns' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiSwitch label={'Show Ended'} onChange={onShowEndedChange} checked={showEnded} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiSwitch label={'Show Archived'} onChange={onShowArchivedChange} checked={showArchived} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiInMemoryTable loading={campaignQuery.isLoading} items={filteredCampaigns} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
      </EuiFormRow>
    </React.Fragment>
  )
}
