import React, { FC } from 'react'
import { useParams } from 'react-router'

import { EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody } from '@elastic/eui'

import { SalesManagerTabs } from 'features/salesManagerDashboard/SalesManagerTabs'

export interface ISalesManagerDashboardPage {
  tab?: string
}

export const SalesManagerDashboardPage: FC = () => {
  const { tab } = useParams<ISalesManagerDashboardPage>()

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <SalesManagerTabs currentTab={tab ?? ''} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}
