import { useEffect, useState } from 'react'

import { Audience, CampaignType, GeoLocationTarget, IDemographics, SegmentOperator } from 'api'
import { LocationTargetingType, useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'

export interface ICampaignTargeting {
  locations?: GeoLocationTarget
  locationTargetingType: LocationTargetingType
  demographics: IDemographics
  campaignType: CampaignType
}

export const useGetCampaignTargeting = (id?: string) => {
  const campaignQuery = useGetCampaignQuery(id ?? '', { skip: !id })

  const [campaignTargeting, setCampaignTargeting] = useState<ICampaignTargeting>({
    locations: {
      countries: [],
      regions: [],
      dmas: [],
      cities: [],
      postalCodes: [],
      geoCircles: [],
      geoRectangles: [],
      geoPolygons: [],
      geoFences: []
    },
    locationTargetingType: LocationTargetingType.State,
    demographics: {
      ageRanges: [],
      genders: [],
      householdIncomes: []
    },
    campaignType: CampaignType.Internet
  })

  useEffect(() => {
    if (!campaignQuery.data) {
      return
    }

    const campaign = campaignQuery.data
    if (campaign) {
      const advancedTargeting = campaign.advancedTargeting
      if (campaign.campaignType === 'Internet' && advancedTargeting) {
        setCampaignTargeting({
          locations: {
            countries: advancedTargeting.locations?.countries ?? [],
            regions: advancedTargeting.locations?.regions ?? [],
            dmas: advancedTargeting.locations?.dmas ?? [],
            cities: advancedTargeting.locations?.cities ?? [],
            postalCodes: advancedTargeting.locations?.postalCodes ?? [],
            geoFences: advancedTargeting.geoFences,
            geoCircles: advancedTargeting.geoCircles,
            geoPolygons: advancedTargeting.geoPolygons,
            geoRectangles: advancedTargeting.geoRectangles,
            uploadedAudienceUrl: advancedTargeting.locations?.uploadedAudienceUrl
          },
          locationTargetingType: (advancedTargeting.locationType ?? LocationTargetingType.USA) as LocationTargetingType,
          demographics: {
            ageRanges: advancedTargeting.ageRanges,
            genders: advancedTargeting.genders,
            householdIncomes: advancedTargeting.householdIncomes
          },
          campaignType: CampaignType.Internet
        })
        return
      }

      const billboardTargeting = campaign.billboardTargeting
      if (campaign.targetingTemplate === 'Billboard' && billboardTargeting) {
        setCampaignTargeting({
          locations: { countries: billboardTargeting.locations?.countries ?? [], regions: billboardTargeting.locations?.regions ?? [], dmas: billboardTargeting.locations?.dmas ?? [], cities: billboardTargeting.locations?.cities ?? [], postalCodes: billboardTargeting.locations?.postalCodes ?? [], geoFences: [], geoCircles: [], geoPolygons: [], geoRectangles: [] },
          locationTargetingType: (campaign.billboardTargeting?.locationType ?? LocationTargetingType.USA) as LocationTargetingType,
          demographics: {
            ageRanges: [],
            genders: [],
            householdIncomes: []
          },
          campaignType: CampaignType.Billboard
        })
        return
      }

      const tvTargeting = campaign.tvTargeting
      if (tvTargeting && (campaign.targetingTemplate === 'TV' || campaign.targetingTemplate === 'ShopifyTV')) {
        setCampaignTargeting({
          locations: { countries: tvTargeting.locations?.countries ?? [], regions: tvTargeting.locations?.regions ?? [], dmas: tvTargeting.locations?.dmas ?? [], cities: tvTargeting.locations?.cities ?? [], postalCodes: tvTargeting.locations?.postalCodes ?? [], geoFences: [], geoCircles: [], geoPolygons: [], geoRectangles: [], uploadedAudienceUrl: tvTargeting.locations?.uploadedAudienceUrl },
          locationTargetingType: (campaign.tvTargeting?.locationType ?? LocationTargetingType.USA) as LocationTargetingType,
          demographics: {
            ageRanges: campaign.tvTargeting.ageRanges,
            genders: campaign.tvTargeting.genders,
            householdIncomes: campaign.tvTargeting.householdIncomes
          },
          campaignType: CampaignType.TV
        })
      }
      if (campaign.targetingTemplate === 'Standard') {
        setCampaignTargeting({
          locations: campaign.smartTargeting?.locations,
          locationTargetingType: LocationTargetingType.USA,
          demographics: {
            ageRanges: [],
            genders: [],
            householdIncomes: []
          },
          campaignType: CampaignType.Internet
        })
      }
    }
  }, [campaignQuery.data])

  return campaignTargeting
}
