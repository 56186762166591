import moment from 'moment/moment'
import React, { useState } from 'react'

import { EuiBadge, EuiButton, EuiCopy, EuiFormRow, EuiSpacer, EuiTitle } from '@elastic/eui'

import { AgencySalesStage, SalesContactedMethodType } from 'api'
import { useOrganizationInviteToCohortMutation, useSetSalesAgencyDetailsContactedMutation, useSetSalesAgencyDetailsStageMutation } from 'api/rtkQueryApi/opsApi/agencyDetailsApi'
import { useGetOrganizationAgencyDetailsQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import config from 'app/config'
import { AgencyCohortSelect } from 'features/agencyManagerDashboard/components/AgencyCohortSelect'
import { CohortStatusSymbol } from 'features/agencyManagerDashboard/components/CohortStatusSymbol'
import { CommunicationTypeButtonGroup } from 'features/agencyManagerDashboard/components/CommunicationTypeButtonGroup'
import { SalesStageSelect } from 'features/agencyManagerDashboard/components/SalesStageSelect'
import { AgencyCohortInviteModal } from 'features/customers/organizations/AgencyCohortInviteModal'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IAgencyDetailsSection {
  organizationId: string
  onOrganizationUpdated: () => void
}

export const AgencyDetailsSection: React.FC<IAgencyDetailsSection> = props => {
  const orgAgencyDetailsQuery = useGetOrganizationAgencyDetailsQuery(props.organizationId)
  const [setAgencyContacted] = useSetSalesAgencyDetailsContactedMutation()
  const [setAgencySalesStage] = useSetSalesAgencyDetailsStageMutation()
  const [showInviteToCohort, setShowInviteToCohort] = useState<boolean>(false)

  const [inviteToCohort] = useOrganizationInviteToCohortMutation()

  const onSalesStageChange = (salesStage: AgencySalesStage, salesAgencyDetailsId: string) => {
    setAgencySalesStage({ salesAgencyDetailsId: salesAgencyDetailsId, salesStage: salesStage, description: '' })
  }

  const onCallClick = (agencyDetailsId?: string) => {
    if (!agencyDetailsId) {
      return
    }
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Call, salesAgencyDetailsId: agencyDetailsId })
  }
  const onVoicemailClick = (agencyDetailsId?: string) => {
    if (!agencyDetailsId) {
      return
    }
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Voicemail, salesAgencyDetailsId: agencyDetailsId })
  }
  const onTextClick = (agencyDetailsId?: string) => {
    if (!agencyDetailsId) {
      return
    }
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Text, salesAgencyDetailsId: agencyDetailsId })
  }

  const onEmailClick = (agencyDetailsId?: string) => {
    if (!agencyDetailsId) {
      return
    }
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Email, salesAgencyDetailsId: agencyDetailsId })
  }

  const onInviteToCohortClick = () => {
    setShowInviteToCohort(true)
  }

  const onInviteToCohortClose = () => {
    setShowInviteToCohort(false)
  }

  const onInviteToCohortAccept = (cohortId: string) => {
    setShowInviteToCohort(false)
    inviteToCohort({ organizationId: props.organizationId, cohortId: cohortId }).then(props.onOrganizationUpdated)
  }

  if (!orgAgencyDetailsQuery.data) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiButton onClick={onInviteToCohortClick} color={'success'}>
          Invite To Cohort
        </EuiButton>
        <EuiSpacer />
        {showInviteToCohort && <AgencyCohortInviteModal onClose={onInviteToCohortClose} onAccept={onInviteToCohortAccept} />}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h2>Agency Details</h2>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      {orgAgencyDetailsQuery.data?.id && (
        <React.Fragment>
          {orgAgencyDetailsQuery.data.cohortId && (
            <EuiFormRow>
              <EuiCopy textToCopy={`${config.AGENCYURL}offers`}>
                {copy => (
                  <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Offer Link'>
                    Offer URL
                  </EuiBadge>
                )}
              </EuiCopy>
            </EuiFormRow>
          )}
          <EuiFormRow label={'Recruitment Stage'}>
            <SalesStageSelect selectedSalesStage={orgAgencyDetailsQuery.data.agencyStageDetails?.salesStage ?? AgencySalesStage.T} onSalesStageChange={stage => onSalesStageChange(stage, orgAgencyDetailsQuery.data?.id ?? '')} />
          </EuiFormRow>
          <EuiFormRow label={'Cohort'}>
            <AgencyCohortSelect agencyDetails={orgAgencyDetailsQuery.data} />
          </EuiFormRow>
          {orgAgencyDetailsQuery.data.cohortId && (
            <EuiFormRow label={'Cohort Status'}>
              <CohortStatusSymbol cohortStatus={orgAgencyDetailsQuery.data.cohortStatus} />
            </EuiFormRow>
          )}
          <EuiFormRow label={`Last Contacted: ${moment(orgAgencyDetailsQuery.data.lastContacted).format('MM/DD/yyyy hh:mm A')} (${insertSpaces(orgAgencyDetailsQuery.data.lastContactedMethodType)})`}>
            <CommunicationTypeButtonGroup onVoicemailClick={() => onVoicemailClick(orgAgencyDetailsQuery.data?.id)} onCallClick={() => onCallClick(orgAgencyDetailsQuery.data?.id)} onEmailClick={() => onEmailClick(orgAgencyDetailsQuery.data?.id)} onTextClick={() => onTextClick(orgAgencyDetailsQuery.data?.id)} />
          </EuiFormRow>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
