import React from 'react'

import { EuiLoadingSpinner, EuiSpacer, EuiTitle } from '@elastic/eui'

import { AdvertisingSnapshot } from 'api/entities/AdvertisingSnapshot'

export interface IShowCaseDomainsChart {
  isLoading?: boolean
  snapshot?: AdvertisingSnapshot
}
export const ShowcaseDomainsChart: React.FC<IShowCaseDomainsChart> = props => {
  const sortedDomains = props.snapshot?.topDomains ? [...props.snapshot?.topDomains.slice(0, 9)].sort() : []
  return (
    <React.Fragment>
      <EuiTitle className='eui-textCenter' size='s'>
        <h3>Top Viewed Sites</h3>
      </EuiTitle>
      <EuiSpacer size={'l'} />
      {props.isLoading ? (
        <EuiLoadingSpinner size='l' />
      ) : (
        sortedDomains.map(domain => (
          <React.Fragment>
            <EuiTitle size={'xxs'}>
              <h4>- {domain}</h4>
            </EuiTitle>
            <EuiSpacer size={'xs'} />
          </React.Fragment>
        ))
      )}
    </React.Fragment>
  )
}
