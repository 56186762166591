import React, { FC } from 'react'

import { EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { useGetActiveExecutiveDashboardCampaignsByDayQuery, useGetEndedExecutiveDashboardCampaignsByDayQuery, useGetInactiveExecutiveDashboardCampaignsByDayQuery, useGetTotalActiveExecutiveDashboardCampaignsByDayQuery } from 'api/rtkQueryApi/opsApi/executiveDashboardApi'
import { Loading } from 'components/Loading'
import ExecutiveDashboardCampaignsByOrganizationChart, { ExecutiveCampaignsByDayFilter } from 'features/executiveDashboard/graphs/ExecutiveDashboardCampaignsByOrganizationChart'

export interface ActiveCampaignsByOrganizationTypeProps {
  dayFilter: ExecutiveCampaignsByDayFilter
  showZero: boolean
}

const CampaignsByOrganizationType: FC<ActiveCampaignsByOrganizationTypeProps> = ({ dayFilter, showZero }) => {
  const { isLoading: isLoadingTotalActive, data: totalActive } = useGetTotalActiveExecutiveDashboardCampaignsByDayQuery({ ignoreCache: false, pastXDays: 365 })
  const { isLoading: isLoadingActive, data: active } = useGetActiveExecutiveDashboardCampaignsByDayQuery({ ignoreCache: false, pastXDays: 365 })
  const { isLoading: isLoadingInactive, data: inactive } = useGetInactiveExecutiveDashboardCampaignsByDayQuery({ ignoreCache: false, pastXDays: 365 })
  const { isLoading: isLoadingEnded, data: ended } = useGetEndedExecutiveDashboardCampaignsByDayQuery({ ignoreCache: false, pastXDays: 365 })

  if (isLoadingTotalActive || isLoadingActive || isLoadingInactive || isLoadingEnded) {
    return <Loading spinnerSize='xxl' textSize='m' timeBeforeMessageMS={60000} />
  }

  return (
    <EuiPanel>
      <EuiTitle size='m'>
        <p>Campaigns by Day</p>
      </EuiTitle>

      <EuiSpacer size='xl' />

      <ExecutiveDashboardCampaignsByOrganizationChart dayFilter={dayFilter} showZero={showZero} totalActive={totalActive?.direct} active={active?.direct} inactive={inactive?.direct} ended={ended?.direct} title={'Direct'} />
      <ExecutiveDashboardCampaignsByOrganizationChart dayFilter={dayFilter} showZero={showZero} totalActive={totalActive?.agencies} active={active?.agencies} inactive={inactive?.agencies} ended={ended?.agencies} title={'Agencies'} />
      <ExecutiveDashboardCampaignsByOrganizationChart dayFilter={dayFilter} showZero={showZero} totalActive={totalActive?.partners} active={active?.partners} inactive={inactive?.partners} ended={ended?.partners} title={'Partners'} />
    </EuiPanel>
  )
}

export default CampaignsByOrganizationType
