import { useSelector } from 'react-redux'

import { EuiSelect } from '@elastic/eui'

import { BundleCategory, BundleType } from 'api/entities/Bundle'
import { RootState } from 'app/rootReducer'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IPackageCategoryPicker {
  bundleType: BundleType
  onBadgeClick: (key: BundleCategory) => void
  selectedKey: BundleCategory
  fullWidth?: boolean
  checkIsCSuite?: boolean
}

export const BundleCategoryPicker: React.FC<IPackageCategoryPicker> = props => {
  const { isCSuite } = useSelector((state: RootState) => state.app)

  const badges = Object.values(BundleCategory)
    .filter(key => props.bundleType === BundleType.SelfServicePackage || key !== BundleCategory.SelfService)
    .map(key => {
      return { value: key, text: insertSpaces(key) }
    })

  return (
    <EuiSelect
      fullWidth={props.fullWidth}
      options={badges}
      value={props.selectedKey}
      onChange={e => {
        props.onBadgeClick(e.target.value as BundleCategory)
      }}
      disabled={props.checkIsCSuite && !isCSuite}
    />
  )
}
