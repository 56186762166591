import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiTitle } from '@elastic/eui'

import { Campaign } from 'api'
import AddCampaignToBundlePage from 'components/Bundles/AddCampaignToBundlePage'

interface AddCampaignToBundleFlyout {
  campaign: Campaign
  onSubmit: () => void
  onFlyoutClose: () => void
}

const AddCampaignToBundleFlyout: React.FC<AddCampaignToBundleFlyout> = ({ campaign, onSubmit, onFlyoutClose }) => {
  const onSubmitFlyout = () => {
    onFlyoutClose()
    onSubmit()
  }

  return (
    <EuiFlyout onClose={onFlyoutClose} size='l' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle>
          <h4>Add Campaign to Existing {campaign.bundleType}</h4>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <AddCampaignToBundlePage campaign={campaign} onSubmit={onSubmitFlyout} />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty id='close' iconType='cross' onClick={onFlyoutClose} flush='left'>
              Close
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default AddCampaignToBundleFlyout
