import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { EuiSpacer, EuiComment, EuiText } from '@elastic/eui'

import { Campaign, useOpsClient, User } from 'api'
import { ChangeData } from 'api/entities/ChangeData'
import { Loading } from 'components/Loading'

interface CampaignHistoryTabParams {
  campaign: Campaign
}

const CampaignDetailsTab: React.FC<CampaignHistoryTabParams> = ({ campaign }) => {
  const [changeData, setChangeData] = useState<ChangeData[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    if (campaign) {
      let itemsProcessed = 0
      let tempArray: ChangeData[] = []

      campaign?.changes.forEach((change, index, array) => {
        let userId = change.username?.replace('auth0|', '') ?? 'adcritter'
        opsClient
          ?.getUser(userId)
          .then(user => {
            tempArray.push({ change: change, user: user })
            itemsProcessed++

            if (itemsProcessed === array.length) {
              tempArray.sort((a, b) => -(a.change.changed > b.change.changed) || +(a.change.changed < b.change.changed))
              setChangeData(tempArray)
            }
          })
          .catch(_ => {
            tempArray.push({ change: change, user: { email: userId } as User })
            itemsProcessed++

            if (itemsProcessed === array.length) {
              tempArray.sort((a, b) => -(a.change.changed > b.change.changed) || +(a.change.changed < b.change.changed))
              setChangeData(tempArray)
            }
          })
      })
    }
  }, [opsClient, campaign])

  if (changeData.length <= 0) {
    return <Loading spinnerSize='xxl' textSize='m' />
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {changeData.map((item, i) => {
        return (
          <EuiComment key={i} username={item.user.email} timestamp={item.change.changed && moment.utc(item.change.changed).utc().tz(moment.tz.guess()).format('LLL')}>
            {item.change?.changeType ? <EuiText size='xs'>Change Type: {item.change?.changeType}</EuiText> : ''}
            {item.change?.oldValue ? <EuiText size='xs'>Old Value: {item.change?.oldValue}</EuiText> : ''}
            {item.change?.newValue ? <EuiText size='xs'>New Value: {item.change?.newValue}</EuiText> : ''}
          </EuiComment>
        )
      })}
    </React.Fragment>
  )
}

export default CampaignDetailsTab
