import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiBadge, EuiButton, EuiButtonEmpty, EuiButtonIcon, EuiCopy, EuiDescriptionList, EuiDescriptionListDescription, EuiDescriptionListTitle, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiProgress, EuiSpacer, EuiText, EuiTextArea, EuiTitle, EuiToolTip, htmlIdGenerator } from '@elastic/eui'

import { AnchorPhrase, GranularPhrase, TvCommercialText, TvCommercial, useOpsClient } from 'api'

interface TvCommercialTextsTabParams {
  tvCommercialId: string
}

interface FormValues {
  texts: TvCommercialText[]
  notes: string
}

const defaultTextFormValues: TvCommercialText = {
  id: uuidv4(),
  startTime: 1,
  duration: 2,
  text: 'Text goes here'
}

const TvCommercialTextsTab: React.FC<TvCommercialTextsTabParams> = ({ tvCommercialId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tvCommercial, setTvCommercial] = useState<TvCommercial>()
  const [anchorPhrases, setAnchorPhrases] = useState<AnchorPhrase[]>()
  const [granularPhrases, setGranularPhrases] = useState<GranularPhrase[]>()
  const [isGeneratingTextSample, setIsGeneratingTextSample] = useState(false)
  const [isExportingTextSample, setIsExportingTextSample] = useState(false)
  const [textSampleUrl, setTextSampleUrl] = useState<string | null>(null)
  const opsClient = useOpsClient()
  const [initialValues, setInitialValues] = useState<FormValues>({
    notes: '',
    texts: []
  })

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getTvCommercial(tvCommercialId).then(result => {
        setTvCommercial(result)
        setIsLoading(false)
      })
      opsClient.getAnchorPhrases().then(result => {
        setAnchorPhrases(result)
      })
      opsClient.getGranularPhrases().then(result => {
        setGranularPhrases(result)
      })
    }
  }, [opsClient, tvCommercialId])

  useEffect(() => {
    if (tvCommercial) {
      setInitialValues({
        notes: tvCommercial.notes,
        texts: tvCommercial.texts
      })
      if (tvCommercial.textSampleUrl) setTextSampleUrl(tvCommercial.textSampleUrl)
    }
  }, [tvCommercial])

  const textSchema = Yup.object().shape({
    notes: Yup.string(),
    texts: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        startTime: Yup.number().min(0).max(30),
        duration: Yup.number(),
        text: Yup.string().required()
      })
    )
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: textSchema,
    onSubmit: (values: FormValues, { setSubmitting, resetForm }) => {
      opsClient!.updateTvCommercialTexts(tvCommercialId, { notes: values.notes, texts: values.texts }).then(t => {
        setSubmitting(false)
        resetForm()
        setTvCommercial(t)
      })
    }
  })

  const generateTextSample = () => {
    setTextSampleUrl(null)
    setIsGeneratingTextSample(true)
    setIsExportingTextSample(true)
    opsClient!.generateTextSampleTvCommercial(tvCommercialId).then(result => {
      setIsGeneratingTextSample(false)
      setIsExportingTextSample(true)
    })
    const checkTextSampleExist = setInterval(async function () {
      let url = await opsClient!.getTvCommercialTextSampleUrl(tvCommercialId)
      if (url) {
        setTextSampleUrl(url)
        setIsExportingTextSample(false)
        clearInterval(checkTextSampleExist)
      }
    }, 1000)
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={2}>
          <EuiSpacer size='m' />
          <FormikProvider value={formik}>
            <EuiFormRow label='Notes' fullWidth display='rowCompressed' isInvalid={formik.touched.notes && !!formik.errors.notes}>
              <EuiTextArea fullWidth name='notes' value={formik.values.notes ?? ''} onChange={formik.handleChange} isInvalid={formik.touched.notes && !!formik.errors.notes} rows={3} />
            </EuiFormRow>
            <EuiSpacer size='m' />
            <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
              <EuiText size='m'>
                <h4>Text Sections</h4>
              </EuiText>
              <EuiSpacer size='s' />
              <FieldArray
                name='texts'
                render={arrayHelpers => (
                  <React.Fragment>
                    {formik.values.texts &&
                      formik.values.texts.length > 0 &&
                      formik.values.texts.map((text, index) => (
                        <EuiFlexGroup gutterSize='xs' key={index} style={{ marginTop: 5, marginBottom: 5 }}>
                          <EuiFlexItem grow={false}>
                            <EuiFlexGroup alignItems='center'>
                              <EuiFlexItem grow={false}>
                                <EuiToolTip position='top' content='Remove this Section'>
                                  <EuiButtonIcon
                                    id='removeText'
                                    iconType='cross'
                                    aria-label='remove'
                                    color='warning'
                                    onClick={() => {
                                      arrayHelpers.remove(index)
                                    }}
                                  />
                                </EuiToolTip>
                                <input type='hidden' name={`texts[${index}].id`} value={`texts[${index}].id`} />
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexItem>
                          <EuiFlexItem grow={1}>
                            <EuiFormRow label={index === 0 ? 'Start Time (secs)' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `texts[${index}].startTime`) && !!getIn(formik.errors, `texts[${index}].startTime`)} error={getIn(formik.errors, `texts[${index}].startTime`)}>
                              <EuiFieldText fullWidth name={`texts[${index}].startTime`} value={getIn(formik.values, `texts[${index}].startTime`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `texts[${index}].startTime`) && !!getIn(formik.errors, `texts[${index}].startTime`)} />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem grow={1}>
                            <EuiFormRow label={index === 0 ? 'Duration (secs)' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `texts[${index}].duration`) && !!getIn(formik.errors, `texts[${index}].duration`)} error={getIn(formik.errors, `texts[${index}].duration`)}>
                              <EuiFieldText fullWidth name={`texts[${index}].duration`} value={getIn(formik.values, `texts[${index}].duration`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `texts[${index}].duration`) && !!getIn(formik.errors, `texts[${index}].duration`)} spellCheck={false} autoCapitalize='off' autoComplete='off' autoCorrect='off' />
                            </EuiFormRow>
                          </EuiFlexItem>
                          <EuiFlexItem grow={5}>
                            <EuiFormRow label={index === 0 ? 'Text' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `texts[${index}].text`) && !!getIn(formik.errors, `texts[${index}].text`)} error={getIn(formik.errors, `texts[${index}].text`)}>
                              <EuiFieldText fullWidth name={`texts[${index}].text`} value={getIn(formik.values, `texts[${index}].text`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `texts[${index}].text`) && !!getIn(formik.errors, `texts[${index}].text`)} />
                            </EuiFormRow>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ))}
                    <EuiSpacer size='s' />
                    <EuiButtonEmpty
                      id='addText'
                      size='s'
                      onClick={() => {
                        arrayHelpers.push(defaultTextFormValues)
                      }}
                      iconType='listAdd'>
                      Add {formik.values.texts.length === 0 ? 'New' : 'Another'} Text Section
                    </EuiButtonEmpty>
                  </React.Fragment>
                )}
              />
              <EuiSpacer />
              <EuiFlexGroup>
                {tvCommercial && tvCommercial.status === 'Creating' && (
                  <EuiFlexItem grow={false}>
                    <EuiButton id='finalize' fill type='submit' isLoading={formik.isSubmitting}>
                      Save
                    </EuiButton>
                  </EuiFlexItem>
                )}
                <EuiFlexItem grow={false}>
                  <EuiButton color='accent' onClick={generateTextSample} isLoading={isGeneratingTextSample} isDisabled={isGeneratingTextSample}>
                    Generate Text Sample
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer size='m' />
              <EuiText size='s' color='subdued'>
                <p>Any changes to the texts cannot be previewed until they are saved</p>
              </EuiText>
            </EuiForm>
          </FormikProvider>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiTitle size='m'>
            <h4>Preview</h4>
          </EuiTitle>
          <EuiSpacer size='m' />
          {textSampleUrl && <video controls src={textSampleUrl} style={{ width: 640 }} />}
          {isExportingTextSample && (
            <div style={{ width: 640, height: 360, backgroundColor: '#000' }}>
              <EuiProgress />
            </div>
          )}
          <EuiSpacer />
          {textSampleUrl && (
            <EuiCopy textToCopy={textSampleUrl}>
              {copy => (
                <EuiBadge className='eui-textTruncate' onClick={() => copy()} onClickAriaLabel='Copy URL'>
                  Click here to copy preview URL
                </EuiBadge>
              )}
            </EuiCopy>
          )}
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiDescriptionList compressed align='center'>
                <EuiDescriptionListTitle>{`<CN>`}</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>Business Category</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>{`<BT>`}</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>Business Type</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>{`<BN>`}</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>Business Name</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>{`<BC>`}</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>Business City</EuiDescriptionListDescription>
              </EuiDescriptionList>
            </EuiFlexItem>
            <EuiFlexItem>
              {anchorPhrases && (
                <EuiDescriptionList compressed align='center'>
                  {anchorPhrases.map(p => (
                    <React.Fragment key={htmlIdGenerator()()}>
                      <EuiDescriptionListTitle>{`{${p.code}}`}</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{p.name}</EuiDescriptionListDescription>
                    </React.Fragment>
                  ))}
                </EuiDescriptionList>
              )}
            </EuiFlexItem>
            <EuiFlexItem>
              {granularPhrases && (
                <EuiDescriptionList compressed align='center'>
                  {granularPhrases.map(p => (
                    <React.Fragment key={htmlIdGenerator()()}>
                      <EuiDescriptionListTitle>{`{{${p.code}}}`}</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{p.name}</EuiDescriptionListDescription>
                    </React.Fragment>
                  ))}
                </EuiDescriptionList>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default TvCommercialTextsTab
