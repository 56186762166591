import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiBadge, EuiButton, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiProgress, EuiSpacer, EuiTextArea } from '@elastic/eui'

import { TvCommercial, UpdateTvCommercialScript, useOpsClient } from 'api'

interface TvCommercialVoiceOverScriptTabParams {
  tvCommercialId: string
}

interface FormValues {
  script: string
}

const TvCommercialVoiceOverScriptTab: React.FC<TvCommercialVoiceOverScriptTabParams> = ({ tvCommercialId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tvCommercial, setTvCommercial] = useState<TvCommercial>()
  const [initial, setInitial] = useState<FormValues>({ script: '' })
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false)
  const [isExportingPreview, setIsExportingPreview] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const opsClient = useOpsClient()

  const refresh = () => {
    setIsLoading(true)
    opsClient!.getTvCommercial(tvCommercialId).then(result => {
      setInitial({
        script: result.script ?? ''
      })
      setTvCommercial(result)
      setPreviewUrl(result.previewUrl)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsClient, tvCommercialId])

  const tvCommercialSchema = Yup.object().shape({
    script: Yup.string().required('Please enter the Voice-over script')
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateTvCommercialScript = {
      script: values.script
    }

    opsClient?.updateTvCommercialScript(tvCommercialId, update).then(result => {
      setTvCommercial(result)
      setSubmitting(false)
    })
  }

  const generatePreview = () => {
    setPreviewUrl(null)
    setIsGeneratingPreview(true)
    setIsExportingPreview(true)
    opsClient!.generatePreviewTvCommercial(tvCommercialId).then(result => {
      setIsGeneratingPreview(false)
      setIsExportingPreview(true)
    })
    const checkPreviewExist = setInterval(async function () {
      let url = await opsClient!.getTvCommercialPreviewUrl(tvCommercialId)
      if (url) {
        setPreviewUrl(url)
        setIsExportingPreview(false)
        clearInterval(checkPreviewExist)
      }
    }, 1000)
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {tvCommercial && (
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <Formik initialValues={initial} enableReinitialize validationSchema={tvCommercialSchema} onSubmit={doSubmit}>
              {props => (
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Script' isInvalid={!!props.errors.script} error={props.errors.script}>
                    <EuiTextArea name='script' value={props.values.script} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.script} rows={20} fullWidth />
                  </EuiFormRow>

                  <EuiSpacer />

                  <EuiFlexGroup gutterSize='s'>
                    <EuiFlexItem grow={false}>
                      <EuiButton isLoading={props.isSubmitting} fill type='submit'>
                        Save
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='accent' onClick={generatePreview} isLoading={isGeneratingPreview} isDisabled={isGeneratingPreview}>
                        Generate Preview
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiForm>
              )}
            </Formik>
          </EuiFlexItem>
          <EuiFlexItem>
            {previewUrl && <video controls src={previewUrl} style={{ width: 640 }} />}
            {isExportingPreview && (
              <div style={{ width: 640, height: 360, backgroundColor: '#000' }}>
                <EuiProgress />
              </div>
            )}
            <EuiSpacer />
            {previewUrl && (
              <EuiCopy textToCopy={previewUrl}>
                {copy => (
                  <EuiBadge className='eui-textTruncate' onClick={() => copy()} onClickAriaLabel='Copy URL'>
                    Click here to copy preview URL
                  </EuiBadge>
                )}
              </EuiCopy>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </React.Fragment>
  )
}

export default TvCommercialVoiceOverScriptTab
