import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiSelect } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { Bundle } from 'api/entities/Bundle'
import { bundlesApi } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { useCreditBundleToSalesAgentMutation, useGetAllSalesAgentsQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface ICreditSalesAgentModalProps {
  bundle: Bundle
  onClose: () => void
  onCompleted: () => void
}

export const CreditSalesAgentModal: React.FC<ICreditSalesAgentModalProps> = props => {
  const salesAgentsQuery = useGetAllSalesAgentsQuery()
  const [salesAgentOptions, setSalesAgentOptions] = React.useState<EuiSelectOption[]>([])
  const [selectedSalesAgentId, setSelectedSalesAgentId] = React.useState<string>()
  const [assignToSalesAgent, assignToSalesAgentQuery] = useCreditBundleToSalesAgentMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (salesAgentsQuery.data && salesAgentsQuery.data.length > 0) {
      setSalesAgentOptions(salesAgentsQuery.data.map(salesAgent => ({ value: salesAgent.id, text: salesAgent.name })))
      setSelectedSalesAgentId(salesAgentsQuery.data[0].id)
    }
  }, [salesAgentsQuery.data])

  const onSalesAgentSelected = (agentId: string) => {
    setSelectedSalesAgentId(agentId)
  }

  const onConfirm = () => {
    if (selectedSalesAgentId) {
      assignToSalesAgent({ bundleId: props.bundle.id, salesAgentId: selectedSalesAgentId }).finally(() => {
        dispatch(bundlesApi.util.invalidateTags([{ type: 'Bundles' }, { type: 'BundleById', id: props.bundle.id }, { type: 'OrganizationBundles', id: props.bundle.organizationId }]))
        props.onCompleted()
      })
    }
  }

  return (
    <EuiModal onClose={props.onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Assign {insertSpaces(props.bundle.bundleType)}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow label='Account Manager'>
          <EuiSelect options={salesAgentOptions} onChange={e => onSalesAgentSelected(e.target.value)} value={selectedSalesAgentId} />
        </EuiFormRow>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup>
          <EuiFlexItem />
          <EuiFlexItem grow={false}>
            <EuiButton onClick={props.onClose} disabled={assignToSalesAgentQuery.isLoading}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton fill onClick={onConfirm} isLoading={assignToSalesAgentQuery.isLoading} isDisabled={!selectedSalesAgentId}>
              Assign
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}
