import { Moment } from 'moment'
import React from 'react'

import { EuiConfirmModal, EuiDatePicker, EuiFormRow, EuiOverlayMask } from '@elastic/eui'
import { EuiConfirmModalProps } from '@elastic/eui/src/components/modal/confirm_modal'

export interface IAcDatePickerModal extends EuiConfirmModalProps {
  message: string
  dateValue: Moment
  minDate: Moment
  maxDate: Moment
  onChangeDate: (date: Moment) => void
  datePickerLabel?: string
}

export const AcDatePickerModal: React.FC<IAcDatePickerModal> = props => {
  const onDateChanged = (date: Moment) => {
    props.onChangeDate(date)
  }

  return (
    <EuiOverlayMask>
      <EuiConfirmModal {...props}>
        <p>{props.message}</p>
        <EuiFormRow label={props.datePickerLabel ?? 'Select Date'}>
          <EuiDatePicker minDate={props.minDate} maxDate={props.maxDate} onChange={onDateChanged} selected={props.dateValue} />
        </EuiFormRow>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}
