import moment from 'moment/moment'
import React, { FC } from 'react'

import { EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem, EuiSelect, EuiSpacer, SortDirection } from '@elastic/eui'

import { OrganizationProposal, SalesStage } from 'api'
import { useGetOrganizationProposalsQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { ContactedButton } from 'features/accountManagerDashboard/leads/ContactedButton'
import LostButton from 'features/accountManagerDashboard/leads/LostButton'
import OrganizationProposalsGrid from 'features/accountManagerDashboard/leads/OrganizationProposalsGrid'
import ProspectCreateFlyOut from 'features/accountManagerDashboard/leads/ProspectCreateFlyOut'

const Offered: FC = () => {
  const { isFetching, data: proposals } = useGetOrganizationProposalsQuery({
    hasBundle: false,
    ignoredSalesStages: [SalesStage.Lost],
    sentAfter: moment().subtract(6, 'days').format('yyyy-MM-DD')
  })

  const columns: EuiBasicTableColumn<OrganizationProposal>[] = [
    {
      name: '',
      render: (proposal: OrganizationProposal) => <ContactedButton proposal={proposal} />
    },
    {
      name: '',
      render: (proposal: OrganizationProposal) => <LostButton proposal={proposal} />
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'lastContacted',
      direction: SortDirection.DESC
    }
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem />
        <EuiFlexItem grow={false}>
          <ProspectCreateFlyOut />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <OrganizationProposalsGrid isLoading={isFetching} data={proposals} appendedColumns={columns} sorting={sorting} />
    </React.Fragment>
  )
}

export default Offered
