import React, { useCallback, useEffect } from 'react'

import { EuiButton, EuiButtonEmpty, EuiFieldNumber, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask } from '@elastic/eui'

import { CommissionEvent, SalesAgent } from 'api/entities/SalesAgent'
import { useUpdateCommissionEventMutation } from 'api/rtkQueryApi/opsApi/salesApi'

export interface ICommissionEventEditModal {
  commissionEvent: CommissionEvent
  salesAgent: SalesAgent
  onClose: () => void
}

export const CommissionEventEditModal: React.FC<ICommissionEventEditModal> = props => {
  const [commissionEvent, setCommissionEvent] = React.useState<CommissionEvent>({ ...props.commissionEvent })
  const [editEvent, editEventQuery] = useUpdateCommissionEventMutation()

  useEffect(() => {
    setCommissionEvent({ ...props.commissionEvent })
  }, [props.commissionEvent])
  const onCloseModal = () => {
    props.onClose()
  }

  const onCancel = () => {
    onCloseModal()
  }

  const isValid = useCallback(() => {
    return commissionEvent.commissionPercentage >= 0 && commissionEvent.commissionPercentage <= 1 && commissionEvent.commissionAmount <= Math.abs(commissionEvent.saleAmount)
  }, [commissionEvent.commissionPercentage, commissionEvent.commissionAmount, commissionEvent.saleAmount])

  const saveCommissionEvent = () => {
    if (isValid()) {
      editEvent({ salesAgentId: props.salesAgent.id, commissionEventId: commissionEvent.id, commissionAmount: commissionEvent.commissionAmount, commissionBonus: commissionEvent.commissionBonus, commissionPercentage: commissionEvent.commissionPercentage, saleAmount: commissionEvent.saleAmount }).then(() => {
        props.onClose()
      })
    }
  }

  const onSaleAmountChange = (amount: number) => {
    setCommissionEvent({ ...commissionEvent, saleAmount: amount, commissionAmount: amount * commissionEvent.commissionPercentage })
  }

  const onCommissionPercentChange = (percent: number) => {
    setCommissionEvent({ ...commissionEvent, commissionPercentage: percent, commissionAmount: percent * commissionEvent.saleAmount })
  }

  const onCommissionAmountChange = (amount: number) => {
    setCommissionEvent({ ...commissionEvent, commissionAmount: amount, commissionPercentage: amount / commissionEvent.saleAmount })
  }

  const onCommissionBonusChange = (amount: number) => {
    setCommissionEvent({ ...commissionEvent, commissionBonus: amount })
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Edit Commission Event</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFormRow label={'Sale Amount'}>
            <EuiFieldNumber
              value={commissionEvent.saleAmount}
              onChange={e => {
                onSaleAmountChange(parseFloat(e.target.value))
              }}
            />
          </EuiFormRow>
          <EuiFormRow label={'Commission Ratio'}>
            <EuiFieldNumber
              value={commissionEvent.commissionPercentage}
              onChange={e => {
                onCommissionPercentChange(parseFloat(e.target.value))
              }}
              isInvalid={commissionEvent.commissionPercentage > 1 || commissionEvent.commissionPercentage < 0}
            />
          </EuiFormRow>
          <EuiFormRow label={'Commission Amount'}>
            <EuiFieldNumber
              value={commissionEvent.commissionAmount}
              onChange={e => {
                onCommissionAmountChange(parseFloat(e.target.value))
              }}
              isInvalid={commissionEvent.commissionAmount > Math.abs(commissionEvent.saleAmount)}
            />
          </EuiFormRow>
          <EuiFormRow label={'Bonus Commission'}>
            <EuiFieldNumber
              value={commissionEvent.commissionBonus}
              onChange={e => {
                onCommissionBonusChange(parseFloat(e.target.value))
              }}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={onCancel} disabled={editEventQuery.isLoading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={saveCommissionEvent} fill disabled={!isValid()} isLoading={editEventQuery.isLoading}>
            Save
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
