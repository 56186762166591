import { Polygon, PolygonProps } from '@react-google-maps/api'
import React, { FC, useCallback, useRef } from 'react'

export interface AcPolygonProps extends PolygonProps {
  onPolygonChange: (polygon: google.maps.Polygon) => void
}

const AcPolygon: FC<AcPolygonProps> = ({ onLoad, onPolygonChange, ...props }) => {
  const polygonRef = useRef<google.maps.Polygon | null>()
  const listenersRef = useRef<google.maps.MapsEventListener[]>([])

  const updatePolygon = useCallback(() => {
    const polygon = polygonRef.current

    if (polygon) {
      onPolygonChange(polygon)
    }
  }, [onPolygonChange])

  const onLoadOverride = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon

      const path = polygon.getPath()
      listenersRef.current.push(path.addListener('set_at', updatePolygon), path.addListener('insert_at', updatePolygon), path.addListener('remove_at', updatePolygon))

      if (onLoad) {
        onLoad(polygon)
        polygon.getPath()
      }
    },
    [onLoad]
  )

  const onUnmount = useCallback(() => {
    polygonRef.current = null
    listenersRef.current.forEach(lis => lis.remove())
  }, [])

  return <Polygon {...props} onLoad={onLoadOverride} onDragEnd={updatePolygon} onMouseUp={updatePolygon} onUnmount={onUnmount} />
}

export default AcPolygon
