import Konva from 'konva'
import React, { useEffect } from 'react'
import { Layer, Rect, Stage } from 'react-konva'

import { AdImagePlacement, BillboardComponent, UpdateBillboard, BillboardImageComponent, BillboardTextComponent, GetAdWidthForAdImageType } from 'api'
import { StageSnapGuide } from 'utils/KonvaTools/StageSnapGuide'

import { ACImage } from '../Basic/ACImage'
import ResizableBillboardImageComponent from '../Basic/ResizableBillboardImageComponent'
import ResizableBillboardTextComponent from '../Basic/ResizableBillboardTextComponent'

export interface IBillboardEditor {
  billboard: UpdateBillboard
  onUpdateBillboard: (billboard: UpdateBillboard) => void
  width?: number
  height?: number
  xScale?: number
  yScale?: number
  chosenLogo?: string
  chosenPhoto?: string
  selected?: boolean
  disableEdit?: boolean
  stageRef?: React.RefObject<Konva.Stage>
  disableGuideLines?: boolean
}

export const BillboardEditor: React.FC<IBillboardEditor> = props => {
  const headlineLayerRef = React.useRef<Konva.Layer>(null)
  const companyNameLayerRef = React.useRef<Konva.Layer>(null)
  const phoneLayerRef = React.useRef<Konva.Layer>(null)
  const locationLayerRef = React.useRef<Konva.Layer>(null)
  const websiteLayerRef = React.useRef<Konva.Layer>(null)
  const logoLayerRef = React.useRef<Konva.Layer>(null)
  const photoLayerRef = React.useRef<Konva.Layer>(null)
  const stageRef = React.useRef<Konva.Stage>(null)

  const onComponentResize = (x: number, y: number, width: number, height: number, component: BillboardComponent, onDone: (component: BillboardComponent) => void) => {
    const tempComponent = { ...component }
    tempComponent.x = x
    tempComponent.y = y
    tempComponent.width = width
    tempComponent.height = height

    onDone(tempComponent)
  }

  const baseScale = 0.5

  const xScaleAdjusted = ((props.width ?? 700) / 700) * baseScale
  const yScaleAdjusted = ((props.height ?? 200) / 200) * baseScale

  const xOffset = (() => {
    switch (props.billboard.imageLocation) {
      case AdImagePlacement.Left:
        return GetAdWidthForAdImageType(props.billboard.billboardImageType)
      default:
        return 0
    }
  })()

  useEffect(() => {
    if (props.disableGuideLines) {
      return
    }
    if ((props.stageRef?.current ?? stageRef.current) && headlineLayerRef.current && logoLayerRef.current && photoLayerRef.current && locationLayerRef.current && phoneLayerRef.current && websiteLayerRef.current && companyNameLayerRef.current) {
      const stageSnapGuide = new StageSnapGuide(props.stageRef ?? stageRef)
      stageSnapGuide.ApplyGuidesToLayer(headlineLayerRef, ['object'])
      stageSnapGuide.ApplyGuidesToLayer(locationLayerRef, ['object'])
      stageSnapGuide.ApplyGuidesToLayer(phoneLayerRef, ['object'])
      stageSnapGuide.ApplyGuidesToLayer(websiteLayerRef, ['object'])
      stageSnapGuide.ApplyGuidesToLayer(companyNameLayerRef, ['object'])
      stageSnapGuide.ApplyGuidesToLayer(logoLayerRef, ['object'])
      stageSnapGuide.ApplyGuidesToLayer(photoLayerRef, ['object'])
    }
  }, [props.stageRef, props.disableGuideLines, stageRef, headlineLayerRef, logoLayerRef, photoLayerRef, locationLayerRef, phoneLayerRef, websiteLayerRef, companyNameLayerRef])

  return (
    <Stage scaleX={props.xScale ?? xScaleAdjusted} scaleY={props.yScale ?? yScaleAdjusted} width={props.width ?? 700} height={props.height ?? 200} ref={props.stageRef}>
      <Layer name={'background'} x={xOffset}>
        <ACImage backgroundImageUrl={props.billboard.backgroundUrl!} key={'backgroundImage'} />
      </Layer>
      <Layer ref={headlineLayerRef} x={xOffset}>
        {props.billboard.headline?.selected && (
          <ResizableBillboardTextComponent
            textComponent={props.billboard.headline}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard }
              tempState.headline.isDragging = false
              tempState.headline.x = event.target.x()
              tempState.headline.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.headline, (text: BillboardTextComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.headline = text
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            onClick={() => {
              if (props.disableEdit) {
                return
              }
            }}
            name='object'
          />
        )}
      </Layer>
      <Layer ref={companyNameLayerRef} x={xOffset}>
        {props.billboard.companyName?.selected && (
          <ResizableBillboardTextComponent
            textComponent={props.billboard.companyName}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard }
              tempState.companyName.isDragging = false
              tempState.companyName.x = event.target.x()
              tempState.companyName.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.companyName, (text: BillboardTextComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.companyName = text
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            onClick={() => {
              if (props.disableEdit) {
                return
              }
            }}
            name='object'
          />
        )}
      </Layer>
      <Layer ref={phoneLayerRef} x={xOffset}>
        {props.billboard.phone?.selected && (
          <ResizableBillboardTextComponent
            textComponent={props.billboard.phone}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard }
              tempState.phone.isDragging = false
              tempState.phone.x = event.target.x()
              tempState.phone.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.phone, (text: BillboardTextComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.phone = text
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            onClick={() => {
              if (props.disableEdit) {
                return
              }
            }}
            name='object'
          />
        )}
      </Layer>
      <Layer ref={locationLayerRef} x={xOffset}>
        {props.billboard.location?.selected && (
          <ResizableBillboardTextComponent
            textComponent={props.billboard.location}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard }
              tempState.location.isDragging = false
              tempState.location.x = event.target.x()
              tempState.location.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.location, (text: BillboardTextComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.location = text
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            onClick={() => {
              if (props.disableEdit) {
                return
              }
            }}
            name='object'
          />
        )}
      </Layer>
      <Layer ref={websiteLayerRef} x={xOffset}>
        {props.billboard.website?.selected && (
          <ResizableBillboardTextComponent
            textComponent={props.billboard.website}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard }
              tempState.website.isDragging = false
              tempState.website.x = event.target.x()
              tempState.website.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.website, (text: BillboardTextComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.website = text
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            onClick={() => {
              if (props.disableEdit) {
                return
              }
            }}
            name='object'
          />
        )}
      </Layer>
      <Layer ref={logoLayerRef} x={xOffset}>
        {props.billboard.logo?.selected && (
          <ResizableBillboardImageComponent
            imageComponent={props.billboard.logo}
            backgroundImageUrl={props.chosenLogo ?? ''}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard, logo: { ...props.billboard.logo } }
              tempState.logo.isDragging = false
              tempState.logo.x = event.target.x()
              tempState.logo.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.logo, (image: BillboardImageComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.logo = image
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            name='object'
          />
        )}
      </Layer>
      <Layer ref={photoLayerRef} x={xOffset}>
        {props.billboard.photo?.selected && (
          <ResizableBillboardImageComponent
            imageComponent={props.billboard.photo}
            backgroundImageUrl={props.chosenPhoto ?? ''}
            onDragEnd={event => {
              if (props.disableEdit) {
                return
              }
              const tempState = { ...props.billboard, photo: { ...props.billboard.photo } }
              tempState.photo.isDragging = false
              tempState.photo.x = event.target.x()
              tempState.photo.y = event.target.y()
              props.onUpdateBillboard(tempState)
            }}
            draggable={!props.disableEdit}
            onChange={(x, y, w, h) =>
              onComponentResize(x, y, w, h, props.billboard.photo, (image: BillboardImageComponent) => {
                const tempInitial = { ...props.billboard }
                tempInitial.photo = image
                props.onUpdateBillboard(tempInitial)
              })
            }
            isSelected={props.selected}
            onClick={() => {
              if (props.disableEdit) {
                return
              }
            }}
            name='object'
          />
        )}
      </Layer>
      <Layer>
        <Rect width={GetAdWidthForAdImageType(props.billboard.billboardImageType)} height={400} fill={'#232e40'} x={1400 - GetAdWidthForAdImageType(props.billboard.billboardImageType)} y={0} name={'rightPadding'} />
      </Layer>
    </Stage>
  )
}
