import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiBreadcrumbs, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { GranularPhrase, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import GranularPhraseCreateFlyOut from './GranularPhraseCreateFlyOut'
import { fetchGranularPhrases } from './granularPhrasesSlice'

const GranularPhrasesPage: React.FC = () => {
  const dispatch = useDispatch()
  const opsClient = useOpsClient()
  const { isLoadingGranularPhrases, granularPhrases } = useSelector((state: RootState) => state.granularPhrases)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canCreate, setCanCreate] = useState(false)

  useEffect(() => {
    if (opsClient) {
      dispatch(fetchGranularPhrases(opsClient))
    }
  }, [dispatch, opsClient])

  useEffect(() => {
    if (userProfile) {
      setCanCreate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  const columns: Array<EuiBasicTableColumn<GranularPhrase>> = [
    {
      name: `Name`,
      render: (c: GranularPhrase) => <EuiLink onClick={() => history.push(`/data/granularPhrases/${c.id}`)}>{c.name}</EuiLink>
    },
    {
      name: 'Code',
      field: 'code'
    }
  ]

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Data' },
              {
                text: 'Granular Phrases',
                href: '/data/granularPhrases',
                onClick: () => history.push('/data/granularPhrases')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Granular Phrases</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
            <EuiPageContentHeaderSection>{canCreate && <GranularPhraseCreateFlyOut />}</EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiInMemoryTable
              loading={isLoadingGranularPhrases}
              items={granularPhrases}
              columns={columns}
              pagination={pagination}
              tableLayout='fixed'
              search={{
                box: {
                  incremental: true
                }
              }}
            />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default GranularPhrasesPage
