import React, { createContext, useContext } from 'react'

import { OpsClient } from './OpsClient'

const OpsClientContext = createContext<OpsClient | undefined>(undefined)

export const useOpsClient = () => useContext(OpsClientContext)

interface Props {
  children: any
}

export const OpsClientProvider: React.FC<Props> = ({ children }) => {
  const opsClient = new OpsClient()

  return <OpsClientContext.Provider value={opsClient}>{children}</OpsClientContext.Provider>
}
