import { AccountInfo, AuthenticationResult, SilentRequest } from '@azure/msal-browser'
import jwtDecode from 'jwt-decode'

import config from '../app/config'
import { getMsalApp } from 'utils/msal'

interface DecodedToken {
  roles: string[]
}

export enum AuthStatus {
  Unauthenticated,
  Authenticating,
  Authenticated
}

export interface UserProfile {
  id: string
  name: string
  username: string
  roles: string[]
  isAdmin: boolean
}

export default class AuthService {
  public static async signIn(): Promise<AuthenticationResult> {
    const msalApp = getMsalApp()
    const loginRequest = {
      scopes: config.OPS_SCOPES
    }

    return await msalApp.loginPopup(loginRequest)
  }

  public static async signOut(account: AccountInfo): Promise<void> {
    const msalApp = getMsalApp()

    return await msalApp.logoutRedirect({
      account,
      onRedirectNavigate: () => {
        return false
      }
    })
  }

  public static getAccounts(): AccountInfo[] {
    const msalApp = getMsalApp()
    return msalApp.getAllAccounts()
  }

  public static async requestSilentToken(account: AccountInfo): Promise<AuthenticationResult> {
    const msalApp = getMsalApp()
    const request: SilentRequest = {
      account,
      scopes: config.OPS_SCOPES
    }

    return await msalApp.acquireTokenSilent(request)
  }

  public static async requestSilentTokenGraph(account: AccountInfo): Promise<AuthenticationResult> {
    const msalApp = getMsalApp()
    const request: SilentRequest = {
      account,
      scopes: config.GRAPH_SCOPES
    }

    return await msalApp.acquireTokenSilent(request)
  }

  public static getUserProfile(authResult: AuthenticationResult): UserProfile {
    const userRoles = AuthService.getUserRoles(authResult.accessToken)
    return {
      id: authResult.account?.localAccountId || '',
      name: authResult.account?.name || '',
      username: authResult.account?.username || '',
      roles: userRoles,
      isAdmin: userRoles.includes('adcritter-ops-admin')
    }
  }

  private static getUserRoles(jwtToken: string): string[] {
    const decodedToken = jwtDecode(jwtToken) as DecodedToken

    return decodedToken.roles as string[]
  }
}
