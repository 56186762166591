import axios, { AxiosInstance } from 'axios'
import { Moment } from 'moment'

import {
  Account,
  AcImage,
  Activation,
  Ad,
  Agency,
  AnchorPhrase,
  ApiResponse,
  BillboardUploadAdRequest,
  BusinessType,
  BusinessTypeCategory,
  BusinessTypeCategoryGroup,
  BusinessTypeCategoryNode,
  BusinessTypeTargeting,
  BusinessTypeTextAssignment,
  Campaign,
  CampaignBidder,
  CampaignByDayAndDimensionReport,
  CampaignByDayAndDimensionReportRequest,
  CampaignTraffic,
  CategoryUpdateBusinessType,
  City,
  Country,
  CreateAccount,
  CreateAdTvCommercial,
  CreateAnchorPhrase,
  CreateBusinessType,
  CreateBusinessTypeCategory,
  CreateCustomer,
  CreateGranularPhrase,
  CreateTvCommercial,
  Dma,
  GranularPhrase,
  IabCategory,
  InternetAd,
  Keyword,
  LicenseImageForAccount,
  LicenseImageForBusinessType,
  Organization,
  OrganizationAddTrialSubscriptionRequest,
  PendingAuditBillboardAd,
  PendingAuditInternetAd,
  PendingAuditTvCommercial,
  PreviewTvCommercial,
  SearchImage,
  SearchResult,
  SetBusinessTypePhrases,
  SetBusinessTypeTemplates,
  SignupReport,
  SignupProspect,
  SmartTargeting,
  State,
  Subscription,
  ThirdPartyAudience,
  TvCommercial,
  UpdateAccount,
  UpdateAd,
  UpdateAdTv,
  UpdateAdvancedTargeting,
  UpdateAnchorPhrase,
  UpdateBillboard,
  UpdateBillboardDetails,
  UpdateBillboardSpecificAssignments,
  UpdateBillboardTargeting,
  UpdateBusinessType,
  UpdateBusinessTypeCategory,
  UpdateBusinessTypeTargeting,
  UpdateCampaign,
  UpdateCampaignBidder,
  UpdateGranularPhrase,
  UpdateOrganization,
  UpdateOrganizationIncludedCampaignIds,
  UpdateSalesStage,
  UpdateTvCommercial,
  UpdateTvCommercialScript,
  UpdateTvCommercialTextAssignments,
  UpdateTvCommercialTexts,
  UpdateTvCommercialVoiceOverAssignments,
  UpdateTvTargeting,
  UpdateUploadAdBillboard,
  UpdateUploadAdTv,
  UpdateUser,
  User,
  Visit,
  Zip,
  ActivationReport,
  PurchaseReport,
  SegmentTarget,
  CampaignBidderX,
  UpdateCampaignBidderX
} from 'api'
import { Lead } from 'api/entities/AccountManager'
import { Billboard, CreateBillboard } from 'api/entities/Billboards'
import { OrganizationManagerBundle, CampaignSlot } from 'api/entities/Bundle'
import { Customer } from 'api/entities/Customers'
import { CreateInvoiceRequest, Invoice, UpdateInvoiceStatusRequest } from 'api/entities/Invoice'
import { Partner } from 'api/entities/Partner'
import { Transaction } from 'api/entities/Transaction'
import config from 'app/config'
import AuthService from 'services/AuthService'

export class OpsClient {
  private apiClient: AxiosInstance

  constructor() {
    this.apiClient = axios.create({
      baseURL: `${config.api.BASEURL}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }

  async getToken() {
    const accounts = AuthService.getAccounts()

    if (accounts && accounts.length > 0) {
      try {
        const authResult = await AuthService.requestSilentToken(accounts[0])

        return authResult.accessToken
      } catch (error) {
        console.error(error)
      }
    }
  }

  async getSearchResults(search: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<SearchResult[]>>(`search?query=${search}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCallToActions() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string[]>>('dictionary/callToActions', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCountry(countryId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Country>>(`dictionary/countries/${countryId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getDmas() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Dma[]>>('dictionary/dmas', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getDma(dmaId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Dma>>(`dictionary/dmas/${dmaId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getStates() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<State[]>>('dictionary/states', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getState(stateId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<State>>(`dictionary/states/${stateId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCities(query: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<City[]>>(`dictionary/cities?query=${query}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCity(cityId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<City>>(`dictionary/cities/${cityId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getZips(query: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Zip[]>>(`dictionary/zips?query=${query}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getZip(zipId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Zip>>(`dictionary/zips/${zipId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getInternetAdsPendingAudit(targetingTemplate?: string, campaignId?: string) {
    const token = await this.getToken()
    let query = ''
    if (targetingTemplate && targetingTemplate !== '') {
      query = `?targetingTemplate=${targetingTemplate}`
    }
    if (campaignId && campaignId !== '') {
      query = `?campaignId=${campaignId}`
    }

    const { data } = await this.apiClient.get<ApiResponse<PendingAuditInternetAd[]>>(`ads/pending-audit/internet${query}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercialsPendingAudit(targetingTemplate?: string) {
    const token = await this.getToken()
    let query = ''
    if (targetingTemplate && targetingTemplate !== '') {
      query = `?targetingTemplate=${targetingTemplate}`
    }

    const { data } = await this.apiClient.get<ApiResponse<PendingAuditTvCommercial[]>>(`ads/pending-audit/tv${query}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBillboardAdsPendingAudit(targetingTemplate?: string) {
    const token = await this.getToken()
    let query = ''
    if (targetingTemplate && targetingTemplate !== '') {
      query = `?targetingTemplate=${targetingTemplate}`
    }

    const { data } = await this.apiClient.get<ApiResponse<PendingAuditBillboardAd[]>>(`ads/pending-audit/billboard${query}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getRecentActivations() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Activation[]>>('dashboard/recent-activations', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getRecentAgencyActivations() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Activation[]>>('dashboard/recent-activations-agency', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getRecentPartnerActivations() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Activation[]>>('dashboard/recent-activations-partner', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCountAdsPendingAudit() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<number>>('dashboard/count-pending', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCountAdsPendingOutro() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<number>>('dashboard/count-pending-outro', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCountAccountsNeedingCustomAds() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<number>>('dashboard/count-customads', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCountAccountsNeedingCustomTvCommercials() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<number>>('dashboard/count-customtvads', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCountAccountsNeedingCustomBillboard() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<number>>('dashboard/count-custombillboardads', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getUsers(query: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<User[]>>(`users?query=${query}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getUsersByOrganizationId(organizationId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<User[]>>(`users?organizationId=${organizationId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getUser(userId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<User>>(`users/${userId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateUser(userId: string, update: UpdateUser) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<User>>(`users/${userId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getOrganizations(query: string | null = null) {
    const token = await this.getToken()

    let url = 'organizations'
    if (query) url += `?query=${query}`

    const { data } = await this.apiClient.get<ApiResponse<Organization[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getOrganization(organizationId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Organization>>(`organizations/${organizationId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCustomer(organizationId: string, accountId?: string) {
    const token = await this.getToken()

    let url = `customers/${organizationId}`
    if (accountId) {
      url += `?accountId=${accountId}`
    }

    const { data } = await this.apiClient.get<ApiResponse<Customer>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getOrganizationSubscription(organizationId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Subscription>>(`organizations/${organizationId}/subscription`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateOrganizationTrialSubscription(organizationId: string, organizationAddTrialSubscriptionRequest: OrganizationAddTrialSubscriptionRequest) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Subscription>>(`organizations/${organizationId}/subscription/trials`, organizationAddTrialSubscriptionRequest, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateOrganization(organizationId: string, update: UpdateOrganization) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Organization>>(`organizations/${organizationId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateOrganizationIncludedCampaignIds(organizationId: string, update: UpdateOrganizationIncludedCampaignIds) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Organization>>(`organizations/${organizationId}/includedCampaignIds`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateOrganizationSalesStage(organizationId: string, update: UpdateSalesStage) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Organization>>(`organizations/${organizationId}/salesStage`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createNewProspectOrganization(request: SignupProspect) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<boolean>>(`organizations/signup/prospect`, request, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async getBusinessTypeCategoryGroups() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeCategoryGroup[]>>('businessTypeCategoryGroups', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypeCategories() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeCategory[]>>('businessTypeCategories', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypeCategory(businessTypeCategoryId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeCategory>>(`businessTypeCategories/${businessTypeCategoryId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getIabCategories() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<IabCategory[]>>(`businessTypeCategories/iabCategories`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypeByCategory(businessTypeCategoryId: string, isFiltered: boolean) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeCategory[]>>(`businessTypes?businessTypeCategoryId=${businessTypeCategoryId}&isFiltered=${isFiltered}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createBusinessTypeCategory(create: CreateBusinessTypeCategory) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<BusinessTypeCategory>>('businessTypeCategories', create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateBusinessTypeCategory(businessTypeCategoryId: string, update: UpdateBusinessTypeCategory) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessTypeCategory>>(`businessTypeCategories/${businessTypeCategoryId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypes() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessType[]>>('businessTypes', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessType(businessTypeId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getKeywordOptions() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Keyword[]>>(`businessTypes/keywords`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypeTargeting(businessTypeId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeTargeting>>(`businessTypes/${businessTypeId}/targeting`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createBusinessType(create: CreateBusinessType) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<BusinessType>>('businessTypes', create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateBusinessType(businessTypeId: string, update: UpdateBusinessType) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCategoryUpdateBusinessType(businessTypeCategoryId: string, categoryBusinessType: CategoryUpdateBusinessType) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypeCategories/${businessTypeCategoryId}/businessTypes`, categoryBusinessType, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateBusinessTypeTargeting(businessTypeId: string, update: UpdateBusinessTypeTargeting) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/targeting`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async setBusinessTypeNounPhrases(businessTypeId: string, update: SetBusinessTypePhrases) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/phrases/noun`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async setBusinessTypeWeActionPhrases(businessTypeId: string, update: SetBusinessTypePhrases) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/phrases/we-action`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async setBusinessTypeYouActionPhrases(businessTypeId: string, update: SetBusinessTypePhrases) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/phrases/you-action`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async setBusinessTypeHeadlines(businessTypeId: string, update: SetBusinessTypeTemplates) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/headlines`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async setBusinessTypeBodies(businessTypeId: string, update: SetBusinessTypeTemplates) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/bodies`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async setBusinessTypeImageUrl(businessTypeId: string, imageUrl: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/image`, { imageUrl }, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async regenerateBusinessType(businessTypeId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/generate`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async regenerateCouponBusinessType(businessTypeId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<BusinessType>>(`businessTypes/${businessTypeId}/couponGenerate`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getPartners() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Partner[]>>('partners', { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiGetOrganizationSubscriptionInvoices(organizationId: string) {
    const url = `organizations/${organizationId}/subscriptions/invoices`

    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Invoice[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiGetOrganizationAllInvoices(organizationId: string) {
    const url = `organizations/${organizationId}/invoices`

    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Invoice[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiGetOrganizationManagerBundles(organizationManagerId: string) {
    const url = `organizationsManager/bundles?organizationManagerId=${organizationManagerId}`

    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<OrganizationManagerBundle[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiCreateInvoice(invoiceRequest: CreateInvoiceRequest) {
    const url = `organizations/${invoiceRequest.organizationId}/invoices`

    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<CreateInvoiceRequest>>(url, invoiceRequest, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiUpdateInvoiceStatus(invoiceRequest: UpdateInvoiceStatusRequest) {
    const url = `organizations/invoices`

    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<CreateInvoiceRequest>>(url, invoiceRequest, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getImages(containerId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<AcImage[]>>(`images/${containerId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async searchImages(search: string, pageSize: number, currentPage: number) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<SearchImage>>(`images/search?query=${search}&pageSize=${pageSize}&currentPage=${currentPage}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async licenseImageForBusinessType(id: string, businessTypeId: string, searchId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<AcImage>>(
      `images/license/businessType`,
      {
        shutterstockId: id,
        businessTypeId: businessTypeId,
        shutterstockSearchId: searchId
      } as LicenseImageForBusinessType,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    return data.data
  }

  async licenseRecruitmentImageForBusinessType(id: string, businessTypeId: string, searchId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<AcImage>>(
      `images/license/businessType-recruitment`,
      {
        shutterstockId: id,
        businessTypeId: businessTypeId,
        shutterstockSearchId: searchId
      } as LicenseImageForBusinessType,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    return data.data
  }

  async licenseImageForAccount(id: string, accountId: string, searchId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<AcImage>>(
      `images/license/account`,
      {
        shutterstockId: id,
        accountId: accountId,
        shutterstockSearchId: searchId
      } as LicenseImageForAccount,
      { headers: { Authorization: `Bearer ${token}` } }
    )

    return data.data
  }

  async deleteBundleWithOneCampaignByCampaignId(campaignId: string) {
    const url = `campaigns/${campaignId}/bundles`
    const token = await this.getToken()

    await this.apiClient.delete<ApiResponse<boolean>>(url, { headers: { Authorization: `Bearer ${token}` } })
  }

  async deleteImageForBusinessType(businessTypeId: string, id: string) {
    const token = await this.getToken()
    await this.apiClient.delete<ApiResponse<boolean>>(`images/businessType/${businessTypeId}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  }

  async deleteImageForAccount(accountId: string, id: string) {
    const token = await this.getToken()
    await this.apiClient.delete<ApiResponse<boolean>>(`images/account/${accountId}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  }

  async searchImageSuggestions(search: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string[]>>(`images/search/suggestions?query=${search}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAccounts(organizationId: string | null = null, query: string | null = null) {
    const token = await this.getToken()

    let url = 'accounts'
    if (organizationId) url += `?organizationId=${organizationId}`
    if (query) url += `?query=${query}`
    const { data } = await this.apiClient.get<ApiResponse<Account[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAccount(accountId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Account>>(`accounts/${accountId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAccountPerformance(accountId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Performance>>(`accounts/${accountId}/performance`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAccountVisits(accountId: string, start: Moment, end: Moment) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Visit[]>>(`accounts/${accountId}/visits?start=${start.format('YYYY-MM-DD')}&end=${end.format('YYYY-MM-DD')}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createAccount(create: CreateAccount) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Account>>(`accounts`, create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createCustomer(create: CreateCustomer) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<boolean>>(`accounts/customer`, create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCustomerByAccountId(accountId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Customer>>(`accounts/${accountId}/customers`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateAccount(accountId: string, update: UpdateAccount) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Account>>(`accounts/${accountId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaigns(organizationId: string | null = null, accountId: string | null = null, query: string | null = null) {
    const token = await this.getToken()

    let url = 'campaigns'
    if (organizationId) url += `?organizationId=${organizationId}`
    if (accountId) url += `?accountId=${accountId}`
    if (query) url += `?query=${query}`
    const { data } = await this.apiClient.get<ApiResponse<Campaign[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateCampaign(campaignId: string, update: UpdateCampaign) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaignsNeedingOutros() {
    const token = await this.getToken()

    let url = 'campaigns/outros'
    const { data } = await this.apiClient.get<ApiResponse<Campaign[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaignsByBusinessTypeId(businessTypeId: string) {
    const token = await this.getToken()

    let url = `campaigns?businessTypeId=${businessTypeId}`
    const { data } = await this.apiClient.get<ApiResponse<Campaign[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaign(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Campaign>>(`campaigns/${campaignId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignActive(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/active`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCanUnpauseWithoutPayment(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<boolean>>(`campaigns/${campaignId}/canUnpauseWithoutPayment`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiGetCampaignSlot(campaignId: string) {
    const url = `campaigns/${campaignId}/campaignSlot`
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<CampaignSlot>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiAddCampaignToCampaignSlot(campaignId: string, campaignSlotId: string) {
    const url = `campaigns/${campaignId}/addCampaignToBundleSlot/${campaignSlotId}`
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Campaign>>(url, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignInactive(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/inactive`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignEnded(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/ended`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignClearUnpaid(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/clearUnpaid`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignManuallyActivate(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/manuallyActivate`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignClearRequiresCustomAds(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/clearCustomAds`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignChangeToLocationTargeting(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/change-type`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaignBidder(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<CampaignBidder>>(`campaigns/${campaignId}/bidder`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaignBidderX(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<CampaignBidderX>>(`campaigns/${campaignId}/bidderx`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidder(campaignId: string, model: UpdateCampaignBidder) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<CampaignBidder>>(`campaigns/${campaignId}/bidder`, model, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderX(campaignId: string, model: UpdateCampaignBidderX) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<CampaignBidderX>>(`campaigns/${campaignId}/bidderx`, model, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderXSyncLocations(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<CampaignBidderX>>(`campaigns/${campaignId}/bidderx/sync/locations`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderXSyncSegments(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<CampaignBidderX>>(`campaigns/${campaignId}/bidderx/sync/segments`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderBoost(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<CampaignBidder>>(`campaigns/${campaignId}/bidder/boost`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderImportLocations(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<string[]>>(`campaigns/${campaignId}/bidder/import/locations`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderImportSegments(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<string[]>>(`campaigns/${campaignId}/bidder/import/segments`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignBidderImportDeals(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<string[]>>(`campaigns/${campaignId}/bidder/import/deals`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putCampaignAdvancedTargeting(campaignId: string, advancedTargeting: UpdateAdvancedTargeting) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(`campaigns/${campaignId}/advancedTargeting`, advancedTargeting, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async getAds(campaignId: string | null = null) {
    const token = await this.getToken()
    let url = 'ads'
    if (campaignId) url += `?campaignId=${campaignId}`
    const { data } = await this.apiClient.get<ApiResponse<Ad[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAd(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Ad>>(`ads/${adId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAdUpload(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string>>(`ads/${adId}/upload`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async postInternetAd(ad: InternetAd) {
    const token = await this.getToken()

    const { data } = await this.apiClient.post<ApiResponse<InternetAd>>(`ads/internet`, ad, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async postTVAd(tvAd: CreateAdTvCommercial) {
    const token = await this.getToken()

    const { data } = await this.apiClient.post<ApiResponse<CreateAdTvCommercial>>(`ads/tv`, tvAd, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async updateAd(adId: string, update: UpdateAd) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/internet`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateAdBillboard(adId: string, update: UpdateUploadAdBillboard) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/billboard`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateAdTv(adId: string, update: UpdateAdTv) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/tv`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateUploadAdTv(adId: string, update: UpdateUploadAdTv) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/tvUpload`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generatePreviewAd(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Ad>>(`ads/${adId}/generate/preview`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generateFinalAd(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Ad>>(`ads/${adId}/generate/final`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAdPreviewUrl(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string | null>>(`ads/${adId}/previewUrl`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAdFinalUrl(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string | null>>(`ads/${adId}/finalUrl`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putAdActive(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/active`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putAdInactive(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/inactive`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async putAdDeleted(adId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Ad>>(`ads/${adId}/delete`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaignTransactions(campaignId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Transaction[]>>(`campaigns/${campaignId}/transactions`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAgenciesReport() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Agency[]>>(`reports/agencies`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getSignupReport(start: string, end: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<SignupReport[]>>(`reports/signups?start=${start}&end=${end}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getActivationReport(start: string, end: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<ActivationReport[]>>(`reports/activations?start=${start}&end=${end}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getPurchasesReport(start: string, end: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<PurchaseReport[]>>(`reports/purchases?start=${start}&end=${end}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getLeads(salesStage: string, date?: string, accountManagerId?: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Lead[]>>(`organizationsManager/leads?salesStage=${salesStage}&date=${date ?? ''}&accountManagerId=${accountManagerId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async postVistarMediaDspAnalytics(csv: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<boolean>>(`dictionary/vistarMediaDsp/csv`, { csv }, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createBillboard(create: CreateBillboard) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Billboard>>(`billboards`, create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBillboard(billboardId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Billboard>>(`billboards/${billboardId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBillboards() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Billboard[]>>(`billboards`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBillboardUpload(billboardId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string>>(`billboards/upload/${billboardId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateBillboardDetails(billboardId: string, update: UpdateBillboardDetails) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Billboard>>(`billboards/billboardDetails/${billboardId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateBillboard(billboardId: string, update: UpdateBillboard) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Billboard>>(`billboards/${billboardId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBillboardBusinessTypeAssignments(billboardId: string, businessTypeCategoryId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeTextAssignment[]>>(`billboards/${billboardId}/categories/${businessTypeCategoryId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateBillboardSpecificAssignments(billboardId: string, update: UpdateBillboardSpecificAssignments) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Billboard>>(`billboards/${billboardId}/assignments/specific`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercials() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<TvCommercial[]>>(`tvCommercials`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercial(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercialSampleUrl(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string | null>>(`tvCommercials/${tvCommercialId}/sampleUrl`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercialTextSampleUrl(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string | null>>(`tvCommercials/${tvCommercialId}/textSampleUrl`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercialPreviewUrl(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string | null>>(`tvCommercials/${tvCommercialId}/previewUrl`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createTvCommercial(create: CreateTvCommercial) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<TvCommercial>>(`tvCommercials`, create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateTvCommercial(tvCommercialId: string, update: UpdateTvCommercial) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateTvCommercialTextAssignments(tvCommercialId: string, update: UpdateTvCommercialTextAssignments) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/assignments/text`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateTvCommercialVoiceOverAssignments(tvCommercialId: string, update: UpdateTvCommercialVoiceOverAssignments) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/assignments/voiceOver`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generateSampleTvCommercial(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/generate/sample`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generateTextSampleTvCommercial(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/generate/textSample`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generatePreviewTvCommercial(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/generate/preview`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generateBusinessTypeTvCommercial(tvCommercialId: string, preview: PreviewTvCommercial) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<string>>(`tvCommercials/${tvCommercialId}/generate/businessType`, preview, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateTvCommercialScript(tvCommercialId: string, update: UpdateTvCommercialScript) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/script`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateTvCommercialTexts(tvCommercialId: string, update: UpdateTvCommercialTexts) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<TvCommercial>>(`tvCommercials/${tvCommercialId}/texts`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getTvCommercialUpload(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string>>(`tvCommercials/upload/${tvCommercialId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypeCategoryNodes(tvCommercialId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeCategoryNode[]>>(`tvCommercials/${tvCommercialId}/categories`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getBusinessTypeAssignments(tvCommercialId: string, businessTypeCategoryId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<BusinessTypeTextAssignment[]>>(`tvCommercials/${tvCommercialId}/categories/${businessTypeCategoryId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async generatePreviewsForBusinessTypeCategory(tvCommercialId: string, businessTypeCategoryId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<BusinessTypeTextAssignment[]>>(`tvCommercials/${tvCommercialId}/generate/businessTypeCategory?businessTypeCategoryId=${businessTypeCategoryId}`, {}, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAnchorPhrases() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<AnchorPhrase[]>>(`anchorPhrases`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getAnchorPhrase(anchorPhraseId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<AnchorPhrase>>(`anchorPhrases/${anchorPhraseId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createAnchorPhrase(create: CreateAnchorPhrase) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<AnchorPhrase>>(`anchorPhrases`, create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateAnchorPhrase(anchorPhraseId: string, update: UpdateAnchorPhrase) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<AnchorPhrase>>(`anchorPhrases/${anchorPhraseId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getGranularPhrases() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<GranularPhrase[]>>(`granularPhrases`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getGranularPhrase(granularPhraseId: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<GranularPhrase>>(`granularPhrases/${granularPhraseId}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async createGranularPhrase(create: CreateGranularPhrase) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<GranularPhrase>>(`granularPhrases`, create, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async updateGranularPhrase(granularPhraseId: string, update: UpdateGranularPhrase) {
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<GranularPhrase>>(`granularPhrases/${granularPhraseId}`, update, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getNeedsCustomAdCampaigns(campaignType: string) {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<Array<Campaign>>>(`campaigns/needsCustomAds?campaignType=${campaignType}`, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async apiPostCampaignDuplicate(accountId: string, campaignId: string, name: string) {
    const url = `campaigns/${campaignId}/duplicate`
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Campaign>>(url, { name } as any, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiGetBillboardBuilderUpload(accountId: string) {
    const url = `billboards/upload?accountId=${accountId}`
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<string>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiUploadBillboardAd(ad: BillboardUploadAdRequest) {
    const url = `billboards/upload`
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<Campaign>>(url, ad, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiGetDataAudiences(query: string | null) {
    const url = `dictionary/audiences/thirdParty?query=${query}`
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<ThirdPartyAudience[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiGetDataSegments(query: string | null) {
    const url = `dictionary/segments/internet?query=${query}`
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<SegmentTarget[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiGetDataTvAudiences(query: string | null) {
    const url = `dictionary/audiences/tv?query=${query}`
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<ThirdPartyAudience[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiGetDataTvSegments(query: string | null) {
    const url = `dictionary/segments/tv?query=${query}`
    const token = await this.getToken()
    const { data } = await this.apiClient.get<ApiResponse<SegmentTarget[]>>(url, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiPutCampaignTvTargeting(campaignId: string, tvTargeting: UpdateTvTargeting) {
    const url = `campaigns/${campaignId}/tvTargeting`
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(url, tvTargeting, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiPutCampaignStandardTargeting(campaignId: string, standardTargeting: SmartTargeting) {
    const url = `campaigns/${campaignId}/standardTargeting`
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(url, standardTargeting, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async apiPutCampaignBillboardTargeting(campaignId: string, billboardTargeting: UpdateBillboardTargeting) {
    const url = `campaigns/${campaignId}/billboardTargeting`
    const token = await this.getToken()
    const { data } = await this.apiClient.put<ApiResponse<Campaign>>(url, billboardTargeting, { headers: { Authorization: `Bearer ${token}` } })

    return data
  }

  async getCampaignByDayAndDimensionReport(reportRequest: CampaignByDayAndDimensionReportRequest) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<CampaignByDayAndDimensionReport[]>>(`reports/campaignByDayAndDimensionReport`, reportRequest, { headers: { Authorization: `Bearer ${token}` } })

    return data.data
  }

  async getCampaignTraffic(campaignId: string, start: Moment, end: Moment) {
    const token = await this.getToken()
    const { data } = await this.apiClient.post<ApiResponse<CampaignTraffic[]>>(
      `reports/campaignTraffic`,
      {
        campaignId,
        start,
        end
      },
      { headers: { Authorization: `Bearer ${token}` } }
    )

    return data.data
  }
}
