import React from 'react'

import { EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

export const BusinessImpactSignals: React.FC = () => {
  return (
    <React.Fragment>
      <EuiTitle size={'s'}>
        <h3>Business Impact Signals (Stage 2)</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiText size={'s'}>
        <p> In Stage 2, you should be seeing all of the signals identified in Stage 1 plus some new and important indicators that your campaigns are running properly.</p>
      </EuiText>
    </React.Fragment>
  )
}
