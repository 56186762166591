import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { CampaignType } from 'api'
import { BundleType, CampaignSlotSimple } from 'api/entities/Bundle'
import { useGetCampaignsWithoutBundlesForOrganizationQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { RootState } from 'app/rootReducer'

export interface CreateCampaignSlotPanelParams {
  bundleType: BundleType
  slot: CampaignSlotSimple
  onCancelClick?: () => void
  onSlotUpdate?: (slot: CampaignSlotSimple) => void
  isBonusSlot?: boolean
  allowMatchingOption?: boolean
  organizationId?: string
  disabled?: boolean
  hideAssignToCampaign?: boolean
}

export const CreateCampaignSlotPanel: React.FC<CreateCampaignSlotPanelParams> = props => {
  const { isCSuite } = useSelector((state: RootState) => state.app)

  const campaignsForOrgRequest = useGetCampaignsWithoutBundlesForOrganizationQuery(props.organizationId ?? '', { skip: !props.organizationId })
  const [campaignOptions, setCampaignOptions] = useState<EuiSelectOption[]>([{ value: '-1', text: 'Unassigned' }])

  useEffect(() => {
    if (!campaignsForOrgRequest?.data || campaignsForOrgRequest.isLoading || campaignsForOrgRequest.isFetching) {
      return
    }
    const availableCampaigns = campaignsForOrgRequest!.data!.filter(camp => props.slot.campaignType === camp.campaignType)
    if (availableCampaigns && availableCampaigns.length > 0) {
      setCampaignOptions([{ value: '-1', text: 'Unassigned' }, ...availableCampaigns.map(camp => ({ value: camp.campaignId, text: camp.campaignName }))])
    } else {
      setCampaignOptions([{ value: '-1', text: 'Unassigned' }])
    }
  }, [campaignsForOrgRequest.data, campaignsForOrgRequest.isLoading, campaignsForOrgRequest.isFetching, props.slot.campaignType])

  const campaignTypeOptions = Object.values(CampaignType)
    .filter(key => {
      return isCSuite || !props.isBonusSlot || (props.isBonusSlot && key === CampaignType.Internet)
    })
    .map(key => {
      return { value: key, text: key }
    })

  const onCancelClick = () => {
    if (props.onCancelClick) {
      props.onCancelClick()
    }
  }

  const onBudgetChange = (value: number) => {
    if (value < 0) {
      return
    }
    props.onSlotUpdate?.({ ...props.slot, budget: value })
  }

  const onMatchingChange = (value: boolean) => {
    props.onSlotUpdate?.({ ...props.slot, isBonus: value, campaignType: value ? CampaignType.Internet : props.slot.campaignType })
  }

  const onCampaignTypeChange = (campaignType: CampaignType) => {
    props.onSlotUpdate?.({ ...props.slot, campaignType: campaignType })
  }

  const onCampaignSelected = (e: any) => {
    if (e.target.value === '-1') {
      props.onSlotUpdate?.({ ...props.slot, assignedCampaignId: undefined })
      return
    }
    const campaign = campaignsForOrgRequest.data?.find(c => c.campaignId === e.target.value)
    if (campaign) {
      props.onSlotUpdate?.({ ...props.slot, assignedCampaignId: campaign.campaignId })
    }
  }

  return (
    <EuiPanel hasShadow={false} hasBorder={true} disabled={props.disabled} style={{ width: '400px' }}>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiTitle size={'s'}>
            <h2>
              ${props.slot.budget}
              {props.isBonusSlot ? ' Matching ' : ' '}
              {props.slot.campaignType} Campaign
            </h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon id='close' iconType='cross' onClick={onCancelClick} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFormRow label={'Budget'}>
        <EuiFieldText
          placeholder={'Budget'}
          value={props.slot.budget === 0 ? '' : props.slot.budget}
          onChange={e => {
            const parsed = parseInt(e.target.value)

            if (isNaN(parsed)) {
              onBudgetChange(0)
            } else {
              onBudgetChange(parsed)
            }
          }}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label={'Campaign Type'}>
        <EuiSelect
          options={campaignTypeOptions}
          value={props.slot.campaignType}
          onChange={e => {
            onCampaignTypeChange(e.target.value as CampaignType)
          }}
        />
      </EuiFormRow>
      {props.allowMatchingOption && (
        <EuiFormRow label={''}>
          <EuiSwitch
            label={'Matching'}
            checked={!!props.isBonusSlot}
            onChange={() => {
              onMatchingChange(!props.isBonusSlot)
            }}
          />
        </EuiFormRow>
      )}
      {props.organizationId && props.bundleType !== BundleType.SelfServicePackage && !props.hideAssignToCampaign && (
        <EuiFormRow label={'Assign to Campaign'}>
          <EuiSelect
            options={campaignOptions}
            value={props.slot.assignedCampaignId ?? -1}
            onChange={e => {
              onCampaignSelected(e)
            }}
          />
        </EuiFormRow>
      )}
    </EuiPanel>
  )
}
