import React, { useEffect, useState } from 'react'

import { EuiSwitch } from '@elastic/eui'

import { BusinessTypeTextAssignment } from 'api'

type AssignCellType = {
  assignment: BusinessTypeTextAssignment
}

const AssignTextCell: React.FC<AssignCellType> = ({ assignment }) => {
  const [isAssigned, setIsAssigned] = useState(false)

  useEffect(() => {
    if (assignment) setIsAssigned(assignment.isAssigned)
  }, [assignment])

  useEffect(() => {
    assignment.isAssigned = isAssigned
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssigned])

  return <EuiSwitch label={''} checked={isAssigned} onChange={e => setIsAssigned(e.target.checked)} />
}

export default AssignTextCell
