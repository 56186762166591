import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'

import { Chart, LayoutDirection, Metric, PartialTheme, Settings } from '@elastic/charts'
import { EuiPanel, EuiText } from '@elastic/eui'

import { Campaign } from 'api'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'

export interface ICampaignMetricsChart {
  campaign: Campaign
}
export const CampaignMetricsChart: React.FC<ICampaignMetricsChart> = props => {
  const [monthDaysIn, setMonthDaysIn] = useState<number>(0)
  const [monthTotalDays, setMonthTotalDays] = useState<number>(0)
  const [monthsIn, setMonthsIn] = useState<number>(0)
  const bundlesQuery = useGetOrganizationBundlesQuery(props.campaign.organizationId)
  const themeOverrides: PartialTheme = {
    partition: { outerSizeRatio: 1 }
  }

  useEffect(() => {
    if (props.campaign.bundleId) {
      const bundle = bundlesQuery.data?.find(bundle => bundle.id === props.campaign.bundleId)
      if (bundle) {
        const campaignSlot = bundle.campaignSlots.find(slot => slot.campaignId === props.campaign.campaignId)
        if (campaignSlot && campaignSlot.flightSchedule?.startDate) {
          setMonthsIn(moment().diff(moment(campaignSlot.flightSchedule.startDate), 'months') + 1)
          setMonthDaysIn(moment().diff(moment(campaignSlot.flightSchedule.lastIntervalDate), 'days') + 1)
          setMonthTotalDays(moment(campaignSlot.flightSchedule.nextEventDate).diff(moment(campaignSlot.flightSchedule.lastIntervalDate), 'days') + 1)
        } else {
          setMonthsIn(0)
          setMonthDaysIn(0)
          setMonthTotalDays(1)
        }
      }
    } else {
      if (props.campaign.budgetFrequency === 'Total') {
        const mIn = moment().diff(moment(props.campaign.startDate), 'months') + 1
        const lastMonthStart = moment(props.campaign.startDate).add(mIn - 1, 'months')
        const nextMonthStart = moment(props.campaign.startDate).add(mIn, 'months')
        setMonthsIn(mIn)
        setMonthDaysIn(moment().diff(lastMonthStart, 'days') + 1)
        setMonthTotalDays(nextMonthStart.diff(lastMonthStart, 'days') + 1)
      } else if (props.campaign.flightSchedule?.startDate) {
        const mIn = moment().diff(moment(props.campaign.flightSchedule.startDate), 'months') + 1
        const lastMonthStart = moment(props.campaign.flightSchedule.startDate).add(mIn - 1, 'months')
        const nextMonthStart = moment(props.campaign.flightSchedule.startDate).add(mIn, 'months')
        setMonthsIn(mIn)
        setMonthDaysIn(moment().diff(lastMonthStart, 'days') + 1)
        setMonthTotalDays(nextMonthStart.diff(lastMonthStart, 'days') + 1)
      } else {
        setMonthsIn(0)
        setMonthDaysIn(0)
        setMonthTotalDays(1)
      }
    }
  }, [props.campaign, bundlesQuery.data])

  const onPanelClick = () => {
    window.open(`/customers/campaigns/${props.campaign.campaignId}`, '_blank')
  }
  const monthText = (month: number) => {
    switch (month % 10) {
      case 1:
        return `${month}st`
      case 2:
        return `${month}nd`
      case 3:
        return `${month}rd`
      default:
        return `${month}th`
    }
  }
  return (
    <EuiPanel paddingSize='none' style={{ overflow: 'hidden', width: '200px' }}>
      <Chart size={[200, 200]}>
        <Settings theme={[themeOverrides]} onElementClick={onPanelClick} />
        <Metric
          id={props.campaign.campaignId}
          data={[
            [
              {
                color: '#54B399',
                title: props.campaign.campaignName,
                subtitle: `$${props.campaign.budget.toLocaleString()}`,
                value: (monthDaysIn / monthTotalDays) * 100,
                valueFormatter: () => `${props.campaign.campaignType}`,
                domainMax: 100,
                progressBarDirection: LayoutDirection.Vertical,
                extra: (
                  <span>
                    <EuiText>
                      <p>{monthText(monthsIn)} Month</p>
                    </EuiText>
                  </span>
                )
              }
            ]
          ]}
        />
      </Chart>
    </EuiPanel>
  )
}
