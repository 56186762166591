import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { EuiLoadingContent, EuiBadge, EuiCopy, EuiButton, EuiPageTemplate, EuiFlexGroup, EuiFlexItem, EuiStat } from '@elastic/eui'

import { useOpsClient } from 'api'
import { AnomalyStatus } from 'api/entities/Anomaly'
import { Customer } from 'api/entities/Customers'
import { useGetOrganizationAnomaliesQuery } from 'api/rtkQueryApi/opsApi/anomalyApi'
import { useGetOrganizationQuery, useGetOrganizationSpendDataQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcTab } from 'components/ACTools/AcTab'
import { ContactsTab } from 'features/customers/contacts/ContactsTab'
import { OrganizationAnomaliesTab } from 'features/customers/organizations/OrganizationAnomaliesTab'
import OrganizationBundlesTab from 'features/customers/organizations/OrganizationBundlesTab'
import { OrganizationCampaignsTab } from 'features/customers/organizations/OrganizationCampaignsTab'
import { OrganizationNotesTab } from 'features/customers/organizations/OrganizationNotesTab'
import OrganizationSignupDetails from 'features/customers/organizations/OrganizationSignupDetails'
import history from 'services/HistoryService'

import OrganizationAccountsTab from './OrganizationAccountsTab'
import OrganizationDetailsTab from './OrganizationDetailsTab'
import OrganizationIncludedCampaignIdsTab from './OrganizationIncludedCampaignIdsTab'
import OrganizationInvoicesTab from './OrganizationInvoicesTab'
import OrganizationProposalTab from './OrganizationProposalTab'
import OrganizationSubscriptionTab from './OrganizationSubscriptionTab'
import OrganizationUsersTab from './OrganizationUsersTab'

interface OrganizationPageParams {
  organizationId: string
  tabName?: string
}

enum OrganizationTab {
  Details,
  Campaigns,
  IncludedCampaigns,
  PlatformAccess,
  Accounts,
  Users,
  Invoices,
  Agreement,
  PlansAndPackages,
  Contacts,
  SignupDetails,
  Notes,
  Anomalies
}

const OrganizationPage: React.FC = () => {
  const { userProfile } = useSelector((state: RootState) => state.app)
  let { organizationId, tabName } = useParams<OrganizationPageParams>()
  const organizationAnomaliesQuery = useGetOrganizationAnomaliesQuery(organizationId)
  const organizationSpendDataQuery = useGetOrganizationSpendDataQuery(organizationId, { skip: !userProfile?.roles.includes('adcritter-ops-csuite') && !userProfile?.roles.includes('adcritter-ops-finance') })
  const { isFetching: organizationIsFetching, data: organization, refetch: refetchOrganization } = useGetOrganizationQuery(organizationId, {})
  const [customer, setCustomer] = useState<Customer>()
  const opsClient = useOpsClient()
  const [isFinance, setIsFinance] = useState(false)
  const [tab, setTab] = useState<OrganizationTab>(OrganizationTab.Details)

  useEffect(() => {
    if (organizationId) {
      refetchOrganization()
    }
  }, [organizationId])

  useEffect(() => {
    if (tabName === 'signupDetails') {
      setTab(9)
      return
    }
  }, [tabName])

  useEffect(() => {
    if (userProfile) {
      setIsFinance(userProfile.roles.includes('adcritter-ops-finance') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      opsClient.getCustomer(organizationId).then(result => {
        setCustomer(result)
      })
    }
  }, [opsClient, organizationId])

  const tabs: AcTab[] = [
    { label: 'Details', isSelected: tab === OrganizationTab.Details, onClick: () => setTab(OrganizationTab.Details) },
    { label: 'Campaigns', isSelected: tab === OrganizationTab.Campaigns, onClick: () => setTab(OrganizationTab.Campaigns) },
    { label: 'Included Campaigns', isSelected: tab === OrganizationTab.IncludedCampaigns, onClick: () => setTab(OrganizationTab.IncludedCampaigns) },
    ...(isFinance ? [{ label: 'Platform Access', isSelected: tab === OrganizationTab.PlatformAccess, onClick: () => setTab(OrganizationTab.PlatformAccess) }] : []),
    { label: 'Accounts', isSelected: tab === OrganizationTab.Accounts, onClick: () => setTab(OrganizationTab.Accounts) },
    { label: 'Users', isSelected: tab === OrganizationTab.Users, onClick: () => setTab(OrganizationTab.Users) },
    { label: 'Invoices', isSelected: tab === OrganizationTab.Invoices, onClick: () => setTab(OrganizationTab.Invoices) },
    { label: 'Agreement', isSelected: tab === OrganizationTab.Agreement, onClick: () => setTab(OrganizationTab.Agreement) },
    { label: 'Plans & Packages', isSelected: tab === OrganizationTab.PlansAndPackages, onClick: () => setTab(OrganizationTab.PlansAndPackages) },
    { label: 'Contacts', isSelected: tab === OrganizationTab.Contacts, onClick: () => setTab(OrganizationTab.Contacts) },
    { label: 'Signup Details', isSelected: tab === OrganizationTab.SignupDetails, onClick: () => setTab(OrganizationTab.SignupDetails) },
    { label: 'Notes', isSelected: tab === OrganizationTab.Notes, onClick: () => setTab(OrganizationTab.Notes) },
    ...(organizationAnomaliesQuery.data && organizationAnomaliesQuery.data.length > 0 ? [{ label: `Anomalies (${organizationAnomaliesQuery.data?.filter(a => a.status === AnomalyStatus.Escalated || a.status === AnomalyStatus.ActiveIssue).length})`, isSelected: tab === OrganizationTab.Anomalies, onClick: () => setTab(OrganizationTab.Anomalies) }] : [])
  ]

  if (!organization) {
    return <EuiLoadingContent />
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header
        iconType='savedObjectsApp'
        pageTitle={
          <React.Fragment>
            Organization
            <br />
            <EuiCopy textToCopy={organizationId}>
              {copy => (
                <EuiBadge color='default' onClick={copy} onClickAriaLabel='Copy Organization ID'>
                  {organizationId}
                </EuiBadge>
              )}
            </EuiCopy>
            {(userProfile?.roles.includes('adcritter-ops-csuite') || userProfile?.roles.includes('adcritter-ops-finance')) && organizationSpendDataQuery.data && (
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiStat titleSize='s' title={`$${organizationSpendDataQuery.data.lifetimeValue.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`} description='Lifetime Value' />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiStat titleSize='s' title={`$${organizationSpendDataQuery.data.lastMonthSpend.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`} description='Last Month Spend' />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiStat titleSize='s' title={`$${organizationSpendDataQuery.data.monthlyValue.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`} description='Recurring Monthly Value' />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </React.Fragment>
        }
        breadcrumbs={[
          { text: 'Customers' },
          {
            text: 'Organizations',
            href: '/customers/organizations',
            onClick: () => history.push('/customers/organizations')
          },
          {
            text: organization?.organizationName || '',
            href: `/customers/organizations/${organizationId}`,
            onClick: () => history.push(`/customers/organizations/${organizationId}`)
          }
        ]}
        tabs={tabs}
        rightSideItems={[
          ...(organization && !organization.isAgency
            ? [
                <EuiButton href={`${config.PLATFORMURL}gotoOrganization/${organization.organizationId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                  AdCritter Platform
                </EuiButton>
              ]
            : []),
          ...(organization && organization.isAgency
            ? [
                <EuiButton href={`${config.AGENCYURL}gotoOrganization/${organization.organizationId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                  AdCritter for Agencies
                </EuiButton>
              ]
            : []),
          ...(organization && isFinance && customer
            ? [
                <EuiButton href={`https://dashboard.stripe.com/customers/${customer.stripeCustomerId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                  Stripe
                </EuiButton>
              ]
            : [])
        ]}
        restrictWidth={false}
      />
      <EuiPageTemplate.Section restrictWidth={false}>
        {tab === OrganizationTab.Details && <OrganizationDetailsTab organization={organization!} refetchOrganization={refetchOrganization} />}
        {tab === OrganizationTab.Campaigns && <OrganizationCampaignsTab organization={organization!} />}
        {tab === OrganizationTab.IncludedCampaigns && <OrganizationIncludedCampaignIdsTab organization={organization!} refetchOrganization={refetchOrganization} />}
        {tab === OrganizationTab.PlatformAccess && <OrganizationSubscriptionTab organization={organization!} />}
        {tab === OrganizationTab.Accounts && <OrganizationAccountsTab organization={organization!} />}
        {tab === OrganizationTab.Users && <OrganizationUsersTab organization={organization!} />}
        {tab === OrganizationTab.Invoices && <OrganizationInvoicesTab organization={organization!} />}
        {tab === OrganizationTab.Agreement && <OrganizationProposalTab organization={organization!} organizationIsFetching={organizationIsFetching} />}
        {tab === OrganizationTab.PlansAndPackages && <OrganizationBundlesTab organization={organization!} />}
        {tab === OrganizationTab.Contacts && <ContactsTab organization={organization!} />}
        {tab === OrganizationTab.SignupDetails && <OrganizationSignupDetails organization={organization!} />}
        {tab === OrganizationTab.Notes && <OrganizationNotesTab organization={organization!} />}
        {tab === OrganizationTab.Anomalies && <OrganizationAnomaliesTab organization={organization!} />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default OrganizationPage
