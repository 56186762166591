import React from 'react'

import { EuiText } from '@elastic/eui'

export const OpsOverviewPage: React.FC = () => {
  return (
    <React.Fragment>
      <EuiText>
        <p>Ops Overview Page</p>
      </EuiText>
    </React.Fragment>
  )
}
