import React, { useEffect, useState } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiSelect, EuiSelectOption, EuiSpacer, EuiText } from '@elastic/eui'

import { useGetCohortsQuery } from 'api/rtkQueryApi/opsApi/cohortsApi'

export interface IAgencyCohortInviteModal {
  onClose: () => void
  onAccept: (cohortId: string) => void
}

export const AgencyCohortInviteModal: React.FC<IAgencyCohortInviteModal> = props => {
  const getCohortsQuery = useGetCohortsQuery()
  const [cohortOptions, setCohortOptions] = useState<EuiSelectOption[]>([{ value: 'unassigned', text: 'unassigned' }])
  const [selectedCohortId, setSelectedCohortId] = useState<string>('unassigned')

  useEffect(() => {
    if (getCohortsQuery.data) {
      const options = getCohortsQuery.data.map(cohort => {
        return { value: cohort.id, text: cohort.name }
      })
      options.push({ value: 'unassigned', text: 'unassigned' })
      setCohortOptions(options)
    }
  }, [getCohortsQuery.data])

  const onCohortSelectChange = (value: string) => {
    setSelectedCohortId(value)
  }

  const onConfirmClick = () => {
    props.onAccept(selectedCohortId)
  }

  return (
    <EuiOverlayMask headerZindexLocation='above'>
      <EuiModal onClose={props.onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Invite to Cohort</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFormRow>
            <EuiText>This will convert the organization to an agency and place them in the selected cohort.</EuiText>
          </EuiFormRow>
          <EuiFormRow>
            <EuiSelect id={'SalesStageSelect'} options={cohortOptions} value={selectedCohortId} onChange={e => onCohortSelectChange(e.target.value)} aria-label='Agency Cohort' />
          </EuiFormRow>
          <EuiSpacer />
          <EuiFlexGroup direction={'rowReverse'}>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={onConfirmClick} fill disabled={selectedCohortId === 'unassigned'}>
                Confirm
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
      </EuiModal>
    </EuiOverlayMask>
  )
}
