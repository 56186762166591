import React, { FC } from 'react'

import { EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { Bundle, BundleActiveStatus, BundleStatus, BundleType } from 'api/entities/Bundle'
import { Plan } from 'api/entities/Plan'
import { SelfServicePackage, SelfServicePackageStatus } from 'api/entities/SelfServicePackage'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface BundleStatusBadgeProps {
  bundle: Bundle
}

const BundleStatusBadge: FC<BundleStatusBadgeProps> = ({ bundle }) => {
  const plan = bundle as Plan
  const selfServicePackage = bundle as SelfServicePackage

  let selfServicePackageStatusColor
  switch (selfServicePackage?.selfServicePackageStatus) {
    case SelfServicePackageStatus.Expired:
      selfServicePackageStatusColor = 'danger'
      break
    default:
      selfServicePackageStatusColor = 'default'
      break
  }

  return (
    <EuiFlexGroup gutterSize='s'>
      <EuiFlexItem grow={false}>
        <EuiBadge color={bundle.bundleActiveStatus === BundleActiveStatus.Inactive ? (bundle.bundleStatus === BundleStatus.Deleted ? 'danger' : 'default') : 'success'}>{bundle.bundleStatus}</EuiBadge>
      </EuiFlexItem>
      {bundle.bundleType === BundleType.Plan && plan.planStatus && (
        <EuiFlexItem grow={false}>
          <EuiBadge color={bundle.bundleActiveStatus === BundleActiveStatus.Inactive ? (bundle.bundleStatus === BundleStatus.Deleted ? 'danger' : 'default') : 'success'}>{plan.planStatus}</EuiBadge>
        </EuiFlexItem>
      )}
      {bundle.bundleType === BundleType.SelfServicePackage && selfServicePackage.selfServicePackageStatus && (
        <EuiFlexItem grow={false}>
          <EuiBadge color={selfServicePackageStatusColor}>{insertSpaces(selfServicePackage.selfServicePackageStatus)}</EuiBadge>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default BundleStatusBadge
