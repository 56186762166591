import {AgencyOrganization} from "api";
import {
  EuiButton,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader, EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import React, {useState} from "react";
import {
  useDeleteNoteMutation,
  useGetNotesForIdQuery, useSalesAgencyDetailsAddNoteMutation,
  useUpdateNoteContentMutation
} from "api/rtkQueryApi/opsApi/noteApi";
import {AcNote} from "components/ACTools/AcNote";
import {Note} from "api/entities/Notes";
import {AcConfirmModal} from "components/Modals/AcConfirmModal";

export interface IAgencyDetailsFlyout {
  agencyOrg: AgencyOrganization
  onClose: () => void
}
export const AgencyDetailsFlyout: React.FC<IAgencyDetailsFlyout> = props => {
  const agencyDetailsNotes = useGetNotesForIdQuery(props.agencyOrg.agencyDetails.id)
  const [updateNote, updateNoteQuery] = useUpdateNoteContentMutation()
  const [deleteNote] = useDeleteNoteMutation()
  const [addingNote, setAddingNote] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState<Note>()
  const [saveAgencyDetailsNote] = useSalesAgencyDetailsAddNoteMutation()
  const onAddNoteClick = () => {
    setAddingNote(true)
  }

  const onCancelAddNoteClick = () => {
    setAddingNote(false)
  }

  const addNoteClick = (note: Note) => {
    saveAgencyDetailsNote({
      salesAgencyDetailsId: props.agencyOrg.agencyDetails.id,
      organizationId: props.agencyOrg.organization.organizationId,
      followUpDate: note.followUpDate,
      followUp: note.followUp,
      secondaryReferenceIds: [],
      text: note.text,
      name: note.name
    })
    setAddingNote(false)
  }

  const updateNoteClick = (note: Note) => {
    updateNote({ followUp: note.followUp, text: note.text, name: note.name, followUpDate: note.followUpDate, noteId: note.id })
  }

  const deleteNoteClick = (note: Note) => {
    setNoteToDelete(note)
    setShowDeleteConfirm(true)
  }

  const onDeleteNoteConfirm = () => {
    if (noteToDelete?.id) {
      setShowDeleteConfirm(false)
      deleteNote(noteToDelete.id)
    }
  }

  const onDeleteNoteCancel = () => {
    setShowDeleteConfirm(false)
  }

  return (
    <React.Fragment>
      <EuiFlyout ownFocus onClose={props.onClose} size={'l'}>
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h2>{props.agencyOrg.organization.organizationName}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {!addingNote && (
            <React.Fragment>
              <EuiSpacer />
              <EuiButton
                onClick={() => {
                  onAddNoteClick()
                }}>
                Add Note
              </EuiButton>
              <EuiSpacer/>
            </React.Fragment>
          )}
          {addingNote && <AcNote editable buttonText={'Add'} onNoteSave={addNoteClick} onNoteDelete={onCancelAddNoteClick} />}
          {agencyDetailsNotes.data?.map(note => <AcNote isLoading={updateNoteQuery.isLoading} editable key={note.id} onNoteSave={updateNoteClick} note={note} onNoteDelete={deleteNoteClick} /> ?? [])}
        </EuiFlyoutBody>
      </EuiFlyout>
      {showDeleteConfirm && <AcConfirmModal onConfirm={onDeleteNoteConfirm} onCancel={onDeleteNoteCancel} title={'Delete Note'} message={'Are you sure you would like to delete this note?'} confirmButtonText={'Delete'} />}
    </React.Fragment>
  )
}