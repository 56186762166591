import { OrganizationRostersByType } from 'api/entities/OrganizationRosters'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const organizationRostersTags = opsApi.enhanceEndpoints({ addTagTypes: ['OrganizationRosters'] })

const organizationRostersApi = organizationRostersTags.injectEndpoints({
  endpoints: build => ({
    getOrganizationRosters: build.query<OrganizationRostersByType, void>({
      query: () => 'organizationRosters',
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'OrganizationRosters' }]
    })
  }),
  overrideExisting: false
})

export const { useGetOrganizationRostersQuery } = organizationRostersApi
