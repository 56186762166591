import { Global } from '@emotion/react'
import { useLoadScript } from '@react-google-maps/api'
import { GraphClientProvider } from 'graph/GraphClientContext'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiProvider } from '@elastic/eui'
import '@elastic/eui/dist/eui_theme_light.css'

import { OpsClientProvider } from 'api/OpsClientContext'
import config from 'app/config'
import HeaderBar from 'components/Header/HeaderBar'
import { AuthStatus } from 'services/AuthService'

import Routes from './Routes'
import { initializeAuthentication, setGoogleLoaded } from './appSlice'
import { RootState } from './rootReducer'

const libraries: any = ['drawing', 'geometry', 'places']

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { status: authStatus } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    dispatch(initializeAuthentication())
  }, [dispatch])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMaps.APIKEY,
    libraries: libraries
  })

  useEffect(() => {
    if (isLoaded) {
      dispatch(setGoogleLoaded())
    }
  }, [isLoaded, dispatch])

  return (
    <EuiProvider>
      <Global
        styles={{
          '*:focus:focus-visible': {
            outlineStyle: 'none'
          },
          '.selectableImage': {
            cursor: 'pointer'
          },
          '.selectableImage-selected': {
            outline: '4px solid #000'
          }
        }}
      />
      <OpsClientProvider>
        <GraphClientProvider>
          {authStatus === AuthStatus.Authenticated && <HeaderBar />}
          <Routes />
        </GraphClientProvider>
      </OpsClientProvider>
    </EuiProvider>
  )
}

export default App
