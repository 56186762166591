import React, { useEffect, useState } from 'react'

import { EuiLoadingSpinner, EuiText } from '@elastic/eui'

type LoadingSpinnerProps = {
  initialMessage?: string
  promptReload?: boolean
  textSize?: 's' | 'xs' | 'm' | 'relative' | undefined
  spinnerSize?: 's' | 'm' | 'l' | 'xl' | 'xxl' | undefined
  timeBeforeMessageMS?: number
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ initialMessage = '', promptReload = true, textSize, spinnerSize, timeBeforeMessageMS }) => {
  const [loadingMessage, setLoadingMessage] = useState<string>(initialMessage)

  useEffect(() => {
    if (promptReload) {
      const timeout = setTimeout(() => {
        setLoadingMessage('This is taking longer than expected... Please reload the page to try again.')
      }, timeBeforeMessageMS ?? 15000)
      return clearTimeout.bind(null, timeout)
    }
  }, [promptReload])

  return (
    <EuiText size={textSize ?? 's'}>
      <p style={{ margin: 30 }}>
        <EuiLoadingSpinner size={spinnerSize ?? 'm'} /> {loadingMessage}
      </p>
    </EuiText>
  )
}

export const Loading: React.FC<LoadingSpinnerProps> = props => {
  return (
    <div>
      <LoadingSpinner {...props} />
    </div>
  )
}
