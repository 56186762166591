import { useFormik } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiFieldText, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiSelect, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { CommunicationPreference, ContactMethod, ContactMethodType, transformContactMethodCommunicationPreferences, transformContactMethodTypes } from 'api'
import { ContactAction, ContactActionKind } from 'features/customers/contacts/useContactReducer'

export interface EditContactMethodModalProps {
  contactMethod?: ContactMethod
  dispatchContact: React.Dispatch<ContactAction>
  isModalVisible: boolean
  closeModal: () => void
}

const EditContactMethodModal: FC<EditContactMethodModalProps> = ({ contactMethod, dispatchContact, isModalVisible, closeModal }) => {
  const [communicationPreferences, setCommunicationPreferences] = useState([] as EuiSelectOption[])
  const contactMethodTypes = useMemo(() => transformContactMethodTypes(), [contactMethod])

  const contactMethodSchema = Yup.object().shape({
    type: Yup.string().required('Required'),
    communicationPreference: Yup.string().required('Required'),
    info: Yup.string().test('info', function (value) {
      const type = this.parent.type
      switch (type) {
        case ContactMethodType.PersonalEmail:
        case ContactMethodType.WorkEmail:
          if (!Yup.string().email('Invalid email').required('Required').isValidSync(value)) {
            return this.createError({ message: 'Invalid email' })
          }
          break
        case ContactMethodType.HomePhone:
        case ContactMethodType.PersonalCellPhone:
        case ContactMethodType.WorkPhone:
        case ContactMethodType.WorkCellPhone:
        case ContactMethodType.HomeFax:
        case ContactMethodType.WorkFax:
        case ContactMethodType.Other:
        default:
          if (!Yup.string().required('Required').isValidSync(value)) {
            return this.createError({ message: 'Required' })
          }
          break
      }

      return true
    }),
    isPrimary: Yup.boolean()
  })

  const formik = useFormik({
    initialValues: {
      info: contactMethod?.info ?? '',
      type: contactMethod?.type ?? ContactMethodType.WorkPhone,
      communicationPreference: contactMethod?.communicationPreference ?? CommunicationPreference.Call,
      isPrimary: contactMethod?.isPrimary ?? false
    } as ContactMethod,
    enableReinitialize: true,
    validationSchema: contactMethodSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values: ContactMethod) => {
      if (contactMethod) {
        dispatchContact({ type: ContactActionKind.CONTACT_METHOD_EDIT, payload: { ...contactMethod, ...values } })
      } else {
        dispatchContact({ type: ContactActionKind.CONTACT_METHOD_ADD, payload: { ...values } })
      }
      closeModal()
    }
  })

  useEffect(() => {
    formik.resetForm()
  }, [contactMethod])

  useEffect(() => {
    if (formik.values.type === ContactMethodType.PersonalEmail || formik.values.type === ContactMethodType.WorkEmail) {
      setCommunicationPreferences(transformContactMethodCommunicationPreferences(cp => cp === CommunicationPreference.Email))
    } else {
      setCommunicationPreferences(transformContactMethodCommunicationPreferences(cp => cp !== CommunicationPreference.Email))
    }
  }, [contactMethod, formik.values.type])

  const onAddEditClicked = () => {
    if (contactMethod) {
      dispatchContact({ type: ContactActionKind.CONTACT_METHOD_EDIT, payload: { ...contactMethod, ...formik.values } })
    } else {
      dispatchContact({ type: ContactActionKind.CONTACT_METHOD_ADD, payload: { ...formik.values } })
    }

    formik.resetForm()
    closeModal()
  }

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as ContactMethodType
    formik.setFieldValue('type', e.target.value)

    const currentCommunicationPreference = formik.values.communicationPreference

    if (type === ContactMethodType.PersonalEmail || type === ContactMethodType.WorkEmail) {
      formik.setFieldValue('communicationPreference', CommunicationPreference.Email)
      setCommunicationPreferences(transformContactMethodCommunicationPreferences(cp => cp === CommunicationPreference.Email))

      return
    } else if (currentCommunicationPreference === CommunicationPreference.Email) {
      formik.setFieldValue('communicationPreference', CommunicationPreference.Call)
    }

    setCommunicationPreferences(transformContactMethodCommunicationPreferences(cp => cp !== CommunicationPreference.Email))
  }

  return (
    <React.Fragment>
      {isModalVisible && (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1>Contact Method</h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiForm onBlur={formik.handleBlur}>
              <EuiFormRow title='Info' label='Info' isInvalid={!!formik.errors.info} error={formik.errors.info}>
                <EuiFieldText name='info' value={formik.values.info} onChange={formik.handleChange} isInvalid={!!formik.errors.info} />
              </EuiFormRow>
              <EuiFormRow title='Type' label='Type' isInvalid={!!formik.errors.type} error={formik.errors.type}>
                <EuiSelect name='type' options={contactMethodTypes} value={formik.values.type} onChange={onTypeChange} isInvalid={!!formik.errors.type} />
              </EuiFormRow>
              <EuiFormRow title='Communication Preference' label='Communication Preference' isInvalid={!!formik.errors.communicationPreference} error={formik.errors.communicationPreference}>
                <EuiSelect name='communicationPreference' options={communicationPreferences} value={formik.values.communicationPreference} onChange={formik.handleChange} isInvalid={!!formik.errors.communicationPreference} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiCheckbox name='isPrimary' id={htmlIdGenerator()()} label='Is Primary' checked={formik.values.isPrimary} onChange={formik.handleChange} />
              </EuiFormRow>
            </EuiForm>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={closeModal} color='danger'>
              Cancel
            </EuiButton>
            <EuiButton onClick={onAddEditClicked} fill>
              Save
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </React.Fragment>
  )
}

export default EditContactMethodModal
