import React from "react";
import {
  EuiButtonEmpty, EuiButtonIcon, EuiDatePicker,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTitle
} from "@elastic/eui";
import {localDate} from "utils/dateUtils";
import moment, {Moment} from "moment";
import {IPayment} from "api/entities/Invoice";

export interface IInvoiceSplitterContent {
  payments: IPayment[]
  amountToPay: number
  maxDate: Moment
  onAddPaymentClicked: () => void
  getInvalidText: (payment: IPayment) => string | undefined
  onPaymentAmountChanged: (value: number, payment: IPayment) => void
  onPayDateChanged: (value: Moment, payment: IPayment) => void
  onDeletePayment: (payment: IPayment) => void
}

export const InvoiceSplitterContent : React.FC<IInvoiceSplitterContent> = (props) => {
  return <React.Fragment>
    <EuiFormRow helpText={props.payments.length + ' payments totalling $' + props.payments.reduce((partialSum, a) => partialSum + a.amount, 0) + ' / $' + props.amountToPay}>
      <EuiText>
        ${props.amountToPay} is due by {localDate(props.maxDate)}
      </EuiText>
    </EuiFormRow>
    <EuiSpacer />
    <EuiTitle size={'xxs'}>
      <h2>Payments</h2>
    </EuiTitle>
    <EuiButtonEmpty iconType={'plus'} onClick={props.onAddPaymentClicked}>
      Add Payment
    </EuiButtonEmpty>
    {props.payments.map(payment => (
      <EuiFormRow helpText={props.getInvalidText(payment)}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow>
              <EuiFieldNumber
                prepend={'$'}
                step={1}
                value={payment.amount}
                onChange={e => {
                  props.onPaymentAmountChanged(Number(e.target.value), payment)
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiDatePicker
                selected={payment.payDate}
                onChange={date => {
                  if (date) {
                    props.onPayDateChanged(date, payment)
                  }
                }}
                minDate={moment()}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiButtonIcon
                iconType='cross'
                aria-label='delete'
                onClick={() => {
                  props.onDeletePayment(payment)
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    ))}
    <EuiSpacer />
  </React.Fragment>
}