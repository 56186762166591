import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiForm, EuiFlyout, EuiFormRow, EuiPortal, EuiSelect, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'

import { Account, BusinessType, Organization, UpdateAccount, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'

interface AccountDetailsTabParams {
  account: Account
  organization: Organization

  setAccount(a: Account): void
}

interface FormValues {
  accountName: string
  streetAddress: string
  city: string
  state: string
  zip: string
  website: string
  hasVisits: boolean
  hideVisits: boolean
  showDemoData: boolean
  hasCustomBusinessType: boolean
}

interface FormValuesCreateCustomer {
  firstName: string
  lastName: string
  companyName: string
  email: string
  phone: string
}

const AccountDetailsTab: React.FC<AccountDetailsTabParams> = ({ account, organization, setAccount }) => {
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false)
  const [isCreateCustomerFlyoutOpen, setIsCreateCustomerFlyoutOpen] = useState(false)
  const [businessTypes, setBusinessTypes] = useState<BusinessType[] | undefined>()
  const [businessTypeOptions, setBusinessTypeOptions] = useState<EuiComboBoxOptionOption[]>([])
  const [chosenBusinessType, setChosenBusinessType] = useState<EuiComboBoxOptionOption[]>([])
  const [initial, setInitial] = useState<FormValues>({
    accountName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    website: '',
    hasVisits: false,
    hideVisits: false,
    showDemoData: false,
    hasCustomBusinessType: false
  })
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [accountHasCustomer, setAccountHasCustomer] = useState(true)

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-customermanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  const getCustomerByAccountId = () => {
    opsClient!.getCustomerByAccountId(account.accountId).then(res => {
      if (res !== null) {
        setAccountHasCustomer(true)
      } else {
        setAccountHasCustomer(false)
      }
    })
  }

  useEffect(() => {
    getCustomerByAccountId()
  }, [opsClient])

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoadingBusinessTypes(true)
      setInitial({
        accountName: account.accountName,
        streetAddress: account.streetAddress,
        city: account.city,
        state: account.state,
        zip: account.zip,
        website: account.website,
        showDemoData: account.showDemoData,
        hasVisits: account.hasVisits,
        hideVisits: account.hideVisits,
        hasCustomBusinessType: account.hasCustomBusinessType
      })
      opsClient.getBusinessTypes().then(result => {
        if (isMounted) {
          setBusinessTypes(result)
          setIsLoadingBusinessTypes(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, account])

  useEffect(() => {
    if (businessTypes) {
      const p = businessTypes.map(
        bt =>
          ({
            key: bt.id,
            label: bt.name
          } as EuiComboBoxOptionOption)
      )
      setBusinessTypeOptions(p)
      if (account && account.businessTypeId) {
        setChosenBusinessType(
          businessTypes
            .filter(p => p.id === account.businessTypeId)
            .map(
              btc =>
                ({
                  key: btc.id,
                  label: btc.name
                } as EuiComboBoxOptionOption)
            )
        )
      }
    }
  }, [businessTypes, account])

  const accountSchema = Yup.object().shape({
    accountName: Yup.string().required('Please enter the account name'),
    streetAddress: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    zip: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    website: Yup.string().url('Please enter a valid URL').nullable(),
    hasVisits: Yup.boolean(),
    showDemoData: Yup.boolean(),
    hasCustomBusinessType: Yup.boolean()
  })

  const customerSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter the first name'),
    lastName: Yup.string().required('Please enter the last name'),
    companyName: Yup.string().required('Please enter the company name'),
    email: Yup.string().email('Please enter a valid email').required('Please enter the email'),
    phone: Yup.string().required('Please enter the phone number')
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateAccount = {
      accountName: values.accountName,
      streetAddress: values.streetAddress,
      city: values.city,
      state: values.state,
      zip: values.zip,
      website: values.website,
      businessTypeId: chosenBusinessType.length > 0 ? chosenBusinessType[0].key! : null,
      hasVisits: account!.hasVisits,
      hideVisits: account!.hideVisits,
      showDemoData: account!.showDemoData
    }

    if (userProfile!.isAdmin) {
      update.showDemoData = values.showDemoData
    }

    opsClient?.updateAccount(account.accountId, update).then(result => {
      setAccount(result)
      setSubmitting(false)
    })
  }

  const selectBusinessType = (options: EuiComboBoxOptionOption[]) => {
    setChosenBusinessType(options)
  }

  const onSubmitCreateCustomer = (values: FormValuesCreateCustomer, { setSubmitting }: FormikHelpers<FormValuesCreateCustomer>) => {
    opsClient!
      .createCustomer({
        organizationId: null,
        accountId: account.accountId,
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        email: values.email,
        phone: values.phone
      })
      .then(res => {
        onCloseCreateCustomerFlyout()
        getCustomerByAccountId()
      })
  }

  const onCloseCreateCustomerFlyout = () => {
    setIsCreateCustomerFlyoutOpen(false)
  }

  const initialValuesCreateCustomer = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phone: ''
  } as FormValuesCreateCustomer

  let createCustomerFlyout
  if (isCreateCustomerFlyoutOpen) {
    createCustomerFlyout = (
      <EuiPortal>
        <Formik initialValues={initialValuesCreateCustomer} validationSchema={customerSchema} onSubmit={onSubmitCreateCustomer}>
          {props => (
            <EuiFlyout onClose={onCloseCreateCustomerFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Customer</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='First Name' isInvalid={!!props.errors.firstName} error={props.errors.firstName}>
                    <EuiFieldText name='firstName' value={props.values.firstName} isInvalid={!!props.errors.firstName} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Last Name' isInvalid={!!props.errors.lastName} error={props.errors.lastName}>
                    <EuiFieldText name='lastName' value={props.values.lastName} isInvalid={!!props.errors.lastName} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Comapny Name' isInvalid={!!props.errors.companyName} error={props.errors.companyName}>
                    <EuiFieldText name='companyName' value={props.values.companyName} isInvalid={!!props.errors.companyName} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Email' isInvalid={!!props.errors.email} error={props.errors.email}>
                    <EuiFieldText name='email' value={props.values.email} isInvalid={!!props.errors.email} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Phone' isInvalid={!!props.errors.phone} error={props.errors.phone}>
                    <EuiFieldText name='phone' value={props.values.phone} isInvalid={!!props.errors.phone} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
              <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty id='close' iconType='cross' onClick={onCloseCreateCustomerFlyout} flush='left'>
                      Close
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlyoutFooter>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      {createCustomerFlyout}
      <EuiSpacer />
      {account && (
        <Formik initialValues={initial} enableReinitialize validationSchema={accountSchema} onSubmit={doSubmit}>
          {props => (
            <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
              <EuiFormRow label='Name' isInvalid={!!props.errors.accountName} error={props.errors.accountName}>
                <EuiFieldText name='accountName' value={props.values.accountName} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.accountName} />
              </EuiFormRow>
              <EuiFormRow label='Street Address' isInvalid={!!props.errors.streetAddress} error={props.errors.streetAddress}>
                <EuiFieldText name='streetAddress' value={props.values.streetAddress} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.streetAddress} />
              </EuiFormRow>
              <EuiFormRow label='City' isInvalid={!!props.errors.city} error={props.errors.city}>
                <EuiFieldText name='city' value={props.values.city} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.city} />
              </EuiFormRow>
              <EuiFormRow label='State' isInvalid={!!props.errors.state} error={props.errors.state} helpText={'Use 2-letter abbrev.'}>
                <EuiFieldText name='state' value={props.values.state} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.state} />
              </EuiFormRow>
              <EuiFormRow label='Zip' isInvalid={!!props.errors.zip} error={props.errors.zip}>
                <EuiFieldText name='zip' value={props.values.zip} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.zip} />
              </EuiFormRow>
              <EuiFormRow label='Website' isInvalid={!!props.errors.website} error={props.errors.website}>
                <EuiFieldText name='website' value={props.values.website} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.website} />
              </EuiFormRow>

              <React.Fragment>
                <EuiFormRow label='Business Type'>
                  <EuiComboBox singleSelection={{ asPlainText: true }} options={businessTypeOptions} selectedOptions={chosenBusinessType} onChange={selectBusinessType} isLoading={isLoadingBusinessTypes} />
                </EuiFormRow>
              </React.Fragment>
              {userProfile!.isAdmin && (
                <React.Fragment>
                  <EuiSpacer size='m' />
                  <EuiSwitch onChange={e => props.setFieldValue('hasVisits', e.target.checked)} checked={props.values.hasVisits} label='Has Tracking Script Correct' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                  <EuiSpacer size='m' />
                  <EuiSwitch onChange={e => props.setFieldValue('hideVisits', e.target.checked)} checked={props.values.hideVisits} label='Hide Verified Sessions on Dashboard' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                  <EuiSpacer size='m' />
                  <EuiSwitch onChange={e => props.setFieldValue('showDemoData', e.target.checked)} checked={props.values.showDemoData} label='Show Demo Analytics Data' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                  <EuiSpacer size='m' />
                </React.Fragment>
              )}
              <EuiSpacer />
              <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
                Save
              </EuiButton>

              {!accountHasCustomer && organization?.isAgency && (
                <EuiButton style={{ marginLeft: '10px' }} fill onClick={() => setIsCreateCustomerFlyoutOpen(true)} isDisabled={!canUpdate}>
                  Create Customer
                </EuiButton>
              )}
            </EuiForm>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}

export default AccountDetailsTab
