import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { useCallback, useEffect, useState } from 'react'

import { useGetBuilderUploadQuery } from 'api/rtkQueryApi/opsApi/uploadApi'

export const useAcImageUploader = (props: { accountId: string }) => {
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const builderUploadQuery = useGetBuilderUploadQuery({ accountId: props.accountId })

  useEffect(() => {
    if (builderUploadQuery.data) {
      const storageClient = new BlobServiceClient(builderUploadQuery.data)
      const containerClient = storageClient.getContainerClient(props.accountId)
      setContainerClient(containerClient)
    }
  }, [builderUploadQuery.data, props.accountId])

  function base64ToBlob(base64: string, sliceSize = 512) {
    const contentType = 'image/png'
    const byteCharacters = atob(base64.split(',')[1])
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
  }

  const uploadBase64Image = useCallback(
    (key: string, base64Image: string, fileName: string) => {
      if (!containerClient) {
        return Promise.reject('Container client is not ready')
      }
      const blockBlobClient = containerClient.getBlockBlobClient(fileName)
      const blob = base64ToBlob(base64Image)
      return blockBlobClient.uploadData(blob).then(() => {
        return { key: key, url: blockBlobClient.url }
      })
    },
    [builderUploadQuery.data, containerClient]
  )

  return { uploadBase64Image }
}
