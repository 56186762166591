import React, { useEffect, useState } from 'react'

import { EuiButtonGroup } from '@elastic/eui'

interface HouseholdIncomeTargeterProps {
  householdIncomes: Array<string>

  setHouseholdIncomes(householdIncomes: Array<string>): void
}

export const HouseholdIncomeTargeter = ({ householdIncomes, setHouseholdIncomes }: HouseholdIncomeTargeterProps) => {
  const [toggleHouseholdIncomeToSelectedMap, setToggleHouseholdIncomeToSelectedMap] = useState<{ [id: string]: boolean }>({
    householdIncomes_all: true,
    householdIncomes_less50: false,
    householdIncomes_50to100: false,
    householdIncomes_100to150: false,
    householdIncomes_150to200: false,
    householdIncomes_more200: false
  })

  const householdIncomesButtons = [
    {
      id: 'householdIncomes_all',
      label: 'All'
    },
    {
      id: 'householdIncomes_less50',
      label: '< 50k'
    },
    {
      id: 'householdIncomes_50to100',
      label: '50k - 100k'
    },
    {
      id: 'householdIncomes_100to150',
      label: '100k - 150k'
    },
    {
      id: 'householdIncomes_150to200',
      label: '150k - 200k'
    },
    {
      id: 'householdIncomes_more200',
      label: '> 200k'
    }
  ]

  useEffect(() => {
    if (householdIncomes) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (householdIncomes.length === 0) householdIncomes = ['all']

      setToggleHouseholdIncomeToSelectedMap({
        householdIncomes_all: householdIncomes.includes('all'),
        householdIncomes_less50: householdIncomes.includes('less50'),
        householdIncomes_50to100: householdIncomes.includes('50to100'),
        householdIncomes_100to150: householdIncomes.includes('100to150'),
        householdIncomes_150to200: householdIncomes.includes('150to200'),
        householdIncomes_more200: householdIncomes.includes('more200')
      })
    }
  }, [householdIncomes])

  const onChangeHouseholdIncome = (optionId: string) => {
    let householdIncomes: Array<string> = []
    if (optionId === 'householdIncomes_all' && !toggleHouseholdIncomeToSelectedMap[optionId]) {
      setToggleHouseholdIncomeToSelectedMap({
        householdIncomes_all: true,
        householdIncomes_less50: false,
        householdIncomes_50to100: false,
        householdIncomes_100to150: false,
        householdIncomes_150to200: false,
        householdIncomes_more200: false
      })
      householdIncomes.push('all')
    } else {
      const newToggleIdToSelectedMap = {
        ...toggleHouseholdIncomeToSelectedMap,
        ...{
          [optionId]: !toggleHouseholdIncomeToSelectedMap[optionId]
        }
      }
      newToggleIdToSelectedMap['householdIncomes_all'] =
        (!newToggleIdToSelectedMap.hasOwnProperty('householdIncomes_less50') || !newToggleIdToSelectedMap['householdIncomes_less50']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('householdIncomes_50to100') || !newToggleIdToSelectedMap['householdIncomes_50to100']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('householdIncomes_100to150') || !newToggleIdToSelectedMap['householdIncomes_male']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('householdIncomes_150to200') || !newToggleIdToSelectedMap['householdIncomes_150to200']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('householdIncomes_more200') || !newToggleIdToSelectedMap['householdIncomes_more200'])
      setToggleHouseholdIncomeToSelectedMap(newToggleIdToSelectedMap)

      if (newToggleIdToSelectedMap.householdIncomes_less50) householdIncomes.push('less50')
      if (newToggleIdToSelectedMap.householdIncomes_50to100) householdIncomes.push('50to100')
      if (newToggleIdToSelectedMap.householdIncomes_100to150) householdIncomes.push('100to150')
      if (newToggleIdToSelectedMap.householdIncomes_150to200) householdIncomes.push('150to200')
      if (newToggleIdToSelectedMap.householdIncomes_more200) householdIncomes.push('more200')
    }
    setHouseholdIncomes(householdIncomes)
  }

  return <EuiButtonGroup legend='HouseholdIncomes' options={householdIncomesButtons} idToSelectedMap={toggleHouseholdIncomeToSelectedMap} onChange={id => onChangeHouseholdIncome(id)} type='multi' />
}
