import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiDatePicker, EuiDatePickerRange, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingContent, EuiSpacer, EuiSuperSelect } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { Campaign, CampaignByDayAndDimensionReport, useOpsClient } from 'api'

export interface ICampaignTrafficTab {
  campaign?: Campaign
}

export const CampaignTrafficDimensionedTab: React.FC<ICampaignTrafficTab> = props => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [trafficData, setTrafficData] = useState<any>([])
  const [dimensions, setDimensions] = useState<EuiSuperSelectOption<string>[]>([])
  const [dimension, setDimension] = useState<string>('City')
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment())
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const [visibleColumns, setVisibleColumns] = useState(['forDate', 'dimensionValue', 'clicks', 'impressions', 'spend'])
  const [sortingColumns, setSortingColumns] = useState([])
  const opsClient = useOpsClient()

  useEffect(() => {
    if (props.campaign) {
      let d: EuiSuperSelectOption<string>[] = []

      d.push({
        value: 'Region',
        inputDisplay: 'Region'
      })
      d.push({
        value: 'Dma',
        inputDisplay: 'DMA'
      })
      d.push({
        value: 'City',
        inputDisplay: 'Top 20 Cities by day'
      })

      if (props.campaign.campaignType === 'Internet') {
        d.push({
          value: 'Domain',
          inputDisplay: 'Top 20 Domains by day'
        })
      }
      d.push({
        value: 'Audience',
        inputDisplay: 'Audience'
      })
      d.push({
        value: 'BrowserType',
        inputDisplay: 'Browser Type'
      })
      d.push({
        value: 'DesktopOs',
        inputDisplay: 'Desktop OS'
      })
      d.push({
        value: 'Exchange',
        inputDisplay: 'Exchange/Publisher'
      })
      d.push({
        value: 'FrequencyBucket1d',
        inputDisplay: 'Frequency Bucket 1-Day'
      })
      d.push({
        value: 'FrequencyBucket30d',
        inputDisplay: 'Frequency Bucket 30-Day'
      })

      setDimensions(d)
    }
  }, [props.campaign])

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && props.campaign?.campaignId) {
      setIsLoading(true)
      const startDate = start.toDate()
      const endDate = end.toDate()
      opsClient
        ?.getCampaignByDayAndDimensionReport({
          dimension: dimension,
          campaignId: props.campaign?.campaignId,
          startDate: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
          endDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23)
        })
        .then(report => {
          if (isMounted) {
            setTrafficData(
              report.map<CampaignByDayAndDimensionReport>(r => ({
                dimensionValue: r.dimensionValue,
                spend: Math.round(r.spend * 100) / 100,
                clicks: r.clicks,
                forDate: r.forDate,
                impressions: r.impressions
              }))
            )
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    return () => {
      isMounted = false
    }
  }, [dimension, start, end])

  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const OnDimensionChange = (value: string) => {
    setDimension(value)
  }

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId }: any) => {
      return trafficData.hasOwnProperty(rowIndex) && trafficData[rowIndex][columnId] ? trafficData[rowIndex][columnId] : null
    }
  }, [trafficData])

  const columns: EuiDataGridColumn[] = [
    {
      id: 'forDate',
      displayAsText: 'Date',
      schema: 'date',
      initialWidth: 200
    },
    {
      id: 'dimensionValue',
      displayAsText: dimension
    },
    {
      id: 'impressions',
      displayAsText: 'Impressions'
    },
    {
      id: 'clicks',
      displayAsText: 'Clicks'
    },
    {
      id: 'spend',
      displayAsText: 'Spend',
      schema: 'currency'
    }
  ]

  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  return (
    <React.Fragment>
      <EuiSpacer />
      {props.campaign && (
        <React.Fragment>
          <EuiDatePickerRange
            fullWidth={false}
            startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
            endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
          />
          <EuiSpacer />
          <EuiSuperSelect options={dimensions} valueOfSelected={dimension} onChange={value => OnDimensionChange(value)} style={{ borderRadius: 0 }} />
          <EuiSpacer />
          {isLoading ? (
            <EuiLoadingContent />
          ) : (
            <EuiFlexGroup>
              <EuiFlexItem style={{ maxHeight: '600px' }}>
                <EuiDataGrid
                  aria-label='Visits'
                  columns={columns}
                  columnVisibility={{ visibleColumns, setVisibleColumns }}
                  gridStyle={{
                    fontSize: 's'
                  }}
                  rowCount={trafficData?.length ?? 0}
                  renderCellValue={renderCellValue}
                  inMemory={{ level: 'sorting' }}
                  sorting={{ columns: sortingColumns, onSort }}
                  pagination={{
                    ...pagination,
                    pageSizeOptions: [50, 100, 200, 500],
                    onChangeItemsPerPage,
                    onChangePage
                  }}
                  toolbarVisibility={{
                    additionalControls: (
                      <React.Fragment>
                        <CSVLink data={trafficData} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`traffoc.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                          <span className='euiButtonContent euiButtonEmpty__content'>
                            <EuiIcon type='download' />
                            <span className='euiButton__text'>Download Report (CSV)</span>
                          </span>
                        </CSVLink>
                      </React.Fragment>
                    )
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
