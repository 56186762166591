import Konva from 'konva'
import React, { useCallback, useEffect } from 'react'
import { Layer, Rect, Text } from 'react-konva'

import { IAdCreator } from 'components/AdCreation/AdCreators/IAdCreator'
import { FocusArea } from 'components/AdCreation/DisplayMaker'
import { ACImage } from 'components/Basic/ACImage'
import { IsDark } from 'utils/colorUtils'

export const AdCreatorTowerBottom: React.FC<IAdCreator> = props => {
  const backgroundLayerRef = React.useRef<Konva.Layer>(null)
  const imageLayerRef = React.useRef<Konva.Layer>(null)
  const nameLayerRef = React.useRef<Konva.Layer>(null)

  const [baseWidth, setBaseWidth] = React.useState(0)
  const [baseHeight, setBaseHeight] = React.useState(0)

  const colorPalette = props.colorPalette

  useEffect(() => {
    const image = new Image()
    image.src = props.uploadedUrl
    image.onload = () => {
      setBaseWidth(image.width)
      setBaseHeight(image.height)
    }
  }, [props.uploadedUrl])

  const getScaledWidth = useCallback(
    (height: number) => {
      if (!baseHeight || baseHeight <= 0) {
        return 1
      }
      return (baseWidth / baseHeight) * height
    },
    [baseWidth, baseHeight]
  )

  const xOffSet = (() => {
    if (props.contentSpecifications.focusArea === FocusArea.Left) {
      return 0
    } else if (props.contentSpecifications.focusArea === FocusArea.Center) {
      return (-1 * (props.adWidth - getScaledWidth(props.adHeight / 3))) / 2
    } else if (props.contentSpecifications.focusArea === FocusArea.Right) {
      return -1 * (props.adWidth - getScaledWidth(props.adHeight / 3))
    }
    return 0
  })()
  return (
    <React.Fragment>
      <Layer name={'background'} ref={backgroundLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Rect x={0} y={0} width={props.adWidth} height={(2 * props.adHeight) / 3} fill={colorPalette.backgroundColor} />
      </Layer>
      <Layer name={'name'} ref={nameLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Text x={16} y={props.adHeight / 2 / 8} text={props.details.companyName} verticalAlign={'middle'} align={'center'} fontSize={36} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} height={72} width={props.adWidth - 32} fontStyle={'bold'} />
        <Text x={16} y={(2 * props.adHeight) / 3 - 24 - 60 - 36 - 120} text={props.details.headline} fontSize={28} verticalAlign={'middle'} align={'center'} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} height={112} width={props.adWidth - 32} />
        <Rect cornerRadius={24} x={props.adWidth / 8} y={(2 * props.adHeight) / 3 - 24 - 60} width={(3 * props.adWidth) / 4} height={72} fill={colorPalette.buttonColor + 'aa'} />
        <Text align={'center'} verticalAlign={'middle'} fontSize={24} text={props.details.callToAction} x={props.adWidth / 8} y={(2 * props.adHeight) / 3 - 24 - 60 + 4} width={(3 * props.adWidth) / 4} height={68} fill={IsDark(colorPalette.buttonColor) ? '#FFF' : '#000'} />
      </Layer>
      <Layer
        name={'image'}
        ref={imageLayerRef}
        offsetX={(props.xOffSet ?? 0) + xOffSet}
        offsetY={(props.yOffSet ?? 0) - (2 * props.adHeight) / 3}
        scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}
        clip={{
          x: xOffSet,
          y: 0,
          width: props.adWidth,
          height: props.adHeight
        }}>
        <ACImage backgroundImageUrl={props.uploadedUrl} key={'backgroundImage'} height={props.adHeight / 3} width={getScaledWidth(props.adHeight / 3)} />
      </Layer>
    </React.Fragment>
  )
}
