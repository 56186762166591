import React from 'react'

import { EuiIcon, EuiListGroup, EuiSpacer } from '@elastic/eui'

import { SegmentTarget } from 'api'

interface SegmentTargeterProps {
  segments: Array<SegmentTarget>
  onSegmentRemoved(segment: SegmentTarget): void
}

export const SegmentTargeter = ({ segments, onSegmentRemoved }: SegmentTargeterProps) => {
  return (
    <React.Fragment>
      {segments && segments.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s'>
          {segments.map(w => (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 5px' }} key={w.id.toString()}>
              <div>
                <b>{w.name}</b>
                <EuiSpacer size={'s'} />
                <div style={{ paddingLeft: '10px', fontSize: '0.8em' }}>{w.description}</div>
              </div>
              <div style={{ minWidth: '150px', display: 'flex', justifyContent: 'end' }}>
                <EuiIcon type='cross' onClick={() => onSegmentRemoved(w)} />
              </div>
            </div>
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
