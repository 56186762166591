import moment from 'moment'
import React from 'react'

import { EuiComment, EuiFlexGroup, EuiText } from '@elastic/eui'

import { Anomaly } from 'api/entities/Anomaly'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IAnomalyHistoryChart {
  anomaly: Anomaly
}
export const AnomalyHistoryChart: React.FC<IAnomalyHistoryChart> = props => {
  const timelineSortedByDate = [...props.anomaly.timeline].sort((a, b) => {
    return moment(b.createdAt).diff(moment(a.createdAt))
  })

  return (
    <EuiFlexGroup wrap direction={'column'}>
      {timelineSortedByDate.map((item, i) => {
        return (
          <EuiComment key={i} username={item.createdBy} timestamp={item.createdAt && moment.utc(item.createdAt).utc().tz(moment.tz.guess()).format('LLL')}>
            {item.newStatus ? <EuiText size='xs'>Status: {insertSpaces(item.newStatus)}</EuiText> : ''}
            {item.description ? <EuiText size='xs'>Description: {item.description}</EuiText> : ''}
          </EuiComment>
        )
      })}
    </EuiFlexGroup>
  )
}
