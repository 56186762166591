import React, { useRef } from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { ActivatedPlansPage } from 'features/advisorDashboard/ActivatedPlansPage'
import { DefaultSpacerForTabbedContent } from 'features/advisorDashboard/AdvisorDashboardTabs'
import { LiveCampaignsPage } from 'features/advisorDashboard/LiveCampaignsPage'
import { OnboardingPage } from 'features/advisorDashboard/OnboardingPage'
import { AnomalyPage } from 'features/tools/AnomalyPage'

export const useAdvisorDashboardTabs = () => {
  return useRef<EuiTabbedContentTab[]>([
    {
      id: 'onboarding',
      name: 'Onboarding',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <OnboardingPage />
        </React.Fragment>
      )
    },
    {
      id: 'activatedPlans',
      name: 'Activated Plans',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <ActivatedPlansPage />
        </React.Fragment>
      )
    },
    {
      id: 'liveCampaigns',
      name: 'Live Campaigns',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <LiveCampaignsPage />
        </React.Fragment>
      )
    },
    {
      id: 'anomalies',
      name: 'Anomalies',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <AnomalyPage />
        </React.Fragment>
      )
    }
  ])
}
