import moment from 'moment-timezone'
import React, { FC } from 'react'

import { EuiButtonEmpty, EuiInMemoryTable } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { BundleActivation } from 'api/entities/Bundle'
import { useGetBundleActivationsQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import history from 'services/HistoryService'

const AccountManagerBundlesPage: FC = () => {
  const bundleActivationsRequest = useGetBundleActivationsQuery({ ignoreAgencies: true, pastXDays: 30 })
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const bundleActivationData = !bundleActivationsRequest.data
    ? []
    : [...bundleActivationsRequest.data]?.sort((a, b) => {
        if (a.activated && b.activated) {
          return moment(b.activated).valueOf() - moment(a.activated).valueOf()
        } else if (b.activated) {
          return -1
        } else if (a.activated) {
          return 1
        } else {
          return 0
        }
      })

  const bundlePurchasesColumns: Array<EuiBasicTableColumn<BundleActivation>> = [
    {
      name: 'Organization',
      render: (p: BundleActivation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/organizations/${p.organizationId}`)} size='s'>
          {p.organizationName}
        </EuiButtonEmpty>
      )
    },
    {
      name: 'Name',
      field: 'bundleName'
    },
    {
      name: 'Status',
      field: 'bundleStatus'
    },
    {
      name: `Budget`,
      render: (p: BundleActivation) => formatter.format(p.budget),
      width: '150'
    },
    {
      name: `Budget Frequency`,
      field: 'budgetFrequency'
    },
    {
      name: `Purchase Date`,
      render: (p: BundleActivation) => moment.utc(p.activated).utc().tz(moment.tz.guess()).format('L'),
      sortable: (p: BundleActivation) => moment(p.activated).valueOf()
    }
  ]

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiInMemoryTable loading={bundleActivationsRequest.isLoading} items={bundleActivationData} columns={bundlePurchasesColumns} pagination={pagination} />
    </React.Fragment>
  )
}

export default AccountManagerBundlesPage
