import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiIcon, EuiDatePicker, EuiButton, EuiDatePickerRange, EuiPageTemplate, EuiLink } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { useOpsClient } from 'api'

const SignupsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [signups, setSignups] = useState<any>([])
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment())
  const opsClient = useOpsClient()

  function refresh() {
    setIsLoading(true)
    opsClient
      ?.getSignupReport(start.format('yyyy-MM-DD'), end.format('yyyy-MM-DD'))
      .then(c => {
        setSignups(c)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
  }, [opsClient])

  const columns: EuiDataGridColumn[] = [
    {
      id: 'organizationId',
      displayAsText: 'Organization Id'
    },
    {
      id: 'organizationName',
      displayAsText: 'Organization',
      schema: 'orgLink'
    },
    {
      id: 'firstName',
      displayAsText: 'First Name'
    },
    {
      id: 'lastName',
      displayAsText: 'Last Name'
    },
    {
      id: 'email',
      displayAsText: 'Email'
    },
    {
      id: 'phone',
      displayAsText: 'Phone'
    },
    {
      id: 'hasPaymentMethod',
      displayAsText: 'Has Payment Method'
    },
    {
      id: 'signupDate',
      displayAsText: 'Signup Date',
      schema: 'date'
    },
    {
      id: 'firstDashboardTouch',
      displayAsText: 'Dashboard Date',
      schema: 'date'
    },
    {
      id: 'savedDate',
      displayAsText: 'Saved Date',
      schema: 'date'
    },
    {
      id: 'activationDate',
      displayAsText: 'Activation Date',
      schema: 'date'
    },
    {
      id: 'method',
      displayAsText: 'Method'
    },
    {
      id: 'signupVersion',
      displayAsText: 'Signup Version'
    },
    {
      id: 'browserFamily',
      displayAsText: 'Browser'
    },
    {
      id: 'osFamily',
      displayAsText: 'OS'
    },
    {
      id: 'deviceFamily',
      displayAsText: 'Device'
    },
    {
      id: 'screenSize',
      displayAsText: 'Screen Size',
      schema: 'screenSize'
    },
    {
      id: 'businessType',
      displayAsText: 'Business Type'
    },
    {
      id: 'companySize',
      displayAsText: 'Company Size'
    },
    {
      id: 'businessStage',
      displayAsText: 'Business Stage'
    },
    {
      id: 'hasAdvertisingChallenges',
      displayAsText: 'Has Advertising Challenges'
    },
    {
      id: 'referralSource',
      displayAsText: 'Referral Source'
    },
    {
      id: 'clarityUrl',
      displayAsText: 'Signup Clarity',
      schema: 'url'
    },
    {
      id: 'dashboardClarityUrl',
      displayAsText: 'Dashboard Clarity',
      schema: 'url'
    },
    {
      id: 'source',
      displayAsText: 'ACID'
    },
    {
      id: 'initiatedEmailConversation',
      displayAsText: 'Initiated Email Conversation',
      schema: 'date'
    },
    {
      id: 'initiatedTextConversation',
      displayAsText: 'Initiated Text Conversation',
      schema: 'date'
    },
    {
      id: 'initiatedPhoneConversation',
      displayAsText: 'Initiated Phone Conversation',
      schema: 'date'
    }
  ]

  const [visibleColumns, setVisibleColumns] = useState(['organizationName', 'firstName', 'lastName', 'email', 'phone', 'signupDate', 'firstDashboardTouch', 'savedDate', 'activationDate', 'signupVersion', 'browserFamily', 'osFamily', 'deviceFamily', 'screenSize', 'companySize', 'clarityUrl', 'dashboardClarityUrl', 'source'])

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const [sortingColumns, setSortingColumns] = useState([
    {
      id: 'signupDate',
      direction: 'desc' as 'asc' | 'desc'
    }
  ])

  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (signups.hasOwnProperty(rowIndex)) {
        if (typeof signups[rowIndex][columnId] == 'boolean') {
          if (signups[rowIndex][columnId] === undefined || signups[rowIndex][columnId] === null) {
            return ''
          }

          return signups[rowIndex][columnId] ? 'true' : 'false'
        }
        if (typeof signups[rowIndex][columnId] == 'number') {
          return signups[rowIndex][columnId] ? signups[rowIndex][columnId] + '' : ''
        }

        if (schema === 'orgLink') {
          return (
            <EuiLink href={`/customers/organizations/${signups[rowIndex]['organizationId']}/signupDetails`} target='_blank'>
              {signups[rowIndex][columnId]}
            </EuiLink>
          )
        }

        if (schema === 'url') {
          if (!signups[rowIndex][columnId]) {
            return ''
          }

          return <EuiLink href={signups[rowIndex][columnId]} target='_blank' />
        }

        if (schema === 'screenSize') {
          return signups[rowIndex][columnId] ? `${signups[rowIndex][columnId]} ${signups[rowIndex]['screenWidth']}px` : ''
        }
        if (schema === 'date') {
          return signups[rowIndex][columnId] ? moment(signups[rowIndex][columnId]).format('YYYY-MM-DD HH:mm:ss') + '' : ''
        }
        if (typeof signups[rowIndex][columnId] == 'object') {
          return ''
        }
        return signups[rowIndex][columnId] ? signups[rowIndex][columnId] : null
      }
    }
  }, [signups])

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header
        iconType='tableDensityNormal'
        pageTitle='Signups'
        restrictWidth={false}
        bottomBorder={true}
        rightSideItems={[
          <EuiButton id='update' fill={true} onClick={refresh} iconType='refresh' isLoading={isLoading}>
            Update
          </EuiButton>,
          <EuiDatePickerRange
            fullWidth={false}
            startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
            endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
          />
        ]}
      />
      <EuiPageTemplate.Section restrictWidth={false} bottomBorder={false} grow={true}>
        {isLoading && <EuiPageTemplate.EmptyPrompt restrictWidth={false} grow={true} hasShadow={false} color='plain' hasBorder={false} iconType='tableDensityNormal' title={<h2>Loading Signups</h2>} body={<p>Please wait while signups are retrieved.</p>} />}
        {!isLoading && (
          <EuiDataGrid
            aria-label='Signups Report'
            columns={columns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal' }}
            rowCount={signups.length}
            renderCellValue={renderCellValue}
            inMemory={{ level: 'sorting' }}
            sorting={{ columns: sortingColumns, onSort }}
            pagination={{
              ...pagination,
              pageSizeOptions: [50, 100, 200, 500],
              onChangeItemsPerPage,
              onChangePage
            }}
            toolbarVisibility={{
              additionalControls: (
                <React.Fragment>
                  <CSVLink data={signups} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`signups.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                    <span className='euiButtonContent euiButtonEmpty__content'>
                      <EuiIcon type='download' />
                      <span className='euiButton__text'>Download Report (CSV)</span>
                    </span>
                  </CSVLink>
                </React.Fragment>
              )
            }}
          />
        )}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default SignupsPage
