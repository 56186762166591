import React, { useEffect, useState } from 'react'

import { EuiBreadcrumbs, EuiInMemoryTable, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Partner, useOpsClient } from 'api'
import history from 'services/HistoryService'

const PartnersPage: React.FC = () => {
  const [isLoadingPartners, setIsLoadingPartners] = useState(false)
  const [partners, setPartners] = useState<Partner[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    setIsLoadingPartners(true)
    opsClient?.getPartners().then(mi => {
      setPartners(mi)
      setIsLoadingPartners(false)
    })
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<Partner>> = [
    {
      name: 'Name',
      field: 'name',
      sortable: true
    },
    {
      name: 'Short Name',
      field: 'shortName',
      sortable: true
    },
    {
      name: 'Group',
      field: 'group',
      sortable: true
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Data' },
              {
                text: 'Partners',
                href: '/data/partners',
                onClick: () => history.push('/data/partners')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Partners</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiInMemoryTable
              loading={isLoadingPartners}
              items={partners}
              columns={columns}
              sorting={sorting}
              pagination={pagination}
              tableLayout='fixed'
              search={{
                box: {
                  incremental: true
                }
              }}
            />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default PartnersPage
