import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import AuthService, { AuthStatus, UserProfile } from '../services/AuthService'
import { AppThunk } from './store'

export type AppState = {
  status: AuthStatus
  userProfile: UserProfile | null
  isCSuite: boolean
  initialized: boolean
  isGoogleLoaded: boolean
}

const initialState: AppState = {
  status: AuthStatus.Unauthenticated,
  userProfile: null,
  isCSuite: false,
  initialized: false,
  isGoogleLoaded: false
}

export type userAuthenticatedPayload = {
  status: AuthStatus
  userProfile: UserProfile | null
}

export type userUnauthorizedPayload = {
  userProfile: UserProfile | null
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initialized(state: AppState, { payload }: PayloadAction<boolean>) {
      state.initialized = payload
    },
    setGoogleLoaded(state: AppState) {
      state.isGoogleLoaded = true
    },
    userAuthenticating(state: AppState) {
      state.status = AuthStatus.Authenticating
    },
    userAuthenticated(state: AppState, { payload }: PayloadAction<userAuthenticatedPayload>) {
      state.status = payload.status
      state.userProfile = payload.userProfile

      if (payload.userProfile) {
        state.isCSuite = payload.userProfile.roles.includes('adcritter-ops-csuite')
      }
    },
    userUnauthenticated(state: AppState) {
      state.status = AuthStatus.Unauthenticated
    }
  }
})

export const { initialized, setGoogleLoaded, userAuthenticating, userAuthenticated, userUnauthenticated } = appSlice.actions

export const initializeAuthentication = (): AppThunk => async dispatch => {
  const accounts = AuthService.getAccounts()

  if (accounts && accounts.length > 0) {
    try {
      dispatch(userAuthenticating())
      const authResult = await AuthService.requestSilentToken(accounts[0])
      const userProfile = AuthService.getUserProfile(authResult)

      dispatch(userAuthenticated({ userProfile, status: AuthStatus.Authenticated }))
    } catch (error) {
      console.error(error)
      dispatch(userUnauthenticated())
    }
  }

  dispatch(initialized(true))
}

export const signIn = (): AppThunk => async dispatch => {
  dispatch(userAuthenticating())

  try {
    const authResult = await AuthService.signIn()
    const userProfile = AuthService.getUserProfile(authResult)

    dispatch(userAuthenticated({ userProfile, status: AuthStatus.Authenticated }))
  } catch (error) {
    console.error(error)
    dispatch(userUnauthenticated())
  }
}

export default appSlice.reducer
