import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui'
import { EuiConfirmModalProps } from '@elastic/eui/src/components/modal/confirm_modal'

export interface IAcConfirmModal extends EuiConfirmModalProps {
  message: string
}

export const AcConfirmModal: React.FC<IAcConfirmModal> = props => {
  return (
    <EuiOverlayMask>
      <EuiConfirmModal {...props}>
        <p>{props.message}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}
