import React, { useEffect, useState } from 'react'

import { EuiFieldSearch, EuiInputPopover, EuiLoadingContent, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { Zip, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface ZipTargeterProps {
  isInvalid: boolean
  addZip(zip: Zip): void
}

export const ZipFinder = ({ addZip, isInvalid }: ZipTargeterProps) => {
  const opsClient = useOpsClient()
  const [zipSearchTerm, setZipSearchTerm] = useState<string>('')
  const debouncedZipSearchTerm = useDebounce(zipSearchTerm, 100)
  const [searchZipPopoverOpen, setSearchZipPopoverOpen] = useState<boolean>(false)
  const [zips, setZips] = useState<Array<Zip>>([])
  const [filteredZips, setFilteredZips] = useState<Array<Zip>>([])
  const [selectableZips, setSelectableZips] = useState<Array<EuiSelectableOption>>()
  const [isLoadingZips, setIsLoadingZips] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      if (debouncedZipSearchTerm) {
        setIsLoadingZips(true)
        opsClient.getZips(debouncedZipSearchTerm).then(result => {
          if (isMounted) {
            setZips(result)
            setSelectableZips(
              result.map(
                d =>
                  ({
                    label: d.name,
                    key: d.zipId
                  } as EuiSelectableOption)
              )
            )
            setIsLoadingZips(false)
          }
        })
      } else {
        setZips([])
      }
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, debouncedZipSearchTerm])

  const onSearchState = () => {
    setSearchZipPopoverOpen(false)
  }

  const onSearchStateChange = (hint: string) => {
    setZipSearchTerm(hint)
  }

  useEffect(() => {
    if (debouncedZipSearchTerm) {
      setFilteredZips([...zips.filter(d => d.zipId.toLowerCase().includes(debouncedZipSearchTerm.toLowerCase()))])
    } else {
      setFilteredZips([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedZipSearchTerm, zips])

  useEffect(() => {
    if (filteredZips) {
      setSelectableZips(
        filteredZips.map(
          s =>
            ({
              label: s.name,
              key: s.yahooDspId,
              checked: undefined,
              onClick: () => {
                addZip(s)
                setSearchZipPopoverOpen(false)
                setZipSearchTerm('')
              }
            } as EuiSelectableOption)
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredZips])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={zipSearchTerm}
          isLoading={isLoadingZips}
          onChange={v => onSearchStateChange(v.target.value)}
          onFocus={() => setSearchZipPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchState()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchZipPopoverOpen}
      closePopover={() => setSearchZipPopoverOpen(false)}>
      {filteredZips && filteredZips.length > 0 ? (
        <EuiSelectable singleSelection={false} options={selectableZips}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
