import React from 'react'

import { Anomaly, AnomalyType } from 'api/entities/Anomaly'
import { AcFilterGroup } from 'components/ACTools/AcFilterGroup'

export interface IAnomalyFilterGroup {
  anomalies: Anomaly[]
  onFilterClicked?: (anomalyType: AnomalyType) => void
  onResetFilters?: () => void
  activeFilters: AnomalyType[]
}

export const AnomalyFilterGroup: React.FC<IAnomalyFilterGroup> = props => {
  const onFilterClick = (anomalyType: string) => {
    props.onFilterClicked?.(anomalyType as AnomalyType)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }

  return <AcFilterGroup items={props.anomalies} getGroup={(anomaly: Anomaly) => anomaly.anomalyType} activeFilters={props.activeFilters} onFilterClicked={onFilterClick} onResetFilters={onTotalClick} />
}
