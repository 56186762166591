import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { GranularPhrase, OpsClient } from 'api'

export type GranularPhrasesState = {
  isLoadingGranularPhrases: boolean
  granularPhrases: GranularPhrase[]
}

const initialState: GranularPhrasesState = {
  isLoadingGranularPhrases: false,
  granularPhrases: []
}

export const fetchGranularPhrases = createAsyncThunk('data/granularPhrases', async (opsClient: OpsClient) => {
  return opsClient!.getGranularPhrases()
})

const granularPhrasesSlice = createSlice({
  name: 'granularPhrases',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGranularPhrases.pending, state => {
      state.isLoadingGranularPhrases = true
    })
    builder.addCase(fetchGranularPhrases.fulfilled, (state, action) => {
      state.isLoadingGranularPhrases = false
      state.granularPhrases = action.payload || []
    })
    builder.addCase(fetchGranularPhrases.rejected, state => {
      state.isLoadingGranularPhrases = false
      state.granularPhrases = []
    })
  }
})

//export const {} = granularPhrasesSlice.actions

export default granularPhrasesSlice.reducer
