import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiFilePicker, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiForm, EuiLink, EuiPortal, EuiTitle, EuiCallOut, EuiFormRow, EuiSpacer, EuiText } from '@elastic/eui'

import { Campaign, useOpsClient } from 'api'

interface AdCreateBillboardFlyOutParams {
  campaign: Campaign
  onCreated: () => void
}

const CampaignCreateBillboardAdFlyOut: React.FC<AdCreateBillboardFlyOutParams> = ({ campaign, onCreated }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const opsClient = useOpsClient()
  const [uploadedBillboardImg, setUploadedBillboardImg] = useState<string>('')
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [fileType, setFileType] = useState<string>('')
  const [isUploading, setIsUploading] = useState(false)
  const filePickerRemoval = useRef<EuiFilePicker>(null)
  const [hasFile, setHasFile] = useState(false)
  const [hasCorrectFile, setHasCorrectFile] = useState(false)
  const [billboardContainerClient, setBillboardContainerClient] = useState<ContainerClient>()

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  useEffect(() => {
    if (opsClient && campaign) {
      opsClient.apiGetBillboardBuilderUpload(campaign.accountId).then(data => {
        const storageClient = new BlobServiceClient(data.data)
        const containerClient = storageClient.getContainerClient(campaign.accountId)
        setBillboardContainerClient(containerClient)
      })
    }
  }, [opsClient, campaign])

  const delay = (func: any, num: number) => {
    setTimeout(func, num)
  }

  function getMeta(url: any) {
    const img = new Image()
    img.src = url
    img.onload = function () {
      setHeight(img.height)
      setWidth(img.width)
      delay(function () {
        setHasFile(true)
      }, 750)
    }
  }

  const largeRectangle = {
    width: 1400,
    height: 400
  }

  const standardRectangle = {
    width: 840,
    height: 400
  }

  useEffect(() => {
    fileType.toLowerCase()
    if ((fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg') && ((width === largeRectangle.width && height === largeRectangle.height) || (width === standardRectangle.width && height === standardRectangle.height))) {
      setHasCorrectFile(true)
    } else {
      setHasCorrectFile(false)
    }
  }, [fileType, height, width, standardRectangle.width, standardRectangle.height, largeRectangle.width, largeRectangle.height])

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      const blockBlobClient = billboardContainerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      let splitArrayLength = url.split('.').length
      let fileType = url.split('.')[splitArrayLength - 1].toLowerCase()
      setFileType(fileType)
      setIsUploading(true)
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploading(false)
          setUploadedBillboardImg(url)
          getMeta(url)
        })
        .then(() => {
          if (!(fileType === 'png' || fileType === 'jpg' || fileType === 'jpg')) {
            setHasFile(true)
            setUploadedBillboardImg('')
          }
        })
    }
    if (files && files.length < 1) {
      setHeight(0)
      setWidth(0)
      setFileType('')
      setUploadedBillboardImg('')
      setHasFile(false)
    }
  }

  const create = () => {
    if (hasFile) {
      opsClient
        ?.apiUploadBillboardAd({
          campaignId: campaign.campaignId,
          accountId: campaign.accountId,
          height: height,
          width: width,
          uploadedUrl: uploadedBillboardImg,
          mimeType: fileType,
          previewUrl: uploadedBillboardImg
        })
        .then(res => {
          setHeight(0)
          setWidth(0)
          setFileType('')
          setUploadedBillboardImg('')
          setHasFile(false)
          closeFlyout()
          onCreated()
        })
    }
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size='s'>
              <h2>Create Billboard Ad</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiForm component='form'>
              <EuiFormRow label='Billboard' fullWidth>
                <EuiFilePicker ref={filePickerRemoval} id='upload' fullWidth display='large' multiple={false} initialPromptText='Select or drag and drop file here' onChange={onFileChange} isLoading={isUploading} />
              </EuiFormRow>
            </EuiForm>

            {hasFile && !hasCorrectFile && (
              <EuiCallOut title='Your file has not been uploaded because it had these issues: ' iconType='document' color='warning'>
                {!(fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg') && (
                  <p>
                    Your file is not an accepted file type (it's a .{fileType}). Please upload a file that is a <strong>PNG, JPEG or JPG</strong>.
                  </p>
                )}
                {!((width === standardRectangle.width && height === standardRectangle.height) || (width === largeRectangle.width && height === largeRectangle.height)) && (
                  <section>
                    Your file isn't one of the accepted image dimensions (it's {width} x {height}). Please upload a file that is one of the following dimensions:
                    <section style={{ marginLeft: 30 }}>
                      <EuiText size='s'>
                        Large Rectangle: {largeRectangle.width} x {largeRectangle.height} pixels{' '}
                      </EuiText>
                      <EuiText size='s'>
                        Standard Rectangle: {standardRectangle.width} x {standardRectangle.height} pixels{' '}
                      </EuiText>
                    </section>
                  </section>
                )}
                <EuiSpacer />
                <div>
                  <EuiButton color='warning' iconType='trash' onClick={() => filePickerRemoval.current!.removeFiles()}>
                    <h3 style={{ marginBottom: -0.5 }}>Remove file</h3>
                  </EuiButton>
                </div>
              </EuiCallOut>
            )}
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiButton fill onClick={create}>
              Create
            </EuiButton>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showFlyout} color='primary'>
        Create Billboard Ad
      </EuiLink>
      {flyout}
    </React.Fragment>
  )
}

export default CampaignCreateBillboardAdFlyOut
