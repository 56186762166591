import React, { useEffect } from 'react'

import { EuiFieldNumber, EuiFormRow, EuiSelect, EuiSpacer } from '@elastic/eui'

import { BidStrategy, CampaignType, OptimizationType } from 'api'

export interface ICampaignOptimizationControl {
  campaignType: CampaignType
  bidSettings?: BidStrategy
  onBidSettingsChange?: (bidSettings: BidStrategy) => void
}

export const CampaignOptimizationControl: React.FC<ICampaignOptimizationControl> = props => {
  let minCpc = 0
  let minCpm = 0
  let startCpc = 0
  let startCpm = 0

  switch (props.campaignType) {
    case CampaignType.Internet:
      minCpc = 0.8
      minCpm = 0.2
      startCpc = 1.85
      startCpm = 3.5
      break
    case CampaignType.TV:
      minCpm = 15
      startCpm = 30
      break
    case CampaignType.Billboard:
    default:
      break
  }

  useEffect(() => {
    if (props.bidSettings?.optimizationType === OptimizationType.Cpc && (props.bidSettings.optimizationGoal ?? 0) < minCpc) {
      props.onBidSettingsChange?.({ ...props.bidSettings, optimizationGoal: minCpc })
    }
    if (props.bidSettings?.optimizationType === OptimizationType.Cpm && (props.bidSettings.optimizationGoal ?? 0) < minCpm) {
      props.onBidSettingsChange?.({ ...props.bidSettings, optimizationGoal: minCpm })
    }
  }, [props.bidSettings])
  const onOptimizationTypeChange = (optimizationType: OptimizationType) => {
    let startingGoal = 0
    if (optimizationType === OptimizationType.Cpc) {
      startingGoal = startCpc
    } else if (optimizationType === OptimizationType.Cpm) {
      startingGoal = startCpm
    }
    props.onBidSettingsChange?.(props.bidSettings ? { ...props.bidSettings, optimizationType: optimizationType, optimizationGoal: startingGoal } : { optimizationType: optimizationType, optimizationGoal: startingGoal })
  }

  const onOptimizationGoalChange = (optimizationGoal: number) => {
    if (props.bidSettings) {
      props.onBidSettingsChange?.({ ...props.bidSettings, optimizationGoal: optimizationGoal })
    }
  }

  const optimizationOptions = [{ value: OptimizationType.Unset, text: 'Maximum Reach (Default)' }, ...(props.campaignType === CampaignType.Internet ? [{ value: OptimizationType.Cpc, text: 'Manual CPC' }] : []), { value: OptimizationType.Cpm, text: 'Manual CPM' }]

  const GetHelpText = () => {
    switch (props.bidSettings?.optimizationType) {
      case OptimizationType.Cpc:
        return 'Manual CPC is best for generating maximum website traffic with the budget.'
      case OptimizationType.Cpm:
        return 'Manual CPM prioritizes advertising with a specified cost per 1000 impressions.'
      default:
        return 'Maximum Reach (Default) will prioritize the highest quality traffic at the most efficient price. (Recommended)'
    }
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth={false} helpText={GetHelpText()}>
        <EuiSelect id={'optimizationGoal'} options={optimizationOptions} value={props.bidSettings?.optimizationType ?? OptimizationType.Unset} onChange={(e: any) => onOptimizationTypeChange(e.target.value as OptimizationType)} aria-label={'optimization goal'} />
      </EuiFormRow>
      {props.bidSettings?.optimizationType === OptimizationType.Cpc && (
        <React.Fragment>
          <EuiFormRow fullWidth={false}>
            <EuiFieldNumber prepend={'$'} placeholder='Target CPC...' value={props.bidSettings?.optimizationGoal ?? 0} step={0.01} min={minCpc} onChange={e => onOptimizationGoalChange(Number(e.target.value))} />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.bidSettings?.optimizationType === OptimizationType.Cpm && (
        <React.Fragment>
          <EuiFormRow fullWidth={false}>
            <EuiFieldNumber prepend={'$'} placeholder='Target CPM...' value={props.bidSettings?.optimizationGoal ?? 0} step={0.01} min={minCpm} onChange={e => onOptimizationGoalChange(Number(e.target.value))} />
          </EuiFormRow>
        </React.Fragment>
      )}
      <EuiSpacer />
    </React.Fragment>
  )
}
