import { Product, SetSubscriptionProduct } from 'api/entities/Product'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const productsTag = opsApi.enhanceEndpoints({ addTagTypes: ['Products', 'ProductForSubscription'] })

const productsApi = productsTag.injectEndpoints({
  endpoints: build => ({
    getAllProducts: build.query<Product[], void>({
      query: () => `products`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Products' }]
    }),
    getProductForSubscription: build.query<Product, string>({
      query: subscriptionId => `products/subscription/${subscriptionId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, subscriptionId) => [{ type: 'ProductForSubscription', id: subscriptionId }]
    }),
    setProductForSubscription: build.mutation<Product, SetSubscriptionProduct>({
      query: ({ subscriptionId, productId, activateNow, chargeAndActivateNow }) => ({
        url: `products/subscription/${subscriptionId}`,
        method: 'POST',
        body: {
          productId: productId,
          activateNow: activateNow,
          chargeAndActivateNow: chargeAndActivateNow
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { subscriptionId }) => [{ type: 'ProductForSubscription', id: subscriptionId }]
    })
  }),
  overrideExisting: false
})

export const { useGetAllProductsQuery, useGetProductForSubscriptionQuery, useSetProductForSubscriptionMutation } = productsApi
