import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const uploadTags = opsApi.enhanceEndpoints({ addTagTypes: ['UploadAccount'] })

const uploadApi = uploadTags.injectEndpoints({
  endpoints: build => ({
    getBuilderUpload: build.query<string, { accountId: string }>({
      query: ({ accountId }) => `billboards/upload?accountId=${accountId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { accountId }) => [{ type: 'UploadAccount', id: accountId }]
    })
  }),
  overrideExisting: false
})

export const { useGetBuilderUploadQuery } = uploadApi
