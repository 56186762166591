import moment from 'moment/moment'
import React, { useCallback, useEffect } from 'react'

import { EuiBadge, EuiButton, EuiButtonEmpty, EuiDatePicker, EuiFieldNumber, EuiFieldText, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { Bundle } from 'api/entities/Bundle'
import { SalesAgent } from 'api/entities/SalesAgent'
import { useGetAllBundlesQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { CreateCommissionEventRequest, useCreateCommissionEventMutation } from 'api/rtkQueryApi/opsApi/salesApi'
import { AcSearchSelector } from 'components/ACTools/AcSearchSelector'

export interface ICommissionEventCreateModal {
  salesAgent: SalesAgent
  onClose: () => void
}

export const CommissionEventCreateModal: React.FC<ICommissionEventCreateModal> = props => {
  const [commissionEvent, setCommissionEvent] = React.useState<CreateCommissionEventRequest>({
    commissionTriggerName: 'Manually Added',
    salesAgentId: props.salesAgent.id,
    commissionItemId: '',
    eventDate: moment(),
    saleAmount: 0,
    commissionPercentage: 0,
    commissionAmount: 0,
    commissionBonus: 0,
    note: ''
  })
  const getAllBundlesQuery = useGetAllBundlesQuery()

  const [createCommissionEvent, createCommissionEventQuery] = useCreateCommissionEventMutation()
  const [bundleSearchTerm, setBundleSearchTerm] = React.useState<string>('')
  const [filteredBundles, setFilteredBundles] = React.useState<Bundle[]>([])
  const [selectedBundle, setSelectedBundle] = React.useState<Bundle>()

  useEffect(() => {
    if (getAllBundlesQuery.data) {
      setFilteredBundles(getAllBundlesQuery.data.filter(b => b.name && b.name.toLowerCase().includes(bundleSearchTerm.toLowerCase())))
    }
  }, [getAllBundlesQuery.data, bundleSearchTerm])

  useEffect(() => {
    setCommissionEvent({ ...commissionEvent, commissionItemId: selectedBundle?.id ?? '' })
  }, [selectedBundle])

  const onCloseModal = () => {
    props.onClose()
  }

  const onCancel = () => {
    onCloseModal()
  }

  const isValid = useCallback(() => {
    return commissionEvent.commissionPercentage >= 0 && commissionEvent.commissionPercentage <= 1 && commissionEvent.commissionAmount <= Math.abs(commissionEvent.saleAmount)
  }, [commissionEvent.commissionPercentage, commissionEvent.commissionAmount, commissionEvent.saleAmount])

  const saveCommissionEvent = () => {
    if (isValid()) {
      createCommissionEvent(commissionEvent).then(() => {
        props.onClose()
      })
    }
  }

  const onCommissionPercentChange = (percent: number) => {
    setCommissionEvent({ ...commissionEvent, commissionPercentage: percent, commissionAmount: percent * commissionEvent.saleAmount })
  }

  const onSaleAmountChange = (amount: number) => {
    setCommissionEvent({ ...commissionEvent, saleAmount: amount, commissionAmount: amount * commissionEvent.commissionPercentage })
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Create Commission Event</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFormRow>
            <EuiText>
              <p>${commissionEvent.commissionAmount.toLocaleString()} in commission</p>
            </EuiText>
          </EuiFormRow>
          <EuiFormRow label={'Date'}>
            <EuiDatePicker
              selected={commissionEvent.eventDate}
              onChange={date => {
                if (date) {
                  setCommissionEvent({ ...commissionEvent, eventDate: date })
                }
              }}
            />
          </EuiFormRow>
          <EuiFormRow label={'Name'}>
            <EuiFieldText value={commissionEvent.commissionTriggerName} onChange={e => setCommissionEvent({ ...commissionEvent, commissionTriggerName: e.target.value })} />
          </EuiFormRow>
          <EuiFormRow label={`Bundle`}>
            <React.Fragment>
              <AcSearchSelector searchTerm={bundleSearchTerm} onSearchChange={setBundleSearchTerm} onItemClicked={item => setSelectedBundle(filteredBundles.find(b => b.id === item.key))} items={filteredBundles.map(b => ({ key: b.id, label: b.name, description: `$${b.price}` })) ?? []} />
              <EuiSpacer size='xs' />
              {selectedBundle && (
                <EuiBadge color='success'>
                  {selectedBundle.name} (${selectedBundle.price.toLocaleString()})
                </EuiBadge>
              )}
            </React.Fragment>
          </EuiFormRow>
          <EuiFormRow label={'Sale Amount'}>
            <EuiFieldNumber value={commissionEvent.saleAmount} onChange={e => onSaleAmountChange(parseFloat(e.target.value))} />
          </EuiFormRow>
          <EuiFormRow label={'Commission Ratio'}>
            <EuiFieldNumber value={commissionEvent.commissionPercentage} onChange={e => onCommissionPercentChange(parseFloat(e.target.value))} />
          </EuiFormRow>
          <EuiFormRow label={'Commission Bonus'}>
            <EuiFieldNumber value={commissionEvent.commissionBonus} onChange={e => setCommissionEvent({ ...commissionEvent, commissionBonus: parseFloat(e.target.value) })} />
          </EuiFormRow>
          <EuiFormRow label={'Note'}>
            <EuiTextArea value={commissionEvent.note} onChange={e => setCommissionEvent({ ...commissionEvent, note: e.target.value })} />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={onCancel} disabled={createCommissionEventQuery.isLoading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={saveCommissionEvent} fill disabled={!isValid()} isLoading={createCommissionEventQuery.isLoading}>
            Save
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
