import React from 'react'

import { EuiButtonEmpty, EuiButtonIcon, EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiPopover } from '@elastic/eui'

import { AcDomain, NetworkType } from 'api/entities/Domains'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IShowcaseDomainItem {
  acDomain: AcDomain
  onUpdateDomain: (domain: AcDomain) => void
  onRemoveDomain: (domain: AcDomain) => void
  onDomainSelected?: () => void
  isSelected?: boolean
  isEven?: boolean
}

export const ShowcaseDomainItem: React.FC<IShowcaseDomainItem> = props => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onUpdateDomain = (updated: AcDomain) => {
    props.onUpdateDomain(updated)
  }

  const onRemoveDomain = (domain: AcDomain) => {
    props.onRemoveDomain(domain)
  }

  const onNetworkTypeClick = (value: string) => {
    onUpdateDomain({ ...props.acDomain, networkType: value as NetworkType })
  }

  const onActionsClick = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const onPopoverClose = () => {
    setIsPopoverOpen(false)
  }

  const onDomainSelected = () => {
    if (props.onDomainSelected) {
      props.onDomainSelected()
    }
  }

  const domain = props.acDomain
  return (
    <EuiPanel style={{ backgroundColor: props.isEven ? '#EFF7FF' : undefined, paddingTop: 8, paddingBottom: 8 }} hasShadow={false} hasBorder={false} onMouseLeave={onPopoverClose}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiCheckbox
            id={domain.domain}
            label={domain.domain}
            checked={props.isSelected}
            onChange={() => {
              onDomainSelected()
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiPopover
            button={
              <EuiButtonEmpty size='xs' color='primary' onMouseEnter={onActionsClick} onClick={() => {}} aria-label={insertSpaces(domain.networkType)} title={insertSpaces(domain.networkType)}>
                {insertSpaces(domain.networkType)}
              </EuiButtonEmpty>
            }
            isOpen={isPopoverOpen}
            closePopover={onPopoverClose}
            anchorPosition='leftCenter'
            display='block'>
            <EuiFlexGroup wrap gutterSize={'s'}>
              {Object.values(NetworkType).map(value => (
                <EuiFlexItem grow={false} key={value}>
                  <EuiButtonEmpty style={{ border: 'solid 1px #0061a6', backgroundColor: value == domain.networkType ? '#ADDDFF' : undefined }} size='xs' color='primary' onClick={() => onNetworkTypeClick(value)} aria-label={value} title={insertSpaces(value)}>
                    {insertSpaces(value)}
                  </EuiButtonEmpty>
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiPopover>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon iconType={'trash'} color={'danger'} onClick={() => onRemoveDomain(domain)} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
