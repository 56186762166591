import axios, { AxiosInstance } from 'axios'

import { AadUser } from 'api'
import config from 'app/config'

import AuthService from '../services/AuthService'

interface GraphCall<T> {
  value: T
}

export class GraphClient {
  private apiClient: AxiosInstance
  constructor() {
    this.apiClient = axios.create({
      baseURL: `${config.graph.BASEURL}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }

  async getToken() {
    const accounts = AuthService.getAccounts()

    if (accounts && accounts.length > 0) {
      try {
        const authResult = await AuthService.requestSilentTokenGraph(accounts[0])

        return authResult.accessToken
      } catch (error) {
        console.error(error)
      }
    }
  }

  async getProfilePicture() {
    const token = await this.getToken()

    const response = await this.apiClient.get('me/photo/$value', {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${token}` }
    })

    window.URL = window.URL || window.webkitURL
    return response.data ? window.URL?.createObjectURL(response.data) : ''
  }

  async getAdConsultants() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<GraphCall<AadUser[]>>('groups/1f9507d3-ac9e-4b77-9623-8bd0e783b2c1/members', { headers: { Authorization: `Bearer ${token}` } })

    return data.value
  }

  async getCampaignAdvisors() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<GraphCall<AadUser[]>>('groups/a1b60488-8641-4eb2-bc7a-446f07e9c2c4/members', { headers: { Authorization: `Bearer ${token}` } })

    return data.value
  }

  async getAccountManagers() {
    const token = await this.getToken()
    const { data } = await this.apiClient.get<GraphCall<AadUser[]>>('groups/af0dece3-f4ec-46d6-a08c-64cbecb2da65/members', { headers: { Authorization: `Bearer ${token}` } })

    return data.value
  }
}
