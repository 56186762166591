import React, { useEffect } from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { DefaultSpacerForTabbedContent } from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import { DomainManagementPage } from 'features/opsManagerDashboard/DomainManagementPage'
import { OpsOverviewPage } from 'features/opsManagerDashboard/OpsOverviewPage'

export const useOpsManagerTabs = () => {
  const [tabs, setTabs] = React.useState<EuiTabbedContentTab[]>([])
  useEffect(() => {
    setTabs([
      {
        id: 'ops-overview',
        name: `Overview`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <OpsOverviewPage />
          </React.Fragment>
        )
      },
      {
        id: 'showcase',
        name: `Showcase`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <DomainManagementPage />
          </React.Fragment>
        )
      }
    ])
  }, [])

  return tabs
}
