import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiFormRow, EuiHealth, EuiSwitch } from '@elastic/eui'

import { Permission, PermissionType } from 'api'
import { useGetOrganizationQuery, useSetOrganizationPermissionsMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { RootState } from 'app/rootReducer'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export interface IOrganizationPermissionsSection {
  organizationId: string
}

export const OrganizationPermissionsSection: React.FC<IOrganizationPermissionsSection> = props => {
  const getOrganizationQuery = useGetOrganizationQuery(props.organizationId)
  const [modifyOrganizationPermissions] = useSetOrganizationPermissionsMutation()
  const { userProfile } = useSelector((state: RootState) => state.app)

  const canEdit = userProfile ? userProfile?.roles.includes('adcritter-ops-csuite') || userProfile?.roles.includes('adcritter-ops-customersuccessmanager') : false

  const [enableAudienceUpload, setEnableAudienceUpload] = useState<boolean>(false)
  const [debouncedEnableAudienceUpload, setDebouncedEnableAudienceUpload] = useState<boolean>()

  useEffect(() => {
    if (getOrganizationQuery.data && getOrganizationQuery.data.permissions) {
      setEnableAudienceUpload(getOrganizationQuery.data.permissions.some(p => p.permissionType === PermissionType.UploadAudience))
    }
  }, [getOrganizationQuery.data])

  useDebounceEffect(
    1000,
    () => {
      if (debouncedEnableAudienceUpload === undefined) {
        return
      }

      const permissions: Permission[] = []
      if (debouncedEnableAudienceUpload) {
        permissions.push({ permissionType: PermissionType.UploadAudience })
      }

      modifyOrganizationPermissions({
        organizationId: props.organizationId,
        permissions: permissions
      })
    },
    [debouncedEnableAudienceUpload]
  )

  const OnEnableAudienceUploadChange = () => {
    const newValue = !enableAudienceUpload
    setEnableAudienceUpload(newValue)
    setDebouncedEnableAudienceUpload(newValue)
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>{canEdit ? <EuiSwitch label={'Allow Audience Upload'} checked={enableAudienceUpload} onChange={OnEnableAudienceUploadChange} /> : enableAudienceUpload ? <EuiHealth color={'success'}>Allow Audience Upload</EuiHealth> : <React.Fragment />}</EuiFormRow>
    </React.Fragment>
  )
}
