import React, { useEffect } from 'react'

import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiLoadingSpinner, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Organization } from 'api'
import { Anomaly, AnomalyStatus, AnomalyType } from 'api/entities/Anomaly'
import { useEscalateAnomalyMutation, useGetOrganizationAnomaliesQuery, useReactivateAnomalyMutation, useResolveAnomalyMutation } from 'api/rtkQueryApi/opsApi/anomalyApi'
import { AnomalyFilterGroup } from 'components/Anomaly/AnomalyFilterGroup'
import { AnomalyItem } from 'components/Anomaly/AnomalyItem'
import { CampaignAnomalyDetails } from 'components/Anomaly/CampaignAnomalyDetails'

export interface IOrganizationAnomaliesTab {
  organization: Organization
}

export const OrganizationAnomaliesTab: React.FC<IOrganizationAnomaliesTab> = props => {
  const anomalyQuery = useGetOrganizationAnomaliesQuery(props.organization.organizationId)
  const [resolveAnomaly, resolveAnomalyQuery] = useResolveAnomalyMutation()
  const [escalateAnomaly, escalateAnomalyQuery] = useEscalateAnomalyMutation()
  const [reactivateAnomaly, reactivateAnomalyQuery] = useReactivateAnomalyMutation()
  const [selectedAnomaly, setSelectedAnomaly] = React.useState<Anomaly>()
  const [activeFilters, setActiveFilters] = React.useState<AnomalyType[]>([])
  const [filteredAnomalies, setFilteredAnomalies] = React.useState<Anomaly[]>([])

  useEffect(() => {
    if (!anomalyQuery.data || anomalyQuery.data.length === 0) {
      setFilteredAnomalies([])
      return
    }

    if (anomalyQuery.data && anomalyQuery.data.length > 0 && selectedAnomaly) {
      setSelectedAnomaly(anomalyQuery.data.find(a => a.id === selectedAnomaly.id) ?? undefined)
    }

    setFilteredAnomalies(filterAnomalies(anomalyQuery.data))
  }, [anomalyQuery.data])

  useEffect(() => {
    if (anomalyQuery.data) {
      setFilteredAnomalies(filterAnomalies(anomalyQuery.data))
    }
  }, [activeFilters])

  const filterAnomalies = (anomalies: Anomaly[]) => {
    if (activeFilters.length === 0) {
      return anomalies
    }

    return anomalies.filter(a => activeFilters.includes(a.anomalyType))
  }

  const onAnomalySelected = (anomaly: Anomaly) => {
    setSelectedAnomaly(anomaly)
  }

  const onResolveAnomaly = (anomaly: Anomaly, details: string) => {
    resolveAnomaly({ anomalyId: anomaly.id, resolutionReason: details })
  }

  const onEscalateAnomaly = (anomaly: Anomaly, details: string) => {
    escalateAnomaly({ anomalyId: anomaly.id, escalationReason: details })
  }

  const onReactivateAnomaly = (anomaly: Anomaly, details: string) => {
    reactivateAnomaly({ anomalyId: anomaly.id, reactivationReason: details })
  }

  const onFilterClicked = (filter: AnomalyType) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter(f => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const onResetFilters = () => {
    setActiveFilters([])
  }

  return (
    <React.Fragment>
      <EuiTitle>
        <h2>Organization Anomalies ({anomalyQuery.data?.filter(a => a.status === AnomalyStatus.Escalated || a.status === AnomalyStatus.ActiveIssue).length})</h2>
      </EuiTitle>
      <EuiSpacer />
      {anomalyQuery.data && (
        <EuiFormRow fullWidth>
          <AnomalyFilterGroup anomalies={anomalyQuery.data ?? []} activeFilters={activeFilters} onFilterClicked={onFilterClicked} onResetFilters={onResetFilters} />
        </EuiFormRow>
      )}
      <EuiHorizontalRule />
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiPanel hasBorder={false} hasShadow={false} style={{ maxHeight: 800, overflow: 'auto' }}>
            <EuiFlexGroup direction='column'>
              {anomalyQuery.isLoading && (
                <EuiFlexItem>
                  <EuiLoadingSpinner />
                </EuiFlexItem>
              )}
              {!anomalyQuery.isLoading &&
                filteredAnomalies.map(anomaly => (
                  <EuiFlexItem key={anomaly.id}>
                    <AnomalyItem showStatus anomaly={anomaly} isSelected={selectedAnomaly?.id === anomaly.id} onSelected={() => onAnomalySelected(anomaly)} />
                  </EuiFlexItem>
                ))}
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          {!anomalyQuery.isLoading && anomalyQuery.data && filteredAnomalies.length === 0 ? (
            <React.Fragment>
              <EuiTitle>
                <h3>There are no anomalies</h3>
              </EuiTitle>
            </React.Fragment>
          ) : (
            <React.Fragment>{!selectedAnomaly ? <EuiEmptyPrompt title={<h2>{props.organization.organizationName} Anomalies</h2>} /> : <CampaignAnomalyDetails showStatus hideOrganization anomaly={selectedAnomaly} onEscalateClicked={onEscalateAnomaly} onResolveClicked={onResolveAnomaly} isSubmitting={escalateAnomalyQuery.isLoading || resolveAnomalyQuery.isLoading || reactivateAnomalyQuery.isLoading} onReActivateClicked={onReactivateAnomaly} />}</React.Fragment>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
