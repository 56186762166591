import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiButtonIcon, EuiCallOut, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiSelect, EuiSpacer, EuiSwitch, EuiText, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { useOpsClient, Billboard, UpdateBillboardDetails } from 'api'

interface BillboardDetailsTabParams {
  billboardId: string
}

interface FormValues {
  name: string
  kind: string
  enabled: boolean
  quality: string
  onlyForOrganizationIds: string[]
}

const BillboardDetailsTab: React.FC<BillboardDetailsTabParams> = ({ billboardId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [billboard, setBillboard] = useState<Billboard>()
  const opsClient = useOpsClient()
  const [approvedOrgId, setApprovedOrgId] = useState(true)
  const [initial, setInitial] = useState<FormValues>({
    name: '',
    kind: 'Large1400x400',
    enabled: true,
    quality: 'Normal',
    onlyForOrganizationIds: []
  })

  const refresh = () => {
    setIsLoading(true)
    opsClient!.getBillboard(billboardId).then(result => {
      setInitial({
        name: result.name,
        enabled: result.enabled,
        kind: result.kind,
        quality: result.quality,
        onlyForOrganizationIds: result.onlyForOrganizationIds
      })
      setBillboard(result)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsClient, billboardId])

  useEffect(() => {
    if (initial?.onlyForOrganizationIds) setApprovedOrgId(initial.onlyForOrganizationIds.some(e => e.length !== 36))
  }, [initial])

  const billboardSchema = Yup.object().shape({
    name: Yup.string().required('Please add a name here'),
    onlyForOrganizationIds: Yup.array().of(Yup.string().min(36))
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateBillboardDetails = {
      name: values.name,
      kind: values.kind,
      quality: values.quality,
      enabled: values.enabled,
      onlyForOrganizationIds: values.onlyForOrganizationIds
    }
    opsClient?.updateBillboardDetails(billboardId, update).then(result => {
      setBillboard(result)
      setSubmitting(false)
    })
  }

  const kinds: EuiSelectOption[] = [{ value: ' Large1400x400', text: ' Large1400x400' }]

  const qualities: EuiSelectOption[] = [
    { value: 'Normal', text: 'Normal' },
    { value: 'VeryGood', text: 'Very Good' },
    { value: 'Awesome', text: 'Awesome' }
  ]

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {billboard && (
        <EuiFlexGroup>
          <EuiFlexItem>
            <Formik initialValues={initial} enableReinitialize validationSchema={billboardSchema} onSubmit={doSubmit}>
              {props => (
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Enabled'>
                    <EuiSwitch name='enabled' label='' checked={props.values.enabled} onChange={() => props.setFieldValue('enabled', !props.values.enabled)} />
                  </EuiFormRow>
                  <EuiFormRow label='Type'>
                    <EuiText size='s'>{billboard.type}</EuiText>
                  </EuiFormRow>
                  <EuiFormRow label='Status'>
                    <EuiText size='s'>{billboard.status}</EuiText>
                  </EuiFormRow>
                  <EuiFormRow label='Production Quality' isInvalid={!!props.errors.quality} error={props.errors.quality}>
                    <EuiSelect name='quality' isInvalid={!!props.errors.quality} options={qualities} value={props.values.quality} onChange={e => props.setFieldValue('quality', e.target.value)} />
                  </EuiFormRow>
                  <EuiFormRow label='Size' isInvalid={!!props.errors.kind} error={props.errors.kind}>
                    <EuiSelect name='kind' isInvalid={!!props.errors.kind} options={kinds} value={props.values.kind} onChange={e => props.setFieldValue('kind', e.target.value)} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiText size='xs'>
                    <strong>Map To Organization(s):</strong>
                  </EuiText>
                  <FieldArray
                    name='onlyForOrganizationIds'
                    render={arrayHelpers => (
                      <React.Fragment>
                        {props.values.onlyForOrganizationIds &&
                          props.values.onlyForOrganizationIds.length > 0 &&
                          props.values.onlyForOrganizationIds.map((text, index) => (
                            <EuiFlexGroup gutterSize='xs' key={index} style={{ marginTop: 5, marginBottom: 5 }}>
                              <EuiFlexItem grow={false}>
                                <EuiFlexGroup alignItems='center'>
                                  <EuiFlexItem grow={false}>
                                    <EuiToolTip position='top' content='Remove this Organization'>
                                      <EuiButtonIcon
                                        id='remove'
                                        iconType='cross'
                                        aria-label='remove'
                                        color='warning'
                                        onClick={() => {
                                          arrayHelpers.remove(index)
                                          if (!index) {
                                            setApprovedOrgId(true)
                                          }
                                        }}
                                      />
                                    </EuiToolTip>
                                    <input type='hidden' name={`texts[${index}].id`} value={`texts[${index}].id`} />
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiFlexItem>
                              <EuiFlexItem>
                                <EuiFormRow label={index === 0 ? 'Organization ID' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `onlyForOrganizationIds[${index}]`) && !!getIn(props.errors, `onlyForOrganizationIds[${index}]`)} error={getIn(props.errors, `onlyForOrganizationIds[${index}]`)}>
                                  <EuiFieldText fullWidth name={`onlyForOrganizationIds[${index}]`} value={getIn(props.values, `onlyForOrganizationIds[${index}]`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `onlyForOrganizationIds[${index}]`) && !!getIn(props.errors, `onlyForOrganizationIds[${index}]`)} />
                                </EuiFormRow>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          ))}
                        {approvedOrgId && props.values.onlyForOrganizationIds && props.values.onlyForOrganizationIds.length > 0 && <EuiCallOut color='warning' iconType='alert' title='Please add valid Organization ID(s), or remove all invalid organization IDs' />}
                        <EuiSpacer size='s' />
                        <EuiButtonEmpty
                          id='addText'
                          size='s'
                          onClick={() => {
                            arrayHelpers.push('ID')
                          }}
                          iconType='listAdd'>
                          Add {props.values.onlyForOrganizationIds.length === 0 ? 'New' : 'Another'} Organization
                        </EuiButtonEmpty>
                      </React.Fragment>
                    )}
                  />

                  <EuiSpacer />

                  <EuiFlexGroup gutterSize='s'>
                    <EuiFlexItem grow={false}>
                      <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={approvedOrgId}>
                        Save
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='success' iconType='refresh' onClick={refresh} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiForm>
              )}
            </Formik>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </React.Fragment>
  )
}

export default BillboardDetailsTab
