import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiSelect, EuiSpacer, EuiSwitch, EuiTitle, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { ApiError, Campaign, SmartTargeterAreaType, SmartTargeting, State, useOpsClient } from 'api'
import Competitors from 'components/Competitors'
import WalkInAddresses from 'components/WalkInAddresses'

interface SmartTargetingTabParams {
  campaign: Campaign
}

const SmartTargetingTab: React.FC<SmartTargetingTabParams> = ({ campaign }) => {
  const [isLoadingStates, setIsLoadingStates] = useState(false)
  const [states, setStates] = useState<State[]>([])
  const [openTargetAddress, setOpenTargetAddress] = useState(true)
  const [getStateOnly, setGetStateOnly] = useState(false)
  const opsClient = useOpsClient()
  const [initialValues, setInitialValues] = useState<SmartTargeting>({
    targetArea: SmartTargeterAreaType.Mile5,
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    walkInAddresses: [],
    walkInTargeting: false,
    walkInUsePrimary: true,
    competitors: [],
    competitorTargeting: false
  })

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      opsClient.getStates().then(result => {
        if (isMounted) {
          setStates(result)
          setIsLoadingStates(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient])

  useEffect(() => {
    if (campaign && campaign.smartTargeting) {
      setInitialValues(campaign.smartTargeting)
      const targetArea = campaign.smartTargeting.targetArea
      setOpenTargetAddress(targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50)
      setGetStateOnly(targetArea === SmartTargeterAreaType.State)
    }
  }, [campaign])

  const targetAreaOptions: Array<EuiSelectOption> = [
    {
      value: SmartTargeterAreaType.Mile5,
      text: 'up to 5 miles away from address'
    },
    {
      value: SmartTargeterAreaType.Mile20,
      text: 'up to 20 miles away from address'
    },
    {
      value: SmartTargeterAreaType.Mile50,
      text: 'up to 50 miles away from address'
    },
    {
      value: SmartTargeterAreaType.State,
      text: `Statewide`
    },
    {
      value: SmartTargeterAreaType.Country,
      text: 'Entire USA'
    }
  ]

  const standardSchema = Yup.object().shape({
    targetArea: Yup.string().required('Please select the targeted area'),
    streetAddress: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your Street Address')
      }),
    city: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your City')
      }),
    state: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your State')
      }),
    zip: Yup.string()
      .nullable()
      .when('targetArea', {
        is: (targetArea: string) => targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50,
        then: Yup.string().required('Please Enter your Zip')
      }),
    walkInTargeting: Yup.boolean().required(),
    walkInUsePrimary: Yup.boolean().required(),
    walkInAddresses: Yup.array().when(['walkInTargeting', 'walkInUsePrimary'], {
      is: (walkInTargeting: boolean, walkInUsePrimary: boolean) => walkInTargeting && !walkInUsePrimary,
      then: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            streetAddress: Yup.string().required('Please enter a street address'),
            city: Yup.string().required('Please enter a city'),
            state: Yup.string().required('Please select a state'),
            zip: Yup.string().required('Please enter a walk-in zip')
          })
        )
    }),
    competitorTargeting: Yup.boolean().required(),
    competitors: Yup.array().when('competitorTargeting', {
      is: true,
      then: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            name: Yup.string().max(64).required('Please enter a name'),
            streetAddress: Yup.string().max(120).required('Please enter a street address'),
            city: Yup.string().max(150).required('Please enter a city'),
            state: Yup.string().required('Please select a state'),
            zip: Yup.string().max(15).required('Please enter a zip')
          })
        ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().max(64),
          streetAddress: Yup.string().max(120),
          city: Yup.string().max(150),
          state: Yup.string(),
          zip: Yup.string().max(15)
        })
      )
    }),
    specialities: Yup.array().of(Yup.string())
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: standardSchema,
    onSubmit: (values: SmartTargeting) => {
      opsClient
        ?.apiPutCampaignStandardTargeting(campaign.campaignId, values)
        .then(() => {
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const onTargetAreaChanged = (targetArea: string) => {
    formik.setFieldValue('targetArea', targetArea, true)
    setOpenTargetAddress(targetArea === SmartTargeterAreaType.Mile5 || targetArea === SmartTargeterAreaType.Mile20 || targetArea === SmartTargeterAreaType.Mile50)
    setGetStateOnly(targetArea === SmartTargeterAreaType.State)
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <FormikProvider value={formik}>
        <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
          <React.Fragment>
            <EuiFormRow label='Target Area' fullWidth isInvalid={formik.touched.targetArea && !!formik.errors.targetArea} error={formik.errors.targetArea}>
              <EuiSelect name='targetArea' options={targetAreaOptions} value={formik.values.targetArea} onChange={e => onTargetAreaChanged(e.target.value)} fullWidth isInvalid={formik.touched.targetArea && !!formik.errors.targetArea} hasNoInitialSelection={true} />
            </EuiFormRow>

            {openTargetAddress && (
              <React.Fragment>
                <EuiSpacer size='m' />
                <EuiFormRow label='Street Address' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} error={formik.errors.streetAddress}>
                  <EuiFieldText name='streetAddress' value={formik.values.streetAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.streetAddress && !!formik.errors.streetAddress} />
                </EuiFormRow>
                <EuiSpacer size='s' />
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow label='City' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} error={formik.errors.city}>
                      <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='' fullWidth isInvalid={formik.touched.city && !!formik.errors.city} />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                      <EuiSelect
                        name='state'
                        hasNoInitialSelection={formik.values.state === ''}
                        isLoading={isLoadingStates}
                        options={states.map(
                          s =>
                            ({
                              value: s.code,
                              label: s.name,
                              text: s.name
                            } as EuiSelectOption)
                        )}
                        value={formik.values.state}
                        onChange={value => formik.setFieldValue('state', value.target.value, true)}
                        fullWidth
                        isInvalid={formik.touched.state && !!formik.errors.state}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label='Zip' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} error={formik.errors.zip}>
                      <EuiFieldText name='zip' value={formik.values.zip} onChange={formik.handleChange} placeholder='' fullWidth isInvalid={formik.touched.zip && !!formik.errors.zip} />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </React.Fragment>
            )}
            {getStateOnly && (
              <React.Fragment>
                <EuiSpacer size='m' />
                <EuiFormRow label='State' fullWidth isInvalid={formik.touched.state && !!formik.errors.state} error={formik.errors.state}>
                  <EuiSelect
                    name='state'
                    isLoading={isLoadingStates}
                    options={states.map(
                      s =>
                        ({
                          value: s.code,
                          label: s.name,
                          text: s.name
                        } as EuiSelectOption)
                    )}
                    value={formik.values.state}
                    onChange={value => formik.setFieldValue('state', value, true)}
                    onBlur={formik.handleBlur}
                    hasNoInitialSelection={formik.values.state === ''}
                    fullWidth
                    isInvalid={formik.touched.state && !!formik.errors.state}
                  />
                </EuiFormRow>
              </React.Fragment>
            )}
            <EuiSpacer />
            <EuiTitle size='xs'>
              <h6>
                <EuiSwitch label='' checked={formik.values.walkInTargeting} onChange={e => formik.setFieldValue('walkInTargeting', e.target.checked, true)} />
                Walk-in customer targeting?
              </h6>
            </EuiTitle>
            <EuiSpacer size='s' />
            <div hidden={!formik.values.walkInTargeting} style={{ paddingLeft: 24 }}>
              <EuiSpacer size='s' />
              <EuiCheckbox id={htmlIdGenerator()()} label='Target the primary business address?' checked={formik.values.walkInUsePrimary} onChange={e => (formik.values.walkInUsePrimary = e.target.checked)} />
              <EuiSpacer size='s' />
              <WalkInAddresses formik={formik} />
            </div>
            <EuiSpacer />
            <EuiTitle size='xs'>
              <h6>
                <EuiSwitch label='' checked={formik.values.competitorTargeting} onChange={e => formik.setFieldValue('competitorTargeting', e.target.checked, true)} />
                Competitor location targeting?
              </h6>
            </EuiTitle>
            <EuiSpacer size='s' />
            <div hidden={!formik.values.competitorTargeting} style={{ paddingLeft: 24 }}>
              <Competitors formik={formik} />
            </div>
          </React.Fragment>
          <EuiSpacer />
          <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
            Save
          </EuiButton>
        </EuiForm>
      </FormikProvider>
    </React.Fragment>
  )
}

export default SmartTargetingTab
