import React, { ChangeEvent, useEffect, useState } from 'react'

import { EuiButton, EuiCheckbox, EuiFlyoutBody, EuiFlyoutHeader, EuiFlyout, EuiFormRow, EuiSelect, EuiTitle } from '@elastic/eui'

import { Product } from 'api/entities/Product'
import { useGetAllProductsQuery } from 'api/rtkQueryApi/opsApi/productsApi'

export interface IOrganizationSetSubscriptionPanel {
  subscriptionProductId: string
  onSaveClick?: (setSubscriptionProduct: { selectedProductId: string; activateNow: boolean; activateAndBillNow: boolean }) => void
  onCancelClick?: () => void
  isLoading?: boolean
}

export const OrganizationSetSubscriptionPanel: React.FC<IOrganizationSetSubscriptionPanel> = props => {
  const productsRequest = useGetAllProductsQuery()
  const [productOptions, setProductOptions] = useState<Product[]>([])
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [activateNow, setActivateNow] = useState<boolean>(false)
  const [activateAndBillNow, setActivateAndBillNow] = useState<boolean>(true)

  useEffect(() => {
    if (productOptions.length > 0) {
      setSelectedProduct(productOptions.find(o => o.id == props.subscriptionProductId))
    }
  }, [props.subscriptionProductId, productOptions])

  useEffect(() => {
    if (productsRequest?.data && !productsRequest.isLoading && !productsRequest.isFetching) {
      setProductOptions(productsRequest.data)
    }
  }, [productsRequest.data, productsRequest.isLoading, productsRequest.isFetching])

  const onProductSelected = (e: ChangeEvent<HTMLSelectElement>) => {
    const product = productOptions.find(o => o.id == e.target.value)
    if (product) {
      setSelectedProduct(product)
    }
  }

  const frequencyOptions = productOptions.map(option => ({ value: option.id, text: `${option.name}: $${option.intervalPrice} every ${option.intervalUnitFrequency} ${option.intervalUnit}s` }))

  const onClose = () => {
    if (props.onCancelClick) {
      props.onCancelClick()
    }
  }

  const onSave = () => {
    if (props.onSaveClick && selectedProduct) {
      props.onSaveClick({ selectedProductId: selectedProduct.id, activateNow: activateNow, activateAndBillNow: activateAndBillNow })
    }
  }

  return (
    <EuiFlyout onClose={onClose}>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size='m'>
          <h2>Set Organization Subscription</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow label={'Subscription Type'} helpText={`Organization will be billed $${selectedProduct?.intervalPrice ?? 0} every ${selectedProduct?.intervalUnitFrequency} ${selectedProduct?.intervalUnit}s`}>
          <EuiSelect options={frequencyOptions} value={selectedProduct?.id} onChange={onProductSelected} />
        </EuiFormRow>
        <EuiFormRow helpText={activateNow ? 'The selected subscription will begin now, without billing the organization. Recurring payments will still be charged.' : undefined}>
          <EuiCheckbox
            id={'activateNowCB'}
            onChange={() => {
              setActivateNow(true)
              setActivateAndBillNow(false)
            }}
            checked={activateNow}
            label={'Activate Now (No Billing)'}
          />
        </EuiFormRow>
        <EuiFormRow helpText={activateAndBillNow ? 'Organization will be billed now and the subscription will begin' : undefined}>
          <EuiCheckbox
            id={'activateAndBillNowCB'}
            onChange={() => {
              setActivateAndBillNow(true)
              setActivateNow(false)
            }}
            checked={activateAndBillNow}
            label={'Activate and Bill Now'}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton onClick={onSave} isLoading={props.isLoading} isDisabled={!activateNow && !activateAndBillNow}>
            Save
          </EuiButton>
        </EuiFormRow>
      </EuiFlyoutBody>
    </EuiFlyout>
  )
}
