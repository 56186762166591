import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBetaBadge, EuiFilterButton, EuiFilterGroup, EuiFlexGroup, EuiFlexItem, EuiHeaderBreadcrumbs, EuiLink, EuiPageTemplate, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'

import { CampaignPerformance } from 'api/entities/PerformanceDashboard'
import { useGetPerformanceDashboardCampaignsQuery } from 'api/rtkQueryApi/opsApi/performanceDashboardApi'
import { RootState } from 'app/rootReducer'
import { currencyStyle, percentStyle } from 'utils/styles.'

import localStoreManagerService from '../../services/LocalStoreManagerService'
import { ProblemBadge } from './ProblemBadge'
import { TodayHealth } from './TodayHealth'

const CampaignTab: React.FC = () => {
  const { data, isLoading, isFetching } = useGetPerformanceDashboardCampaignsQuery()
  const [results, setResults] = useState<CampaignPerformance[]>()
  const { userProfile } = useSelector((state: RootState) => state.app)

  const [isNotDeliveringFilterOn, setIsNotDeliveringFilterOn] = useState(true)
  const [isSlowPacingFilterOn, setIsSlowPacingFilterOn] = useState(true)
  const [isNoTrafficTodayFilterOn, setIsNoTrafficTodayFilterOn] = useState(true)
  const [isSegmentMismatchedFilterOn, setIsSegmentMismatchedFilterOn] = useState(true)
  const [isBidderErrorFilterOn, setIsBidderErrorFilterOn] = useState(true)

  const [notDeliveringCount, setNotDeliveringCount] = useState(0)
  const [slowPacingCount, setSlowPacingCount] = useState(0)
  const [noTrafficCount, setNoTrafficCount] = useState(0)
  const [segmentMismatchedCount, setSegmentMismatchedCount] = useState(0)
  const [bidderErrorCount, setBidderErrorCount] = useState(0)

  const [clickedIds, setClickedIds] = useState<string[]>(localStoreManagerService.getDataObject<string[]>('clickedCampaigns') ?? [])

  useEffect(() => {
    let lastClicked = localStoreManagerService.getData('lastCampaigns')

    if (lastClicked) {
      let d = moment(lastClicked)
      if (d.isBefore(moment().utc().date())) {
        localStoreManagerService.deleteData('clickedCampaigns')
        setClickedIds([])
      }
    }
  }, [])

  useEffect(() => {
    localStoreManagerService.savePermanentData('clickedCampaigns', clickedIds)
    localStoreManagerService.savePermanentData('lastCampaigns', moment().utc().format('l'))
  }, [clickedIds])

  const toggleNotDeliveringFilter = () => {
    setIsNotDeliveringFilterOn(!isNotDeliveringFilterOn)
  }

  const toggleSlowPacingFilter = () => {
    setIsSlowPacingFilterOn(!isSlowPacingFilterOn)
  }

  const toggleNoTrafficTodayFilter = () => {
    setIsNoTrafficTodayFilterOn(!isNoTrafficTodayFilterOn)
  }

  const toggleSegmentMismatchedFilter = () => {
    setIsSegmentMismatchedFilterOn(!isSegmentMismatchedFilterOn)
  }

  const toggleBidderErrorFilter = () => {
    setIsBidderErrorFilterOn(!isBidderErrorFilterOn)
  }

  useEffect(() => {
    if (data) {
      const notDelivering = data.filter(d => d.problems.includes('Not Delivering')).length
      setNotDeliveringCount(notDelivering)

      const slowPacing = data.filter(d => d.problems.includes('Slow Pacing')).length
      setSlowPacingCount(slowPacing)

      const noTraffic = data.filter(d => d.problems.includes('No Traffic Today')).length
      setNoTrafficCount(noTraffic)

      const segmentMismatched = data.filter(d => d.problems.includes('Segment Mismatched')).length
      setSegmentMismatchedCount(segmentMismatched)

      const bidderError = data.filter(d => d.problems.includes('Bidder Error')).length
      setBidderErrorCount(bidderError)

      if ((!isNotDeliveringFilterOn || notDelivering === 0) && (!isSlowPacingFilterOn || slowPacing === 0) && (!isNoTrafficTodayFilterOn || noTraffic === 0) && (!isSegmentMismatchedFilterOn || segmentMismatched === 0) && (!isBidderErrorFilterOn || bidderError === 0)) {
        setResults(data)
      } else {
        setResults(data.filter(d => (isNotDeliveringFilterOn && d.problems.includes('Not Delivering')) || (isSlowPacingFilterOn && d.problems.includes('Slow Pacing')) || (isNoTrafficTodayFilterOn && d.problems.includes('No Traffic Today')) || (isSegmentMismatchedFilterOn && d.problems.includes('Segment Mismatched')) || (isBidderErrorFilterOn && d.problems.includes('Bidder Error'))))
      }
    }
  }, [data, isNotDeliveringFilterOn, isSlowPacingFilterOn, isNoTrafficTodayFilterOn, isSegmentMismatchedFilterOn, isBidderErrorFilterOn])

  const gotoCampaign = (campaignId: string) => {
    setClickedIds([campaignId, ...clickedIds])
    window.open(`/customers/campaigns/${campaignId}`, '_blank')
  }

  return (
    <EuiPageTemplate.Section restrictWidth={false} bottomBorder={false} grow={true}>
      {!isLoading && !isFetching && results && (
        <React.Fragment>
          <EuiFilterGroup>
            <EuiFilterButton hasActiveFilters={isNotDeliveringFilterOn} onClick={toggleNotDeliveringFilter} numActiveFilters={notDeliveringCount} isDisabled={notDeliveringCount === 0} color='danger'>
              Not Delivering
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isSlowPacingFilterOn} onClick={toggleSlowPacingFilter} numActiveFilters={slowPacingCount} isDisabled={slowPacingCount === 0} color='warning'>
              Slow Pacing
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isNoTrafficTodayFilterOn} onClick={toggleNoTrafficTodayFilter} numActiveFilters={noTrafficCount} isDisabled={noTrafficCount === 0} color='primary'>
              No Traffic Today
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isSegmentMismatchedFilterOn} onClick={toggleSegmentMismatchedFilter} numActiveFilters={segmentMismatchedCount} isDisabled={segmentMismatchedCount === 0} color='primary'>
              Segment Mismatched
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isBidderErrorFilterOn} onClick={toggleBidderErrorFilter} numActiveFilters={bidderErrorCount} isDisabled={bidderErrorCount === 0} color='danger'>
              Bidder Error
            </EuiFilterButton>
          </EuiFilterGroup>
          <EuiSpacer />
          <EuiText size='xs'>
            {results.map(campaign => (
              <React.Fragment>
                <EuiPanel hasShadow={false} hasBorder={false} color='subdued' paddingSize='xs'>
                  <EuiFlexGroup alignItems='center'>
                    <EuiFlexItem>
                      <div>
                        {campaign.problems.map(p => (
                          <ProblemBadge problem={p} />
                        ))}
                      </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiHeaderBreadcrumbs breadcrumbs={[{ text: campaign.organizationName }, { text: campaign.accountName }, { text: campaign.campaignName }]} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiSpacer size='s' />
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false} style={{ width: 50 }}>
                      {campaign.campaignType}
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} style={{ width: 50 }}>
                      {campaign.budget.toLocaleString('en-US', currencyStyle)}
                    </EuiFlexItem>
                    <EuiFlexItem grow={true}>
                      <EuiPanel hasShadow={false} hasBorder={false} color={clickedIds.some(id => campaign.campaignId === id) ? 'success' : 'primary'} paddingSize='none'>
                        <EuiFlexGroup alignItems='center'>
                          <EuiFlexItem grow={false} style={{ width: 150 }}>
                            <EuiBetaBadge label={campaign.campaignStatus} size='s' color='hollow' style={{ marginTop: 3, marginLeft: 3 }} />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false} style={{ width: 100 }}>
                            {campaign.dsp} {campaign.dspStatus}
                          </EuiFlexItem>
                          <EuiFlexItem>
                            {moment(campaign.flightStartDate!).utc().format('l')}-{moment(campaign.flightEndDate!).utc().format('l')}
                          </EuiFlexItem>
                          <EuiFlexItem>
                            {campaign.flightSpend.toLocaleString('en-US', currencyStyle)} / {campaign.flightBudget.toLocaleString('en-US', currencyStyle)}
                          </EuiFlexItem>
                          <EuiFlexItem>
                            {campaign.flightPacing.toLocaleString('en-US', percentStyle)} pacing {campaign.previousFlightPacing > 0 && <React.Fragment>({campaign.previousFlightPacing.toLocaleString('en-US', percentStyle)} previous)</React.Fragment>}
                          </EuiFlexItem>
                          <EuiFlexItem grow={false} style={{ width: 100 }}>
                            {userProfile?.roles.includes('adcritter-ops-csuite') && campaign.boost && <React.Fragment>{campaign.boost.toLocaleString('en-US', currencyStyle)}</React.Fragment>}
                          </EuiFlexItem>
                          <EuiFlexItem grow={false} style={{ width: 20 }}>
                            <TodayHealth campaign={campaign} />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false} style={{ width: 50 }}>
                            <EuiLink onClick={() => gotoCampaign(campaign.campaignId)}>view</EuiLink>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPanel>
                <EuiSpacer size='xs' />
              </React.Fragment>
            ))}
          </EuiText>
        </React.Fragment>
      )}
      {(isLoading || isFetching) && <EuiPageTemplate.EmptyPrompt restrictWidth={false} grow={true} hasShadow={false} hasBorder={false} iconType='advancedSettingsApp' title={<h2>Loading Campaign Performance</h2>} body={<p>Please wait while numbers are retrieved and calculated.</p>} />}
    </EuiPageTemplate.Section>
  )
}
export default CampaignTab
