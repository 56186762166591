import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiComboBox, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiPortal, EuiSpacer, EuiSuperSelect, EuiTitle } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { BusinessTypeCategory, BusinessTypeCategoryGroup, useOpsClient } from 'api'
import history from 'services/HistoryService'

interface FormValues {
  name: string
}

const BusinessTypeCategoryCreateFlyOut: React.FC = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [isLoadingBusinessTypeCategories, setIsLoadingBusinessTypeCategories] = useState(false)
  const [isLoadingBusinessTypeCategoryGroups, setIsLoadingBusinessTypeCategoryGroups] = useState(false)
  const [businessTypeCategories, setBusinessTypeCategories] = useState<BusinessTypeCategory[]>([])
  const [businessTypeCategoryGroups, setBusinessTypeCategoryGroups] = useState<BusinessTypeCategoryGroup[]>([])
  const [parents, setParents] = useState<EuiComboBoxOptionOption[]>()
  const [chosenParents, setChosenParents] = useState<EuiComboBoxOptionOption[]>([])
  const [groups, setGroups] = useState<EuiSuperSelectOption<string>[]>([])
  const [chosenGroup, setChosenGroup] = useState<string>('b9b16aa2-67ed-42d6-91ee-910b1e962613')
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      setIsLoadingBusinessTypeCategories(true)
      setIsLoadingBusinessTypeCategoryGroups(true)
      opsClient.getBusinessTypeCategories().then(result => {
        setBusinessTypeCategories(result)
        setIsLoadingBusinessTypeCategories(false)
      })
      opsClient.getBusinessTypeCategoryGroups().then(result => {
        setBusinessTypeCategoryGroups(result)
        setIsLoadingBusinessTypeCategoryGroups(false)
      })
    }
  }, [opsClient])

  useEffect(() => {
    if (businessTypeCategories) {
      const p = businessTypeCategories.map(
        btc =>
          ({
            key: btc.id,
            label: btc.name
          } as EuiComboBoxOptionOption)
      )
      setParents(p)
    }
  }, [businessTypeCategories])

  useEffect(() => {
    if (businessTypeCategoryGroups) {
      const p = businessTypeCategoryGroups.map(
        btcg =>
          ({
            value: btcg.id,
            inputDisplay: btcg.name
          } as EuiSuperSelectOption<string>)
      )
      setGroups(p)
    }
  }, [businessTypeCategoryGroups])

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const closeFlyout = () => setIsFlyoutVisible(false)

  const businessCategoryTypeSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the business category type name')
  })

  const selectParent = (options: EuiComboBoxOptionOption[]) => {
    setChosenParents(options)
  }

  const selectGroup = (groupId: string) => {
    setChosenGroup(groupId)
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.createBusinessTypeCategory({
        name: values.name,
        parentId: chosenParents.length > 0 ? chosenParents[0].key! : null,
        groupId: chosenGroup
      })
      .then(result => {
        setSubmitting(false)
        setIsFlyoutVisible(false)
        history.push(`/data/businessTypeCategories/${result.id}`)
      })
  }
  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={{ name: '' }} validationSchema={businessCategoryTypeSchema} onSubmit={doSubmit}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Business Type Category</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Group'>
                    <EuiSuperSelect options={groups} onChange={selectGroup} valueOfSelected={chosenGroup} isLoading={isLoadingBusinessTypeCategoryGroups} />
                  </EuiFormRow>
                  <EuiFormRow label='Parent'>
                    <EuiComboBox singleSelection={{ asPlainText: true }} options={parents} selectedOptions={chosenParents} onChange={selectParent} isLoading={isLoadingBusinessTypeCategories} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiButton id='createBusinessTypeCategoryFlyOut' onClick={showFlyout} fill>
        Create
      </EuiButton>
      {flyout}
    </React.Fragment>
  )
}

export default BusinessTypeCategoryCreateFlyOut
