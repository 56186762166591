import React, { FC, useEffect, useState } from 'react'

import { EuiTabbedContent } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useTemplatesTabs } from 'features/templates/useTemplatesTabs'
import history from 'services/HistoryService'

export interface TemplatesTabsProps {
  currentTab: string
}

export const TemplatesTabs: FC<TemplatesTabsProps> = ({ currentTab }) => {
  const tabs = useTemplatesTabs()
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs.filter(t => t.id === currentTab)[0])

  useEffect(() => {
    setSelectedTab(tabs.filter(t => t.id === currentTab)[0])
  }, [currentTab])

  const onTabClick = (selectedTab: EuiTabbedContentTab) => {
    history.push(`/templates/${selectedTab.id}`)
  }

  return <EuiTabbedContent tabs={tabs} selectedTab={selectedTab} onTabClick={onTabClick} />
}
