import { AgencyDetails, AgencySalesStage, SalesContactedMethodType } from 'api/entities/Organization'
import { defaultTransformFn } from 'api/rtkQueryApi/opsApi/opsApi'
import { organizationsApi } from 'api/rtkQueryApi/opsApi/organizationsApi'

export interface SetSalesAgencyDetailsContactedMutation {
  salesAgencyDetailsId: string
  contactMethodType: SalesContactedMethodType
}

export interface SetSalesAgencyDetailsStageMutation {
  salesAgencyDetailsId: string
  salesStage: AgencySalesStage
  description: string
}

const agencyDetailsTags = organizationsApi.enhanceEndpoints({ addTagTypes: ['AgencyDetails'] })

const salesAgencyDetailsApi = agencyDetailsTags.injectEndpoints({
  endpoints: build => ({
    setSalesAgencyDetailsContacted: build.mutation<AgencyDetails, SetSalesAgencyDetailsContactedMutation>({
      query: request => {
        return {
          url: `salesAgencyDetails/${request.salesAgencyDetailsId}/contacted`,
          method: 'POST',
          body: {
            contactedMethodType: request.contactMethodType
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'AgencyDetails', id: request.salesAgencyDetailsId }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: result?.organizationId }, { type: 'AgencyOrganizations' }, { type: 'AgencyOrganization', id: result?.organizationId }]
    }),
    setSalesAgencyDetailsStage: build.mutation<AgencyDetails, SetSalesAgencyDetailsStageMutation>({
      query: request => {
        return {
          url: `salesAgencyDetails/${request.salesAgencyDetailsId}/salesStage`,
          method: 'POST',
          body: {
            salesStage: request.salesStage,
            description: request.description
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'AgencyDetails', id: request.salesAgencyDetailsId }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: result?.organizationId }, { type: 'AgencyOrganizations' }, { type: 'AgencyOrganization', id: result?.organizationId }]
    }),
    inviteToCohort: build.mutation<AgencyDetails, { salesAgencyDetailsId: string; cohortId: string }>({
      query: request => {
        return {
          url: `salesAgencyDetails/${request.salesAgencyDetailsId}/inviteToCohort`,
          method: 'POST',
          body: {
            cohortId: request.cohortId
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'AgencyDetails', id: request.salesAgencyDetailsId }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: result?.organizationId }, { type: 'AgencyOrganizations' }]
    }),
    removeFromCohort: build.mutation<AgencyDetails, { salesAgencyDetailsId: string }>({
      query: request => {
        return {
          url: `salesAgencyDetails/${request.salesAgencyDetailsId}/removeFromCohort`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, salesAgencyDetailsId) => [{ type: 'AgencyDetails', salesAgencyDetailsId }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: result?.organizationId }, { type: 'AgencyOrganizations' }]
    }),
    organizationInviteToCohort: build.mutation<AgencyDetails, { organizationId: string; cohortId: string }>({
      query: request => {
        return {
          url: `organizations/${request.organizationId}/inviteToCohort`,
          method: 'POST',
          body: {
            cohortId: request.cohortId
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'AgencyDetails', id: result?.id }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: request.organizationId }]
    })
  }),
  overrideExisting: false
})

export const { useSetSalesAgencyDetailsContactedMutation, useSetSalesAgencyDetailsStageMutation, useInviteToCohortMutation, useRemoveFromCohortMutation, useOrganizationInviteToCohortMutation } = salesAgencyDetailsApi
