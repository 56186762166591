import {Formik, FormikHelpers} from 'formik'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'

import {
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty, EuiButtonIcon,
  EuiCallOut,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiLoadingContent,
  EuiPortal,
  EuiRadioGroup,
  EuiSelect,
  EuiSpacer,
  EuiSwitch,
  EuiTitle, EuiToolTip
} from '@elastic/eui'
import {EuiBasicTableColumn} from '@elastic/eui/src/components/basic_table/basic_table'

import {Organization, useOpsClient} from 'api'
import {
  CreateInvoiceRequest,
  Invoice,
  InvoiceStatus,
  InvoiceType,
  OrderActiveStatus
} from 'api/entities/Invoice'
import {useGetCampaignsQuery} from 'api/rtkQueryApi/opsApi/campaignsApi'
import {useGetPaymentMethodCardsQuery} from 'api/rtkQueryApi/opsApi/paymentMethodsApi'
import InvoiceFlyout from 'components/Invoices/InvoiceFlyout'

import {useLazyGetOrganizationInvoicesQuery} from 'api/rtkQueryApi/opsApi/invoicesApi'
import {InvoiceSplitterModal} from 'components/Invoices/InvoiceSplitterModal'
import {InvoiceRefundModal} from "components/Invoices/InvoiceRefundModal";

interface OrganizationInvoicesTabParams {
  organization: Organization
}

interface FormValues {
  title: string
  description: string
  amount: number
}

const OrganizationInvoicesTab: React.FC<OrganizationInvoicesTabParams> = ({ organization }) => {
  const opsClient = useOpsClient()
  const [triggerGetInvoices, getInvoicesResult] = useLazyGetOrganizationInvoicesQuery()
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
  const campaignsRequest = useGetCampaignsQuery({ organizationId: organization.organizationId })
  const { data: paymentMethods } = useGetPaymentMethodCardsQuery(organization.organizationId)
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>()
  const [chargeOrganization, setChargeOrganization] = useState(false)
  const [invoiceType, setInvoiceType] = useState<InvoiceType>(InvoiceType.Campaign)
  const [showPaymentSplitter, setShowPaymentSplitter] = useState(false)
  const [isInvoiceOpen, setIsInvoiceOpen] = useState<boolean>(false)
  const [isCreateInvoiceOpen, setIsCreateInvoiceOpen] = useState<boolean>(false)
  const [paymentMethodId, setPaymentMethodId] = useState<string>('')
  const [showInvoiceRefundModal, setShowInvoiceRefundModal] = useState(false)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const getAllInvoices = useCallback(() => {
    triggerGetInvoices(organization.organizationId)
  }, [opsClient, organization])

  useEffect(() => {
    if (!campaignsRequest.isFetching && !campaignsRequest.isLoading && campaignsRequest.data && campaignsRequest.data.length > 0) {
      setSelectedCampaignId(campaignsRequest.data[0].campaignId)
    }
  }, [campaignsRequest.data, campaignsRequest.isFetching, campaignsRequest.isLoading])

  useEffect(() => {
    getAllInvoices()
  }, [getAllInvoices])

  const onSplitInvoiceClick = (invoice: Invoice) => {
    setShowPaymentSplitter(true)
    setSelectedInvoice(invoice)
  }

  const columns: Array<EuiBasicTableColumn<Invoice>> = [
    {
      name: 'Date',
      field: 'created',
      render: (item: string) => moment(item).format('DD MMM yyyy')
    },
    {
      name: 'Number',
      field: 'number'
    },
    {
      name: 'Amount',
      field: 'amount',
      render: (item: number) => formatter.format(item)
    },
    {
      name: 'Refund Amount',
      render: (item: Invoice) => (item.status === InvoiceStatus.Refunded || item.status === InvoiceStatus.PartiallyRefunded ? formatter.format(item.refundAmount) : null)
    },
    {
      name: 'Status',
      field: 'status'
    },
    {
      name: 'Actions',
      render: (item: Invoice) =>
        <EuiFlexGroup gutterSize={'xs'}>
          {item.status === InvoiceStatus.PaymentFailed && !item.lines.some(l => l.order.orderActiveStatus === OrderActiveStatus.Inactive) &&
          <EuiFlexItem grow={false}>
              <EuiToolTip
                  position="top"
                  content="Split Payments"
              >
                  <EuiButtonIcon
                      iconType={'logstashIf'}
                      onClick={() => {
                        onSplitInvoiceClick(item)
                      }}
                  />
              </EuiToolTip>
          </EuiFlexItem>
          }
          {(item.status === InvoiceStatus.Paid || item.status === InvoiceStatus.PartiallyRefunded) && item.amountPaid > 0 &&
          <EuiFlexItem grow={false}>
              <EuiToolTip
                  position="top"
                  content="Refund"
              >
                  <EuiButtonIcon
                      iconType={'payment'}
                      onClick={() => {
                        setSelectedInvoice(item)
                        setShowInvoiceRefundModal(true)
                      }}
                  />
              </EuiToolTip>
          </EuiFlexItem>
          }
          <EuiFlexItem grow={false}>
            <EuiToolTip
              position="top"
              content="View"
            >
              <EuiButtonIcon
                iconType={'inspect'}
                onClick={() => {
                  setSelectedInvoice(item)
                  setIsInvoiceOpen(true)
                }}
              />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
    },
    {
      name: 'Will Retry',
      field: 'shouldRetry'
    }
  ]

  const onClose = () => {
    setIsInvoiceOpen(false)
    setSelectedInvoice(undefined)
  }

  const onCloseCreate = () => {
    setIsCreateInvoiceOpen(false)
  }

  const onCreateInvoice = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient!
      .apiCreateInvoice({
        organizationId: organization.organizationId,
        amount: values.amount,
        title: values.title,
        description: values.description,
        campaignId: invoiceType === InvoiceType.Campaign ? selectedCampaignId : undefined,
        invoiceType: invoiceType,
        dontCharge: !chargeOrganization,
        stripeSetupIntentId: paymentMethodId
      } as CreateInvoiceRequest)
      .then(() => {
        setSubmitting(false)
        onCloseCreate()
        getAllInvoices()
      })
  }

  const onCreateInvoiceSchema = Yup.object().shape({
    title: Yup.string().max(128).required('Please enter a title'),
    description: Yup.string().max(128).required('Please enter a description'),
    amount: Yup.number().min(5).required('Please enter a description')
  })
  const initialValues = {
    title: '',
    description: '',
    amount: 0,
    campaignId: ''
  } as FormValues

  const subscriptionTypeOptions = [
    {
      id: InvoiceType.Campaign,
      label: InvoiceType.Campaign
    },
    {
      id: InvoiceType.Subscription,
      label: InvoiceType.Subscription
    }
  ]

  let flyoutCreateInvoice
  if (isCreateInvoiceOpen) {
    flyoutCreateInvoice = (
      <EuiPortal>
        <Formik initialValues={initialValues} validationSchema={onCreateInvoiceSchema} onSubmit={onCreateInvoice}>
          {props => (
            <EuiFlyout onClose={onCloseCreate} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Invoice</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Type'>
                    <EuiRadioGroup
                      options={subscriptionTypeOptions}
                      idSelected={invoiceType}
                      onChange={id => {
                        setInvoiceType(id as InvoiceType)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Title' isInvalid={!!props.errors.title} error={props.errors.title}>
                    <EuiFieldText name='title' value={props.values.title} isInvalid={!!props.errors.title} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Description' isInvalid={!!props.errors.description} error={props.errors.description}>
                    <EuiFieldText name='description' value={props.values.description} isInvalid={!!props.errors.description} onChange={props.handleChange} onBlur={props.handleBlur} />
                  </EuiFormRow>
                  <EuiFormRow label='Amount' isInvalid={!!props.errors.amount} error={props.errors.amount}>
                    <EuiFieldNumber name='amount' min={0} max={10000} value={props.values.amount} isInvalid={!!props.errors.amount} onChange={props.handleChange} onBlur={props.handleBlur} prepend={'$'} />
                  </EuiFormRow>
                  <EuiSpacer />
                  {invoiceType == InvoiceType.Campaign && (
                    <React.Fragment>
                      <EuiFormRow label='Campaign'>
                        <EuiSelect options={campaignsRequest.data ? campaignsRequest.data.map(c => ({ value: c.campaignId, text: `${c.campaignName} ($${c.budget})` })) : []} />
                      </EuiFormRow>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  <EuiFormRow label='Select your payment method' fullWidth>
                    <EuiRadioGroup
                      options={
                        paymentMethods?.map(pm => ({
                          id: pm.id,
                          label: `Your ${pm.brand} card ending xxxx${pm.last4}, expiring ${pm.expiryMonth.toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false
                          })}/${pm.expiryYear}`
                        })) ?? []
                      }
                      onChange={id => setPaymentMethodId(id)}
                      idSelected={paymentMethodId}
                    />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow>
                    <EuiSwitch
                      label={'Charge Organization Now'}
                      checked={chargeOrganization}
                      onChange={() => {
                        setChargeOrganization(!chargeOrganization)
                      }}
                    />
                  </EuiFormRow>
                  <EuiSpacer />
                  {chargeOrganization ? (
                    <EuiCallOut title={`Organization will be charged $${props.values.amount} now.`} color='warning' iconType='alert'>
                      <EuiButton type='submit' fill disabled={props.isSubmitting}>
                        Create
                      </EuiButton>
                    </EuiCallOut>
                  ) : (
                    <EuiButton type='submit' fill disabled={props.isSubmitting}>
                      Create
                    </EuiButton>
                  )}
                </EuiForm>
              </EuiFlyoutBody>
              <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty id='close' iconType='cross' onClick={onCloseCreate} flush='left'>
                      Close
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlyoutFooter>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  let content

  if (getInvoicesResult.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <React.Fragment>
        {showPaymentSplitter && selectedInvoice && (
          <InvoiceSplitterModal
            invoice={selectedInvoice}
            organizationId={organization.organizationId}
            onCloseClicked={() => {
              setShowPaymentSplitter(false)
              setSelectedInvoice(undefined)
            }}
          />
        )}
        {showInvoiceRefundModal && selectedInvoice && (
          <InvoiceRefundModal organizationId={organization.organizationId} invoice={selectedInvoice} onCloseClicked={() => {
            setShowInvoiceRefundModal(false)
            setSelectedInvoice(undefined)
          }}/>
        )}
        <EuiButton onClick={() => setIsCreateInvoiceOpen(true)} iconType={'payment'}>
          Create Invoice
        </EuiButton>
        <EuiBasicTable tableLayout={"fixed"} loading={getInvoicesResult.isLoading} items={getInvoicesResult.data ? [...getInvoicesResult.data].sort((a: Invoice, b: Invoice) => (moment(b.created).unix() ?? 0) - (moment(a.created).unix() ?? 0)) : []} columns={columns} />
        {selectedInvoice && isInvoiceOpen && <InvoiceFlyout selectedInvoice={selectedInvoice} onFlyoutClose={onClose} />}
        <React.Fragment>{flyoutCreateInvoice}</React.Fragment>
      </React.Fragment>
    )
  }

  return <div style={{ marginTop: '10px' }}>{content}</div>
}

export default OrganizationInvoicesTab
