import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiBreadcrumbs, EuiIcon, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { BusinessTypeCategory, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import BusinessTypeCategoryCreateFlyOut from './BusinessTypeCategoryCreateFlyOut'
import { fetchBusinessTypeCategories } from './businessTypeCategoriesSlice'

const BusinessTypeCategoriesPage: React.FC = () => {
  const dispatch = useDispatch()
  const opsClient = useOpsClient()
  const { isLoadingBusinessTypeCategories, businessTypeCategories } = useSelector((state: RootState) => state.businessTypeCategories)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canCreate, setCanCreate] = useState(false)

  useEffect(() => {
    if (opsClient) {
      dispatch(fetchBusinessTypeCategories(opsClient))
    }
  }, [dispatch, opsClient])

  useEffect(() => {
    if (userProfile) {
      setCanCreate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  const columns: Array<EuiBasicTableColumn<BusinessTypeCategory>> = [
    {
      name: `Name`,
      render: (c: BusinessTypeCategory) => <EuiLink onClick={() => history.push(`/data/businessTypeCategories/${c.id}`)}>{c.name}</EuiLink>
    },
    {
      name: 'Parent',
      field: 'parentName'
    },
    {
      name: 'Is Deleted',
      width: '150',
      render: (c: BusinessTypeCategory) => <React.Fragment>{c.isDeleted && <EuiIcon type='checkInCircleFilled' />}</React.Fragment>
    },
    {
      name: 'Is Hidden',
      width: '150',
      render: (c: BusinessTypeCategory) => <React.Fragment>{c.isHidden && <EuiIcon type='checkInCircleFilled' />}</React.Fragment>
    }
  ]

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Data' },
              {
                text: 'Business Type Categories',
                href: '/data/businessTypeCategories',
                onClick: () => history.push('/data/businessTypeCategories')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Business Type Categories</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
            <EuiPageContentHeaderSection>{canCreate && <BusinessTypeCategoryCreateFlyOut />}</EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiInMemoryTable
              loading={isLoadingBusinessTypeCategories}
              items={businessTypeCategories}
              columns={columns}
              pagination={pagination}
              tableLayout='fixed'
              search={{
                box: {
                  incremental: true
                }
              }}
            />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default BusinessTypeCategoriesPage
