import React from 'react'

import { AgencyOrganization, AgencySalesStage, AgencySalesStagePrecedence } from 'api'
import { AcFilterGroup } from 'components/ACTools/AcFilterGroup'

export interface IAgencySalesStageFilterGroup {
  agencies: AgencyOrganization[]
  onFilterClicked?: (salesStage: AgencySalesStage) => void
  onResetFilters?: () => void
  activeFilters: AgencySalesStage[]
}

export const AgencySalesStageFilterGroup: React.FC<IAgencySalesStageFilterGroup> = props => {
  const onFilterClick = (salesStage: string) => {
    props.onFilterClicked?.(salesStage as AgencySalesStage)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }

  const orderedAgencies = props.agencies.sort((a, b) => (AgencySalesStagePrecedence.indexOf(a.agencyDetails.agencyStageDetails?.salesStage ?? AgencySalesStage.T) > AgencySalesStagePrecedence.indexOf(b.agencyDetails.agencyStageDetails?.salesStage ?? AgencySalesStage.T) ? 1 : -1))

  return <AcFilterGroup items={orderedAgencies} getGroup={(agencyOrganization: AgencyOrganization) => agencyOrganization.agencyDetails?.agencyStageDetails?.salesStage ?? AgencySalesStage.T} activeFilters={props.activeFilters} onFilterClicked={onFilterClick} onResetFilters={onTotalClick} />
}
