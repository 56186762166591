import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiForm, EuiComboBoxOptionOption, EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiComboBox } from '@elastic/eui'

import { Organization, useOpsClient, UpdateOrganizationIncludedCampaignIds, Campaign } from 'api'
import { RootState } from 'app/rootReducer'

interface OrganizationIncludedCampaignIdsTabParams {
  organization: Organization
  refetchOrganization(id: string): void
}

interface FormValues {
  includedCampaigns: Campaign[] | null
}

const OrganizationDetailsTab: React.FC<OrganizationIncludedCampaignIdsTabParams> = ({ organization, refetchOrganization }) => {
  const [initial, setInitial] = useState<FormValues>({
    includedCampaigns: null
  })
  const [isLoadingCampaings, setIsLoadingCampaigns] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && campaigns) {
      setInitial({
        includedCampaigns: campaigns.filter(c => organization.includedCampaignIds.some(x => x === c.campaignId))
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, organization, campaigns])

  useEffect(() => {
    let isMounted = true
    if (isMounted && opsClient && organization) {
      setIsLoadingCampaigns(true)
      opsClient.getCampaigns(organization.organizationId).then(a => {
        if (isMounted) {
          setCampaigns(a)
          setIsLoadingCampaigns(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, organization])

  const organizationSchema = Yup.object().shape({
    includedCampaignIds: Yup.array().of(Yup.string())
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateOrganizationIncludedCampaignIds = {
      includedCampaignIds: values.includedCampaigns?.map(c => c.campaignId) ?? null
    }

    opsClient?.updateOrganizationIncludedCampaignIds(organization.organizationId, update).then(result => {
      setSubmitting(false)
      refetchOrganization(result?.organizationId)
    })
  }

  const includedChange = (options: EuiComboBoxOptionOption[]) => {
    setInitial(initial => {
      let init = { ...initial }
      init.includedCampaigns = campaigns.filter(c => options.some(x => x.id === c.campaignId))
      return init
    })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={initial} enableReinitialize validationSchema={organizationSchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <EuiFormRow label='Included Campaigns' helpText={'These campaigns are excluded from any billing'}>
              <EuiComboBox id='campaigns' onChange={includedChange} options={campaigns.map(c => ({ id: c.campaignId, label: c.campaignName }))} selectedOptions={props.values.includedCampaigns?.map(c => ({ id: c.campaignId, label: c.campaignName }))} isLoading={isLoadingCampaings} />
            </EuiFormRow>
            <EuiSpacer />

            <EuiFlexGroup gutterSize='s'>
              <EuiFlexItem grow={false}>
                <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
                  Save
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={true} />
            </EuiFlexGroup>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default OrganizationDetailsTab
