import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { EuiBadge, EuiCopy, EuiButton, EuiPageTemplate, EuiLoadingContent } from '@elastic/eui'

import { AdStatus } from 'api'
import { useGetAccountQuery } from 'api/rtkQueryApi/opsApi/accountsApi'
import { useGetAdQuery, useRefreshAdStatusMutation } from 'api/rtkQueryApi/opsApi/adsApi'
import { useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcTab } from 'components/ACTools/AcTab'
import { AdDetailsTab } from 'features/customers/ads/AdDetailsTab'
import { AdNotesTab } from 'features/customers/ads/AdNotesTab'
import history from 'services/HistoryService'

import AdBillboardDetailsTab from './AdBillboardDetailsTab'
import AdTvDetailsTab from './AdTvDetailsTab'
import AdTvUploadedDetailsTab from './AdTvUploadedDetailsTab'

interface AdPageParams {
  adId: string
}

const AdPage: React.FC = () => {
  let { adId } = useParams<AdPageParams>()
  const [tab, setTab] = useState(0)

  const {
    isLoading: isAdLoading,
    isFetching: isAdFetching,
    data: ad
  } = useGetAdQuery(adId, {
    skip: !adId
  })

  const { data: organization } = useGetOrganizationQuery(ad?.organizationId, {
    skip: !ad?.organizationId
  })

  const { data: account } = useGetAccountQuery(ad?.accountId, {
    skip: !ad?.accountId
  })

  const { data: campaign } = useGetCampaignQuery(ad?.campaignId ?? '', {
    skip: !ad?.campaignId
  })

  const [refreshAdStatus, refreshAdStatusResult] = useRefreshAdStatusMutation()
  const { userProfile } = useSelector((state: RootState) => state.app)

  const renderDetailsTab = () => {
    switch (ad!.adType) {
      case 'Internet':
        return <AdDetailsTab adId={adId} />
      case 'TV':
        if (ad?.tvCommercialDetails) return <AdTvDetailsTab adId={adId} />
        else return <AdTvUploadedDetailsTab adId={adId} />
      case 'Billboard':
        return <AdBillboardDetailsTab adId={adId} />
    }
  }

  const onRefreshStatusClick = () => {
    refreshAdStatus(adId)
  }

  const tabs: AcTab[] = [
    { label: 'Details', isSelected: tab === 0, onClick: () => setTab(0) },
    { label: 'Notes', isSelected: tab === 1, onClick: () => setTab(1) }
  ]

  return (
    <React.Fragment>
      {organization && (
        <EuiPageTemplate>
          <EuiPageTemplate.Header
            iconType='savedObjectsApp'
            pageTitle={
              <React.Fragment>
                Ad
                <br />
                <EuiCopy textToCopy={adId}>
                  {copy => (
                    <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Ad ID'>
                      {adId}
                    </EuiBadge>
                  )}
                </EuiCopy>
              </React.Fragment>
            }
            breadcrumbs={[
              {
                text: organization?.organizationName,
                onClick: () => history.push(`/customers/organizations/${organization.organizationId}`)
              },
              {
                text: account?.accountName,
                onClick: () => history.push(`/customers/accounts/${ad?.accountId}`)
              },
              {
                text: campaign?.campaignName,
                onClick: () => history.push(`/customers/campaigns/${ad?.campaignId}`)
              }
            ]}
            tabs={tabs}
            rightSideItems={[
              ...(ad && userProfile?.roles.includes('adcritter-ops-admin') && ad.status !== AdStatus.Inactive && ad.status !== AdStatus.Deleted
                ? [
                    <EuiButton isLoading={refreshAdStatusResult.isLoading} target='_blank' size='s' iconType='refresh' iconSide='right' color='ghost' fill onClick={onRefreshStatusClick}>
                      Refresh
                    </EuiButton>
                  ]
                : []),
              ...(ad && organization && !organization.isAgency
                ? [
                    <EuiButton href={`${config.PLATFORMURL}gotoAd/${ad.organizationId}/${ad.accountId}/${ad.campaignId}/${ad.adId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                      AdCritter Platform
                    </EuiButton>
                  ]
                : []),
              ...(ad && organization && organization.isAgency
                ? [
                    <EuiButton href={`${config.AGENCYURL}gotoAd/${ad.organizationId}/${ad.accountId}/${ad.campaignId}/${ad.adId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                      AdCritter for Agencies
                    </EuiButton>
                  ]
                : [])
            ]}
            restrictWidth={false}
          />
          <EuiPageTemplate.Section restrictWidth={false}>
            {(isAdLoading || isAdFetching) && <EuiLoadingContent />}
            {ad && !(isAdLoading || isAdFetching) && (
              <React.Fragment>
                {tab === 0 && renderDetailsTab()}
                {tab === 1 && <AdNotesTab ad={ad} />}
              </React.Fragment>
            )}
          </EuiPageTemplate.Section>
        </EuiPageTemplate>
      )}
    </React.Fragment>
  )
}

export default AdPage
