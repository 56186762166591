import React, { FC } from 'react'
import { useParams } from 'react-router'

import { EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody } from '@elastic/eui'

import AccountManagerDashboardTabs from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import history from 'services/HistoryService'

export interface AccountManagerDashboardPageProps {
  tab?: string
}

const AccountManagerDashboardPage: FC = () => {
  const { tab } = useParams<AccountManagerDashboardPageProps>()

  if (!tab) {
    history.push('/account-manager/dashboard/offered')
    return <React.Fragment></React.Fragment>
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <AccountManagerDashboardTabs currentTab={tab} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default AccountManagerDashboardPage
