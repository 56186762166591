import React, { useEffect, useState } from 'react'

import { EuiFieldText } from '@elastic/eui'

type PhraseEntererProps = {
  selectedPhrase: string
  setParentState(selectedPhrase: string): void
}

const PhraseEnterer: React.FC<PhraseEntererProps> = ({ selectedPhrase, setParentState }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (selectedPhrase) {
      setValue(selectedPhrase)
    }
  }, [selectedPhrase])

  return (
    <EuiFieldText
      compressed
      style={{ width: 250 }}
      controlOnly
      value={value}
      onChange={e => {
        setParentState(e.target.value)
        setValue(e.target.value)
      }}
    />
  )
}

export default PhraseEnterer
