import React, { useEffect, useState } from 'react'

import { EuiButtonGroup } from '@elastic/eui'

interface GenderTargeterProps {
  genders: Array<string>

  setGenders(genders: Array<string>): void
}

export const GenderTargeter = ({ genders, setGenders }: GenderTargeterProps) => {
  const [toggleGenderToSelectedMap, setToggleGenderToSelectedMap] = useState<{ [id: string]: boolean }>({
    gender_all: true,
    gender_female: false,
    gender_male: false
  })

  const genderButtons = [
    {
      id: 'gender_all',
      label: 'All'
    },
    {
      id: 'gender_female',
      label: 'Female'
    },
    {
      id: 'gender_male',
      label: 'Male'
    }
  ]

  useEffect(() => {
    if (genders) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (genders.length === 0) genders = ['all']

      setToggleGenderToSelectedMap({
        gender_all: genders.includes('all'),
        gender_female: genders.includes('female'),
        gender_male: genders.includes('male')
      })
    }
  }, [genders])

  const onChangeGender = (optionId: string) => {
    let innerGenders: Array<string> = []
    if (optionId === 'gender_all' && !toggleGenderToSelectedMap[optionId]) {
      setToggleGenderToSelectedMap({
        gender_all: true,
        gender_female: false,
        gender_male: false
      })
      innerGenders.push('all')
    } else {
      const newToggleIdToSelectedMap = {
        ...toggleGenderToSelectedMap,
        ...{
          [optionId]: !toggleGenderToSelectedMap[optionId]
        }
      }
      newToggleIdToSelectedMap['gender_all'] = (!newToggleIdToSelectedMap.hasOwnProperty('gender_female') || !newToggleIdToSelectedMap['gender_female']) && (!newToggleIdToSelectedMap.hasOwnProperty('gender_male') || !newToggleIdToSelectedMap['gender_male'])
      setToggleGenderToSelectedMap(newToggleIdToSelectedMap)

      if (newToggleIdToSelectedMap.gender_female) innerGenders.push('female')
      if (newToggleIdToSelectedMap.gender_male) innerGenders.push('male')
    }
    setGenders(innerGenders)
  }

  return <EuiButtonGroup legend='Genders' options={genderButtons} idToSelectedMap={toggleGenderToSelectedMap} onChange={id => onChangeGender(id)} type='multi' />
}
