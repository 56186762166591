import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EuiBadge, EuiBreadcrumbs, EuiCopy, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTabbedContent, EuiTitle } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

import AnchorPhraseDetailsTab from './AnchorPhraseDetailsTab'

interface AnchorPhrasePageParams {
  anchorPhraseId: string
}

const AnchorPhrasePage: React.FC = () => {
  let { anchorPhraseId } = useParams<AnchorPhrasePageParams>()
  const [name, setName] = useState('')
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      opsClient.getAnchorPhrase(anchorPhraseId).then(result => {
        setName(result.name)
      })
    }
  }, [opsClient, anchorPhraseId])

  const tabs: EuiTabbedContentTab[] = [
    {
      id: 'details',
      name: 'Details',
      content: <AnchorPhraseDetailsTab anchorPhraseId={anchorPhraseId} />
    }
  ]

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Data' },
              {
                text: 'Anchor Phrases',
                href: '/data/anchorPhrases',
                onClick: () => history.push('/data/anchorPhrases')
              },
              {
                text: name,
                href: `/data/anchorPhrases/${anchorPhraseId}`,
                onClick: () => history.push(`/data/anchorPhrases/${anchorPhraseId}`)
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Anchor Phrase</h1>
              </EuiTitle>
              <EuiCopy textToCopy={anchorPhraseId}>
                {copy => (
                  <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Anchor Phrase ID'>
                    {anchorPhraseId}
                  </EuiBadge>
                )}
              </EuiCopy>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}
export default AnchorPhrasePage
