import { Moment } from 'moment/moment'

import { PaymentMethod } from 'api/entities/Organization'

export interface Invoice {
  id: string
  number: string
  description: string
  amount: number
  amountDue: number
  amountPaid: number
  paidDate: Moment | null
  refundAmount: number
  status: InvoiceStatus
  billingReason: string
  created: Moment | null
  url: string
  shouldRetry: boolean
  lines: InvoiceLine[]
  paymentMethod: PaymentMethod
}

export interface InvoiceLine {
  id: string
  title: string
  description: string
  periodRangeStart: string
  periodRangeEnd: string
  subTotalAmount: number
  discountAmount: number
  totalAmount: number
  order: Order
}

export interface Order {
  id: string
  amount: number
  name: string
  referenceId: string
  coreReferenceType: CoreReferenceType
  orderActiveStatus: OrderActiveStatus
}

export interface IPayment {
  key: string
  amount: number
  payDate: Moment
}

export enum OrderActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum InvoiceType {
  Campaign = 'Campaign',
  Subscription = 'Subscription'
}

export interface CreateInvoiceRequest {
  organizationId: string
  title: string
  description: string
  amount: number
  campaignId: string
  dontCharge: boolean
  invoiceType: InvoiceType
  stripeSetupIntentId: string
}

export interface UpdateInvoiceStatusRequest {
  invoiceId: string
  status: string
  refundAmount: number
}

export enum InvoiceStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Open = 'Open',
  Paid = 'Paid',
  Voided = 'Voided',
  Canceled = 'Canceled',
  Refunded = 'Refunded',
  PartiallyRefunded = 'PartiallyRefunded',
  PaymentFailed = 'PaymentFailed'
}

export enum CoreReferenceType {
  Campaign = 'Campaign',
  Plan = 'Plan',
  Package = 'Package',
  VendastaPackage = 'VendastaPackage',
  Subscription = 'Subscription'
}
