import React, { FC, useRef, useState } from 'react'

import { EuiButton, EuiInMemoryTable, EuiSpacer, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { ContactMethod } from 'api'
import EditContactMethodModal from 'features/customers/contacts/ContactMethod/EditContactMethodModal'
import { ContactAction, ContactActionKind } from 'features/customers/contacts/useContactReducer'

export interface ContactMethodProps {
  contactMethods: ContactMethod[]
  dispatchContact: React.Dispatch<ContactAction>
}

export const ContactMethodTable: FC<ContactMethodProps> = ({ contactMethods, dispatchContact }) => {
  const [contactMethod, setContactMethod] = useState<ContactMethod>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const sorting = useRef({
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'isPrimary',
      direction: SortDirection.DESC
    }
  })

  const closeModal = () => setIsModalVisible(false)
  const showModal = (contactMethod?: ContactMethod) => {
    setContactMethod(contactMethod)
    setIsModalVisible(true)
  }

  const actions = [
    {
      name: 'Edit',
      description: 'Edit this contact method',
      icon: 'pencil',
      type: 'icon',
      onClick: (contactMethod: ContactMethod) => showModal(contactMethod)
    },
    {
      name: 'Delete',
      description: 'Delete this contact method',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: (contactMethod: ContactMethod) => {
        dispatchContact({ type: ContactActionKind.CONTACT_METHOD_REMOVE, payload: contactMethod.id })
        setContactMethod({ ...contactMethod })
      }
    }
  ]

  const columns = [
    {
      field: 'info',
      name: 'Info',
      sortable: true
    },
    {
      field: 'type',
      name: 'Type',
      sortable: true,
      render: (type: string) =>
        type
          .split(/(?=[A-Z])/)
          .join(' ')
          .toLowerCase()
    },
    {
      field: 'communicationPreference',
      name: 'Communication Preference',
      sortable: true,
      render: (communicationPreference: string) =>
        communicationPreference
          .split(/(?=[A-Z])/)
          .join(' ')
          .toLowerCase()
    },
    {
      field: 'isPrimary',
      name: 'Is Primary',
      sortable: true
    },
    {
      name: 'Actions',
      actions
    }
  ] as EuiBasicTableColumn<ContactMethod>[]

  const sortedContactMethods = [...contactMethods]?.sort((a, b) => {
    if (a.isPrimary === b.isPrimary) return 0
    if (a.isPrimary) return -1
    return 1
  })

  return (
    <React.Fragment>
      <EuiInMemoryTable items={sortedContactMethods ?? []} columns={columns} sorting={sorting.current} />

      <EuiSpacer size='xxl' />

      <EuiButton onClick={() => showModal()}>Add Contact Method</EuiButton>
      <EditContactMethodModal contactMethod={contactMethod} dispatchContact={dispatchContact} isModalVisible={isModalVisible} closeModal={closeModal} />
    </React.Fragment>
  )
}

export default ContactMethodTable
