import jsonTryParse from 'utils/jsonTryParse'

export const THEME = 'theme'

export class LocalStoreManagerService {
  private reservedKeys: string[] = ['sync_keys', 'addToSyncKeys', 'removeFromSyncKeys', 'getSessionStorage', 'setSessionStorage', 'addToSessionStorage', 'removeFromSessionStorage', 'clearAllSessionsStorage']

  public clearSessionStorage() {
    sessionStorage.clear()
  }

  public saveSessionData(key: string, data: any) {
    this.testForInvalidKeys(key)

    localStorage.removeItem(key)
    LocalStoreManagerService.sessionStorageSetItem(key, data)
  }

  public savePermanentData(key: string, data: any) {
    this.testForInvalidKeys(key)

    sessionStorage.removeItem(key)
    LocalStoreManagerService.localStorageSetItem(key, data)
  }

  public exists(key: string) {
    let data = sessionStorage.getItem(key)

    if (data == null) {
      data = localStorage.getItem(key)
    }

    return data != null
  }

  public getData(key: string) {
    this.testForInvalidKeys(key)

    let data = LocalStoreManagerService.sessionStorageGetItem(key)

    if (data == null) {
      data = LocalStoreManagerService.localStorageGetItem(key)
    }

    return data
  }

  public getDataObject<T>(key: string, isDateType = false): T | null {
    let data = this.getData(key)

    if (data != null) {
      if (isDateType) {
        data = new Date(data)
      }
      return data as T
    }
    return null
  }

  public deleteData(key: string) {
    this.testForInvalidKeys(key)

    sessionStorage.removeItem(key)
    localStorage.removeItem(key)
  }

  private testForInvalidKeys(key: string) {
    if (!key) {
      throw new Error('key cannot be empty')
    }

    if (this.reservedKeys.some(x => x === key)) {
      throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`)
    }
  }

  private static localStorageSetItem(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  private static sessionStorageSetItem(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data))
  }

  private static localStorageGetItem(key: string) {
    return jsonTryParse(localStorage.getItem(key))
  }

  private static sessionStorageGetItem(key: string) {
    return jsonTryParse(sessionStorage.getItem(key))
  }
}

const localStoreManagerService = new LocalStoreManagerService()

export default localStoreManagerService

export const getTheme = () => localStoreManagerService.getDataObject<string>(THEME)
export const setTheme = (value: string) => localStoreManagerService.savePermanentData(THEME, value)
export const deleteTheme = () => localStoreManagerService.deleteData(THEME)
