import React from 'react'

import { EuiBadge, useEuiBackgroundColor } from '@elastic/eui'

import { PerformanceDashboardPackage } from '../../api/entities/PerformanceDashboard'

export const PackageBadge: React.FC<{ pack: PerformanceDashboardPackage }> = ({ pack }) => {
  const onboardedColor = useEuiBackgroundColor('warning')
  const partiallyOnboardedColor = useEuiBackgroundColor('accent')

  return (
    <React.Fragment>
      {pack.packageStatus === 'Onboarded' && <EuiBadge color={onboardedColor}>Onboarded</EuiBadge>}
      {pack.packageStatus === 'PartiallyOnboarded' && <EuiBadge color={partiallyOnboardedColor}>Partially Onboarded</EuiBadge>}
      {pack.packageStatus === 'Live' && <EuiBadge color='success'>Live</EuiBadge>}
    </React.Fragment>
  )
}
