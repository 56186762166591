import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { LocationCity } from 'api'

interface CityTargeterPropsNew {
  cities: Array<LocationCity>
  onCityRemoved(city: LocationCity): void
}

export const CityTargeterNew = ({ cities, onCityRemoved }: CityTargeterPropsNew) => {
  return (
    <React.Fragment>
      {cities && cities.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
          {cities.map(w => (
            <EuiListGroupItem
              size='xs'
              key={w.id.toString()}
              label={
                <React.Fragment>
                  {w.name}, {w.regionCode}
                </React.Fragment>
              }
              extraAction={{
                onClick: () => {
                  onCityRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
