import React from 'react'

import { EuiFormRow, EuiLoadingSpinner, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AdvertisingSnapshot } from 'api/entities/AdvertisingSnapshot'
import { useGetSegmentsQuery } from 'api/rtkQueryApi/opsApi/audiencesApi'
import { AcBadgeGroup } from 'components/Basic/AcBadgeGroup'
import { useGetAccountCampaignTargeting } from 'utils/useGetAccountCampaignTargeting'

export interface IAdvertisingTargetingChart {
  isLoading?: boolean
  snapshot?: AdvertisingSnapshot
}

export const AdvertisingTargetingChart: React.FC<IAdvertisingTargetingChart> = props => {
  const accountTargeting = useGetAccountCampaignTargeting(props.snapshot?.accountId)
  const segmentsQuery = useGetSegmentsQuery({ segmentIds: accountTargeting.segmentIds.map(s => s.toString()) }, { skip: accountTargeting.segmentIds.length < 0 })

  const segments = segmentsQuery.data ?? []
  const totalGeoTargets = (accountTargeting.locations.geoCircles?.length ?? 0) + (accountTargeting.locations.geoRectangles?.length ?? 0) + (accountTargeting.locations.geoPolygons?.length ?? 0) + (accountTargeting.locations.geoFences?.length ?? 0)
  return (
    <React.Fragment>
      <EuiTitle className='eui-textCenter' size='s'>
        <h3>Targeting Criteria</h3>
      </EuiTitle>
      <EuiSpacer size={'l'} />
      {props.isLoading ? (
        <EuiLoadingSpinner size='l' />
      ) : (
        <React.Fragment>
          {accountTargeting.smartTargetings.map(smartTargeting => {
            return (
              <EuiFormRow label={`Smart Targeting (${smartTargeting.targetArea})`} fullWidth>
                <React.Fragment>
                  {smartTargeting.walkInTargeting && (
                    <EuiText size={'s'}>
                      <p>
                        <b>Walk In Targeting</b>
                      </p>
                    </EuiText>
                  )}
                  {smartTargeting.competitorTargeting && (
                    <EuiText size={'s'}>
                      <p>
                        <b>Competitor Targeting</b>
                      </p>
                    </EuiText>
                  )}
                  <EuiText size={'s'}>
                    <p>
                      {smartTargeting.streetAddress} <br />
                      {smartTargeting.city} {smartTargeting.state}, {smartTargeting.zip}
                    </p>
                  </EuiText>
                </React.Fragment>
              </EuiFormRow>
            )
          })}
          {accountTargeting.locations.countries.length > 0 && (
            <EuiFormRow label='Countries'>
              <AcBadgeGroup items={accountTargeting.locations.countries.map(c => c.name)} />
            </EuiFormRow>
          )}
          {accountTargeting.locations.regions.length > 0 && (
            <EuiFormRow label='Regions'>
              <AcBadgeGroup items={accountTargeting.locations.regions.map(r => r.name)} />
            </EuiFormRow>
          )}
          {accountTargeting.locations.dmas.length > 0 && (
            <EuiFormRow label='DMAs'>
              <AcBadgeGroup items={accountTargeting.locations.dmas.map(d => d.name)} />
            </EuiFormRow>
          )}
          {accountTargeting.locations.cities.length > 0 && (
            <EuiFormRow label='Cities'>
              <AcBadgeGroup items={accountTargeting.locations.cities.map(c => c.name)} />
            </EuiFormRow>
          )}
          {accountTargeting.locations.postalCodes.length > 0 && (
            <EuiFormRow label='Postal Codes'>
              <AcBadgeGroup items={accountTargeting.locations.postalCodes.map(p => p.code)} />
            </EuiFormRow>
          )}
          {totalGeoTargets > 0 && (
            <EuiFormRow label='Map Targeting'>
              <EuiText size={'s'}>
                <p>{totalGeoTargets} Geofenced Areas</p>
              </EuiText>
            </EuiFormRow>
          )}
          {(accountTargeting.demographics.genders.length > 0 || accountTargeting.demographics.ageRanges.length > 0 || accountTargeting.demographics.householdIncomes.length > 0) && (
            <EuiFormRow label='Demographics'>
              <EuiText size={'s'}>
                <p>
                  Ages: <AcBadgeGroup items={accountTargeting.demographics.ageRanges} />
                  <br />
                  Genders:
                  <AcBadgeGroup items={accountTargeting.demographics.genders} />
                  <br />
                  Incomes: <AcBadgeGroup items={accountTargeting.demographics.householdIncomes} />
                </p>
              </EuiText>
            </EuiFormRow>
          )}
          {segments.length > 0 && (
            <EuiFormRow label='Segments'>
              <EuiText size={'s'}>
                <AcBadgeGroup items={segments.slice(0, 7).map(s => s.name)} />
                {segments.length > 7 && <p>...and {segments.length - 7} more</p>}
              </EuiText>
            </EuiFormRow>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
