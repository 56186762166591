import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { EuiCopy, EuiBadge, EuiButton, EuiPageTemplate, EuiLoadingContent } from '@elastic/eui'

import { Account, Organization, useOpsClient } from 'api'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcTab } from 'components/ACTools/AcTab'
import { AccountSnapshotTab } from 'features/customers/accounts/AccountSnapshotTab'
import history from 'services/HistoryService'

import AccountCampaignsTab from './AccountCampaignsTab'
import AccountDetailsTab from './AccountDetailsTab'
import AccountImagesTab from './AccountImagesTab'
import AccountVisitsTab from './AccountVisitsTab'

interface AccountPageParams {
  accountId: string
}

enum AccountTabs {
  Details,
  Campaigns,
  Images,
  Visits,
  Snapshot
}

const AccountPage: React.FC = () => {
  let { accountId } = useParams<AccountPageParams>()
  const [account, setAccount] = useState<Account>()
  const [organization, setOrganization] = useState<Organization>()
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [tab, setTab] = useState<AccountTabs>(AccountTabs.Details)

  const [isYahooDsp, setIsYahooDsp] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setIsYahooDsp(userProfile.roles.includes('adcritter-ops-bidder') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      opsClient.getAccount(accountId).then(result => {
        if (isMounted) {
          setAccount(result)
          opsClient.getOrganization(result.organizationId).then(o => setOrganization(o))
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, accountId, isYahooDsp])

  const tabs: AcTab[] = [
    { label: 'Details', isSelected: tab === AccountTabs.Details, onClick: () => setTab(AccountTabs.Details) },
    { label: 'Campaigns', isSelected: tab === AccountTabs.Campaigns, onClick: () => setTab(AccountTabs.Campaigns) },
    { label: 'Uploaded Images', isSelected: tab === AccountTabs.Images, onClick: () => setTab(AccountTabs.Images) },
    { label: 'Visits', isSelected: tab === AccountTabs.Visits, onClick: () => setTab(AccountTabs.Visits) },
    ...(userProfile?.roles.includes('adcritter-ops-csuite') ? [{ label: 'Snapshot', isSelected: tab === AccountTabs.Snapshot, onClick: () => setTab(AccountTabs.Snapshot) }] : [])
  ]

  if (!account) {
    return <EuiLoadingContent />
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header
        iconType='savedObjectsApp'
        pageTitle={
          <React.Fragment>
            Account
            <br />
            <EuiCopy textToCopy={accountId}>
              {copy => (
                <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Account ID'>
                  {accountId}
                </EuiBadge>
              )}
            </EuiCopy>
          </React.Fragment>
        }
        breadcrumbs={[
          {
            text: organization?.organizationName,
            onClick: () => history.push(`/customers/organizations/${account.organizationId}`)
          }
        ]}
        tabs={tabs}
        rightSideItems={[
          ...(account && organization && !organization.isAgency
            ? [
                <EuiButton href={`${config.PLATFORMURL}gotoAccount/${account.organizationId}/${account.accountId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                  AdCritter Platform
                </EuiButton>
              ]
            : []),
          ...(account && organization && organization.isAgency
            ? [
                <EuiButton href={`${config.AGENCYURL}gotoAccount/${account.organizationId}/${account.accountId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                  AdCritter for Agencies
                </EuiButton>
              ]
            : [])
        ]}
        restrictWidth={false}
      />
      <EuiPageTemplate.Section restrictWidth={false}>
        {tab === AccountTabs.Details && <AccountDetailsTab account={account} organization={organization!} setAccount={setAccount} />}
        {tab === AccountTabs.Campaigns && <AccountCampaignsTab account={account} />}
        {tab === AccountTabs.Images && <AccountImagesTab account={account} />}
        {tab === AccountTabs.Visits && <AccountVisitsTab account={account} />}
        {tab === AccountTabs.Snapshot && userProfile?.roles.includes('adcritter-ops-csuite') && <AccountSnapshotTab account={account!} />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default AccountPage
