import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { LocationPostalCode } from 'api'

interface PostalCodeTargeterProps {
  postalCodes: Array<LocationPostalCode>
  onPostalCodeRemoved(postalCode: LocationPostalCode): void
}

export const PostalCodeTargeter = ({ postalCodes, onPostalCodeRemoved }: PostalCodeTargeterProps) => {
  return (
    <React.Fragment>
      {postalCodes && postalCodes.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
          {postalCodes.map(w => (
            <EuiListGroupItem
              size='xs'
              key={w.id.toString()}
              label={w.name}
              extraAction={{
                onClick: () => {
                  onPostalCodeRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
