import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import { CampaignType } from 'api'
import { BundleType, CampaignSlotSimple } from 'api/entities/Bundle'
import { CreateCampaignSlotPanel } from 'components/Bundles/CreateCampaignSlotPanel'
import CreateCampaignSlotTypePanel from 'components/Bundles/CreateCampaignSlotTypePanel'

export interface IBundleSlotCreationFormRow {
  addSlotButtonText: string
  showTypeOnly?: boolean
  organizationId?: string
  isBonus?: boolean
  bundleType: BundleType
  campaignSlots: CampaignSlotSimple[]
  onCampaignSlotRemoved: (campaignSlot: CampaignSlotSimple) => void
  onCampaignSlotUpdated: (campaignSlot: CampaignSlotSimple) => void
  onCampaignSlotAdd: (campaignSlot: CampaignSlotSimple) => void
}

export const BundleSlotCreationFormRow: React.FC<IBundleSlotCreationFormRow> = props => {
  const onAddSlotClicked = () => {
    props.onCampaignSlotAdd?.({
      id: uuidv4(),
      isBonus: props.isBonus,
      campaignType: CampaignType.Internet,
      budget: 0
    })
  }

  const onUpdateCampaignSlot = (campaignSlot: CampaignSlotSimple) => {
    props.onCampaignSlotUpdated?.(campaignSlot)
  }

  const onRemoveCampaignSlot = (campaignSlot: CampaignSlotSimple) => {
    props.onCampaignSlotRemoved?.(campaignSlot)
  }

  const onCampaignTypeUpdate = (campaignType: CampaignType, campaignSlot: CampaignSlotSimple) => {
    onUpdateCampaignSlot({ ...campaignSlot, campaignType })
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty iconType={'plus'} onClick={onAddSlotClicked}>
            {props.addSlotButtonText}
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>
      {props.campaignSlots.map((slot, index) => {
        if (props.showTypeOnly) {
          return (
            <React.Fragment key={index}>
              <CreateCampaignSlotTypePanel
                campaignType={slot.campaignType}
                isBonusSlot={props.isBonus}
                onCampaignTypeUpdate={campaignType => {
                  onCampaignTypeUpdate(campaignType, slot)
                }}
                onCancelClick={() => {
                  onRemoveCampaignSlot(slot)
                }}
              />
              <EuiSpacer />
            </React.Fragment>
          )
        } else {
          return (
            <React.Fragment key={slot.id}>
              <CreateCampaignSlotPanel
                bundleType={props.bundleType}
                slot={slot}
                isBonusSlot={props.isBonus}
                onSlotUpdate={onUpdateCampaignSlot}
                onCancelClick={() => {
                  onRemoveCampaignSlot(slot)
                }}
                organizationId={props.organizationId}
              />
              <EuiSpacer />
            </React.Fragment>
          )
        }
      })}
    </React.Fragment>
  )
}
