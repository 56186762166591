import { useEffect, useState } from 'react'

import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'

export const useSelfServicePackageIsValid = (template: SelfServicePackageTemplate) => {
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    if (template) {
      const { salesTitle, salesDescription, templateName, campaignSlots } = template
      if (!salesTitle || !salesDescription || !templateName) {
        setIsValid(false)
        return
      }

      if (campaignSlots.length > 0 && campaignSlots.filter(s => s.isBonus).reduce((acc, slot) => acc + slot.budget, 0) > campaignSlots.filter(s => !s.isBonus).reduce((acc, slot) => acc + slot.budget, 0)) {
        setIsValid(false)
        return
      }
      setIsValid(true)
    }
  }, [template])

  return isValid
}
