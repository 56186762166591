import Konva from 'konva'
import React, { useCallback, useEffect } from 'react'
import { Layer, Rect, Text } from 'react-konva'

import { IAdCreator } from 'components/AdCreation/AdCreators/IAdCreator'
import { ACImage } from 'components/Basic/ACImage'

export const AdCreatorNativeMed: React.FC<IAdCreator> = props => {
  const backgroundLayerRef = React.useRef<Konva.Layer>(null)
  const imageLayerRef = React.useRef<Konva.Layer>(null)
  const nameLayerRef = React.useRef<Konva.Layer>(null)

  const [baseWidth, setBaseWidth] = React.useState(0)
  const [baseHeight, setBaseHeight] = React.useState(0)

  useEffect(() => {
    const image = new Image()
    image.src = props.uploadedUrl
    image.onload = () => {
      setBaseWidth(image.width)
      setBaseHeight(image.height)
    }
  }, [props.uploadedUrl])

  const getScaledHeight = useCallback(
    (width: number) => {
      if (!baseWidth || baseWidth <= 0) {
        return 1
      }
      return (baseHeight / baseWidth) * width
    },
    [baseWidth, baseHeight]
  )

  const ctaHeight = 34
  const ctaWidth = 110
  const ctaMargin = (props.adHeight - (16 + 32 + 60 + 4 + 50 + getScaledHeight(props.adWidth)) - ctaHeight) / 2

  return (
    <React.Fragment>
      <Layer name={'background'} ref={backgroundLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Rect x={0} y={0} width={props.adWidth} height={props.adHeight} fill={'#FFFFFF'} />
      </Layer>
      <Layer name={'name'} ref={nameLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Text x={12} y={16} text={props.details.headline} lineHeight={1.5} fontSize={19} fill={'#000'} height={76} width={props.adWidth - 12 - 12} fontStyle={'bold'} />
        <Text x={12} y={16 + 16 + 60 + 4} text={props.details.body} lineHeight={1.5} fontSize={14} fill={'#000'} height={66} width={props.adWidth - 12 - 12} />
        <Text verticalAlign={'middle'} x={12} y={props.adHeight - ctaMargin - ctaHeight} text={props.details.companyName} lineHeight={1.5} fontSize={16} fill={'#6A707C'} height={ctaHeight} width={props.adWidth / 2} fontStyle={'bold'} />
        <Rect x={props.adWidth - ctaMargin - ctaWidth} y={props.adHeight - ctaMargin - ctaHeight} width={ctaWidth} height={ctaHeight} fill={'#D7D8DA'} />
        <Text verticalAlign={'middle'} align={'center'} fontSize={14} text={props.details.callToAction} x={props.adWidth - ctaMargin - ctaWidth} y={props.adHeight - ctaMargin - ctaHeight} width={ctaWidth} height={ctaHeight} fill={'#000'} />
      </Layer>
      <Layer name={'image'} ref={imageLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <ACImage x={0} y={16 + 32 + 60 + 4 + 50} backgroundImageUrl={props.uploadedUrl} key={'backgroundImage'} height={getScaledHeight(props.adWidth)} width={props.adWidth} />
      </Layer>
    </React.Fragment>
  )
}
