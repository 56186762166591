import React, { useEffect } from 'react'
import { useParams } from 'react-router'

import { EuiLoadingContent } from '@elastic/eui'

import { CampaignSubType, CampaignType } from 'api'
import { useGetAdQuery } from 'api/rtkQueryApi/opsApi/adsApi'
import { useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import BillboardAdPendingAuditPage from 'features/customers/ads/BillboardAdPendingAuditPage'
import InternetAdPendingAuditPage from 'features/customers/ads/InternetAdPendingAuditPage'
import { NativeToDisplayPage } from 'features/customers/ads/NativeToDisplayPage'
import TvCommercialPendingAuditPage from 'features/customers/ads/TvCommercialPendingAuditPage'

export interface IAdAuditPageParams {
  adId: string
}

export const AdAuditPage: React.FC = () => {
  const { adId } = useParams<IAdAuditPageParams>()
  const [auditAsNative, setAuditAsNative] = React.useState(false)

  const getAdQuery = useGetAdQuery(adId)
  const getCampaignQuery = useGetCampaignQuery(getAdQuery.data?.campaignId ?? '', { skip: !getAdQuery.data?.campaignId })

  const campaign = getCampaignQuery.data

  useEffect(() => {
    setAuditAsNative(false)
  }, [adId])

  const onAuditAsNative = () => {
    setAuditAsNative(true)
  }

  if (getAdQuery.isLoading || getCampaignQuery.isLoading || !campaign) {
    return <EuiLoadingContent />
  } else if (!auditAsNative && (campaign.campaignSubType === CampaignSubType.NativeOrDisplay)) {
    return <NativeToDisplayPage campaignId={campaign.campaignId} onAuditAsNative={onAuditAsNative} />
  } else if (campaign.campaignType === CampaignType.Internet) {
    return <InternetAdPendingAuditPage adId={adId} />
  } else if (campaign.campaignType === CampaignType.TV) {
    return <TvCommercialPendingAuditPage adId={adId} />
  } else if (campaign.campaignType === CampaignType.Billboard) {
    return <BillboardAdPendingAuditPage adId={adId} />
  } else {
    return <React.Fragment />
  }
}
