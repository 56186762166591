import React, { useEffect } from 'react'

import { EuiHeaderLink } from '@elastic/eui'

import { Anomaly, AnomalyStatus } from 'api/entities/Anomaly'
import { useGetOpenAnomaliesQuery } from 'api/rtkQueryApi/opsApi/anomalyApi'
import history from 'services/HistoryService'

export interface IAnomaliesHeaderLink {}

export const AnomaliesHeaderLink: React.FC<IAnomaliesHeaderLink> = () => {
  const [anomalies, setAnomalies] = React.useState<Anomaly[]>([])

  const campaignAnomaliesQuery = useGetOpenAnomaliesQuery(undefined, {
    pollingInterval: 3 * 60 * 1000
  })

  useEffect(() => {
    if (campaignAnomaliesQuery.data) {
      setAnomalies(campaignAnomaliesQuery.data.filter(a => a.status !== AnomalyStatus.Ignored))
    }
  }, [campaignAnomaliesQuery.data])

  const navigateToAnomalies = () => {
    history.push('/tools/anomalies')
  }

  if (anomalies.length > 0) {
    return (
      <EuiHeaderLink onClick={navigateToAnomalies} iconType={'warning'} color={'warning'}>
        {anomalies.length} Anomalies
      </EuiHeaderLink>
    )
  }

  return <React.Fragment />
}
