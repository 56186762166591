import { useGraphClient } from 'graph/GraphClientContext'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiHeader, EuiHeaderLogo, EuiHeaderLinks, EuiHeaderLink, EuiHeaderSectionItemButton, EuiAvatar, EuiSelectableTemplateSitewide, EuiIcon, EuiSelectableMessage, EuiHeaderSectionItem, EuiHeaderSection, EuiFlexItem, EuiText, EuiSpacer, EuiFlexGroup, EuiPopover, EuiLink } from '@elastic/eui'
import { EuiSelectableTemplateSitewideOption } from '@elastic/eui/src/components/selectable/selectable_templates/selectable_template_sitewide_option'

import { useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import { AnomaliesHeaderLink } from 'components/Header/AnomaliesHeaderLink'
import { LegacyMenu } from 'components/Header/LegacyMenu'
import AuthService from 'services/AuthService'
import history from 'services/HistoryService'
import useDebounce from 'utils/useDebounce'

const HeaderBar: React.FC = () => {
  const [searchValue, setSearchValue] = useState('')
  const { userProfile } = useSelector((state: RootState) => state.app)
  const debouncedSearchValue = useDebounce(searchValue, 400)
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState<EuiSelectableTemplateSitewideOption[]>([])
  const [profilePic, setProfilePic] = useState('/profilePic.png')
  const [isOpen, setIsOpen] = useState(false)
  const graphClient = useGraphClient()
  const opsClient = useOpsClient()

  useEffect(() => {
    if (graphClient) {
      graphClient.getProfilePicture().then(picture => setProfilePic(picture))
    }
  }, [graphClient])

  const closeMenu = () => {
    setIsOpen(false)
  }

  const logout = async () => {
    const accounts = AuthService.getAccounts()

    if (accounts && accounts.length > 0) {
      await AuthService.signOut(accounts[0])
      window.location.reload()
    }
  }

  const searchChange = (updatedOptions: EuiSelectableTemplateSitewideOption[]) => {
    const clickedItem = updatedOptions.find(option => option.checked === 'on')
    if (clickedItem) {
      switch (clickedItem.meta![0].text) {
        case 'Organization':
          history.push(`/customers/organizations/${clickedItem.meta![1].text}`)
          break
        case 'Account':
          history.push(`/customers/accounts/${clickedItem.meta![1].text}`)
          break
        case 'Campaign':
          history.push(`/customers/campaigns/${clickedItem.meta![1].text}`)
          break
        case 'Ad':
          history.push(`/customers/ads/${clickedItem.meta![1].text}`)
          break
        case 'Contact':
          history.push(`/customers/contacts/${clickedItem.meta![1].text}`)
          break
      }
    }
  }

  const onKeyUpCapture = (e: any) => {
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    if (debouncedSearchValue && debouncedSearchValue.length > 0) {
      setIsSearching(true)
      opsClient?.getSearchResults(debouncedSearchValue).then(results => {
        setSearchResults(
          results.map(
            r =>
              ({
                label: r.name,
                searchableLabel: `${r.name}|${r.id}|${r.email}|${r.phoneNumber}`,
                meta: [
                  { text: r.type, highlightSearchString: true, type: 'platform' },
                  { text: r.id, highlightSearchString: true, type: 'article' },
                  { text: r.email, highlightSearchString: true, type: 'deployment' },
                  { text: r.phoneNumber, highlightSearchString: true, type: 'case' }
                ]
              } as EuiSelectableTemplateSitewideOption)
          )
        )
        setIsSearching(false)
      })
    }
  }, [opsClient, debouncedSearchValue])

  const search = (
    <EuiSelectableTemplateSitewide
      isLoading={isSearching}
      onChange={searchChange}
      options={searchResults}
      searchProps={{
        compressed: true,
        onKeyUpCapture: onKeyUpCapture,
        spellCheck: false,
        autoCapitalize: 'off',
        autoComplete: 'off',
        autoCorrect: 'off'
      }}
      popoverButton={
        <EuiHeaderSectionItemButton aria-label='system search'>
          <EuiIcon type='search' size='m' />
        </EuiHeaderSectionItemButton>
      }
      emptyMessage={
        <EuiSelectableMessage style={{ minHeight: 300 }}>
          <p>Search organizations, accounts, campaigns, ads and contacts here.</p>
        </EuiSelectableMessage>
      }
    />
  )

  const navigateHome = () => {
    history.push('/')
  }

  const navigateToAdvisorDashboard = () => {
    history.push('/advisor/dashboard/campaigns')
  }

  const navigateToTools = () => {
    history.push('/tools')
  }

  const navigateToAccountManagerDashboard = () => {
    history.push('/account-manager/dashboard/offered')
  }

  const navigateToAgencyManagerDashboard = () => {
    history.push('/agency-manager/dashboard')
  }

  const navigateToSalesManagerDashboard = () => {
    history.push('/sales-manager/dashboard')
  }

  const navigateToOpsManagerDashboard = () => {
    history.push('/ops-manager/dashboard')
  }

  return (
    <React.Fragment>
      {userProfile && (
        <EuiHeader>
          <EuiHeaderSection grow={false}>
            <EuiHeaderSectionItem>
              <EuiHeaderLogo iconType='/logo.png' onClick={navigateHome}>
                AdCritter Ops
              </EuiHeaderLogo>
            </EuiHeaderSectionItem>
            <EuiHeaderLinks aria-label='AdCritter Ops'>
              {userProfile && userProfile.roles.includes('adcritter-ops-csuite') && <EuiHeaderLink onClick={navigateToOpsManagerDashboard}>Ops Manager</EuiHeaderLink>}
              <EuiHeaderLink onClick={navigateToAccountManagerDashboard}>Account Manager</EuiHeaderLink>
              {userProfile && (userProfile.roles.includes('adcritter-ops-csuite') || userProfile.roles.includes('adcritter-ops-salesmanager') || userProfile.roles.includes('adcritter-ops-finance')) && <EuiHeaderLink onClick={navigateToSalesManagerDashboard}>Sales Manager</EuiHeaderLink>}
              <EuiHeaderLink onClick={navigateToAgencyManagerDashboard}>Agency Manager</EuiHeaderLink>
              <EuiHeaderLink onClick={navigateToAdvisorDashboard}>Campaign Advisor</EuiHeaderLink>
              <EuiHeaderLink onClick={navigateToTools}>Tools</EuiHeaderLink>
              {userProfile && (userProfile.roles.includes('adcritter-ops-csuite') || userProfile.roles.includes('adcritter-ops-vistarupload')) && <LegacyMenu />}
              <AnomaliesHeaderLink />
            </EuiHeaderLinks>
          </EuiHeaderSection>
          <EuiHeaderSection side='right'>
            <EuiHeaderSectionItem>{search}</EuiHeaderSectionItem>
            <EuiHeaderSectionItemButton aria-label='Account menu' style={{ paddingTop: 5 }} onClick={() => setIsOpen(!isOpen)}>
              <EuiPopover button={<EuiAvatar name={userProfile.username} size='s' imageUrl={profilePic ?? ''} />} isOpen={isOpen} anchorPosition='downRight' closePopover={closeMenu} panelPaddingSize='none'>
                <div style={{ width: 320 }}>
                  <EuiFlexGroup gutterSize='m' className='euiHeaderProfile' responsive={false}>
                    <EuiFlexItem grow={false}>
                      <EuiAvatar name={userProfile.username} imageUrl={profilePic ?? ''} size='xl' />
                    </EuiFlexItem>

                    <EuiFlexItem>
                      <EuiText>
                        <p>{userProfile.name}</p>
                      </EuiText>

                      <EuiSpacer size='m' />

                      <EuiFlexGroup>
                        <EuiFlexItem>
                          <EuiFlexGroup justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                              <EuiLink onClick={logout}>Log out</EuiLink>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </div>
              </EuiPopover>
            </EuiHeaderSectionItemButton>
          </EuiHeaderSection>
        </EuiHeader>
      )}
    </React.Fragment>
  )
}

export default HeaderBar
