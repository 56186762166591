import Konva from 'konva'
import React, { useEffect, useState } from 'react'
import { Transformer } from 'react-konva'

import { BillboardTextComponent } from 'api'
import { BillboardTextComponentsFontLoaded } from 'utils/KonvaTools/BillboardTextComponentsFontLoaded'

import { ACText } from './ACText'

interface IResizableBillboardTextComponent {
  textComponent: BillboardTextComponent
  onDragEnd: (event: Konva.KonvaEventObject<DragEvent>) => void
  onChange?: (x: number, y: number, width: number, height: number) => void
  isSelected?: boolean
  onClick?: () => void
  name?: string
  draggable?: boolean
}

const ResizableBillboardTextComponent: React.FC<IResizableBillboardTextComponent> = props => {
  const shapeRef = React.useRef<any>()
  const trRef = React.useRef<any>()
  const [, setIsSelected] = useState(false)
  const [fontsLoaded, setFontsLoaded] = useState(false)

  useEffect(() => {
    let count = 0
    const interval = setInterval(() => {
      const loaded = BillboardTextComponentsFontLoaded([props.textComponent])
      if (loaded) {
        clearInterval(interval)
        setFontsLoaded(loaded)
      } else if (count > 5) {
        setFontsLoaded(true)
        clearInterval(interval)
      }
      count++
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [props.textComponent])

  React.useEffect(() => {
    if (props.isSelected) {
      trRef.current?.nodes([shapeRef.current])
    }
  }, [props.isSelected])

  const onSelect = () => {
    setIsSelected(true)
  }

  return (
    <React.Fragment>
      <ACText
        text={props.textComponent.sampleText}
        onClick={onSelect}
        onTap={onSelect}
        shapeRef={shapeRef}
        fontSize={props.textComponent.fontSize}
        fill={props.textComponent.fontColor}
        fontFamily={fontsLoaded ? props.textComponent.fontFace : undefined}
        height={props.textComponent.height}
        width={props.textComponent.width}
        x={props.textComponent.x}
        y={props.textComponent.y}
        align={props.textComponent.align}
        draggable
        name={props.name}
        onDragEnd={e => {
          if (props.onChange) props.onChange(e.target.x(), e.target.y(), props.textComponent.width ?? 500, props.textComponent.height ?? 80)
        }}
        onTransformEnd={() => {
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          node.scaleX(1)
          node.scaleY(1)
          if (props.onChange)
            props.onChange(
              node.x(),
              node.y(),

              Math.max(5, node.width() * scaleX),
              Math.max(node.height() * scaleY)
            )
        }}
      />

      {props.isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['middle-left', 'middle-right', 'top-center', 'bottom-center']}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </React.Fragment>
  )
}

export default ResizableBillboardTextComponent
