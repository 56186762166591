import { Category, ExecutiveDashboardCustomer, ExecutiveDashboardCampaign, ExecutiveDashboardCampaignChange, ExecutiveDashboardCampaignSum, ExecutiveDashboardPackage, ExecutiveDashboardPackageChange, ExecutiveDashboardPackageSum, ExecutiveDashboardPlan, ExecutiveDashboardPlanChange, ExecutiveDashboardPlanSum, ExecutiveDashboardSubscription, ExecutiveDashboardSubscriptionSum, AllowedDates } from 'api/entities/ExecutiveDashboard'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const newExecutiveDashboardTags = opsApi.enhanceEndpoints({
  addTagTypes: ['ExecutiveDashboardDates', 'ExecutiveDashboardCustomer', 'ExecutiveDashboardCampaign', 'ExecutiveDashboardCampaignSum', 'ExecutiveDashboardCampaignChange', 'ExecutiveDashboardPlan', 'ExecutiveDashboardPlanSum', 'ExecutiveDashboardPlanChange', 'ExecutiveDashboardPackage', 'ExecutiveDashboardPackageSum', 'ExecutiveDashboardPackageChange', 'ExecutiveDashboardSubscription', 'ExecutiveDashboardSubscriptionSum']
})

const newExecutiveDashboardApi = newExecutiveDashboardTags.injectEndpoints({
  endpoints: build => ({
    getExecutiveDashboardDates: build.query<AllowedDates, void>({
      query: () => `executiveDashboard/dates`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardDates', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardCustomer: build.query<ExecutiveDashboardCustomer, { forDate: string | undefined }>({
      query: ({ forDate }) => `executiveDashboard/customers/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardCustomer', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardCampaign: build.query<ExecutiveDashboardCampaign, { channel: string | undefined; forDate: string | undefined }>({
      query: ({ channel, forDate }) => `executiveDashboard/campaigns/${(channel ?? 'all').toLowerCase()}/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardCampaign', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardCampaignSum: build.query<ExecutiveDashboardCampaignSum[], { channel: string | undefined; daysOfHistory: number | undefined; forDate: string | undefined }>({
      query: ({ channel, daysOfHistory, forDate }) => `executiveDashboard/campaigns/sum/${(channel ?? 'all').toLowerCase()}/?daysOfHistory=${daysOfHistory}&forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardCampaignSum', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardCampaignChange: build.query<ExecutiveDashboardCampaignChange, { channel: string | undefined; forDate: string | undefined }>({
      query: ({ channel, forDate }) => `executiveDashboard/campaigns/changes/${(channel ?? 'all').toLowerCase()}/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardCampaignChange', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardPlan: build.query<ExecutiveDashboardPlan, { category: Category | undefined; forDate: string | undefined }>({
      query: ({ category, forDate }) => `executiveDashboard/plans/${Object.keys(Category)[Object.values(Category).indexOf(category ?? Category.Regular)].toLowerCase()}/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardPlan', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardPlanSum: build.query<ExecutiveDashboardPlanSum[], { category: Category | undefined; daysOfHistory: number | undefined; forDate: string | undefined }>({
      query: ({ category, daysOfHistory, forDate }) => `executiveDashboard/plans/sum/${Object.keys(Category)[Object.values(Category).indexOf(category ?? Category.Regular)].toLowerCase()}/?daysOfHistory=${daysOfHistory}&forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardPlanSum', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardPlanChange: build.query<ExecutiveDashboardPlanChange, { category: Category | undefined; forDate: string | undefined }>({
      query: ({ category, forDate }) => `executiveDashboard/plans/changes/${Object.keys(Category)[Object.values(Category).indexOf(category ?? Category.Regular)].toLowerCase()}/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardPlanChange', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardPackage: build.query<ExecutiveDashboardPackage, { forDate: string | undefined }>({
      query: ({ forDate }) => `executiveDashboard/packages/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardPackage', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardPackageSum: build.query<ExecutiveDashboardPackageSum[], { daysOfHistory: number | undefined; forDate: string | undefined }>({
      query: ({ daysOfHistory, forDate }) => `executiveDashboard/packages/sum/?daysOfHistory=${daysOfHistory}&forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardPackageSum', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardPackageChange: build.query<ExecutiveDashboardPackageChange, { forDate: string | undefined }>({
      query: ({ forDate }) => `executiveDashboard/packages/changes/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardPackageChange', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardSubscription: build.query<ExecutiveDashboardSubscription, { forDate: string | undefined }>({
      query: ({ forDate }) => `executiveDashboard/subscriptions/?forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardSubscription', id: JSON.stringify(query) }]
    }),
    getExecutiveDashboardSubscriptionSum: build.query<ExecutiveDashboardSubscriptionSum[], { daysOfHistory: number | undefined; forDate: string | undefined }>({
      query: ({ daysOfHistory, forDate }) => `executiveDashboard/subscriptions/sum/?daysOfHistory=${daysOfHistory}&forDate=${forDate}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'ExecutiveDashboardSubscriptionSum', id: JSON.stringify(query) }]
    })
  }),
  overrideExisting: false
})

export const {
  useGetExecutiveDashboardDatesQuery,
  useGetExecutiveDashboardCustomerQuery,
  useGetExecutiveDashboardCampaignQuery,
  useGetExecutiveDashboardCampaignSumQuery,
  useGetExecutiveDashboardCampaignChangeQuery,
  useGetExecutiveDashboardPlanQuery,
  useGetExecutiveDashboardPlanChangeQuery,
  useGetExecutiveDashboardPlanSumQuery,
  useGetExecutiveDashboardPackageQuery,
  useGetExecutiveDashboardPackageSumQuery,
  useGetExecutiveDashboardPackageChangeQuery,
  useGetExecutiveDashboardSubscriptionQuery,
  useGetExecutiveDashboardSubscriptionSumQuery
} = newExecutiveDashboardApi
