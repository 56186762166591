import moment, { Moment } from 'moment/moment'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButtonEmpty, EuiDatePicker, EuiFieldNumber, EuiFieldText, EuiFormRow, EuiHorizontalRule, EuiRadioGroup, EuiSpacer, EuiSwitch, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { CampaignType } from 'api'
import { BundleCategory, BundleType, CampaignSlotSimple, IntervalUnit, TargetingPriority } from 'api/entities/Bundle'
import { SelfServicePackageType } from 'api/entities/SelfServicePackage'
import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'
import IntervalUnitFrequency from 'components/IntervalUnitFrequency'
import { BundleCategoryPicker } from 'features/customers/bundles/BundleCategoryPicker'
import { BundleSlotCreationFormRow } from 'features/customers/bundles/BundleSlotCreationFormRow'
import { SelfServicePackageTemplateSelectModal } from 'features/customers/bundles/SelfServicePackageTemplateSelectModal'
import { TargetingPrioritySelect } from 'features/customers/bundles/TargetingPrioritySelect'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface ISelfServicePackageTemplateForm {
  bundleTemplate: SelfServicePackageTemplate
  onChange: (selfServePackage: SelfServicePackageTemplate) => void
  hideTemplateSelect?: boolean
}

export const SelfServicePackageTemplateForm: React.FC<ISelfServicePackageTemplateForm> = props => {
  const [showTemplateSelect, setShowTemplateSelect] = React.useState(false)
  const defaultMonthlyBudget = 1000

  const onNameChange = (name: string) => {
    props.onChange({ ...props.bundleTemplate, templateName: name })
  }

  const onSelfServicePackageTypeChange = (packageType: SelfServicePackageType) => {
    if (packageType === SelfServicePackageType.Specific) {
      const bonusBudget = (defaultMonthlyBudget * props.bundleTemplate.matchingPercentage) / 100
      const paidSlots = [{ id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: defaultMonthlyBudget }]
      const matchingSlots = [{ id: uuidv4(), campaignType: CampaignType.Internet, isBonus: true, budget: bonusBudget }]
      props.onChange({ ...props.bundleTemplate, campaignSlots: [...paidSlots, ...matchingSlots], selfServicePackageType: packageType })
    }

    if (packageType === SelfServicePackageType.Percent) {
      props.onChange({ ...props.bundleTemplate, campaignSlots: [], selfServicePackageType: packageType })
    }
  }

  const onSalesTitleChange = (title: string) => {
    props.onChange({ ...props.bundleTemplate, salesTitle: title })
  }

  const onSalesDescriptionChange = (value: string) => {
    props.onChange({ ...props.bundleTemplate, salesDescription: value })
  }

  const onSalesShortDescriptionChange = (value: string) => {
    props.onChange({ ...props.bundleTemplate, salesShortDescription: value })
  }

  const onMinimumAmountChange = (amount: number) => {
    props.onChange({ ...props.bundleTemplate, minimumAmount: amount })
  }

  const onMaximumAmountChange = (amount: number) => {
    props.onChange({ ...props.bundleTemplate, maximumAmount: amount })
  }

  const onMatchingPercentChange = (percent: number) => {
    props.onChange({ ...props.bundleTemplate, matchingPercentage: percent })
  }

  const onExpirationDateChange = (date: Moment) => {
    props.onChange({ ...props.bundleTemplate, expirationDate: date })
  }

  const onSubscriptionIncentiveDurationChange = (value: number) => {
    if (value > 0) {
      props.onChange({ ...props.bundleTemplate, subscriptionIncentiveDuration: value })
    }
  }
  const onMatchingIncentiveDurationChange = (value: number) => {
    if (value > 0) {
      props.onChange({ ...props.bundleTemplate, matchingIncentiveDuration: value })
    }
  }

  const onAddCampaignSlot = (campaignSlot: CampaignSlotSimple) => {
    props.onChange({ ...props.bundleTemplate, campaignSlots: [...props.bundleTemplate.campaignSlots, campaignSlot] })
  }

  const onRemoveCampaignSlot = (campaignSlot: CampaignSlotSimple) => {
    props.onChange({ ...props.bundleTemplate, campaignSlots: props.bundleTemplate.campaignSlots.filter(slot => slot.id !== campaignSlot.id) })
  }

  const onUpdateCampaignSlot = (campaignSlot: CampaignSlotSimple) => {
    const replaceSlot = (slot: CampaignSlotSimple) => {
      if (slot.id === campaignSlot.id) {
        return campaignSlot
      }
      return slot
    }
    props.onChange({ ...props.bundleTemplate, campaignSlots: props.bundleTemplate.campaignSlots.map(replaceSlot) })
  }

  const onPackageCategoryChange = (category: BundleCategory) => {
    props.onChange({ ...props.bundleTemplate, bundleCategory: category })
  }

  const onTargetingPriorityChange = (targetingPriority: TargetingPriority) => {
    props.onChange({ ...props.bundleTemplate, targetingPriority })
  }

  const onIncludeSubscriptionChange = () => {
    props.onChange({ ...props.bundleTemplate, includeSubscription: !props.bundleTemplate.includeSubscription })
  }

  const onIncludeMatchingChange = () => {
    props.onChange({ ...props.bundleTemplate, includeMatching: !props.bundleTemplate.includeMatching })
  }

  const onIntervalUnitChange = (intervalUnit: IntervalUnit) => {
    props.onChange({ ...props.bundleTemplate, intervalUnit })
  }

  const onIntervalUnitFrequencyChange = (intervalUnitFrequency: number) => {
    props.onChange({ ...props.bundleTemplate, intervalUnitFrequency })
  }

  const onTotalIntervalsChange = (totalIntervals: number) => {
    props.onChange({ ...props.bundleTemplate, totalIntervals })
  }

  const onTemplateSelected = (template: SelfServicePackageTemplate) => {
    props.onChange({
      id: uuidv4(),
      salesTitle: template.salesTitle,
      salesShortDescription: template.salesShortDescription,
      salesDescription: template.salesDescription,
      selfServicePackageType: template.selfServicePackageType,
      selfServicePackageStatus: template.selfServicePackageStatus,
      proposalText: template.proposalText,
      subscriptionIncentiveDuration: 12,
      matchingIncentiveDuration: 12,
      campaignSlots: template.campaignSlots.map(slot => ({ ...slot, id: uuidv4() })),
      matchingPercentage: template.matchingPercentage,
      minimumAmount: template.minimumAmount,
      maximumAmount: template.maximumAmount,
      expirationDate: moment().add(1, 'month'),
      includeSubscription: false,
      includeMatching: true,
      intervalUnit: IntervalUnit.Month,
      totalIntervals: 12,
      intervalUnitFrequency: 1,
      foreverBundle: true,
      templateName: template.templateName,
      bundleCategory: BundleCategory.SelfService,
      targetingPriority: TargetingPriority.Location
    })

    setShowTemplateSelect(false)
  }

  const selfServicePackageTypeOptionRadios = Object.keys(SelfServicePackageType).map(key => {
    return {
      id: key,
      label: insertSpaces(key)
    }
  })

  // @ts-ignore
  return (
    <React.Fragment>
      {!props.hideTemplateSelect && (
        <EuiFormRow>
          <EuiButtonEmpty
            color={'primary'}
            onClick={() => {
              setShowTemplateSelect(true)
            }}
            iconType={'download'}>
            Use Template
          </EuiButtonEmpty>
        </EuiFormRow>
      )}
      <EuiFormRow label={`Bundle Name`}>
        <EuiFieldText
          value={props.bundleTemplate.templateName}
          onChange={e => {
            onNameChange(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow label='Self Service Package Type'>
        <EuiRadioGroup
          options={selfServicePackageTypeOptionRadios}
          idSelected={props.bundleTemplate.selfServicePackageType}
          onChange={value => {
            onSelfServicePackageTypeChange(value as SelfServicePackageType)
          }}
          name='type radio group'
        />
      </EuiFormRow>
      <EuiFormRow label='Title'>
        <EuiFieldText
          value={props.bundleTemplate.salesTitle}
          onChange={e => {
            onSalesTitleChange(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow label='Description'>
        <EuiTextArea
          value={props.bundleTemplate.salesDescription}
          onChange={e => {
            onSalesDescriptionChange(e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow label='Short Description'>
        <EuiFieldText
          value={props.bundleTemplate.salesShortDescription}
          onChange={e => {
            onSalesShortDescriptionChange(e.target.value)
          }}
        />
      </EuiFormRow>
      {props.bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent && (
        <React.Fragment>
          <EuiFormRow label='Minimum Amount'>
            <EuiFieldNumber
              prepend={
                <EuiText size='xs'>
                  <strong>$</strong>
                </EuiText>
              }
              placeholder={'Minimum Amount'}
              value={props.bundleTemplate.minimumAmount}
              onChange={e => onMinimumAmountChange(parseInt(e.target.value))}
            />
          </EuiFormRow>
          <EuiFormRow label='Maximum Amount'>
            <EuiFieldNumber
              prepend={
                <EuiText size='xs'>
                  <strong>$</strong>
                </EuiText>
              }
              placeholder={'Maximum Amount'}
              value={props.bundleTemplate.maximumAmount}
              onChange={e => onMaximumAmountChange(parseInt(e.target.value))}
            />
          </EuiFormRow>
          <EuiFormRow label='Matching Percentage'>
            <EuiFieldNumber
              prepend={
                <EuiText size='xs'>
                  <strong>%</strong>
                </EuiText>
              }
              placeholder={'Matching Percentage'}
              value={props.bundleTemplate.matchingPercentage}
              onChange={e => onMatchingPercentChange(parseInt(e.target.value))}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      <EuiFormRow label='Expiration Date'>
        <EuiDatePicker selected={props.bundleTemplate.expirationDate} onChange={onExpirationDateChange} />
      </EuiFormRow>
      <EuiFormRow label='Category'>
        <BundleCategoryPicker bundleType={BundleType.SelfServicePackage} onBadgeClick={onPackageCategoryChange} selectedKey={props.bundleTemplate.bundleCategory} />
      </EuiFormRow>
      <EuiFormRow label='Targeting'>
        <TargetingPrioritySelect onChange={onTargetingPriorityChange} selectedKey={props.bundleTemplate.targetingPriority} />
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle size={'xs'}>
        <h2>Billing Information</h2>
      </EuiTitle>
      <EuiHorizontalRule margin={'s'} />
      <EuiFormRow label='Billing Cycle'>
        <IntervalUnitFrequency
          price={0}
          intervalUnitFrequency={props.bundleTemplate.intervalUnitFrequency}
          onIntervalUnitFrequencyChange={e => onIntervalUnitFrequencyChange(parseInt(e.target.value))}
          intervalUnit={props.bundleTemplate.intervalUnit}
          onIntervalUnitChange={e => onIntervalUnitChange(e.target.value as IntervalUnit)}
          totalIntervals={props.bundleTemplate.totalIntervals}
          onTotalIntervalsChange={e => onTotalIntervalsChange(parseInt(e.target.value))}
          foreverBundle={props.bundleTemplate.foreverBundle}
        />
      </EuiFormRow>
      <EuiSpacer size={'s'} />
      <EuiTitle size={'xs'}>
        <h2>Incentives</h2>
      </EuiTitle>
      <EuiHorizontalRule margin={'s'} />
      <EuiFormRow helpText={props.bundleTemplate.includeSubscription ? `Subscription price is included with the ${insertSpaces(BundleType.SelfServicePackage)}.` : undefined}>
        <EuiSwitch label={'Include Subscription'} checked={props.bundleTemplate.includeSubscription} onChange={onIncludeSubscriptionChange} id={'includeSubscription'} />
      </EuiFormRow>
      {props.bundleTemplate.includeSubscription && (
        <EuiFormRow label='Subscription Incentive Duration'>
          <EuiFieldNumber
            placeholder={'Subscription Incentive Duration'}
            value={props.bundleTemplate.subscriptionIncentiveDuration}
            step={1}
            onChange={e => {
              onSubscriptionIncentiveDurationChange(parseInt(e.target.value))
            }}
            append={props.bundleTemplate.subscriptionIncentiveDuration > 1 ? props.bundleTemplate.intervalUnit + 's' : props.bundleTemplate.intervalUnit}
          />
        </EuiFormRow>
      )}
      <EuiFormRow fullWidth>
        <EuiSwitch label={'Include Matching'} checked={props.bundleTemplate.includeMatching} onChange={onIncludeMatchingChange} id={'includeMatching'} />
      </EuiFormRow>
      {props.bundleTemplate.includeMatching && (
        <EuiFormRow label='Matching Incentive Duration'>
          <EuiFieldNumber
            placeholder={'Matching Incentive Duration'}
            value={props.bundleTemplate.matchingIncentiveDuration}
            step={1}
            onChange={e => {
              onMatchingIncentiveDurationChange(parseInt(e.target.value))
            }}
            append={props.bundleTemplate.matchingIncentiveDuration > 1 ? props.bundleTemplate.intervalUnit + 's' : props.bundleTemplate.intervalUnit}
          />
        </EuiFormRow>
      )}
      <EuiSpacer />
      <EuiTitle size={'xs'}>
        <h2>Paid Campaign Slots</h2>
      </EuiTitle>
      <EuiSpacer size='xs' />
      <BundleSlotCreationFormRow addSlotButtonText={'Add Paid Slot'} showTypeOnly={props.bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent} bundleType={BundleType.SelfServicePackage} campaignSlots={props.bundleTemplate.campaignSlots.filter(c => !c.isBonus)} onCampaignSlotRemoved={onRemoveCampaignSlot} onCampaignSlotUpdated={onUpdateCampaignSlot} onCampaignSlotAdd={onAddCampaignSlot} />
      <BundleSlotCreationFormRow isBonus addSlotButtonText={'Add Matching Slot'} showTypeOnly={props.bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent} bundleType={BundleType.SelfServicePackage} campaignSlots={props.bundleTemplate.campaignSlots.filter(c => c.isBonus)} onCampaignSlotRemoved={onRemoveCampaignSlot} onCampaignSlotUpdated={onUpdateCampaignSlot} onCampaignSlotAdd={onAddCampaignSlot} />

      {showTemplateSelect && (
        <SelfServicePackageTemplateSelectModal
          onConfirm={onTemplateSelected}
          onClose={() => {
            setShowTemplateSelect(false)
          }}
        />
      )}
    </React.Fragment>
  )
}
