import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiForm, EuiFormRow, EuiRadio, EuiSpacer, EuiText } from '@elastic/eui'

import { ApiError, Campaign, City, useOpsClient, Zip } from 'api'
import { DmaFinder, StateFinder, ZipFinder, CityFinder } from 'components/Finders'
import { CityTargeter, DmaTargeter, StateTargeter, ZipTargeter } from 'components/Targeters'
import TargetingMap from 'components/TargetingMap'

interface BillboardDetailsTabParams {
  campaign: Campaign
}

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
}

const BillboardTargetingTab: React.FC<BillboardDetailsTabParams> = ({ campaign }) => {
  const opsClient = useOpsClient()
  const [suggestedPlaces, setSuggestedPlace] = useState<string[]>([])
  const [initialValues, setInitialValues] = useState<FormValues>({
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: []
  })

  useEffect(() => {
    if (opsClient && campaign && campaign.billboardTargeting) {
      setInitialValues({
        locationType: campaign.billboardTargeting.locationType ?? 'USA',
        states: campaign.billboardTargeting.states ?? [],
        dmas: campaign.billboardTargeting.dmas ?? [],
        cities: campaign.billboardTargeting.cities ?? [],
        zips: campaign.billboardTargeting.zips ?? []
      })
    }
  }, [opsClient, campaign])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 DMA/metro area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please add at least 1 city').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please add at least 1 zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    })
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      if (opsClient) {
        opsClient
          .apiPutCampaignBillboardTargeting(campaign.campaignId, {
            locationType: values.locationType,
            states: values.states,
            dmas: values.dmas,
            cities: values.cities.map(c => c.cityId),
            zips: values.zips.map(z => z.zipId)
          })
          .then(() => {
            formik.setSubmitting(false)
            formik.setStatus(null)
          })
          .catch(response => {
            formik.setSubmitting(false)
            response.errors.forEach(function (error: ApiError) {
              formik.setFieldError(error.name, error.message)
            })
          })
      }
    }
  })

  useEffect(() => {
    setSuggestedPlace(getSuggestedPlaces())
  }, [formik.values])

  const getSuggestedPlaces = useCallback(() => {
    switch (formik.values.locationType) {
      case 'State':
        return formik.values.states
      case 'DMA':
        return formik.values.dmas
      case 'City':
        return formik.values.cities.map(c => `${c.name}, ${c.state}`)
      case 'Zip':
        return formik.values.zips.map(z => z.name)
      default:
        return []
    }
  }, [formik.values])

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiRadio
          id='usa'
          name='locationType'
          value='USA'
          label={
            <EuiText size='s'>
              <strong>Targeting the USA</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'USA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'USA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='state'
          name='locationType'
          value='State'
          label={
            <EuiText size='s'>
              <strong>Targeting using state</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'State'}
          onChange={() => {
            formik.setFieldValue('locationType', 'State', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='dma'
          name='locationType'
          value='DMA'
          label={
            <EuiText size='s'>
              <strong>Targeting using DMA/metro area</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'DMA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'DMA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='city'
          name='locationType'
          value='City'
          label={
            <EuiText size='s'>
              <strong>Targeting using city</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'City'}
          onChange={() => {
            formik.setFieldValue('locationType', 'City', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='zip'
          name='locationType'
          value='Zip'
          label={
            <EuiText size='s'>
              <strong>Targeting using zip code</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'Zip'}
          onChange={() => {
            formik.setFieldValue('locationType', 'Zip', true)
          }}
        />

        {formik.values.locationType === 'State' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a state' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
              <StateFinder
                addState={stateToAdd => {
                  formik.setFieldValue('states', [...formik.values.states, stateToAdd])
                }}
                isInvalid={!!formik.errors.states}
              />
            </EuiFormRow>
            {formik.values.states && (
              <EuiFormRow label='The campaign will target these states:' fullWidth>
                <StateTargeter
                  states={formik.values.states}
                  onStateRemoved={stateToRemove =>
                    formik.setFieldValue(
                      'states',
                      formik.values.states.filter(x => x !== stateToRemove),
                      true
                    )
                  }
                />
              </EuiFormRow>
            )}
          </React.Fragment>
        )}
        {formik.values.locationType === 'DMA' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
              <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
            </EuiFormRow>
            <EuiFormRow label='The campaign will target these DMAs:' fullWidth>
              <DmaTargeter
                dmas={formik.values.dmas}
                onDmaRemoved={dmaToRemove =>
                  formik.setFieldValue(
                    'dmas',
                    formik.values.dmas.filter(x => x !== dmaToRemove),
                    true
                  )
                }
              />
            </EuiFormRow>
          </React.Fragment>
        )}

        {formik.values.locationType === 'City' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
              <CityFinder addCity={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
            </EuiFormRow>
            <EuiFormRow label='The campaign will target these cities:' fullWidth>
              <CityTargeter
                cities={formik.values.cities}
                onCityRemoved={city => {
                  formik.setFieldValue(
                    'cities',
                    formik.values.cities.filter(c => c.cityId !== city.cityId)
                  )
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}

        {formik.values.locationType === 'Zip' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
              <ZipFinder addZip={zip => formik.setFieldValue('zips', [...formik.values.zips, zip])} isInvalid={!!formik.errors.zips} />
            </EuiFormRow>
            <EuiFormRow label='The campaign will target these zip codes:' fullWidth>
              <ZipTargeter
                zips={formik.values.zips}
                onZipRemoved={zip => {
                  formik.setFieldValue(
                    'zips',
                    formik.values.zips.filter(z => z.zipId !== zip.zipId)
                  )
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        <EuiSpacer />
        <EuiFormRow fullWidth>
          <TargetingMap hideDrawingTools hideLocationSearch suggestedPlaces={suggestedPlaces} includeBillboards geoCircles={[]} addCircle={() => {}} removeCircle={() => {}} modifyCircle={() => {}} geoRectangles={[]} addRectangle={() => {}} removeRectangle={() => {}} modifyRectangle={() => {}} geoPolygons={[]} addPolygon={() => {}} removePolygon={() => {}} modifyPolygon={() => {}} />
        </EuiFormRow>
        <EuiSpacer />

        <EuiButton fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    </React.Fragment>
  )
}

export default BillboardTargetingTab
