import { Campaign, Organization } from 'api'

export enum Partner {
  Shopify,
  Unspecified
}

export const GetPartnerForCampaign = (campaign: Campaign) => {
  if (campaign.targetingTemplate === 'Shopify' || campaign.targetingTemplate === 'ShopifyTV' || campaign.targetingTemplate === 'ShopifyBillboard') {
    return Partner.Shopify
  }

  return Partner.Unspecified
}

export const GetPartnerForOrganization = (organization: Organization) => {
  if (organization.signupMethod === 'Shopify') {
    return Partner.Shopify
  }

  return Partner.Unspecified
}
