import { KonvaEventObject } from 'konva/lib/Node'
import React, { useEffect } from 'react'
import { Image } from 'react-konva'
import useImage from 'use-image'

export interface IACImage {
  backgroundImageUrl?: string
  height?: number
  width?: number
  viewportWidth?: number
  viewportHeight?: number
  x?: number
  y?: number
  draggable?: boolean
  onDragStart?: (event: KonvaEventObject<DragEvent>) => void
  onDragEnd?: (event: KonvaEventObject<DragEvent>) => void
  onClick?: () => void
  onTap?: () => void
  getImageRef?: (ref: React.MutableRefObject<any>) => void
  onTransformEnd?: (event: KonvaEventObject<DragEvent>) => void
  onChange?: (i: {}) => void
  name?: string
}

export const ACImage: React.FC<IACImage> = props => {
  const [image] = useImage(`${props.backgroundImageUrl}`, 'anonymous')
  const imageRef = React.createRef<any>()

  const getImageRef = props.getImageRef
  useEffect(() => {
    if (getImageRef) {
      getImageRef(imageRef)
    }
  }, [imageRef, getImageRef])

  useEffect(() => {
    const node = imageRef.current
    if (!node || !props.viewportHeight || !props.viewportWidth || !props.height || !props.width) {
      return
    }
    const xScale = props.width / props.viewportWidth
    const yScale = props.height / props.viewportHeight
    node.scaleX(xScale)
    node.scaleY(yScale)
  }, [imageRef.current, props.viewportHeight, props.viewportWidth, props.width, props.height])

  return <Image ref={imageRef} image={image} onClick={props.onClick} height={props.viewportHeight ?? props.height} width={props.viewportWidth ?? props.width} x={props.x} y={props.y} draggable={props.draggable} onDragStart={props.onDragStart} onDragEnd={props.onDragEnd} onTransformEnd={props.onTransformEnd} name={props.name} />
}
