import Konva from 'konva'
import React from 'react'
import { Layer, Stage } from 'react-konva'
import { v4 as uuidv4 } from 'uuid'

import { EuiButtonEmpty, EuiFormRow } from '@elastic/eui'

import { NativeDetails } from 'api'
import { AdCreatorLargeBanner } from 'components/AdCreation/AdCreators/AdCreatorLargeBanner'
import { AdCreatorRectangle } from 'components/AdCreation/AdCreators/AdCreatorRectangle'
import { AdCreatorTowerBottom } from 'components/AdCreation/AdCreators/AdCreatorTowerBottom'
import { AdFormat, ColorPalette, ContentSpecifications, TempDisplayAd } from 'components/AdCreation/DisplayMaker'
import { ACImage } from 'components/Basic/ACImage'

export interface IAdSampleCollage {
  topLeftAd?: TempDisplayAd
  topRightAd?: TempDisplayAd
  bottomAd?: TempDisplayAd
  uploadedUrl: string
  details: NativeDetails
  colorPalette: ColorPalette
  contentSpecifications: ContentSpecifications
  onCapture?: (captureImage: TempDisplayAd) => void
}

export const AdSampleCollage: React.FC<IAdSampleCollage> = props => {
  const stageRef = React.useRef<Konva.Stage>(null)
  const templateLayerRef = React.useRef<Konva.Layer>(null)
  const baseHeight = 381
  const baseWidth = 400

  const rectScale = 0.8
  const towerScale = 0.38
  const bannerScale = 0.38

  const onStageClick = () => {
    if (stageRef.current === null) {
      return
    }
    const uri = stageRef.current.toDataURL({ pixelRatio: 2 })
    const link = document.createElement('a')
    link.download = 'image.png'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onCaptureEmailTemplateClick = () => {
    if (props.onCapture && stageRef.current) {
      props.onCapture({
        key: uuidv4(),
        adUri: stageRef.current.toDataURL({ pixelRatio: 2 }),
        width: baseWidth,
        height: baseHeight,
        destinationUrl: ''
      })
    }
  }

  return (
    <React.Fragment>
      {props.onCapture && (
        <EuiFormRow fullWidth>
          <EuiButtonEmpty onClick={onCaptureEmailTemplateClick}>Capture Collage</EuiButtonEmpty>
        </EuiFormRow>
      )}
      <Stage scaleX={1} scaleY={1} width={baseWidth} height={baseHeight} ref={stageRef} onClick={onStageClick}>
        <Layer ref={templateLayerRef}>
          <ACImage backgroundImageUrl={'/Frame Ad Sample Collage.png'} key={'backgroundImage'} height={381} width={400} />
        </Layer>
        <AdCreatorRectangle scale={rectScale} xOffSet={-15 / rectScale} yOffSet={-18 / rectScale} details={props.details} uploadedUrl={props.uploadedUrl} adWidth={286 / rectScale} adHeight={237 / rectScale} format={AdFormat.Rectangle} colorPalette={props.colorPalette} contentSpecifications={props.contentSpecifications} />
        <AdCreatorTowerBottom scale={towerScale} xOffSet={-318 / towerScale} yOffSet={-18 / towerScale} details={props.details} uploadedUrl={props.uploadedUrl} adWidth={66 / towerScale} adHeight={237 / towerScale} format={AdFormat.LargeTower} colorPalette={props.colorPalette} contentSpecifications={props.contentSpecifications} />
        <AdCreatorLargeBanner scale={bannerScale} xOffSet={-15 / bannerScale} yOffSet={-270 / bannerScale} details={props.details} uploadedUrl={props.uploadedUrl} adWidth={370 / bannerScale} adHeight={92 / bannerScale} format={AdFormat.LargeBanner} colorPalette={props.colorPalette} contentSpecifications={props.contentSpecifications} />
      </Stage>
    </React.Fragment>
  )
}
