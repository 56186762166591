import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import {
  EuiLoadingContent,
  EuiSpacer,
  EuiDataGrid,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiDatePickerRange,
  EuiDatePicker,
  EuiButton,
  EuiFormRow
} from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { Account, useOpsClient } from 'api'

interface AccountImagesTabParams {
  account: Account
}

const AccountVisitsTab: React.FC<AccountImagesTabParams> = ({ account }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [visits, setVisits] = useState<any>([])
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment().add(0, 'd'))
  const [visibleColumns, setVisibleColumns] = useState(['url', 'referringUrl', 'title', 'timestamp', 'consumerId', 'creativeId', 'campaignId', 'campaignName', 'pageTitle', 'ipAddress', 'userAgent', 'platform'])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const [refreshKey, setRefreshKey] = useState(0)
  const opsClient = useOpsClient()

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoading(true)

      opsClient.getAccountVisits(account.accountId, start, end).then(r => {
        if (isMounted) {
          setVisits(r)
          setIsLoading(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, account, refreshKey, start, end])

  const columns: EuiDataGridColumn[] = [
    {
      id: 'timestamp',
      displayAsText: 'Date',
      schema: 'date'
    },
    {
      id: 'url',
      displayAsText: 'URL'
    },
    {
      id: 'referringUrl',
      displayAsText: 'Referring URL'
    },
    {
      id: 'consumerId',
      displayAsText: 'User ID'
    },
    {
      id: 'creativeId',
      displayAsText: 'Creative ID'
    },
    {
      id: 'campaignId',
      displayAsText: 'Campaign ID'
    },
    {
      id: 'campaignName',
      displayAsText: 'Campaign Name'
    },
    {
      id: 'pageTitle',
      displayAsText: 'Page Title'
    },
    {
      id: 'ipAddress',
      displayAsText: 'IP Address'
    },
    {
      id: 'userAgent',
      displayAsText: 'User Agent'
    },
    {
      id: 'platform',
      displayAsText: 'Platform'
    }
  ]

  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const [sortingColumns, setSortingColumns] = useState([])
  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId }: any) => {
      return visits.hasOwnProperty(rowIndex) && visits[rowIndex][columnId] ? visits[rowIndex][columnId] : null
    }
  }, [visits])

  const onUpdateButtonClick = () => {
    setRefreshKey(k => k + 1)
  }

  const dateRanges = [
    {
      value: 'last_7',
      inputDisplay: 'Last 7 Days'
    },
    {
      value: 'week',
      inputDisplay: 'This Week (so far)'
    },
    {
      value: 'last_30',
      inputDisplay: 'Last 30 Days'
    },
    {
      value: 'month',
      inputDisplay: 'This Month (so far)'
    },
    {
      value: 'last_90',
      inputDisplay: 'Last 90 Days'
    },
    {
      value: 'lifetime',
      inputDisplay: 'Lifetime'
    },
    {
      value: 'custom',
      inputDisplay: 'Custom'
    }
  ]
  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[0].value)
  const onDateRangeChange = (value: string) => {
    let today = moment()
    let yesterday = moment().add(-1, 'd')
    switch (value) {
      case 'last_7':
        setStart(today.add(-7, 'd'))
        setEnd(yesterday)
        break

      case 'week':
        setStart(today.startOf('week'))
        setEnd(yesterday)
        break

      case 'last_30':
        setStart(today.add(-31, 'd'))
        setEnd(yesterday)
        break

      case 'month':
        setStart(today.startOf('month'))
        setEnd(yesterday)
        break

      case 'last_90':
        setStart(today.add(-91, 'd'))
        setEnd(yesterday)
        break

      case 'lifetime':
        setStart(today.add(-50, 'y'))
        setEnd(yesterday)
        break
    }
    setSelectedDateRange(value)
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem>
          <EuiSuperSelect options={dateRanges} valueOfSelected={selectedDateRange} onChange={value => onDateRangeChange(value)} style={{ borderRadius: 0 }} />
        </EuiFlexItem>
        {selectedDateRange === 'custom' && (
          <EuiFlexItem>
            <EuiDatePickerRange
              fullWidth={false}
              startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
              endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiButton fill={true} onClick={onUpdateButtonClick} iconType='refresh'>
            Update
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      {isLoading && <EuiLoadingContent />}
      {!isLoading && (
        <EuiFormRow fullWidth>
          <EuiDataGrid
            aria-label='Visits'
            columns={columns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal' }}
            rowCount={visits.length}
            renderCellValue={renderCellValue}
            inMemory={{ level: 'sorting' }}
            sorting={{ columns: sortingColumns, onSort }}
            pagination={{
              ...pagination,
              pageSizeOptions: [50, 100, 200, 500],
              onChangeItemsPerPage,
              onChangePage
            }}
            toolbarVisibility={{
              additionalControls: (
                <React.Fragment>
                  <CSVLink data={visits} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`visits.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                  <span className='euiButtonContent euiButtonEmpty__content'>
                    <EuiIcon type='download' />
                    <span className='euiButton__text'>Download Report (CSV)</span>
                  </span>
                  </CSVLink>
                </React.Fragment>
              )
            }}
          />
        </EuiFormRow>
      )}
    </React.Fragment>
  )
}

export default AccountVisitsTab
