import React, { FC } from 'react'

import { EuiButton } from '@elastic/eui'

import { OrganizationProposal } from 'api'
import { useSetOrganizationProposalContactedMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'

export interface ContactedLostButtonsProps {
  proposal: OrganizationProposal
}

export const ContactedButton: FC<ContactedLostButtonsProps> = ({ proposal }) => {
  const [setOrganizationProposalContacted, contactedResult] = useSetOrganizationProposalContactedMutation()

  return (
    <EuiButton color={'primary'} onClick={() => setOrganizationProposalContacted(proposal.organizationId)} isLoading={contactedResult.isLoading}>
      Contacted
    </EuiButton>
  )
}
