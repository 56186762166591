import React from 'react'

import { EuiText } from '@elastic/eui'

import { CohortStatus } from 'api'

export interface ICohortStatusSymbol {
  cohortStatus?: CohortStatus
}
export const CohortStatusSymbol: React.FC<ICohortStatusSymbol> = props => {
  switch (props.cohortStatus) {
    case CohortStatus.Offered:
      return <EuiText size={'s'}>Offered</EuiText>
    case CohortStatus.Accepted:
      return <EuiText size={'s'}>Accepted</EuiText>
    case CohortStatus.Declined:
      return <EuiText size={'s'}>Declined</EuiText>
    case CohortStatus.Rejected:
      return <EuiText size={'s'}>Rejected</EuiText>
    case CohortStatus.Pending:
      return <EuiText size={'s'}>Pending</EuiText>
    default:
      return <React.Fragment />
  }
}
