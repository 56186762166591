import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EuiBreadcrumbs, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiTabbedContent, EuiBadge, EuiCopy } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

import UserDetailsTab from './UserDetailsTab'

interface UserPageParams {
  userId: string
}

const UserPage: React.FC = () => {
  let { userId } = useParams<UserPageParams>()
  const [name, setName] = useState('')
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      opsClient.getUser(userId).then(result => {
        setName(result.email)
      })
    }
  }, [opsClient, userId])

  const tabs: EuiTabbedContentTab[] = [{ id: 'details', name: 'Details', content: <UserDetailsTab userId={userId} /> }]

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Customers' },
              {
                text: 'Users',
                href: '/customers/users',
                onClick: () => history.push('/customers/users')
              },
              {
                text: name,
                href: `/customers/users/${userId}`,
                onClick: () => history.push(`/customers/users/${userId}`)
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>User</h1>
              </EuiTitle>
              <EuiCopy textToCopy={userId}>
                {copy => (
                  <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy User ID'>
                    {userId}
                  </EuiBadge>
                )}
              </EuiCopy>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default UserPage
