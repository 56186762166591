import React, { useEffect, useState } from 'react'

import { EuiSelect, EuiSelectOption } from '@elastic/eui'

import { AgencyDetails } from 'api'
import { useInviteToCohortMutation, useRemoveFromCohortMutation } from 'api/rtkQueryApi/opsApi/agencyDetailsApi'
import { useGetCohortsQuery } from 'api/rtkQueryApi/opsApi/cohortsApi'

export interface ISalesStageSelect {
  agencyDetails: AgencyDetails
}
export const AgencyCohortSelect: React.FC<ISalesStageSelect> = props => {
  const getCohortsQuery = useGetCohortsQuery()
  const [inviteToCohort] = useInviteToCohortMutation()
  const [removeCohort] = useRemoveFromCohortMutation()
  const [selectedCohortId, setSelectedCohortId] = useState<string>('unassigned')
  const [cohortOptions, setCohortOptions] = useState<EuiSelectOption[]>([{ value: 'unassigned', text: 'unassigned' }])

  useEffect(() => {
    if (props.agencyDetails) {
      setSelectedCohortId(props.agencyDetails.cohortId ?? 'unassigned')
    }
  }, [props.agencyDetails])

  useEffect(() => {
    if (getCohortsQuery.data) {
      const options = getCohortsQuery.data.map(cohort => {
        return { value: cohort.id, text: cohort.name }
      })
      options.push({ value: 'unassigned', text: 'unassigned' })
      setCohortOptions(options)
    }
  }, [getCohortsQuery.data])

  const onCohortSelectChange = (value: string) => {
    setSelectedCohortId(value)
    if (value !== 'unassigned' && props.agencyDetails?.id) {
      inviteToCohort({ salesAgencyDetailsId: props.agencyDetails.id, cohortId: value })
    } else if (props.agencyDetails?.id) {
      removeCohort({ salesAgencyDetailsId: props.agencyDetails.id })
    }
  }

  return <EuiSelect id={'SalesStageSelect'} options={cohortOptions} value={selectedCohortId} onChange={e => onCohortSelectChange(e.target.value)} aria-label='Agency Cohort' />
}
