import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiIcon, EuiLink, EuiPortal, EuiText, EuiTitle } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { Category } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardPlanQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'
import { insertSpaces } from 'utils/EnumToFriendly'

interface FlyOutParams {
  category: Category
  forDate: string
  title?: string
  planIds: string[] | null
}

const columns: EuiDataGridColumn[] = [
  {
    id: 'planId',
    displayAsText: 'Plan ID'
  },
  {
    id: 'organizationId',
    displayAsText: 'Organization ID',
    isSortable: false
  },
  {
    id: 'organizationName',
    displayAsText: 'Organization Name'
  },
  {
    id: 'organizationLink',
    displayAsText: 'Organization',
    isSortable: false,
    isExpandable: false
  },
  {
    id: 'planStatus',
    displayAsText: 'Status',
    isExpandable: false
  },
  {
    id: 'planCategory',
    displayAsText: 'Category',
    isExpandable: false
  },
  {
    id: 'price',
    displayAsText: 'Price',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'priceDescription',
    displayAsText: 'Price Desc.',
    isExpandable: false,
    isSortable: false
  },
  {
    id: 'numberSlots',
    displayAsText: 'Number Slots',
    isExpandable: false,
    isSortable: false
  },
  {
    id: 'numberFilledSlots',
    displayAsText: 'Number Filled Slots',
    isExpandable: false,
    isSortable: false
  },
  {
    id: 'numberActivatedSlots',
    displayAsText: 'Number Activated Slots',
    isExpandable: false,
    isSortable: false
  },
  {
    id: 'lastInvoiceAmount',
    displayAsText: 'Last Invoice',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'lastInvoiceDate',
    displayAsText: 'Last Invoice Date',
    schema: 'date',
    isExpandable: false
  },
  {
    id: 'expectedNextInvoiceAmount',
    displayAsText: 'Expected Next Invoice',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'expectedNextInvoiceDate',
    displayAsText: 'Expected Next Invoice Date',
    schema: 'date',
    isExpandable: false
  },
  {
    id: 'actualNextInvoiceAmount',
    displayAsText: 'Actual Next Invoice',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'actualNextInvoiceDate',
    displayAsText: 'Actual Next Invoice Date',
    schema: 'date',
    isExpandable: false
  },
  {
    id: 'actualNextInvoiceStatus',
    displayAsText: 'Actual Next Invoice Status',
    isExpandable: false
  },
  {
    id: 'createdDate',
    displayAsText: 'Purchase Date',
    schema: 'date',
    isExpandable: false
  }
]

const ExecutiveDashboardPlanReportFlyOut: React.FC<FlyOutParams> = ({ category, forDate, title = 'view plans', planIds }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const { data } = useGetExecutiveDashboardPlanQuery({ category, forDate })
  const [plans, setPlans] = useState<any>([])
  const [visibleColumns, setVisibleColumns] = useState(['planId', 'planStatus', 'planCategory', 'createdDate', 'organizationName', 'organizationLink', 'price', 'priceDescription', 'lastInvoiceDate'])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const [sortingColumns, setSortingColumns] = useState<Array<{ id: string; direction: 'asc' | 'desc' }>>([{ id: 'organizationName', direction: 'asc' }])

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  useEffect(() => {
    if (data) {
      let raw: any[]
      if (planIds) {
        raw = data.allPlans.filter(cr => planIds.includes(cr.planId))
      } else {
        raw = data.allPlans
      }

      setPlans(
        raw.map(pr => ({
          organizationLink: (
            <EuiLink href={`/customers/organizations/${pr.organizationId}`} target='_blank'>
              view
            </EuiLink>
          ),
          ...pr
        }))
      )
    }
  }, [data, planIds])

  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (plans.hasOwnProperty(rowIndex)) {
        if (typeof plans[rowIndex][columnId] == 'boolean') {
          return plans[rowIndex][columnId] ? 'true' : 'false'
        }

        if (typeof plans[rowIndex][columnId] === 'number') {
          if (schema === 'currency') {
            return plans[rowIndex][columnId] ? plans[rowIndex][columnId].toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + '' : ''
          }

          return plans[rowIndex][columnId] ? plans[rowIndex][columnId] + '' : ''
        }

        if (schema === 'date') {
          return plans[rowIndex][columnId] ? moment(plans[rowIndex][columnId]).format('YYYY-MM-DD') + '' : ''
        }

        if (columnId === 'planStatus' || columnId === 'planCategory') {
          return plans[rowIndex][columnId] ? insertSpaces(plans[rowIndex][columnId]) : ''
        }

        return plans[rowIndex][columnId] ? plans[rowIndex][columnId] : null
      }
    }
  }, [plans])

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
          <EuiFlyoutHeader>
            <EuiTitle size='s'>
              <h2>Plans</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiDataGrid
              aria-label='Plans Report'
              columns={columns}
              columnVisibility={{ visibleColumns, setVisibleColumns }}
              gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal', rowHover: 'highlight' }}
              rowCount={plans.length}
              renderCellValue={renderCellValue}
              inMemory={{ level: 'sorting' }}
              sorting={{ columns: sortingColumns, onSort }}
              pagination={{
                ...pagination,
                pageSizeOptions: [50, 100, 200, 500],
                onChangeItemsPerPage,
                onChangePage
              }}
              toolbarVisibility={{
                additionalControls: (
                  <React.Fragment>
                    <CSVLink data={plans} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`campaigns_report.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                      <span className='euiButtonContent euiButtonEmpty__content'>
                        <EuiIcon type='download' />
                        <span className='euiButton__text'>Download Report (CSV)</span>
                      </span>
                    </CSVLink>
                  </React.Fragment>
                )
              }}
            />
          </EuiFlyoutBody>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showFlyout} color='primary'>
        <EuiText size='xs'>{title}</EuiText>
      </EuiLink>
      {flyout}
    </React.Fragment>
  )
}

export default ExecutiveDashboardPlanReportFlyOut
