import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiImage, EuiPanel, EuiText } from '@elastic/eui'

import { TempDisplayAd } from 'components/AdCreation/DisplayMaker'

export interface IDisplayAdPreview {
  ad: TempDisplayAd
  onDelete?: () => void
  width?: number
  titleOverride?: string
}
export const DisplayAdPreview: React.FC<IDisplayAdPreview> = props => {
  return (
    <EuiPanel hasShadow={false} hasBorder={true} style={{ width: props.width, padding: 12 }}>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText size={'s'} style={{ marginTop: 8 }}>
            {props.titleOverride ?? `${props.ad.width}x${props.ad.height}`}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size={'s'} iconType='trash' color='danger' onClick={props.onDelete} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin={'xs'} />
      <EuiImage alt={`Ad preview`} url={props.ad.adUri} />
    </EuiPanel>
  )
}
