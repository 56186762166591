import {AgencySalesStage} from "api";
import React from "react";
import {EuiSelect} from "@elastic/eui";

export interface ISalesStageSelect {
  selectedSalesStage: AgencySalesStage
  onSalesStageChange: (salesStage: AgencySalesStage) => void
}
export const SalesStageSelect : React.FC<ISalesStageSelect> = props => {
  const options = Object.keys(AgencySalesStage).map((key) => {
    return {value: key, text: key}
  })

  return (
    <EuiSelect
      id={'SalesStageSelect'}
      options={options}
      value={props.selectedSalesStage}
      onChange={e => props.onSalesStageChange(e.target.value as AgencySalesStage)}
      aria-label="Sales Stage Select"
    />
  )
}