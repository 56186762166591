import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiImage, EuiInMemoryTable, EuiLink, EuiPageTemplate, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { FieldValueOptionType } from '@elastic/eui/src/components/search_bar/filters/field_value_selection_filter'

import { Ad, useOpsClient } from 'api'
import { getAdStatusReadable } from 'utils/EnumToFriendly'
import { localTimestamp } from 'utils/dateUtils'

const AdsPage: React.FC = () => {
  const [isLoadingAds, setIsLoadingAds] = useState(false)
  const [ads, setAds] = useState<Ad[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    setIsLoadingAds(true)
    opsClient?.getAds().then(c => {
      setAds(c)
      setIsLoadingAds(false)
    })
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<Ad>> = [
    {
      name: '',
      render: (c: Ad) =>
        c.thumbnailUrl === null ? (
          c.tvCommercialUploadedDetails ? (
            <video id='uploadedVideo' width='100%' hidden={false} style={{ width: 'auto', maxWidth: 41, maxHeight: 41 }}>
              <source src={c.tvCommercialDetails?.previewUrl ? c.tvCommercialDetails.previewUrl! : c.tvCommercialUploadedDetails.uploadedUrl!} type='video/mp4' />
            </video>
          ) : (
            <React.Fragment />
          )
        ) : (
          <EuiImage url={c.thumbnailUrl ? c.thumbnailUrl : c.displayAdDetails?.uploadedUrl!} size={41} alt='thumbnail' />
        ),
      width: '49'
    },
    {
      name: 'Ad',
      render: (c: Ad) => (
        <EuiLink href={`/customers/ads/${c.adId}`} target='_blank'>
          {c.nativeDetails?.headline ?? c.tvCommercialDetails?.tvCommercialName}
        </EuiLink>
      )
    },
    {
      name: 'Campaign',
      render: (c: Ad) => (
        <EuiLink href={`/customers/campaigns/${c.campaignId}`} target='_blank'>
          {c.campaignName}
        </EuiLink>
      )
    },
    {
      name: 'Account',
      render: (c: Ad) => (
        <EuiLink href={`/customers/accounts/${c.accountId}`} target='_blank'>
          {c.accountName}
        </EuiLink>
      )
    },
    {
      name: 'Organization',
      render: (c: Ad) => (
        <EuiLink href={`/customers/organizations/${c.organizationId}`} target='_blank'>
          {c.organizationName}
        </EuiLink>
      )
    },
    {
      name: `Type`,
      render: (c: Ad) => c.adType
    },
    {
      name: `Status`,
      render: (c: Ad) => getAdStatusReadable(c.status)
    },
    {
      name: `Audited Date`,
      width: '150',
      render: (c: Ad) => (c.audited ? localTimestamp(c.audited) : ''),
      sortable: (c: Ad) => (c.audited ? moment(c.audited, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString() : null)
    },
    {
      name: `Created Date`,
      width: '150',
      render: (c: Ad) => localTimestamp(c.created),
      sortable: (c: Ad) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'nativeDetails.headline',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const loadStatus = () => {
    return new Promise<FieldValueOptionType[]>(resolve => {
      resolve(
        [...new Set(ads.map(c => c.status))].map(s => ({
          value: s,
          view: getAdStatusReadable(s)
        }))
      )
    })
  }

  const loadTypes = () => {
    return new Promise<FieldValueOptionType[]>(resolve => {
      resolve(
        [...new Set(ads.map(c => c.adType))].map(s => ({
          value: s,
          view: s
        }))
      )
    })
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='Ads' restrictWidth={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiInMemoryTable
          loading={isLoadingAds}
          items={ads}
          columns={columns}
          sorting={sorting}
          pagination={pagination}
          tableLayout='fixed'
          search={{
            box: {
              incremental: true
            },
            filters: [
              {
                type: 'field_value_selection',
                field: 'status',
                name: 'Status',
                multiSelect: 'or',
                operator: 'exact',
                options: () => loadStatus()
              },
              {
                type: 'field_value_selection',
                field: 'adType',
                name: 'Type',
                multiSelect: 'or',
                operator: 'exact',
                options: () => loadTypes()
              }
            ]
          }}
        />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default AdsPage
