import React from 'react'
import { useParams } from 'react-router'

import { EuiPage, EuiPageBody, EuiPageSection } from '@elastic/eui'

import { TemplatesTabs } from 'features/templates/TemplatesTabs'
import history from 'services/HistoryService'

interface TemplatesPageParams {
  tab?: string
}
export const TemplatesPage: React.FC = () => {
  const { tab } = useParams<TemplatesPageParams>()

  if (!tab) {
    history.push('/templates/selfServePackages')
    return <React.Fragment />
  }

  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageSection color={'plain'} grow>
          <TemplatesTabs currentTab={tab} />
        </EuiPageSection>
      </EuiPageBody>
    </EuiPage>
  )
}
