import React from 'react'

import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiLoadingSpinner, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignMonitor, Monitor } from 'api/entities/Monitor'
import { useGetActiveCampaignMonitorsQuery, useResolveMonitorMutation } from 'api/rtkQueryApi/opsApi/monitorsApi'
import { CampaignMonitorDetails } from 'components/Monitors/CampaignMonitorDetails'
import { CampaignMonitorItem } from 'components/Monitors/CampaignMonitorItem'

export const CampaignMonitorsTab: React.FC = () => {
  const campaignMonitorsQuery = useGetActiveCampaignMonitorsQuery()
  const [resolveMonitor, resolveMonitorQuery] = useResolveMonitorMutation()
  const [selectedMonitor, setSelectedMonitor] = React.useState<CampaignMonitor>()

  const onMonitorSelected = (monitor: CampaignMonitor) => {
    setSelectedMonitor(monitor)
  }

  const onResolveMonitor = (monitor: Monitor) => {
    if (monitor) {
      resolveMonitor({ monitorId: monitor.id }).then(() => {
        setSelectedMonitor(undefined)
      })
    }
  }

  return (
    <React.Fragment>
      <EuiTitle>
        <h2>Campaign Monitors</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiHorizontalRule />
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiPanel hasBorder={false} hasShadow={false} style={{ maxHeight: 800, overflow: 'auto' }}>
            <EuiFlexGroup direction='column'>
              {campaignMonitorsQuery.isLoading && (
                <EuiFlexItem>
                  <EuiLoadingSpinner />
                </EuiFlexItem>
              )}
              {!campaignMonitorsQuery.isLoading &&
                campaignMonitorsQuery.data &&
                campaignMonitorsQuery.data.map(monitor => (
                  <EuiFlexItem key={monitor.id}>
                    <CampaignMonitorItem monitor={monitor} isSelected={selectedMonitor?.id === monitor.id} onSelected={() => onMonitorSelected(monitor)} />
                  </EuiFlexItem>
                ))}
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={4}>{!campaignMonitorsQuery.isLoading && campaignMonitorsQuery.data && <React.Fragment>{!selectedMonitor ? <EuiEmptyPrompt title={<h2>Campaign Monitors</h2>} /> : <CampaignMonitorDetails monitor={selectedMonitor} isSubmitting={resolveMonitorQuery.isLoading} onResolveClicked={onResolveMonitor} />}</React.Fragment>}</EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
