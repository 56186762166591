import moment from 'moment'
import React, { useState } from 'react'

import { EuiButton, EuiFormRow, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiSwitch, EuiText } from '@elastic/eui'

import { Bundle } from 'api/entities/Bundle'
import { IPayment } from 'api/entities/Invoice'
import { useReactivateBundleMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { OrganizationPaymentMethodsRadioGroup } from 'components/Invoices/OrganizationPaymentMethodsRadioGroup'
import { PaymentSplitterInline } from 'components/Invoices/PaymentSplitterInline'
import { insertSpaces } from 'utils/EnumToFriendly'
import {useGetPaymentMethodIdForReferenceQuery} from "api/rtkQueryApi/opsApi/paymentMethodsApi";

export interface IReactivateBundleModal {
  onCancel?: () => void
  onConfirmComplete?: () => void
  bundle: Bundle
}

export const ReactivateBundleModal: React.FC<IReactivateBundleModal> = props => {
  const getPaymentMethodIdQuery = useGetPaymentMethodIdForReferenceQuery({ organizationId: props.bundle.organizationId, accountId: props.bundle.accountId, referenceId: props.bundle.id });
  const [reactivateBundle, reactivateBundleRequest] = useReactivateBundleMutation()
  const [paymentMethodId, setPaymentMethodId] = useState<string>()
  const [splitPayment, setSplitPayment] = useState<boolean>(false)
  const [payments, setPayments] = useState<IPayment[]>()
  const [paymentSplitterValid, setPaymentSplitterValid] = useState<boolean>(false)

  const onSaveModal = () => {
    if (!isValidForSave()) {
      return
    }
    reactivateBundle({ organizationId: props.bundle.organizationId, bundleId: props.bundle.id, paymentMethodId: paymentMethodId, paymentSplitRequests: payments }).then(() => {
      props.onConfirmComplete?.()
    })
  }

  const onCloseModal = () => {
    props.onCancel?.()
  }

  const onPaymentsChanged = (payments: IPayment[]) => {
    setPayments(payments)
  }

  const onPaymentSplitterValidChanged = (valid: boolean) => {
    setPaymentSplitterValid(valid)
  }

  const toggleSplitPayment = () => {
    setPayments(undefined)
    setSplitPayment(!splitPayment)
  }

  const isValidForSave = () => {
    return paymentMethodId !== '-1' && (!splitPayment || paymentSplitterValid)
  }

  return (
    <EuiModal onClose={onCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Reactivate {insertSpaces(props.bundle.bundleType)}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow fullWidth>
          <EuiText>
            This {insertSpaces(props.bundle.bundleType)} will be charged ${props.bundle.price} and be reactivated immediately.
          </EuiText>
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <OrganizationPaymentMethodsRadioGroup
            organizationId={props.bundle.organizationId}
            onSelectedPaymentMethodIdChange={id => {
              setPaymentMethodId(id)
            }}
            defaultPaymentMethodId={getPaymentMethodIdQuery.data}
          />
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <EuiSwitch label={'Split Payment'} checked={splitPayment} onChange={toggleSplitPayment} />
        </EuiFormRow>
        {splitPayment && (
          <EuiFormRow fullWidth>
            <PaymentSplitterInline amountDue={props.bundle.price} maxDate={moment().add(1, 'week')} onValidChanged={onPaymentSplitterValidChanged} onPaymentsChanged={onPaymentsChanged} />
          </EuiFormRow>
        )}
        <EuiFormRow>
          <EuiButton onClick={onSaveModal} isLoading={reactivateBundleRequest.isLoading} isDisabled={!isValidForSave()}>
            Confirm
          </EuiButton>
        </EuiFormRow>
      </EuiModalBody>
    </EuiModal>
  )
}
