import React from 'react'

import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Anomaly, AnomalyStatus } from 'api/entities/Anomaly'
import { AnomalyStatusBadge } from 'components/Anomaly/AnomalyStatusBadge'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IAnomalyItem {
  anomaly: Anomaly
  isSelected?: boolean
  onSelected?: () => void
  showStatus?: boolean
}

export const AnomalyItem: React.FC<IAnomalyItem> = props => {
  const anomaly = props.anomaly

  const onItemClicked = () => {
    props.onSelected?.()
  }

  return (
    <EuiPanel onClick={onItemClicked} style={{ cursor: 'pointer', borderColor: props.isSelected ? 'orange' : undefined }} hasBorder={props.isSelected}>
      <EuiTitle size={'s'}>
        <h4>
          {anomaly.organizationName.slice(0, 32)}
          {anomaly.organizationName.length > 32 ? '...' : ''} {props.anomaly.status === AnomalyStatus.Ignored && <EuiIcon type={'eyeClosed'} />}
        </h4>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiFlexGroup wrap gutterSize={'xs'}>
        <EuiFlexItem grow={false}>
          <EuiBadge color={anomaly.status === AnomalyStatus.Escalated ? 'danger' : 'default'}>{insertSpaces(anomaly.anomalyType)}</EuiBadge>
        </EuiFlexItem>
        {props.showStatus && (
          <EuiFlexItem grow={false}>
            <AnomalyStatusBadge anomaly={anomaly} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiPanel>
  )
}
