import React from 'react'

import { BundleStatus } from 'api/entities/Bundle'
import { AcFilterGroup } from 'components/ACTools/AcFilterGroup'

export interface IBundleStatusItem {
  bundleStatus: BundleStatus
}

export interface IBundleStatusFilterGroup {
  bundleStatusItems: IBundleStatusItem[]
  onFilterClicked?: (campaignType: BundleStatus) => void
  onResetFilters?: () => void
  activeFilters: BundleStatus[]
}

export const BundleStatusFilterGroup: React.FC<IBundleStatusFilterGroup> = props => {
  const onFilterClick = (status: string) => {
    props.onFilterClicked?.(status as BundleStatus)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }
  return <AcFilterGroup items={props.bundleStatusItems} getGroup={(statusItem: IBundleStatusItem) => statusItem.bundleStatus} activeFilters={props.activeFilters} onFilterClicked={onFilterClick} onResetFilters={onTotalClick} />
}
