import moment, { Moment } from 'moment'

import { BundleCategory } from 'api/entities/Bundle'
import { CampaignType } from 'api/entities/Campaign'

export interface Organization {
  organizationId: string
  organizationName: string
  billingEmail: string
  phoneNumber: string | null
  targetingTemplates: string[]
  dma: string | null
  includedCampaignIds: string[]
  signupMethod: string
  customerId: string
  subscriptionId: string
  signupSource: string | null
  isAgency: boolean
  verifiedAgency: boolean
  receivesChecks: boolean
  excludeFromMessageSequence: boolean
  adConsultantId: string | null
  campaignAdvisorId: string | null
  organizationManagerId: string | null
  partnerId: string | null
  proposal: OrganizationProposal | null
  firstName: string | null
  lastName: string | null
  email: string | null
  created: moment.Moment
  allowBankAccountPayments: boolean
  signupDetails: SignupDetails | null
  initiatedEmailConversation?: moment.Moment
  initiatedTextConversation?: moment.Moment
  initiatedPhoneConversation?: moment.Moment
  permissions?: Permission[]
  selfServicePackageMatchingPercent?: number
}

export interface CreateOrganization {
  email: string
  company: string
  phone: string
  firstName: string
  lastName: string
  signupMethod: SignupMethod
}

export enum SignupMethod {
  AgencyApplication = 'AgencyApplication',
  Sales = 'Sales',
  Support = 'Support'
}

export interface AgencyOrganization {
  organization: Organization
  agencyDetails: AgencyDetails
}

export interface AgencyDetails {
  id: string
  organizationId: string
  agencyStatus: AgencyStatus
  agencyCategory: AgencyCategory
  agencyDetailsChanges: AgencyDetailsChange[]
  lastContacted?: moment.Moment
  lastContactedBy?: string
  agencyStageDetails?: AgencyStageDetails
  lastContactedMethodType?: SalesContactedMethodType
  cohortId?: string
  cohortName?: string
  cohortStatus?: CohortStatus
  businessName?: string
  businessPhone?: string
  website?: string
  timezone?: string
  address?: string
  city?: string
  state?: string
  zipCode?: string
  contactName?: string
  contactEmail?: string
  contactPhone?: string
}

export enum CohortStatus {
  Offered = 'Offered',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Rejected = 'Rejected',
  Pending = 'Pending'
}

export interface AgencyStageDetails {
  salesStage: AgencySalesStage
  timeLine: SalesAgencyStageDetailsTimelineItem
}

export interface SalesAgencyStageDetailsTimelineItem {
  createdAt: moment.Moment
  createdBy: string
  newStage: AgencySalesStage
  oldStage?: AgencySalesStage
  description: string
}

export enum SalesContactedMethodType {
  Email = 'Email',
  Text = 'Text',
  Voicemail = 'Voicemail',
  Call = 'Call'
}

export enum AgencySalesStage {
  T = 'T',
  S = 'S',
  D = 'D',
  C = 'C',
  B = 'B',
  A = 'A',
  W = 'W',
  L = 'L'
}

export const AgencySalesStagePrecedence: AgencySalesStage[] = [AgencySalesStage.T, AgencySalesStage.S, AgencySalesStage.D, AgencySalesStage.C, AgencySalesStage.B, AgencySalesStage.A, AgencySalesStage.W, AgencySalesStage.L]

export interface AgencyDetailsChange {
  changed: Moment
  username: string
  changeType: AgencyDetailsChangeType
  oldValue: string
  newValue: string
}

export enum AgencyDetailsChangeType {
  AgencyStatusChange = 'AgencyStatusChange',
  AgencyCategoryChange = 'AgencyCategoryChange'
}

export enum AgencyStatus {
  Active = 'Active',
  Archived = 'Archived',
  Inactive = 'Inactive'
}

export interface Permission {
  permissionType: PermissionType
}

export enum PermissionType {
  UploadAudience = 'UploadAudience'
}

export interface OrganizationSpendData {
  organizationId: string
  monthlyValue: number
  lastMonthSpend: number
  lifetimeValue: number
}

export interface SignupDetails {
  version: string
  steps: SignupDetailStep[]
}

export interface SignupDetailStep {
  version: string
  name: string
  started: moment.Moment
  completed: moment.Moment
  data: string
}

export interface UpdateOrganization {
  organizationName: string
  billingEmail: string
  phone: string | null
  dma: string | null
  adConsultantId: string | null
  campaignAdvisorId: string | null
  organizationManagerId: string | null
  isAgency: boolean
  verifiedAgency: boolean
  receivesChecks: boolean
  excludeFromMessageSequence: boolean
  partnerId: string | null
  activeCampaignAccountId?: string
  activeCampaignPrimaryContactId?: string
  allowBankAccountPayments: boolean
  initiatedEmailConversation?: moment.Moment
  initiatedTextConversation?: moment.Moment
  initiatedPhoneConversation?: moment.Moment
}

export interface OrganizationProposalViewsEstimate {
  planTVTotalViewsEstimate: string
  planTVViewsEstimate: string
  planBillboardViewsEstimate: string
  planInternetViewsEstimate: string
}

export interface OrganizationProposal {
  organizationId: string
  organizationName: string
  firstName: string
  lastName: string
  company: string
  email: string
  proposalType: ProposalType
  planLength: number
  planFrequency: PlanFrequency
  planPrice: number
  planTVPrice?: number
  planBillboardPrice?: number
  planMatching: number
  planTVEstimatedTotalViewsMin: number
  planTVEstimatedTotalViewsMax: number
  planTVViewsMin: number
  planTVViewsMax: number
  planBillboardViewsMin: number
  planBillboardViewsMax: number
  planInternetViewsMin: number
  planInternetViewsMax: number
  created?: string
  sent?: string
  phone?: string
  altPhone?: string
  website?: string
  planType?: BundleCategory
  lastContacted?: moment.Moment
  lastContactedBy: string
}

export interface GetProposalViewsEstimateRequest {
  planFrequency: PlanFrequency
  planPrice: number
  planTVPrice?: number
  planBillboardPrice?: number
  planMatching: number
  campaignMatchingType: CampaignType
}

export interface CreateOrganizationProposal {
  firstName: string
  lastName: string
  company: string
  email: string
  proposalType: ProposalType
  planLength: number
  planFrequency: PlanFrequency
  planPrice: number
  planTVPrice?: number
  planBillboardPrice?: number
  planMatching: number
  campaignMatchingType: CampaignType
  phone: string
  altPhone: string
  website: string
  planType: string
}

export interface UpdateOrganizationIncludedCampaignIds {
  includedCampaignIds: string[] | null
}

export interface UpdateSalesStage {
  salesStage: string
}

export interface SignupProspect {
  email: string
  company: string
  phone: string
  firstName: string
  lastName: string
  isAgency: boolean
}

export interface Subscription {
  subscriptionId: string
  customerId: string
  status: string
  nextBillDate: string | null
  nextBillAmount: number | null
  trialEnd: string | null
  canceledAt: string | null
  created: string | null
  productId: string
}

export interface OrganizationCanDelete {
  shouldDelete: boolean
  numberAccounts: number
  numberCampaigns: number
  numberCreatives: number
  numberCreativeAds: number
  numberUsers: number
  hasStripeCustomer: boolean
  numberStripeSubscriptions: number
  numberStripePayments: number
}

export interface OrganizationPrepareCapture {
  clientSecret: string
  setupIntentId: string
  paymentMethodId: string
  amount: number
  paymentMethods: PaymentMethod[]
  error: string
}

export interface PaymentMethod {
  id: string
  name: string
  brand: string
  last4: string
  expiryMonth: number
  expiryYear: number
  isDefault: boolean
  paymentMethodType: PaymentMethodType
}

export enum PaymentMethodType {
  Card = 'card',
  BankAccount = 'bankAccount'
}

export interface OrganizationAddTrialSubscriptionRequest {
  turns: string
  resetSnapDate: boolean
}

export enum PlanFrequency {
  Month = 'month',
  Week = 'week'
}

export enum ProposalType {
  TV_Internet = 'TV_Internet',
  BB_Internet = 'BB_Internet',
  TVBB_Internet = 'TVBB_Internet',
  Internet_Internet = 'Internet_Internet',
  TV_BB = 'TV_BB',
  BB_TV = 'BB_TV'
}

export enum SalesStage {
  Offered = 'Offered',
  Lost = 'Lost'
}

export enum AgencyCategory {
  Premier = 'Premier',
  Growth = 'Growth',
  HighVelocity = 'HighVelocity',
  Suspected = 'Suspected'
}
