import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBreadcrumbs, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiSwitch, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { FieldValueOptionType } from '@elastic/eui/src/components/search_bar/filters/field_value_selection_filter'

import { UpdateBillboardDetails, useOpsClient, Billboard } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { localTimestamp } from 'utils/dateUtils'

import BillboardCreateFlyOut from './BillboardCreateFlyOut'

const BillboardsPage: React.FC = () => {
  const [isLoadingBillboards, setIsLoadingBillboards] = useState(false)
  const [billboards, setBillboards] = useState<Billboard[]>([])
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canCreate, setCanCreate] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)

  const previewWidth = 144

  useEffect(() => {
    if (userProfile) {
      setCanCreate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    setIsLoadingBillboards(true)
    opsClient?.getBillboards().then(c => {
      setBillboards(c)
      setIsLoadingBillboards(false)
    })
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<Billboard>> = [
    {
      name: 'Enabled',
      render: (b: Billboard) => (
        <EuiSwitch
          label=''
          checked={b.enabled}
          onChange={() => {
            setBillboards(billboards => {
              let _billboards = [...billboards]
              b.enabled = !b.enabled
              return _billboards
            })
            const update: UpdateBillboardDetails = {
              name: b.name,
              kind: b.kind,
              quality: b.quality,
              enabled: b.enabled,
              onlyForOrganizationIds: b.onlyForOrganizationIds
            }
            opsClient?.updateBillboardDetails(b.id, update)
          }}
        />
      ),
      sortable: (c: Billboard) => c.enabled
    },
    {
      name: 'Name',
      render: (c: Billboard) => <EuiLink onClick={() => history.push(`/assets/billboards/${c.id}`)}>{c.name}</EuiLink>,
      sortable: (c: Billboard) => c.name
    },
    {
      field: 'type',
      name: `Type`,
      sortable: true
    },
    {
      field: 'created',
      name: 'Creation Date',
      dataType: 'date',
      render: (c: moment.Moment) => localTimestamp(c),
      sortable: true
    },
    {
      field: 'kind',
      name: `Size`,
      sortable: () => true
    },
    {
      field: 'status',
      name: `Status`,
      sortable: true
    },
    {
      field: 'previewUrl',
      name: `SnapShot`,
      render: (url: string) => <img alt={''} src={url} width={previewWidth} />
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    pageIndex: pageIndex,
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const loadStatus = () => {
    return new Promise<FieldValueOptionType[]>(resolve => {
      resolve(
        [...new Set(billboards.map(c => c.status))].map(s => ({
          value: s,
          view: s
        }))
      )
    })
  }

  const loadType = () => {
    return new Promise<FieldValueOptionType[]>(resolve => {
      resolve(
        [...new Set(billboards.map(c => c.type))].map(s => ({
          value: s,
          view: s
        }))
      )
    })
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Assets' },
              {
                text: 'Billboards',
                href: '/assets/billboards',
                onClick: () => history.push('/assets/billboards')
              }
            ]}
          />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiSpacer />
              <EuiTitle size='s'>
                <h2>Billboards</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
            <EuiPageContentHeaderSection>{canCreate && <BillboardCreateFlyOut />}</EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiInMemoryTable
              loading={isLoadingBillboards}
              items={billboards}
              columns={columns}
              sorting={sorting}
              pagination={pagination}
              onTableChange={({ page: { index } }) => setPageIndex(index)}
              tableLayout='fixed'
              search={{
                box: {
                  incremental: true
                },
                filters: [
                  {
                    type: 'field_value_selection',
                    field: 'type',
                    name: 'Type',
                    multiSelect: 'or',
                    operator: 'exact',
                    options: () => loadType()
                  },
                  {
                    type: 'field_value_selection',
                    field: 'status',
                    name: 'Status',
                    multiSelect: 'or',
                    operator: 'exact',
                    options: () => loadStatus()
                  }
                ]
              }}
            />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default BillboardsPage
