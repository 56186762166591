import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EuiBreadcrumbs, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiTabbedContent, EuiCopy, EuiBadge } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useOpsClient, Billboard } from 'api'
import history from 'services/HistoryService'

import BillboardDetailsTab from './BillboardDetailsTab'
import BillboardSpecificAssignmentTab from './BillboardSpecificAssignmentTab'
import BillboardSpecificBuilderTab from './BillboardSpecificBuilderTab'

interface BillboardPageParams {
  billboardId: string
}

const BillboardPage: React.FC = () => {
  let { billboardId } = useParams<BillboardPageParams>()
  const [billboard, setBillboard] = useState<Billboard>()
  const [tabs, setTabs] = useState<EuiTabbedContentTab[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      opsClient.getBillboard(billboardId).then(result => {
        setBillboard(result)
      })
    }
  }, [opsClient, billboardId])

  const tabbedContent = useCallback(() => {
    let t: EuiTabbedContentTab[] = [
      {
        id: 'details',
        name: 'Details',
        content: <BillboardDetailsTab billboardId={billboardId} />
      }
    ]

    t.push({ id: 'generic', name: 'Builder', content: <BillboardSpecificBuilderTab billboardId={billboardId} /> })
    if (billboard?.type === 'Specific') {
      t.push({
        id: 'businessTypes',
        name: 'Business Types',
        content: <BillboardSpecificAssignmentTab billboardId={billboardId} />
      })
    }

    setTabs(t)
  }, [billboardId, billboard])

  useEffect(() => {
    tabbedContent()
    return () => {
      setTabs([])
    }
  }, [tabbedContent])

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Assets' },
              {
                text: 'Billboards',
                href: '/assets/billboards',
                onClick: () => history.push('/assets/billboards')
              },
              {
                text: billboard?.name || '',
                href: `/assets/billboards/${billboardId}`,
                onClick: () => history.push(`/assets/billboards/${billboardId}`)
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Billboard</h1>
              </EuiTitle>
              <EuiCopy textToCopy={billboardId}>
                {copy => (
                  <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Billboard ID'>
                    {billboardId}
                  </EuiBadge>
                )}
              </EuiCopy>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>{tabs && tabs.length > 0 && <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />}</EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default BillboardPage
