import moment from 'moment'

import { AcImage } from './AcImage'

export enum BillboardAdImageType {
  None = 0,
  Square = 1,
  Rectangle = 2
}

export enum AdImagePlacement {
  Right = 0,
  Left = 1
}

export const GetAdWidthForAdImageType = (type: BillboardAdImageType) => {
  const BillboardSizesForAd = {
    Rectangle: 765,
    Square: 400,
    None: 0
  }
  switch (type) {
    case BillboardAdImageType.None:
      return BillboardSizesForAd.None
    case BillboardAdImageType.Rectangle:
      return BillboardSizesForAd.Rectangle
    case BillboardAdImageType.Square:
      return BillboardSizesForAd.Square
    default:
      return BillboardSizesForAd.None
  }
}

export interface Billboard {
  id: string
  name: string
  enabled: boolean
  type: string
  status: string
  quality: string
  kind: string
  backgroundUrl: string
  logo: BillboardImageComponent
  photo: BillboardImageComponent
  headline: BillboardTextComponent
  companyName: BillboardTextComponent
  phone: BillboardTextComponent
  website: BillboardTextComponent
  location: BillboardTextComponent
  previewUrl: string
  onlyForOrganizationIds: string[]
  created: moment.Moment
  billboardImageType: BillboardAdImageType
}

export interface UpdateBillboard {
  backgroundUrl: string
  logo: BillboardImageComponent
  photo: BillboardImageComponent
  headline: BillboardTextComponent
  phone: BillboardTextComponent
  website: BillboardTextComponent
  location: BillboardTextComponent
  companyName: BillboardTextComponent
  previewUrl: string
  billboardImageType: BillboardAdImageType
  imageLocation: AdImagePlacement
  adImage?: AcImage
}

export interface UpdateBillboardDetails {
  name: string
  kind: string
  quality: string
  enabled: boolean
  onlyForOrganizationIds: string[]
}

export interface BillboardImageComponent extends BillboardComponent {
  sampleUrl?: string
  isDragging?: boolean
  selected?: boolean
}

export interface BillboardTextComponent extends BillboardComponent {
  fontFace?: string
  fontSize?: number
  fontColor?: string
  sampleText?: string
  isDragging?: boolean
  selected?: boolean
}

export interface BillboardComponent {
  width?: number
  height?: number
  x?: number
  y?: number
  align?: string
}

export interface CreateBillboard {
  name: string
  type: string
}

export interface UpdateBillboardBusinessTypeVoiceOverAssignment {
  businessTypeId: string
  isAssigned: boolean
}

export interface UpdateBillboardSpecificAssignments {
  businessTypeCategoryId: string
  businessTypeVoiceOverAssignments: UpdateBillboardBusinessTypeVoiceOverAssignment[]
}

export interface BillboardUploadAdRequest {
  accountId: string
  campaignId: string
  uploadedUrl: string
  previewUrl: string
  width: number
  height: number
  mimeType: string
}

export interface BillboardGeosInRange {
  lat: number
  lng: number
  radiusMeters: number
}

export interface BillboardGeo {
  id: string
  mediaOwner: string
  venueName: string
  venueType: string
  latitude: number
  longitude: number
  dma: string
  zipCode: string
}
