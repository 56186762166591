import { CampaignType, SegmentLogic, SegmentTarget } from 'api/entities/Campaign'
import { CuratedAudience } from 'api/entities/CuratedAudience'

import { defaultTransformFn, opsApi } from './opsApi'

const audiencesTag = opsApi.enhanceEndpoints({ addTagTypes: ['CuratedAudiences', 'InternetSegments', 'TvSegments', 'AudienceSegments', 'Segments'] })

const audiencesApi = audiencesTag.injectEndpoints({
  endpoints: build => ({
    getCuratedAudiences: build.query<CuratedAudience[], { query: string }>({
      query: query => `curatedAudiences?query=` + query,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'CuratedAudiences' }]
    }),
    createAudience: build.mutation<CuratedAudience, { name: string; description: string; campaignType: CampaignType; segmentLogic: SegmentLogic }>({
      query: request => ({
        url: `curatedAudiences`,
        method: 'POST',
        body: {
          name: request.name,
          description: request.description,
          campaignType: request.campaignType,
          segmentLogic: request.segmentLogic
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'CuratedAudiences' }]
    }),
    updateCuratedAudience: build.mutation<CuratedAudience, { audienceId: string; name: string; description: string; segmentLogic: SegmentLogic }>({
      query: request => ({
        url: `curatedAudiences/${request.audienceId}`,
        method: 'PUT',
        body: {
          name: request.name,
          description: request.description,
          segmentLogic: request.segmentLogic
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, query) => [{ type: 'CuratedAudiences' }, { type: 'AudienceSegments', id: query.audienceId }]
    }),
    getInternetSegments: build.query<SegmentTarget[], string>({
      query: query => `dictionary/segments/internet?query=${query}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'InternetSegments', id: query }]
    }),
    getTvSegments: build.query<SegmentTarget[], string>({
      query: query => `dictionary/segments/tv?query=${query}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'TvSegments', id: query }]
    }),
    getAudienceSegments: build.query<SegmentTarget[], { audienceId: string }>({
      query: query => `curatedAudiences/${query.audienceId}/segments`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'AudienceSegments', id: query.audienceId }]
    }),
    getSegments: build.query<SegmentTarget[], { segmentIds: string[] }>({
      query: query => `dictionary/segments?segmentId=${query.segmentIds.join('&segmentId=')}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => [{ type: 'Segments', id: query.segmentIds.join('&segmentId=') }]
    })
  }),
  overrideExisting: false
})

export const { useGetSegmentsQuery, useGetCuratedAudiencesQuery, useCreateAudienceMutation, useGetInternetSegmentsQuery, useGetTvSegmentsQuery, useGetAudienceSegmentsQuery, useUpdateCuratedAudienceMutation } = audiencesApi
