import React, { useEffect, useState } from 'react'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { State, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface StateTargeterProps {
  isInvalid: boolean
  addState(state: string): void
}

export const StateFinder = ({ addState, isInvalid }: StateTargeterProps) => {
  const opsClient = useOpsClient()
  const [stateSearchTerm, setStateSearchTerm] = useState<string>('')
  const debouncedStateSearchTerm = useDebounce(stateSearchTerm, 100)
  const [searchStatePopoverOpen, setSearchStatePopoverOpen] = useState<boolean>(false)
  const [states, setStates] = useState<Array<State>>([])
  const [filteredStates, setFilteredStates] = useState<Array<State>>([])
  const [selectableStates, setSelectableStates] = useState<Array<EuiSelectableOption>>()
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>(false)

  useEffect(() => {
    if (opsClient) {
      opsClient.getStates().then(r => {
        setStates(r)
        setSelectableStates(
          r.map(
            s =>
              ({
                label: s.name,
                key: s.stateId
              } as EuiSelectableOption)
          )
        )
      })
    }
  }, [opsClient])

  const onSearchState = () => {
    setSearchStatePopoverOpen(false)
  }

  const onSearchStateChange = (hint: string) => {
    setStateSearchTerm(hint)
  }

  useEffect(() => {
    if (debouncedStateSearchTerm) {
      setIsLoadingStates(true)
      setFilteredStates([...states.filter(d => d.name.toLowerCase().includes(debouncedStateSearchTerm.toLowerCase()) || d.code.toLowerCase().includes(debouncedStateSearchTerm.toLowerCase()))])
      setIsLoadingStates(false)
    } else {
      setFilteredStates([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStateSearchTerm])

  useEffect(() => {
    if (filteredStates) {
      setSelectableStates(
        filteredStates.map(
          s =>
            ({
              label: s.name,
              key: s.stateId,
              checked: undefined,
              onClick: () => {
                addState(s.stateId)
                setSearchStatePopoverOpen(false)
                setStateSearchTerm('')
              }
            } as EuiSelectableOption)
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStates])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={stateSearchTerm}
          isLoading={isLoadingStates}
          onChange={v => onSearchStateChange(v.target.value)}
          onFocus={() => setSearchStatePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchState()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchStatePopoverOpen}
      closePopover={() => setSearchStatePopoverOpen(false)}>
      {filteredStates && filteredStates.length > 0 ? (
        <EuiSelectable singleSelection={false} options={selectableStates}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
