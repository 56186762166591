import React, { FC, useCallback, useState } from 'react'

import { EuiButton, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiLoadingContent, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Note } from 'api/entities/Notes'
import { useDeleteNoteMutation, useGetNotesForIdQuery, useBundleAddNoteMutation, useUpdateNoteContentMutation } from 'api/rtkQueryApi/opsApi/noteApi'
import { AcNote } from 'components/ACTools/AcNote'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'

export interface RequiredIdsForBundleNote {
  bundleId: string
  organizationId: string
}

export const useBundleNotesFlyoutState = () => {
  const [requiredIdsForNote, setRequiredIdsForNote] = useState<RequiredIdsForBundleNote>()
  const [isBundleNotesFlyoutVisible, setIsBundleNotesFlyoutVisible] = useState(false)

  const showBundleNotesFlyout = () => {
    setIsBundleNotesFlyoutVisible(true)
  }

  const closeBundleNotesFlyout = () => {
    setIsBundleNotesFlyoutVisible(false)
  }

  return {
    requiredIdsForNote,
    setRequiredIdsForNote,
    isBundleNotesFlyoutVisible,
    showBundleNotesFlyout,
    closeBundleNotesFlyout
  }
}

export interface BundleNotesFlyoutProps {
  isFlyoutVisible: boolean
  closeFlyout: () => void
  requiredIdsForNote?: RequiredIdsForBundleNote
}

const BundleNotesFlyout: FC<BundleNotesFlyoutProps> = ({ requiredIdsForNote, isFlyoutVisible, closeFlyout }) => {
  const [saveBundleNote] = useBundleAddNoteMutation()
  const { isFetching: isBundleNotesFetching, data: bundleNotes } = useGetNotesForIdQuery(requiredIdsForNote?.bundleId ?? '', { skip: !requiredIdsForNote?.bundleId })
  const [updateNote] = useUpdateNoteContentMutation()
  const [deleteNote] = useDeleteNoteMutation()
  const [addingNote, setAddingNote] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState<Note>()

  const onAddNoteClick = () => {
    setAddingNote(true)
  }

  const onCancelAddNoteClick = () => {
    setAddingNote(false)
  }

  const addNoteClick = (note: Note) => {
    if (!requiredIdsForNote) {
      return
    }

    saveBundleNote({
      bundleId: requiredIdsForNote.bundleId,
      organizationId: requiredIdsForNote.organizationId,
      secondaryReferenceIds: [],
      followUpDate: note.followUpDate,
      followUp: note.followUp,
      text: note.text,
      name: note.name
    })
    setAddingNote(false)
  }

  const updateNoteClick = (note: Note) => {
    updateNote({ followUp: note.followUp, text: note.text, name: note.name, followUpDate: note.followUpDate, noteId: note.id })
  }

  const deleteNoteClick = (note: Note) => {
    setNoteToDelete(note)
    setShowDeleteConfirm(true)
  }

  const onDeleteNoteConfirm = () => {
    if (noteToDelete?.id) {
      setShowDeleteConfirm(false)
      deleteNote(noteToDelete.id)
    }
  }

  const onDeleteNoteCancel = () => {
    setShowDeleteConfirm(false)
  }

  const innerCloseFlyout = useCallback(() => {
    setAddingNote(false)
    closeFlyout()
  }, [closeFlyout])

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout onClose={innerCloseFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
        {requiredIdsForNote ? (
          <React.Fragment>
            <EuiFlyoutHeader>
              <EuiTitle size='s'>
                <h2>Plan Notes</h2>
              </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
              {isBundleNotesFetching && <EuiLoadingContent lines={3} />}
              {!isBundleNotesFetching && (
                <React.Fragment>
                  {!addingNote && (
                    <React.Fragment>
                      <EuiSpacer />
                      <EuiButton onClick={onAddNoteClick}>Add Note</EuiButton>
                    </React.Fragment>
                  )}
                  <EuiSpacer />
                  {addingNote && (
                    <React.Fragment>
                      <AcNote editable buttonText='Add' onNoteSave={addNoteClick} onNoteDelete={onCancelAddNoteClick} />
                      <EuiSpacer size='xl' />
                    </React.Fragment>
                  )}
                  {bundleNotes?.map(note => {
                    return (
                      <React.Fragment>
                        <AcNote editable key={note.id} note={note} onNoteSave={updateNoteClick} onNoteDelete={deleteNoteClick} />
                        <EuiSpacer size='xl' />
                      </React.Fragment>
                    )
                  })}
                  {showDeleteConfirm && <AcConfirmModal onConfirm={onDeleteNoteConfirm} onCancel={onDeleteNoteCancel} title={'Delete Note'} message={'Are you sure you would like to delete this note?'} confirmButtonText={'Delete'} />}
                </React.Fragment>
              )}
            </EuiFlyoutBody>
          </React.Fragment>
        ) : (
          <EuiFlyoutBody>
            <EuiLoadingContent lines={3} />
          </EuiFlyoutBody>
        )}
      </EuiFlyout>
    )
  }

  return <React.Fragment>{flyout}</React.Fragment>
}

export default BundleNotesFlyout
