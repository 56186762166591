import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel } from '@elastic/eui'

import { NativeDetails } from 'api'
import { AdSampleCollage } from 'components/AdCreation/AdSampleCollage'
import { AdFormat, ColorPalette, ContentSpecifications, DisplayMaker, TempDisplayAd } from 'components/AdCreation/DisplayMaker'
import { getDisplayAdMakerSizes } from 'utils/imageUtils'

export interface IDisplayAdPicker {
  nativeDetails: NativeDetails
  colorPalette: ColorPalette
  contentSpecifications: ContentSpecifications
  uploadedUrl: string
  onTempAdSelected?: (ad: TempDisplayAd) => void
  onCollageCapture?: (collage: TempDisplayAd) => void
  displayWidth?: number
}
export const DisplayAdPicker: React.FC<IDisplayAdPicker> = props => {
  const sizes = getDisplayAdMakerSizes()

  const estimateFormat = (width: number, height: number) => {
    if (width === 300 && height === 372) {
      return AdFormat.NativeMed
    } else if (width / height > 0.6 && width / height < 1.4) {
      return AdFormat.Rectangle
    } else if (width > height) {
      if (height > 200) {
        return AdFormat.LargeBanner
      } else if (height < 100) {
        if (width > 320) {
          return AdFormat.LongBanner
        } else {
          return AdFormat.MobileBanner
        }
      } else {
        return AdFormat.LongRectangle
      }
    } else {
      return AdFormat.LargeTower
    }
  }

  const onTempAdSelected = (ad: TempDisplayAd) => {
    if (props.onTempAdSelected) {
      props.onTempAdSelected(ad)
    }
  }

  const onCaptureCollage = (collageUri: TempDisplayAd) => {
    if (props.onCollageCapture) {
      props.onCollageCapture(collageUri)
    }
  }

  const displayWidth = props.displayWidth ?? 400
  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <AdSampleCollage colorPalette={props.colorPalette} details={props.nativeDetails} contentSpecifications={props.contentSpecifications} uploadedUrl={props.uploadedUrl} onCapture={onCaptureCollage} />
      </EuiFormRow>
      <EuiFlexGroup wrap>
        {sizes.map((size, i) => {
          return (
            <EuiFlexItem grow={false}>
              <EuiPanel hasBorder={true} hasShadow={false} style={{ width: displayWidth + 32 }}>
                <DisplayMaker key={i} onCapture={onTempAdSelected} colorPalette={props.colorPalette} details={props.nativeDetails} contentSpecifications={props.contentSpecifications} uploadedUrl={props.uploadedUrl} adWidth={size.width} adHeight={size.height} format={estimateFormat(size.width, size.height)} maxHeight={200} maxWidth={displayWidth} />
              </EuiPanel>
            </EuiFlexItem>
          )
        })}
      </EuiFlexGroup>
    </React.Fragment>
  )
}
