import React, { useEffect, useState } from 'react'

import { EuiSpacer, EuiInMemoryTable } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign, useOpsClient } from 'api'
import { Transaction } from 'api/entities/Transaction'

interface CampaignTransactionsTabParams {
  campaign: Campaign
}

export const CampaignTransactionsTab: React.FC<CampaignTransactionsTabParams> = ({ campaign }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const opsClient = useOpsClient()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoading(true)

      opsClient
        .getCampaignTransactions(campaign.campaignId)
        .then(r => {
          if (isMounted) {
            setTransactions(r)
          }
        })
        .finally(() => {
          if (isMounted) {
            setIsLoading(false)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, campaign])

  const columns: EuiBasicTableColumn<Transaction>[] = [
    {
      field: 'type',
      name: 'Type'
    },
    {
      field: 'amount',
      name: 'Amount',
      render: (amount: number) => `${formatter.format(amount)}`
    },
    {
      field: 'balance',
      name: 'Balance',
      render: (balance: number) => `${formatter.format(balance)}`
    },
    {
      field: 'forTimestamp',
      name: 'forTimestamp',
      dataType: 'date'
    }
  ]

  const search = {
    box: {
      incremental: true,
      schema: true
    }
  }

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }
  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiInMemoryTable items={isLoading ? [] : transactions} columns={columns} search={search} loading={isLoading} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}
