import React, { FC } from 'react'
import { useParams } from 'react-router'

import { EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody } from '@elastic/eui'

import AccountManagerDashboardTabs from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import history from 'services/HistoryService'
import {ToolsTab} from "features/tools/ToolsTab";
import {ToolsTabs} from "features/tools/ToolsTabs";

export interface AccountManagerDashboardPageProps {
  tab?: string
}

export const ToolsPage: FC = () => {
  const { tab } = useParams<AccountManagerDashboardPageProps>()

  if (!tab) {
    history.push('/tools/tools')
    return <React.Fragment/>
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <ToolsTabs currentTab={tab} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}
