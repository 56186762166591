import React, { FC, useState } from 'react'

import { EuiFilterButton, EuiFilterGroup, EuiFlexGroup, EuiFlexItem, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiSpacer, EuiTitle } from '@elastic/eui'

import CampaignsByOrganizationType from 'features/executiveDashboard/graphs/CampaignsByOrganizationType'
import { ExecutiveCampaignsByDayFilter } from 'features/executiveDashboard/graphs/ExecutiveDashboardCampaignsByOrganizationChart'

const ExecutiveDashboardPage: FC = () => {
  const [dayFilter, setDayFilter] = useState(ExecutiveCampaignsByDayFilter.threeMonths)
  const [showZero, setShowZero] = useState(false)

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <EuiTitle size='s'>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiFilterGroup>
                    <EuiFilterButton withNext hasActiveFilters={dayFilter === ExecutiveCampaignsByDayFilter.oneMonth} onClick={() => setDayFilter(ExecutiveCampaignsByDayFilter.oneMonth)}>
                      1 month
                    </EuiFilterButton>
                    <EuiFilterButton withNext hasActiveFilters={dayFilter === ExecutiveCampaignsByDayFilter.threeMonths} onClick={() => setDayFilter(ExecutiveCampaignsByDayFilter.threeMonths)}>
                      3 months
                    </EuiFilterButton>
                    <EuiFilterButton withNext hasActiveFilters={dayFilter === ExecutiveCampaignsByDayFilter.sixMonths} onClick={() => setDayFilter(ExecutiveCampaignsByDayFilter.sixMonths)}>
                      6 months
                    </EuiFilterButton>
                    <EuiFilterButton hasActiveFilters={dayFilter === ExecutiveCampaignsByDayFilter.oneYear} onClick={() => setDayFilter(ExecutiveCampaignsByDayFilter.oneYear)}>
                      1 year
                    </EuiFilterButton>
                    <EuiFilterButton hasActiveFilters={showZero} onClick={() => setShowZero(!showZero)}>
                      show zero
                    </EuiFilterButton>
                  </EuiFilterGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiTitle>

            <EuiSpacer size='xxl' />

            <CampaignsByOrganizationType dayFilter={dayFilter} showZero={showZero} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default ExecutiveDashboardPage
