import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiComboBox, EuiFieldText, EuiSwitch, EuiForm, EuiFormRow, EuiFieldNumber, EuiLoadingContent, EuiSpacer, EuiSuperSelect } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { BusinessTypeCategory, BusinessTypeCategoryGroup, IabCategory, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'

interface BusinessTypeCategoryDetailsTabParams {
  businessTypeCategoryId: string
}

interface BusinessTypeCategoryContent {
  label: string
  id: string
}

interface FormValues {
  name: string
  isDeleted: boolean
  isHidden: boolean
  isOther: boolean
  position: number
  iabCategories?: IabCategory[]
}

const BusinessTypeCategoryDetailsTab: React.FC<BusinessTypeCategoryDetailsTabParams> = ({ businessTypeCategoryId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBusinessTypeCategories, setIsLoadingBusinessTypeCategories] = useState(false)
  const [isLoadingBusinessTypeCategoryGroups, setIsLoadingBusinessTypeCategoryGroups] = useState(false)
  const [businessTypeCategory, setBusinessTypeCategory] = useState<BusinessTypeCategory>()
  const [businessTypeCategories, setBusinessTypeCategories] = useState<BusinessTypeCategory[] | undefined>()
  const [businessTypeCategoryGroups, setBusinessTypeCategoryGroups] = useState<BusinessTypeCategoryGroup[] | undefined>()
  const [parents, setParents] = useState<EuiComboBoxOptionOption[]>()
  const [chosenParents, setChosenParents] = useState<EuiComboBoxOptionOption[]>([])
  const [groups, setGroups] = useState<EuiSuperSelectOption<string>[]>([])
  const [chosenGroup, setChosenGroup] = useState<string>('')
  const [initial, setInitial] = useState<FormValues>({
    name: '',
    isDeleted: false,
    isHidden: false,
    isOther: false,
    position: 1,
    iabCategories: []
  })
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)

  const [businessTypeOptions, setBusinessTypeOptions] = useState<BusinessTypeCategoryContent[]>([])
  const [selectedOptions, setSelectedOptions] = useState<BusinessTypeCategoryContent[]>([])

  const [iabCategories, setIabcategories] = useState<IabCategory[]>([])

  const onChangeCategory = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions)
  }

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      setIsLoadingBusinessTypeCategories(true)
      setIsLoadingBusinessTypeCategoryGroups(true)
      opsClient.getBusinessTypeCategory(businessTypeCategoryId).then(result => {
        setInitial({
          name: result.name,
          isDeleted: result.isDeleted,
          isHidden: result.isHidden,
          isOther: result.isOther,
          position: result.position,
          iabCategories: result.iabCategories
        })
        setBusinessTypeCategory(result)
        setIsLoading(false)
      })
      opsClient.getIabCategories().then(result => {
        setIabcategories(result)
      })
      opsClient.getBusinessTypeCategories().then(result => {
        setBusinessTypeCategories(result)
        setIsLoadingBusinessTypeCategories(false)
      })
      opsClient.getBusinessTypeCategoryGroups().then(result => {
        setBusinessTypeCategoryGroups(result)
        setIsLoadingBusinessTypeCategoryGroups(false)
      })
    }
  }, [opsClient, businessTypeCategoryId])

  useEffect(() => {
    if (opsClient) {
      opsClient.getBusinessTypes().then(result => {
        setBusinessTypeOptions(result.map(bt => ({ label: bt.name, id: bt.id } as BusinessTypeCategoryContent)))
      })
      opsClient.getBusinessTypeByCategory(businessTypeCategoryId, true).then(result => {
        setSelectedOptions(result.map(bt => ({ label: bt.name, id: bt.id } as BusinessTypeCategoryContent)))
      })
    }
  }, [opsClient, businessTypeCategoryId])

  useEffect(() => {
    if (businessTypeCategories) {
      const p = businessTypeCategories
        .filter(p => p.id !== businessTypeCategoryId)
        .map(
          btc =>
            ({
              key: btc.id,
              label: btc.name
            } as EuiComboBoxOptionOption)
        )
      setParents(p)
      if (businessTypeCategory) {
        setChosenParents(
          businessTypeCategories
            .filter(p => p.id === businessTypeCategory.parentId)
            .map(
              btc =>
                ({
                  key: btc.id,
                  label: btc.name
                } as EuiComboBoxOptionOption)
            )
        )
      }
    }
  }, [businessTypeCategoryId, businessTypeCategories, businessTypeCategory])

  useEffect(() => {
    if (businessTypeCategoryGroups) {
      const p = businessTypeCategoryGroups.map(
        btcg =>
          ({
            value: btcg.id,
            inputDisplay: btcg.name
          } as EuiSuperSelectOption<string>)
      )
      setGroups(p)
      if (businessTypeCategory) {
        let groupIds = businessTypeCategoryGroups.filter(p => p.id === businessTypeCategory.groupId)
        if (groupIds.length > 0) setChosenGroup(groupIds[0].id)
      }
    }
  }, [businessTypeCategoryGroups, businessTypeCategory])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const businessTypeCategorySchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the business type category name'),
    isDeleted: Yup.boolean(),
    isHidden: Yup.boolean(),
    position: Yup.number().required('Please enter a position to help sort this category in the tree')
  })

  const selectParent = (options: EuiComboBoxOptionOption[]) => {
    setChosenParents(options)
  }

  const selectGroup = (groupId: string) => {
    setChosenGroup(groupId)
  }

  const iabChange = (iabCategoryOptions: EuiComboBoxOptionOption[]) => {
    setInitial(initial => {
      let init = { ...initial }
      init.iabCategories = iabCategoryOptions.map(c => ({ id: c.id?.toUpperCase(), name: c.label } as IabCategory))
      return init
    })
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.updateBusinessTypeCategory(businessTypeCategoryId, {
        name: values.name,
        parentId: chosenParents.length > 0 ? chosenParents[0].key! : null,
        groupId: chosenGroup,
        isDeleted: values.isDeleted,
        isHidden: values.isHidden,
        isOther: values.isOther,
        position: values.position,
        iabCategories: iabCategories.filter(c => values.iabCategories?.some(i => i.id === c.id))
      })
      .then(() => {
        setSubmitting(false)
      })
    opsClient?.putCategoryUpdateBusinessType(businessTypeCategoryId, { businessTypeIds: selectedOptions.map(x => x.id) }).then(() => {})
  }

  const selectedIabOptions = iabCategories.filter(c => initial.iabCategories?.some(i => i.id === c.id))

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={initial} enableReinitialize validationSchema={businessTypeCategorySchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
              <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
            </EuiFormRow>
            <EuiFormRow label='Iab Categories'>
              <EuiComboBox id='iabCategories' onChange={iabChange} options={iabCategories.map(c => ({ id: c.id, label: `${c.id}: ${c.name}` }))} selectedOptions={selectedIabOptions.map(c => ({ id: c.id, label: `${c.id}: ${c.name}` }))} isLoading={isLoadingBusinessTypeCategories} />
            </EuiFormRow>
            <EuiFormRow label='Group'>
              <EuiSuperSelect options={groups} onChange={selectGroup} valueOfSelected={chosenGroup} isLoading={isLoadingBusinessTypeCategoryGroups} />
            </EuiFormRow>
            <EuiFormRow label='Parent'>
              <EuiComboBox singleSelection={{ asPlainText: true }} options={parents} selectedOptions={chosenParents} onChange={selectParent} isLoading={isLoadingBusinessTypeCategories} />
            </EuiFormRow>
            <EuiFormRow label='Business Types'>
              <EuiComboBox aria-label='Accessible screen reader label' placeholder='Select or create options' options={businessTypeOptions} selectedOptions={selectedOptions} onChange={onChangeCategory} isClearable={true} data-test-subj='demoComboBox' autoFocus />
            </EuiFormRow>
            <EuiFormRow label=''>
              <EuiSwitch label='Is Deleted' checked={props.values.isDeleted} onChange={e => props.setFieldValue('isDeleted', e.target.checked)} />
            </EuiFormRow>
            <EuiFormRow label=''>
              <EuiSwitch label='Is Hidden' checked={props.values.isHidden} onChange={e => props.setFieldValue('isHidden', e.target.checked)} />
            </EuiFormRow>
            <EuiFormRow label=''>
              <EuiSwitch label='Is Other' checked={props.values.isOther} onChange={e => props.setFieldValue('isOther', e.target.checked)} />
            </EuiFormRow>
            <EuiFormRow label='Position'>
              <EuiFieldNumber name='position' value={props.values.position} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.position} />
            </EuiFormRow>
            <EuiSpacer />
            <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
              Save
            </EuiButton>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default BusinessTypeCategoryDetailsTab
