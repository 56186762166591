import { CampaignSlotSimple } from 'api/entities/Bundle'

export const BonusBudgetLessThanBudget = (slots: CampaignSlotSimple[], intervalUnitPrice: number) => {
  return slots.filter(s => s.isBonus).reduce((partial, slot) => partial + slot.budget, 0) <= intervalUnitPrice
}

export const MatchingSlotsAreInternet = (slots: CampaignSlotSimple[]) => {
  return slots.filter(s => s.isBonus && s.campaignType !== 'Internet').length === 0
}

export const SlotBudgetEqualBudget = (slots: CampaignSlotSimple[], intervalUnitPrice: number) => {
  return slots.filter(s => !s.isBonus).reduce((partial, slot) => partial + slot.budget, 0) === intervalUnitPrice
}

export const SlotAssignmentsAreUnique = (slots: CampaignSlotSimple[]) => {
  return slots.filter(s => s.assignedCampaignId).map(s => s.assignedCampaignId).length === new Set(slots.filter(s => s.assignedCampaignId).map(s => s.assignedCampaignId)).size
}

export const SlotsHaveBonusAndPrimary = (slots: CampaignSlotSimple[]) => {
  return !(!slots.find(s => !s.isBonus) || !slots.find(s => s.isBonus))
}
