import { Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiPortal, EuiSpacer, EuiTextArea, EuiTitle } from '@elastic/eui'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

interface FormValues {
  name: string
  code: string
  description: string
}

const GranularPhraseCreateFlyOut: React.FC = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const opsClient = useOpsClient()

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const closeFlyout = () => setIsFlyoutVisible(false)

  const granularPhraseTypeSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the name'),
    code: Yup.string().max(16).required('Please enter the granular phrase code'),
    description: Yup.string().nullable()
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.createGranularPhrase({
        name: values.name,
        code: values.code,
        description: values.description
      })
      .then(result => {
        setSubmitting(false)
        setIsFlyoutVisible(false)
        history.push(`/data/granularPhrases/${result.id}`)
      })
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={{ name: '', code: '', description: '' }} validationSchema={granularPhraseTypeSchema} onSubmit={doSubmit}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Granular Phrase</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Code' isInvalid={!!props.errors.code} error={props.errors.code}>
                    <EuiFieldText name='code' value={props.values.code} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.code} prepend={'{{'} append={'}}'} />
                  </EuiFormRow>
                  <EuiFormRow label='Description' isInvalid={!!props.errors.description} error={props.errors.description}>
                    <EuiTextArea name='description' value={props.values.description} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.description} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiButton id='createGranularPhraseFlyOut' onClick={showFlyout} fill>
        Create
      </EuiButton>
      {flyout}
    </React.Fragment>
  )
}

export default GranularPhraseCreateFlyOut
