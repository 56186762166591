import numeral from 'numeral'
import React, { useCallback, useEffect, useState } from 'react'

import { EuiBadge, EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { City, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface CityTargeterProps {
  isInvalid: boolean
  addCity(city: City): void
}

export const CityFinder = ({ addCity, isInvalid }: CityTargeterProps) => {
  const [citySearchTerm, setCitySearchTerm] = useState<string>('')
  const debouncedCitySearchTerm = useDebounce(citySearchTerm, 750)
  const [searchCityPopoverOpen, setSearchCityPopoverOpen] = useState<boolean>(false)
  const [isLoadingCities, setIsLoadingCities] = useState(false)
  const [cities, setCities] = useState<Array<City>>([])
  const [cityOptions, setCityOptions] = useState<Array<EuiSelectableOption>>([])
  const opsClient = useOpsClient()

  const onSearchCity = () => {
    setSearchCityPopoverOpen(false)
  }

  const onSearchCityChange = (hint: string) => {
    setCitySearchTerm(hint)
  }

  const onSearchCityClick = useCallback(
    (city: City) => {
      setSearchCityPopoverOpen(false)
      setCitySearchTerm('')
      addCity(city)
    },
    [addCity]
  )

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      if (debouncedCitySearchTerm) {
        setIsLoadingCities(true)
        opsClient.getCities(debouncedCitySearchTerm).then(result => {
          if (isMounted) {
            setCities(result)
            setIsLoadingCities(false)
          }
        })
      } else {
        setCities([])
      }
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, debouncedCitySearchTerm])

  useEffect(() => {
    if (cities) {
      setCityOptions(
        cities.map(
          c =>
            ({
              label: `${c.name}, ${c.stateCode}`,
              key: c.cityId.toString(),
              checked: undefined,
              append: (
                <>
                  <EuiBadge color='primary'>{numeral(c.population).format('0a')} people</EuiBadge>
                  <EuiBadge color='primary'>{numeral(c.households).format('0a')} households</EuiBadge>
                </>
              ),
              onClick: () => onSearchCityClick(c)
            } as EuiSelectableOption)
        )
      )
    }
  }, [cities, onSearchCityClick])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={citySearchTerm}
          isLoading={isLoadingCities}
          onChange={v => onSearchCityChange(v.target.value)}
          onFocus={() => setSearchCityPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchCity()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchCityPopoverOpen}
      closePopover={() => setSearchCityPopoverOpen(false)}>
      {cityOptions && cityOptions.length ? (
        <EuiSelectable singleSelection options={cityOptions}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
