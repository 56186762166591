import moment, { Moment } from 'moment'
import React from 'react'

import { Axis, Chart, CurveType, LineSeries, BarSeries, PartialTheme, Position, Settings } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLoadingSpinner, euiPaletteForDarkBackground, EuiPanel, EuiStat } from '@elastic/eui'
import { EUI_CHARTS_THEME_DARK } from '@elastic/eui/dist/eui_charts_theme'

import { Organization } from 'api'
import { useGetSearchTermResultsQuery, useLazyGetAdSpendPerMonthQuery } from 'api/rtkQueryApi/opsApi/brandMetricsApi'

export interface IBrandMetricsChart {
  searchTerm: string
  locations: string[]
  locationType: string
  startDate: Moment
  endDate: Moment
  organization?: Organization
}

export interface IMonthBrandGain {
  forDate: Moment
  gain: number
}

interface normalizedBrandResult {
  forDate: Moment
  value: number
}

export const BrandMetricsChart: React.FC<IBrandMetricsChart> = props => {
  const getSearchTermResultsRequest = useGetSearchTermResultsQuery({ searchTerm: props.searchTerm, locations: props.locations, locationType: props.locationType, startDate: props.startDate.toDate(), endDate: props.endDate.toDate() }, { skip: !props.searchTerm })
  const [gainVsLastYear, setGainVsLastYear] = React.useState<IMonthBrandGain[]>([])
  const [getOrganizationAsSpendPerMonth, getOrganizationAsSpendPerMonthRequest] = useLazyGetAdSpendPerMonthQuery()

  React.useEffect(() => {
    if (getSearchTermResultsRequest.data) {
      const newGainVsLastYear = getSearchTermResultsRequest.data.brandResults.map(d => ({
        forDate: moment(d.forDate),
        gain: (() => {
          const lastYear = getSearchTermResultsRequest.data?.brandResults.find(d2 => moment(d2.forDate).year() === moment(d.forDate).year() - 1 && moment(d2.forDate).month() === moment(d.forDate).month())
          if (lastYear) {
            const lastYearSearches = lastYear.searches > 0 ? lastYear.searches : 1
            return (d.searches - lastYearSearches) / lastYearSearches
          }
          return 0
        })()
      }))

      setGainVsLastYear(newGainVsLastYear)
    }
  }, [getSearchTermResultsRequest.data])

  React.useEffect(() => {
    if (props.organization) {
      getOrganizationAsSpendPerMonth({ organizationId: props.organization.organizationId })
    }
  }, [props.organization])

  const dashboardBaseTheme: PartialTheme = {
    crosshair: {
      band: {
        visible: false
      }
    },
    lineSeriesStyle: {
      point: {
        radius: 2,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    highlighter: {
      point: {
        radius: 4,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    axes: {
      gridLine: {
        vertical: {
          visible: false
        },
        horizontal: {
          visible: false
        }
      }
    }
  }
  const darkBackground: PartialTheme = {
    background: {
      color: 'transparent'
    }
  }
  const darkTheme = [dashboardBaseTheme, darkBackground, EUI_CHARTS_THEME_DARK.theme]

  const NormalizeResultByYear = () => {
    if (!getSearchTermResultsRequest.data) {
      return null
    }

    const groupedYears = getSearchTermResultsRequest.data.brandResults.reduce((grouped: { year: number; count: number }[], result) => {
      const year = moment(result.forDate).utc().year()
      if (!grouped.filter(g => g.year === year).length) {
        grouped.push({ year, count: 0 })
      }
      grouped.find(g => g.year === year)!.count += 1
      return grouped
    }, [])

    const allowedYears = groupedYears.filter(g => g.count > 11).map(g => g.year)

    return getSearchTermResultsRequest.data.brandResults
      .filter(result => allowedYears.filter(y => y === moment(result.forDate).utc().year()).length > 0)
      .map(result => {
        const yearlySum = getSearchTermResultsRequest.data!.brandResults.filter(r => moment(r.forDate).utc().year() === moment(result.forDate).utc().year()).reduce((a, b) => a + b.searches, 0)
        return {
          forDate: moment(result.forDate),
          value: yearlySum > 0 ? result.searches / yearlySum : 0
        } as normalizedBrandResult
      })
  }

  const GetGroupedNormalizedMonths = () => {
    const normalizedResults = NormalizeResultByYear()
    if (!normalizedResults) {
      return null
    }

    const monthGroups: { month: number; result: normalizedBrandResult[] }[] = []

    normalizedResults.forEach(curr => {
      const month = moment(curr.forDate).utc().month()
      if (!monthGroups.filter(g => g.month === month).length) {
        monthGroups.push({ month, result: [] })
      }
      monthGroups.find(g => g.month === month)!.result.push(curr)
    })

    return monthGroups
  }

  const GetPeakMonth = () => {
    const groupedNormalizedMonths = GetGroupedNormalizedMonths()
    if (!groupedNormalizedMonths) {
      return null
    }

    return groupedNormalizedMonths
      .map(g => ({
        month: g.month,
        average: g.result.reduce((a, b) => a + b.value, 0) / g.result.length
      }))
      .sort((a, b) => b.average - a.average)[0]
  }

  const GetLowMonth = () => {
    const groupedNormalizedMonths = GetGroupedNormalizedMonths()
    if (!groupedNormalizedMonths) {
      return null
    }

    return groupedNormalizedMonths
      .map(g => ({
        month: g.month,
        average: g.result.reduce((a, b) => a + b.value, 0) / g.result.length
      }))
      .sort((a, b) => a.average - b.average)[0]
  }

  const GetYearOverYearAverage = () => {
    if (gainVsLastYear.length > 12) {
      const monthsToCount = [...gainVsLastYear]
      monthsToCount.splice(0, 12)
      return monthsToCount.reduce((a, b) => a + b.gain, 0) / monthsToCount.length
    }
    return null
  }

  if (getSearchTermResultsRequest.isLoading) {
    return <EuiLoadingSpinner />
  }

  if (!getSearchTermResultsRequest.data) {
    return <React.Fragment />
  }

  const peakMonth = GetPeakMonth()
  const lowMonth = GetLowMonth()
  const yearOverYearAverage = GetYearOverYearAverage()

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <EuiPanel paddingSize='none' style={{ overflow: 'hidden', height: 300 }}>
          <Chart>
            <Settings theme={darkTheme} showLegend={false} />
            <Axis
              id='total'
              groupId='total'
              position={Position.Left}
              labelFormat={d =>
                `${Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 1
                }).format(d)}`
              }
              domain={{
                min: Math.min(...getSearchTermResultsRequest.data.brandResults.map(r => r.searches)) * 0.95,
                max: Math.max(...getSearchTermResultsRequest.data.brandResults.map(r => r.searches)) * 1.05
              }}
            />
            <Axis
              id='yearGrowth'
              groupId='yearGrowth'
              position={Position.Left}
              domain={{
                min: -2,
                max: -1
              }}
              hide
            />
            <Axis id='date-axis' groupId='total' position={Position.Bottom} />
            <LineSeries id='brandSearches' name={`Organic Searches`} data={getSearchTermResultsRequest.data.brandResults} xAccessor={x => moment(x.forDate).utc().startOf('month').format('YYYY-MM-DD')} yAccessors={[y => y.searches]} color={[euiPaletteForDarkBackground()[0]]} groupId='total' curve={CurveType.CURVE_MONOTONE_X} />
            <LineSeries id='growth' name={`Year/Year Gain`} data={gainVsLastYear} xAccessor={x => moment(x.forDate).utc().startOf('month').format('YYYY-MM-DD')} yAccessors={[y => y.gain]} color={[euiPaletteForDarkBackground()[1]]} groupId='yearGrowth' curve={CurveType.LINEAR} tickFormat={d => `${(d * 100).toFixed(2)}%`} />
            {getOrganizationAsSpendPerMonthRequest.data && (
              <BarSeries
                id='organizationEvents'
                name={'Event'}
                barSeriesStyle={{
                  rect: {
                    widthPixel: 10
                  }
                }}
                data={getOrganizationAsSpendPerMonthRequest.data}
                xAccessor={x => moment(x.fromDate).startOf('month').format('YYYY-MM-DD')}
                yAccessors={[y => y.spend]}
                color={[euiPaletteForDarkBackground()[4]]}
                groupId='events'
                tickFormat={d => `$${d.toFixed(2)}`}
              />
            )}
          </Chart>
        </EuiPanel>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup wrap>
          <EuiFlexItem grow={false}>
            <EuiPanel style={{ minWidth: 200 }}>
              <EuiStat title={moment((peakMonth?.month ?? 0) + 1, 'MM').format('MMMM')} description='Peak'>{`${((peakMonth?.average ?? 0) * 100).toFixed(2)}% of annual traffic`}</EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel style={{ minWidth: 200 }}>
              <EuiStat title={moment((lowMonth?.month ?? 0) + 1, 'MM').format('MMMM')} description='Low'>{`${((lowMonth?.average ?? 0) * 100).toFixed(2)}% of annual traffic`}</EuiStat>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel style={{ minWidth: 200 }}>
              <EuiStat title={`${(getSearchTermResultsRequest.data.brandResults.reduce((a, b) => a + b.searches, 0) / getSearchTermResultsRequest.data.brandResults.length).toFixed()} / Month`} description='Search Average' />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel style={{ minWidth: 200 }}>
              <EuiStat title={yearOverYearAverage === null ? 'Insufficient Range' : `${(yearOverYearAverage * 100).toFixed(2)}%`} description='Year / Year Gain' />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </React.Fragment>
  )
}
