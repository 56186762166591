import {EuiButtonIcon, EuiPopover} from "@elastic/eui";
import React from "react";

export const AcActionsPopover: React.FC= props => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onActionsClick = () => {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const onPopoverClose = () => {
    setIsPopoverOpen(false)
  }
  return (
    <EuiPopover
      button={(<EuiButtonIcon iconType="boxesHorizontal" aria-label="Actions" onClick={onActionsClick}/>)}
      isOpen={isPopoverOpen}
      closePopover={onPopoverClose}
      display="block"
    >
      {props.children}
    </EuiPopover>
  )
}