import moment from 'moment'
import React from 'react'

import { Axis, Chart, CurveType, LineSeries, PartialTheme, Settings } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem, euiPaletteForLightBackground, EuiPanel, EuiSpacer, EuiStat, EuiTitle, useEuiBackgroundColor } from '@elastic/eui'
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme'

import { timespanKeySubscription, timespanSumKeySubscription } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardSubscriptionQuery, useGetExecutiveDashboardSubscriptionSumQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'

import ExecutiveDashboardSubscriptionReportFlyOut from '../ExecutiveDashboardSubscriptionReportFlyout'

export interface SubscriptionOverviewPanelProps {
  forDate: string
  timespan: string
}

const currencyStyle = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }

const SubscriptionOverviewPanel: React.FC<SubscriptionOverviewPanelProps> = ({ forDate, timespan }) => {
  const { data: totals } = useGetExecutiveDashboardSubscriptionQuery({ forDate })
  const { data: historicalTotals } = useGetExecutiveDashboardSubscriptionSumQuery({ daysOfHistory: 7, forDate })

  const dashboardBaseTheme: PartialTheme = {
    crosshair: {
      band: {
        visible: false
      }
    },
    lineSeriesStyle: {
      point: {
        radius: 2,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    highlighter: {
      point: {
        radius: 4,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    axes: {
      gridLine: {
        vertical: {
          visible: false
        },
        horizontal: {
          visible: false
        }
      }
    }
  }
  const blueBackground: PartialTheme = {
    background: {
      color: useEuiBackgroundColor('primary')
    }
  }
  const greyBackground: PartialTheme = {
    background: {
      color: useEuiBackgroundColor('subdued')
    }
  }
  const theme = [dashboardBaseTheme, blueBackground, EUI_CHARTS_THEME_LIGHT.theme]
  const greyTheme = [dashboardBaseTheme, greyBackground, EUI_CHARTS_THEME_LIGHT.theme]

  return (
    <EuiPanel hasShadow={false} color='primary'>
      {totals && historicalTotals && (
        <React.Fragment>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiTitle size='xs'>
                <h1>Subscriptions</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ExecutiveDashboardSubscriptionReportFlyOut forDate={forDate} subscriptionIds={totals.activeRevenue.subscriptionIds.concat(totals.trialingRevenue.subscriptionIds)} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat description='Total Count' title={totals.totalActive} titleSize='xs' />
            </EuiFlexItem>
            <EuiFlexItem>
              <Chart size={{ height: 40 }}>
                <Settings theme={theme} showLegend={false} />
                <Axis
                  id='number'
                  groupId='number'
                  domain={{
                    max: Math.max(...historicalTotals.map(x => x.totalActiveSubscriptions)) * 1.05,
                    min: Math.min(...historicalTotals.map(x => x.totalActiveSubscriptions)) * 0.95
                  }}
                  hide={true}
                />
                <LineSeries id='totalSubscriptions' name='Subscriptions' data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y.totalActiveSubscriptions]} color={[euiPaletteForLightBackground()[1]]} groupId='number' curve={CurveType.CURVE_MONOTONE_X} />
              </Chart>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xs' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat description={`Total ${timespan} Revenue`} title={totals.activeRevenue[timespan.toLowerCase() as timespanKeySubscription].toLocaleString('en-US', currencyStyle)} titleSize='xs' />
            </EuiFlexItem>
            <EuiFlexItem>
              <Chart size={{ height: 40 }}>
                <Settings theme={theme} showLegend={false} />
                <Axis
                  id='total'
                  groupId='total'
                  domain={{
                    min: Math.min(...historicalTotals.map(x => x[`totalActive${timespan}Revenue` as timespanSumKeySubscription])) * 0.95,
                    max: Math.max(...historicalTotals.map(x => x[`totalActive${timespan}Revenue` as timespanSumKeySubscription])) * 1.05
                  }}
                  hide={true}
                />
                <LineSeries id='totalRevenue' name={`${timespan} Revenue`} data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y[`totalActive${timespan}Revenue` as timespanSumKeySubscription]]} color={[euiPaletteForLightBackground()[0]]} groupId='total' curve={CurveType.CURVE_MONOTONE_X} tickFormat={d => `${Number(d).toLocaleString('en-US', currencyStyle)}`} />
              </Chart>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='s' />
          <EuiSpacer size='xs' />
          <EuiPanel color='transparent' hasBorder={true}>
            <EuiFlexGroup>
              <EuiFlexItem grow={2}>
                <EuiStat description={`MTD Signups`} title={totals.totalMtdSignups} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiStat description={`Previous`} title={totals.totalPreviousMtdSignups} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={1} />
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>Active Subscriptions</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description={`${timespan} Revenue`} title={totals.activeRevenue[timespan.toLowerCase() as timespanKeySubscription].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardSubscriptionReportFlyOut forDate={forDate} subscriptionIds={totals.activeRevenue.subscriptionIds} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Count' title={totals.totalActive} titleSize='xxxs' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>Trialing Subscriptions</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description={`${timespan} Revenue`} title={totals.trialingRevenue[timespan.toLowerCase() as timespanKeySubscription].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardSubscriptionReportFlyOut forDate={forDate} subscriptionIds={totals.trialingRevenue.subscriptionIds} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Count' title={totals.totalTrialing} titleSize='xxxs' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </React.Fragment>
      )}
    </EuiPanel>
  )
}
export default SubscriptionOverviewPanel
