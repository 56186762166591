import React, { useRef } from 'react'

import { EuiConfirmModal, EuiFieldText, EuiFormRow, EuiOverlayMask, EuiRadioGroup } from '@elastic/eui'

import { CreateOrganization, SignupMethod } from 'api'
import { useCreateOrganizationMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'

export interface IAcCreateOrganizationModal {
  onClose: () => void
  onOrgCreated: (orgId: string) => void
}

export const AcCreateOrganizationModal: React.FC<IAcCreateOrganizationModal> = props => {
  const [createOrg, createOrgQuery] = useCreateOrganizationMutation()
  const [createInfo, setCreateInfo] = React.useState<CreateOrganization>({
    email: '',
    company: '',
    phone: '',
    firstName: '',
    lastName: '',
    signupMethod: SignupMethod.AgencyApplication
  })

  const signupTypes = useRef([
    {
      id: SignupMethod.AgencyApplication,
      label: 'Agency Application'
    },
    {
      id: SignupMethod.Sales,
      label: SignupMethod.Sales
    },
    {
      id: SignupMethod.Support,
      label: SignupMethod.Support
    }
  ])

  const onConfirm = () => {
    createOrg(createInfo).then(res => {
      if ('data' in res) {
        props.onOrgCreated(res.data.organizationId)
      }
    })
  }

  const onCancel = () => {
    props.onClose()
  }

  const isValid = () => {
    return createInfo.email.length > 0
  }

  return (
    <EuiOverlayMask>
      <EuiConfirmModal title={'Create New Organization'} onConfirm={onConfirm} onCancel={onCancel} confirmButtonText={'Create'} cancelButtonText={'Cancel'} isLoading={createOrgQuery.isLoading} confirmButtonDisabled={!isValid()}>
        <EuiFormRow label={'Email'} fullWidth>
          <EuiFieldText value={createInfo.email} onChange={e => setCreateInfo({ ...createInfo, email: e.target.value })} />
        </EuiFormRow>
        <EuiFormRow label={'Company'} fullWidth>
          <EuiFieldText value={createInfo.company} onChange={e => setCreateInfo({ ...createInfo, company: e.target.value })} />
        </EuiFormRow>
        <EuiFormRow label={'Phone'} fullWidth>
          <EuiFieldText value={createInfo.phone} onChange={e => setCreateInfo({ ...createInfo, phone: e.target.value })} />
        </EuiFormRow>
        <EuiFormRow label={'First Name'} fullWidth>
          <EuiFieldText value={createInfo.firstName} onChange={e => setCreateInfo({ ...createInfo, firstName: e.target.value })} />
        </EuiFormRow>
        <EuiFormRow label={'Last Name'} fullWidth>
          <EuiFieldText value={createInfo.lastName} onChange={e => setCreateInfo({ ...createInfo, lastName: e.target.value })} />
        </EuiFormRow>
        <EuiFormRow label={'Signup Reason'} fullWidth>
          <EuiRadioGroup
            options={signupTypes.current}
            idSelected={createInfo.signupMethod}
            onChange={s => {
              setCreateInfo({ ...createInfo, signupMethod: s as SignupMethod })
            }}
            name='campaign type'
          />
        </EuiFormRow>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}
