import React from 'react'

import { Campaign, Organization } from 'api'
import { useReactivateCampaignMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { AcPaymentModal } from 'components/Modals/AcPaymentModal'

export interface IReactivateCampaignModal {
  campaign: Campaign
  organization: Organization
  onCloseModal: () => void
}

export const ReactivateCampaignModal: React.FC<IReactivateCampaignModal> = props => {
  const [reactivateCampaign, reactivateCampaignQuery] = useReactivateCampaignMutation()
  const isIncluded = props.organization.includedCampaignIds.some(id => id === props.campaign.campaignId)

  const cancelReactivateCampaignModal = () => {
    if (!reactivateCampaignQuery.isLoading) {
      props.onCloseModal()
    }
  }

  const confirmReactivateCampaignModal = (paymentMethodId: string) => {
    reactivateCampaign({
      campaignId: props.campaign.campaignId,
      paymentMethodId
    }).then(() => {
      props.onCloseModal()
    })
  }

  if (isIncluded) {
    return <AcConfirmModal title='Are you sure you want to reactivate this campaign?' message={`This campaign is included, no additional payment is needed.`} onCancel={cancelReactivateCampaignModal} onConfirm={() => confirmReactivateCampaignModal('')} cancelButtonText='Cancel' confirmButtonText={'Reactivate'} isLoading={reactivateCampaignQuery.isLoading} />
  }

  return (
    <AcPaymentModal
      organizationId={props.organization.organizationId}
      title='Are you sure you want to reactivate this campaign?'
      message={`To restart this campaign, we need to charge a payment method ${props.campaign.budget.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}.`}
      onCancel={cancelReactivateCampaignModal}
      onConfirm={confirmReactivateCampaignModal}
      cancelButtonText='Cancel'
      confirmButtonText={`Pay ${props.campaign.budget.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })} Now`}
      isLoading={reactivateCampaignQuery.isLoading}
    />
  )
}
