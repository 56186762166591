import React, { useEffect } from 'react'

import { EuiRadioGroup, EuiRadioGroupOption } from '@elastic/eui'

import { useGetPaymentMethodBankAccountsQuery, useGetPaymentMethodCardsQuery } from 'api/rtkQueryApi/opsApi/paymentMethodsApi'

export interface IPaymentMethodsRadioGroup {
  organizationId: string
  onSelectedPaymentMethodIdChange?: (paymentMethodId: string | undefined) => void
}

export const PaymentMethodsRadioGroup = (props: IPaymentMethodsRadioGroup) => {
  const { data: paymentMethodsCard } = useGetPaymentMethodCardsQuery(props.organizationId)
  const { data: paymentMethodsBankAccount } = useGetPaymentMethodBankAccountsQuery(props.organizationId)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState<string>()

  const [paymentMethodOptions, setPaymentMethodOptions] = React.useState<EuiRadioGroupOption[]>([])

  useEffect(() => {
    if (paymentMethodsCard && selectedPaymentMethodId === undefined && paymentMethodsCard.length > 0) {
      setSelectedPaymentMethodId(paymentMethodsCard?.[0].id)
      props.onSelectedPaymentMethodIdChange?.(paymentMethodsCard?.[0].id)
    } else if (paymentMethodsBankAccount && selectedPaymentMethodId === undefined && paymentMethodsBankAccount.length > 0) {
      setSelectedPaymentMethodId(paymentMethodsBankAccount?.[0].id)
      props.onSelectedPaymentMethodIdChange?.(paymentMethodsBankAccount?.[0].id)
    }

    if (paymentMethodsCard && paymentMethodsBankAccount) {
      setPaymentMethodOptions([
        ...paymentMethodsCard.map(pm => ({
          id: pm.id,
          label: `${pm.brand} xxxx${pm.last4}, expiring ${pm.expiryMonth.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}/${pm.expiryYear}`
        })),
        ...paymentMethodsBankAccount.map(pm => ({
          id: pm.id,
          label: `Bank Account: ${pm.name}`
        }))
      ])
    }
  }, [paymentMethodsCard, paymentMethodsBankAccount])

  const onPaymentMethodChange = (paymentMethodId: string) => {
    setSelectedPaymentMethodId(paymentMethodId)
    props.onSelectedPaymentMethodIdChange?.(paymentMethodId)
  }

  return (
    <React.Fragment>
      <EuiRadioGroup style={{ maxHeight: 300, overflow: 'auto' }} options={paymentMethodOptions} onChange={onPaymentMethodChange} idSelected={selectedPaymentMethodId} />
    </React.Fragment>
  )
}
