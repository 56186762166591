import moment from 'moment'
import React from 'react'

import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiSpacer } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CoreReferenceType, Invoice, InvoiceLine } from 'api/entities/Invoice'

interface InvoicePageParams {
  invoice: Invoice
}

const InvoicePage: React.FC<InvoicePageParams> = ({ invoice }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const columns: Array<EuiBasicTableColumn<InvoiceLine>> = [
    {
      name: 'Title',
      render: (il: InvoiceLine) => il.title
    },
    {
      name: `Description`,
      render: (il: InvoiceLine) => il.description
    },
    {
      name: `Campaign Id`,
      render: (il: InvoiceLine) => {
        if (il.order.coreReferenceType !== CoreReferenceType.Campaign) {
          return ''
        }

        return il.order.referenceId
      }
    },
    {
      name: `Subscription Id`,
      render: (il: InvoiceLine) => {
        if (il.order.coreReferenceType !== CoreReferenceType.Subscription) {
          return ''
        }

        return il.order.referenceId
      }
    },
    {
      name: `Plan/Package Id`,
      render: (il: InvoiceLine) => {
        if (il.order.coreReferenceType === CoreReferenceType.Plan || il.order.coreReferenceType === CoreReferenceType.Package || il.order.coreReferenceType === CoreReferenceType.VendastaPackage) {
          return il.order.referenceId
        }

        return ''
      }
    },
    {
      name: `Amount`,
      render: (il: InvoiceLine) => formatter.format(il.subTotalAmount),
      align: 'right'
    }
  ]

  const css = `
        .table-color div .euiTable {
            background-color: inherit;
        }
        `
  return (
    <EuiFlexGroup>
      <style>{css}</style>
      <EuiFlexItem>
        <EuiFlexGroup direction={'column'} style={{ margin: '10px' }}>
          <EuiFlexItem grow={false}>
            <EuiFlexGrid columns={2}>
              <EuiFlexItem>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <b style={{ paddingRight: '5px' }}>Invoice Date</b> {moment.utc(invoice.created).format('MMM Do YYYY')}
                  </div>
                </div>
              </EuiFlexItem>
              <EuiFlexItem style={{ minWidth: '400px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <b style={{ paddingRight: '5px' }}>Invoice Number</b> {invoice?.number}
                  </div>
                </div>
              </EuiFlexItem>
              <EuiFlexItem style={{ minWidth: '400px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <b style={{ paddingRight: '5px' }}>Status</b> {invoice?.status}
                  </div>
                </div>
              </EuiFlexItem>
            </EuiFlexGrid>
            <EuiSpacer size={'xl'} />

            <EuiInMemoryTable className={'table-color'} items={invoice?.lines ?? []} columns={columns} />

            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem style={{ minWidth: '200px' }}>
                {invoice?.paidDate !== null && invoice?.paidDate && (
                  <EuiFlexGroup alignItems={'flexEnd'}>
                    <EuiFlexItem>
                      <span
                        style={{
                          transform: 'rotate(12deg)',
                          color: '#357960',
                          fontSize: '3rem',
                          fontWeight: '700',
                          border: '0.25rem solid #357960',
                          display: 'inline-block',
                          padding: '0.25rem 1rem',
                          textTransform: 'uppercase',
                          borderRadius: '1rem',
                          fontFamily: 'Courier',
                          mixBlendMode: 'multiply',
                          width: 'fit-content'
                        }}>
                        Paid
                      </span>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexItem>
              <EuiFlexItem>
                <div>
                  <EuiFlexGroup justifyContent='flexEnd'>
                    <EuiFlexItem grow={false}>Total</EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: '90px', textAlign: 'end' }} grow={false}>
                      {formatter.format(invoice?.amount ?? 0)}
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup justifyContent='flexEnd'>
                    <EuiFlexItem grow={false}>{invoice?.paidDate && moment.utc(invoice?.paidDate).format('MMM Do')} Payment</EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: '90px', textAlign: 'end' }} grow={false}>
                      {formatter.format(invoice?.amountPaid ?? 0)}
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup justifyContent='flexEnd'>
                    <EuiFlexItem style={{ minWidth: '250px', textAlign: 'end', borderBottom: '1px solid #D3DAE6' }} grow={false} />
                  </EuiFlexGroup>
                  <EuiFlexGroup justifyContent='flexEnd'>
                    <EuiFlexItem grow={false} style={{ fontWeight: 'bold' }}>
                      Balance Due
                    </EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: '90px', textAlign: 'end' }} grow={false}>
                      {formatter.format(invoice?.amountDue ?? 0)}
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </div>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default InvoicePage
