import _ from 'lodash'
import { useReducer } from 'react'

import { Contact } from 'api'

export enum ContactActionKind {
  CONTACT = 'CONTACT',
  TYPE = 'TYPE',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  ADDRESS = 'ADDRESS',
  CITY = 'CITY',
  STATE = 'STATE',
  ZIP_CODE = 'ZIP_CODE',
  TIME_ZONE = 'TIME_ZONE',
  CONTACT_METHODS = 'CONTACT_METHODS',
  CONTACT_METHOD_EDIT = 'CONTACT_METHOD_EDIT',
  CONTACT_METHOD_ADD = 'CONTACT_METHOD_ADD',
  CONTACT_METHOD_REMOVE = 'CONTACT_METHOD_REMOVE'
}

export interface ContactAction {
  type: ContactActionKind
  payload: any
}

function reducer(state: Contact, action: ContactAction): Contact {
  const clone = _.cloneDeep(state)

  switch (action.type) {
    case ContactActionKind.CONTACT:
      return { ...clone, ...action.payload }
    case ContactActionKind.FIRST_NAME:
      return { ...clone, firstName: action.payload }
    case ContactActionKind.LAST_NAME:
      return { ...clone, lastName: action.payload }
    case ContactActionKind.ADDRESS:
      return { ...clone, address: action.payload }
    case ContactActionKind.CITY:
      return { ...clone, city: action.payload }
    case ContactActionKind.STATE:
      return { ...clone, state: action.payload }
    case ContactActionKind.ZIP_CODE:
      return { ...clone, zipCode: action.payload }
    case ContactActionKind.TIME_ZONE:
      return { ...clone, timeZone: action.payload }
    case ContactActionKind.CONTACT_METHODS:
      return { ...clone, contactMethods: action.payload }
    case ContactActionKind.CONTACT_METHOD_ADD:
      return { ...clone, contactMethods: [...clone.contactMethods, action.payload] }
    case ContactActionKind.CONTACT_METHOD_EDIT:
      const newContactMethods = clone.contactMethods.map(contactMethod => {
        if (contactMethod.id === action.payload.id) {
          return action.payload
        }
        return contactMethod
      })

      return {
        ...clone,
        contactMethods: newContactMethods
      }
    case ContactActionKind.CONTACT_METHOD_REMOVE:
      return { ...clone, contactMethods: clone.contactMethods.filter(contactMethod => contactMethod.id !== action.payload) }
    default:
      return clone
  }
}

export const useContactReducer = (initialState: Contact) => {
  return useReducer(reducer, _.cloneDeep(initialState))
}
