import React, { useEffect, useState } from 'react'

import { EuiBreadcrumbs, EuiLink, EuiLoadingContent, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CampaignSubType, NativeDetailsWithImage } from 'api'
import { useGetInternetPendingAuditsQuery } from 'api/rtkQueryApi/opsApi/adsApi'
import { useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { NativeToDisplayBuilder } from 'features/customers/campaigns/NativeToDisplayBuilder'
import history from 'services/HistoryService'

interface SimpleCampaignInfo {
  campaignId: string
  accountId: string
}

export interface INativeToDisplayPage {
  campaignId: string
  onAuditAsNative?: () => void
}

export const NativeToDisplayPage: React.FC<INativeToDisplayPage> = props => {
  const [campaignInfo, setCampaignInfo] = useState<SimpleCampaignInfo>()
  const [nativeDetailsList, setNativeDetailsList] = useState<NativeDetailsWithImage[]>([])
  const getCampaignQuery = useGetCampaignQuery(props.campaignId)
  const getInternetPendingAuditsQuery = useGetInternetPendingAuditsQuery({ campaignId: props.campaignId })

  useEffect(() => {
    if (getInternetPendingAuditsQuery.data) {
      const adsForCampaign = getInternetPendingAuditsQuery.data
      setNativeDetailsList(
        adsForCampaign.map(a => ({
          headline: a.headline,
          body: a.body,
          companyName: a.companyName,
          callToAction: a.callToAction,
          uploadedUrl: a.highQualityUrl,
          destinationUrl: a.destinationUrl,
          adId: a.adId
        }))
      )
      if (adsForCampaign.length > 0) {
        const firstAd = adsForCampaign[0]
        setCampaignInfo({
          campaignId: firstAd.campaignId,
          accountId: firstAd.accountId
        })
      }
    }
  }, [getInternetPendingAuditsQuery, props.campaignId])

  const onAdDisapproved = (adId: string) => {
    setNativeDetailsList(nativeDetailsList.filter(d => d.adId !== adId))
  }

  const currentCampaign = getCampaignQuery.data

  return (
    <React.Fragment>
      <EuiPage>
        <EuiPageBody component='div'>
          <EuiPageContent>
            {currentCampaign && (
              <EuiBreadcrumbs
                breadcrumbs={[
                  {
                    text: currentCampaign.organizationName,
                    onClick: () => history.push(`/customers/organizations/${currentCampaign?.organizationId}`),
                    truncate: true
                  },
                  {
                    text: currentCampaign.accountName,
                    onClick: () => history.push(`/customers/accounts/${currentCampaign?.accountId}`),
                    truncate: true
                  },
                  {
                    text: currentCampaign.campaignName,
                    onClick: () => history.push(`/customers/campaigns/${currentCampaign?.campaignId}`),
                    truncate: true
                  }
                ]}
              />
            )}
            <EuiSpacer />
            <EuiPageContentHeader>
              <EuiPageContentHeaderSection>
                <EuiTitle size='m'>
                  <h1>Display Creation</h1>
                </EuiTitle>
                {currentCampaign && (currentCampaign.campaignSubType === CampaignSubType.NativeOrDisplay || currentCampaign.campaignSubType === CampaignSubType.Display) && <EuiLink onClick={props.onAuditAsNative}>Audit As Native</EuiLink>}
              </EuiPageContentHeaderSection>
            </EuiPageContentHeader>
            <EuiPageContentBody>
              {getInternetPendingAuditsQuery.isLoading ? (
                <EuiLoadingContent lines={5} />
              ) : campaignInfo && nativeDetailsList.length > 0 ? (
                <NativeToDisplayBuilder
                  accountId={campaignInfo.accountId}
                  detailsList={nativeDetailsList}
                  campaignId={campaignInfo.campaignId}
                  onSaved={() => {
                    history.push(`/customers/campaigns/${campaignInfo.campaignId}`)
                  }}
                  onAdDisapproved={onAdDisapproved}
                />
              ) : (
                <EuiText>No Ads To Audit</EuiText>
              )}
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </React.Fragment>
  )
}
