import moment from 'moment-timezone'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiIcon, EuiImage, EuiInMemoryTable, EuiLink, EuiPageTemplate, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { BusinessType, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import BusinessTypeCreateFlyOut from './BusinessTypeCreateFlyOut'

const BusinessTypesPage: React.FC = () => {
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false)
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([])
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canCreate, setCanCreate] = useState(false)
  const [headerButtons, setHeaderButtons] = useState<ReactNode[]>([])

  useEffect(() => {
    if (userProfile) {
      setCanCreate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    setIsLoadingBusinessTypes(true)
    opsClient?.getBusinessTypes().then(bt => {
      setBusinessTypes(bt)
      setIsLoadingBusinessTypes(false)
    })
  }, [opsClient])

  useEffect(() => {
    if (canCreate) {
      setHeaderButtons([<BusinessTypeCreateFlyOut />])
    }
  }, [canCreate])

  const columns: Array<EuiBasicTableColumn<BusinessType>> = [
    {
      name: ``,
      width: '40px',
      render: (c: BusinessType) => (c.imageUrl ? <EuiImage alt={'bt'} src={c.imageUrl} style={{ height: 22 }} /> : <></>)
    },
    {
      name: `Name`,
      render: (c: BusinessType) => <EuiLink onClick={() => history.push(`/data/businessTypes/${c.id}`)}>{c.name}</EuiLink>,
      sortable: (c: BusinessType) => c.name
    },
    {
      name: 'Category(s)',
      render: (c: BusinessType) => <React.Fragment>{c.businessTypeCategories.join(', ')}</React.Fragment>
    },
    {
      name: 'Keywords',
      render: (c: BusinessType) => <React.Fragment>{c.keywords.map(k => k.word).join(', ')}</React.Fragment>
    },
    {
      name: '# Campaigns',
      width: '150',
      field: 'numberCampaigns',
      sortable: (c: BusinessType) => c.numberCampaigns
    },
    {
      name: 'Is Hidden',
      width: '150',
      render: (c: BusinessType) => <React.Fragment>{c.isHidden && <EuiIcon type='checkInCircleFilled' />}</React.Fragment>
    },
    {
      name: 'Last Updated',
      width: '240',
      render: (c: BusinessType) => moment.utc(c.updated).utc().tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss z'),
      sortable: (c: BusinessType) => moment.utc(c.updated).utc().tz(moment.tz.guess()).format('YYYY:MM:DD HH:mm:ss')
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header pageTitle='Business Types' restrictWidth={false} rightSideItems={headerButtons} />
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiInMemoryTable
          loading={isLoadingBusinessTypes}
          items={businessTypes}
          columns={columns}
          sorting={sorting}
          pagination={pagination}
          tableLayout='fixed'
          search={{
            box: {
              incremental: true
            }
          }}
        />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default BusinessTypesPage
