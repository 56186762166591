import React, { useEffect, useState } from 'react'

import { EuiHealth } from '@elastic/eui'

import { BusinessTypeTextAssignment } from 'api'

type HealthCellType = {
  assignment: BusinessTypeTextAssignment
}

const HealthCell: React.FC<HealthCellType> = ({ assignment }) => {
  const [color, setColor] = useState('warning')

  useEffect(() => {
    if (assignment) setColor(!assignment.isAssigned ? 'danger' : assignment.selections.filter(p => !p.isCompleted).length === 0 ? 'success' : 'warning')
  }, [assignment])

  return <EuiHealth color={color} />
}

export default HealthCell
