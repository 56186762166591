import { Moment } from 'moment'

import { BundleCategory, BundleActivation, BundleBillingView, CampaignSlotSimple, TargetingPriority, Bundle, BundleType } from 'api/entities/Bundle'
import { CampaignType } from 'api/entities/Campaign'
import { IPayment } from 'api/entities/Invoice'
import { CreatePackageRequest } from 'api/entities/Package'
import { CreatePlanRequest } from 'api/entities/Plan'
import { CreateSelfServicePackageRequest, UpdateSelfServicePackageRequest } from 'api/entities/SelfServicePackage'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'
import { organizationsApi } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { addQueryArg } from 'utils/UrlUtils'

export enum BundlesView {
  CampaignAdvisor = 'CampaignAdvisor',
  AccountManager = 'AccountManager',
  BusinessDefined = 'BusinessDefined'
}

export interface IGetAllBundlesBillingViewByCampaignAdvisorEmailQuery {
  view: BundlesView
  emailFilterBasedOnView?: string
}

export interface IGetBundleActivationQuery {
  ignoreAgencies?: boolean
  pastXDays?: number
}

function bundleTransformFn(response: { data: Bundle }) {
  return response.data
}

function bundleTransformArrFn(response: { data: Bundle[] }) {
  return response.data
}

const bundlesTag = opsApi.enhanceEndpoints({ addTagTypes: ['Bundles', 'BundleById', 'BundleBillings', 'BundleBilling', 'OrganizationBundles', 'BundleById', 'AccountBundles', 'BundleActivations'] })

export const bundlesApi = bundlesTag.injectEndpoints({
  endpoints: build => ({
    getBundleForId: build.query<Bundle, string>({
      query: bundleId => `bundles/${bundleId}`,
      transformResponse: bundleTransformFn,
      providesTags: (result, error, bundleId) => [{ type: 'BundleById', id: bundleId }]
    }),
    getAllBundles: build.query<Bundle[], void>({
      query: () => `bundles`,
      transformResponse: bundleTransformArrFn,
      providesTags: () => [{ type: 'Bundles' }]
    }),
    getBundleActivations: build.query<BundleActivation[], IGetBundleActivationQuery>({
      query: ({ ignoreAgencies, pastXDays }) => {
        let url = `bundles/activations`

        if (ignoreAgencies) {
          url = addQueryArg(url, 'ignoreAgencies', ignoreAgencies.toString())
        }

        if (pastXDays) {
          url = addQueryArg(url, 'pastXDays', pastXDays.toString())
        }

        return url
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { ignoreAgencies, pastXDays }) => [{ type: 'BundleActivations', id: `${ignoreAgencies}|${pastXDays}` }]
    }),
    getAllBundlesBillingViewByCampaignAdvisorEmail: build.query<BundleBillingView[], IGetAllBundlesBillingViewByCampaignAdvisorEmailQuery>({
      query: ({ view, emailFilterBasedOnView }) => `bundles/getAllBundlesBillingView?view=${view}&emailFilterBasedOnView=${emailFilterBasedOnView ?? ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { view, emailFilterBasedOnView }) =>
        result
          ? [
              ...result.map(bundleBilling => ({
                type: 'BundleBilling' as const,
                id: bundleBilling.bundle.id
              })),
              { type: 'BundleBillings', id: `${emailFilterBasedOnView}|${view}` }
            ]
          : [{ type: 'BundleBillings', id: `${emailFilterBasedOnView}|${view}` }]
    }),
    getOrganizationBundles: build.query<Bundle[], string>({
      query: organizationId => `organizations/${organizationId}/bundles`,
      transformResponse: bundleTransformArrFn,
      providesTags: (result, error, organizationId) => [{ type: 'OrganizationBundles', id: organizationId }]
    }),
    getAccountBundles: build.query<Bundle[], string>({
      query: accountId => `accounts/${accountId}/bundles`,
      transformResponse: bundleTransformArrFn,
      providesTags: (result, error, accountId) => [{ type: 'AccountBundles', id: accountId }]
    }),
    createBundle: build.mutation<Bundle, CreatePlanRequest | CreatePackageRequest | CreateSelfServicePackageRequest>({
      query: (request: CreatePlanRequest | CreatePackageRequest | CreateSelfServicePackageRequest) => {
        let url = 'bundles/'

        if (request.bundleType === BundleType.Plan) {
          url += 'plan'
        } else if (request.bundleType === BundleType.Package) {
          url += 'package'
        } else if (request.bundleType === BundleType.SelfServicePackage) {
          url += 'selfServicePackage'
        }

        return {
          url: url,
          method: 'POST',
          body: request
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'OrganizationBundles' }, { type: 'AccountBundles' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        dispatch(organizationsApi.util.invalidateTags([{ type: 'CampaignsWithoutBundlesForOrgId', id: result?.data?.organizationId }]))
      }
    }),
    addCampaignToCampaignSlot: build.mutation<Bundle, { campaignId: string; campaignSlotId: string; bundleId: string }>({
      query: request => ({
        url: `campaigns/${request.campaignId}/addCampaignToBundleSlot/${request.bundleId}/${request.campaignSlotId}`,
        method: 'POST',
        body: {}
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [{ type: 'BundleById', id: result?.id }, { type: 'OrganizationBundles' }, { type: 'AccountBundles' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        dispatch(organizationsApi.util.invalidateTags([{ type: 'CampaignsWithoutBundlesForOrgId', id: result?.data?.organizationId }]))
      }
    }),
    addCampaignSlotsToBundle: build.mutation<Bundle, { bundleId: string; organizationId: string; campaignSlots: CampaignSlotSimple[] }>({
      query: request => ({
        url: `bundles/${request.bundleId}/addCampaignSlots`,
        method: 'POST',
        body: {
          campaignSlots: request.campaignSlots.map(slot => ({
            campaignType: slot.campaignType,
            budget: slot.budget,
            isBonus: slot.isBonus
          }))
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [{ type: 'BundleById', id: result?.id }, { type: 'OrganizationBundles' }, { type: 'AccountBundles' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        dispatch(organizationsApi.util.invalidateTags([{ type: 'CampaignsWithoutBundlesForOrgId', id: result?.data?.organizationId }]))
      }
    }),
    changeBundleCampaignSlots: build.mutation<Bundle, { bundleId: string; organizationId: string; campaignSlots: CampaignSlotSimple[] }>({
      query: request => ({
        url: `bundles/${request.organizationId}/${request.bundleId}/changeBundleCampaignSlots`,
        method: 'POST',
        body: {
          campaignSlots: request.campaignSlots.map(slot => ({
            campaignType: slot.campaignType,
            budget: slot.budget,
            isBonus: slot.isBonus
          }))
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [{ type: 'BundleById', id: result?.id }, { type: 'OrganizationBundles' }, { type: 'AccountBundles' }]
    }),
    updateIsWatchListed: build.mutation<Bundle, { organizationId: string; bundleId: string; isWatchListed: boolean }>({
      query: request => ({
        url: `bundles/${request.bundleId}/updateIsWatchListed`,
        method: 'POST',
        body: {
          isWatchListed: request.isWatchListed
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    updateBundleCategory: build.mutation<Bundle, { organizationId: string; bundleId: string; bundleCategory: BundleCategory; targetingPriority: TargetingPriority }>({
      query: request => ({
        url: `bundles/${request.bundleId}/updateBundleCategory`,
        method: 'POST',
        body: {
          bundleCategory: request.bundleCategory,
          targetingPriority: request.targetingPriority
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    updateSelfServicePackage: build.mutation<Bundle, UpdateSelfServicePackageRequest>({
      query: request => {
        return {
          url: `bundles/${request.bundleId}/updateSelfServicePackage`,
          method: 'POST',
          body: request
        }
      },
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    endBundle: build.mutation<Bundle, { organizationId: string; bundleId: string }>({
      query: request => ({
        url: `bundles/${request.bundleId}/endBundle`,
        method: 'POST',
        body: {}
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    forceEndBundle: build.mutation<Bundle, { organizationId: string; bundleId: string }>({
      query: request => ({
        url: `bundles/${request.bundleId}/forceEndBundle`,
        method: 'POST',
        body: {}
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    deleteBundle: build.mutation<Bundle, { organizationId: string; bundleId: string }>({
      query: request => ({
        url: `bundles/${request.bundleId}/deleteBundle`,
        method: 'POST',
        body: {}
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    postponeBundle: build.mutation<Bundle, { organizationId: string; bundleId: string; untilDate: Moment }>({
      query: request => ({
        url: `bundles/${request.bundleId}/postponeBundle`,
        method: 'POST',
        body: {
          resumeDate: request.untilDate
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    reactivateBundle: build.mutation<Bundle, { organizationId: string; bundleId: string; paymentMethodId?: string; paymentSplitRequests?: IPayment[] }>({
      query: request => ({
        url: `bundles/${request.bundleId}/retryBundlePayment`,
        method: 'POST',
        body: {
          paymentMethodId: request.paymentMethodId,
          paymentSplitRequests: request.paymentSplitRequests
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [
        { type: 'BundleById', id: result?.id },
        { type: 'OrganizationBundles', id: result?.organizationId },
        { type: 'AccountBundles', id: result?.accountId }
      ]
    }),
    changeCampaignSlotType: build.mutation<Bundle, { organizationId: string; campaignSlotId: string; bundleId: string; campaignType: CampaignType }>({
      query: request => ({
        url: `bundles/${request.organizationId}/changeBundleCampaignSlotType`,
        method: 'POST',
        body: {
          BundleId: request.bundleId,
          campaignSlotId: request.campaignSlotId,
          campaignType: request.campaignType
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [{ type: 'BundleById', id: result?.id }, { type: 'OrganizationBundles' }, { type: 'AccountBundles' }]
    }),
    unbindBundleCampaignSlot: build.mutation<Bundle, { organizationId: string; campaignSlotId: string; bundleId: string }>({
      query: request => ({
        url: `bundles/${request.organizationId}/unbindBundleCampaignSlot`,
        method: 'POST',
        body: {
          BundleId: request.bundleId,
          campaignSlotId: request.campaignSlotId
        }
      }),
      transformResponse: bundleTransformFn,
      invalidatesTags: result => [{ type: 'BundleById', id: result?.id }, { type: 'OrganizationBundles' }, { type: 'AccountBundles' }]
    })
  }),
  overrideExisting: false
})

export const {
  useGetBundleForIdQuery,
  useLazyGetBundleForIdQuery,
  useGetAllBundlesQuery,
  useUnbindBundleCampaignSlotMutation,
  useGetBundleActivationsQuery,
  useGetAllBundlesBillingViewByCampaignAdvisorEmailQuery,
  useUpdateIsWatchListedMutation,
  useUpdateBundleCategoryMutation,
  useChangeCampaignSlotTypeMutation,
  useGetOrganizationBundlesQuery,
  useGetAccountBundlesQuery,
  useAddCampaignToCampaignSlotMutation,
  useAddCampaignSlotsToBundleMutation,
  useChangeBundleCampaignSlotsMutation,
  useCreateBundleMutation,
  useUpdateSelfServicePackageMutation,
  useEndBundleMutation,
  useForceEndBundleMutation,
  useDeleteBundleMutation,
  useReactivateBundleMutation,
  usePostponeBundleMutation
} = bundlesApi
