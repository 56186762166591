import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiSpacer, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Organization, Contact } from 'api'
import { contactsApi, useLazyGetContactsQuery, useDeleteContactMutation } from 'api/rtkQueryApi/opsApi/contactsApi'
import { AcNotesFlyout } from 'components/ACTools/AcNotesFlyout'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { ContactsPane } from 'features/customers/contacts/ContactPane'

interface ContactsTabParams {
  organization: Organization
}

export const ContactsTab: React.FC<ContactsTabParams> = ({ organization }) => {
  const dispatch = useDispatch()
  const [triggerGetContacts, contactsResult] = useLazyGetContactsQuery()
  const [deleteContact] = useDeleteContactMutation()
  const [selectedContact, setSelectedContact] = useState<Contact>()
  const [showContactPanel, setShowContactPanel] = useState(false)
  const [showContactDeleteModal, setShowContactDeleteModal] = useState(false)
  const [contactForNotes, setContactForNotes] = useState<Contact>()
  const [showContactNotes, setShowContactNotes] = useState(false)

  useEffect(() => {
    triggerGetContacts(organization.organizationId).then(results => {
      if (results.data) {
        setSelectedContact(results.data[0])
      }
    })
  }, [organization])

  useEffect(() => {
    const arg: void = undefined
    dispatch(contactsApi.util.prefetch('getTimeZones', arg, { ifOlderThan: 300 }))
  }, [])

  const actions = [
    {
      render: (contact: Contact) => {
        return (
          <EuiButtonIcon
            iconType={'notebookApp'}
            color={'success'}
            aria-label={'Contact Notes'}
            onClick={() => {
              setShowContactNotes(true)
              setContactForNotes(contact)
            }}
          />
        )
      }
    },
    {
      name: 'Delete',
      description: 'Delete this contact method',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: () => {
        setShowContactDeleteModal(true)
      }
    }
  ]

  const columns = [
    {
      name: 'First Name',
      field: 'firstName'
    },
    {
      name: 'Last Name',
      field: 'lastName'
    },
    {
      name: 'Comms',
      render: (c: Contact) => c.contactMethods?.find(m => m.isPrimary)?.info ?? ''
    },
    {
      name: 'Time Zone',
      field: 'timeZone'
    },
    {
      name: 'Address',
      field: 'address'
    },
    {
      name: 'City',
      field: 'city'
    },
    {
      name: 'State',
      field: 'state'
    },
    {
      name: 'Zip Code',
      field: 'zipCode'
    },
    {
      name: 'Actions',
      actions
    }
  ] as EuiBasicTableColumn<Contact>[]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100]
  }

  const getRowProps = (item: Contact) => {
    return {
      onClick: () => {
        setSelectedContact(item)
        setShowContactPanel(true)
      }
    }
  }

  const onCreateContactClick = () => {
    setSelectedContact(undefined)
    setShowContactPanel(true)
  }

  const updateSelectedContact = (contact: Contact) => {
    setSelectedContact(contact)
    setShowContactPanel(false)
  }

  const onCancelDeleteModal = () => {
    setShowContactDeleteModal(false)
  }

  const onConfirmDeleteModal = () => {
    if (selectedContact) {
      deleteContact({ contactId: selectedContact.id, organizationId: organization.organizationId })
    }
    setShowContactPanel(false)
    setShowContactDeleteModal(false)
  }

  const onContactNotesFlyoutCloseClick = () => {
    setShowContactNotes(false)
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiButton onClick={onCreateContactClick} fill>
        Create
      </EuiButton>

      <EuiSpacer size='xxl' />
      {showContactPanel ? (
        <EuiFlexGroup>
          <EuiFlexItem grow={7}>
            <EuiInMemoryTable loading={contactsResult.isLoading || contactsResult.isFetching} items={contactsResult.data ?? []} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' rowProps={getRowProps} />
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <ContactsPane organization={organization} updateSelectedContact={updateSelectedContact} initialContact={selectedContact} />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiInMemoryTable loading={contactsResult.isLoading || contactsResult.isFetching} items={contactsResult.data ?? []} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' rowProps={getRowProps} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      {showContactDeleteModal && (
        <AcConfirmModal
          title={'Delete Contact'}
          message={'Are you sure you want to delete this contact?'}
          confirmButtonText={'Delete'}
          onCancel={() => {
            onCancelDeleteModal()
          }}
          onConfirm={() => {
            onConfirmDeleteModal()
          }}
        />
      )}
      {showContactNotes && contactForNotes && <AcNotesFlyout onFlyoutClose={onContactNotesFlyoutCloseClick} primaryId={contactForNotes.id} title={`Notes for ${contactForNotes.firstName} ${contactForNotes.lastName}`} />}
    </React.Fragment>
  )
}
