import React from 'react'

import { EuiBadge } from '@elastic/eui'

import { SalesContactedMethodType } from 'api'

export interface IAgencyDetailsLastContactedMethod {
  contactMethodType?: SalesContactedMethodType
}

export const AgencyDetailsLastContactedMethod: React.FC<IAgencyDetailsLastContactedMethod> = props => {
  switch (props.contactMethodType) {
    case SalesContactedMethodType.Email:
      return (
        <EuiBadge iconType='email' color='success'>
          Emailed
        </EuiBadge>
      )
    case SalesContactedMethodType.Text:
      return (
        <EuiBadge iconType='discuss' color='success'>
          Texted
        </EuiBadge>
      )
    case SalesContactedMethodType.Voicemail:
      return (
        <EuiBadge iconType='logstashQueue' color='success'>
          Left VM
        </EuiBadge>
      )
    case SalesContactedMethodType.Call:
      return (
        <EuiBadge iconType='mobile' color='success'>
          Spoke
        </EuiBadge>
      )
  }

  return <EuiBadge color='default'>Not Contacted</EuiBadge>
}
