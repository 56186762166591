import moment from 'moment'
import React, { useState } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiTitle } from '@elastic/eui'

import { Organization } from 'api'
import { BundleCategory, BundleType, IntervalUnit, TargetingPriority } from 'api/entities/Bundle'
import { CreateSelfServicePackageRequest, SelfServicePackageStatus, SelfServicePackageType } from 'api/entities/SelfServicePackage'
import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'
import { useCreateBundleMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { SelfServicePackageTemplateForm } from 'features/customers/bundles/SelfServicePackageTemplateForm'
import { useSelfServicePackageIsValid } from 'features/templates/useSelfServicePackageIsValid'

export interface ISelfServicePackageCreateFlyout {
  onCloseFlyout: () => void
  onCreated: () => void
  organization: Organization
}

export const SelfServicePackageCreateFlyout: React.FC<ISelfServicePackageCreateFlyout> = props => {
  const [createBundle, createBundleRequest] = useCreateBundleMutation()
  const [selfServePackageTemplate, setSelfServePackageTemplate] = useState<SelfServicePackageTemplate>({
    id: '',
    salesTitle: '',
    salesDescription: '',
    salesShortDescription: '',
    proposalText: '',
    selfServicePackageType: SelfServicePackageType.Percent,
    selfServicePackageStatus: SelfServicePackageStatus.Available,
    subscriptionIncentiveDuration: 12,
    matchingIncentiveDuration: 12,
    campaignSlots: [],
    matchingPercentage: 100,
    minimumAmount: 1000,
    maximumAmount: 10000,
    expirationDate: moment().add(1, 'month'),
    includeSubscription: false,
    includeMatching: false,
    intervalUnit: IntervalUnit.Month,
    totalIntervals: 12,
    intervalUnitFrequency: 1,
    foreverBundle: true,
    templateName: '',
    bundleCategory: BundleCategory.SelfService,
    targetingPriority: TargetingPriority.Location
  })
  const bundleIsValid = useSelfServicePackageIsValid(selfServePackageTemplate)
  const onCloseClicked = () => {
    props.onCloseFlyout?.()
  }
  const onCreateClicked = () => {
    if (bundleIsValid) {
      createBundle({
        name: selfServePackageTemplate.templateName,
        organizationId: props.organization.organizationId,
        bundleType: BundleType.SelfServicePackage,
        price: 0,
        organizationManagerId: props.organization.organizationManagerId,
        campaignAdvisorId: props.organization.campaignAdvisorId,
        isPrepaid: false,
        isRollover: false,
        isIndefinite: selfServePackageTemplate.foreverBundle,
        intervalUnitFrequency: selfServePackageTemplate.intervalUnitFrequency,
        intervalUnit: selfServePackageTemplate.intervalUnit,
        totalIntervals: selfServePackageTemplate.totalIntervals,
        includeSubscription: selfServePackageTemplate.includeSubscription,
        subscriptionIncentiveDuration: selfServePackageTemplate.subscriptionIncentiveDuration,
        isSubscriptionIncentiveIndefinite: false,
        includeMatching: selfServePackageTemplate.includeMatching,
        matchingIncentiveDuration: selfServePackageTemplate.matchingIncentiveDuration,
        isMatchingIncentiveIndefinite: false,
        campaignSlots: selfServePackageTemplate.selfServicePackageType === SelfServicePackageType.Percent ? [] : selfServePackageTemplate.campaignSlots,
        noteAddRequest: undefined,
        paymentSplitRequests: [],
        paymentMethodId: undefined,
        bundleCategory: selfServePackageTemplate.bundleCategory,
        targetingPriority: selfServePackageTemplate.targetingPriority,
        isWatchListed: false,
        startDate: undefined,
        selfServicePackageType: selfServePackageTemplate.selfServicePackageType,
        matchingPercentage: selfServePackageTemplate.matchingPercentage ? selfServePackageTemplate.matchingPercentage / 100 : undefined,
        salesTitle: selfServePackageTemplate.salesTitle,
        salesShortDescription: selfServePackageTemplate.salesShortDescription,
        salesDescription: selfServePackageTemplate.salesDescription,
        proposalText: selfServePackageTemplate.proposalText,
        minimumAmount: selfServePackageTemplate.minimumAmount,
        maximumAmount: selfServePackageTemplate.maximumAmount,
        paidSlotCampaignTypes: selfServePackageTemplate.campaignSlots.filter(s => !s.isBonus).map(slot => slot.campaignType),
        matchingSlotCampaignTypes: selfServePackageTemplate.campaignSlots.filter(s => s.isBonus).map(slot => slot.campaignType),
        expirationDate: selfServePackageTemplate.expirationDate
      } as CreateSelfServicePackageRequest).then(() => {
        props.onCreated?.()
      })
    }
  }

  const onBundleTemplateChange = (bundleTemplate: SelfServicePackageTemplate) => {
    setSelfServePackageTemplate(bundleTemplate)
  }

  return (
    <EuiFlyout onClose={onCloseClicked} size='m' aria-labelledby='flyoutLargeTitle' className='flyout'>
      <EuiFlyoutHeader>
        <EuiTitle size='s'>
          <h2>Create Bundle Template</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <SelfServicePackageTemplateForm bundleTemplate={selfServePackageTemplate} onChange={onBundleTemplateChange} />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='close' onClick={onCloseClicked} color='danger' isDisabled={createBundleRequest.isLoading}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={onCreateClicked} disabled={!bundleIsValid} isLoading={createBundleRequest.isLoading}>
              Create
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}
