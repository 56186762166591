import React, { useEffect, useRef, useState } from 'react'

import { EuiButton, EuiButtonEmpty, EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHorizontalRule, EuiImage, EuiInMemoryTable, EuiLoadingSpinner, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiPanel, EuiSuperSelect } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { NativeDetailsWithImage } from 'api'
import { useConvertToDisplayWithAdsMutation, useDisapproveInternetAdMutation } from 'api/rtkQueryApi/opsApi/adsApi'
import { DisplayAdPicker } from 'components/AdCreation/DisplayAdPicker'
import { ColorPalette, ContentFocus, ContentSpecifications, FocusArea, TempDisplayAd } from 'components/AdCreation/DisplayMaker'
import { useAcImageUploader } from 'components/Basic/useAcImageUploader'
import { getAdDisapproveOptions } from 'features/customers/ads/AdDisapproveOptions'
import { DisplayAdMakerPanel } from 'features/customers/campaigns/DisplayAdMakerPanel'
import { DisplayAdPreview } from 'features/customers/campaigns/DisplayAdPreview'

export interface INativeToDisplayBuilder {
  campaignId: string
  accountId: string
  detailsList: NativeDetailsWithImage[]
  onSaved?: () => void
  onAdDisapproved?: (adId: string) => void
  isLoading?: boolean
}

interface KeyedNativeDetails extends NativeDetailsWithImage {
  key: string
}

export const NativeToDisplayBuilder: React.FC<INativeToDisplayBuilder> = props => {
  const [tempDetails, setTempDetails] = React.useState<KeyedNativeDetails[]>(props.detailsList.map((d, i) => ({ ...d, key: i.toString() })))
  const [selectedKey, setSelectedKey] = React.useState<string>('')
  const [selectedAds, setSelectedAds] = React.useState<TempDisplayAd[]>([])
  const [collage, setCollage] = React.useState<TempDisplayAd>()
  const [showDisapprove, setShowDisapprove] = useState(false)
  const [disapproveReason, setDisapproveReason] = useState('Image quality issue(s)')
  const [disapproveAdId, setDisapproveAdId] = useState<string>()
  const [convertToDisplayWithAds, convertToDisplayWithAdsRequest] = useConvertToDisplayWithAdsMutation()
  const [disapproveInternetAd, disapproveInternetAdQuery] = useDisapproveInternetAdMutation()
  const [colorPalette, setColorPalette] = React.useState<ColorPalette>({
    prominentColor: '',
    baseColor: '',
    baseColor2: '',
    backgroundColor: '',
    backgroundColor2: '',
    buttonColor: '',
    buttonColor2: ''
  })
  const [contentSpecifications, setContentSpecifications] = React.useState<ContentSpecifications>({
    contentFocus: ContentFocus.CompanyName,
    focusArea: FocusArea.Left
  })
  const { uploadBase64Image } = useAcImageUploader({ accountId: props.accountId })

  const displayWidth = useRef(400)

  useEffect(() => {
    setTempDetails(props.detailsList.map((d, i) => ({ ...d, key: i.toString() })))
    if (props.detailsList.length > 0) {
      setSelectedKey('0')
    }
  }, [props.detailsList])

  const selectedDetails = tempDetails.find(d => d.key === selectedKey)

  const columns: Array<EuiBasicTableColumn<KeyedNativeDetails>> = [
    {
      name: '',
      render: (details: KeyedNativeDetails) => (
        <EuiCheckbox
          id={details.key}
          onChange={() => {
            setSelectedKey(details.key)
          }}
          checked={selectedKey === details.key}
        />
      ),
      width: '20'
    },
    {
      name: '',
      render: (url: string) => <EuiImage src={url} alt={'nativeImage'} />,
      field: 'uploadedUrl'
    },
    {
      name: '',
      width: '140',
      render: (details: KeyedNativeDetails) =>
        (details.adId ?? '').length > 0 ? (
          <EuiButtonEmpty color={'danger'} size={'s'} onClick={() => onDisapproveClick(details.adId ?? '')}>
            Disapprove
          </EuiButtonEmpty>
        ) : (
          ''
        )
    }
  ]

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const onDisapproveClick = (adId: string) => {
    setDisapproveAdId(adId)
    setShowDisapprove(true)
  }

  const onTempAdSelected = (ad: TempDisplayAd) => {
    setSelectedAds(selectedAds => [...selectedAds, ad])
  }

  const closeDisapproveModal = () => {
    setShowDisapprove(false)
  }

  const onFinalizeClick = () => {
    if (!collage) {
      return
    }
    uploadBase64Image('collage', collage.adUri, 'collage.png').then(collageResult => {
      const pngName = (name: string) => name.replace('.png', '') + '.png'
      const uploadPromises = selectedAds.map(ad => uploadBase64Image(ad.key, ad.adUri, pngName(ad.key)))
      Promise.all(uploadPromises).then(results => {
        const remappedSelectedAds = selectedAds
          .map(ad => ({
            ...ad,
            adUri: results.find(r => r.key === ad.key)?.url.split('?')[0] ?? ''
          }))
          .filter(a => a.adUri !== '')

        convertToDisplayWithAds({
          campaignId: props.campaignId,
          displayAds: remappedSelectedAds.map((ad, index) => {
            const destinationUrl = selectedAds.find(s => s.key === ad.key)?.destinationUrl ?? ''
            return {
              accountId: props.accountId,
              companyName: selectedDetails?.companyName ?? '',
              campaignIds: [props.campaignId],
              destinationUrl: destinationUrl,
              uploadedUrl: ad.adUri,
              htmlTag: '',
              width: ad.width,
              height: ad.height,
              name: `Display Ad ${index + 1}: ${ad.width}x${ad.height}`
            }
          }),
          collage: {
            accountId: props.accountId,
            companyName: selectedDetails?.companyName ?? '',
            campaignIds: [props.campaignId],
            destinationUrl: selectedDetails?.destinationUrl ?? '',
            uploadedUrl: collageResult.url,
            htmlTag: '',
            width: collage.width,
            height: collage.height,
            name: `collage for ${props.campaignId}`
          }
        }).finally(() => {
          if (props.onSaved) {
            props.onSaved()
          }
        })
      })
    })
  }

  const onDisapproveChange = (value: string) => {
    setDisapproveReason(value)
  }

  const disapproveAd = () => {
    if (disapproveAdId) {
      disapproveInternetAd({ adId: disapproveAdId, reason: disapproveReason })
        .then(() => {
          setShowDisapprove(false)
        })
        .finally(() => {
          props.onAdDisapproved?.(disapproveAdId)
        })
    }
  }

  const disapproveOptions = getAdDisapproveOptions()

  const getHelpText = () => {
    const issues: string[] = []
    if (!collage) {
      issues.push('Collage')
    }
    if (!selectedAds.find(s => s.width === 300 && s.height === 372)) {
      issues.push('Native Sample (300x372)')
    }
    if (selectedAds.length <= 1) {
      issues.push('Display ads')
    }
    if (issues.length > 0) {
      return `Required: ${issues.join(', ')}`
    }
    return undefined
  }

  const onCollageCapture = (collageAd: TempDisplayAd) => {
    setCollage(collageAd)
  }

  if (!selectedDetails) {
    return <EuiLoadingSpinner />
  }

  return (
    <React.Fragment>
      <EuiFormRow helpText={getHelpText()}>
        <EuiButton fill iconType={'importAction'} disabled={!!getHelpText()} onClick={onFinalizeClick} isLoading={convertToDisplayWithAdsRequest.isLoading}>
          Finalize
        </EuiButton>
      </EuiFormRow>
      {(selectedAds.length > 0 || collage) && (
        <React.Fragment>
          <EuiFormRow label='Captured' fullWidth>
            <EuiFlexGroup wrap>
              {collage && (
                <EuiFlexItem grow={false}>
                  <DisplayAdPreview
                    width={180}
                    ad={collage}
                    titleOverride={'Collage'}
                    onDelete={() => {
                      setCollage(undefined)
                    }}
                  />
                </EuiFlexItem>
              )}
              {selectedAds.map((ad, i) => (
                <EuiFlexItem key={i} grow={false}>
                  <DisplayAdPreview
                    width={180}
                    ad={ad}
                    onDelete={() => {
                      setSelectedAds(selectedAds.filter(a => a.key !== ad.key))
                    }}
                  />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiHorizontalRule />
        </React.Fragment>
      )}
      <EuiFlexGroup wrap responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiPanel style={{ width: 400 }} hasBorder={true} hasShadow={false}>
            <EuiInMemoryTable loading={props.isLoading} items={tempDetails} columns={columns} pagination={pagination} tableLayout='fixed' width={400} />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <DisplayAdMakerPanel
            details={{
              nativeDetails: selectedDetails,
              colorPalette: colorPalette,
              contentSpecifications: contentSpecifications
            }}
            displayWidth={displayWidth.current}
            onBuilderChange={details => {
              setTempDetails(tempDetails.map(d => (d.key === selectedKey ? { ...d, ...details.nativeDetails } : d)))
              setColorPalette(details.colorPalette)
              setContentSpecifications(details.contentSpecifications)
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <DisplayAdPicker contentSpecifications={contentSpecifications} uploadedUrl={selectedDetails.uploadedUrl} nativeDetails={selectedDetails} colorPalette={colorPalette} onTempAdSelected={onTempAdSelected} displayWidth={displayWidth.current} onCollageCapture={onCollageCapture} />
        </EuiFlexItem>
      </EuiFlexGroup>
      {showDisapprove && (
        <EuiOverlayMask>
          <EuiModal onClose={closeDisapproveModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Disapprove Ad</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiForm>
                <EuiFormRow label='Please choose the reason you are rejecting this ad'>
                  <EuiSuperSelect options={disapproveOptions} valueOfSelected={disapproveReason} onChange={value => onDisapproveChange(value)} itemLayoutAlign='top' hasDividers />
                </EuiFormRow>
              </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeDisapproveModal} disabled={disapproveInternetAdQuery.isLoading}>
                Cancel
              </EuiButtonEmpty>
              <EuiButton onClick={disapproveAd} fill color='danger' isLoading={disapproveInternetAdQuery.isLoading}>
                Disapprove
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </React.Fragment>
  )
}
