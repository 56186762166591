import { Formik, FormikHelpers } from 'formik'
import { useGraphClient } from 'graph/GraphClientContext'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCheckbox, EuiDescriptionList, EuiDescriptionListDescription, EuiDescriptionListTitle, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLink, EuiSelect, EuiSpacer, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { Campaign, CampaignActiveStatus, CampaignStatus, Organization, UpdateCampaign, useOpsClient } from 'api'
import { CampaignSlot } from 'api/entities/Bundle'
import { campaignsApi } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useSetOrganizationIncludedCampaignsMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { RootState } from 'app/rootReducer'
import AddCampaignToBundleFlyout from 'components/Bundles/AddCampaignToBundleFlyout'
import { AcCancelCampaignBundleModal } from 'components/Modals/AcCancelCampaignBundleModal'
import { AcDuplicateCampaignModal } from 'components/Modals/AcDuplicateCampaignModal'
import { ReactivateCampaignModal } from 'features/customers/campaigns/ReactivateCampaignModal'
import history from 'services/HistoryService'
import { getCampaignStatusReadable } from 'utils/EnumToFriendly'
import { localDate, localTimestamp, utcDate } from 'utils/dateUtils'
import { canPauseCampaign, canUnpauseCampaign } from 'utils/isCampaign'
import { GetPartnerForOrganization, Partner } from 'utils/partnerUtils'

interface CampaignDetailsTabParams {
  campaign: Campaign
  organization: Organization
}

interface FormValues {
  campaignName: string
  budget: number
  campaignAdvisorId: string | null
  totalAudienceSize: number
  isWatchListed: boolean
}

const CampaignDetailsTab: React.FC<CampaignDetailsTabParams> = ({ campaign, organization }) => {
  const dispatch = useDispatch()
  const opsClient = useOpsClient()
  const [setIncludedCampaignIds, setIncludedCampaignIdsQuery] = useSetOrganizationIncludedCampaignsMutation()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const graphClient = useGraphClient()
  const [canUpdate, setCanUpdate] = useState(false)
  const [canUnpauseWithoutPayment, setCanUnpauseWithoutPayment] = useState(false)
  const [canManuallyActivate, setCanManuallyActivate] = useState(false)
  const [isLoadingCampaignAdvisors, setIsLoadingCampaignAdvisors] = useState(false)
  const [isAddCampaignToBundleOpen, setIsAddCampaignToBundleOpen] = useState(false)
  const [campaignAdvisors, setCampaignAdvisors] = useState<EuiSelectOption[]>([])
  const [initial, setInitial] = useState<FormValues>({
    campaignName: '',
    budget: 0,
    campaignAdvisorId: campaign?.campaignAdvisorId ?? '',
    totalAudienceSize: campaign?.totalAudienceSize ?? 0,
    isWatchListed: false
  })
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false)
  const [showCancelCampaignBundleModal, setShowCancelCampaignBundleModal] = useState<boolean>(false)
  const [showManuallyActivate, setShowManuallyActivate] = useState<boolean>(false)
  const [campaignSlot, setCampaignSlot] = useState<CampaignSlot | null>(null)
  const [showReactivateCampaignModal, setShowReactivateCampaignModal] = useState<boolean>(false)
  const [isIncludedCampaign, setIsIncludedCampaign] = useState<boolean>(false)

  const isOpsAdmin = userProfile?.roles.includes('adcritter-ops-admin') ?? false

  useEffect(() => {
    setIsIncludedCampaign(organization.includedCampaignIds.some(x => x === campaign.campaignId))
  }, [organization.includedCampaignIds])

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-customermanager') || userProfile.roles.includes('adcritter-ops-admin'))
      setCanManuallyActivate(userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    opsClient!.getCanUnpauseWithoutPayment(campaign.campaignId).then(res => {
      setCanUnpauseWithoutPayment(res)
    })
  }, [opsClient, campaign])

  useEffect(() => {
    opsClient!.apiGetCampaignSlot(campaign.campaignId).then(res => {
      setCampaignSlot(res)
    })
  }, [])

  useEffect(() => {
    if (campaign) {
      setInitial({
        campaignName: campaign.campaignName,
        budget: campaign.budget,
        campaignAdvisorId: campaign.campaignAdvisorId ?? organization?.campaignAdvisorId ?? '',
        totalAudienceSize: campaign.totalAudienceSize ?? 0,
        isWatchListed: campaign.isWatchListed
      })
      setShowManuallyActivate(!campaign.activated && canManuallyActivate)
    }
  }, [campaign, organization, canManuallyActivate])

  useEffect(() => {
    if (graphClient) {
      setIsLoadingCampaignAdvisors(true)
      graphClient.getCampaignAdvisors().then(a => {
        let options = a.map(x => ({ value: x.mail, text: x.displayName }))
        options.push({ value: '', text: '[not assigned]' })
        setCampaignAdvisors(options)
        setIsLoadingCampaignAdvisors(false)
      })
    }
  }, [graphClient])

  const updateCampaign = (patch: Campaign) => {
    dispatch(
      campaignsApi.util.updateQueryData('getCampaign', campaign.campaignId, draft => {
        Object.assign(draft, patch)
      })
    )
  }

  const pauseCampaign = () => {
    opsClient!.putCampaignInactive(campaign.campaignId).then(updateCampaign)
  }

  const forcePauseCampaign = () => {
    opsClient!.putCampaignEnded(campaign.campaignId).then(updateCampaign)
  }

  const startCampaign = () => {
    opsClient!.putCampaignActive(campaign.campaignId).then(updateCampaign)
  }

  const reactivateCampaign = () => {
    setShowReactivateCampaignModal(true)
  }

  const onCloseReactivateCampaignModal = () => {
    setShowReactivateCampaignModal(false)
  }

  const clearUnpaidCampaign = () => {
    opsClient!.putCampaignClearUnpaid(campaign.campaignId).then(updateCampaign)
  }

  const manuallyActivateCampaign = () => {
    opsClient!.putCampaignManuallyActivate(campaign.campaignId).then(updateCampaign)
  }

  const clearRequiresCustomAds = () => {
    opsClient!.putCampaignClearRequiresCustomAds(campaign.campaignId).then(updateCampaign)
  }

  const campaignSchema = Yup.object().shape({
    budget: Yup.number().required()
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    let totalAudienceSize: number | null = Number(values.totalAudienceSize)

    if (totalAudienceSize === 0) {
      totalAudienceSize = null
    }
    const update: UpdateCampaign = {
      campaignName: values.campaignName,
      budget: values.budget,
      campaignAdvisorId: values.campaignAdvisorId ?? organization?.campaignAdvisorId!,
      totalAudienceSize: totalAudienceSize,
      isWatchListed: values.isWatchListed
    }

    opsClient?.updateCampaign(campaign.campaignId, update).then(result => {
      updateCampaign(result)
      setSubmitting(false)
    })
  }

  const onAddCampaignToBundleFlyoutSubmit = () => {
    dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaign', id: campaign.campaignId }]))
  }

  const changeToLocationTargeting = () => {
    opsClient!.putCampaignChangeToLocationTargeting(campaign.campaignId).then(() => {
      history.push('/customers/campaigns/' + campaign.campaignId)
    })
  }

  const onIsIncludedChange = useCallback(() => {
    if (canUpdate) {
      const newIsIncludedCampaign = !isIncludedCampaign
      setIsIncludedCampaign(newIsIncludedCampaign)
      const otherCampaignIds = organization.includedCampaignIds.filter(id => id !== campaign.campaignId)
      if (newIsIncludedCampaign) {
        setIncludedCampaignIds({ organizationId: organization.organizationId, includedCampaignIds: [...otherCampaignIds, campaign.campaignId] })
      } else {
        setIncludedCampaignIds({ organizationId: organization.organizationId, includedCampaignIds: otherCampaignIds })
      }
    }
  }, [canUpdate, isIncludedCampaign, campaign.campaignId, organization])

  return (
    <React.Fragment>
      {showReactivateCampaignModal && <ReactivateCampaignModal campaign={campaign} organization={organization} onCloseModal={onCloseReactivateCampaignModal} />}
      <EuiSpacer />
      {campaign && (
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Formik initialValues={initial} enableReinitialize validationSchema={campaignSchema} onSubmit={doSubmit}>
                {props => (
                  <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                    <EuiFormRow label='Name' isInvalid={!!props.errors.campaignName} error={props.errors.campaignName}>
                      <EuiFieldText name='campaignName' value={props.values.campaignName} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.campaignName} />
                    </EuiFormRow>
                    <EuiFormRow label={`${campaign?.budgetFrequency} Budget`} helpText={'Increasing the budget here will not charge customer any pro-rata amount'} isInvalid={!!props.errors.budget} error={props.errors.budget}>
                      <EuiFieldNumber fullWidth prepend={'$'} value={props.values.budget} onBlur={props.handleBlur} onChange={e => props.setFieldValue('budget', Number(e.target.value), true)} isInvalid={props.touched.budget && !!props.errors.budget} />
                    </EuiFormRow>
                    <EuiFormRow label='Assigned Campaign Advisor' isInvalid={!!props.errors.campaignAdvisorId} error={props.errors.campaignAdvisorId}>
                      <EuiSelect name='campaignAdvisorId' isInvalid={!!props.errors.campaignAdvisorId} options={campaignAdvisors} isLoading={isLoadingCampaignAdvisors} value={props.values.campaignAdvisorId ?? organization?.campaignAdvisorId ?? ''} onChange={e => props.setFieldValue('campaignAdvisorId', e.target.value)} />
                    </EuiFormRow>
                    <EuiFormRow label='Total Audience Size' isInvalid={!!props.errors.totalAudienceSize} error={props.errors.totalAudienceSize}>
                      <EuiFieldText id={htmlIdGenerator()()} name='totalAudienceSize' value={props.values.totalAudienceSize} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.totalAudienceSize} />
                    </EuiFormRow>
                    <EuiFormRow isInvalid={!!props.errors.isWatchListed} error={props.errors.isWatchListed}>
                      <EuiCheckbox id={htmlIdGenerator()()} disabled={campaign?.bundleId?.length > 0} label='Watchlisted' checked={props.values.isWatchListed} onChange={e => (props.values.isWatchListed = e.target.checked)} />
                    </EuiFormRow>
                    {canUpdate && (
                      <EuiFormRow>
                        <EuiCheckbox disabled={setIncludedCampaignIdsQuery.isLoading} id={htmlIdGenerator()()} label='Is Included' checked={isIncludedCampaign} onChange={onIsIncludedChange} />
                      </EuiFormRow>
                    )}
                    <EuiSpacer />

                    <EuiFlexGroup gutterSize='s'>
                      <EuiFlexItem grow={false}>
                        <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
                          Save
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton fill color='success' isDisabled={!canUpdate} onClick={() => setShowDuplicateModal(true)}>
                          Duplicate Campaign
                        </EuiButton>
                      </EuiFlexItem>
                      {userProfile?.roles.includes('adcritter-ops-csuite') && campaign.targetingTemplate === 'Standard' && (
                        <EuiFlexItem grow={false}>
                          <EuiButton fill color='warning' onClick={() => changeToLocationTargeting()}>
                            Change to Location Targeting
                          </EuiButton>
                        </EuiFlexItem>
                      )}
                      {campaignSlot === null && (
                        <EuiFlexItem grow={false}>
                          <EuiButton fill color='success' onClick={() => setIsAddCampaignToBundleOpen(true)}>
                            Add Campaign to Plan/Package
                          </EuiButton>
                        </EuiFlexItem>
                      )}
                      {GetPartnerForOrganization(organization) === Partner.Shopify && (
                        <EuiFlexItem grow={false}>
                          <EuiButton fill color='danger' isDisabled={!canUpdate} onClick={() => setShowCancelCampaignBundleModal(true)}>
                            Cancel Campaign Plan/Package
                          </EuiButton>
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>
                  </EuiForm>
                )}
              </Formik>
            </EuiFlexItem>
            <EuiFlexItem style={{ backgroundColor: '#fff9e8', padding: 10, border: '1px solid #ABB4C4' }}>
              <EuiDescriptionList type='column'>
                <EuiDescriptionListTitle>Campaign Status</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>
                  {getCampaignStatusReadable(campaign)}
                  {canPauseCampaign(campaign) && canUpdate && (
                    <React.Fragment>
                      {' '}
                      | <EuiLink onClick={pauseCampaign}>pause this campaign</EuiLink>
                    </React.Fragment>
                  )}
                  {campaign.activeStatus === CampaignActiveStatus.Active && canUpdate && isOpsAdmin && (
                    <React.Fragment>
                      {' '}
                      | <EuiLink onClick={forcePauseCampaign}>immediately pause this campaign</EuiLink>
                    </React.Fragment>
                  )}
                  {canUnpauseCampaign(campaign) && canUnpauseWithoutPayment && canUpdate && (
                    <React.Fragment>
                      {' '}
                      | <EuiLink onClick={startCampaign}>start this campaign</EuiLink>
                    </React.Fragment>
                  )}
                  {canUnpauseCampaign(campaign) && !canUnpauseWithoutPayment && canUpdate && (isIncludedCampaign || campaign.budgetFrequency !== 'Total') && (
                    <React.Fragment>
                      {' '}
                      | <EuiLink onClick={reactivateCampaign}>Reactivate this campaign</EuiLink>
                    </React.Fragment>
                  )}
                  {campaign.status === CampaignStatus.PaymentFailed && userProfile!.isAdmin && (
                    <React.Fragment>
                      {' '}
                      |{' '}
                      <EuiLink onClick={clearUnpaidCampaign} color='success'>
                        clear unpaid status
                      </EuiLink>
                    </React.Fragment>
                  )}
                </EuiDescriptionListDescription>
                {campaign.businessTypeName && (
                  <React.Fragment>
                    <EuiDescriptionListTitle>Business Type</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>
                      <EuiLink onClick={() => history.push(`/data/businessTypes/${campaign.businessTypeId}`)}>{campaign.businessTypeName}</EuiLink>
                    </EuiDescriptionListDescription>
                  </React.Fragment>
                )}
                <EuiDescriptionListTitle>Budget Frequency</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.budgetFrequency}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>Campaign Type</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.campaignType}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>Targeting Template</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.targetingTemplate}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>Activated Date</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>
                  {campaign.activated ? localTimestamp(campaign.activated) : 'Not activated yet'}
                  {showManuallyActivate && (
                    <React.Fragment>
                      {' '}
                      | <EuiLink onClick={manuallyActivateCampaign}>manually activate this campaign</EuiLink>
                    </React.Fragment>
                  )}
                </EuiDescriptionListDescription>
                <EuiDescriptionListTitle>Start Date</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.startDate ? utcDate(campaign.startDate) : 'At activation'}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>End Date</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.endDate ? utcDate(campaign.endDate) : 'Indefinite'}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>First View Date</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.firstViewDate ? localDate(campaign.firstViewDate) : 'No views yet'}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>Is part of Plan/Package</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaignSlot === null ? 'No' : 'Yes'}</EuiDescriptionListDescription>
                <EuiDescriptionListTitle>Is matching</EuiDescriptionListTitle>
                <EuiDescriptionListDescription>{campaign.isMatching ? 'Yes' : 'No'}</EuiDescriptionListDescription>

                {campaign.campaignContactInfo && (
                  <React.Fragment>
                    <EuiDescriptionListTitle>Contact Details</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>
                      <EuiDescriptionList type='inline'>
                        <EuiDescriptionListTitle>Name</EuiDescriptionListTitle>
                        <EuiDescriptionListDescription>{campaign.campaignContactInfo.name}</EuiDescriptionListDescription>
                        <EuiDescriptionListTitle>Phone</EuiDescriptionListTitle>
                        <EuiDescriptionListDescription>{campaign.campaignContactInfo.phone}</EuiDescriptionListDescription>
                        <EuiDescriptionListTitle>Email</EuiDescriptionListTitle>
                        <EuiDescriptionListDescription>
                          {campaign.campaignContactInfo.email}{' '}
                          {canUpdate && campaign.status === CampaignStatus.WaitingForCustomAds && (
                            <React.Fragment>
                              {' '}
                              | <EuiLink onClick={clearRequiresCustomAds}>clear custom ads</EuiLink>
                            </React.Fragment>
                          )}
                        </EuiDescriptionListDescription>
                      </EuiDescriptionList>
                    </EuiDescriptionListDescription>
                  </React.Fragment>
                )}
              </EuiDescriptionList>
            </EuiFlexItem>
          </EuiFlexGroup>
          <AcDuplicateCampaignModal accountId={campaign!.accountId} campaignId={campaign.campaignId} srcCampaignName={campaign!.campaignName} organizationId={campaign.organizationId} isVisible={showDuplicateModal} cancelModal={() => setShowDuplicateModal(false)} finishDuplicating={res => history.push(`/customers/campaigns/${res.campaignId}`)} />
          <AcCancelCampaignBundleModal campaignId={campaign.campaignId} isVisible={showCancelCampaignBundleModal} cancelModal={() => setShowCancelCampaignBundleModal(false)} />
          {isAddCampaignToBundleOpen && <AddCampaignToBundleFlyout campaign={campaign} onSubmit={() => onAddCampaignToBundleFlyoutSubmit()} onFlyoutClose={() => setIsAddCampaignToBundleOpen(false)} />}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default CampaignDetailsTab
