import React, { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiCheckbox, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiHorizontalRule, EuiTitle } from '@elastic/eui'

import { CommissionItemType, CommissionRule, CommissionTriggerType, SalesAgent } from 'api/entities/SalesAgent'
import { useCreateSalesAgentMutation } from 'api/rtkQueryApi/opsApi/salesApi'

export interface IAgentsOverviewGridItem {
  onCreated: () => void
  onClose: () => void
}

export const SalesAgentCreateFlyout: React.FC<IAgentsOverviewGridItem> = props => {
  const [createSalesAgent, createSalesAgentQuery] = useCreateSalesAgentMutation()
  const [salesAgent, setSalesAgent] = React.useState<SalesAgent>({
    commissionStructure: {
      commissionRules: []
    },
    email: '',
    id: uuidv4(),
    isActive: true,
    name: '',
    phone: '',
    title: ''
  })

  const [hasMonthlyCap, setHasMonthlyCap] = React.useState<boolean>(false)
  const [hasMonthlyMin, setHasMonthlyMin] = React.useState<boolean>(false)

  const onHasMonthlyCapChange = () => {
    setHasMonthlyCap(!hasMonthlyCap)
    if (!salesAgent.commissionStructure.maxMonthlyCommission) {
      setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, maxMonthlyCommission: 30000 } })
    }
  }

  const onHasMonthlyMinChange = () => {
    setHasMonthlyMin(!hasMonthlyMin)
    if (!salesAgent.commissionStructure.minMonthlyCommission) {
      setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, minMonthlyCommission: 5000 } })
    }
  }

  const isValid = useCallback(() => {
    if (hasMonthlyCap) {
      if (salesAgent.commissionStructure.maxMonthlyCommission && salesAgent.commissionStructure.maxMonthlyCommission < 0) {
        return false
      }
      if (salesAgent.commissionStructure.minMonthlyCommission && salesAgent.commissionStructure.maxMonthlyCommission && salesAgent.commissionStructure.maxMonthlyCommission < salesAgent.commissionStructure.minMonthlyCommission) {
        return false
      }
    }
    if (hasMonthlyMin) {
      if (salesAgent.commissionStructure.minMonthlyCommission && salesAgent.commissionStructure.minMonthlyCommission < 0) {
        return false
      }
      if (salesAgent.commissionStructure.maxMonthlyCommission && salesAgent.commissionStructure.minMonthlyCommission && salesAgent.commissionStructure.minMonthlyCommission > salesAgent.commissionStructure.maxMonthlyCommission) {
        return false
      }
    }

    return salesAgent.name.length > 0 && salesAgent.title.length > 0 && salesAgent.email.length > 0 && salesAgent.phone.length > 0
  }, [salesAgent, hasMonthlyCap, hasMonthlyMin])

  const onAddRuleClick = () => {
    const newRule: CommissionRule = {
      id: uuidv4(),
      commissionItemType: CommissionItemType.Bundle,
      commissionPercentage: 0.1,
      commissionTriggerType: CommissionTriggerType.BundlePurchase,
      isActive: true,
      maxCommission: undefined,
      minCommission: undefined,
      name: CommissionTriggerType.BundlePurchase
    }
    setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, commissionRules: [...salesAgent.commissionStructure.commissionRules, newRule] } })
  }

  const onRuleChange = (rule: CommissionRule) => {
    const index = salesAgent.commissionStructure.commissionRules.findIndex(r => r.id === rule.id)
    const newRules = salesAgent.commissionStructure.commissionRules
    newRules[index] = rule
    setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, commissionRules: newRules } })
  }

  const onRemoveClick = (rule: CommissionRule) => {
    const index = salesAgent.commissionStructure.commissionRules.findIndex(r => r.id === rule.id)
    const newRules = salesAgent.commissionStructure.commissionRules
    newRules.splice(index, 1)
    setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, commissionRules: newRules } })
  }

  const onCreateSalesAgent = () => {
    if (!isValid()) {
      return
    }
    const agent = { ...salesAgent }
    if (!hasMonthlyCap) {
      agent.commissionStructure.maxMonthlyCommission = undefined
    }
    if (!hasMonthlyMin) {
      agent.commissionStructure.minMonthlyCommission = undefined
    }

    createSalesAgent(agent).then(() => {
      props.onCreated()
    })
  }

  return (
    <React.Fragment>
      <EuiFlyout ownFocus onClose={props.onClose} size={'s'}>
        <EuiFlyoutHeader>
          <EuiTitle size='s'>
            <h2>New Sales Agent</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFormRow label={'Name'}>
            <EuiFieldText value={salesAgent.name} onChange={e => setSalesAgent({ ...salesAgent, name: e.target.value })} />
          </EuiFormRow>
          <EuiFormRow label={'Title'}>
            <EuiFieldText value={salesAgent.title} onChange={e => setSalesAgent({ ...salesAgent, title: e.target.value })} />
          </EuiFormRow>
          <EuiFormRow label={'Email'}>
            <EuiFieldText value={salesAgent.email} onChange={e => setSalesAgent({ ...salesAgent, email: e.target.value })} />
          </EuiFormRow>
          <EuiFormRow label={'Phone'}>
            <EuiFieldText value={salesAgent.phone} onChange={e => setSalesAgent({ ...salesAgent, phone: e.target.value })} />
          </EuiFormRow>
          <EuiHorizontalRule />
          <EuiFormRow>
            <EuiCheckbox label={`Monthly Commission Cap ${salesAgent.commissionStructure.maxMonthlyCommission && hasMonthlyCap ? `($${salesAgent.commissionStructure.maxMonthlyCommission.toLocaleString()})` : ''}`} checked={hasMonthlyCap} id={'hasMonthlyCap'} onChange={onHasMonthlyCapChange} />
          </EuiFormRow>
          {hasMonthlyCap && (
            <EuiFormRow>
              <EuiFieldNumber prepend={'$'} value={salesAgent.commissionStructure.maxMonthlyCommission} onChange={e => setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, maxMonthlyCommission: parseFloat(e.target.value) } })} />
            </EuiFormRow>
          )}
          <EuiFormRow>
            <EuiCheckbox label={`Monthly Commission Min ${salesAgent.commissionStructure.minMonthlyCommission && hasMonthlyMin ? `($${salesAgent.commissionStructure.minMonthlyCommission.toLocaleString()})` : ''}`} checked={hasMonthlyMin} id={'hasMonthlyMin'} onChange={onHasMonthlyMinChange} />
          </EuiFormRow>
          {hasMonthlyMin && (
            <EuiFormRow>
              <EuiFieldNumber prepend={'$'} value={salesAgent.commissionStructure.minMonthlyCommission} onChange={e => setSalesAgent({ ...salesAgent, commissionStructure: { ...salesAgent.commissionStructure, minMonthlyCommission: parseFloat(e.target.value) } })} />
            </EuiFormRow>
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton fill isDisabled={!isValid()} isLoading={createSalesAgentQuery.isLoading} onClick={onCreateSalesAgent}>
                Create
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem />
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </React.Fragment>
  )
}
