import { BundleType } from 'api/entities/Bundle'
import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'

import { defaultTransformFn, opsApi } from './opsApi'

const selfServicePackageTemplatesTag = opsApi.enhanceEndpoints({ addTagTypes: ['BundleTemplates'] })

const selfServicePackageTemplatesApi = selfServicePackageTemplatesTag.injectEndpoints({
  endpoints: build => ({
    getSelfServicePackageTemplates: build.query<SelfServicePackageTemplate[], void>({
      query: () => `bundleTemplates`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'BundleTemplates' }]
    }),
    getSelfServicePackageTemplate: build.query<SelfServicePackageTemplate, { templateId: string }>({
      query: request => `bundleTemplates/${request.templateId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'BundleTemplates', id: request.templateId }]
    }),
    createSelfServicePackageTemplate: build.mutation<SelfServicePackageTemplate, { template: SelfServicePackageTemplate }>({
      query: request => ({
        url: `bundleTemplates/selfServicePackage`,
        method: 'POST',
        body: {
          templateName: request.template.templateName,
          bundleType: BundleType.SelfServicePackage,
          salesTitle: request.template.salesTitle,
          salesShortDescription: request.template.salesShortDescription,
          salesDescription: request.template.salesDescription,
          selfServicePackageStatus: request.template.selfServicePackageStatus,
          selfServicePackageType: request.template.selfServicePackageType,
          matchingPercentage: request.template.matchingPercentage,
          minimumAmount: request.template.minimumAmount,
          maximumAmount: request.template.maximumAmount,
          campaignSlots: request.template.campaignSlots,
          expirationDate: request.template.expirationDate,
          subscriptionIncentiveDuration: request.template.subscriptionIncentiveDuration,
          matchingIncentiveDuration: request.template.matchingIncentiveDuration,
          includeSubscription: request.template.includeSubscription,
          includeMatching: request.template.includeMatching,
          intervalUnit: request.template.intervalUnit,
          totalIntervals: request.template.totalIntervals,
          intervalUnitFrequency: request.template.intervalUnitFrequency,
          foreverBundle: request.template.foreverBundle,
          bundleCategory: request.template.bundleCategory,
          targetingPriority: request.template.targetingPriority
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: result => [{ type: 'BundleTemplates', id: result?.id }, { type: 'BundleTemplates' }]
    }),
    archiveSelfServicePackageTemplate: build.mutation<SelfServicePackageTemplate, { templateId: string }>({
      query: request => ({
        url: `bundleTemplates/${request.templateId}/archive`,
        method: 'POST',
        body: {}
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: result => [{ type: 'BundleTemplates', id: result?.id }, { type: 'BundleTemplates' }]
    }),
    updateSelfServicePackageTemplate: build.mutation<SelfServicePackageTemplate, { templateId: string; template: SelfServicePackageTemplate }>({
      query: request => ({
        url: `bundleTemplates/selfServicePackage/${request.templateId}`,
        method: 'PUT',
        body: {
          templateName: request.template.templateName,
          bundleType: BundleType.SelfServicePackage,
          salesTitle: request.template.salesTitle,
          salesShortDescription: request.template.salesShortDescription,
          salesDescription: request.template.salesDescription,
          selfServicePackageStatus: request.template.selfServicePackageStatus,
          selfServicePackageType: request.template.selfServicePackageType,
          matchingPercentage: request.template.matchingPercentage,
          minimumAmount: request.template.minimumAmount,
          maximumAmount: request.template.maximumAmount,
          campaignSlots: request.template.campaignSlots,
          expirationDate: request.template.expirationDate,
          subscriptionIncentiveDuration: request.template.subscriptionIncentiveDuration,
          matchingIncentiveDuration: request.template.matchingIncentiveDuration,
          includeSubscription: request.template.includeSubscription,
          includeMatching: request.template.includeMatching,
          intervalUnit: request.template.intervalUnit,
          totalIntervals: request.template.totalIntervals,
          intervalUnitFrequency: request.template.intervalUnitFrequency,
          foreverBundle: request.template.foreverBundle,
          bundleCategory: request.template.bundleCategory,
          targetingPriority: request.template.targetingPriority
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: result => [{ type: 'BundleTemplates' }, { type: 'BundleTemplates', id: result?.id }]
    })
  }),
  overrideExisting: false
})

export const { useGetSelfServicePackageTemplateQuery, useGetSelfServicePackageTemplatesQuery, useArchiveSelfServicePackageTemplateMutation, useCreateSelfServicePackageTemplateMutation, useUpdateSelfServicePackageTemplateMutation } = selfServicePackageTemplatesApi
