import React, { useEffect } from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { DefaultSpacerForTabbedContent } from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import { AgentsOverviewPage } from 'features/salesManagerDashboard/AgentsOverviewPage'

export const useSalesManagerTabs = () => {
  const [tabs, setTabs] = React.useState<EuiTabbedContentTab[]>([])
  useEffect(() => {
    setTabs([
      {
        id: 'agents-overview',
        name: `Overview`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <AgentsOverviewPage />
          </React.Fragment>
        )
      }
    ])
  }, [])

  return tabs
}
