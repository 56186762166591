import React, { FC } from 'react'

import { EuiSpacer, EuiText } from '@elastic/eui'

import { Bundle } from 'api/entities/Bundle'

export interface BundlePriceDisplayProps {
  bundle: Bundle
  totalPrice?: number
  addedPrice?: number
  originalPrice?: number
}

const BundlePriceDisplay: FC<BundlePriceDisplayProps> = ({ bundle, totalPrice, addedPrice, originalPrice }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const price = totalPrice ?? bundle.price

  return (
    <React.Fragment>
      {formatter.format(price)} every {bundle.billingSchedule.intervalUnitFrequency} {bundle.billingSchedule.intervalUnit + (bundle.billingSchedule.intervalUnitFrequency > 1 ? 's' : '')}{' '}
      {bundle.billingSchedule.isIndefinite ? (
        <React.Fragment>forever</React.Fragment>
      ) : (
        <React.Fragment>
          {bundle.billingSchedule.totalIntervals} {bundle.billingSchedule.totalIntervals > 1 ? 'times' : 'time'}
        </React.Fragment>
      )}
      <EuiSpacer size='xs' />
      {!!addedPrice && !!originalPrice && originalPrice !== price && (
        <EuiText color={'warning'} size='s'>
          <p>
            ({formatter.format(originalPrice)} original + {formatter.format(addedPrice)} new)
          </p>
        </EuiText>
      )}
    </React.Fragment>
  )
}

export default BundlePriceDisplay
