import React, { useEffect } from 'react'

import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiRadioGroup, EuiRadioGroupOption, EuiSpacer } from '@elastic/eui'

import { useGetPaymentMethodBankAccountsQuery, useGetPaymentMethodCardsQuery, useLazyGetPaymentMethodIdByPaymentProviderSetupIntentQuery } from 'api/rtkQueryApi/opsApi/paymentMethodsApi'
import { AddPaymentCard } from 'components/Basic/AddPaymentCard'

export interface IOrganizationPaymentMethodsRadio {
  organizationId: string
  onSelectedPaymentMethodIdChange?: (paymentMethodId: string | undefined) => void
  disabledModes?: PaymentMode[]
  defaultPaymentMethodId?: string
}

export enum PaymentMode {
  Default,
  Select,
  New
}

export const OrganizationPaymentMethodsRadioGroup = (props: IOrganizationPaymentMethodsRadio) => {
  const { data: paymentMethodsCard } = useGetPaymentMethodCardsQuery(props.organizationId)
  const { data: paymentMethodsBankAccount } = useGetPaymentMethodBankAccountsQuery(props.organizationId)
  const [getPaymentMethodId, getPaymentMethodIdRequest] = useLazyGetPaymentMethodIdByPaymentProviderSetupIntentQuery()
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState<string>()
  const [paymentMode, setPaymentMode] = React.useState<PaymentMode>(PaymentMode.Select)

  const [paymentMethodOptions, setPaymentMethodOptions] = React.useState<EuiRadioGroupOption[]>([])

  useEffect(() => {
    if (props.defaultPaymentMethodId) {
      setSelectedPaymentMethodId(props.defaultPaymentMethodId)
      setPaymentMode(PaymentMode.Select)
      props.onSelectedPaymentMethodIdChange?.(props.defaultPaymentMethodId)
    }
  }, [props.defaultPaymentMethodId]);

  useEffect(() => {
    if (getPaymentMethodIdRequest.data) {
      onPaymentMethodChange(getPaymentMethodIdRequest.data)
    }
  }, [getPaymentMethodIdRequest.data])

  useEffect(() => {
    if (props.disabledModes?.includes(paymentMode)) {
      if (!props.disabledModes?.includes(PaymentMode.Default)) {
        setPaymentMode(PaymentMode.Default)
      } else if (!props.disabledModes?.includes(PaymentMode.New)) {
        setPaymentMode(PaymentMode.New)
      } else if (!props.disabledModes?.includes(PaymentMode.Select)) {
        setPaymentMode(PaymentMode.Select)
      }
    }
  }, [props.disabledModes, paymentMode])

  useEffect(() => {
    if (paymentMethodsCard && selectedPaymentMethodId === undefined && paymentMethodsCard.length > 0) {
      setSelectedPaymentMethodId(paymentMethodsCard?.[0].id)
      props.onSelectedPaymentMethodIdChange?.(paymentMethodsCard?.[0].id)
    } else if (paymentMethodsBankAccount && selectedPaymentMethodId === undefined && paymentMethodsBankAccount.length > 0) {
      setSelectedPaymentMethodId(paymentMethodsBankAccount?.[0].id)
      props.onSelectedPaymentMethodIdChange?.(paymentMethodsBankAccount?.[0].id)
    }

    if (paymentMethodsCard && paymentMethodsBankAccount) {
      setPaymentMethodOptions([
        ...paymentMethodsCard.map(pm => ({
          id: pm.id,
          label: `${pm.brand} xxxx${pm.last4}, expiring ${pm.expiryMonth.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}/${pm.expiryYear}`
        })),
        ...paymentMethodsBankAccount.map(pm => ({
          id: pm.id,
          label: `Bank Account: ${pm.name}`
        }))
      ])
    }
  }, [paymentMethodsCard, paymentMethodsBankAccount])

  const onPaymentMethodChange = (id: string) => {
    setSelectedPaymentMethodId(id)
    props.onSelectedPaymentMethodIdChange?.(id)
  }

  const onUseDefaultCardClick = () => {
    setPaymentMode(PaymentMode.Default)
    props.onSelectedPaymentMethodIdChange?.(undefined)
  }

  const onSelectPaymentClick = () => {
    setPaymentMode(PaymentMode.Select)
    if (paymentMethodsCard && paymentMethodsCard.length > 0) {
      setSelectedPaymentMethodId(paymentMethodsCard?.[0].id)
      props.onSelectedPaymentMethodIdChange?.(paymentMethodsCard?.[0].id)
    }
  }

  const onAddPaymentClick = () => {
    setPaymentMode(PaymentMode.New)
    props.onSelectedPaymentMethodIdChange?.('-1')
  }

  const onCardAdded = (setupIntentId: string) => {
    getPaymentMethodId({ organizationId: props.organizationId, setupIntentId: setupIntentId })
    onSelectPaymentClick()
  }

  return (
    <React.Fragment>
      <EuiKeyPadMenu>
        <EuiKeyPadMenuItem label={'Select Payment'} isSelected={paymentMode === PaymentMode.Select} onClick={onSelectPaymentClick} disabled={props.disabledModes?.includes(PaymentMode.Select)}>
          <EuiIcon type={'payment'} />
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem label={'Default'} isSelected={paymentMode === PaymentMode.Default} disabled={props.disabledModes?.includes(PaymentMode.Default) || !paymentMethodsCard || paymentMethodsCard.length <= 0} onClick={onUseDefaultCardClick}>
          <EuiIcon type={'starEmpty'} />
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem label={'Add Payment'} isSelected={paymentMode === PaymentMode.New} onClick={onAddPaymentClick} disabled={props.disabledModes?.includes(PaymentMode.New)}>
          <EuiIcon type={'plusInCircleFilled'} />
        </EuiKeyPadMenuItem>
      </EuiKeyPadMenu>
      <EuiSpacer />
      {paymentMode == PaymentMode.Select && <EuiRadioGroup style={{ maxHeight: 300, overflow: 'auto' }} options={paymentMethodOptions} onChange={onPaymentMethodChange} idSelected={selectedPaymentMethodId} />}
      {paymentMode == PaymentMode.New && <AddPaymentCard organizationId={props.organizationId} onCardAdded={onCardAdded} />}
    </React.Fragment>
  )
}
