import { combineReducers } from '@reduxjs/toolkit'

import { graphClientApi } from 'api/rtkQueryApi/graphClient/graphClientApi'
import { opsApi } from 'api/rtkQueryApi/opsApi/opsApi'
import anchorPhrasesReducer from 'features/data/anchorPhrases/anchorPhrasesSlice'
import businessTypeCategoriesReducer from 'features/data/businessTypeCategories/businessTypeCategoriesSlice'
import granularPhrasesReducer from 'features/data/granularPhrases/granularPhrasesSlice'

import appReducer from './appSlice'

const rootReducer = combineReducers({
  app: appReducer,
  businessTypeCategories: businessTypeCategoriesReducer,
  anchorPhrases: anchorPhrasesReducer,
  granularPhrases: granularPhrasesReducer,
  [opsApi.reducerPath]: opsApi.reducer,
  [graphClientApi.reducerPath]: graphClientApi.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
