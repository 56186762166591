import React, { useEffect, useState } from 'react'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { Dma, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface DmaTargeterProps {
  isInvalid: boolean
  addDma(dma: string): void
}

export const DmaFinder = ({ addDma, isInvalid }: DmaTargeterProps) => {
  const opsClient = useOpsClient()
  const [dmaSearchTerm, setDmaSearchTerm] = useState<string>('')
  const debouncedDmaSearchTerm = useDebounce(dmaSearchTerm, 100)
  const [searchDmaPopoverOpen, setSearchDmaPopoverOpen] = useState<boolean>(false)
  const [dmas, setDmas] = useState<Array<Dma>>([])
  const [filteredDmas, setFilteredDmas] = useState<Array<Dma>>([])
  const [selectableDmas, setSelectableDmas] = useState<Array<EuiSelectableOption>>()
  const [isLoadingDmas, setIsLoadingDmas] = useState<boolean>(false)

  useEffect(() => {
    if (opsClient) {
      opsClient.getDmas().then(r => {
        setDmas(r)
        setSelectableDmas(
          r
            .filter(d => d.yahooDspId !== null)
            .map(
              d =>
                ({
                  label: d.name,
                  key: d.dmaId
                } as EuiSelectableOption)
            )
        )
      })
    }
  }, [opsClient])

  const onSearchState = () => {
    setSearchDmaPopoverOpen(false)
  }

  const onSearchStateChange = (hint: string) => {
    setDmaSearchTerm(hint)
  }

  useEffect(() => {
    if (debouncedDmaSearchTerm) {
      setIsLoadingDmas(true)
      setFilteredDmas([...dmas.filter(d => d.name.toLowerCase().includes(debouncedDmaSearchTerm.toLowerCase()) && d.yahooDspId !== null)])
      setIsLoadingDmas(false)
    } else {
      setFilteredDmas([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDmaSearchTerm])

  useEffect(() => {
    if (filteredDmas) {
      setSelectableDmas(
        filteredDmas.map(
          s =>
            ({
              label: s.name,
              key: s.dmaId,
              checked: undefined,
              onClick: () => {
                addDma(s.name)
                setSearchDmaPopoverOpen(false)
                setDmaSearchTerm('')
              }
            } as EuiSelectableOption)
        )
      )
    }
  }, [filteredDmas, addDma])

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={dmaSearchTerm}
          isLoading={isLoadingDmas}
          onChange={v => onSearchStateChange(v.target.value)}
          onFocus={() => setSearchDmaPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchState()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchDmaPopoverOpen}
      closePopover={() => setSearchDmaPopoverOpen(false)}>
      {filteredDmas && filteredDmas.length > 0 ? (
        <EuiSelectable singleSelection={false} options={selectableDmas}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
