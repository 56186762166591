import { useSelector } from 'react-redux'

import { EuiSelect } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { TargetingPriority } from 'api/entities/Bundle'
import { RootState } from 'app/rootReducer'

export interface ITargetingPrioritySelect {
  onChange: (key: TargetingPriority) => void
  selectedKey: TargetingPriority
  fullWidth?: boolean
  checkIsCSuite?: boolean
}

export const TargetingPrioritySelect: React.FC<ITargetingPrioritySelect> = props => {
  const { isCSuite } = useSelector((state: RootState) => state.app)

  const options: EuiSelectOption[] = [
    {
      value: TargetingPriority.Location,
      text: TargetingPriority.Location
    },
    {
      value: TargetingPriority.AudienceSegment,
      text: TargetingPriority.AudienceSegment
    }
  ]

  return (
    <EuiSelect
      fullWidth={props.fullWidth}
      options={options}
      value={props.selectedKey}
      onChange={e => {
        props.onChange(e.target.value as TargetingPriority)
      }}
      disabled={props.checkIsCSuite && !isCSuite}
    />
  )
}
