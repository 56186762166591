import { MonthlySpend } from 'api/entities/MonthlySpend'
import { SearchTermResults } from 'api/entities/SearchTermResults'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const brandMetricsApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['BrandMetrics', 'OrganizationEvents'] })

const adsApi = brandMetricsApiTags.injectEndpoints({
  endpoints: build => ({
    getSearchTermResults: build.query<SearchTermResults, { searchTerm: string; locations: string[]; locationType: string; startDate: Date; endDate: Date }>({
      query: request => {
        return {
          url: `brandMetrics`,
          method: 'POST',
          body: {
            searchTerm: request.searchTerm,
            locations: request.locations,
            locationType: request.locationType,
            startDate: request.startDate,
            endDate: request.endDate
          }
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'BrandMetrics', id: `${request.searchTerm} | ${request.locations.join(',')} | ${request.locationType} | ${request.startDate} | ${request.endDate}` }]
    }),
    getAdSpendPerMonth: build.query<MonthlySpend[], { organizationId: string }>({
      query: ({ organizationId }) => `brandMetrics/adSpendPerMonth?organizationId=${organizationId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'OrganizationEvents', id: request.organizationId }]
    })
  }),
  overrideExisting: false
})

export const { useGetSearchTermResultsQuery, useLazyGetAdSpendPerMonthQuery } = adsApi
