import { Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiCard, EuiEmptyPrompt, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiIcon, EuiPanel, EuiSelectable, EuiSelectableOption, EuiSpacer, EuiStat, EuiSwitch, EuiText, EuiTitle } from '@elastic/eui'

import { BidderXCampaignCreativesAuditStatus, BidderXStatus, BidStrategy, Campaign, CampaignBidderX, CampaignType, LocationCity, LocationDma, LocationPostalCode, LocationRegion, OptimizationType, SegmentTarget, UpdateCampaignBidderX, useOpsClient } from 'api'
import { campaignsApi, useSetCampaignBidStrategyMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useLazyGetPostalCodesCsvQuery } from 'api/rtkQueryApi/opsApi/targetingApi'
import { RootState } from 'app/rootReducer'
import { CampaignOptimizationControl } from 'components/BidderOptimization/CampaignOptimizationControl'
import { CityFinderXandr } from 'components/Finders/CityFinderXandr'
import { DMAFinderXandr } from 'components/Finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from 'components/Finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from 'components/Finders/RegionFinderXandr'
import { SegmentFinder } from 'components/Finders/SegmentFinder'
import { CityTargeterNew } from 'components/Targeters/CityTargeterNew'
import { DmaTargeterNew } from 'components/Targeters/DmaTargeterNew'
import { PostalCodeTargeter } from 'components/Targeters/PostalCodeTargeter'
import { RegionTargeter } from 'components/Targeters/RegionTargeter'
import { SegmentTargeter } from 'components/Targeters/SegmentTargeter'
import localStoreManagerService from 'services/LocalStoreManagerService'
import { insertSpaces } from 'utils/EnumToFriendly'

interface CampaignBidderXTabParams {
  campaign: Campaign
  campaignBidder?: CampaignBidderX
}

interface FormValues {
  targetedSegments: SegmentTarget[]
  additionalSegments: SegmentTarget[]
  hiddenSegments: SegmentTarget[]
  excludedSegmentIds: number[]
  targetedRegions: LocationRegion[]
  additionalRegions: LocationRegion[]
  hiddenRegions: LocationRegion[]
  excludedRegionIds: number[]
  targetedDmas: LocationDma[]
  additionalDmas: LocationDma[]
  hiddenDmas: LocationDma[]
  excludedDmaIds: number[]
  targetedCities: LocationCity[]
  additionalCities: LocationCity[]
  hiddenCities: LocationCity[]
  excludedCityIds: number[]
  targetedPostalCodes: LocationPostalCode[]
  additionalPostalCodes: LocationPostalCode[]
  hiddenPostalCodes: LocationPostalCode[]
  excludedPostalCodeIds: number[]
}

interface SegmentOptionData {
  description?: string
  hierarchy?: string[]
}

const CampaignBidderXTab: React.FC<CampaignBidderXTabParams> = ({ campaign, campaignBidder }) => {
  const dispatch = useDispatch()
  const [bidSettings, setBidSettings] = useState<BidStrategy>(campaign.bidStrategy ?? { optimizationType: OptimizationType.Unset, optimizationGoal: 0 })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [isXandr, setIsXandr] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isSyncingLocations, setIsSyncingLocations] = useState(false)
  const [isSyncingSegments, setIsSyncingSegments] = useState(false)
  const [initial, setInitial] = useState<FormValues>({
    targetedSegments: [],
    additionalSegments: [],
    hiddenSegments: [],
    excludedSegmentIds: [],
    targetedRegions: [],
    additionalRegions: [],
    hiddenRegions: [],
    excludedRegionIds: [],
    targetedDmas: [],
    additionalDmas: [],
    hiddenDmas: [],
    excludedDmaIds: [],
    targetedCities: [],
    additionalCities: [],
    hiddenCities: [],
    excludedCityIds: [],
    targetedPostalCodes: [],
    additionalPostalCodes: [],
    hiddenPostalCodes: [],
    excludedPostalCodeIds: []
  })
  const [regions, setRegions] = useState<EuiSelectableOption[]>()
  const [dmas, setDmas] = useState<EuiSelectableOption[]>()
  const [cities, setCities] = useState<EuiSelectableOption[]>()
  const [postalCodes, setPostalCodes] = useState<EuiSelectableOption[]>()
  const [segments, setSegments] = useState<EuiSelectableOption<SegmentOptionData>[]>()
  const opsClient = useOpsClient()
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
    []
  )
  const [getZipsFromCsv, getZipsFromCsvQuery] = useLazyGetPostalCodesCsvQuery()

  const [setCampaignBidSettings] = useSetCampaignBidStrategyMutation()

  useEffect(() => {
    setBidSettings(campaign.bidStrategy ?? { optimizationType: OptimizationType.Unset, optimizationGoal: 0 })
  }, [campaign])

  useEffect(() => {
    if (userProfile) {
      setIsXandr(userProfile.roles.includes('adcritter-ops-bidder'))
    }
  }, [userProfile])

  useEffect(() => {
    if (campaignBidder) {
      setInitial({
        targetedSegments: campaignBidder.targetedSegments,
        additionalSegments: campaignBidder.additionalSegments,
        hiddenSegments: campaignBidder.hiddenSegments,
        excludedSegmentIds: campaignBidder.excludedSegmentIds,
        targetedRegions: campaignBidder.targetedRegions,
        additionalRegions: campaignBidder.additionalRegions,
        hiddenRegions: campaignBidder.hiddenRegions,
        excludedRegionIds: campaignBidder.excludedRegionIds,
        targetedDmas: campaignBidder.targetedDmas,
        additionalDmas: campaignBidder.additionalDmas,
        hiddenDmas: campaignBidder.hiddenDmas,
        excludedDmaIds: campaignBidder.excludedDmaIds,
        targetedCities: campaignBidder.targetedCities,
        additionalCities: campaignBidder.additionalCities,
        hiddenCities: campaignBidder.hiddenCities,
        excludedCityIds: campaignBidder.excludedCityIds,
        targetedPostalCodes: campaignBidder.targetedPostalCodes,
        additionalPostalCodes: campaignBidder.additionalPostalCodes,
        hiddenPostalCodes: campaignBidder.hiddenPostalCodes,
        excludedPostalCodeIds: campaignBidder.excludedPostalCodeIds
      })
    }
  }, [campaignBidder])

  useEffect(() => {
    if (initial) {
      setDmas(initial.targetedDmas.map(s => ({ key: s.id.toString(), label: s.name, checked: initial.excludedDmaIds.includes(s.id) ? undefined : 'on' } as EuiSelectableOption)))
      setRegions(initial.targetedRegions.map(s => ({ key: s.id.toString(), label: s.name, checked: initial.excludedRegionIds.includes(s.id) ? undefined : 'on' } as EuiSelectableOption)))
      setCities(initial.targetedCities.map(s => ({ key: s.id.toString(), label: s.name + ', ' + s.regionCode, checked: initial.excludedCityIds.includes(s.id) ? undefined : 'on' } as EuiSelectableOption)))
      setPostalCodes(initial.targetedPostalCodes.map(s => ({ key: s.id.toString(), label: s.code, checked: initial.excludedPostalCodeIds.includes(s.id) ? undefined : 'on' } as EuiSelectableOption)))
      setSegments(
        initial.targetedSegments.map(
          s =>
            ({
              key: s.id.toString(),
              label: s.name || '',
              checked: initial.excludedSegmentIds.includes(s.id) ? undefined : 'on',
              data: {
                description: s.description,
                hierarchy: s.hierarchy
              }
            } as EuiSelectableOption<SegmentOptionData>)
        )
      )
    }
  }, [initial, formatter])

  const campaignSchema = Yup.object().shape({
    advertiserId: Yup.number().nullable(),
    pixelId: Yup.number().nullable(),
    additionalAudienceIds: Yup.array().of(Yup.number())
  })

  const updateCampaignBidder = (patch: CampaignBidderX) => {
    dispatch(
      campaignsApi.util.updateQueryData('getCampaignBidderX', campaign.campaignId, draft => {
        Object.assign(draft, patch)
      })
    )
  }

  const refresh = () => {
    setIsRefreshing(true)
    opsClient!.getCampaignBidderX(campaign.campaignId).then(r => {
      updateCampaignBidder(r)
      setIsRefreshing(false)
    })
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateCampaignBidderX = {
      additionalSegmentIds: values.additionalSegments.map(s => s.id),
      hiddenSegmentIds: values.hiddenSegments.map(s => s.id),
      excludedSegmentIds: segments!.filter(s => s.checked !== 'on').map(s => parseInt(s.key!)),
      additionalRegionIds: values.additionalRegions.map(s => s.id),
      hiddenRegionIds: values.hiddenRegions.map(s => s.id),
      excludedRegionIds: regions!.filter(s => s.checked !== 'on').map(s => Number(s.key!)),
      additionalDmaIds: values.additionalDmas.map(s => s.id),
      hiddenDmaIds: values.hiddenDmas.map(s => s.id),
      excludedDmaIds: dmas!.filter(s => s.checked !== 'on').map(s => Number(s.key!)),
      additionalCityIds: values.additionalCities.map(c => c.id),
      hiddenCityIds: values.hiddenCities.map(c => c.id),
      excludedCityIds: cities!.filter(s => s.checked !== 'on').map(s => Number(s.key!)),
      additionalPostalCodeIds: values.additionalPostalCodes.map(z => z.id),
      hiddenPostalCodeIds: values.hiddenPostalCodes.map(z => z.id),
      excludedPostalCodeIds: postalCodes!.filter(s => s.checked !== 'on').map(s => Number(s.key!))
    }

    setCampaignBidSettings({
      campaignId: campaign.campaignId,
      bidSettings: bidSettings
    }).then(() => {
      opsClient!.putCampaignBidderX(campaign.campaignId, update).then(result => {
        updateCampaignBidder(result)
        setSubmitting(false)
      })
    })
  }

  const updateShowAdvanced = (show: boolean) => {
    setShowAdvanced(show)
    localStoreManagerService.savePermanentData('showAdvanced', show)
  }

  const syncLocations = () => {
    setIsSyncingLocations(true)
    opsClient!.putCampaignBidderXSyncLocations(campaign.campaignId).then(r => {
      updateCampaignBidder(r)
      setIsSyncingLocations(false)
    })
  }
  const syncSegments = () => {
    setIsSyncingSegments(true)
    opsClient!.putCampaignBidderXSyncSegments(campaign.campaignId).then(r => {
      updateCampaignBidder(r)
      setIsSyncingSegments(false)
    })
  }

  const renderSegmentOption = (option: EuiSelectableOption<SegmentOptionData>) => {
    return (
      <>
        {option.label}
        <EuiText size='xs' color='subdued' className='eui-displayBlock'>
          <small>{option.description || ''}</small>
        </EuiText>
      </>
    )
  }

  const bidSettingsChanged = (bidSettings: BidStrategy) => {
    setBidSettings(bidSettings)
  }

  const onPostalCsvUploaded = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onload = e => {
        let csv = e.target?.result as string
        csv = csv.replaceAll(/\n/g, ',')
        csv = csv.replaceAll(/\s/g, ',')
        const allZips = csv
          .split(',')
          .filter((v, i, a) => a.indexOf(v) === i && (v.length == 4 || v.length == 5))
          .map(zip => (zip.length == 4 ? `0${zip}` : zip))
        const zipChunks = []
        for (let i = 0; i < allZips.length; i += 150) {
          zipChunks.push(allZips.slice(i, i + 150))
        }
        const zipPromises = zipChunks.map(zips => getZipsFromCsv({ codeCsv: zips.join(',') }))
        Promise.all(zipPromises).then(zipResults => {
          const hiddenZips = zipResults.flatMap(result => result.data ?? [])
          setInitial(() => {
            return {
              ...initial,
              hiddenPostalCodes: hiddenZips
            }
          })
        })
      }
      reader.readAsText(file)
    }
  }

  return (
    <React.Fragment>
      {!campaignBidder && <EuiEmptyPrompt />}
      {campaignBidder && (
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              {campaignBidder.status === BidderXStatus.Unknown && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'questionInCircle'} color='success' />} title='Unknown' description='Campaign is currently in an unknown state' titleSize='xs' />}
              {campaignBidder.status === BidderXStatus.Active && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'playFilled'} color='success' />} title='Active' description='Campaign is currently active and running' titleSize='xs' />}
              {campaignBidder.status === BidderXStatus.Inactive && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'stopFilled'} color='subdued' />} title='Ended' description='Campaign is not running' titleSize='xs' />}
              {campaignBidder.status === BidderXStatus.PartiallyInactive && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'dot'} color='success' />} title='Total Budget Reached' description='Campaign is not fully running' titleSize='xs' />}
              {campaignBidder.status === BidderXStatus.Banned && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'magnifyWithExclamation'} color='danger' />} title='Banned' description='Campaign creatives have been banned' titleSize='xs' />}
              {campaignBidder.status === BidderXStatus.CreativeNotPushed && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'magnifyWithExclamation'} color='danger' />} title='Creative Not Pushed' description='One or more creatives have not been pushed to the bidder' titleSize='xs' />}
              {campaignBidder.status === BidderXStatus.CreativeNotAttached && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'magnifyWithExclamation'} color='danger' />} title='Creative Not Attached' description='One or more creatives have not been attached to the campaign in the bidder' titleSize='xs' />}
            </EuiFlexItem>
            {campaignBidder.campaignCreativesAuditStatus !== BidderXCampaignCreativesAuditStatus.Ok && (
              <EuiFlexItem>
                {campaignBidder.campaignCreativesAuditStatus === BidderXCampaignCreativesAuditStatus.OneOrMoreCreativesRejected && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'magnifyWithExclamation'} color='warning' />} title={insertSpaces(BidderXCampaignCreativesAuditStatus.OneOrMoreCreativesRejected)} description='One or more creatives have been rejected' titleSize='xs' />}
                {campaignBidder.campaignCreativesAuditStatus === BidderXCampaignCreativesAuditStatus.OneOrMoreCreativesPending && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'reporter'} color='warning' />} title={insertSpaces(BidderXCampaignCreativesAuditStatus.OneOrMoreCreativesPending)} description='One or more creatives are pending' titleSize='xs' />}
              </EuiFlexItem>
            )}
            {campaignBidder.start && campaignBidder.end && (
              <EuiFlexItem>
                <EuiPanel hasShadow={false} hasBorder={true}>
                  <EuiStat title='Current Billing Schedule' description='' titleSize='xs'>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            {moment(campaignBidder.start).utc().format('ll')} - {moment(campaignBidder.end).utc().format('ll')}
                          </p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiStat>
                </EuiPanel>
              </EuiFlexItem>
            )}
            {campaignBidder.pacing && campaignBidder.pacing.flightCurrentDay > 0 && (
              <EuiFlexItem grow={2}>
                <EuiPanel hasShadow={false} hasBorder={true}>
                  <EuiStat title='Today' description='' titleSize='xs'>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.todaysImpressions.toLocaleString()}</strong> Impressions
                          </p>
                          {campaign.campaignType === CampaignType.Internet && (
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.todaysClicks.toLocaleString()}</strong> Clicks
                            </p>
                          )}
                          <p style={{ marginBottom: 4 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.todaysSpend)}</strong> Spend
                          </p>
                          <p style={{ marginBottom: 4 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.todaysCpm)}</strong> CPM
                          </p>
                          {campaign.campaignType === CampaignType.Internet && (
                            <p style={{ marginBottom: 4 }}>
                              <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.todaysCpc)}</strong> CPC
                            </p>
                          )}
                          <p>Updated approx. every top-of-the hour</p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiStat>
                </EuiPanel>
              </EuiFlexItem>
            )}
            {campaignBidder.pacing && (
              <EuiFlexItem grow={2}>
                <EuiPanel hasShadow={false} hasBorder={true}>
                  <EuiStat title={campaignBidder.pacing.flightCurrentDay > 0 ? 'Current Flight (excluding today)' : 'Last Flight'} description='' titleSize='xs'>
                    {campaignBidder.pacing.flightCurrentDay < 0 && (
                      <EuiText color='subdued' size='xs'>
                        No pacing data available yet, too early.
                      </EuiText>
                    )}
                    {campaignBidder.pacing.flightCurrentDay >= 0 && (
                      <EuiFlexGroup gutterSize='none'>
                        <EuiFlexItem>
                          <EuiText size='xs'>
                            <p style={{ marginBottom: 2 }}>
                              {moment(campaignBidder.pacing.flightStart).utc().format('ll')} - {moment(campaignBidder.pacing.flightEnd).utc().format('ll')}
                            </p>
                            {campaignBidder.pacing.flightCurrentDay > 0 && (
                              <p style={{ marginBottom: 2 }}>
                                {campaignBidder.pacing.flightCurrentDay} of {campaignBidder.pacing.flightTotalDays} days ({campaignBidder.pacing.flightDayProgress.toFixed(1) + '%'})
                              </p>
                            )}
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.flightSpend)}</strong> / {formatter.format(campaignBidder.pacing.flightBudget)} ({campaignBidder.pacing.flightSpendProgress.toFixed(1) + '%'})
                            </p>
                            {showAdvanced && campaignBidder.pacing.flightBoost && campaignBidder.pacing.flightBoost > 0 && (
                              <p style={{ marginBottom: 2 }}>
                                <EuiText color='warning' size='s'>
                                  <strong>{formatter.format(campaignBidder.pacing.flightBoost)}</strong> boost
                                </EuiText>
                              </p>
                            )}
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.flightImpressions.toLocaleString()}</strong> Impressions
                            </p>
                            {campaign.campaignType === CampaignType.Internet && (
                              <p style={{ marginBottom: 2 }}>
                                <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.flightClicks.toLocaleString()}</strong> Clicks
                              </p>
                            )}
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.flightCpm)}</strong> CPM
                            </p>
                            {campaign.campaignType === CampaignType.Internet && (
                              <p style={{ marginBottom: 2 }}>
                                <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.flightCpc)}</strong> CPC
                              </p>
                            )}
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.flightPacing.toFixed(1) + '%'}</strong> Pacing
                            </p>
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )}
                  </EuiStat>
                </EuiPanel>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={2}>
              <EuiPanel hasShadow={false} hasBorder={true}>
                <EuiStat title={'Lifetime (excluding today)'} description='' titleSize='xs'>
                  {!campaignBidder.pacing && (
                    <EuiText color='subdued' size='xs'>
                      No lifetime data available yet.
                    </EuiText>
                  )}
                  {campaignBidder.pacing && campaignBidder.pacing.lifetimeCurrentDay >= 0 && (
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            {moment(campaignBidder.pacing.lifetimeStart).utc().format('ll')} - {moment(campaignBidder.pacing.lifetimeEnd).utc().format('ll')}
                          </p>
                          <p style={{ marginBottom: 2 }}>Total of {campaignBidder.pacing.lifetimeTotalDays} days</p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.lifetimeSpend)}</strong> / {formatter.format(campaignBidder.pacing.lifetimeBudget)} ({campaignBidder.pacing.lifetimeSpendProgress.toFixed(1) + '%'})
                          </p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.lifetimeImpressions.toLocaleString()}</strong> Impressions
                          </p>
                          {campaign.campaignType === CampaignType.Internet && (
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.lifetimeClicks.toLocaleString()}</strong> Clicks
                            </p>
                          )}
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.lifetimeCpm)}</strong> CPM
                          </p>
                          {campaign.campaignType === CampaignType.Internet && (
                            <p style={{ marginBottom: 2 }}>
                              <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.lifetimeCpc)}</strong> CPC
                            </p>
                          )}
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.lifetimePacing.toFixed(1) + '%'}</strong> Pacing
                          </p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </EuiStat>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiTitle size='s'>
            <h3>Bid Strategy</h3>
          </EuiTitle>
          <EuiSpacer size={'xs'} />
          <CampaignOptimizationControl campaignType={campaign.campaignType as CampaignType} bidSettings={bidSettings} onBidSettingsChange={bidSettingsChanged} />

          <Formik initialValues={initial} enableReinitialize validationSchema={campaignSchema} onSubmit={doSubmit}>
            {props => (
              <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth>
                <EuiTitle size='s'>
                  <h3>Location Targeting</h3>
                </EuiTitle>
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'Regions'}>
                      <EuiSelectable aria-label='Regions' emptyMessage='Customer did not enter any Regions' options={regions} listProps={{ bordered: true }} onChange={newOptions => setRegions(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional Regions'}>
                      <React.Fragment>
                        <RegionFinderXandr onOptionClick={region => props.setFieldValue('additionalRegions', [...props.values.additionalRegions, region])} />
                        <EuiSpacer size='xs' />
                        <RegionTargeter
                          regions={props.values.additionalRegions}
                          onRegionRemoved={region => {
                            props.setFieldValue(
                              'additionalRegions',
                              props.values.additionalRegions.filter(c => c.id !== region.id)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin Regions'}>
                        <React.Fragment>
                          <RegionFinderXandr onOptionClick={region => props.setFieldValue('hiddenRegions', [...props.values.hiddenRegions, region])} />
                          <EuiSpacer size='xs' />
                          <RegionTargeter
                            regions={props.values.hiddenRegions}
                            onRegionRemoved={region => {
                              props.setFieldValue(
                                'hiddenRegions',
                                props.values.hiddenRegions.filter(c => c.id !== region.id)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'DMAs'}>
                      <EuiSelectable aria-label='DMAs' emptyMessage='Customer did not enter any DMAs' options={dmas} listProps={{ bordered: true }} onChange={newOptions => setDmas(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional DMAs'}>
                      <React.Fragment>
                        <DMAFinderXandr onOptionClick={dma => props.setFieldValue('additionalDmas', [...props.values.additionalDmas, dma])} />
                        <EuiSpacer size='xs' />
                        <DmaTargeterNew
                          dmas={props.values.additionalDmas}
                          onDmaRemoved={dma => {
                            props.setFieldValue(
                              'additionalDmas',
                              props.values.additionalDmas.filter(c => c.id !== dma.id)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin DMAs'}>
                        <React.Fragment>
                          <DMAFinderXandr onOptionClick={dma => props.setFieldValue('hiddenDmas', [...props.values.hiddenDmas, dma])} />
                          <EuiSpacer size='xs' />
                          <DmaTargeterNew
                            dmas={props.values.hiddenDmas}
                            onDmaRemoved={dma => {
                              props.setFieldValue(
                                'hiddenDmas',
                                props.values.hiddenDmas.filter(c => c.id !== dma.id)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'Cities'}>
                      <EuiSelectable aria-label='Cities' emptyMessage='Customer did not enter any Cities' options={cities} listProps={{ bordered: true }} onChange={newOptions => setCities(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional Cities'}>
                      <React.Fragment>
                        <CityFinderXandr onOptionClick={city => props.setFieldValue('additionalCities', [...props.values.additionalCities, city])} />
                        <EuiSpacer size='xs' />
                        <CityTargeterNew
                          cities={props.values.additionalCities}
                          onCityRemoved={city => {
                            props.setFieldValue(
                              'additionalCities',
                              props.values.additionalCities.filter(c => c.id !== city.id)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin Cities'}>
                        <React.Fragment>
                          <CityFinderXandr onOptionClick={city => props.setFieldValue('hiddenCities', [...props.values.hiddenCities, city])} />
                          <EuiSpacer size='xs' />
                          <CityTargeterNew
                            cities={props.values.hiddenCities}
                            onCityRemoved={city => {
                              props.setFieldValue(
                                'hiddenCities',
                                props.values.hiddenCities.filter(c => c.id !== city.id)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                {isXandr && showAdvanced && (
                  <React.Fragment>
                    <EuiFormRow label={'Upload CSV'} fullWidth={false}>
                      <EuiFilePicker id={'filePicker'} initialPromptText='Select or drag and drop file' onChange={onPostalCsvUploaded} display={'default'} aria-label='file picker' isLoading={getZipsFromCsvQuery.isLoading} />
                    </EuiFormRow>
                    <EuiSpacer size='m' />
                  </React.Fragment>
                )}
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'Postal Codes'}>
                      <EuiSelectable aria-label='Postal Codes' emptyMessage='Customer did not enter any Postal Codes' options={postalCodes} listProps={{ bordered: true }} onChange={newOptions => setPostalCodes(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional Postal Codes'}>
                      <React.Fragment>
                        <PostalCodeFinderXandr onOptionClick={postalCode => props.setFieldValue('additionalPostalCodes', [...props.values.additionalPostalCodes, postalCode])} />
                        <EuiSpacer size='xs' />
                        <PostalCodeTargeter
                          postalCodes={props.values.additionalPostalCodes}
                          onPostalCodeRemoved={postalCode => {
                            props.setFieldValue(
                              'additionalPostalCodes',
                              props.values.additionalPostalCodes.filter(c => c.id !== postalCode.id)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin Postal Codes'}>
                        <React.Fragment>
                          <PostalCodeFinderXandr onOptionClick={postalCode => props.setFieldValue('hiddenPostalCodes', [...props.values.hiddenPostalCodes, postalCode])} />
                          <EuiSpacer size='xs' />
                          <PostalCodeTargeter
                            postalCodes={props.values.hiddenPostalCodes}
                            onPostalCodeRemoved={postalCode => {
                              props.setFieldValue(
                                'hiddenPostalCodes',
                                props.values.hiddenPostalCodes.filter(c => c.id !== postalCode.id)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                {showAdvanced && (
                  <React.Fragment>
                    {campaignBidder?.postalCodeLists && campaignBidder.postalCodeLists.length > 0 && (
                      <React.Fragment>
                        <EuiSpacer size='m' />
                        <EuiFormRow label='Postal Code Lists Uploaded'>
                          <React.Fragment>
                            {campaignBidder.postalCodeLists.map(w => (
                              <React.Fragment>
                                found <strong>{w.count.toLocaleString()}</strong> postal codes
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        </EuiFormRow>
                      </React.Fragment>
                    )}
                    {campaignBidder?.mapPostalCodeList && (
                      <React.Fragment>
                        <EuiSpacer size='m' />
                        <EuiFormRow label='Postal Code Lists Extracted From Map'>
                          <React.Fragment>
                            found <strong>{campaignBidder.mapPostalCodeList.count.toLocaleString()}</strong> postal codes
                          </React.Fragment>
                        </EuiFormRow>
                      </React.Fragment>
                    )}

                    <EuiSpacer size='m' />
                    <EuiButtonEmpty size='s' isLoading={isSyncingLocations} onClick={syncLocations}>
                      Sync Locations from Bidder-X
                    </EuiButtonEmpty>
                  </React.Fragment>
                )}

                <EuiSpacer />
                {campaign.audience && (campaign.audience.segmentLogic || campaign.audience.curatedAudienceIds.length > 0) ? (
                  <EuiTitle size='s'>
                    <h3>Segment Logic Editing Coming Soon...</h3>
                  </EuiTitle>
                ) : (
                  <React.Fragment>
                    <EuiTitle size='s'>
                      <h3>Segment/Audience Targeting</h3>
                    </EuiTitle>
                    <EuiFlexGroup gutterSize='s'>
                      <EuiFlexItem>
                        <EuiFormRow label={'Segments'}>
                          <EuiSelectable aria-label='Segments' emptyMessage='Customer did not enter any Segments' options={segments} listProps={{ bordered: true, rowHeight: 50 }} onChange={newOptions => setSegments(newOptions)} renderOption={renderSegmentOption}>
                            {list => list}
                          </EuiSelectable>
                        </EuiFormRow>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFormRow label={'Additional Segments'}>
                          <React.Fragment>
                            <SegmentFinder isInvalid={false} onSegmentClicked={segment => props.setFieldValue('additionalSegments', [...props.values.additionalSegments, segment])} mode={campaign.campaignType === 'Internet' ? 'Internet' : 'Tv'} />
                            <EuiSpacer size='s' />
                            <SegmentTargeter
                              segments={props.values.additionalSegments}
                              onSegmentRemoved={segment => {
                                props.setFieldValue(
                                  'additionalSegments',
                                  props.values.additionalSegments.filter(c => c.id !== segment.id)
                                )
                              }}
                            />
                          </React.Fragment>
                        </EuiFormRow>
                      </EuiFlexItem>
                      {showAdvanced && (
                        <EuiFlexItem>
                          <EuiFormRow label={'Admin Segments'}>
                            <React.Fragment>
                              <SegmentFinder isInvalid={false} onSegmentClicked={segment => props.setFieldValue('hiddenSegments', [...props.values.hiddenSegments, segment])} mode={campaign.campaignType === 'Internet' ? 'Internet' : 'Tv'} />
                              <EuiSpacer size='s' />
                              <SegmentTargeter
                                segments={props.values.hiddenSegments}
                                onSegmentRemoved={segment => {
                                  props.setFieldValue(
                                    'hiddenSegments',
                                    props.values.hiddenSegments.filter(c => c.id !== segment.id)
                                  )
                                }}
                              />
                            </React.Fragment>
                          </EuiFormRow>
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>
                    {showAdvanced && (
                      <React.Fragment>
                        <EuiSpacer size='m' />
                        <EuiButtonEmpty size='s' isLoading={isSyncingSegments} onClick={syncSegments}>
                          Sync Segments from Bidder-X
                        </EuiButtonEmpty>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <EuiSpacer />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem grow={false}>
                    <EuiButton isLoading={props.isSubmitting} iconType='push' fill type='submit'>
                      Save &amp; Push to Bidder
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton color='success' iconType='refresh' onClick={refresh} isLoading={isRefreshing}>
                      Refresh
                    </EuiButton>
                  </EuiFlexItem>
                  {isXandr && (
                    <React.Fragment>
                      <EuiFlexItem grow={false}>
                        <EuiButton color='text' fill iconType='magnifyWithPlus' href={`https://invest.xandr.com/bmw/insertion-orders/in-progress/insertion-order/${campaignBidder.bidderCampaignId}?tab=settings`} target='_blank'>
                          View in Bidder
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiSwitch onChange={e => updateShowAdvanced(e.target.checked)} checked={showAdvanced} label='Show Advanced Options' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                      </EuiFlexItem>
                    </React.Fragment>
                  )}
                  <EuiFlexItem grow={true} />
                </EuiFlexGroup>
              </EuiForm>
            )}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default CampaignBidderXTab
