import React, { useEffect, useState } from 'react'
import useDebounce from 'utils/useDebounce'

import { EuiButton, EuiFieldSearch, EuiFlexGrid, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiImage, EuiInputPopover, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner, EuiPagination, EuiPortal, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AcImage, useOpsClient } from 'api'

interface AccountImageAddFlyOutParams {
  accountId: string
}

const AccountImageAddFlyOut: React.FC<AccountImageAddFlyOutParams> = ({ accountId }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [searchId, setSearchId] = useState<string>('')
  const [suggestions, setSuggestions] = useState<string[]>([])
  const debouncedSearch = useDebounce(search, 500)
  const [images, setImages] = useState<AcImage[]>([])
  const [selectedImage, setSelectedImage] = useState<AcImage | null>(null)
  const [searchPopoverOpen, setSearchPopoverOpen] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const pageSize = 20
  const opsClient = useOpsClient()

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  const onSearch = () => {
    if (opsClient) {
      setCurrentPage(1)
      setSearchPopoverOpen(false)
      setIsSearching(true)
      opsClient.searchImages(search, pageSize, currentPage).then(result => {
        setSearchId(result.searchId)
        setTotalCount(result.totalCount)
        setImages(result.images)
        setIsSearching(false)
      })
    }
  }

  const onLicense = () => {
    if (opsClient && selectedImage) {
      setIsFlyoutVisible(false)
      opsClient.licenseImageForAccount(selectedImage.id, accountId, searchId).then(() => {
        setSearch('')
        setIsFlyoutVisible(false)
      })
    }
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  useEffect(() => {
    if (debouncedSearch && opsClient) {
      setIsLoadingSuggestions(true)
      opsClient.searchImageSuggestions(debouncedSearch).then(result => {
        setSuggestions(result)
        setIsLoadingSuggestions(false)
      })
    }
  }, [debouncedSearch, opsClient])

  const gotoPage = (page: number) => {
    setCurrentPage(page)
    setIsSearching(true)
    opsClient!.searchImages(search, pageSize, page).then(result => {
      setSearchId(result.searchId)
      setTotalCount(result.totalCount)
      setImages(result.images)
      setIsSearching(false)
    })
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
          <EuiFlyoutHeader>
            <EuiTitle size='s'>
              <h2>Search Shutterstock</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiInputPopover fullWidth input={<EuiFieldSearch fullWidth value={search} isLoading={isLoadingSuggestions} onChange={v => onSearchChange(v.target.value)} isClearable={true} onFocus={() => setSearchPopoverOpen(true)} incremental={false} onSearch={() => onSearch()} />} isOpen={searchPopoverOpen} closePopover={() => setSearchPopoverOpen(false)}>
              {suggestions.length ? (
                <EuiListGroup flush={true}>
                  {suggestions.map(h => (
                    <EuiListGroupItem
                      key={h}
                      label={h}
                      onClick={() => {
                        setSearch(h)
                        onSearch()
                      }}
                    />
                  ))}
                </EuiListGroup>
              ) : (
                <EuiText color='subdued' size='xs'>
                  <p>Start typing a search, hints will appear here</p>
                </EuiText>
              )}
            </EuiInputPopover>
            <EuiSpacer />

            {images && images.length > 0 && (
              <React.Fragment>
                {isSearching && <EuiLoadingSpinner size='xl' />}
                {!isSearching && (
                  <EuiFlexGrid>
                    {images!.map(i => (
                      <EuiFlexItem key={i.id}>
                        <EuiImage alt={''} url={i.thumbnailUrl} hasShadow={true} onClick={() => setSelectedImage(i.thumbnailUrl === selectedImage?.thumbnailUrl ? null : i)} className={selectedImage === i ? 'selectableImage selectableImage-selected' : 'selectableImage'} />
                      </EuiFlexItem>
                    ))}
                  </EuiFlexGrid>
                )}
                <EuiSpacer />
                <EuiPagination aria-label='Image search result pages' pageCount={Math.floor(totalCount / pageSize)} activePage={currentPage} onPageClick={page => gotoPage(page)} />
              </React.Fragment>
            )}
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiButton fill disabled={selectedImage === null} onClick={onLicense}>
              Add Selected Image
            </EuiButton>
            <EuiSpacer size='s' />
            <EuiText size='xs' color='subdued'>
              <p>Please note adding an image can take a minute before it appears in the list of images for this business type.</p>
            </EuiText>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiButton onClick={showFlyout} fill color='primary' size='s'>
        Add Image
      </EuiButton>
      {flyout}
    </React.Fragment>
  )
}

export default AccountImageAddFlyOut
