import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer } from '@elastic/eui'

import { ThirdPartyAudience, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import { TvAudienceFinder } from 'components/Finders'
import { AudienceFinder } from 'components/Finders/AudienceFinder'
import { AudienceTargeterDetailed } from 'components/Targeters'

interface BusinessTypeTargetingTabParams {
  businessTypeId: string
}

interface FormValues {
  internetAudiences: ThirdPartyAudience[]
  tvAudiences: ThirdPartyAudience[]
  tvInspirationalAudiences: ThirdPartyAudience[]
  internetInspirationalAudiences: ThirdPartyAudience[]
}

const BusinessTypeDetailsTab: React.FC<BusinessTypeTargetingTabParams> = ({ businessTypeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [initial, setInitial] = useState<FormValues>({ internetAudiences: [], tvAudiences: [], tvInspirationalAudiences: [], internetInspirationalAudiences: [] })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const opsClient = useOpsClient()

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getBusinessTypeTargeting(businessTypeId).then(result => {
        setInitial({ internetAudiences: result.internetAudiences, tvAudiences: result.tvAudiences, tvInspirationalAudiences: result.tvInspirationalAudiences ?? [], internetInspirationalAudiences: result.internetInspirationalAudiences ?? [] })
        setIsLoading(false)
      })
    }
  }, [opsClient, businessTypeId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.updateBusinessTypeTargeting(businessTypeId, { internetAudiences: values.internetAudiences, tvAudiences: values.tvAudiences, internetInspirationalAudiences: values.internetInspirationalAudiences, tvInspirationalAudiences: values.tvInspirationalAudiences }).then(() => setSubmitting(false))
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <Formik initialValues={initial} enableReinitialize onSubmit={doSubmit}>
            {props => (
              <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth>
                <EuiFormRow label={'Internet Campaign Segments'} fullWidth>
                  <React.Fragment>
                    <AudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('internetAudiences', [...props.values.internetAudiences, segment])} />
                    <EuiSpacer size='s' />
                    <AudienceTargeterDetailed
                      audiences={props.values.internetAudiences}
                      onAudienceRemoved={segment => {
                        props.setFieldValue(
                          'internetAudiences',
                          props.values.internetAudiences.filter(c => c.id !== segment.id)
                        )
                      }}
                    />
                  </React.Fragment>
                </EuiFormRow>
                <EuiFormRow label={'TV Campaign Segments'} fullWidth>
                  <React.Fragment>
                    <TvAudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('tvAudiences', [...props.values.tvAudiences, segment])} />
                    <EuiSpacer size='s' />
                    <AudienceTargeterDetailed
                      audiences={props.values.tvAudiences}
                      onAudienceRemoved={segment => {
                        props.setFieldValue(
                          'tvAudiences',
                          props.values.tvAudiences.filter(c => c.id !== segment.id)
                        )
                      }}
                    />
                  </React.Fragment>
                </EuiFormRow>
                <EuiSpacer size={'xxl'} />
                <EuiFormRow label={'Internet Inspirational Segments'} fullWidth>
                  <React.Fragment>
                    <TvAudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('internetInspirationalAudiences', [...props.values.internetInspirationalAudiences, segment])} />
                    <EuiSpacer size='s' />
                    <AudienceTargeterDetailed
                      audiences={props.values.internetInspirationalAudiences}
                      onAudienceRemoved={segment => {
                        props.setFieldValue(
                          'internetInspirationalAudiences',
                          props.values.internetInspirationalAudiences.filter(c => c.id !== segment.id)
                        )
                      }}
                    />
                  </React.Fragment>
                </EuiFormRow>
                <EuiFormRow label={'TV Inspirational Segments'} fullWidth>
                  <React.Fragment>
                    <TvAudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('tvInspirationalAudiences', [...props.values.tvInspirationalAudiences, segment])} />
                    <EuiSpacer size='s' />
                    <AudienceTargeterDetailed
                      audiences={props.values.tvInspirationalAudiences}
                      onAudienceRemoved={segment => {
                        props.setFieldValue(
                          'tvInspirationalAudiences',
                          props.values.tvInspirationalAudiences.filter(c => c.id !== segment.id)
                        )
                      }}
                    />
                  </React.Fragment>
                </EuiFormRow>
                <EuiSpacer />
                <EuiButton isLoading={props.isSubmitting} isDisabled={!canUpdate} fill type='submit'>
                  Save
                </EuiButton>
              </EuiForm>
            )}
          </Formik>
        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>
    </React.Fragment>
  )
}

export default BusinessTypeDetailsTab
