import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBreadcrumbs, EuiFieldSearch, EuiIcon, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { User, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import useDebounce from 'utils/useDebounce'

const UsersPage: React.FC = () => {
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const opsClient = useOpsClient()
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const { userProfile } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (userProfile) {
      if (!userProfile.roles.includes('adcritter-ops-admin')) {
        history.push('/dashboard')
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (debouncedSearch) {
      setIsLoadingUsers(true)
      opsClient?.getUsers(debouncedSearch).then(c => {
        setUsers(c)
        setIsLoadingUsers(false)
      })
    }
  }, [opsClient, debouncedSearch])

  const columns: Array<EuiBasicTableColumn<User>> = [
    {
      name: `Email`,
      render: (c: User) => <EuiLink onClick={() => history.push(`/customers/users/${c.userId}`)}>{c.email}</EuiLink>,
      sortable: (c: User) => c.email
    },
    {
      name: 'First Name',
      field: 'firstName',
      sortable: (c: User) => c.firstName
    },
    {
      name: 'Last Name',
      field: 'lastName',
      sortable: (c: User) => c.lastName
    },
    {
      name: 'First Name',
      field: 'firstName',
      sortable: (c: User) => c.firstName
    },
    {
      name: 'Is Blocked',
      render: (c: User) => <React.Fragment>{c.isBlocked && <EuiIcon type='checkInCircleFilled' />}</React.Fragment>
    },
    {
      name: 'Last Login',
      sortable: (c: User) => (c.lastLogin ? moment(c.lastLogin, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString() : null),
      render: (c: User) => c.lastLogin?.format('MMM Do YYYY HH:mm:ss')
    },
    {
      name: 'Created',
      sortable: (c: User) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString(),
      render: (c: User) => c.created.format('MMM Do YYYY HH:mm:ss')
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'email',
      direction: SortDirection.ASC
    }
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Settings' },
              {
                text: 'Users',
                href: '/settings/users',
                onClick: () => history.push('/settings/users')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Users</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiFieldSearch fullWidth placeholder='Please start by searching for a user.' value={search} isLoading={false} onChange={v => onSearchChange(v.target.value)} isClearable={true} incremental={true} />
            <EuiSpacer />
            <EuiInMemoryTable loading={isLoadingUsers} items={users} columns={columns} sorting={sorting} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default UsersPage
