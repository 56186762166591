import moment from 'moment'
import React, { FC } from 'react'

import { Axis, Chart, CurveType, LIGHT_THEME, LineSeries, PartialTheme, Position, ScaleType, Settings } from '@elastic/charts'

import { NumberOfCampaignsByDay } from 'api/entities/CampaignExecutiveDashboardByOrganizationType'

export enum ExecutiveCampaignsByDayFilter {
  oneMonth = 1,
  threeMonths = 3,
  sixMonths = 6,
  oneYear = 12
}

export interface GenericExecutiveChartProps {
  dayFilter: ExecutiveCampaignsByDayFilter
  showZero: boolean
  title: string
  totalActive: NumberOfCampaignsByDay | undefined
  active: NumberOfCampaignsByDay | undefined
  inactive: NumberOfCampaignsByDay | undefined
  ended: NumberOfCampaignsByDay | undefined
}

const ExecutiveDashboardCampaignsByOrganizationChart: FC<GenericExecutiveChartProps> = ({ dayFilter, showZero, title, totalActive, active, inactive, ended }) => {
  if (!active || !inactive || !ended) {
    return <></>
  }

  const dayWithinFilter = (date: string) => {
    const today = moment()
    if (moment(date).valueOf() < today.subtract(dayFilter.valueOf(), 'month').valueOf()) {
      return false
    }

    return true
  }

  const filterData = (numberOfCampaignsByDay: NumberOfCampaignsByDay | undefined, forceHideZero: boolean) => {
    const data = [] as any[]

    for (const item in numberOfCampaignsByDay) {
      if (!dayWithinFilter(item)) {
        continue
      }

      if ((!showZero || forceHideZero) && (!numberOfCampaignsByDay[item] || numberOfCampaignsByDay[item] === 0)) {
        continue
      }

      data.push({ x: item, y: numberOfCampaignsByDay[item] ?? 0 })
    }

    return data
  }

  const filteredTotalActive = filterData(totalActive, false)
  const filteredActive = filterData(active, false)
  const filteredInactive = filterData(inactive, false)
  const filteredEnded = filterData(ended, true)

  const theme: PartialTheme = {
    lineSeriesStyle: {
      line: {
        strokeWidth: 3
      },
      point: {
        radius: dayFilter === ExecutiveCampaignsByDayFilter.oneMonth || dayFilter === ExecutiveCampaignsByDayFilter.threeMonths ? 4 : 2,
        visible: dayFilter !== ExecutiveCampaignsByDayFilter.oneYear
      }
    }
  }

  return (
    <Chart size={{ height: 300 }}>
      <Settings theme={theme} baseTheme={LIGHT_THEME} showLegend />
      <LineSeries data={filteredTotalActive} id='Total Active Campaigns' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} curve={CurveType.CURVE_MONOTONE_X} color={['#f49342']} />
      {filteredActive.length > 0 && <LineSeries data={filteredActive} id='Campaigns that went active' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} color={['#30694B']} lineSeriesStyle={{ line: { strokeWidth: 1 } }} />}
      {filteredInactive.length > 0 && <LineSeries data={filteredInactive} id='Campaigns that went inactive' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} color={['#D2042D']} lineSeriesStyle={{ line: { strokeWidth: 1 } }} />}
      {filteredEnded.length > 0 && <LineSeries data={filteredEnded} id='Campaigns that ended' xAccessor='x' yAccessors={['y']} xScaleType={ScaleType.Ordinal} yScaleType={ScaleType.Linear} color={['#8A8A8A']} lineSeriesStyle={{ line: { strokeWidth: 0 }, point: { radius: 5, fill: '#8A8A8A', visible: true } }} />}
      <Axis id='campaigns' title='Campaigns' position={Position.Left} tickFormat={d => d.toString()} />
      <Axis id='bottom' title={title} position={Position.Bottom} />
    </Chart>
  )
}

export default ExecutiveDashboardCampaignsByOrganizationChart
