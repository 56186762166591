import React from 'react'

import { EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiTitle } from '@elastic/eui'

import { useGetNotesForIdQuery } from 'api/rtkQueryApi/opsApi/noteApi'
import { AcNote } from 'components/ACTools/AcNote'

export interface IAcNotesFlyout {
  onFlyoutClose: () => void
  title?: string
  primaryId: string
}

export const AcNotesFlyout: React.FC<IAcNotesFlyout> = props => {
  const contactNotesResults = useGetNotesForIdQuery(props.primaryId)

  return (
    <EuiFlyout
      onClose={() => {
        props.onFlyoutClose()
      }}>
      <EuiFlyoutHeader>
        {props.title && (
          <EuiTitle size='s'>
            <h2>{props.title}</h2>
          </EuiTitle>
        )}
      </EuiFlyoutHeader>
      <EuiFlyoutBody>{contactNotesResults.data?.map(note => <AcNote key={note.id} hideSave note={note} hideDelete={true} /> ?? [])}</EuiFlyoutBody>
    </EuiFlyout>
  )
}
