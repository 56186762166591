import React, { useEffect, useState } from 'react'

import { EuiFieldSearch, EuiInMemoryTable, EuiLink, EuiSpacer, SortDirection, EuiPageTemplate } from '@elastic/eui'

import { Account, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

const AccountsPage: React.FC = () => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false)
  const [accounts, setAccounts] = useState<Account[]>([])
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const opsClient = useOpsClient()

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && debouncedSearch) {
      setIsLoadingAccounts(true)

      opsClient.getAccounts(null, debouncedSearch).then(c => {
        if (isMounted) {
          setAccounts(c)
          setIsLoadingAccounts(false)
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [opsClient, debouncedSearch])

  const columns = [
    {
      name: 'Account',
      render: (c: Account) => (
        <EuiLink href={`/customers/accounts/${c.accountId}`} target='_blank'>
          {c.accountName}
        </EuiLink>
      )
    },
    {
      name: 'Organization',
      render: (c: Account) => (
        <EuiLink href={`/customers/organizations/${c.organizationId}`} target='_blank'>
          {c.organizationName}
        </EuiLink>
      )
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'accountName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='Accounts' restrictWidth={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiFieldSearch fullWidth placeholder='Please start by searching for an account.' value={search} isLoading={isLoadingAccounts} onChange={v => onSearchChange(v.target.value)} isClearable={true} incremental={true} />
        <EuiSpacer />
        {accounts && accounts.length > 0 && <EuiInMemoryTable loading={isLoadingAccounts} items={accounts} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default AccountsPage
