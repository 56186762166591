import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { EuiBadge, EuiButtonEmpty, EuiFormRow, EuiIcon, EuiInMemoryTable } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { EuiInMemoryTableProps } from '@elastic/eui/src/components/basic_table/in_memory_table'

import { Activation, CampaignType, useOpsClient } from 'api'
import { CampaignTypeFilterGroup } from 'features/advisorDashboard/components/CampaignTypeFilterGroup'
import history from 'services/HistoryService'
import { insertSpaces } from 'utils/EnumToFriendly'

export const LiveCampaignsPage: React.FC = () => {
  const opsClient = useOpsClient()
  const [isLoadingRecentActivations, setIsLoadingRecentActivations] = useState(false)
  const [recentActivations, setRecentActivations] = useState<Activation[]>([])
  const [activeCampaignTypeFilters, setActiveCampaignTypeFilters] = React.useState<CampaignType[]>([])
  const [filteredRecentActivations, setFilteredRecentActivations] = useState<Activation[]>([])
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    if (activeCampaignTypeFilters.length === 0) {
      setFilteredRecentActivations(recentActivations)
    } else {
      setFilteredRecentActivations(recentActivations.filter(a => activeCampaignTypeFilters.includes(a.campaignType as CampaignType)))
    }
  }, [activeCampaignTypeFilters, recentActivations])

  const onCampaignTypeFilterClick = (campaignType: CampaignType) => {
    if (activeCampaignTypeFilters.includes(campaignType)) {
      setActiveCampaignTypeFilters(activeCampaignTypeFilters.filter(f => f !== campaignType))
    } else {
      setActiveCampaignTypeFilters([...activeCampaignTypeFilters, campaignType])
    }
  }

  const onResetCampaignTypeFilters = () => {
    setActiveCampaignTypeFilters([])
  }

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoadingRecentActivations(true)

      opsClient.getRecentActivations().then(activations => {
        if (isMounted) {
          setRecentActivations(activations)
          setIsLoadingRecentActivations(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient])

  const recentActivationColumns: Array<EuiBasicTableColumn<Activation>> = [
    {
      name: 'Organization',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/organizations/${c.organizationId}`)} size='s'>
          {c.organizationName}
        </EuiButtonEmpty>
      ),
      sortable: (c: Activation) => c.organizationName
    },
    {
      name: 'Account',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/accounts/${c.accountId}`)} size='s'>
          {c.accountName}
        </EuiButtonEmpty>
      ),
      sortable: (c: Activation) => c.accountName
    },
    {
      name: 'Campaign',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/campaigns/${c.campaignId}`)} size='s'>
          {c.isInBundle && !c.isMatching ? (
            <>
              <EuiBadge color='default'>{insertSpaces(c.bundleType)}</EuiBadge>{' '}
            </>
          ) : (
            ''
          )}
          {c.isMatching ? (
            <>
              <EuiBadge color='success'>Matching</EuiBadge>{' '}
            </>
          ) : (
            ''
          )}
          {c.campaignName}
        </EuiButtonEmpty>
      ),
      sortable: (c: Activation) => c.campaignName
    },
    {
      name: `Type`,
      field: 'campaignType',
      width: '150',
      sortable: (c: Activation) => c.campaignType
    },
    {
      name: `Budget`,
      render: (c: Activation) => formatter.format(c.budget) + ' ' + c.budgetFrequency.toLowerCase(),
      width: '150'
    },
    {
      name: `Activation Date`,
      render: (c: Activation) => moment.utc(c.activated).utc().tz(moment.tz.guess()).format('L'),
      width: '100'
    },
    {
      name: 'IA?',
      width: '75',
      render: (c: Activation) => (
        <React.Fragment>
          {c.isFirstTime && <EuiIcon type='checkInCircleFilled' />} {c.isSameDay && <EuiIcon type='checkInCircleFilled' color='subdued' />}
        </React.Fragment>
      )
    }
  ]

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  const sorting: EuiInMemoryTableProps<Activation>['sorting'] = {
    sort: {
      field: 'activated',
      direction: 'desc'
    }
  }

  return (
    <React.Fragment>
      <EuiFormRow>
        <CampaignTypeFilterGroup campaignTypeItems={recentActivations.map(a => ({ campaignType: a.campaignType as CampaignType }))} activeFilters={activeCampaignTypeFilters} onFilterClicked={onCampaignTypeFilterClick} onResetFilters={onResetCampaignTypeFilters} />
      </EuiFormRow>
      <EuiInMemoryTable sorting={sorting} loading={isLoadingRecentActivations} items={filteredRecentActivations} columns={recentActivationColumns} pagination={pagination} />
    </React.Fragment>
  )
}
