import React from 'react'

import { AgencyOrganization, AgencySalesStage, AgencySalesStagePrecedence } from 'api'
import { AcDomain, NetworkType } from 'api/entities/Domains'
import { AcFilterGroup } from 'components/ACTools/AcFilterGroup'

export interface IShowcaseDomainFilterGroup {
  domains: AcDomain[]
  onFilterClicked?: (salesStage: NetworkType) => void
  onResetFilters?: () => void
  activeFilters: NetworkType[]
}

export const ShowcaseDomainFilterGroup: React.FC<IShowcaseDomainFilterGroup> = props => {
  const onFilterClick = (networkType: string) => {
    props.onFilterClicked?.(networkType as NetworkType)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }

  return <AcFilterGroup items={props.domains} getGroup={(domain: AcDomain) => domain.networkType} activeFilters={props.activeFilters} onFilterClicked={onFilterClick} onResetFilters={onTotalClick} />
}
