import React from 'react'

import { EuiPanel, EuiStat } from '@elastic/eui'

export interface IAcStatPanel {
  title: string
  description: string
  titleSize?: 's' | 'xxxs' | 'xxs' | 'xs' | 'm' | 'l'
  titleColor?: 'default' | 'subdued' | 'primary' | 'secondary' | 'accent' | 'warning' | 'danger' | 'success'
}

export const AcStatPanel: React.FC<IAcStatPanel> = props => {
  return (
    <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
      <EuiStat title={props.title} description={props.description} titleSize={props.titleSize} titleColor={props.titleColor} />
    </EuiPanel>
  )
}
