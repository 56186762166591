import React from 'react'

import { CommissionEvent, CommissionTriggerType } from 'api/entities/SalesAgent'
import { AcFilterGroup } from 'components/ACTools/AcFilterGroup'

export interface ICommissionDetailsFilterGroup {
  commissionEvents: CommissionEvent[]
  onFilterClicked?: (triggerType: CommissionTriggerType) => void
  onResetFilters?: () => void
  activeFilters: CommissionTriggerType[]
}

export const CommissionEventsFilterGroup: React.FC<ICommissionDetailsFilterGroup> = props => {
  const onFilterClick = (triggerType: string) => {
    props.onFilterClicked?.(triggerType as CommissionTriggerType)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }

  return <AcFilterGroup hideCounts items={props.commissionEvents} getGroup={(event: CommissionEvent) => event.commissionTriggerType} activeFilters={props.activeFilters} onFilterClicked={onFilterClick} onResetFilters={onTotalClick} />
}
