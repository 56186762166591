import { Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiPortal, EuiSelect, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { useOpsClient } from 'api/'
import history from 'services/HistoryService'

interface FormValues {
  name: string
  type: string
}

const BillboardCreateFlyOut: React.FC = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const opsClient = useOpsClient()

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const closeFlyout = () => setIsFlyoutVisible(false)

  const billboardSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the Billboard name'),
    type: Yup.string().required()
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.createBillboard({ name: values.name, type: values.type }).then(result => {
      setSubmitting(false)
      setIsFlyoutVisible(false)
      history.push(`/assets/billboards/${result.id}`)
    })
  }

  const types: EuiSelectOption[] = [
    { value: 'Specific', text: 'Specific' },
    { value: 'General', text: 'General' }
  ]

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={{ name: '', type: 'Specific' }} validationSchema={billboardSchema} onSubmit={doSubmit}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Billboard</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Type' isInvalid={!!props.errors.type} error={props.errors.type}>
                    <EuiSelect name='type' isInvalid={!!props.errors.type} options={types} value={props.values.type} onChange={e => props.setFieldValue('type', e.target.value)} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiButton id='createBillboardFlyOut' onClick={showFlyout} fill>
        Create
      </EuiButton>
      {flyout}
    </React.Fragment>
  )
}

export default BillboardCreateFlyOut
