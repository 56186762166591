import { useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Contact, Organization } from 'api'
import { Note } from 'api/entities/Notes'
import { usePostContactMutation, usePutContactMutation } from 'api/rtkQueryApi/opsApi/contactsApi'
import { useContactAddNoteMutation, useDeleteNoteMutation, useLazyGetNotesForIdQuery, useUpdateNoteContentMutation } from 'api/rtkQueryApi/opsApi/noteApi'
import { AcNote } from 'components/ACTools/AcNote'
import { TimeZoneSelector } from 'components/Basic/TimeZoneSelector'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import ContactMethodTable from 'features/customers/contacts/ContactMethod/ContactMethodTable'
import { ContactActionKind, useContactReducer } from 'features/customers/contacts/useContactReducer'

const defaultContact = (organizationId: string): Contact => ({
  id: '',
  organizationId: organizationId,
  position: '',
  firstName: '',
  lastName: '',
  contactMethods: [],
  timeZone: 'America/New_York',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  changes: [],
  customFields: []
})

interface ContactPaneParams {
  organization: Organization
  updateSelectedContact: (contact: Contact) => void
  initialContact?: Contact
}

export const ContactsPane: React.FC<ContactPaneParams> = ({ organization, updateSelectedContact, initialContact }) => {
  const [postContact, postContactResult] = usePostContactMutation()
  const [getContactNotes, contactNotesResults] = useLazyGetNotesForIdQuery()
  const [putContact, putContactResult] = usePutContactMutation()
  const [deleteContactNote] = useDeleteNoteMutation()
  const [updateNote] = useUpdateNoteContentMutation()
  const [saveContactNote] = useContactAddNoteMutation()
  const [contact, dispatchContact] = useContactReducer(initialContact ?? defaultContact(organization.organizationId))
  const [addingNote, setAddingNote] = useState(false)
  const [updatedNotes, setUpdatedNotes] = useState<Note[]>([])
  const [showNoteDeleteConfirm, setShowNoteDeleteConfirm] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState<Note>()

  const contactSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    zipCode: Yup.string().matches(/^\d{5}$/, 'Must be 5 digits'),
    timeZone: Yup.string().required('Required')
  })

  const formik = useFormik({
    initialValues: _.cloneDeep(initialContact) ?? defaultContact(organization.organizationId),
    enableReinitialize: true,
    validationSchema: contactSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: values => {
      const contactToSave = _.cloneDeep(values)
      contactToSave.contactMethods = contact.contactMethods

      if (contactToSave.id) {
        putContact(contactToSave)
      } else {
        postContact(contactToSave)
      }

      dispatchContact({ type: ContactActionKind.CONTACT, payload: { ...contactToSave } })
    }
  })

  useEffect(() => {
    formik.resetForm()
    dispatchContact({ type: ContactActionKind.CONTACT, payload: { ...initialContact } })
    if (initialContact?.id) {
      getContactNotes(initialContact.id)
    }
  }, [initialContact])

  useEffect(() => {
    if (!postContactResult.data) {
      return
    }
    updateSelectedContact({ ...postContactResult.data })
    dispatchContact({ type: ContactActionKind.CONTACT, payload: { ...postContactResult.data } })
  }, [postContactResult])

  useEffect(() => {
    if (!putContactResult.data) {
      return
    }

    for (let note of updatedNotes) {
      updateNote({ followUp: note.followUp, text: note.text, name: note.name, followUpDate: note.followUpDate, noteId: note.id })
    }
    updateSelectedContact({ ...putContactResult.data })
    dispatchContact({ type: ContactActionKind.CONTACT, payload: { ...putContactResult.data } })
  }, [putContactResult])

  const addNoteClick = () => {
    setAddingNote(true)
  }

  const cancelNoteClick = () => {
    setAddingNote(false)
  }

  const saveNoteClick = (note: Note) => {
    if (initialContact?.id) {
      setAddingNote(false)
      saveContactNote({
        contactId: initialContact.id,
        secondaryReferenceIds: [],
        followUpDate: note.followUpDate,
        followUp: note.followUp,
        text: note.text,
        name: note.name
      })
    }
  }

  const onNoteUpdated = (note: Note) => {
    if (note && note.id) {
      const foundNote = updatedNotes.find(n => note.id === n.id)
      if (foundNote) {
        setUpdatedNotes(notes => {
          const clone = [...notes.filter(n => n.id !== note.id)]
          clone.push(note)
          return clone
        })
      } else {
        setUpdatedNotes([...updatedNotes, note])
      }
    }
  }

  const onConfirmNoteDelete = () => {
    if (noteToDelete?.id) {
      deleteContactNote(noteToDelete.id)
      setUpdatedNotes([...updatedNotes.filter(n => n.id !== noteToDelete?.id)])
    }
    setShowNoteDeleteConfirm(false)
  }

  const onCancelNoteDelete = () => {
    setShowNoteDeleteConfirm(false)
  }

  const onNoteDeleteClick = (note: Note) => {
    setNoteToDelete(note)
    setShowNoteDeleteConfirm(true)
  }

  return (
    <EuiPanel>
      <EuiTitle size={'s'}>
        <h2>{initialContact ? `Update ${initialContact.firstName} ${initialContact.lastName}` : 'Create New Contact'}</h2>
      </EuiTitle>

      <EuiSpacer />

      <EuiForm onBlur={formik.handleBlur}>
        <EuiFormRow>
          <EuiButton onClick={() => formik.handleSubmit()} fill>
            {'Save'}
          </EuiButton>
        </EuiFormRow>

        <EuiSpacer />

        <EuiFormRow label='First Name' isInvalid={!!formik.errors.firstName} error={formik.errors.firstName}>
          <EuiFieldText name='firstName' value={formik.values.firstName} onChange={formik.handleChange} isInvalid={!!formik.errors.firstName} />
        </EuiFormRow>
        <EuiFormRow label='Last Name' isInvalid={!!formik.errors.lastName} error={formik.errors.lastName}>
          <EuiFieldText name='lastName' value={formik.values.lastName} onChange={formik.handleChange} isInvalid={!!formik.errors.lastName} />
        </EuiFormRow>
        <EuiFormRow label='Position'>
          <EuiFieldText name='position' value={formik.values.position} onChange={formik.handleChange} />
        </EuiFormRow>
        <EuiFormRow label='Address'>
          <EuiFieldText name='address' value={formik.values.address} onChange={formik.handleChange} />
        </EuiFormRow>
        <EuiFormRow label='City'>
          <EuiFieldText name='city' value={formik.values.city} onChange={formik.handleChange} />
        </EuiFormRow>
        <EuiFormRow label='State'>
          <EuiFieldText name='state' value={formik.values.state} onChange={formik.handleChange} />
        </EuiFormRow>
        <EuiFormRow label='Zip Code' isInvalid={!!formik.errors.zipCode} error={formik.errors.zipCode}>
          <EuiFieldText name='zipCode' value={formik.values.zipCode} onChange={formik.handleChange} isInvalid={!!formik.errors.zipCode} />
        </EuiFormRow>
        <EuiFormRow label='Time Zone' isInvalid={!!formik.errors.timeZone} error={formik.errors.timeZone}>
          <TimeZoneSelector name='timeZone' value={formik.values.timeZone} onChange={formik.handleChange} isInvalid={!!formik.errors.timeZone} />
        </EuiFormRow>
      </EuiForm>

      <EuiSpacer size='xxl' />

      <ContactMethodTable contactMethods={contact.contactMethods} dispatchContact={dispatchContact} />
      {initialContact?.id && (
        <React.Fragment>
          <EuiSpacer size='xxl' />
          <EuiFormRow fullWidth>
            {addingNote ? (
              <EuiButton
                color={'danger'}
                onClick={() => {
                  cancelNoteClick()
                }}>
                Cancel
              </EuiButton>
            ) : (
              <EuiButton
                onClick={() => {
                  addNoteClick()
                }}>
                Add Note
              </EuiButton>
            )}
          </EuiFormRow>
          {addingNote && (
            <EuiFormRow fullWidth>
              <AcNote editable buttonText={'Add'} onNoteSave={saveNoteClick} hideDelete />
            </EuiFormRow>
          )}
          {contactNotesResults?.data && (
            <EuiFormRow fullWidth>
              <React.Fragment>
                {contactNotesResults.data.map(
                  note =>
                    (
                      <AcNote
                        key={note.id}
                        hideSave
                        editable
                        note={note}
                        onNoteChange={onNoteUpdated}
                        onNoteDelete={(note: Note) => {
                          onNoteDeleteClick(note)
                        }}
                      />
                    ) ?? []
                )}
              </React.Fragment>
            </EuiFormRow>
          )}
          {showNoteDeleteConfirm && (
            <AcConfirmModal
              onConfirm={() => {
                onConfirmNoteDelete()
              }}
              onCancel={() => {
                onCancelNoteDelete()
              }}
              title={'Delete Note'}
              message={'Are you sure you would like to delete this note?'}
              confirmButtonText={'Delete'}
            />
          )}
        </React.Fragment>
      )}
    </EuiPanel>
  )
}
