import moment from 'moment/moment'
import React from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonEmpty, EuiFormRow, EuiIcon, EuiInMemoryTable, EuiLink, EuiSelect, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { AgencyCategory, AgencyDetails, AgencyOrganization, AgencySalesStage, AgencySalesStagePrecedence, AgencyStatus, SalesContactedMethodType } from 'api'
import { useSetSalesAgencyDetailsContactedMutation, useSetSalesAgencyDetailsStageMutation } from 'api/rtkQueryApi/opsApi/agencyDetailsApi'
import { RootState } from 'app/rootReducer'
import { AcActionsPopover } from 'components/Basic/AcActionsPopover'
import { AgencyCohortSelect } from 'features/agencyManagerDashboard/components/AgencyCohortSelect'
import { AgencyDetailsFlyout } from 'features/agencyManagerDashboard/components/AgencyDetailsFlyout'
import { AgencyDetailsLastContactedMethod } from 'features/agencyManagerDashboard/components/AgencyDetailsLastContactedMethod'
import { CohortStatusSymbol } from 'features/agencyManagerDashboard/components/CohortStatusSymbol'
import { CommunicationTypeButtonGroup } from 'features/agencyManagerDashboard/components/CommunicationTypeButtonGroup'
import { SalesStageSelect } from 'features/agencyManagerDashboard/components/SalesStageSelect'

export interface IAgencyGrid {
  agencies: AgencyOrganization[]
  isLoading?: boolean
  onAgencyDetailsChanged?: (organizationId: string, agencyDetails: AgencyDetails) => void
  onRevertAgency?: (organizationId: string) => void
}

export const AgencyGrid: React.FC<IAgencyGrid> = props => {
  const { userProfile } = useSelector((state: RootState) => state.app)
  const isAgencyManager = userProfile?.roles.includes('adcritter-ops-agencymanager') ?? false
  const canUnAgency = isAgencyManager || (userProfile?.roles.includes('adcritter-ops-agencymanagerremoval') ?? false)
  const [setAgencyContacted] = useSetSalesAgencyDetailsContactedMutation()
  const [setAgencySalesStage] = useSetSalesAgencyDetailsStageMutation()
  const [showAgencyDetails, setShowAgencyDetails] = React.useState(false)
  const [selectedAgencyOrganization, setSelectedAgencyOrganization] = React.useState<AgencyOrganization>()

  const agencyCategoryOptions = [
    { value: AgencyCategory.Premier, text: AgencyCategory.Premier },
    { value: AgencyCategory.HighVelocity, text: 'Approved' },
    { value: AgencyCategory.Growth, text: 'Office Hours' },
    { value: AgencyCategory.Suspected, text: AgencyCategory.Suspected }
  ]

  const onAgencyCategoryChange = (organizationId: string, category: AgencyCategory, details?: AgencyDetails) => {
    if (props.onAgencyDetailsChanged) {
      const newDetails: AgencyDetails = details ? { ...details, agencyCategory: category } : { agencyCategory: category, agencyStatus: AgencyStatus.Active, agencyDetailsChanges: [], organizationId: organizationId, id: '' }
      props.onAgencyDetailsChanged(organizationId, newDetails)
    }
  }

  const onRevertAgency = (organizationId: string) => {
    if (props.onRevertAgency) {
      props.onRevertAgency(organizationId)
    }
  }

  const toggleArchiveAgency = (organizationId: string, details?: AgencyDetails) => {
    if (props.onAgencyDetailsChanged) {
      const newDetails: AgencyDetails = details ? { ...details, agencyStatus: details.agencyStatus === AgencyStatus.Active ? AgencyStatus.Archived : AgencyStatus.Active } : { agencyCategory: AgencyCategory.Suspected, agencyStatus: AgencyStatus.Archived, agencyDetailsChanges: [], organizationId: organizationId, id: '' }
      props.onAgencyDetailsChanged(organizationId, newDetails)
    }
  }

  const onCallClick = (agencyDetailsId: string) => {
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Call, salesAgencyDetailsId: agencyDetailsId })
  }
  const onVoicemailClick = (agencyDetailsId: string) => {
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Voicemail, salesAgencyDetailsId: agencyDetailsId })
  }
  const onTextClick = (agencyDetailsId: string) => {
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Text, salesAgencyDetailsId: agencyDetailsId })
  }

  const onEmailClick = (agencyDetailsId: string) => {
    setAgencyContacted({ contactMethodType: SalesContactedMethodType.Email, salesAgencyDetailsId: agencyDetailsId })
  }

  const onSalesStageChange = (salesStage: AgencySalesStage, salesAgencyDetailsId: string) => {
    setAgencySalesStage({ salesAgencyDetailsId: salesAgencyDetailsId, salesStage: salesStage, description: '' })
  }

  const onDetailsClick = (agencyOrg: AgencyOrganization) => {
    setSelectedAgencyOrganization(agencyOrg)
    setShowAgencyDetails(true)
  }
  const onDetailsClose = () => {
    setSelectedAgencyOrganization(undefined)
    setShowAgencyDetails(false)
  }

  const columns: Array<EuiBasicTableColumn<AgencyOrganization>> = [
    {
      name: 'Agency',
      render: (o: AgencyOrganization) => (
        <EuiLink href={`/customers/organizations/${o.organization.organizationId}`} target='_blank'>
          {o.organization.organizationName}
        </EuiLink>
      ),
      sortable: (o: AgencyOrganization) => o.organization.organizationName
    },
    {
      name: 'Name',
      render: (o: AgencyOrganization) => `${o.organization.firstName ?? ''} ${o.organization.lastName ?? ''}`,
      sortable: (o: AgencyOrganization) => `${o.organization.firstName ?? ''} ${o.organization.lastName ?? ''}`
    },
    {
      name: 'Email',
      render: (o: AgencyOrganization) => (
        <EuiLink href={`mailto:${o.organization.billingEmail}`} target='_top'>
          {o.organization.billingEmail}
        </EuiLink>
      ),
      sortable: (o: AgencyOrganization) => o.organization.billingEmail
    },
    {
      name: 'Phone',
      render: (o: AgencyOrganization) => o.organization.phoneNumber,
      sortable: (o: AgencyOrganization) => o.organization.phoneNumber
    },
    {
      name: 'Signup Date',
      render: (o: AgencyOrganization) => moment(o.organization.created).format('MM/DD/YYYY'),
      sortable: (o: AgencyOrganization) => moment(o.organization.created).valueOf()
    },
    {
      name: 'Category',
      render: (o: AgencyOrganization) =>
        isAgencyManager ? (
          <EuiSelect
            id={`agency-category-${o.organization.organizationId}`}
            value={o.agencyDetails?.agencyCategory ?? AgencyCategory.Suspected}
            onChange={e => {
              onAgencyCategoryChange(o.organization.organizationId, e.target.value as AgencyCategory, o.agencyDetails)
            }}
            options={agencyCategoryOptions}
          />
        ) : (
          o.agencyDetails?.agencyCategory ?? AgencyCategory.Suspected
        )
    },
    ...(props.agencies.every(a => a.agencyDetails.agencyCategory !== AgencyCategory.Suspected)
      ? [
          {
            name: 'Recruitment Stage',
            render: (o: AgencyOrganization) => <SalesStageSelect selectedSalesStage={o.agencyDetails.agencyStageDetails?.salesStage ?? AgencySalesStage.T} onSalesStageChange={stage => onSalesStageChange(stage, o.agencyDetails.id)} />,
            sortable: (o: AgencyOrganization) => (o.agencyDetails.agencyStageDetails?.salesStage ? AgencySalesStagePrecedence.indexOf(o.agencyDetails.agencyStageDetails?.salesStage) : -1)
          },
          {
            name: 'Cohort',
            render: (o: AgencyOrganization) => <AgencyCohortSelect agencyDetails={o.agencyDetails} />,
            sortable: (o: AgencyOrganization) => o.agencyDetails.cohortName ?? ''
          },
          {
            name: 'Cohort Status',
            render: (o: AgencyOrganization) => o.agencyDetails.cohortId && <CohortStatusSymbol cohortStatus={o.agencyDetails.cohortStatus} />,
            sortable: (o: AgencyOrganization) => o.agencyDetails.cohortStatus ?? ''
          }
        ]
      : []),
    {
      name: 'Contacted Method',
      render: (o: AgencyOrganization) => <AgencyDetailsLastContactedMethod contactMethodType={o.agencyDetails?.lastContactedMethodType} />,
      sortable: (o: AgencyOrganization) => o.agencyDetails?.lastContactedMethodType ?? -1
    },
    {
      field: 'agencyDetails.lastContacted',
      name: 'Last Contacted',
      render: (lastContacted: string) => {
        return lastContacted ? moment(lastContacted).format('MM/DD/YYYY') : ''
      },
      sortable: (o: AgencyOrganization) => (o.agencyDetails?.lastContacted ? moment(o.agencyDetails.lastContacted).valueOf() : Number.MAX_SAFE_INTEGER)
    },
    {
      name: ' ',
      render: (o: AgencyOrganization) => (o.agencyDetails.agencyStatus === AgencyStatus.Archived ? <EuiIcon type={'database'} color={'subdued'} title={'Archived'} /> : <React.Fragment />),
      sortable: (o: AgencyOrganization) => o.agencyDetails.agencyStatus,
      width: '40px'
    },
    {
      name: ' ',
      render: (o: AgencyOrganization) => <CommunicationTypeButtonGroup onVoicemailClick={() => onVoicemailClick(o.agencyDetails.id)} onCallClick={() => onCallClick(o.agencyDetails.id)} onEmailClick={() => onEmailClick(o.agencyDetails.id)} onTextClick={() => onTextClick(o.agencyDetails.id)} />
    },
    {
      name: '',
      render: (o: AgencyOrganization) => (
        <AcActionsPopover>
          <EuiFormRow fullWidth>
            <EuiButtonEmpty iconType={'refresh'} color={'danger'} onClick={() => onRevertAgency(o.organization.organizationId)} disabled={!canUnAgency}>
              Un-Agency
            </EuiButtonEmpty>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiButtonEmpty iconType={'database'} color={'success'} onClick={() => toggleArchiveAgency(o.organization.organizationId, o.agencyDetails)} disabled={!isAgencyManager}>
              {o.agencyDetails?.agencyStatus === AgencyStatus.Archived ? 'Restore' : 'Archive'}
            </EuiButtonEmpty>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiButtonEmpty iconType={'document'} aria-label='Details' size={'s'} onClick={() => onDetailsClick(o)} color='primary'>
              Details
            </EuiButtonEmpty>
          </EuiFormRow>
        </AcActionsPopover>
      ),
      width: '60px'
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'agencyDetails.lastContacted',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiInMemoryTable loading={props.isLoading} items={props.agencies} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
      {showAgencyDetails && selectedAgencyOrganization && <AgencyDetailsFlyout agencyOrg={selectedAgencyOrganization} onClose={onDetailsClose} />}
    </React.Fragment>
  )
}
