import React from 'react'

import { AdvancedTargeting, Campaign } from 'api'
import { useUpdateAdvancedTargetingMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { RetargetingTab } from 'features/customers/campaigns/targeting/RetargetingTab'

export interface ICampaignRetargetingTab {
  campaign: Campaign
}

export const CampaignRetargetingTab: React.FC<ICampaignRetargetingTab> = props => {
  const [saveTargeting, saveTargetingQuery] = useUpdateAdvancedTargetingMutation()
  const onTargetingSaved = (targeting: AdvancedTargeting) => {
    saveTargeting({
      campaignId: props.campaign.campaignId,
      targeting: {
        advancedTargetingType: 'Retarget',
        locationType: 'USA',
        states: [],
        dmas: [],
        cities: [],
        zips: [],
        geoFences: [],
        geoCircles: [],
        geoRectangles: [],
        geoPolygons: [],
        audiences: [],
        audienceType: 'Retargeting',
        ageRanges: [],
        genders: [],
        householdIncomes: [],
        uploadType: null,
        uploadedData: [],
        retargetingType: targeting.audienceType === 'Retargeting' ? targeting.retargetingType : null,
        urlParts: targeting.urlParts,
        retargetingCampaignIds: targeting.retargetingCampaignIds,
        keywords: [],
        negativeKeywords: [],
        inventorySettings: null,
        deviceSettings: null,
        frequency: null,
        frequencyUnit: null,
        dayParts: targeting.dayParts
      }
    })
  }

  return <RetargetingTab campaign={props.campaign} onCampaignSave={onTargetingSaved} isLoading={saveTargetingQuery.isLoading} />
}
