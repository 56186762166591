import React from 'react'

import { EuiOverlayMask } from '@elastic/eui'

import { CommissionEvent, SalesAgent } from 'api/entities/SalesAgent'
import { useDeleteCommissionEventMutation } from 'api/rtkQueryApi/opsApi/salesApi'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'

export interface ICommissionEventDeleteModal {
  commissionEvent: CommissionEvent
  salesAgent: SalesAgent
  onClose: () => void
}

export const CommissionEventDeleteModal: React.FC<ICommissionEventDeleteModal> = props => {
  const [deleteCommissionEvent, deleteCommissionEventQuery] = useDeleteCommissionEventMutation()

  const onConfirm = () => {
    deleteCommissionEvent({ salesAgentId: props.salesAgent.id, commissionEventId: props.commissionEvent.id }).then(() => {
      props.onClose()
    })
  }

  const onCancel = () => {
    props.onClose()
  }

  return (
    <EuiOverlayMask>
      <AcConfirmModal onConfirm={onConfirm} onCancel={onCancel} title={'Delete Commission Event'} message={'Are you sure you would like to delete this event? (This will not effect any customer transactions)'} confirmButtonText={'Delete'} isLoading={deleteCommissionEventQuery.isLoading} />
    </EuiOverlayMask>
  )
}
