import { Moment } from 'moment'

import { CommissionEvent, SalesAgent } from 'api/entities/SalesAgent'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

export interface CreateCommissionEventRequest {
  commissionTriggerName: string
  salesAgentId: string
  commissionItemId: string
  eventDate: Moment
  saleAmount: number
  commissionAmount: number
  commissionPercentage: number
  commissionBonus: number
  note: string
}

const salesTag = opsApi.enhanceEndpoints({ addTagTypes: ['SalesAgents', 'SalesAgent', 'CommissionEvents'] })

const salesApi = salesTag.injectEndpoints({
  endpoints: build => ({
    getAllSalesAgents: build.query<SalesAgent[], void>({
      query: () => `salesAgents`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'SalesAgents' }]
    }),
    getSalesAgent: build.query<SalesAgent, string>({
      query: salesAgentId => `salesAgents/${salesAgentId}/salesAgent`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, salesAgentId) => [{ type: 'SalesAgent', id: salesAgentId }, { type: 'SalesAgents' }]
    }),
    createSalesAgent: build.mutation<SalesAgent, SalesAgent>({
      query: request => ({
        url: `salesAgents`,
        method: 'POST',
        body: request
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'SalesAgents' }]
    }),
    updateCommissionEvent: build.mutation<SalesAgent, { salesAgentId: string; commissionEventId: string; saleAmount: number; commissionAmount: number; commissionPercentage: number; commissionBonus: number }>({
      query: ({ salesAgentId, commissionEventId, saleAmount, commissionAmount, commissionPercentage, commissionBonus }) => ({
        url: `salesAgents/${salesAgentId}/updateCommissionEvent`,
        method: 'POST',
        body: { commissionEventId, saleAmount, commissionAmount, commissionPercentage, commissionBonus }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { salesAgentId }) => [{ type: 'CommissionEvents', id: salesAgentId }, { type: 'CommissionEvents' }]
    }),
    createCommissionEvent: build.mutation<SalesAgent, CreateCommissionEventRequest>({
      query: request => ({
        url: `salesAgents/${request.salesAgentId}/addCommissionEvent`,
        method: 'POST',
        body: {
          commissionTriggerName: request.commissionTriggerName,
          commissionItemId: request.commissionItemId,
          eventDate: request.eventDate,
          saleAmount: request.saleAmount,
          commissionAmount: request.commissionAmount,
          commissionPercentage: request.commissionPercentage,
          commissionBonus: request.commissionBonus
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'CommissionEvents', id: request.salesAgentId }, { type: 'CommissionEvents' }]
    }),
    deleteCommissionEvent: build.mutation<CommissionEvent, { salesAgentId: string; commissionEventId: string }>({
      query: ({ salesAgentId, commissionEventId }) => ({
        url: `salesAgents/${salesAgentId}/deleteCommissionEvent`,
        method: 'POST',
        body: { commissionEventId }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { salesAgentId }) => [{ type: 'CommissionEvents', id: salesAgentId }, { type: 'CommissionEvents' }]
    }),
    creditBundleToSalesAgent: build.mutation<SalesAgent, { salesAgentId: string; bundleId: string }>({
      query: ({ salesAgentId, bundleId }) => ({
        url: `salesAgents/${salesAgentId}/creditBundle`,
        method: 'POST',
        body: { bundleId }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { salesAgentId }) => [{ type: 'SalesAgent', id: salesAgentId }, { type: 'SalesAgents' }]
    }),
    getCommissionEventsForSalesAgent: build.query<CommissionEvent[], { salesAgentId: string }>({
      query: request => `salesAgents/${request.salesAgentId}/commissionEvents`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'CommissionEvents', id: request.salesAgentId }]
    }),
    getCommissionEvents: build.query<CommissionEvent[], void>({
      query: () => `salesAgents/commissionEvents`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'CommissionEvents' }]
    })
  }),
  overrideExisting: false
})

export const { useGetAllSalesAgentsQuery, useCreateSalesAgentMutation, useGetSalesAgentQuery, useCreateCommissionEventMutation, useUpdateCommissionEventMutation, useDeleteCommissionEventMutation, useCreditBundleToSalesAgentMutation, useGetCommissionEventsForSalesAgentQuery, useGetCommissionEventsQuery } = salesApi
