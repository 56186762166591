import moment from 'moment/moment'
import React from 'react'

import { EuiButton, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { CampaignMonitor } from 'api/entities/Monitor'
import { useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'

export interface ICampaignMonitorDetails {
  monitor: CampaignMonitor
  onResolveClicked?: (monitor: CampaignMonitor) => void
  isSubmitting?: boolean
  hideOrganization?: boolean
  hideCampaign?: boolean
}

export const CampaignMonitorDetails: React.FC<ICampaignMonitorDetails> = props => {
  const getCampaignQuery = useGetCampaignQuery(props.monitor.campaignId)
  const monitor = props.monitor

  const onResolveClicked = () => {
    props.onResolveClicked?.(monitor)
  }

  return (
    <React.Fragment>
      {!props.hideOrganization && (
        <React.Fragment>
          <EuiCopy textToCopy={monitor.organizationId}>
            {copy => (
              <EuiTitle>
                <h2 onClick={copy}>{monitor.organizationName}</h2>
              </EuiTitle>
            )}
          </EuiCopy>
          <EuiFormRow>
            <EuiLink href={`/customers/organizations/${monitor.organizationId}`} target={'_blank'}>
              View Organization
            </EuiLink>
          </EuiFormRow>
          <EuiSpacer />
        </React.Fragment>
      )}
      {!props.hideCampaign && (
        <React.Fragment>
          <EuiCopy textToCopy={getCampaignQuery.data?.campaignId ?? ''}>
            {copy => (
              <EuiTitle>
                <h2 onClick={copy}>{getCampaignQuery.isLoading || getCampaignQuery.isFetching ? '' : getCampaignQuery.data?.campaignName ?? ''}</h2>
              </EuiTitle>
            )}
          </EuiCopy>
          {getCampaignQuery.data?.campaignId && (
            <EuiFormRow>
              <EuiLink href={`/customers/campaigns/${getCampaignQuery.data?.campaignId}`} target={'_blank'}>
                View Campaign
              </EuiLink>
            </EuiFormRow>
          )}
        </React.Fragment>
      )}
      <EuiHorizontalRule />
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiTitle size={'xxs'}>
            <h2>{monitor.message}</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiFormRow label={'Created By'}>
            <EuiText size={'s'}>{monitor.createdBy}</EuiText>
          </EuiFormRow>
          <EuiFormRow label={'Created At'}>
            <EuiText size={'s'}>{moment(monitor.createdAt).format('MM/DD/yyyy hh:mm A')}</EuiText>
          </EuiFormRow>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={onResolveClicked} isLoading={props.isSubmitting}>
                Resolve
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
