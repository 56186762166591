import React from 'react'

import { EuiPageTemplate } from '@elastic/eui'

const CommunicationPage: React.FC = () => {
  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='email' pageTitle='Communication' restrictWidth={false} />
      <EuiPageTemplate.Section restrictWidth={false}>nothing to see yet</EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default CommunicationPage
