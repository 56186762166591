import moment from 'moment/moment'
import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiSpacer, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CommissionTriggerType, SalesAgent } from 'api/entities/SalesAgent'
import { useGetAllSalesAgentsQuery, useGetCommissionEventsQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import { SalesAgentCreateFlyout } from 'features/salesManagerDashboard/SalesAgentCreateFlyout'
import { SalesAgentDetailsFlyout } from 'features/salesManagerDashboard/SalesAgentDetailsFlyout'

interface IAgentsOverviewGridItem {
  id: string
  name: string
  plansSoldMTD: number
  estimatedStartingMRR: number
  newMRR: number
  refundMTD: number
  revenueMTD: number
  netRevenueMTD: number
}

export const AgentsOverviewPage: React.FC = () => {
  const salesAgentsQuery = useGetAllSalesAgentsQuery()
  const commissionEventsQuery = useGetCommissionEventsQuery()
  const [filteredAgents, setFilteredAgents] = React.useState<SalesAgent[]>([])
  const [gridItems, setGridItems] = React.useState<IAgentsOverviewGridItem[]>([])
  const [showDetailsFlyout, setShowDetailsFlyout] = React.useState<boolean>(false)
  const [selectedAgent, setSelectedAgent] = React.useState<IAgentsOverviewGridItem>()
  const [showCreateAgentFlyout, setShowCreateAgentFlyout] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (salesAgentsQuery.data) {
      setFilteredAgents(salesAgentsQuery.data)
    }
  }, [salesAgentsQuery.data])

  React.useEffect(() => {
    if (!commissionEventsQuery.data) {
      return
    }
    const allEvents = commissionEventsQuery.data
    setGridItems(
      filteredAgents.map(agent => {
        const agentEvents = allEvents.filter(ce => ce.salesAgentId === agent.id)

        const lastMonthEvents = agentEvents.filter(ce => moment(ce.eventDate).month() === moment().subtract(1, 'month').month() && moment(ce.eventDate).year() === moment().subtract(1, 'month').year())
        const lastMonthRefunds = lastMonthEvents.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundleRefund)
        const lastMonthNotRefundedBundleEvents = lastMonthEvents.filter(ce => !lastMonthRefunds.some(r => r.commissionItemId === ce.commissionItemId))
        const estimatedStartingMRR = lastMonthNotRefundedBundleEvents.reduce((acc, ce) => acc + ce.saleAmount, 0)

        const thisMonthEvents = agentEvents.filter(ce => moment(ce.eventDate).month() === moment().month() && moment(ce.eventDate).year() === moment().year())
        return {
          id: agent.id,
          name: agent.name,
          plansSoldMTD: thisMonthEvents.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundlePurchase).length,
          newMRR: thisMonthEvents.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundlePurchase).reduce((acc, ce) => acc + ce.saleAmount, 0),
          estimatedStartingMRR: estimatedStartingMRR,
          refundMTD: thisMonthEvents.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundleRefund).reduce((acc, ce) => acc + ce.saleAmount, 0) * -1,
          revenueMTD: thisMonthEvents.filter(ce => ce.saleAmount >= 0).reduce((acc, ce) => acc + ce.saleAmount, 0),
          netRevenueMTD: thisMonthEvents.reduce((acc, ce) => acc + ce.saleAmount, 0)
        }
      })
    )
  }, [filteredAgents, commissionEventsQuery.data])

  const onDetailsClick = (agent: IAgentsOverviewGridItem) => {
    setSelectedAgent(agent)
    setShowDetailsFlyout(true)
  }

  const onDetailsClose = () => {
    setShowDetailsFlyout(false)
    setSelectedAgent(undefined)
  }

  const onCreateClick = () => {
    setShowCreateAgentFlyout(true)
  }

  const onCreateClose = () => {
    setShowCreateAgentFlyout(false)
  }

  const formatCurrency = (amount: number) => {
    return `$${Math.abs(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`
  }

  const columns: Array<EuiBasicTableColumn<IAgentsOverviewGridItem>> = [
    {
      name: 'Name',
      field: 'name',
      sortable: true
    },
    {
      name: 'Plans Sold MTD',
      field: 'plansSoldMTD',
      sortable: true
    },
    {
      name: 'Estimated Starting MRR',
      field: 'estimatedStartingMRR',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'New MRR',
      field: 'newMRR',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Revenue MTD',
      field: 'revenueMTD',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Refunds MTD',
      field: 'refundMTD',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Net Revenue MTD',
      field: 'netRevenueMTD',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: '',
      render: (agent: IAgentsOverviewGridItem) => (
        <EuiButtonEmpty
          size='xs'
          color='primary'
          onClick={() => {
            onDetailsClick(agent)
          }}>
          Details
        </EuiButtonEmpty>
      )
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'name',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size='s' color='primary' iconType='plus' onClick={onCreateClick}>
            Create Agent
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem />
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiInMemoryTable loading={salesAgentsQuery.isLoading} items={gridItems} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
      {showDetailsFlyout && selectedAgent && <SalesAgentDetailsFlyout salesAgentId={selectedAgent.id} onClose={onDetailsClose} />}
      {showCreateAgentFlyout && <SalesAgentCreateFlyout onClose={onCreateClose} onCreated={onCreateClose} />}
    </React.Fragment>
  )
}
