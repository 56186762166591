import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiIcon, EuiDatePicker, EuiButton, EuiDatePickerRange, EuiPageTemplate, EuiInMemoryTable, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { ActivationReport, useOpsClient } from 'api'

const ActivationsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [activations, setActivations] = useState<any>([])
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment())
  const opsClient = useOpsClient()

  function refresh() {
    setIsLoading(true)
    opsClient?.getActivationReport(start.format('yyyy-MM-DD'), end.format('yyyy-MM-DD')).then(c => {
      setActivations(c)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    refresh()
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<ActivationReport>> = [
    {
      name: 'Organization',
      field: 'organizationName',
      sortable: true
    },
    {
      name: 'First Name',
      field: 'firstName',
      sortable: true
    },
    {
      name: 'Last Name',
      field: 'lastName',
      sortable: true
    },
    {
      name: 'Email',
      field: 'email',
      sortable: true
    },
    {
      name: 'Phone',
      field: 'phone',
      sortable: true
    },
    {
      name: 'Signup Date',
      field: 'signupDate',
      render: (d: string) => moment(d).format('MM/DD/yyyy'),
      sortable: true
    },
    {
      name: 'Activation Date',
      field: 'activationDate',
      render: (d: string) => moment(d).format('MM/DD/yyyy'),
      sortable: true
    },
    {
      name: 'Initial Activation',
      field: 'isInitialActivation',
      sortable: true
    },
    {
      name: 'Campaign ID',
      field: 'campaignId',
      sortable: true
    },
    {
      name: 'Campaign',
      field: 'campaignName',
      sortable: true
    },
    {
      name: 'Campaign Type',
      field: 'campaignType',
      sortable: true
    },
    {
      name: 'Budget',
      field: 'budget',
      sortable: true
    },
    {
      name: 'Is Plan or Package',
      field: 'isBundle',
      render: (b: boolean) => (b ? 'Yes' : 'No'),
      sortable: true
    },
    {
      name: 'Method',
      field: 'method',
      sortable: true
    },
    {
      name: 'ACID',
      field: 'source',
      sortable: true
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'organizationName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header
        iconType='tableDensityNormal'
        pageTitle='Activations'
        restrictWidth={false}
        bottomBorder={true}
        rightSideItems={[
          <EuiButton id='update' fill={true} onClick={refresh} iconType='refresh' isLoading={isLoading}>
            Update
          </EuiButton>,
          <EuiDatePickerRange
            fullWidth={false}
            startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
            endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
          />
        ]}
      />
      <EuiPageTemplate.Section restrictWidth={false} bottomBorder={false} grow={true}>
        <React.Fragment>
          <CSVLink data={activations} headers={[{ label: 'Organization Id', key: 'organizationId' }, ...columns.map(c => ({ label: c.name, key: (c as any).field } as LabelKeyObject))]} filename={`activations.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
            <span className='euiButtonContent euiButtonEmpty__content'>
              <EuiIcon type='download' />
              <span className='euiButton__text'>Download Report (CSV)</span>
            </span>
          </CSVLink>
        </React.Fragment>
        <EuiInMemoryTable compressed loading={isLoading} items={activations} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default ActivationsPage
