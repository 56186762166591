import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import { EuiBadge, EuiButtonEmpty, EuiIcon, EuiInMemoryTable, EuiPageTemplate, EuiPanel, EuiSpacer } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Activation, useOpsClient } from 'api'
import { BundleActivation, BundleStatus } from 'api/entities/Bundle'
import { useGetBundleActivationsQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { AcTab } from 'components/ACTools/AcTab'
import history from 'services/HistoryService'
import { insertSpaces } from 'utils/EnumToFriendly'

import { AdsPendingStat } from './stats/AdsPendingStat'
import { CustomPendingStat } from './stats/CustomPendingStat'
import { TvOutrosPending } from './stats/TvOutrosPending'

const DashboardPage: React.FC = () => {
  const opsClient = useOpsClient()
  const bundleActivationsRequest = useGetBundleActivationsQuery({ pastXDays: 30 })
  const [isLoadingRecentActivations, setIsLoadingRecentActivations] = useState(false)
  const [isLoadingRecentAgencyActivations, setIsLoadingRecentAgencyActivations] = useState(false)
  const [isLoadingRecentPartnerActivations, setIsLoadingRecentPartnerActivations] = useState(false)
  const [recentActivations, setRecentActivations] = useState<Activation[]>([])
  const [recentAgencyActivations, setRecentAgencyActivations] = useState<Activation[]>([])
  const [recentPartnerActivations, setRecentPartnerActivations] = useState<Activation[]>([])
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [tab, setTab] = useState(0)

  const tabs: AcTab[] = [
    { label: 'Direct Activations', isSelected: tab === 0, onClick: () => setTab(0) },
    { label: 'Agency Activations', isSelected: tab === 1, onClick: () => setTab(1) },
    { label: 'Partner Activations', isSelected: tab === 2, onClick: () => setTab(2) },
    { label: 'Plan & Package Purchases', isSelected: tab === 3, onClick: () => setTab(3) }
  ]

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoadingRecentActivations(true)
      setIsLoadingRecentAgencyActivations(true)
      setIsLoadingRecentPartnerActivations(true)

      opsClient.getRecentActivations().then(activations => {
        if (isMounted) {
          setRecentActivations(activations)
          setIsLoadingRecentActivations(false)
        }
      })
      opsClient.getRecentAgencyActivations().then(activations => {
        if (isMounted) {
          setRecentAgencyActivations(activations)
          setIsLoadingRecentAgencyActivations(false)
        }
      })
      opsClient.getRecentPartnerActivations().then(activations => {
        if (isMounted) {
          setRecentPartnerActivations(activations)
          setIsLoadingRecentPartnerActivations(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient])

  const recentActivationColumns: Array<EuiBasicTableColumn<Activation>> = [
    {
      name: 'Organization',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/organizations/${c.organizationId}`)} size='s'>
          {c.organizationName}
        </EuiButtonEmpty>
      )
    },
    {
      name: 'Account',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/accounts/${c.accountId}`)} size='s'>
          {c.accountName}
        </EuiButtonEmpty>
      )
    },
    {
      name: 'Campaign',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/campaigns/${c.campaignId}`)} size='s'>
          {c.isInBundle && !c.isMatching ? (
            <>
              <EuiBadge color='default'>{insertSpaces(c.bundleType)}</EuiBadge>{' '}
            </>
          ) : (
            ''
          )}
          {c.isMatching ? (
            <>
              <EuiBadge color='success'>Matching</EuiBadge>{' '}
            </>
          ) : (
            ''
          )}
          {c.campaignName}
        </EuiButtonEmpty>
      )
    },
    {
      name: `Type`,
      field: 'campaignType',
      width: '150'
    },
    {
      name: `Budget`,
      render: (c: Activation) => formatter.format(c.budget) + ' ' + c.budgetFrequency.toLowerCase(),
      width: '150'
    },
    {
      name: `Activation Date`,
      render: (c: Activation) => moment.utc(c.activated).utc().tz(moment.tz.guess()).format('L'),
      width: '100'
    },
    {
      name: 'IA?',
      width: '75',
      render: (c: Activation) => (
        <React.Fragment>
          {c.isFirstTime && <EuiIcon type='checkInCircleFilled' />} {c.isSameDay && <EuiIcon type='checkInCircleFilled' color='subdued' />}
        </React.Fragment>
      )
    }
  ]

  const recentPartnerActivationColumns: Array<EuiBasicTableColumn<Activation>> = [
    {
      name: 'Partner',
      field: 'partnerName'
    },
    {
      name: 'Partner Group',
      field: 'partnerGroup'
    },
    {
      name: 'Account',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/accounts/${c.accountId}`)} size='s'>
          {c.accountName}
        </EuiButtonEmpty>
      )
    },
    {
      name: 'Campaign',
      render: (c: Activation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/campaigns/${c.campaignId}`)} size='s'>
          {c.campaignName}
        </EuiButtonEmpty>
      )
    },
    {
      name: `Type`,
      field: 'campaignType',
      width: '150'
    },
    {
      name: `Budget`,
      render: (c: Activation) => formatter.format(c.budget),
      width: '150'
    },
    {
      name: `Activation Date`,
      render: (c: Activation) => moment.utc(c.activated).utc().tz(moment.tz.guess()).format('L'),
      width: '100'
    },
    {
      name: 'IA?',
      width: '75',
      render: (c: Activation) => (
        <React.Fragment>
          {c.isFirstTime && <EuiIcon type='checkInCircleFilled' />} {c.isSameDay && <EuiIcon type='checkInCircleFilled' color='subdued' />}
        </React.Fragment>
      )
    }
  ]

  const bundlePurchasesColumns: Array<EuiBasicTableColumn<BundleActivation>> = [
    {
      name: 'Organization',
      render: (p: BundleActivation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/organizations/${p.organizationId}`)} size='s'>
          {p.organizationName}
        </EuiButtonEmpty>
      )
    },
    {
      name: 'Name',
      field: 'bundleName'
    },
    {
      name: 'Status',
      field: 'bundleStatus'
    },
    {
      name: `Budget`,
      render: (p: BundleActivation) => formatter.format(p.budget),
      width: '150'
    },
    {
      name: `Budget Frequency`,
      field: 'budgetFrequency'
    },
    {
      name: `Purchase Date`,
      render: (p: BundleActivation) => moment.utc(p.activated).utc().tz(moment.tz.guess()).format('L'),
      sortable: (p: BundleActivation) => moment(p.activated).valueOf()
    }
  ]

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  const bundleActivationData = !bundleActivationsRequest.data
    ? []
    : [...bundleActivationsRequest.data]
        ?.filter(bundle => {
          return bundle.bundleStatus !== BundleStatus.New
        })
        .sort((a, b) => {
          if (a.activated && b.activated) {
            return moment(b.activated).valueOf() - moment(a.activated).valueOf()
          } else if (b.activated) {
            return -1
          } else if (a.activated) {
            return 1
          } else {
            return 0
          }
        })

  return (
    <EuiPageTemplate panelled={true}>
      <EuiPageTemplate.Sidebar>
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <AdsPendingStat />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel hasShadow={false} hasBorder={true}>
          <CustomPendingStat />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel hasShadow={false} hasBorder={true} color='success'>
          <TvOutrosPending />
        </EuiPanel>
      </EuiPageTemplate.Sidebar>
      <EuiPageTemplate.Header iconType='advancedSettingsApp' pageTitle='Dashboard' tabs={tabs} restrictWidth={false} bottomBorder={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        {tab === 0 && <EuiInMemoryTable loading={isLoadingRecentActivations} items={recentActivations} columns={recentActivationColumns} pagination={pagination} />}
        {tab === 1 && <EuiInMemoryTable loading={isLoadingRecentAgencyActivations} items={recentAgencyActivations} columns={recentActivationColumns} pagination={pagination} />}
        {tab === 2 && <EuiInMemoryTable loading={isLoadingRecentPartnerActivations} items={recentPartnerActivations} columns={recentPartnerActivationColumns} pagination={pagination} />}
        {tab === 3 && <EuiInMemoryTable loading={bundleActivationsRequest.isLoading} items={bundleActivationData} columns={bundlePurchasesColumns} pagination={pagination} />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default DashboardPage
