import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { EuiButton, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiLoadingSpinner, EuiPanel, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { Anomaly, AnomalyStatus, AnomalyType } from 'api/entities/Anomaly'
import { useEscalateAnomalyMutation, useGetOpenAnomaliesQuery, useIgnoreAnomalyMutation, useReactivateAnomalyMutation, useRegenerateAnomaliesMutation, useResolveAnomalyMutation } from 'api/rtkQueryApi/opsApi/anomalyApi'
import { RootState } from 'app/rootReducer'
import { AnomalyFilterGroup } from 'components/Anomaly/AnomalyFilterGroup'
import { AnomalyItem } from 'components/Anomaly/AnomalyItem'
import { CampaignAnomalyDetails } from 'components/Anomaly/CampaignAnomalyDetails'

export const AnomalyPage: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const [includeIgnored, setIncludeIgnored] = React.useState<boolean>(false)
  const anomalyQuery = useGetOpenAnomaliesQuery()
  const [regenerateAnomalies, regenerateAnomaliesQuery] = useRegenerateAnomaliesMutation()
  const [resolveAnomaly, resolveAnomalyQuery] = useResolveAnomalyMutation()
  const [escalateAnomaly, escalateAnomalyQuery] = useEscalateAnomalyMutation()
  const [reactivateAnomaly, reactivateAnomalyQuery] = useReactivateAnomalyMutation()
  const [ignoreAnomaly, ignoreAnomalyQuery] = useIgnoreAnomalyMutation()
  const [selectedAnomaly, setSelectedAnomaly] = React.useState(anomalyQuery.data?.find(a => a.id === urlParams.get('anomalyId')) ?? undefined)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [activeFilters, setActiveFilters] = React.useState<AnomalyType[]>([])
  const [filteredAnomalies, setFilteredAnomalies] = React.useState<Anomaly[]>([])
  const [canIgnore, setCanIgnore] = React.useState<boolean>(false)

  const isCsuite = userProfile?.roles.includes('adcritter-ops-csuite') ?? false

  useEffect(() => {
    if (userProfile) {
      setCanIgnore(userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (!anomalyQuery.data || anomalyQuery.data.length === 0) {
      setFilteredAnomalies([])
      return
    }

    const filteredAnoms = filterAnomalies(anomalyQuery.data)
    setFilteredAnomalies(filteredAnoms)

    if (!selectedAnomaly) {
      setSelectedAnomaly(filteredAnoms.find(a => a.id === urlParams.get('anomalyId')) ?? undefined)
    }
  }, [anomalyQuery.data, includeIgnored])

  useEffect(() => {
    if (anomalyQuery.data) {
      setFilteredAnomalies(filterAnomalies(anomalyQuery.data))
    }
  }, [activeFilters])

  useEffect(() => {
    if (!selectedAnomaly) {
      return
    }

    history.push({ search: `?anomalyId=${selectedAnomaly?.id ?? ''}` })
  }, [selectedAnomaly])

  useEffect(() => {
    if (anomalyQuery.data && anomalyQuery.data.length > 0 && selectedAnomaly) {
      setSelectedAnomaly(anomalyQuery.data.find(a => a.id === selectedAnomaly.id) ?? undefined)
    }
  }, [anomalyQuery.data])

  const filterAnomalies = useCallback(
    (anomalies: Anomaly[]) => {
      if (activeFilters.length === 0) {
        return anomalies.filter(a => includeIgnored || a.status !== AnomalyStatus.Ignored)
      }

      return anomalies.filter(a => activeFilters.includes(a.anomalyType)).filter(a => includeIgnored || a.status !== AnomalyStatus.Ignored)
    },
    [activeFilters, includeIgnored]
  )

  const onAnomalySelected = (anomaly: Anomaly) => {
    setSelectedAnomaly(anomaly)
  }

  const onResolveAnomaly = (anomaly: Anomaly, details: string) => {
    resolveAnomaly({ anomalyId: anomaly.id, resolutionReason: details })
  }

  const onReactivateAnomaly = (anomaly: Anomaly, details: string) => {
    reactivateAnomaly({ anomalyId: anomaly.id, reactivationReason: details })
  }

  const onEscalateAnomaly = (anomaly: Anomaly, details: string) => {
    escalateAnomaly({ anomalyId: anomaly.id, escalationReason: details })
  }

  const onIgnoreAnomaly = (anomaly: Anomaly, ignoreReason: string, ignoreUntil: moment.Moment) => {
    ignoreAnomaly({ anomalyId: anomaly.id, ignoreReason, ignoreUntil })
  }

  const onRegenerateAnomalies = () => {
    regenerateAnomalies()
  }

  const onFilterClicked = (filter: AnomalyType) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter(f => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const onResetFilters = () => {
    setActiveFilters([])
  }

  return (
    <React.Fragment>
      <EuiTitle>
        <h2>Campaign Anomaly Tracking (CAT)</h2>
      </EuiTitle>
      {canIgnore && (
        <React.Fragment>
          <EuiSpacer />
          <EuiFlexGroup wrap>
            <EuiFlexItem grow={false}>
              <EuiSwitch checked={includeIgnored} label={'Include Ignored'} onChange={() => setIncludeIgnored(!includeIgnored)} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
      {isCsuite && (
        <React.Fragment>
          <EuiSpacer />
          <EuiFlexGroup wrap>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={onRegenerateAnomalies} isLoading={regenerateAnomaliesQuery.isLoading}>
                Regenerate Anomalies
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
      <EuiSpacer />
      {anomalyQuery.data && (
        <EuiFormRow fullWidth>
          <AnomalyFilterGroup anomalies={anomalyQuery.data.filter(a => includeIgnored || a.status !== AnomalyStatus.Ignored) ?? []} activeFilters={activeFilters} onFilterClicked={onFilterClicked} onResetFilters={onResetFilters} />
        </EuiFormRow>
      )}
      <EuiHorizontalRule />
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiPanel hasBorder={false} hasShadow={false} style={{ maxHeight: 800, overflow: 'auto' }}>
            <EuiFlexGroup direction='column'>
              {anomalyQuery.isLoading ? (
                <EuiFlexItem>
                  <EuiLoadingSpinner />
                </EuiFlexItem>
              ) : (
                filteredAnomalies.map(anomaly => (
                  <EuiFlexItem key={anomaly.id}>
                    <AnomalyItem anomaly={anomaly} isSelected={selectedAnomaly?.id === anomaly.id} onSelected={() => onAnomalySelected(anomaly)} />
                  </EuiFlexItem>
                ))
              )}
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          {!anomalyQuery.isLoading && anomalyQuery.data && filteredAnomalies.length === 0 ? (
            <React.Fragment>
              <EuiTitle>
                <h3>There are no anomalies</h3>
              </EuiTitle>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {!selectedAnomaly ? (
                <EuiEmptyPrompt title={<h2>AdCritter Anomalies</h2>} />
              ) : (
                <CampaignAnomalyDetails
                  anomaly={selectedAnomaly}
                  onEscalateClicked={onEscalateAnomaly}
                  onResolveClicked={onResolveAnomaly}
                  isSubmitting={escalateAnomalyQuery.isLoading || resolveAnomalyQuery.isLoading || reactivateAnomalyQuery.isLoading || ignoreAnomalyQuery.isLoading}
                  onReActivateClicked={onReactivateAnomaly}
                  onIgnoreClicked={(reason, ignoreUntil) => {
                    onIgnoreAnomaly(selectedAnomaly, reason, ignoreUntil)
                  }}
                />
              )}
            </React.Fragment>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
