import React, { useState } from 'react'

import { EuiBreadcrumbs, EuiPage, EuiPageBody, EuiFilePicker, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

const VistarMediaDspPage: React.FC = () => {
  const [isProcessingCsv, setIsProcessingCsv] = useState(false)
  const opsClient = useOpsClient()

  const onFileUpload = (files: FileList | null) => {
    if (files && files.length === 1) {
      setIsProcessingCsv(true)
      let r = new FileReader()
      r.onloadend = ev => {
        let content = r.result as string
        opsClient
          ?.postVistarMediaDspAnalytics(content)
          .then(response => {
            setIsProcessingCsv(false)
          })
          .catch(error => {
            alert('There was an error processing the file. Please try again.')
          })
      }
      r.readAsText(files[0])
    }
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Settings' },
              {
                text: 'VistarMedia Dsp',
                href: '/settings/yahooDsp',
                onClick: () => history.push('/settings/yahooDsp')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Vistar Media DSP</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFilePicker compressed={true} isLoading={isProcessingCsv} initialPromptText='Upload a DSP Analytics report csv' size={1} onChange={onFileUpload} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default VistarMediaDspPage
