import React, { useEffect } from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useGetCurrentUserQuery } from 'api/rtkQueryApi/graphClient/graphClientApi'
import { useGetAllSalesAgentsQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import AccountManagerBundlesPage from 'features/accountManagerDashboard/AccountManagerBundlesPage'
import { DefaultSpacerForTabbedContent } from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import { AccountManagerSalesPage } from 'features/accountManagerDashboard/AccountManagerSalesPage'
import ActivationsPage from 'features/accountManagerDashboard/ActivationsPage'
import Lost from 'features/accountManagerDashboard/leads/Lost'
import Offered from 'features/accountManagerDashboard/leads/Offered'
import Offered14PlusDays from 'features/accountManagerDashboard/leads/Offered14PlusDays'
import Offered714Days from 'features/accountManagerDashboard/leads/Offered714Days'

export const useAccountManagerDashboardTabs = () => {
  const meQuery = useGetCurrentUserQuery()
  const salesAgentsQuery = useGetAllSalesAgentsQuery()
  const [tabs, setTabs] = React.useState<EuiTabbedContentTab[]>([
    {
      id: 'activations',
      name: 'Activations',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <ActivationsPage />
        </React.Fragment>
      )
    },
    {
      id: 'offered',
      name: 'Offered',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <Offered />
        </React.Fragment>
      )
    },
    {
      id: 'offered714',
      name: '7-14 days',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <Offered714Days />
        </React.Fragment>
      )
    },
    {
      id: 'offered14Plus',
      name: '14+ days',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <Offered14PlusDays />
        </React.Fragment>
      )
    },
    {
      id: 'won',
      name: 'Won',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <AccountManagerBundlesPage />
        </React.Fragment>
      )
    },
    {
      id: 'lost',
      name: 'Lost',
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <Lost />
        </React.Fragment>
      )
    }
  ])

  useEffect(() => {
    if (meQuery.data && salesAgentsQuery.data && meQuery.data.mail) {
      const salesAgent = salesAgentsQuery.data.find(agent => agent.email === meQuery.data?.mail)
      const tabs = [
        {
          id: 'activations',
          name: 'Activations',
          content: (
            <React.Fragment>
              <DefaultSpacerForTabbedContent />
              <ActivationsPage />
            </React.Fragment>
          )
        },
        {
          id: 'offered',
          name: 'Offered',
          content: (
            <React.Fragment>
              <DefaultSpacerForTabbedContent />
              <Offered />
            </React.Fragment>
          )
        },
        {
          id: 'offered714',
          name: '7-14 days',
          content: (
            <React.Fragment>
              <DefaultSpacerForTabbedContent />
              <Offered714Days />
            </React.Fragment>
          )
        },
        {
          id: 'offered14Plus',
          name: '14+ days',
          content: (
            <React.Fragment>
              <DefaultSpacerForTabbedContent />
              <Offered14PlusDays />
            </React.Fragment>
          )
        },
        {
          id: 'won',
          name: 'Won',
          content: (
            <React.Fragment>
              <DefaultSpacerForTabbedContent />
              <AccountManagerBundlesPage />
            </React.Fragment>
          )
        },
        {
          id: 'lost',
          name: 'Lost',
          content: (
            <React.Fragment>
              <DefaultSpacerForTabbedContent />
              <Lost />
            </React.Fragment>
          )
        }
      ]
      if (salesAgent) {
        tabs.push({
          id: 'sales',
          name: 'Sales',
          content: (
            <React.Fragment>
              <AccountManagerSalesPage salesAgentId={salesAgent.id} />
            </React.Fragment>
          )
        })
      }
      setTabs(tabs)
    }
  }, [meQuery, salesAgentsQuery])

  return tabs
}
