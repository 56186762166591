import { Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiSelectable, EuiSelectableOption, EuiButton, EuiText, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiSpacer, EuiStat, EuiPanel, EuiCard, EuiIcon, EuiBadge, EuiTitle, EuiSwitch, EuiListGroupItem, EuiListGroup, EuiLink } from '@elastic/eui'

import { UpdateCampaignBidder, useOpsClient } from 'api'
import { Campaign, CampaignBidder, City, ThirdPartyAudience, Zip } from 'api'
import { campaignsApi } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { RootState } from 'app/rootReducer'
import { StateFinder, DmaFinder, CityFinder, ZipFinder, TvAudienceFinder } from 'components/Finders'
import { AudienceFinder } from 'components/Finders/AudienceFinder'
import { StateTargeter, DmaTargeter, CityTargeter, ZipTargeter, AudienceTargeterDetailed } from 'components/Targeters'
import localStoreManagerService from 'services/LocalStoreManagerService'

interface CampaignBidderTabParams {
  campaign: Campaign
  campaignBidder?: CampaignBidder
}

interface FormValues {
  targetedSegments: ThirdPartyAudience[]
  additionalSegments: ThirdPartyAudience[]
  hiddenSegments: ThirdPartyAudience[]
  excludedSegmentIds: number[]
  targetedStates: string[]
  additionalStates: string[]
  hiddenStates: string[]
  excludedStates: string[]
  targetedDmas: string[]
  additionalDmas: string[]
  hiddenDmas: string[]
  excludedDmas: string[]
  targetedCities: City[]
  additionalCities: City[]
  hiddenCities: City[]
  excludedCityIds: string[]
  targetedZips: Zip[]
  additionalZips: Zip[]
  hiddenZips: Zip[]
  excludedZips: string[]
  onlyUseBidderLocations: boolean
  onlyUseBidderSegments: boolean
  onlyUseBidderDeals: boolean
}

interface SegmentOptionData {
  description?: string
  hierarchy?: string[]
}

const CampaignBidderTab: React.FC<CampaignBidderTabParams> = ({ campaign, campaignBidder }) => {
  const dispatch = useDispatch()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [isYahoo, setIsYahoo] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isBoosting, setIsBoosting] = useState(false)
  const [initial, setInitial] = useState<FormValues>({
    targetedSegments: [],
    additionalSegments: [],
    hiddenSegments: [],
    excludedSegmentIds: [],
    targetedStates: [],
    additionalStates: [],
    hiddenStates: [],
    excludedStates: [],
    targetedDmas: [],
    additionalDmas: [],
    hiddenDmas: [],
    excludedDmas: [],
    targetedCities: [],
    additionalCities: [],
    hiddenCities: [],
    excludedCityIds: [],
    targetedZips: [],
    additionalZips: [],
    hiddenZips: [],
    excludedZips: [],
    onlyUseBidderSegments: false,
    onlyUseBidderLocations: false,
    onlyUseBidderDeals: false
  })
  const [states, setStates] = useState<EuiSelectableOption[]>()
  const [dmas, setDmas] = useState<EuiSelectableOption[]>()
  const [cities, setCities] = useState<EuiSelectableOption[]>()
  const [zips, setZips] = useState<EuiSelectableOption[]>()
  const [segments, setSegments] = useState<EuiSelectableOption<SegmentOptionData>[]>()
  const [bidderLocations, setBidderLocations] = useState<string[]>([])
  const [bidderSegments, setBidderSegments] = useState<string[]>([])
  const [bidderDeals, setBidderDeals] = useState<string[]>([])
  const opsClient = useOpsClient()
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
    []
  )

  useEffect(() => {
    if (userProfile) {
      setIsYahoo(userProfile.roles.includes('adcritter-ops-bidder'))
    }
  }, [userProfile])

  useEffect(() => {
    if (campaignBidder) {
      setInitial({
        targetedSegments: campaignBidder.targetedSegments,
        additionalSegments: campaignBidder.additionalSegments,
        hiddenSegments: campaignBidder.hiddenSegments,
        excludedSegmentIds: campaignBidder.excludedSegmentIds,
        targetedStates: campaignBidder.targetedStates,
        additionalStates: campaignBidder.additionalStates,
        hiddenStates: campaignBidder.hiddenStates,
        excludedStates: campaignBidder.excludedStates,
        targetedDmas: campaignBidder.targetedDmas,
        additionalDmas: campaignBidder.additionalDmas,
        hiddenDmas: campaignBidder.hiddenDmas,
        excludedDmas: campaignBidder.excludedDmas,
        targetedCities: campaignBidder.targetedCities,
        additionalCities: campaignBidder.additionalCities,
        hiddenCities: campaignBidder.hiddenCities,
        excludedCityIds: campaignBidder.excludedCityIds,
        targetedZips: campaignBidder.targetedZips,
        additionalZips: campaignBidder.additionalZips,
        hiddenZips: campaignBidder.hiddenZips,
        excludedZips: campaignBidder.excludedZips,
        onlyUseBidderLocations: campaignBidder.onlyTargetBidderLocations,
        onlyUseBidderDeals: campaignBidder.onlyTargetBidderDeals,
        onlyUseBidderSegments: campaignBidder.onlyTargetBidderSegments
      })

      if (campaignBidder.bidderLocations) {
        setBidderLocations(campaignBidder.bidderLocations)
      }

      if (campaignBidder.bidderSegments) {
        setBidderSegments(campaignBidder.bidderSegments)
      }

      if (campaignBidder.bidderDeals) {
        setBidderDeals(campaignBidder.bidderDeals)
      }
    }
  }, [campaignBidder])

  useEffect(() => {
    if (initial) {
      setDmas(initial.targetedDmas.map(s => ({ key: s, label: s, checked: initial.excludedDmas.includes(s) ? undefined : 'on' } as EuiSelectableOption)))
      setStates(initial.targetedStates.map(s => ({ key: s, label: s, checked: initial.excludedStates.includes(s) ? undefined : 'on' } as EuiSelectableOption)))
      setCities(initial.targetedCities.map(s => ({ key: s.cityId, label: s.name + ', ' + s.stateCode, checked: initial.excludedCityIds.includes(s.name + ', ' + s.stateCode) ? undefined : 'on', append: <EuiBadge>{numeral(s.population).format('0a')} people</EuiBadge> } as EuiSelectableOption)))
      setZips(initial.targetedZips.map(s => ({ key: s.zipId, label: s.name, checked: initial.excludedZips.includes(s.zipId) ? undefined : 'on' } as EuiSelectableOption)))
      setSegments(
        initial.targetedSegments.map(
          s =>
            ({
              key: s.id.toString(),
              label: s.name || '',
              checked: initial.excludedSegmentIds.includes(s.id) ? undefined : 'on',
              data: {
                description: s.description,
                hierarchy: s.hierarchy
              },
              append: (
                <>
                  <EuiBadge>{s.reachCount > 0 ? numeral(s.reachCount).format('0a') : '~'} people</EuiBadge>
                  <EuiBadge color='hollow'>{formatter.format(s.price)}</EuiBadge>
                </>
              )
            } as EuiSelectableOption<SegmentOptionData>)
        )
      )
    }
  }, [initial, formatter])

  const campaignSchema = Yup.object().shape({
    advertiserId: Yup.number().nullable(),
    pixelId: Yup.number().nullable(),
    additionalAudienceIds: Yup.array().of(Yup.number())
  })

  const updateCampaignBidder = (patch: CampaignBidder) => {
    dispatch(
      campaignsApi.util.updateQueryData('getCampaignBidder', campaign.campaignId, draft => {
        Object.assign(draft, patch)
      })
    )
  }

  const refresh = () => {
    setIsRefreshing(true)
    opsClient!.getCampaignBidder(campaign.campaignId).then(r => {
      updateCampaignBidder(r)
      setIsRefreshing(false)
    })
  }

  const boost = () => {
    setIsBoosting(true)
    opsClient!.putCampaignBidderBoost(campaign.campaignId).then(r => {
      updateCampaignBidder(r)
      setIsBoosting(false)
    })
  }
  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateCampaignBidder = {
      additionalSegmentIds: values.additionalSegments.map(s => s.id),
      hiddenSegmentIds: values.hiddenSegments.map(s => s.id),
      excludedSegmentIds: segments!.filter(s => s.checked !== 'on').map(s => parseInt(s.key!)),
      additionalStates: values.additionalStates,
      hiddenStates: values.hiddenStates,
      excludedStates: states!.filter(s => s.checked !== 'on').map(s => s.key!),
      additionalDmas: values.additionalDmas,
      hiddenDmas: values.hiddenDmas,
      excludedDmas: dmas!.filter(s => s.checked !== 'on').map(s => s.label),
      additionalCityIds: values.additionalCities.map(c => c.name + ', ' + c.stateCode),
      hiddenCityIds: values.hiddenCities.map(c => c.name + ', ' + c.stateCode),
      excludedCityIds: cities!.filter(s => s.checked !== 'on').map(s => s.label),
      additionalZips: values.additionalZips.map(z => z.zipId),
      hiddenZips: values.hiddenZips.map(z => z.zipId),
      excludedZips: zips!.filter(s => s.checked !== 'on').map(s => s.key!),
      onlyTargetBidderLocations: values.onlyUseBidderLocations,
      onlyTargetBidderSegments: values.onlyUseBidderSegments,
      onlyTargetBidderDeals: values.onlyUseBidderDeals
    }

    opsClient!.putCampaignBidder(campaign.campaignId, update).then(result => {
      updateCampaignBidder(result)
      setSubmitting(false)
    })
  }

  const updateShowAdvanced = (show: boolean) => {
    setShowAdvanced(show)
    localStoreManagerService.savePermanentData('showAdvanced', show)
  }

  const renderSegmentOption = (option: EuiSelectableOption<SegmentOptionData>) => {
    return (
      <>
        {option.label}
        <EuiText size='xs' color='subdued' className='eui-displayBlock'>
          <small>{option.description || ''}</small>
        </EuiText>
      </>
    )
  }

  const importBidderLocations = () => {
    opsClient!.putCampaignBidderImportLocations(campaign.campaignId).then(r => {
      setBidderLocations(r)
    })
  }

  const importBidderSegments = () => {
    opsClient!.putCampaignBidderImportSegments(campaign.campaignId).then(r => {
      setBidderSegments(r)
    })
  }

  const importBidderDeals = () => {
    opsClient!.putCampaignBidderImportDeals(campaign.campaignId).then(r => {
      setBidderDeals(r)
    })
  }

  return (
    <React.Fragment>
      {!campaignBidder && <EuiEmptyPrompt />}
      {campaignBidder && (
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              {campaignBidder.status === 'Unknown' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'questionInCircle'} color='success' />} title='Unknown' description='Campaign is currently in an unknown state' titleSize='xs' />}
              {campaignBidder.status === 'Active' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'playFilled'} color='success' />} title='Active' description='Campaign is currently active and running' titleSize='xs' />}
              {campaignBidder.status === 'Paused' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'pause'} color='warning' />} title='Paused' description='Campaign is currently paused' titleSize='xs' />}
              {campaignBidder.status === 'Ended' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'stopFilled'} color='subdued' />} title='Ended' description='Campaign has finished running' titleSize='xs' />}
              {campaignBidder.status === 'StopDailyBudget' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'cheer'} color='success' />} title='Daily Budget Reached' description='Campaign has successfully run today' titleSize='xs' />}
              {campaignBidder.status === 'StopTotalBudget' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'dot'} color='success' />} title='Total Budget Reached' description='Campaign reached its total budget' titleSize='xs' />}
              {campaignBidder.status === 'Banned' && <EuiCard hasBorder={true} layout='horizontal' icon={<EuiIcon size='xl' type={'magnifyWithExclamation'} color='danger' />} title='Banned' description='Campaign has been banned' titleSize='xs' />}
            </EuiFlexItem>
            {campaignBidder.start && campaignBidder.end && (
              <EuiFlexItem>
                <EuiPanel hasShadow={false} hasBorder={true}>
                  <EuiStat title='Current Billing Schedule' description='' titleSize='xs'>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            {moment(campaignBidder.start).utc().format('ll')} - {moment(campaignBidder.end).utc().format('ll')}
                          </p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiStat>
                </EuiPanel>
              </EuiFlexItem>
            )}
            {campaignBidder.pacing.flightCurrentDay > 0 && (
              <EuiFlexItem grow={2}>
                <EuiPanel hasShadow={false} hasBorder={true}>
                  <EuiStat title='Today' description='' titleSize='xs'>
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.todaysImpressions.toLocaleString()}</strong> Impressions
                          </p>
                          <p style={{ marginBottom: 4 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.todaysSpend)}</strong> Spend
                          </p>
                          <p style={{ marginBottom: 4 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.todaysCpm)}</strong> CPM
                          </p>
                          <p>Updated approx. every top-of-the hour</p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiStat>
                </EuiPanel>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={2}>
              <EuiPanel hasShadow={false} hasBorder={true}>
                <EuiStat title={campaignBidder.pacing.flightCurrentDay > 0 ? 'Current Flight (excluding today)' : 'Last Flight'} description='' titleSize='xs'>
                  {campaignBidder.pacing.flightCurrentDay < 0 && (
                    <EuiText color='subdued' size='xs'>
                      No pacing data available yet, too early.
                    </EuiText>
                  )}
                  {campaignBidder.pacing.flightCurrentDay >= 0 && (
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            {moment(campaignBidder.pacing.flightStart).utc().format('ll')} - {moment(campaignBidder.pacing.flightEnd).utc().format('ll')}
                          </p>
                          {campaignBidder.pacing.flightCurrentDay > 0 && (
                            <p style={{ marginBottom: 2 }}>
                              {campaignBidder.pacing.flightCurrentDay} of {campaignBidder.pacing.flightTotalDays} days ({campaignBidder.pacing.flightDayProgress.toFixed(1) + '%'})
                            </p>
                          )}
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.flightSpend)}</strong> / {formatter.format(campaignBidder.pacing.flightBudget)} ({campaignBidder.pacing.flightSpendProgress.toFixed(1) + '%'})
                          </p>
                          {showAdvanced && campaignBidder.pacing.flightBoost && campaignBidder.pacing.flightBoost > 0 && (
                            <p style={{ marginBottom: 2 }}>
                              <EuiText color='warning' size='s'>
                                <strong>{formatter.format(campaignBidder.pacing.flightBoost)}</strong> boost
                              </EuiText>
                            </p>
                          )}
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.flightImpressions.toLocaleString()}</strong> Impressions
                          </p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.flightCpm)}</strong> CPM
                          </p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.flightPacing.toFixed(1) + '%'}</strong> Pacing
                          </p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </EuiStat>
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={2}>
              <EuiPanel hasShadow={false} hasBorder={true}>
                <EuiStat title={'Lifetime (excluding today)'} description='' titleSize='xs'>
                  {!campaignBidder.pacing && (
                    <EuiText color='subdued' size='xs'>
                      No lifetime data available yet.
                    </EuiText>
                  )}
                  {campaignBidder.pacing.lifetimeCurrentDay >= 0 && (
                    <EuiFlexGroup gutterSize='none'>
                      <EuiFlexItem>
                        <EuiText size='xs'>
                          <p style={{ marginBottom: 2 }}>
                            {moment(campaignBidder.pacing.lifetimeStart).utc().format('ll')} - {moment(campaignBidder.pacing.lifetimeEnd).utc().format('ll')}
                          </p>
                          <p style={{ marginBottom: 2 }}>Total of {campaignBidder.pacing.lifetimeTotalDays} days</p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.lifetimeSpend)}</strong> / {formatter.format(campaignBidder.pacing.lifetimeBudget)} ({campaignBidder.pacing.lifetimeSpendProgress.toFixed(1) + '%'})
                          </p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.lifetimeImpressions.toLocaleString()}</strong> Impressions
                          </p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{formatter.format(campaignBidder.pacing.lifetimeCpm)}</strong> CPM
                          </p>
                          <p style={{ marginBottom: 2 }}>
                            <strong style={{ fontSize: '1.2em' }}>{campaignBidder.pacing.lifetimePacing.toFixed(1) + '%'}</strong> Pacing
                          </p>
                        </EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </EuiStat>
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <Formik initialValues={initial} enableReinitialize validationSchema={campaignSchema} onSubmit={doSubmit}>
            {props => (
              <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth>
                <EuiTitle size='s'>
                  <h3>Location Targeting</h3>
                </EuiTitle>
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'States'}>
                      <EuiSelectable aria-label='States' emptyMessage='Customer did not enter any States' options={states} listProps={{ bordered: true }} onChange={newOptions => setStates(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional States'}>
                      <React.Fragment>
                        <StateFinder isInvalid={false} addState={state => props.setFieldValue('additionalStates', [...props.values.additionalStates, state])} />
                        <EuiSpacer size='xs' />
                        <StateTargeter
                          states={props.values.additionalStates}
                          onStateRemoved={state => {
                            props.setFieldValue(
                              'additionalStates',
                              props.values.additionalStates.filter(c => c !== state)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin States'}>
                        <React.Fragment>
                          <StateFinder isInvalid={false} addState={state => props.setFieldValue('hiddenStates', [...props.values.hiddenStates, state])} />
                          <EuiSpacer size='xs' />
                          <StateTargeter
                            states={props.values.hiddenStates}
                            onStateRemoved={state => {
                              props.setFieldValue(
                                'hiddenStates',
                                props.values.hiddenStates.filter(c => c !== state)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'DMAs'}>
                      <EuiSelectable aria-label='DMAs' emptyMessage='Customer did not enter any DMAs' options={dmas} listProps={{ bordered: true }} onChange={newOptions => setDmas(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional DMAs'}>
                      <React.Fragment>
                        <DmaFinder isInvalid={false} addDma={dma => props.setFieldValue('additionalDmas', [...props.values.additionalDmas, dma])} />
                        <EuiSpacer size='xs' />
                        <DmaTargeter
                          dmas={props.values.additionalDmas}
                          onDmaRemoved={dma => {
                            props.setFieldValue(
                              'additionalDmas',
                              props.values.additionalDmas.filter(c => c !== dma)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin DMAs'}>
                        <React.Fragment>
                          <DmaFinder isInvalid={false} addDma={dma => props.setFieldValue('hiddenDmas', [...props.values.hiddenDmas, dma])} />
                          <EuiSpacer size='xs' />
                          <DmaTargeter
                            dmas={props.values.hiddenDmas}
                            onDmaRemoved={dma => {
                              props.setFieldValue(
                                'hiddenDmas',
                                props.values.hiddenDmas.filter(c => c !== dma)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'Cities'}>
                      <EuiSelectable aria-label='Cities' emptyMessage='Customer did not enter any Cities' options={cities} listProps={{ bordered: true }} onChange={newOptions => setCities(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional Cities'}>
                      <React.Fragment>
                        <CityFinder isInvalid={false} addCity={city => props.setFieldValue('additionalCities', [...props.values.additionalCities, city])} />
                        <EuiSpacer size='xs' />
                        <CityTargeter
                          cities={props.values.additionalCities}
                          onCityRemoved={city => {
                            props.setFieldValue(
                              'additionalCities',
                              props.values.additionalCities.filter(c => c.cityId !== city.cityId)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin Cities'}>
                        <React.Fragment>
                          <CityFinder isInvalid={false} addCity={city => props.setFieldValue('hiddenCities', [...props.values.hiddenCities, city])} />
                          <EuiSpacer size='xs' />
                          <CityTargeter
                            cities={props.values.hiddenCities}
                            onCityRemoved={city => {
                              props.setFieldValue(
                                'hiddenCities',
                                props.values.hiddenCities.filter(c => c.cityId !== city.cityId)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                <EuiSpacer size='m' />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'Zips'}>
                      <EuiSelectable aria-label='Zips' emptyMessage='Customer did not enter any Zips' options={zips} listProps={{ bordered: true }} onChange={newOptions => setZips(newOptions)}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional Zips'}>
                      <React.Fragment>
                        <ZipFinder isInvalid={false} addZip={zip => props.setFieldValue('additionalZips', [...props.values.additionalZips, zip])} />
                        <EuiSpacer size='xs' />
                        <ZipTargeter
                          zips={props.values.additionalZips}
                          onZipRemoved={zip => {
                            props.setFieldValue(
                              'additionalZips',
                              props.values.additionalZips.filter(c => c.zipId !== zip.zipId)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin Zips'}>
                        <React.Fragment>
                          <ZipFinder isInvalid={false} addZip={zip => props.setFieldValue('hiddenZips', [...props.values.hiddenZips, zip])} />
                          <EuiSpacer size='xs' />
                          <ZipTargeter
                            zips={props.values.hiddenZips}
                            onZipRemoved={zip => {
                              props.setFieldValue(
                                'hiddenZips',
                                props.values.hiddenZips.filter(c => c.zipId !== zip.zipId)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                {showAdvanced && (
                  <React.Fragment>
                    <EuiSpacer size='m' />
                    <EuiFormRow
                      label={
                        <React.Fragment>
                          Bidder Locations <EuiLink onClick={importBidderLocations}>import</EuiLink>
                        </React.Fragment>
                      }>
                      <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 100, overflow: 'auto' }}>
                        {bidderLocations?.map(w => (
                          <EuiListGroupItem size='xs' key={w} label={w} />
                        ))}
                      </EuiListGroup>
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiSwitch compressed={true} onChange={e => props.setFieldValue('onlyUseBidderLocations', e.target.checked)} checked={props.values.onlyUseBidderLocations} label='Only target bidder segments?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                  </React.Fragment>
                )}

                <EuiSpacer />
                <EuiTitle size='s'>
                  <h3>Segment/Audience Targeting</h3>
                </EuiTitle>
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem>
                    <EuiFormRow label={'Segments'}>
                      <EuiSelectable aria-label='Segments' emptyMessage='Customer did not enter any Segments' options={segments} listProps={{ bordered: true, rowHeight: 50 }} onChange={newOptions => setSegments(newOptions)} renderOption={renderSegmentOption}>
                        {list => list}
                      </EuiSelectable>
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label={'Additional Segments'}>
                      <React.Fragment>
                        {campaign.campaignType === 'Internet' && <AudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('additionalSegments', [...props.values.additionalSegments, segment])} />}
                        {campaign.campaignType === 'TV' && <TvAudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('additionalSegments', [...props.values.additionalSegments, segment])} />}
                        <EuiSpacer size='s' />
                        <AudienceTargeterDetailed
                          audiences={props.values.additionalSegments}
                          onAudienceRemoved={segment => {
                            props.setFieldValue(
                              'additionalSegments',
                              props.values.additionalSegments.filter(c => c.id !== segment.id)
                            )
                          }}
                        />
                      </React.Fragment>
                    </EuiFormRow>
                  </EuiFlexItem>
                  {showAdvanced && (
                    <EuiFlexItem>
                      <EuiFormRow label={'Admin Segments'}>
                        <React.Fragment>
                          {campaign.campaignType === 'Internet' && <AudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('hiddenSegments', [...props.values.hiddenSegments, segment])} />}
                          {campaign.campaignType === 'TV' && <TvAudienceFinder isInvalid={false} onAudienceClicked={segment => props.setFieldValue('hiddenSegments', [...props.values.hiddenSegments, segment])} />}
                          <EuiSpacer size='s' />
                          <AudienceTargeterDetailed
                            audiences={props.values.hiddenSegments}
                            onAudienceRemoved={segment => {
                              props.setFieldValue(
                                'hiddenSegments',
                                props.values.hiddenSegments.filter(c => c.id !== segment.id)
                              )
                            }}
                          />
                        </React.Fragment>
                      </EuiFormRow>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
                {showAdvanced && (
                  <React.Fragment>
                    <EuiSpacer size='m' />
                    <EuiFormRow
                      label={
                        <React.Fragment>
                          Bidder Segments <EuiLink onClick={importBidderSegments}>import</EuiLink>
                        </React.Fragment>
                      }>
                      <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 100, overflow: 'auto' }}>
                        {bidderSegments?.map(w => (
                          <EuiListGroupItem size='xs' key={w} label={w} />
                        ))}
                      </EuiListGroup>
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiSwitch compressed={true} onChange={e => props.setFieldValue('onlyUseBidderSegments', e.target.checked)} checked={props.values.onlyUseBidderSegments} label='Only target bidder segments?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                  </React.Fragment>
                )}

                {showAdvanced && (
                  <React.Fragment>
                    <EuiSpacer />
                    <EuiTitle size='s'>
                      <h3>Deal Targeting</h3>
                    </EuiTitle>
                    <EuiFormRow
                      label={
                        <React.Fragment>
                          Bidder Deals <EuiLink onClick={importBidderDeals}>import</EuiLink>
                        </React.Fragment>
                      }>
                      <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 100, overflow: 'auto' }}>
                        {bidderDeals?.map(w => (
                          <EuiListGroupItem size='xs' key={w} label={w} />
                        ))}
                      </EuiListGroup>
                    </EuiFormRow>
                    <EuiSpacer size='s' />
                    <EuiSwitch compressed={true} onChange={e => props.setFieldValue('onlyUseBidderDeals', e.target.checked)} checked={props.values.onlyUseBidderDeals} label='Only target bidder deals?' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                  </React.Fragment>
                )}

                <EuiSpacer />
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem grow={false}>
                    <EuiButton isLoading={props.isSubmitting} iconType='push' fill type='submit'>
                      Save &amp; Push to Bidder
                    </EuiButton>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButton color='success' iconType='refresh' onClick={refresh} isLoading={isRefreshing}>
                      Refresh
                    </EuiButton>
                  </EuiFlexItem>
                  {isYahoo && campaignBidder?.bidderCampaignId && (
                    <React.Fragment>
                      <EuiFlexItem grow={false}>
                        <EuiButton color='text' fill iconType='magnifyWithPlus' href={`https://sso.admanagerplus.yahoo.com/app/campaigns/${campaignBidder.bidderCampaignId}/lines`} target='_blank'>
                          View in Bidder
                        </EuiButton>
                      </EuiFlexItem>
                      {showAdvanced && campaignBidder?.pacing?.boost && (
                        <EuiFlexItem grow={false}>
                          <EuiButton color='warning' fill iconType='arrowUp' onClick={boost} disabled={!!campaignBidder.pacing.flightBoost} isLoading={isBoosting}>
                            Boost {formatter.format(campaignBidder.pacing.boost)}
                          </EuiButton>
                        </EuiFlexItem>
                      )}
                      <EuiFlexItem grow={false}>
                        <EuiSwitch onChange={e => updateShowAdvanced(e.target.checked)} checked={showAdvanced} label='Show Advanced Options' labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                      </EuiFlexItem>
                    </React.Fragment>
                  )}
                  <EuiFlexItem grow={true} />
                </EuiFlexGroup>
              </EuiForm>
            )}
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default CampaignBidderTab
