import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import config from 'app/config'
import AuthService from 'services/AuthService'

async function getToken() {
  const accounts = AuthService.getAccounts()

  if (accounts && accounts.length > 0) {
    try {
      const authResult = await AuthService.requestSilentToken(accounts[0])

      return authResult.accessToken
    } catch (error) {
      console.error(error)
    }
  }
}

export function defaultTransformFn<T>(response: { data: T }) {
  return response.data
}

export const opsApi = createApi({
  reducerPath: 'opsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.api.BASEURL}`,
    prepareHeaders: async (headers, _) => {
      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')
      headers.set('Authorization', `Bearer ${await getToken()}`)
      return headers
    }
  }),
  endpoints: () => ({})
})
