import React, { useEffect } from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { AgencyCategory, AgencyStatus } from 'api'
import { useGetAgencyOrganizationsQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { DefaultSpacerForTabbedContent } from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import { AgenciesPage } from 'features/agencyManagerDashboard/AgenciesPage'

export const useAgencyManagerTabs = () => {
  const premierAgenciesQuery = useGetAgencyOrganizationsQuery({ agencyCategory: AgencyCategory.Premier, query: '' })
  const growthAgenciesQuery = useGetAgencyOrganizationsQuery({ agencyCategory: AgencyCategory.Growth, query: '' })
  const highVelocityAgenciesQuery = useGetAgencyOrganizationsQuery({ agencyCategory: AgencyCategory.HighVelocity, query: '' })
  const suspectedAgenciesQuery = useGetAgencyOrganizationsQuery({ agencyCategory: AgencyCategory.Suspected, query: '' })

  const [tabs, setTabs] = React.useState<EuiTabbedContentTab[]>([])
  useEffect(() => {
    setTabs([
      {
        id: 'premierAgencies',
        name: `Premier (${premierAgenciesQuery.data?.filter(a => a.agencyDetails.agencyStatus === AgencyStatus.Active).length ?? 0})`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <AgenciesPage agencyCategory={AgencyCategory.Premier} />
          </React.Fragment>
        )
      },
      {
        id: 'growthAgencies',
        name: `Office Hours (${growthAgenciesQuery.data?.filter(a => a.agencyDetails.agencyStatus === AgencyStatus.Active).length ?? 0})`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <AgenciesPage agencyCategory={AgencyCategory.Growth} />
          </React.Fragment>
        )
      },
      {
        id: 'highVelocityAgencies',
        name: `Approved (${highVelocityAgenciesQuery.data?.filter(a => a.agencyDetails.agencyStatus === AgencyStatus.Active).length ?? 0})`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <AgenciesPage agencyCategory={AgencyCategory.HighVelocity} />
          </React.Fragment>
        )
      },
      {
        id: 'suspectedAgencies',
        name: `Suspected (${suspectedAgenciesQuery.data?.filter(a => a.agencyDetails.agencyStatus === AgencyStatus.Active).length ?? 0})`,
        content: (
          <React.Fragment>
            <DefaultSpacerForTabbedContent />
            <AgenciesPage agencyCategory={AgencyCategory.Suspected} />
          </React.Fragment>
        )
      }
    ])
  }, [premierAgenciesQuery.data, growthAgenciesQuery.data, highVelocityAgenciesQuery.data, suspectedAgenciesQuery.data])

  return tabs
}
