import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiSwitch, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiToolTip, EuiButtonIcon, EuiButtonEmpty, EuiImage } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'

import { useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface BusinessTypeDetailsTabParams {
  businessTypeId: string
}

interface FormValues {
  name: string
  isHidden: boolean
  isOther: boolean
  shortNames: Array<string>
  defaultImageSearchTerm: string
}

const BusinessTypeDetailsTab: React.FC<BusinessTypeDetailsTabParams> = ({ businessTypeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBusinessTypeCategories, setIsLoadingBusinessTypeCategories] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const [categories, setCategories] = useState<EuiComboBoxOptionOption[]>([])
  const [chosenCategories, setChosenCategories] = useState<EuiComboBoxOptionOption[]>([])
  const [keywordOptions, setKeywordOptions] = useState<EuiComboBoxOptionOption[]>([])
  const [chosenKeywords, setChosenKeywords] = useState<EuiComboBoxOptionOption[]>([])
  const [initial, setInitial] = useState<FormValues>({
    name: '',
    isHidden: true,
    isOther: false,
    shortNames: [],
    defaultImageSearchTerm: ''
  })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const opsClient = useOpsClient()

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      setIsLoadingBusinessTypeCategories(true)
      opsClient.getBusinessType(businessTypeId).then(result => {
        setInitial({
          name: result.name,
          isHidden: result.isHidden,
          isOther: result.isOther,
          shortNames: result.shortNames,
          defaultImageSearchTerm: result.defaultImageSearchTerm === null ? '' : result.defaultImageSearchTerm
        })

        const currentWords = result.keywords?.map(word => ({
          key: word.word,
          word: word.word,
          label: word.word
        }))
        setChosenKeywords(currentWords)
        setImageUrl(result.imageUrl)
        setIsLoading(false)
        opsClient.getBusinessTypeCategories().then(res => {
          setCategories(res.map(x => ({ label: x.name, id: x.id })))
          let categoryTypes = res.filter(x => result?.categoryIds?.includes(x.id))
          let selectedCategoryTypes = [...categoryTypes.map(x => ({ label: x.name, id: x.id }))]
          setChosenCategories(selectedCategoryTypes)
          setIsLoadingBusinessTypeCategories(false)
        })
      })
      opsClient.getKeywordOptions().then(result => {
        const options = result.map(word => ({
          key: word.word,
          word: word.word,
          label: word.word
        }))
        setKeywordOptions(options)
      })
    }
  }, [opsClient, businessTypeId])

  const onChangeCategory = (selectedOptions: any) => {
    setChosenCategories(selectedOptions)
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const businessTypeSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the business type name'),
    isDeleted: Yup.boolean(),
    isOther: Yup.boolean(),
    shortNames: Yup.array().of(Yup.string().required('Empty short names are not allowed'))
  })

  const selectKeyword = (options: EuiComboBoxOptionOption[]) => {
    setChosenKeywords(options)
  }

  const createKeyword = (searchValue: string) => {
    const newOption: EuiComboBoxOptionOption = {
      key: searchValue,
      value: searchValue,
      label: searchValue
    }

    setChosenKeywords(words => {
      words = words.filter(w => w.key !== searchValue)
      words.push(newOption)
      return [...words]
    })

    setKeywordOptions(words => {
      words = words.filter(w => w.key !== searchValue)
      words.push(newOption)
      return [...words]
    })
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.updateBusinessType(businessTypeId, {
        name: values.name,
        categoryIds: chosenCategories.map(x => x.id ?? '').filter(x => x !== ''),
        isHidden: values.isHidden,
        isOther: values.isOther,
        shortNames: values.shortNames,
        defaultImageSearchTerm: values.defaultImageSearchTerm,
        keywords: chosenKeywords.map(word => ({
          word: word.key?.toString() ?? ''
        }))
      })
      .then(() => {
        setSubmitting(false)
        history.push(`/data/businessTypes/${businessTypeId}`)
      })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={initial} enableReinitialize validationSchema={businessTypeSchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
              <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
            </EuiFormRow>
            <EuiFormRow label='Categories'>
              <EuiComboBox placeholder='Select or create categories' options={categories} selectedOptions={chosenCategories} onChange={onChangeCategory} isClearable={true} isLoading={isLoadingBusinessTypeCategories} />
            </EuiFormRow>
            <EuiFormRow label='Keywords'>
              <EuiComboBox options={keywordOptions} onCreateOption={createKeyword} selectedOptions={chosenKeywords} onChange={selectKeyword} isLoading={false} />
            </EuiFormRow>
            <EuiFormRow label=''>
              <EuiSwitch label='Is Hidden' checked={props.values.isHidden} onChange={e => props.setFieldValue('isHidden', e.target.checked)} />
            </EuiFormRow>
            <EuiFormRow label=''>
              <EuiSwitch label='Is Other' checked={props.values.isOther} onChange={e => props.setFieldValue('isOther', e.target.checked)} />
            </EuiFormRow>
            <EuiFormRow label='Default Image Search Term' isInvalid={!!props.errors.defaultImageSearchTerm} error={props.errors.defaultImageSearchTerm}>
              <EuiFieldText name='defaultImageSearchTerm' value={props.values.defaultImageSearchTerm} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.defaultImageSearchTerm} />
            </EuiFormRow>
            {imageUrl && (
              <EuiFormRow label='Default Image'>
                <EuiImage alt='image' src={imageUrl} size={40} />
              </EuiFormRow>
            )}
            <EuiSpacer />
            <FieldArray
              name='shortNames'
              render={arrayHelpers => (
                <React.Fragment>
                  {props.values.shortNames &&
                    props.values.shortNames.length > 0 &&
                    props.values.shortNames.map((shortName, index) => (
                      <EuiFlexGroup gutterSize='xs' key={index}>
                        <EuiFlexItem grow={false}>
                          <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={false}>
                              <EuiToolTip position='top' content='Remove this Short Name'>
                                <EuiButtonIcon id='removeShortName' iconType='cross' aria-label='remove' color='warning' onClick={() => arrayHelpers.remove(index)} />
                              </EuiToolTip>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiFormRow label={index === 0 ? 'Short Name' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `shortNames[${index}]`) && !!getIn(props.errors, `shortNames[${index}]`)} error={getIn(props.errors, `shortNames[${index}]`)}>
                            <EuiFieldText name={`shortNames[${index}]`} value={getIn(props.values, `shortNames[${index}]`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `shortNames[${index}]`) && !!getIn(props.errors, `shortNames[${index}]`)} />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ))}
                  <EuiSpacer size='s' />
                  <EuiButtonEmpty id='addShortName' size='s' onClick={() => arrayHelpers.push('')}>
                    Add {props.values.shortNames.length > 0 && 'Another'} Short Name
                  </EuiButtonEmpty>
                </React.Fragment>
              )}
            />
            <EuiSpacer />
            <EuiButton isLoading={props.isSubmitting} isDisabled={!canUpdate} fill type='submit'>
              Save
            </EuiButton>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default BusinessTypeDetailsTab
