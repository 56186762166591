import React from 'react'

import { EuiButton, EuiFormRow, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle } from '@elastic/eui'

import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'
import { useGetSelfServicePackageTemplatesQuery } from 'api/rtkQueryApi/opsApi/bundleTemplatesApi'
import { AcSearchSelector, IAcSearchSelectorItem } from 'components/ACTools/AcSearchSelector'
import { AcBadgeGroup } from 'components/Basic/AcBadgeGroup'

export interface ISelfServicePackageTemplateSelectModal {
  onClose: () => void
  onConfirm: (template: SelfServicePackageTemplate) => void
}

export const SelfServicePackageTemplateSelectModal: React.FC<ISelfServicePackageTemplateSelectModal> = props => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [selectedTemplate, setSelectedTemplate] = React.useState<SelfServicePackageTemplate>()
  const getSelfServicePackageTemplatesQuery = useGetSelfServicePackageTemplatesQuery()

  const [filteredTemplates, setFilteredTemplates] = React.useState<SelfServicePackageTemplate[]>([])

  React.useEffect(() => {
    if (getSelfServicePackageTemplatesQuery.data) {
      setFilteredTemplates(getSelfServicePackageTemplatesQuery.data.filter(t => !t.isArchived))
    }
  }, [getSelfServicePackageTemplatesQuery.data])
  const onConfirmModal = () => {
    if (selectedTemplate) {
      props.onConfirm(selectedTemplate)
    }
  }
  const onSearchChange = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const onItemClicked = (item: IAcSearchSelectorItem) => {
    const foundTemplate = filteredTemplates.find(t => t.id === item.key)
    if (foundTemplate) {
      setSelectedTemplate(foundTemplate)
    }
  }

  return (
    <EuiModal onClose={props.onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Select Template</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow label={'Select Template'}>
          <AcSearchSelector
            searchTerm={searchTerm}
            onSearchChange={onSearchChange}
            onItemClicked={onItemClicked}
            isLoading={getSelfServicePackageTemplatesQuery.isLoading || getSelfServicePackageTemplatesQuery.isFetching}
            items={filteredTemplates.map(t => ({
              label: t.templateName,
              description: t.salesTitle,
              key: t.id
            }))}
          />
        </EuiFormRow>
        <EuiFormRow>
          <AcBadgeGroup items={selectedTemplate ? [selectedTemplate.templateName] : []} />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton onClick={onConfirmModal}>Confirm</EuiButton>
        </EuiFormRow>
      </EuiModalBody>
    </EuiModal>
  )
}
