import React from 'react'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui'

import { useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'

export interface ISlotAllocationItem {
  campaignId: string
  canRemove?: boolean
  onRemoveClick?: () => void
}

export const SlotAllocationItem: React.FC<ISlotAllocationItem> = props => {
  const getCampaignQueryResult = useGetCampaignQuery(props.campaignId)

  const onRemoveClick = () => {
    props.onRemoveClick?.()
  }

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <EuiText>
          <EuiLink href={`/customers/campaigns/${props.campaignId}`} target='_blank'>
            {getCampaignQueryResult?.data?.campaignName ?? ''}
          </EuiLink>
        </EuiText>
      </EuiFlexItem>
      {props.canRemove && (
        <EuiFlexItem grow={false}>
          <EuiButtonIcon iconType='cross' aria-label='Remove' color={'danger'} title={'Remove'} onClick={onRemoveClick} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}
