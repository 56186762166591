import moment from 'moment/moment'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiTitle } from '@elastic/eui'

import { BundleCategory, IntervalUnit, TargetingPriority } from 'api/entities/Bundle'
import { SelfServicePackageStatus, SelfServicePackageType } from 'api/entities/SelfServicePackage'
import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'
import { useCreateSelfServicePackageTemplateMutation } from 'api/rtkQueryApi/opsApi/bundleTemplatesApi'
import { SelfServicePackageTemplateForm } from 'features/customers/bundles/SelfServicePackageTemplateForm'
import { useSelfServicePackageIsValid } from 'features/templates/useSelfServicePackageIsValid'

export interface ICreateSelfServePackageTemplateFlyout {
  onCloseFlyout: () => void
  onCreated: () => void
}

export const CreateSelfServePackageTemplateFlyout: React.FC<ICreateSelfServePackageTemplateFlyout> = props => {
  const [createBundleTemplate, createBundleTemplateRequest] = useCreateSelfServicePackageTemplateMutation()
  const [bundleTemplate, setBundleTemplate] = useState<SelfServicePackageTemplate>({
    id: '',
    salesTitle: '',
    salesDescription: '',
    salesShortDescription: '',
    proposalText: '',
    selfServicePackageType: SelfServicePackageType.Percent,
    selfServicePackageStatus: SelfServicePackageStatus.Available,
    subscriptionIncentiveDuration: 12,
    matchingIncentiveDuration: 12,
    campaignSlots: [],
    matchingPercentage: 100,
    minimumAmount: 1000,
    maximumAmount: 10000,
    expirationDate: moment(),
    includeSubscription: false,
    includeMatching: false,
    intervalUnit: IntervalUnit.Month,
    totalIntervals: 12,
    intervalUnitFrequency: 1,
    foreverBundle: true,
    templateName: '',
    bundleCategory: BundleCategory.SelfService,
    targetingPriority: TargetingPriority.Location
  })
  const bundleIsValid = useSelfServicePackageIsValid(bundleTemplate)

  const onCloseClicked = () => {
    props.onCloseFlyout?.()
  }
  const onSaveClicked = () => {
    if (bundleIsValid && bundleTemplate) {
      createBundleTemplate({
        template: {
          ...bundleTemplate,
          id: uuidv4(),
          selfServicePackageStatus: SelfServicePackageStatus.Available,
          campaignSlots: bundleTemplate.campaignSlots
        }
      }).then(() => {
        props.onCreated?.()
      })
    }
  }

  const onBundleTemplateChange = (bundleTemplate: SelfServicePackageTemplate) => {
    setBundleTemplate(bundleTemplate)
  }

  return (
    <EuiFlyout onClose={onCloseClicked} size='m' aria-labelledby='flyoutLargeTitle' className='flyout'>
      <EuiFlyoutHeader>
        <EuiTitle size='s'>
          <h2>Create Bundle Template</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <SelfServicePackageTemplateForm bundleTemplate={bundleTemplate} onChange={onBundleTemplateChange} />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='close' onClick={onCloseClicked} color='danger' isDisabled={createBundleTemplateRequest.isLoading}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={onSaveClicked} disabled={!bundleIsValid} isLoading={createBundleTemplateRequest.isLoading}>
              Save
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}
