import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { ApiError, Campaign, City, DeviceSettings, FrequencyUnit, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, InventorySettings, ThirdPartyAudience, useOpsClient, Zip } from 'api'
import { RootState } from 'app/rootReducer'
import { CityFinder, DmaFinder, StateFinder, ZipFinder } from 'components/Finders'
import { AudienceFinder } from 'components/Finders/AudienceFinder'
import { CityTargeter, DmaTargeter, StateTargeter, ZipTargeter } from 'components/Targeters'
import { AudienceTargeterDetailed } from 'components/Targeters/AudienceTargeterDetailed'
import { DefaultDayParts, GetDayPartsFromAdvancedTargeting, IDayPart } from 'components/Targeters/DayPartPicker'
import { GeoFenceTargeter } from 'components/Targeters/GeoFenceTargeter'
import TargetingMap from 'components/TargetingMap'

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  geoFences: Array<GeoFence>
  geoCircles: Array<GeoCircle>
  geoRectangles: Array<GeoRectangle>
  geoPolygons: Array<GeoPolygon>
  audiences: Array<ThirdPartyAudience>
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: IDayPart[]
}

interface AdvancedLocationTargetingTabProps {
  campaign: Campaign
}

const AdvancedLocationTargetingTab: React.FC<AdvancedLocationTargetingTabProps> = ({ campaign }) => {
  const opsClient = useOpsClient()
  const { isGoogleLoaded } = useSelector((state: RootState) => state.app)
  const [formValues, setFormValues] = useState<FormValues>({
    locationType: 'City',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    geoFences: [],
    geoCircles: [],
    geoRectangles: [],
    geoPolygons: [],
    audiences: [],
    inventorySettings: { targetWeb: true, targetApp: false },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })

  useEffect(() => {
    if (campaign && campaign.advancedTargeting && campaign.advancedTargeting!.advancedTargetingType === 'Location') {
      setFormValues({
        locationType: campaign.advancedTargeting!.locationType ?? 'City',
        states: campaign.advancedTargeting!.states,
        dmas: campaign.advancedTargeting!.dmas,
        cities: campaign.advancedTargeting!.cities,
        zips: campaign.advancedTargeting!.zips,
        geoFences: campaign.advancedTargeting!.geoFences,
        geoCircles: campaign.advancedTargeting!.geoCircles,
        geoRectangles: campaign.advancedTargeting!.geoRectangles,
        geoPolygons: campaign.advancedTargeting!.geoPolygons,
        audiences: campaign.advancedTargeting!.audiences,
        inventorySettings: {
          targetWeb: campaign.advancedTargeting!.inventorySettings?.targetWeb ?? true,
          targetApp: campaign.advancedTargeting!.inventorySettings?.targetApp ?? false
        },
        deviceSettings: {
          targetPc: campaign.advancedTargeting!.deviceSettings?.targetPc ?? true,
          targetPhone: campaign.advancedTargeting!.deviceSettings?.targetPhone ?? true,
          targetTablet: campaign.advancedTargeting!.deviceSettings?.targetTablet ?? true
        },
        frequency: campaign.advancedTargeting!.frequency ?? 0,
        frequencyUnit: campaign.advancedTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: GetDayPartsFromAdvancedTargeting(campaign.advancedTargeting!)
      })
    }
  }, [campaign])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please Select at Least 1 State').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please Select at Least 1 DMA/Metro Area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please Add at Least 1 City').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please Add at Least 1 Zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    geoFences: Yup.array().when('locationType', {
      is: 'Address',
      then: Yup.array().min(1, 'Please Add at Least 1 Address').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    geoCircles: Yup.array().when('locationType', {
      is: 'Map',
      then: Yup.array().of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    geoRectangles: Yup.array().when('locationType', {
      is: 'Map',
      then: Yup.array().of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    geoPolygons: Yup.array().when('locationType', {
      is: 'Map',
      then: Yup.array().of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    audiences: Yup.array().of(Yup.object()),
    inventorySettings: Yup.object().shape({ targetWeb: Yup.boolean(), targetApp: Yup.boolean() }),
    deviceSettings: Yup.object().shape({
      targetPc: Yup.boolean(),
      targetPhone: Yup.boolean(),
      targetTablet: Yup.boolean()
    }),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      opsClient!
        .putCampaignAdvancedTargeting(campaign.campaignId, {
          advancedTargetingType: 'Location',
          locationType: values.locationType,
          states: values.states,
          dmas: values.dmas,
          cities: values.cities.map(c => c.cityId),
          zips: values.zips.map(z => z.zipId),
          geoFences: values.geoFences,
          geoCircles: values.geoCircles,
          geoRectangles: values.geoRectangles,
          geoPolygons: values.geoPolygons,
          audiences: values.audiences,
          audienceType: null,
          ageRanges: [],
          genders: [],
          householdIncomes: [],
          uploadType: null,
          uploadedData: [],
          retargetingType: null,
          urlParts: [],
          keywords: [],
          negativeKeywords: [],
          inventorySettings: null,
          deviceSettings: null,
          frequency: null,
          frequencyUnit: null,
          dayParts: {
            monday: values.dayParts.filter(d => d.day === 0 && d.selected).map(d => d.hour),
            tuesday: values.dayParts.filter(d => d.day === 1 && d.selected).map(d => d.hour),
            wednesday: values.dayParts.filter(d => d.day === 2 && d.selected).map(d => d.hour),
            thursday: values.dayParts.filter(d => d.day === 3 && d.selected).map(d => d.hour),
            friday: values.dayParts.filter(d => d.day === 4 && d.selected).map(d => d.hour),
            saturday: values.dayParts.filter(d => d.day === 5 && d.selected).map(d => d.hour),
            sunday: values.dayParts.filter(d => d.day === 6 && d.selected).map(d => d.hour)
          }
        })
        .then(_ => {
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const content = (
    <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Targeting Using State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={formik.values.locationType === 'State'}
        onChange={() => {
          formik.setFieldValue('locationType', 'State', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
          </EuiText>
        }
        checked={formik.values.locationType === 'DMA'}
        onChange={() => {
          formik.setFieldValue('locationType', 'DMA', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>Targeting Using City</strong> (one or more City names)
          </EuiText>
        }
        checked={formik.values.locationType === 'City'}
        onChange={() => {
          formik.setFieldValue('locationType', 'City', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
          </EuiText>
        }
        checked={formik.values.locationType === 'Zip'}
        onChange={() => {
          formik.setFieldValue('locationType', 'Zip', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='address'
        name='locationType'
        value='Address'
        label={
          <EuiText size='s'>
            <strong>Targeting Addresses</strong> (one or more Addresses)
          </EuiText>
        }
        checked={formik.values.locationType === 'Address'}
        onChange={() => {
          formik.setFieldValue('locationType', 'Address', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='map'
        name='locationType'
        value='Map'
        label={
          <EuiText size='s'>
            <strong>Targeting Map</strong> (draw the areas you want to target on a Map)
          </EuiText>
        }
        checked={formik.values.locationType === 'Map'}
        onChange={() => {
          formik.setFieldValue('locationType', 'Map', true)
        }}
      />

      <EuiSpacer />

      <div hidden={formik.values.locationType !== 'State'}>
        <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select U.S. States below, multiple States can be targeted at once.' />
        <EuiSpacer size='s' />

        <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
          <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these States:' fullWidth>
          <StateTargeter
            states={formik.values.states}
            onStateRemoved={stateToRemove =>
              formik.setFieldValue(
                'states',
                formik.values.states.filter(x => x !== stateToRemove),
                true
              )
            }
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'DMA'}>
        <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can select a DMA/Metro Area(s) below, multiple DMAs can be targeted at once.' />
        <EuiSpacer size='s' />

        <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
          <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these DMAs:' fullWidth>
          <DmaTargeter
            dmas={formik.values.dmas}
            onDmaRemoved={dmaToRemove =>
              formik.setFieldValue(
                'dmas',
                formik.values.dmas.filter(x => x !== dmaToRemove),
                true
              )
            }
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'City'}>
        <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Cities below, multiple Cities can be targeted at once.' />
        <EuiSpacer size='s' />

        <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
          <CityFinder addCity={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these cities:' fullWidth>
          <CityTargeter
            cities={formik.values.cities}
            onCityRemoved={city => {
              formik.setFieldValue(
                'cities',
                formik.values.cities.filter(c => c.cityId !== city.cityId)
              )
            }}
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'Zip'}>
        <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for U.S. Zip Codes below, multiple Zip Codes can be targeted at once.' />
        <EuiSpacer size='s' />

        <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
          <ZipFinder addZip={zip => formik.setFieldValue('zips', [...formik.values.zips, zip])} isInvalid={!!formik.errors.zips} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these zip codes:' fullWidth>
          <ZipTargeter
            zips={formik.values.zips}
            onZipRemoved={zip => {
              formik.setFieldValue(
                'zips',
                formik.values.zips.filter(z => z.zipId !== zip.zipId)
              )
            }}
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'Address'}>
        <EuiCallOut size='s' iconType='pinFilled' color='success' title='You can search for Addresses below, multiple Addresses can be targeted at once.' />
        <EuiSpacer size='s' />

        <EuiFormRow label='The campaign will target these addresses:' fullWidth>
          <GeoFenceTargeter
            geoFence={formik.values.geoFences}
            onGeoFenceRemoved={geoFence => {
              formik.setFieldValue(
                'geoFences',
                formik.values.geoFences.filter(g => g.placeId !== geoFence.placeId)
              )
            }}
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'Map'}>
        <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
        <EuiSpacer size='s' />

        {formik.values.locationType === 'Map' && isGoogleLoaded && (
          <TargetingMap
            geoCircles={formik.values.geoCircles}
            addCircle={(circle: GeoCircle) => {
              formik.setFieldValue('geoCircles', [...formik.values.geoCircles, circle])
            }}
            removeCircle={(id: string) => {
              formik.setFieldValue(
                'geoCircles',
                formik.values.geoCircles.filter(c => c.id !== id)
              )
            }}
            modifyCircle={(circle: GeoCircle) => {
              formik.setFieldValue(
                'geoCircles',
                formik.values.geoCircles.map(c => {
                  if (c.id !== circle.id) {
                    return c
                  }

                  return circle
                })
              )
            }}
            geoRectangles={formik.values.geoRectangles}
            addRectangle={(rectangle: GeoRectangle) => {
              formik.setFieldValue('geoRectangles', [...formik.values.geoRectangles, rectangle])
            }}
            removeRectangle={(id: string) => {
              formik.setFieldValue(
                'geoRectangles',
                formik.values.geoRectangles.filter(r => r.id !== id)
              )
            }}
            modifyRectangle={(rectangle: GeoRectangle) => {
              formik.setFieldValue(
                'geoRectangles',
                formik.values.geoRectangles.map(r => {
                  if (r.id !== rectangle.id) {
                    return r
                  }

                  return rectangle
                })
              )
            }}
            geoPolygons={formik.values.geoPolygons}
            addPolygon={(polygon: GeoPolygon) => {
              formik.setFieldValue('geoPolygons', [...formik.values.geoPolygons, polygon])
            }}
            removePolygon={(id: string) => {
              formik.setFieldValue(
                'geoPolygons',
                formik.values.geoPolygons.filter(p => p.id !== id)
              )
            }}
            modifyPolygon={(polygon: GeoPolygon) => {
              formik.setFieldValue(
                'geoPolygons',
                formik.values.geoPolygons.map(p => {
                  if (p.id !== polygon.id) {
                    return p
                  }

                  return polygon
                })
              )
            }}
          />
        )}
      </div>

      <EuiSpacer size='xl' />
      <EuiTitle size='xs'>
        <h2>Traffic Prioritization</h2>
      </EuiTitle>
      <EuiSpacer size='s' />

      <EuiFormRow label='Search for individual characteristics to refine the ideal audience.' fullWidth>
        <AudienceFinder onAudienceClicked={audience => formik.setFieldValue('audiences', [...formik.values.audiences, audience])} isInvalid={false} />
      </EuiFormRow>
      <EuiFormRow label='The campaign will prioritize individuals in any of these categories:' fullWidth>
        <AudienceTargeterDetailed
          audiences={formik.values.audiences}
          onAudienceRemoved={audience => {
            formik.setFieldValue(
              'audiences',
              formik.values.audiences.filter(a => a.id !== audience.id)
            )
          }}
        />
      </EuiFormRow>

      <EuiSpacer />

      <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
        Save
      </EuiButton>
    </EuiForm>
  )

  return (
    <>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Bulk Targeting by Zip/Address</h5>
            <p>If you are wanting to target a long list of Zip Codes or Street Addresses, you may want to consider using the Upload an Audience option instead of entering them all by hand. Either will work just fine, but the bulk upload feature may save you time.</p>
            <h5>About Traffic Prioritization</h5>
            <p>uses the characteristics you identify in Traffic Prioritization to favor people in the targeted location(s) who match the desired audience. For example, you could enter ‘golf’ to prioritize people in the specified geographic area interested in golfing.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default AdvancedLocationTargetingTab
