import React from 'react'

import { EuiBadge, EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { City } from 'api'

interface CityTargeterProps {
  cities: Array<City>
  onCityRemoved(city: City): void
}

export const CityTargeter = ({ cities, onCityRemoved }: CityTargeterProps) => {
  return (
    <React.Fragment>
      {cities && cities.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
          {cities.map(w => (
            <EuiListGroupItem
              size='xs'
              key={w.cityId.toString()}
              label={
                <React.Fragment>
                  {w.name}, {w.stateCode} <EuiBadge>{w.population.toLocaleString()} people</EuiBadge>
                </React.Fragment>
              }
              extraAction={{
                onClick: () => {
                  onCityRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
