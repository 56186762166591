import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldNumber, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiPortal, EuiSpacer, EuiTitle, EuiCheckbox, EuiGlobalToastList, EuiGlobalToastListToast } from '@elastic/eui'

import { Account, Organization, useOpsClient } from 'api'

interface FormValues {
  firstName: string
  lastName: string
  company: string
  phone: string
  email: string
  isAgency: boolean
}

interface DealCreateFlyOutParams {
  onCreation?: () => void
}

const ProspectCreateFlyOut: React.FC<DealCreateFlyOutParams> = ({ onCreation }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [toasts, setToasts] = useState<EuiGlobalToastListToast[]>([])
  const opsClient = useOpsClient()
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    isAgency: false
  })

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const closeFlyout = () => setIsFlyoutVisible(false)

  const prospectSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter first name'),
    lastName: Yup.string().required('Please enter last name'),
    company: Yup.string().required('Please enter company'),
    email: Yup.string().required('Please enter email')
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.createNewProspectOrganization({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        company: values.company,
        phone: values.phone,
        isAgency: values.isAgency
      })
      .then(res => {
        if (res?.errors?.length > 0) {
          addToastHandler('Error', res.errors[0].message)
        } else {
          if (onCreation) {
            onCreation()
          }
        }
      })
      .finally(() => {
        setSubmitting(false)
        closeFlyout()
      })
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={formState as FormValues} validationSchema={prospectSchema} onSubmit={doSubmit} enableReinitialize>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='m' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Prospect</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit}>
                  <EuiFormRow label='First Name' isInvalid={!!props.errors.firstName} error={props.errors.firstName} fullWidth>
                    <EuiFieldText name='firstName' value={props.values.firstName} isInvalid={!!props.errors.firstName} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Last Name' isInvalid={!!props.errors.lastName} error={props.errors.lastName} fullWidth>
                    <EuiFieldText name='lastName' value={props.values.lastName} isInvalid={!!props.errors.lastName} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Company' isInvalid={!!props.errors.company} error={props.errors.company} fullWidth>
                    <EuiFieldText name='company' value={props.values.company} isInvalid={!!props.errors.company} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Email' isInvalid={!!props.errors.email} error={props.errors.email} fullWidth>
                    <EuiFieldText name='email' value={props.values.email} isInvalid={!!props.errors.email} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Phone' isInvalid={!!props.errors.phone} error={props.errors.phone} fullWidth>
                    <EuiFieldText name='phone' value={props.values.phone} isInvalid={!!props.errors.phone} onChange={props.handleChange} onBlur={props.handleBlur} fullWidth />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiFormRow label='Is Agency' isInvalid={!!props.errors.isAgency} error={props.errors.isAgency} fullWidth>
                    <EuiCheckbox id={`isAgency`} label='' checked={props.values.isAgency} onChange={e => props.setFieldValue('isAgency', e.target.checked)} compressed />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  const addToastHandler = (title: string, text: string) => {
    const toast = {
      id: `${title}${text}`,
      title: title,
      color: 'danger',
      text: <p>{text}</p>
    } as EuiGlobalToastListToast
    setToasts(toasts.concat(toast))
  }

  const removeToast = (removedToast: any) => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id))
  }

  return (
    <React.Fragment>
      <EuiButton id='createProspectFlyOut' onClick={showFlyout} fill>
        Create Prospect
      </EuiButton>
      {flyout}
      <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
    </React.Fragment>
  )
}

export default ProspectCreateFlyOut
