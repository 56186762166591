import * as msal from '@azure/msal-browser'

import config from 'app/config'

let msalApp: msal.PublicClientApplication

export function getMsalApp(): msal.PublicClientApplication {
  if (msalApp) {
    return msalApp
  } else {
    msalApp = new msal.PublicClientApplication({
      auth: {
        clientId: config.CLIENTID,
        authority: 'https://login.microsoftonline.com/d9898f8e-c178-43d0-903e-4560534bec44',
        redirectUri: `${config.BASEURL}login`,
        postLogoutRedirectUri: `${config.BASEURL}logout`,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
        secureCookies: false
      }
    })
    return msalApp
  }
}
