import React from 'react'

import { CampaignType } from 'api'
import { AcFilterGroup } from 'components/ACTools/AcFilterGroup'

export interface ICampaignTypeItem {
  campaignType: CampaignType
}

export interface ICampaignTypeFilterGroup {
  campaignTypeItems: ICampaignTypeItem[]
  onFilterClicked?: (campaignType: CampaignType) => void
  onResetFilters?: () => void
  activeFilters: CampaignType[]
}

export const CampaignTypeFilterGroup: React.FC<ICampaignTypeFilterGroup> = props => {
  const onFilterClick = (campType: string) => {
    props.onFilterClicked?.(campType as CampaignType)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }
  return <AcFilterGroup items={props.campaignTypeItems} getGroup={(campTypeItem: ICampaignTypeItem) => campTypeItem.campaignType} activeFilters={props.activeFilters} onFilterClicked={onFilterClick} onResetFilters={onTotalClick} />
}
