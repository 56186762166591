import { Moment } from 'moment'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'

import { EuiInMemoryTable, EuiLink, EuiPageTemplate, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign, useOpsClient } from 'api'

const CampaignsNeedingCustomAdsPage: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const opsClient = useOpsClient()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    setIsLoadingCampaigns(true)
    opsClient?.getNeedsCustomAdCampaigns('Internet').then(c => {
      setCampaigns(c)
      setIsLoadingCampaigns(false)
    })
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/campaigns/${c.campaignId}`} target='_blank'>
          {c.campaignName}
        </EuiLink>
      )
    },
    {
      name: 'Account',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/accounts/${c.accountId}`} target='_blank'>
          {c.accountName}
        </EuiLink>
      )
    },
    {
      name: 'Organization',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/organizations/${c.organizationId}`} target='_blank'>
          {c.organizationName}
        </EuiLink>
      )
    },
    {
      name: `Targeting Type`,
      render: (c: Campaign) => c.targetingTemplate,
      width: '130'
    },
    {
      name: `Budget`,
      render: (c: Campaign) => formatter.format(c.budget),
      sortable: (c: Campaign) => c.budget,
      width: '130'
    },
    {
      name: `Created Date`,
      field: 'created',
      render: (created: Moment) => moment(created).format('MM/DD/YYYY hh:mm A'),
      sortable: true
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'campaignName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='Internet Campaigns That Need Custom Ads' restrictWidth={false}></EuiPageTemplate.Header>
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiInMemoryTable
          loading={isLoadingCampaigns}
          items={campaigns}
          columns={columns}
          sorting={sorting}
          pagination={pagination}
          search={{
            box: {
              incremental: true
            }
          }}
        />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default CampaignsNeedingCustomAdsPage
