import moment, { Moment } from 'moment'
import { useState } from 'react'
import React from 'react'

import { EuiButton, EuiDatePicker, EuiDatePickerRange, EuiFieldSearch, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect } from '@elastic/eui'

import { Organization, useOpsClient } from 'api'
import { AcTagPicker, IAcTag } from 'components/ACTools/AcTagPicker'
import { BrandMetricsChart } from 'features/tools/tools/BrandMetricsChart'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export interface IBrandMetricsWidget {
  organizationId?: string
  accountId?: string
}

export const BrandMetricsWidget: React.FC = () => {
  const [organizationSearchTerm, setOrganizationSearchTerm] = React.useState<string>()
  const [suggestedOrganizations, setSuggestedOrganizations] = React.useState<Organization[]>([])
  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization>()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedLocationType, setSelectedLocationType] = useState<string>('State')
  const [locations, setLocations] = useState<string[]>([])
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(2, 'year'))
  const [endDate, setEndDate] = useState<Moment>(moment().startOf('month'))

  const [postSearchTerm, setPostSearchTerm] = useState<string>('')
  const [postLocations, setPostLocations] = useState<string[]>([])
  const [postLocationType, setPostLocationType] = useState<string>('State')
  const [postStartDate, setPostStartDate] = useState<Moment>(moment().subtract(2, 'year'))
  const [postEndDate, setPostEndDate] = useState<Moment>(moment().startOf('month'))
  const [postSelectedOrganization, setPostSelectedOrganization] = useState<Organization>()

  const opsClient = useOpsClient()

  useDebounceEffect(
    200,
    () => {
      if (organizationSearchTerm) {
        opsClient!.getSearchResults(organizationSearchTerm).then(results => {
          setSuggestedOrganizations(
            results
              .filter(r => r.type === 'Organization')
              .slice(0, 7)
              .map(
                r =>
                  ({
                    organizationName: r.name,
                    organizationId: r.id
                  } as Organization)
              )
          )
        })
      } else {
        setSuggestedOrganizations([])
      }
    },
    [organizationSearchTerm]
  )

  const OnAddLocation = (locationTag: IAcTag) => {
    setLocations([...locations, locationTag.key])
  }

  const OnRemoveLocation = (locationTag: IAcTag) => {
    setLocations(locations.filter(l => l !== locationTag.key))
  }

  const OnGenerateReport = () => {
    setPostSearchTerm(searchTerm)
    setPostLocations(locations)
    setPostLocationType(selectedLocationType)
    setPostEndDate(endDate)
    setPostStartDate(startDate)
    setPostSelectedOrganization(selectedOrganization)
  }

  const locationTypes = [
    { value: 'Country', text: 'Country' },
    { value: 'State', text: 'State' },
    { value: 'City', text: 'City' },
    { value: 'County', text: 'County' }
  ]

  const onLocationTypeChange = (locationType: string) => {
    setSelectedLocationType(locationType)
  }

  const onOrganizationSearchesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationSearchTerm(e.target.value)
  }

  const onOrganizationSelected = (organization: Organization) => {
    setSelectedOrganization(organization)
    setOrganizationSearchTerm('')
    setSuggestedOrganizations([])
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={'Search Term'}>
              <EuiFieldText type='text' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={'Dates'}>
              <EuiDatePickerRange
                isInvalid={startDate > endDate}
                startDateControl={<EuiDatePicker selected={startDate} onChange={date => date && setStartDate(date)} startDate={startDate} endDate={endDate} aria-label='Start date' maxDate={moment().startOf('month')} minDate={moment().add(-4, 'year')} />}
                endDateControl={<EuiDatePicker selected={endDate} maxDate={moment().startOf('month')} minDate={moment().add(-4, 'year')} onChange={date => date && setEndDate(date)} startDate={startDate} endDate={endDate} aria-label='End date' />}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={'Location Type'}>
              <EuiSelect
                options={locationTypes}
                value={selectedLocationType}
                onChange={e => {
                  onLocationTypeChange(e.target.value)
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label={'Locations'} fullWidth>
              <AcTagPicker
                tags={locations.map(l => ({
                  key: l,
                  disabled: false,
                  text: l
                }))}
                onAddTag={OnAddLocation}
                onRemoveTag={OnRemoveLocation}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth label={selectedOrganization ? `Currently Showing ${selectedOrganization?.organizationName}` : undefined}>
        <React.Fragment>
          <EuiFieldSearch placeholder='Search Organizations' value={organizationSearchTerm} onChange={onOrganizationSearchesChange} isClearable />
          <EuiFlexGroup wrap>
            {suggestedOrganizations.map(org => (
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => onOrganizationSelected(org)}>{org.organizationName}</EuiButton>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </React.Fragment>
      </EuiFormRow>
      <EuiFormRow>
        <EuiButton onClick={OnGenerateReport} color={'success'}>
          Generate Report
        </EuiButton>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <BrandMetricsChart searchTerm={postSearchTerm} locations={postLocations} locationType={postLocationType} startDate={postStartDate} endDate={postEndDate} organization={postSelectedOrganization} />
      </EuiFormRow>
    </React.Fragment>
  )
}
