import React from 'react'

import { EuiBadge, EuiCopy, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiSwitch } from '@elastic/eui'

import { AgencyCategory, AgencyDetails, AgencySalesStage, AgencyStatus } from 'api'
import { useGetAgencyOrganizationsQuery, useRevertAgencyMutation, useSetAgencyDetailsMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'
import config from 'app/config'
import { AgencyGrid } from 'features/agencyManagerDashboard/AgencyGrid'
import { AgencySalesStageFilterGroup } from 'features/agencyManagerDashboard/components/AgencySalesStageFilterGroup'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export interface IAgenciesPage {
  agencyCategory: AgencyCategory
}

export const AgenciesPage: React.FC<IAgenciesPage> = props => {
  const [searchValue, setSearchValue] = React.useState('')
  const [debounceSearch, setDebounceSearch] = React.useState<string>()
  const getAgenciesQuery = useGetAgencyOrganizationsQuery({ agencyCategory: props.agencyCategory, query: debounceSearch })
  const [setAgencyDetails, setAgencyDetailsQuery] = useSetAgencyDetailsMutation()
  const [revertAgency, revertAgencyQuery] = useRevertAgencyMutation()
  const [showArchived, setShowArchived] = React.useState<boolean>(false)
  const [activeSalesStageFilters, setActiveSalesStageFilters] = React.useState<AgencySalesStage[]>([])

  useDebounceEffect(
    300,
    () => {
      setDebounceSearch(searchValue)
    },
    [searchValue]
  )

  const onSearchChange = (search: string) => {
    setSearchValue(search)
  }

  const onAgencyDetailsChanged = (organizationId: string, agencyDetails: AgencyDetails) => {
    setAgencyDetails({ organizationId, agencyDetails: agencyDetails })
  }

  const onRevertAgency = (organizationId: string) => {
    revertAgency({ organizationId })
  }

  const onShowArchivedChange = () => {
    setShowArchived(!showArchived)
  }

  const onSalesStageFilterClicked = (salesStage: AgencySalesStage) => {
    if (activeSalesStageFilters.includes(salesStage)) {
      setActiveSalesStageFilters(activeSalesStageFilters.filter(f => f !== salesStage))
    } else {
      setActiveSalesStageFilters([...activeSalesStageFilters, salesStage])
    }
  }

  const onResetSalesStageFilters = () => {
    setActiveSalesStageFilters([])
  }

  const archivedFilteredAgencies = getAgenciesQuery.data?.filter(a => showArchived || a.agencyDetails?.agencyStatus !== AgencyStatus.Archived) ?? []
  const salesStageFilteredAgencies = archivedFilteredAgencies.filter(a => activeSalesStageFilters.length === 0 || activeSalesStageFilters.includes(a.agencyDetails?.agencyStageDetails?.salesStage ?? AgencySalesStage.T))

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <EuiFlexGroup wrap>
          <EuiFlexItem grow={false}>
            <EuiFieldSearch style={{ minWidth: 300 }} placeholder='Search' value={searchValue} onChange={e => onSearchChange(e.target.value)} isClearable={true} aria-label='Filter Agencies' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <AgencySalesStageFilterGroup agencies={archivedFilteredAgencies} activeFilters={activeSalesStageFilters} onFilterClicked={onSalesStageFilterClicked} onResetFilters={onResetSalesStageFilters} />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ paddingTop: 10 }}>
            <EuiSwitch label={'Show Archived'} onChange={onShowArchivedChange} checked={showArchived} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiSpacer />
      <EuiCopy textToCopy={`${config.AGENCYURL}offers`}>
        {copy => (
          <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Offer Link'>
            Offer URL
          </EuiBadge>
        )}
      </EuiCopy>
      <AgencyGrid agencies={salesStageFilteredAgencies} isLoading={getAgenciesQuery.isLoading || getAgenciesQuery.isFetching || revertAgencyQuery.isLoading || setAgencyDetailsQuery.isLoading} onAgencyDetailsChanged={onAgencyDetailsChanged} onRevertAgency={onRevertAgency} />
    </React.Fragment>
  )
}
