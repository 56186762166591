import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AnchorPhrase, OpsClient } from 'api'

export type AnchorPhrasesState = {
  isLoadingAnchorPhrases: boolean
  anchorPhrases: AnchorPhrase[]
}

const initialState: AnchorPhrasesState = {
  isLoadingAnchorPhrases: false,
  anchorPhrases: []
}

export const fetchAnchorPhrases = createAsyncThunk('data/anchorPhrases', async (opsClient: OpsClient) => {
  return opsClient!.getAnchorPhrases()
})

const anchorPhrasesSlice = createSlice({
  name: 'anchorPhrases',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAnchorPhrases.pending, state => {
      state.isLoadingAnchorPhrases = true
    })
    builder.addCase(fetchAnchorPhrases.fulfilled, (state, action) => {
      state.isLoadingAnchorPhrases = false
      state.anchorPhrases = action.payload || []
    })
    builder.addCase(fetchAnchorPhrases.rejected, state => {
      state.isLoadingAnchorPhrases = false
      state.anchorPhrases = []
    })
  }
})

//export const {} = anchorPhrasesSlice.actions

export default anchorPhrasesSlice.reducer
