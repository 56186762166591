import React, { useEffect } from 'react'

import { EuiFormRow, EuiLoadingSpinner } from '@elastic/eui'

import { CampaignType, SegmentTarget } from 'api'
import { SegmentFinder } from 'components/Finders/SegmentFinder'

import { SegmentTargeter } from '../Targeters/SegmentTargeter'

export interface ISegmentListBuilder {
  segments: SegmentTarget[]
  campaignType: CampaignType
  onSegmentRemoved?: (segment: SegmentTarget) => void
  onSegmentAdded?: (segment: SegmentTarget) => void
}

export const SegmentListBuilder: React.FC<ISegmentListBuilder> = props => {
  const onSegmentRemoved = (segment: SegmentTarget) => {
    props.onSegmentRemoved?.(segment)
  }

  const onSegmentSelected = (segment: SegmentTarget) => {
    props.onSegmentAdded?.(segment)
  }

  if (props.campaignType !== CampaignType.Internet && props.campaignType !== CampaignType.TV) {
    return <React.Fragment />
  }

  return (
    <React.Fragment>
      <EuiFormRow label={'Add Segments'} fullWidth>
        <SegmentFinder isInvalid={false} onSegmentClicked={onSegmentSelected} mode={props.campaignType === CampaignType.Internet ? 'Internet' : 'Tv'} />
      </EuiFormRow>
      <EuiFormRow fullWidth label={'Selected Segments'}>
        <SegmentTargeter segments={props.segments} onSegmentRemoved={onSegmentRemoved} />
      </EuiFormRow>
    </React.Fragment>
  )
}
