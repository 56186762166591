import moment from 'moment'

export enum Channel {
  All = 'All',
  Direct = 'Direct',
  Agency = 'Agency',
  Partner = 'Partner'
}

export enum Category {
  Regular = 'Regular',
  NonRegular = 'Non-regular',
  LeadGeneration = 'L.Gen/eComm',
  ShortTerm = 'Short/Seasonal',
  Event = 'Event',
  Political = 'Political'
}

export interface AllowedDates {
  forDates: string[]
}

export interface ExecutiveDashboardCustomer {
  forDate: moment.Moment
  totalMtdNewCustomers: number
  mtdNewCustomerIds: string[]
  totalPreviousMtdNewCustomers: number
  previousMtdNewCustomerIds: string[]
  totalPreviousTotalNewCustomers: number
  previousTotalNewCustomerIds: string[]
  allCustomers: CustomerReport[]
}

export interface ExecutiveDashboardCampaign {
  forDate: moment.Moment
  totalActive: number
  totalRevenue: CampaignReportRevenue
  weeklyOngoingRecurringRevenue: CampaignReportRevenue
  weeklyOngoingNonrecurringRevenue: CampaignReportRevenue
  fixedDurationNonrecurringRevenue: CampaignReportRevenue
  otherRecurringRevenue: CampaignReportRevenue
  otherNonrecurringRevenue: CampaignReportRevenue
  totalMtdInitialActivations: number
  totalMtdActivations: number
  totalPreviousMtdInitialActivations: number
  totalPreviousMtdActivations: number
  mtdInitialActivationsCampaignIds: string[]
  mtdActivationsCampaignIds: string[]
  previousMtdInitialActivationsCampaignIds: string[]
  previousInitialActivationsCampaignIds: string[]
  allCampaigns: CampaignReport[]
}

export interface ExecutiveDashboardCampaignChange {
  forDate: moment.Moment
  changedCampaigns: CampaignReportChange[]
}

export interface ExecutiveDashboardPlan {
  forDate: moment.Moment
  totalActive: number
  totalOnboarding: number
  totalProblem1Month: number
  totalProblem2Month: number
  totalLost: number
  activeRevenue: PlanReportRevenue
  onboardingRevenue: PlanReportRevenue
  problem1MonthRevenue: PlanReportRevenue
  problem2MonthRevenue: PlanReportRevenue
  lostRevenue: PlanReportRevenue
  totalMtdPurchases: number
  totalMtdDailyRevenue: number
  totalMtdMonthlyRevenue: number
  totalMtdAnnualRevenue: number
  totalPreviousMtdPurchases: number
  totalPreviousMtdDailyRevenue: number
  totalPreviousMtdMonthlyRevenue: number
  totalPreviousMtdAnnualRevenue: number
  mtdPurchasesPlanIds: string[]
  previousMtdPurchasesPlanIds: string[]
  totalMtdInitialActivations: number
  totalMtdActivations: number
  totalPreviousMtdInitialActivations: number
  totalPreviousMtdActivations: number
  allPlans: PlanReport[]
}

export interface ExecutiveDashboardPlanChange {
  forDate: moment.Moment
  changedPlans: PlanReportChange[]
}

export interface ExecutiveDashboardPackage {
  forDate: moment.Moment
  totalActive: number
  totalOnboarding: number
  totalProblem: number
  activeRevenue: PackageReportRevenue
  onboardingRevenue: PackageReportRevenue
  problemRevenue: PackageReportRevenue
  totalMtdPurchases: number
  totalMtdDailyRevenue: number
  totalMtdMonthlyRevenue: number
  totalMtdAnnualRevenue: number
  totalPreviousMtdPurchases: number
  totalPreviousMtdDailyRevenue: number
  totalPreviousMtdMonthlyRevenue: number
  totalPreviousMtdAnnualRevenue: number
  mtdPurchasesPackageIds: string[]
  previousMtdPurchasesPackageIds: string[]
  allPackages: PackageReport[]
}

export interface ExecutiveDashboardPackageChange {
  forDate: moment.Moment
  changedPackages: PackageReportChange[]
}

export interface ExecutiveDashboardSubscription {
  forDate: moment.Moment
  totalActive: number
  totalTrialing: number
  activeRevenue: SubscriptionReportRevenue
  trialingRevenue: SubscriptionReportRevenue
  totalMtdSignups: number
  totalPreviousMtdSignups: number
  totalPreviousSignups: number
  mtdSignupSubscriptionIds: string[]
  previousMtdSignupSubscriptionIds: string[]
  previousSignupSubscriptionIds: string[]
  allSubscriptions: SubscriptionReport[]
  createdDate: moment.Moment
}

export interface CampaignReportRevenue {
  daily: number
  monthly: number
  annual: number
  campaignIds: string[]
}

export interface PlanReportRevenue {
  daily: number
  monthly: number
  annual: number
  planIds: string[]
}

export interface PackageReportRevenue {
  daily: number
  monthly: number
  annual: number
  packageIds: string[]
}

export interface SubscriptionReportRevenue {
  daily: number
  monthly: number
  annual: number
  subscriptionIds: string[]
}

export interface CustomerReport {
  organizationId: string
  organizationName: string
  firstPaymentDate: moment.Moment
  firstPaymentAmount: number
  firstPaymentType: string
  initialActivationDate?: moment.Moment
  initialActivationAmount?: number
  planPurchaseDate?: moment.Moment
  planPurchaseAmount?: number
  packagePurchaseDate?: moment.Moment
  packagePurchaseAmount?: number
}

export interface CampaignReport {
  campaignId: string
  campaignName: string
  organizationId: string
  organizationName: string
  accountId: string
  accountName: string
  campaignStatus: string
  budget: number
  isInitialActivation: boolean
  lastInvoiceAmount: number
  lastInvoiceDate: moment.Moment
  expectedNextInvoiceAmount: number
  expectedNextInvoiceDate: moment.Moment
}

export interface CampaignReportChange {
  daysAgo: number
  change: string
  campaignId: string
  campaignName: string
  organizationId: string
  organizationName: string
  accountId: string
  accountName: string
  campaignStatus: string
  budget: number
}

export interface PlanReport {
  planId: string
  organizationId: string
  organizationName: string
  accountId: string
  accountName: string
  planStatus: string
  planCategory: string
  price: number
  priceDescription: string
  numberSlots: number
  numberFilledSlots: number
  numberActivatedSlots: number
  lastInvoiceAmount: number
  lastInvoiceDate: moment.Moment
  expectedNextInvoiceAmount: number
  expectedNextInvoiceDate: moment.Moment
  created: moment.Moment
}

export interface PlanReportChange {
  daysAgo: number
  change: string
  planId: string
  organizationId: string
  organizationName: string
  accountId: string
  accountName: string
  planStatus: string
  planCategory: string
  price: number
  priceDescription: string
  numberSlots: number
  numberFilledSlots: number
  numberActivatedSlots: number
  created: moment.Moment
}

export interface PackageReport {
  packageId: string
  organizationId: string
  organizationName: string
  accountId: string
  accountName: string
  price: number
  priceDescription: string
  numberSlots: number
  numberFilledSlots: number
  numberActivatedSlots: number
  lastInvoiceAmount: number
  lastInvoiceDate: moment.Moment
  expectedNextInvoiceAmount: number
  expectedNextInvoiceDate: moment.Moment
  actualNextInvoiceAmount?: number
  actualNextInvoiceDate?: moment.Moment
  actualNextInvoiceStatus?: string
  created: moment.Moment
}

export interface PackageReportChange {
  daysAgo: number
  change: string
  packageId: string
  organizationId: string
  organizationName: string
  accountId: string
  accountName: string
  price: number
  priceDescription: string
  numberSlots: number
  numberFilledSlots: number
  numberActivatedSlots: number
  created: moment.Moment
}

export interface SubscriptionReport {
  subscriptionId: string
  organizationId: string
  organizationName: string
  price: number
  priceDescription: string
  subscriptionStatus: string
}

export interface ExecutiveDashboardCampaignSum {
  forDate: moment.Moment
  totalActiveCampaigns: number
  totalDailyRevenue: number
  totalMonthlyRevenue: number
  totalAnnualRevenue: number
  totalWeeklyOngoingRecurringDailyRevenue: number
  totalWeeklyOngoingNonrecurringDailyRevenue: number
  totalWeeklyOngoingRecurringMonthlyRevenue: number
  totalWeeklyOngoingNonrecurringMonthlyRevenue: number
  totalWeeklyOngoingRecurringAnnualRevenue: number
  totalWeeklyOngoingNonrecurringAnnualRevenue: number
  totalFixedDurationNonrecurringDailyRevenue: number
  totalFixedDurationNonrecurringMonthlyRevenue: number
  totalFixedDurationNonrecurringAnnualRevenue: number
  totalOtherRecurringDailyRevenue: number
  totalOtherNonrecurringDailyRevenue: number
  totalOtherRecurringMonthlyRevenue: number
  totalOtherNonrecurringMonthlyRevenue: number
  totalOtherRecurringAnnualRevenue: number
  totalOtherNonrecurringAnnualRevenue: number
}

export interface ExecutiveDashboardPlanSum {
  forDate: moment.Moment
  totalActivePlans: number
  totalOnboardingPlans: number
  totalProblem1MonthPlans: number
  totalProblem2MonthPlans: number
  totalLostPlans: number
  totalActiveDailyRevenue: number
  totalActiveMonthlyRevenue: number
  totalActiveAnnualRevenue: number
  totalOnboardingDailyRevenue: number
  totalOnboardingMonthlyRevenue: number
  totalOnboardingAnnualRevenue: number
  totalProblem1MonthDailyRevenue: number
  totalProblem1MonthMonthlyRevenue: number
  totalProblem1MonthAnnualRevenue: number
  totalProblem2MonthDailyRevenue: number
  totalProblem2MonthMonthlyRevenue: number
  totalProblem2MonthAnnualRevenue: number
  totalLostDailyRevenue: number
  totalLostMonthlyRevenue: number
  totalLostAnnualRevenue: number
}

export interface ExecutiveDashboardPackageSum {
  forDate: moment.Moment
  totalActivePackages: number
  totalOnboardingPackages: number
  totalProblemPackages: number
  totalActiveDailyRevenue: number
  totalActiveMonthlyRevenue: number
  totalActiveAnnualRevenue: number
  totalOnboardingDailyRevenue: number
  totalOnboardingMonthlyRevenue: number
  totalOnboardingAnnualRevenue: number
  totalProblemDailyRevenue: number
  totalProblemMonthlyRevenue: number
  totalProblemAnnualRevenue: number
}

export interface ExecutiveDashboardSubscriptionSum {
  forDate: moment.Moment
  totalActiveSubscriptions: number
  totalTrialingSubscriptions: number
  totalActiveDailyRevenue: number
  totalActiveMonthlyRevenue: number
  totalActiveAnnualRevenue: number
  totalTrialingDailyRevenue: number
  totalTrialingMonthlyRevenue: number
  totalTrialingAnnualRevenue: number
}

export type timespanKeyCampaign = { [P in keyof CampaignReportRevenue]: CampaignReportRevenue[P] extends number ? P : never }[keyof CampaignReportRevenue]
export type timespanSumKeyCampaign = { [P in keyof ExecutiveDashboardCampaignSum]: ExecutiveDashboardCampaignSum[P] extends number ? P : never }[keyof ExecutiveDashboardCampaignSum]

export type timespanKeyExecPlan = { [P in keyof ExecutiveDashboardPlan]: ExecutiveDashboardPlan[P] extends number ? P : never }[keyof ExecutiveDashboardPlan]
export type timespanKeyPlan = { [P in keyof PlanReportRevenue]: PlanReportRevenue[P] extends number ? P : never }[keyof PlanReportRevenue]
export type timespanSumKeyPlan = { [P in keyof ExecutiveDashboardPlanSum]: ExecutiveDashboardPlanSum[P] extends number ? P : never }[keyof ExecutiveDashboardPlanSum]

export type timespanKeyExecPackage = { [P in keyof ExecutiveDashboardPackage]: ExecutiveDashboardPackage[P] extends number ? P : never }[keyof ExecutiveDashboardPackage]
export type timespanKeyPackage = { [P in keyof PackageReportRevenue]: PackageReportRevenue[P] extends number ? P : never }[keyof PackageReportRevenue]
export type timespanSumKeyPackage = { [P in keyof ExecutiveDashboardPackageSum]: ExecutiveDashboardPackageSum[P] extends number ? P : never }[keyof ExecutiveDashboardPackageSum]

export type timespanKeySubscription = { [P in keyof SubscriptionReportRevenue]: SubscriptionReportRevenue[P] extends number ? P : never }[keyof SubscriptionReportRevenue]
export type timespanSumKeySubscription = { [P in keyof ExecutiveDashboardSubscriptionSum]: ExecutiveDashboardSubscriptionSum[P] extends number ? P : never }[keyof ExecutiveDashboardSubscriptionSum]
