import moment from 'moment'
import React, { FC, useState } from 'react'

import { EuiFieldSearch, EuiInMemoryTable, EuiLink, EuiPageTemplate, EuiSpacer, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign } from 'api'
import { useGetActiveCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { getCampaignStatusReadable } from 'utils/EnumToFriendly'
import { localTimestamp } from 'utils/dateUtils'
import useDebounce from 'utils/useDebounce'

const AllActiveCampaignsPage: FC = () => {
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const { isLoading: isLoadingCampaigns, data: campaigns } = useGetActiveCampaignsQuery({ query: debouncedSearch })

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/campaigns/${c.campaignId}`} target='_blank'>
          {c.campaignName}
        </EuiLink>
      )
    },
    {
      name: 'Account',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/accounts/${c.accountId}`} target='_blank'>
          {c.accountName}
        </EuiLink>
      )
    },
    {
      name: 'Organization',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/organizations/${c.organizationId}`} target='_blank'>
          {c.organizationName}
        </EuiLink>
      )
    },
    {
      name: `Campaign Type`,
      render: (c: Campaign) => c.campaignType,
      width: '130'
    },
    {
      name: `Targeting Type`,
      render: (c: Campaign) => c.targetingTemplate,
      width: '130'
    },
    {
      name: `Status`,
      render: (c: Campaign) => getCampaignStatusReadable(c),
      width: '130'
    },
    {
      name: `Budget`,
      render: (c: Campaign) => formatter.format(c.budget) + ' ' + c.budgetFrequency?.toLowerCase(),
      sortable: (c: Campaign) => c.budget,
      width: '130'
    },
    {
      name: `Activation Date`,
      render: (c: Campaign) => <React.Fragment>{c.activated && localTimestamp(c.activated)}</React.Fragment>,
      sortable: (c: Campaign) => (c.activated ? moment(c.activated, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString() : null),
      width: '230'
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'Budget',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='Active Campaigns' restrictWidth={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiFieldSearch fullWidth placeholder='Search for a campaign.' value={search} isLoading={isLoadingCampaigns} onChange={v => onSearchChange(v.target.value)} isClearable={true} incremental={true} />
        <EuiSpacer />
        <EuiInMemoryTable loading={isLoadingCampaigns} items={campaigns ?? []} columns={columns} sorting={sorting} pagination={pagination} />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default AllActiveCampaignsPage
