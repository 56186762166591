import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { Formik, FormikHelpers } from 'formik'
import Konva from 'konva'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiSelect, EuiButton, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiLoadingContent, EuiSpacer, EuiText, EuiTitle, EuiSuperSelect, EuiFieldNumber, EuiColorPicker, EuiFormRow, EuiLink } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { useOpsClient, BillboardImageComponent, BillboardTextComponent, AdImagePlacement } from 'api'
import { AcCheckBoxGroup, IAcCheckBoxOption } from 'components/Collections/AcCheckBoxGroup'
import { BillboardEditor } from 'components/KonvaTools/BillboardEditor'

interface BillboardDetailsTabParams {
  billboardId: string
}

export enum BillboardAdImageType {
  None = 0,
  Square = 1,
  Rectangle = 2
}

export const GetAdWidthForAdImageType = (type: BillboardAdImageType) => {
  const BillboardSizesForAd = {
    Rectangle: 765,
    Square: 400,
    None: 0
  }
  switch (type) {
    case BillboardAdImageType.None:
      return BillboardSizesForAd.None
    case BillboardAdImageType.Rectangle:
      return BillboardSizesForAd.Rectangle
    case BillboardAdImageType.Square:
      return BillboardSizesForAd.Square
    default:
      return BillboardSizesForAd.None
  }
}

interface FormValues {
  headline: BillboardTextComponent
  photo: BillboardImageComponent
  backgroundUrl: string
  logo: BillboardImageComponent
  companyName: BillboardTextComponent
  phone: BillboardTextComponent
  website: BillboardTextComponent
  location: BillboardTextComponent
  previewUrl: string
  billboardImageType: BillboardAdImageType
  imageLocation: AdImagePlacement
}

const BillboardSpecificBuilderTab: React.FC<BillboardDetailsTabParams> = ({ billboardId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [destination, setDestination] = useState<string | null>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const opsClient = useOpsClient()
  const stageRef = React.useRef<Konva.Stage>(null)
  const [selected, setSelected] = useState<boolean>(true)
  const [takingPicture, setTakingPicture] = useState<boolean>(false)

  const defaultPhotoWidth: number = 200
  const defaultPhotoHeight: number = 200
  const defaultLogoWidth: number = 144
  const defaultLogoHeight: number = 144
  const defaultTextContentWidth: number = BillboardAdImageType.None || BillboardAdImageType.Rectangle ? 665 : BillboardAdImageType.Square ? 300 : 300
  const defaultTextContentHeight: number = 60
  const defaultTextAlign: string = 'left'

  const [initial, setInitial] = useState<FormValues>({
    backgroundUrl: '',
    headline: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Headline}',
      isDragging: false,
      x: 50,
      y: 50,
      width: defaultTextContentWidth,
      height: defaultTextContentHeight,
      align: defaultTextAlign
    },
    photo: {
      selected: false,
      sampleUrl: '/billboardPlaceholderAvatar.png',
      isDragging: false,
      width: defaultPhotoWidth,
      height: defaultPhotoHeight,
      x: 300,
      y: 50
    },
    logo: {
      selected: false,
      sampleUrl: '/billboardPlaceholderLogo.png',
      isDragging: false,
      width: defaultLogoWidth,
      height: defaultLogoHeight,
      x: 50,
      y: 270
    },
    companyName: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Company Name}',
      isDragging: false,
      x: 50,
      y: 110,
      width: defaultTextContentWidth,
      height: defaultTextContentHeight,
      align: defaultTextAlign
    },
    phone: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Phone}',
      isDragging: false,
      x: 50,
      y: 170,
      width: defaultTextContentWidth,
      height: defaultTextContentHeight,
      align: defaultTextAlign
    },
    website: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Website}',
      isDragging: false,
      x: 50,
      y: 230,
      width: defaultTextContentWidth,
      height: defaultTextContentHeight,
      align: defaultTextAlign
    },
    location: {
      selected: false,
      fontFace: 'roboto',
      fontSize: 60,
      fontColor: '#000000',
      sampleText: '{Location}',
      isDragging: false,
      x: 50,
      y: 290,
      width: defaultTextContentWidth,
      height: defaultTextContentHeight,
      align: defaultTextAlign
    },
    previewUrl: '',
    billboardImageType: BillboardAdImageType.None,
    imageLocation: 0
  })

  const billboardSchema = Yup.object().shape({
    backgroundUrl: Yup.string().required('Please drop image here')
  })

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsClient, billboardId])

  useEffect(() => {
    if (opsClient && billboardId) {
      opsClient.getBillboardUpload(billboardId).then(data => setDestination(data))
    }
  }, [opsClient, billboardId])

  useEffect(() => {
    if (destination) {
      const storageClient = new BlobServiceClient(destination)
      setContainerClient(storageClient.getContainerClient(billboardId))
    }
  }, [destination, billboardId])

  const checkboxes = [
    {
      key: 'Headline',
      text: 'Headline',
      checked: initial.headline.selected
    },
    {
      key: 'Company Name',
      text: 'Company Name',
      checked: initial.companyName.selected
    },
    {
      key: 'Phone',
      text: 'Phone',
      checked: initial.phone.selected
    },
    {
      key: 'Logo',
      text: 'Logo',
      checked: initial.logo.selected
    },
    {
      key: 'Photo',
      text: 'Photo',
      checked: initial.photo.selected
    },
    {
      key: 'Website',
      text: 'Website',
      checked: initial.website.selected
    },
    {
      key: 'Location',
      text: 'Location',
      checked: initial.location.selected
    }
  ]

  const onCheckboxSelected = (option: IAcCheckBoxOption) => {
    const tempInitial = { ...initial }
    switch (option.key) {
      case 'Phone':
        tempInitial.phone.selected = option.checked
        break
      case 'Headline':
        tempInitial.headline.selected = option.checked
        break
      case 'Company Name':
        tempInitial.companyName.selected = option.checked
        break
      case 'Logo':
        tempInitial.logo.selected = option.checked
        break
      case 'Photo':
        tempInitial.photo.selected = option.checked
        break
      case 'Website':
        tempInitial.website.selected = option.checked
        break
      case 'Location':
        tempInitial.location.selected = option.checked
        break
      default:
        break
    }
    setInitial(tempInitial)
  }

  const onBackgroundImageFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploading(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          const tempBillboard = { ...initial }
          tempBillboard.backgroundUrl = url
          setInitial(tempBillboard)
          setIsUploading(false)
        })
    }
  }

  const refresh = () => {
    setIsLoading(true)
    opsClient!.getBillboard(billboardId).then(result => {
      if (result.backgroundUrl) {
        setInitial({
          backgroundUrl: result.backgroundUrl,
          logo: {
            selected: result.logo.selected,
            sampleUrl: result.logo.sampleUrl,
            isDragging: initial.logo.isDragging,
            width: result.logo.width,
            height: result.logo.height,
            x: result.logo.x,
            y: result.logo.y
          },
          photo: {
            selected: result.photo.selected,
            sampleUrl: result.photo.sampleUrl,
            isDragging: initial.photo.isDragging,
            width: result.photo.width,
            height: result.photo.height,
            x: result.photo.x,
            y: result.photo.y
          },
          companyName: {
            selected: result.companyName.selected,
            fontFace: result.companyName.fontFace,
            fontSize: result.companyName.fontSize,
            fontColor: result.companyName.fontColor,
            sampleText: result.companyName.sampleText,
            isDragging: initial.companyName.isDragging,
            x: result.companyName.x,
            y: result.companyName.y,
            width: result.companyName.width,
            height: result.companyName.height,
            align: result.companyName.align
          },
          headline: {
            selected: result.headline.selected,
            fontFace: result.headline.fontFace,
            fontSize: result.headline.fontSize,
            fontColor: result.headline.fontColor,
            sampleText: result.headline.sampleText,
            isDragging: initial.headline.isDragging,
            x: result.headline.x,
            y: result.headline.y,
            width: result.headline.width,
            height: result.headline.height,
            align: result.headline.align
          },
          phone: {
            selected: result.phone.selected,
            fontFace: result.phone.fontFace,
            fontSize: result.phone.fontSize,
            fontColor: result.phone.fontColor,
            sampleText: result.phone.sampleText,
            isDragging: initial.phone.isDragging,
            x: result.phone.x,
            y: result.phone.y,
            width: result.phone.width,
            height: result.phone.height,
            align: result.phone.align
          },
          website: {
            selected: result.website.selected,
            fontFace: result.website.fontFace,
            fontSize: result.website.fontSize,
            fontColor: result.website.fontColor,
            sampleText: result.website.sampleText,
            isDragging: initial.website.isDragging,
            x: result.website.x,
            y: result.website.y,
            width: result.website.width,
            height: result.website.height,
            align: result.website.align
          },
          location: {
            selected: result.location.selected,
            fontFace: result.location.fontFace,
            fontSize: result.location.fontSize,
            fontColor: result.location.fontColor,
            sampleText: result.location.sampleText,
            isDragging: initial.location.isDragging,
            x: result.location.x,
            y: result.location.y,
            width: result.location.width,
            height: result.location.height,
            align: result.location.align
          },
          previewUrl: result.previewUrl,
          billboardImageType: result.billboardImageType,
          imageLocation: 0
        })
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (!selected && takingPicture && stageRef.current) {
      const billboardClone = { ...initial, previewUrl: stageRef.current?.toDataURL() ?? '' }
      opsClient?.updateBillboard(billboardId, billboardClone).then(() => {
        setSelected(true)
        setTakingPicture(false)
      })
    }
  }, [selected, takingPicture, initial, billboardId, opsClient])

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSelected(false)
    setTakingPicture(true)
    setSubmitting(false)
  }

  const fontFaces: EuiSuperSelectOption<string>[] = [
    { value: 'roboto', inputDisplay: <span style={{ fontFamily: 'Roboto' }}>Roboto</span> },
    { value: 'noto serif', inputDisplay: <span style={{ fontFamily: 'Noto Serif' }}>Noto Serif</span> },
    {
      value: 'cormorant garamond',
      inputDisplay: <span style={{ fontFamily: 'Cormorant Garamond' }}>Cormorant Garamond</span>
    },
    { value: 'amatic sc', inputDisplay: <span style={{ fontFamily: 'Amatic SC' }}>Amatic SC</span> },
    {
      value: 'Frank Ruhl Libre',
      inputDisplay: <span style={{ fontFamily: 'Frank Ruhl Libre' }}>Frank Ruhl Libre</span>
    },
    { value: 'Merienda', inputDisplay: <span style={{ fontFamily: 'Merienda' }}>Merienda</span> },
    { value: 'Noticia Text', inputDisplay: <span style={{ fontFamily: 'Noticia Text' }}>Noticia Text</span> },
    { value: 'Comfortaa', inputDisplay: <span style={{ fontFamily: 'Comfortaa' }}>Comfortaa</span> },
    { value: 'alfa slab one', inputDisplay: <span style={{ fontFamily: 'Alfa Slab One' }}>Alfa Slab One</span> }
  ]

  const alignOptions: EuiSuperSelectOption<string>[] = [
    { value: 'left', inputDisplay: <span>Left</span> },
    { value: 'center', inputDisplay: <span>Center</span> },
    { value: 'right', inputDisplay: <span>Right</span> }
  ]

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }
  const billboardTypeDropDownOptions: EuiSelectOption[] = [
    { value: BillboardAdImageType.None, text: 'Full Billboard' },
    { value: BillboardAdImageType.Square, text: 'Square Ad' },
    { value: BillboardAdImageType.Rectangle, text: 'Rectangle Ad' }
  ]

  const onSelectBillboardType = (e: any) => {
    const tempInitial = { ...initial }
    tempInitial.billboardImageType = parseInt(e.target.value)
    setInitial(tempInitial)
  }

  const GetLabelForBillboardType = () => {
    return 1400 - GetAdWidthForAdImageType(initial.billboardImageType) + ' x 400'
  }

  return (
    <React.Fragment>
      <link href='https://fonts.googleapis.com/css2?family=Roboto&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Merienda&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Noticia+Text&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Comfortaa&display=swap' rel='stylesheet' />
      <link href='https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Comfortaa&display=swap' rel='stylesheet' />
      <EuiSpacer />
      {initial && (
        <EuiFlexGroup>
          <EuiFlexItem>
            <Formik initialValues={initial} enableReinitialize validationSchema={billboardSchema} onSubmit={doSubmit}>
              {props => (
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiSpacer />
                  {!initial?.backgroundUrl && (
                    <EuiText size='xs'>
                      <strong>Background Url:</strong>
                    </EuiText>
                  )}
                  {initial?.backgroundUrl && (
                    <EuiFormRow label={`Background Url (${GetLabelForBillboardType()})`}>
                      <EuiLink target='_blank' href={initial?.backgroundUrl}>
                        {initial?.backgroundUrl.split('/').pop()}
                      </EuiLink>
                    </EuiFormRow>
                  )}
                  <EuiFilePicker id='backgroundUrl' display='default' multiple={false} isInvalid={!!props.errors.backgroundUrl} initialPromptText={!initial?.backgroundUrl ? 'Insert background image here' : 'Replace background image by dropping new one here'} onChange={onBackgroundImageFileChange} isLoading={isUploading} />
                  <EuiSpacer size='m' />
                  <EuiSpacer size='m' />
                  <EuiFormRow label='Billboard Style'>
                    <EuiSelect name='billboardType' options={billboardTypeDropDownOptions} value={initial.billboardImageType} onChange={onSelectBillboardType} />
                  </EuiFormRow>
                  <EuiSpacer size='m' />
                  <AcCheckBoxGroup options={checkboxes} onChange={onCheckboxSelected} />
                  <EuiSpacer />
                  {initial.headline.selected && (
                    <React.Fragment>
                      <EuiFormRow label='Headline Font' fullWidth error={initial?.headline?.fontFace || initial?.headline?.fontSize}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              options={fontFaces}
                              valueOfSelected={initial.headline?.fontFace}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  headline: {
                                    ...prevState.headline,
                                    fontFace: e
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFieldNumber
                              name='headlineFontSize'
                              value={initial.headline?.fontSize}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  headline: {
                                    ...prevState.headline,
                                    fontSize: parseInt(e.target.value)
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiColorPicker
                              color={initial.headline?.fontColor}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  headline: {
                                    ...prevState.headline,
                                    fontColor: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              fullWidth
                              name='headlineAlign'
                              valueOfSelected={initial.headline?.align}
                              options={alignOptions}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  headline: {
                                    ...prevState.headline,
                                    align: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  {initial.companyName.selected && (
                    <React.Fragment>
                      <EuiFormRow label='Company Name Font' fullWidth error={initial?.companyName?.fontFace || initial?.companyName?.fontSize}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              options={fontFaces}
                              valueOfSelected={initial.companyName?.fontFace}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  companyName: {
                                    ...prevState.companyName,
                                    fontFace: e
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFieldNumber
                              name='companyNameFontSize'
                              value={initial.companyName?.fontSize}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  companyName: {
                                    ...prevState.companyName,
                                    fontSize: parseInt(e.target.value)
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiColorPicker
                              color={initial.companyName?.fontColor}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  companyName: {
                                    ...prevState.companyName,
                                    fontColor: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              fullWidth
                              name='companyNameAlign'
                              valueOfSelected={initial.companyName?.align}
                              options={alignOptions}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  companyName: {
                                    ...prevState.companyName,
                                    align: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  {initial.phone.selected && (
                    <React.Fragment>
                      <EuiFormRow label='Phone Font' fullWidth error={initial?.phone?.fontFace || initial?.phone?.fontSize}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              options={fontFaces}
                              valueOfSelected={initial.phone?.fontFace}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  phone: {
                                    ...prevState.phone,
                                    fontFace: e
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFieldNumber
                              name='phoneFontSize'
                              value={initial.phone?.fontSize}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  phone: {
                                    ...prevState.phone,
                                    fontSize: parseInt(e.target.value)
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiColorPicker
                              color={initial.phone?.fontColor}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  phone: {
                                    ...prevState.phone,
                                    fontColor: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              fullWidth
                              name='phoneAlign'
                              valueOfSelected={initial.phone?.align}
                              options={alignOptions}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  phone: {
                                    ...prevState.phone,
                                    align: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  {initial.website.selected && (
                    <React.Fragment>
                      <EuiFormRow label='Website Font' fullWidth error={initial?.website?.fontFace || initial?.website?.fontSize}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              options={fontFaces}
                              valueOfSelected={initial.website?.fontFace}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  website: {
                                    ...prevState.website,
                                    fontFace: e
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFieldNumber
                              name='websiteFontSize'
                              value={initial.website?.fontSize}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  website: {
                                    ...prevState.website,
                                    fontSize: parseInt(e.target.value)
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiColorPicker
                              color={initial.website?.fontColor}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  website: {
                                    ...prevState.website,
                                    fontColor: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              fullWidth
                              name='websiteAlign'
                              valueOfSelected={initial.website?.align}
                              options={alignOptions}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  website: {
                                    ...prevState.website,
                                    align: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  {initial.location.selected && (
                    <React.Fragment>
                      <EuiFormRow label='Location Font' fullWidth error={initial?.location?.fontFace || initial?.location?.fontSize}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              options={fontFaces}
                              valueOfSelected={initial.location?.fontFace}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  location: {
                                    ...prevState.location,
                                    fontFace: e
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiFieldNumber
                              name='locationFontSize'
                              value={initial.location?.fontSize}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  location: {
                                    ...prevState.location,
                                    fontSize: parseInt(e.target.value)
                                  }
                                }))
                              }}
                              fullWidth
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiColorPicker
                              color={initial.location?.fontColor}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  location: {
                                    ...prevState.location,
                                    fontColor: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiSuperSelect
                              fullWidth
                              name='locationAlign'
                              valueOfSelected={initial.location?.align}
                              options={alignOptions}
                              onChange={e => {
                                setInitial(prevState => ({
                                  ...prevState,
                                  location: {
                                    ...prevState.location,
                                    align: e
                                  }
                                }))
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  <EuiFlexGroup gutterSize='s'>
                    <EuiFlexItem grow={false}>
                      <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={isUploading}>
                        Save
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='success' iconType='refresh' onClick={refresh} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiForm>
              )}
            </Formik>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size='m'>
              <h4>Preview</h4>
            </EuiTitle>
            <EuiSpacer size='m' />
            <BillboardEditor billboard={initial} onUpdateBillboard={setInitial} chosenLogo={initial.logo.sampleUrl ?? undefined} chosenPhoto={initial.photo.sampleUrl ?? undefined} selected={selected} stageRef={stageRef} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </React.Fragment>
  )
}

export default BillboardSpecificBuilderTab
