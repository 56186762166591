import moment from 'moment-timezone'
import React, { FC, useState } from 'react'

import { EuiButtonEmpty, EuiInMemoryTable, EuiLink, EuiLoadingSpinner, EuiPageTemplate, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { OrganizationRosters } from 'api/entities/OrganizationRosters'
import { useGetOrganizationRostersQuery } from 'api/rtkQueryApi/opsApi/organizationRostersApi'
import { AcTab } from 'components/ACTools/AcTab'
import history from 'services/HistoryService'

const OrganizationRostersPage: FC = () => {
  const [tab, setTab] = useState('direct')
  const { isLoading, data } = useGetOrganizationRostersQuery()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const tabs: AcTab[] = [
    { label: 'Direct', isSelected: tab === 'direct', onClick: () => setTab('direct') },
    { label: 'Agency', isSelected: tab === 'agency', onClick: () => setTab('agency') },
    { label: 'Partner', isSelected: tab === 'partner', onClick: () => setTab('partner') }
  ]

  const columns: Array<EuiBasicTableColumn<OrganizationRosters>> = [
    {
      name: 'Organization',
      render: (o: OrganizationRosters) => (
        <EuiLink href={`/customers/organizations/${o.organizationId}`} target='_blank'>
          {o.organizationName}
        </EuiLink>
      )
    },
    {
      name: 'Signup Date',
      field: 'signupDate',
      render: (signupDate: string) => (!signupDate || signupDate.length === 0 ? null : moment.utc(signupDate)?.format('DD MMM yyyy')),
      sortable: true
    },
    {
      name: 'Average Monthly Value',
      field: 'avgMonthlyValue',
      render: (avgMonthlyValue: number) => formatter.format(avgMonthlyValue),
      sortable: true
    },
    {
      name: 'Lifetime Monthly Value',
      field: 'lifeTimeValue',
      render: (lifeTimeValue: number) => formatter.format(lifeTimeValue),
      sortable: true
    }
  ]

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'lifeTimeValue',
      direction: SortDirection.DESC
    }
  }

  return (
    <EuiPageTemplate panelled={true}>
      <EuiPageTemplate.Header iconType='advancedSettingsApp' pageTitle='Organization Rosters' tabs={tabs} restrictWidth={false} bottomBorder={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        {tab === 'direct' && <EuiInMemoryTable loading={isLoading} items={data?.direct ?? []} columns={columns} pagination={pagination} sorting={sorting} />}
        {tab === 'agency' && <EuiInMemoryTable loading={isLoading} items={data?.agency ?? []} columns={columns} pagination={pagination} sorting={sorting} />}
        {tab === 'partner' && <EuiInMemoryTable loading={isLoading} items={data?.partner ?? []} columns={columns} pagination={pagination} sorting={sorting} />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default OrganizationRostersPage
