import numeral from 'numeral'
import React from 'react'

import { EuiBadge, EuiIcon, EuiListGroup, EuiSpacer, EuiToolTip } from '@elastic/eui'

import { ThirdPartyAudience } from 'api'

interface AudienceTargeterProps {
  audiences: Array<ThirdPartyAudience>

  onAudienceRemoved(audience: ThirdPartyAudience): void
}

export const AudienceTargeterDetailed = ({ audiences, onAudienceRemoved }: AudienceTargeterProps) => {
  return (
    <React.Fragment>
      {audiences && audiences.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s'>
          {audiences.map(w => (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 5px' }} key={w.id.toString()}>
              <div>
                <b>{w.name}</b>
                <EuiSpacer size={'s'} />
                <div style={{ paddingLeft: '10px', fontSize: '0.8em' }}>{w.hierarchy.join(' \\ ')}</div>
                <EuiSpacer size={'s'} />
                <div style={{ paddingLeft: '10px', fontSize: '0.8em' }}>{w.description}</div>
              </div>
              <div style={{ minWidth: '150px', display: 'flex', justifyContent: 'end' }}>
                <div style={{ paddingRight: '8px' }}>
                  <EuiToolTip title='Audience reach'>
                    <EuiBadge>{w.reachCount > 0 ? numeral(w.reachCount).format('0a') : '~'} people</EuiBadge>
                  </EuiToolTip>
                </div>
                <EuiIcon type='cross' onClick={() => onAudienceRemoved(w)} />
              </div>
            </div>
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
