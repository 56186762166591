import moment from 'moment'
import React, { FC } from 'react'

import { EuiBasicTableColumn, EuiInMemoryTable, EuiLink, SortDirection } from '@elastic/eui'

import { OrganizationProposal } from 'api'

export interface IOrganizationProposalsGridPagination {
  initialPageSize: number
  pageSizeOptions: number[]
}

export interface IOrganizationProposalsGridSorting {
  allowNeutralSort: boolean
  enableAllColumns: boolean
  sort: IOrganizationProposalsGridSort
}

export interface IOrganizationProposalsGridSort {
  field: string
  direction: 'asc' | 'desc'
}

export interface OrganizationProposalsGridProps {
  isLoading: boolean
  data?: OrganizationProposal[]
  appendedColumns?: EuiBasicTableColumn<OrganizationProposal>[]
  sorting?: IOrganizationProposalsGridSorting
  pagination?: IOrganizationProposalsGridPagination
}

const OrganizationProposalsGrid: FC<OrganizationProposalsGridProps> = ({ isLoading, data, appendedColumns, sorting, pagination }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const columns: EuiBasicTableColumn<OrganizationProposal>[] = [
    {
      name: 'Organization Name',
      render: (p: OrganizationProposal) => (
        <EuiLink href={`/customers/organizations/${p.organizationId}/agreement`} target='_blank'>
          {p.organizationName}
        </EuiLink>
      )
    },
    {
      name: 'First Name',
      field: 'firstName',
      sortable: true
    },
    {
      name: 'Last Name',
      field: 'lastName',
      sortable: true
    },
    {
      name: 'Email',
      field: 'email',
      render: (email: string) => (
        <EuiLink href={`mailto:${email}`} target='_top'>
          {email}
        </EuiLink>
      ),
      width: '250px',
      sortable: true
    },
    {
      name: 'Phone',
      field: 'phone',
      width: '150px',
      sortable: true
    },
    {
      name: 'Value',
      render: (p: OrganizationProposal) => `${formatter.format(p.planPrice)}/${p.planFrequency.toLowerCase()}`,
      sortable: (p: OrganizationProposal) => p.planPrice
    },
    {
      name: 'Initial Offer Date',
      render: (p: OrganizationProposal) => (p.created ? moment(p.created).calendar() : ''),
      sortable: (p: OrganizationProposal) => (p.created ? moment(p.created).calendar() : '')
    },
    {
      name: 'Last Contacted',
      field: 'lastContacted',
      render: (lastContacted: moment.Moment) => (lastContacted ? moment(lastContacted).calendar() : ''),
      sortable: (p: OrganizationProposal) => (p.lastContacted ? moment(p.lastContacted).valueOf() : Number.MAX_SAFE_INTEGER)
    },
    {
      name: 'Last Contacted By',
      field: 'lastContactedBy',
      sortable: true
    },
    ...(appendedColumns ? [...appendedColumns] : [])
  ]

  const defaultPagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  const defaultSorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'lastContacted',
      direction: SortDirection.DESC
    }
  }
  return (
    <React.Fragment>
      <EuiInMemoryTable loading={isLoading} items={data ?? []} columns={columns} sorting={sorting ?? defaultSorting} pagination={pagination ?? defaultPagination} tableLayout='fixed' />
    </React.Fragment>
  )
}

export default OrganizationProposalsGrid
