import React from 'react'

import { EuiBadge, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

export interface IAcBadgeGroup {
  items: string[]
  onAddClick?: () => void
  onRemoveClick?: (item: string) => void
}

export const AcBadgeGroup: React.FC<IAcBadgeGroup> = props => {
  return (
    <React.Fragment>
      <EuiFlexGroup wrap gutterSize={'xs'}>
        {props.items.map((item, index) => (
          <EuiFlexItem key={index} grow={false}>
            {props.onRemoveClick ? (
              <EuiBadge
                color={'default'}
                iconType='cross'
                iconSide='right'
                iconOnClick={() => {
                  props.onRemoveClick?.(item)
                }}
                iconOnClickAriaLabel={'Remove'}>
                {item}
              </EuiBadge>
            ) : (
              <EuiBadge color={'default'}>{item}</EuiBadge>
            )}
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
      {props.onAddClick && (
        <EuiButtonEmpty onClick={props.onAddClick} iconType='plus'>
          Add
        </EuiButtonEmpty>
      )}
    </React.Fragment>
  )
}
