import React, { useEffect } from 'react'

import { EuiFlyoutBody, EuiFlyout, EuiFlyoutHeader, EuiFormRow, EuiText, EuiTitle, EuiButton, EuiLoadingSpinner, EuiFlexGroup, EuiFlexItem, EuiComboBox, EuiComboBoxOptionOption, EuiLink } from '@elastic/eui'

import { LocationTargetingType, useCopyTargetingMutation, useGetCampaignQuery, useGetCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { AcBadgeGroup } from 'components/Basic/AcBadgeGroup'
import { useGetCampaignTargeting } from 'utils/useGetCampaignTargeting'

export interface IImportCampaignTargetingFlyout {
  campaignId: string
  onPanelClose: () => void
}

export const ImportCampaignTargetingFlyout: React.FC<IImportCampaignTargetingFlyout> = props => {
  const campaignsQuery = useGetCampaignQuery(props.campaignId)
  const [copyCampaignTargeting, copyCampaignTargetingQuery] = useCopyTargetingMutation()
  const organizationCampaignsQuery = useGetCampaignsQuery({ organizationId: campaignsQuery.data?.organizationId ?? '' }, { skip: !campaignsQuery.data?.organizationId })
  const [selectedCampaignId, setSelectedCampaignId] = React.useState<string>()
  const [campaignOptions, setCampaignOptions] = React.useState<EuiComboBoxOptionOption[]>([])

  const campaignTargeting = useGetCampaignTargeting(selectedCampaignId)

  useEffect(() => {
    if (organizationCampaignsQuery.data && campaignsQuery.data) {
      const options = organizationCampaignsQuery.data.filter(c => c.campaignType === campaignsQuery.data?.campaignType && c.campaignId !== props.campaignId && c.advancedTargeting?.advancedTargetingType === campaignsQuery.data?.advancedTargeting?.advancedTargetingType).map(camp => ({ value: camp.campaignId, label: camp.campaignName }))
      setCampaignOptions(options)
      if (options.length > 0) {
        setSelectedCampaignId(options[0].value)
      }
    }
  }, [organizationCampaignsQuery.data, campaignsQuery.data])

  const onFlyoutClose = () => {
    props.onPanelClose()
  }

  const onCampaignSelected = (campaignId?: string) => {
    setSelectedCampaignId(campaignId)
  }

  const onImportTargetingClick = () => {
    if (selectedCampaignId) {
      copyCampaignTargeting({ copyToCampaignId: props.campaignId, copyFromCampaignId: selectedCampaignId }).then(() => {
        onFlyoutClose()
      })
    }
  }

  return (
    <React.Fragment>
      <EuiFlyout ownFocus onClose={onFlyoutClose} size={'s'}>
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h2>Import Targeting</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {organizationCampaignsQuery.isLoading || campaignsQuery.isLoading ? (
            <EuiLoadingSpinner size='m' />
          ) : (
            <EuiFormRow
              fullWidth
              label='Select the campaign to copy'
              helpText={
                selectedCampaignId ? (
                  <EuiLink href={`/customers/campaigns/${selectedCampaignId}`} target={'_blank'}>
                    Campaign
                  </EuiLink>
                ) : undefined
              }>
              <EuiComboBox
                options={campaignOptions}
                placeholder={'Select a campaign'}
                singleSelection={{ asPlainText: true }}
                selectedOptions={campaignOptions.filter(c => c.value === selectedCampaignId) ?? []}
                onChange={e => {
                  onCampaignSelected(e[0]?.value?.toString())
                }}
              />
            </EuiFormRow>
          )}
          {campaignTargeting && (
            <React.Fragment>
              <EuiFormRow fullWidth>
                <React.Fragment>
                  {campaignTargeting.locationTargetingType === LocationTargetingType.USA && (
                    <EuiTitle size='xs'>
                      <p>United States</p>
                    </EuiTitle>
                  )}
                  {campaignTargeting.locationTargetingType === LocationTargetingType.State && (
                    <React.Fragment>
                      <EuiTitle size='xs'>
                        <p>States</p>
                      </EuiTitle>
                      <AcBadgeGroup items={campaignTargeting.locations?.regions.map(s => `${s.name}, ${s.code}`) ?? []} />
                    </React.Fragment>
                  )}
                  {campaignTargeting.locationTargetingType === LocationTargetingType.DMA && (
                    <React.Fragment>
                      <EuiTitle size='xs'>
                        <p>DMAs</p>
                      </EuiTitle>
                      <AcBadgeGroup items={campaignTargeting.locations?.dmas.map(dma => dma.name) ?? []} />
                    </React.Fragment>
                  )}
                  {campaignTargeting.locationTargetingType === LocationTargetingType.City && (
                    <React.Fragment>
                      <EuiTitle size={'xs'}>
                        <h3>Cities</h3>
                      </EuiTitle>
                      <AcBadgeGroup items={campaignTargeting.locations?.cities.map(c => c.name) ?? []} />
                    </React.Fragment>
                  )}
                  {campaignTargeting.locationTargetingType === LocationTargetingType.Zip && (
                    <React.Fragment>
                      <EuiTitle size='xs'>
                        <p>Zips</p>
                      </EuiTitle>
                      <AcBadgeGroup items={campaignTargeting.locations?.postalCodes.map(z => z.name) ?? []} />
                    </React.Fragment>
                  )}
                  {campaignTargeting.locationTargetingType === LocationTargetingType.Address && (
                    <React.Fragment>
                      <EuiTitle size='xs'>
                        <p>Address (We cannot currently copy address lists)</p>
                      </EuiTitle>
                    </React.Fragment>
                  )}
                  {campaignTargeting.locationTargetingType === LocationTargetingType.Map && (
                    <React.Fragment>
                      <EuiTitle size='xs'>
                        <p>Geo Targeting</p>
                      </EuiTitle>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </EuiFormRow>
              <EuiFormRow fullWidth>
                <React.Fragment>
                  <EuiTitle size='xs'>
                    <p>Audiences</p>
                  </EuiTitle>
                  <EuiText>
                    <p>Need to update this UI</p>
                  </EuiText>
                </React.Fragment>
              </EuiFormRow>
              <EuiFormRow fullWidth>
                <EuiFlexGroup>
                  <EuiFlexItem />
                  <EuiFlexItem grow={false}>
                    <EuiButton fill onClick={onImportTargetingClick} isLoading={copyCampaignTargetingQuery.isLoading}>
                      Update Campaign's Targeting
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </React.Fragment>
          )}
        </EuiFlyoutBody>
      </EuiFlyout>
    </React.Fragment>
  )
}
