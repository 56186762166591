import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignType } from 'api'
import { RootState } from 'app/rootReducer'

export interface CreateCampaignSlotTypePanelProps {
  campaignType: CampaignType
  isBonusSlot?: boolean
  onCampaignTypeUpdate: (campaignType: CampaignType) => void
  onCancelClick?: () => void
}

const CreateCampaignSlotTypePanel: FC<CreateCampaignSlotTypePanelProps> = ({ campaignType, isBonusSlot, onCampaignTypeUpdate, onCancelClick }) => {
  const { isCSuite } = useSelector((state: RootState) => state.app)

  const campaignTypeOptions = Object.values(CampaignType)
    .filter(key => {
      return isCSuite || !isBonusSlot || (isBonusSlot && key === CampaignType.Internet)
    })
    .map(key => {
      return { value: key, text: key }
    })

  return (
    <EuiPanel hasShadow={false} hasBorder={true}>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiTitle size={'s'}>
            <h2>
              {isBonusSlot ? ' Matching ' : ' '}
              {campaignType} Campaign
            </h2>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon id='close' iconType='cross' onClick={onCancelClick} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFormRow fullWidth label={'Campaign Type'}>
        <EuiSelect
          options={campaignTypeOptions}
          value={campaignType}
          onChange={e => {
            onCampaignTypeUpdate(e.target.value as CampaignType)
          }}
        />
      </EuiFormRow>
    </EuiPanel>
  )
}

export default CreateCampaignSlotTypePanel
