import { useFormik } from 'formik'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiConfirmModal, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLink, EuiLoadingSpinner, EuiSelect, EuiSpacer, EuiStat } from '@elastic/eui'

import { CampaignType, Organization, PlanFrequency, ProposalType } from 'api'
import { BundleCategory, BundleType } from 'api/entities/Bundle'
import { useGetOrganizationProposalQuery, useLazyGetProposalViewsEstimateQuery, usePostOrganizationProposalMutation, usePostOrganizationProposalSendMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { OrganizationPaymentMethodsRadioGroup, PaymentMode } from 'components/Invoices/OrganizationPaymentMethodsRadioGroup'
import { BundleCategoryPicker } from 'features/customers/bundles/BundleCategoryPicker'

interface ProposalForm {
  firstName: string
  lastName: string
  company: string
  email: string
  phone?: string
  altPhone?: string
  website?: string
  proposalType: ProposalType
  planLength: number
  planFrequency: PlanFrequency
  planPrice: number
  planTVPrice?: number
  planBillboardPrice?: number
  planMatching: number
  planTVMatching?: number
  planBillboardMatching?: number
  planType?: BundleCategory
}

interface OrganizationDetailsTabParams {
  organization: Organization
  organizationIsFetching: boolean
}

const OrganizationProposalTab: React.FC<OrganizationDetailsTabParams> = ({ organization, organizationIsFetching }) => {
  const { isFetching: proposalIsFetching, data: proposal } = useGetOrganizationProposalQuery(organization.organizationId)
  const [postOrganizationProposal, postOrganizationProposalRequest] = usePostOrganizationProposalMutation()
  const [postOrganizationProposalSend, postOrganizationProposalSendRequest] = usePostOrganizationProposalSendMutation()
  const [triggerGetViewsEstimate, viewsEstimateResult] = useLazyGetProposalViewsEstimateQuery()

  const [proposalForm, setProposalFormForm] = useState<ProposalForm>({
    firstName: organization.firstName ?? '',
    lastName: organization.lastName ?? '',
    company: organization.organizationName,
    email: organization.email ?? '',
    proposalType: ProposalType.TV_Internet,
    planLength: 12,
    planFrequency: PlanFrequency.Month,
    planPrice: 1000,
    planMatching: 1000,
    phone: organization.phoneNumber ?? '',
    altPhone: '',
    website: '',
    planType: BundleCategory.Regular
  })

  const [isModalSendVisible, setIsModalSendVisible] = useState(false)

  const closeSendModal = () => setIsModalSendVisible(false)
  const showSendModal = () => setIsModalSendVisible(true)

  useEffect(() => {
    setProposalFormForm({
      firstName: organization.firstName ?? '',
      lastName: organization.lastName ?? '',
      company: organization.organizationName,
      email: organization.email ?? '',
      proposalType: ProposalType.TV_Internet,
      planLength: 12,
      planFrequency: PlanFrequency.Month,
      planPrice: 1000,
      planMatching: 1000,
      phone: organization.phoneNumber ?? '',
      altPhone: '',
      website: '',
      planType: BundleCategory.Regular
    })
  }, [organization])

  const onProposalTypeChange = (proposalType: ProposalType) => {
    let planTVPrice
    let planBillboardPrice

    if (proposalType === ProposalType.TVBB_Internet) {
      planTVPrice = formik.values.planPrice / 2
      planBillboardPrice = formik.values.planPrice / 2

      if (proposal?.proposalType === ProposalType.TVBB_Internet && formik.values.planPrice === proposal?.planPrice && proposal.planTVPrice && proposal.planBillboardPrice) {
        planTVPrice = proposal.planTVPrice
        planBillboardPrice = proposal.planBillboardPrice
      }
    }

    formik.setFieldValue('planTVPrice', planTVPrice)
    formik.setFieldValue('planBillboardPrice', planBillboardPrice)
    formik.setFieldValue('proposalType', proposalType)

    triggerGetViewsEstimate({
      planFrequency: formik.values.planFrequency,
      planPrice: formik.values.planPrice,
      planTVPrice: planTVPrice,
      planBillboardPrice: planBillboardPrice,
      planMatching: calculatePlanMatching(formik.values.planPrice, proposalType),
      campaignMatchingType: getCampaignMatchingType(proposalType)
    })
  }

  const onPlanFrequencyChange = (planFrequency: PlanFrequency) => {
    formik.setFieldValue('planFrequency', planFrequency)

    triggerGetViewsEstimate({
      planFrequency: planFrequency,
      planPrice: formik.values.planPrice,
      planMatching: calculatePlanMatching(formik.values.planPrice, formik.values.proposalType),
      campaignMatchingType: getCampaignMatchingType(formik.values.proposalType)
    })
  }

  const onPlanPriceChange = (value: number) => {
    let planPrice = value
    let campaignMatchingType

    switch (formik.values.proposalType) {
      case ProposalType.TV_BB:
        campaignMatchingType = CampaignType.Billboard
        break
      case ProposalType.BB_TV:
        campaignMatchingType = CampaignType.TV
        break
      default:
        campaignMatchingType = CampaignType.Internet
        break
    }

    formik.setFieldValue('planPrice', planPrice)

    triggerGetViewsEstimate({
      planFrequency: formik.values.planFrequency,
      planPrice: planPrice,
      planMatching: calculatePlanMatching(value, formik.values.proposalType),
      campaignMatchingType: campaignMatchingType
    })
  }

  const onPlanTVPriceChange = (value: number) => {
    const planBillboardPrice = parseInt(formik.values.planBillboardPrice?.toString() ?? '0')
    const newPlanPrice = value + planBillboardPrice

    formik.setFieldValue('planPrice', newPlanPrice)
    formik.setFieldValue('planTVPrice', value)

    triggerGetViewsEstimate({
      planFrequency: formik.values.planFrequency,
      planPrice: newPlanPrice,
      planTVPrice: value,
      planBillboardPrice: planBillboardPrice,
      planMatching: calculatePlanMatching(newPlanPrice, formik.values.proposalType),
      campaignMatchingType: getCampaignMatchingType(formik.values.proposalType)
    })
  }

  const onPlanBillboardPriceChange = (value: number) => {
    const planTVPrice = parseInt(formik.values.planTVPrice?.toString() ?? '0')
    const newPlanPrice = value + planTVPrice

    formik.setFieldValue('planPrice', newPlanPrice)
    formik.setFieldValue('planBillboardPrice', value)

    triggerGetViewsEstimate({
      planFrequency: formik.values.planFrequency,
      planPrice: newPlanPrice,
      planTVPrice: planTVPrice,
      planBillboardPrice: value,
      planMatching: calculatePlanMatching(newPlanPrice, formik.values.proposalType),
      campaignMatchingType: getCampaignMatchingType(formik.values.proposalType)
    })
  }

  const calculatePlanMatching = (planPrice: number, proposalType: ProposalType) => {
    return planPrice * (proposalTypes.find(pt => pt.value === proposalType)?.ratio ?? 1)
  }

  useEffect(() => {
    if (proposal) {
      setProposalFormForm({
        firstName: proposal.firstName,
        lastName: proposal.lastName,
        company: proposal.company ?? proposalForm?.company ?? organization.organizationName,
        email: proposal.email,
        phone: proposal.phone,
        altPhone: proposal.altPhone,
        website: proposal.website,
        proposalType: proposal.proposalType,
        planLength: proposal.planLength,
        planFrequency: proposal.planFrequency,
        planPrice: proposal.planPrice,
        planTVPrice: proposal.planTVPrice,
        planBillboardPrice: proposal.planBillboardPrice,
        planMatching: proposal.planMatching,
        planType: proposal.planType
      })

      triggerGetViewsEstimate({
        planFrequency: formik.values.planFrequency,
        planPrice: proposal.planPrice,
        planTVPrice: proposal.planTVPrice,
        planBillboardPrice: proposal.planBillboardPrice,
        planMatching: proposal.planMatching,
        campaignMatchingType: getCampaignMatchingType(proposal.proposalType)
      })
    } else {
      triggerGetViewsEstimate({
        planFrequency: formik.values.planFrequency,
        planPrice: proposalForm.planPrice,
        planMatching: calculatePlanMatching(proposalForm.planPrice, proposalForm.proposalType),
        campaignMatchingType: getCampaignMatchingType(proposalForm.proposalType)
      })
    }
  }, [proposal])

  const getCampaignMatchingType = (proposalType: ProposalType) => {
    switch (proposalType) {
      case ProposalType.TV_Internet:
      case ProposalType.BB_Internet:
      case ProposalType.TVBB_Internet:
      case ProposalType.Internet_Internet:
        return CampaignType.Internet
      case ProposalType.TV_BB:
        return CampaignType.Billboard
      case ProposalType.BB_TV:
        return CampaignType.TV
    }
  }

  const proposalSchema = Yup.object().shape({
    company: Yup.string().max(128).required('Please enter the company name'),
    email: Yup.string().email('Please enter a valid email').required('Please enter the email'),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    proposalType: Yup.string().required(),
    planFrequency: Yup.string().required(),
    planPrice: Yup.number().required(),
    planMatching: Yup.number().required()
  })

  const formik = useFormik({
    initialValues: proposalForm,
    enableReinitialize: true,
    validationSchema: proposalSchema,
    onSubmit: values => {
      postOrganizationProposal({
        organizationId: organization.organizationId,
        proposal: {
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          email: values.email,
          planFrequency: values.planFrequency,
          planPrice: values.planPrice,
          planTVPrice: values.planTVPrice,
          planBillboardPrice: values.planBillboardPrice,
          planMatching: calculatePlanMatching(values.planPrice, values.proposalType),
          campaignMatchingType: getCampaignMatchingType(values.proposalType),
          planLength: values.planLength,
          proposalType: values.proposalType,
          phone: values.phone ?? '',
          altPhone: values.altPhone ?? '',
          website: values.website ?? '',
          planType: values.planType ?? ''
        }
      })
    }
  })

  const send = () => {
    closeSendModal()
    postOrganizationProposalSend({ organizationId: organization.organizationId })
  }

  const saveAndSend = () => {
    formik.submitForm().then()
    showSendModal()
  }

  const proposalTypes = [
    {
      value: ProposalType.TV_Internet,
      text: 'TV w/ Internet',
      ratio: 1
    },
    {
      value: ProposalType.BB_Internet,
      text: 'Billboard w/ Internet',
      ratio: 1
    },
    {
      value: ProposalType.TVBB_Internet,
      text: 'TV and Billboard w/ Internet',
      ratio: 1
    },
    {
      value: ProposalType.Internet_Internet,
      text: 'Internet w/ Internet',
      ratio: 1
    },
    {
      value: ProposalType.TV_BB,
      text: 'TV w/ Billboard',
      ratio: 0.25
    },
    {
      value: ProposalType.BB_TV,
      text: 'BB w/ TV',
      ratio: 0.25
    }
  ]

  const frequency = [
    {
      value: 'month',
      text: 'Month'
    },
    {
      value: 'week',
      text: 'Week'
    }
  ]

  const viewsEstimate = useCallback(() => {
    if (viewsEstimateResult.data) {
      const proposalType = formik.values.proposalType as ProposalType
      const estimate = viewsEstimateResult.data
      const tvTotalViewsEstimate = estimate.planTVTotalViewsEstimate
      const tvViewsEstimate = estimate.planTVViewsEstimate
      const bbViewsEstimate = estimate.planBillboardViewsEstimate
      const internetViewsEstimate = estimate.planInternetViewsEstimate

      const tvTotalStat = <EuiStat description={'TV'} title={tvTotalViewsEstimate} titleSize='xxs' isLoading={viewsEstimateResult.isFetching} />
      const tvStat = <EuiStat description={'TV'} title={tvViewsEstimate} titleSize='xxs' isLoading={viewsEstimateResult.isFetching} />
      const bbStat = <EuiStat description={'Billboard'} title={bbViewsEstimate} titleSize='xxs' isLoading={viewsEstimateResult.isFetching} />
      const internetStat = <EuiStat description={'Internet'} title={internetViewsEstimate} titleSize='xxs' isLoading={viewsEstimateResult.isFetching} />

      switch (proposalType) {
        case ProposalType.TV_Internet:
          return (
            <EuiFlexGroup>
              <EuiFlexItem>{tvTotalStat}</EuiFlexItem>
              <EuiFlexItem>{tvStat}</EuiFlexItem>
            </EuiFlexGroup>
          )
        case ProposalType.TV_BB:
          return (
            <EuiFlexGroup>
              <EuiFlexItem>{tvTotalStat}</EuiFlexItem>
              <EuiFlexItem>{tvStat}</EuiFlexItem>
              <EuiFlexItem>{bbStat}</EuiFlexItem>
            </EuiFlexGroup>
          )
        case ProposalType.BB_Internet:
          return (
            <EuiFlexGroup>
              <EuiFlexItem>{bbStat}</EuiFlexItem>
            </EuiFlexGroup>
          )
        case ProposalType.BB_TV:
          return (
            <EuiFlexGroup>
              <EuiFlexItem>{tvTotalStat}</EuiFlexItem>
              <EuiFlexItem>{tvStat}</EuiFlexItem>
              <EuiFlexItem>{bbStat}</EuiFlexItem>
            </EuiFlexGroup>
          )
        case ProposalType.TVBB_Internet:
          return (
            <EuiFlexGroup>
              <EuiFlexItem>{tvTotalStat}</EuiFlexItem>
              <EuiFlexItem>{tvStat}</EuiFlexItem>
              <EuiFlexItem>{bbStat}</EuiFlexItem>
            </EuiFlexGroup>
          )
        case ProposalType.Internet_Internet:
          return (
            <EuiFlexGroup>
              <EuiFlexItem>{internetStat}</EuiFlexItem>
            </EuiFlexGroup>
          )
      }
    }
  }, [viewsEstimateResult.data, viewsEstimateResult.isFetching])

  let sendModal
  if (isModalSendVisible) {
    sendModal = (
      <EuiConfirmModal style={{ width: 600 }} title='Confirm Send of Agreement?' onCancel={closeSendModal} onConfirm={send} cancelButtonText='Cancel' confirmButtonText='Send' defaultFocusedButton='confirm' isLoading={postOrganizationProposalRequest.isLoading}>
        <p>Please confirm you want to send the Agreement PDF to this customer.</p>
      </EuiConfirmModal>
    )
  }

  if (!proposalForm || proposalIsFetching || organizationIsFetching) {
    return <EuiLoadingSpinner size='xl' />
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiFormRow label='First Name' isInvalid={!!formik.errors.firstName} error={formik.errors.firstName} fullWidth>
              <EuiFieldText name='firstName' value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.firstName} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Last Name' isInvalid={!!formik.errors.lastName} error={formik.errors.lastName} fullWidth>
              <EuiFieldText name='lastName' value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.lastName} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Company Name' isInvalid={!!formik.errors.company} error={formik.errors.company} fullWidth>
              <EuiFieldText name='company' value={formik.values.company} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.company} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Email' isInvalid={!!formik.errors.email} error={formik.errors.email} fullWidth>
              <EuiFieldText name='email' value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.email} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Phone Number' fullWidth>
              <EuiFieldText name='phone' value={formik.values.phone ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.phone} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Phone Number for Ads' fullWidth>
              <EuiFieldText name='altPhone' value={formik.values.altPhone ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.altPhone} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Website' fullWidth>
              <EuiFieldText name='website' value={formik.values.website ?? ''} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.website} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Payment Method' fullWidth>
              <OrganizationPaymentMethodsRadioGroup organizationId={organization.organizationId} onSelectedPaymentMethodIdChange={() => {}} disabledModes={[PaymentMode.Default]} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label='Agreement Type' isInvalid={!!formik.errors.proposalType} error={formik.errors.proposalType} fullWidth>
              <EuiSelect name='proposalType' isInvalid={!!formik.errors.proposalType} options={proposalTypes} value={formik.values.proposalType ?? 'TV_Internet'} onChange={e => onProposalTypeChange(e.target.value as ProposalType)} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Plan Payment Frequency' isInvalid={!!formik.errors.planFrequency} error={formik.errors.planFrequency} fullWidth>
              <EuiSelect name='planFrequency' isInvalid={!!formik.errors.planFrequency} options={frequency} value={formik.values.planFrequency ?? 'month'} onChange={e => onPlanFrequencyChange(e.target.value as PlanFrequency)} fullWidth />
            </EuiFormRow>
            {formik.values.proposalType === ProposalType.TVBB_Internet && (
              <React.Fragment>
                <EuiFormRow label='Plan TV Price' isInvalid={!!formik.errors.planTVPrice} error={formik.errors.planTVPrice} fullWidth>
                  <EuiFieldText name='planTVPrice' value={formik.values.planTVPrice} onChange={e => onPlanTVPriceChange(parseInt(e.target.value))} onBlur={formik.handleBlur} isInvalid={!!formik.errors.planTVPrice} prepend={'$'} fullWidth />
                </EuiFormRow>
                <EuiFormRow label='Plan Billboard Price' isInvalid={!!formik.errors.planBillboardPrice} error={formik.errors.planBillboardPrice} fullWidth>
                  <EuiFieldText name='planBillboardPrice' value={formik.values.planBillboardPrice} onChange={e => onPlanBillboardPriceChange(parseInt(e.target.value))} onBlur={formik.handleBlur} isInvalid={!!formik.errors.planBillboardPrice} prepend={'$'} fullWidth />
                </EuiFormRow>
              </React.Fragment>
            )}
            <EuiFormRow label='Plan Price' isInvalid={!!formik.errors.planPrice} error={formik.errors.planPrice} fullWidth>
              <EuiFieldText name='planPrice' value={formik.values.planPrice} onChange={e => onPlanPriceChange(parseInt(e.target.value))} onBlur={formik.handleBlur} isInvalid={!!formik.errors.planPrice} prepend={'$'} disabled={formik.values.proposalType === ProposalType.TVBB_Internet} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Plan Matching' fullWidth>
              <EuiFieldText name='planMatching' value={calculatePlanMatching(formik.values.planPrice, formik.values.proposalType)} prepend={'$'} disabled fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Plan Length' isInvalid={!!formik.errors.planLength} error={formik.errors.planLength} fullWidth>
              <EuiFieldText name='planLength' value={formik.values.planLength} onChange={formik.handleChange} onBlur={formik.handleBlur} isInvalid={!!formik.errors.planLength} append={'months'} fullWidth />
            </EuiFormRow>
            <EuiFormRow label='Plan Type' fullWidth>
              <BundleCategoryPicker
                bundleType={BundleType.Plan}
                onBadgeClick={bundleCategory => {
                  formik.handleChange({ target: { name: 'planType', value: bundleCategory } })
                }}
                selectedKey={formik.values.planType ?? BundleCategory.Regular}
              />
            </EuiFormRow>
            <EuiFormRow label='Plan Estimated Views'>
              <React.Fragment>{viewsEstimate()}</React.Fragment>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiLink target='_blank' href={`https://calendly.com/d/cprq-pg4-2ww/call-with-your-campaign-advisor-special-offer/?name=${formik.values.firstName}%20${formik.values.lastName}&email=${formik.values.email}&a1=Customer%20${organization.organizationId}`}>
          Kick-off Meeting Calendar
        </EuiLink>
        <EuiSpacer />
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiButton isLoading={postOrganizationProposalRequest.isLoading} fill type='submit'>
              Save
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={saveAndSend} isLoading={postOrganizationProposalRequest.isLoading || postOrganizationProposalSendRequest.isLoading} fill color='success'>
              Save & Send Agreement
            </EuiButton>
            <EuiSpacer size='m' />
            {proposal && proposal.sent && <div>Sent: {moment.utc(proposal.sent).utc().tz(moment.tz.guess()).format('L')}</div>}
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
        </EuiFlexGroup>
      </EuiForm>
      {sendModal}
    </React.Fragment>
  )
}

export default OrganizationProposalTab
