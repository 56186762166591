import React, { useEffect, useState } from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { LocationRegion } from 'api'
import { useGetRegionsQuery } from 'api/rtkQueryApi/opsApi/targetingApi'

interface RegionTargeterProps {
  regions: Array<LocationRegion>
  onRegionRemoved(region: LocationRegion): void
}

export const RegionTargeter = ({ regions, onRegionRemoved }: RegionTargeterProps) => {
  const regionsQuery = useGetRegionsQuery({ name: '' })
  const [selectableRegions, setSelectableRegions] = useState<Array<EuiSelectableOption>>()

  useEffect(() => {
    if (regionsQuery.data) {
      setSelectableRegions(
        regionsQuery.data.map(
          s =>
            ({
              label: s.name,
              key: s.id.toString()
            } as EuiSelectableOption)
        )
      )
    }
  }, [regionsQuery.data])

  return (
    <React.Fragment>
      {selectableRegions && regions && regions.length > 0 && (
        <EuiListGroup bordered maxWidth='100%' gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
          {regions.map(s => (
            <EuiListGroupItem
              size='xs'
              key={s.id}
              label={`${selectableRegions.filter(ss => ss.key === s.id.toString()).map(r => r.label)}`}
              extraAction={{
                onClick: () => {
                  onRegionRemoved(s)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
