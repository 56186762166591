import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiSpacer, SortDirection, EuiInMemoryTable, EuiLink, EuiIcon } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Organization, useOpsClient, User } from 'api'
import history from 'services/HistoryService'
import { localTimestamp } from 'utils/dateUtils'

interface OrganizationUsersTabParams {
  organization: Organization
}

const OrganizationUsersTab: React.FC<OrganizationUsersTabParams> = ({ organization }) => {
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoadingUsers(true)
      opsClient?.getUsersByOrganizationId(organization.organizationId).then(c => {
        setUsers(c)
        setIsLoadingUsers(false)
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, organization])

  const columns: Array<EuiBasicTableColumn<User>> = [
    {
      name: `Email`,
      render: (c: User) => <EuiLink onClick={() => history.push(`/customers/users/${c.userId}`)}>{c.email}</EuiLink>,
      sortable: (c: User) => c.email
    },
    {
      name: 'First Name',
      field: 'firstName',
      sortable: (c: User) => c.firstName
    },
    {
      name: 'Last Name',
      field: 'lastName',
      sortable: (c: User) => c.lastName
    },
    {
      name: 'First Name',
      field: 'firstName',
      sortable: (c: User) => c.firstName
    },
    {
      name: 'Is Blocked',
      render: (c: User) => <React.Fragment>{c.isBlocked && <EuiIcon type='checkInCircleFilled' />}</React.Fragment>
    },
    {
      name: 'Last Login',
      sortable: (c: User) => (c.lastLogin ? moment(c.lastLogin, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString() : null),
      render: (c: User) => (c.lastLogin ? localTimestamp(c.lastLogin) : 'not logged in')
    },
    {
      name: 'Created',
      sortable: (c: User) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString(),
      render: (c: User) => localTimestamp(c.created)
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'email',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiInMemoryTable loading={isLoadingUsers} items={users} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}

export default OrganizationUsersTab
