import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiButton, EuiButtonIcon, EuiDescriptionList, EuiDescriptionListDescription, EuiDescriptionListTitle, EuiFieldText, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLink, EuiLoadingContent, EuiSpacer, EuiText } from '@elastic/eui'

import { Ad, AdStatus, Campaign, UpdateUploadAdTv, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { getAdStatusReadable } from 'utils/EnumToFriendly'
import { useDownloadUrl } from 'utils/useDownloadUrl'

interface AdTvUploadedDetailsTabParams {
  adId: string
}

interface FormValues {
  destinationUrl: string
  uploadedUrl: string | null
}

const AdTvUploadedDetailsTab: React.FC<AdTvUploadedDetailsTabParams> = ({ adId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [ad, setAd] = useState<Ad>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [destination, setDestination] = useState<string | null>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploadingVideo, setIsUploadingVideo] = useState(false)
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [initial, setInitial] = useState<FormValues>({
    destinationUrl: '',
    uploadedUrl: ''
  })
  const [downloadImage] = useDownloadUrl(initial?.uploadedUrl ?? '')

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-customermanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getAd(adId).then(result => {
        setInitial({
          destinationUrl: result.destinationUrl ?? '',
          uploadedUrl: result.tvCommercialUploadedDetails?.uploadedUrl ?? result.tvCommercialUploadedDetails?.previewUrl
        })
        setAd(result)
        opsClient.getCampaign(result.campaignId).then(result => {
          setCampaign(result)
        })
        setIsLoading(false)
      })
    }
  }, [opsClient, adId])

  useEffect(() => {
    if (opsClient && adId) {
      opsClient.getTvCommercialUpload(adId).then(data => setDestination(data))
    }
  }, [opsClient, adId])

  useEffect(() => {
    if (destination) {
      const storageClient = new BlobServiceClient(destination)
      setContainerClient(storageClient.getContainerClient(adId))
    }
  }, [destination, adId])

  const pauseAd = () => {
    opsClient!.putAdInactive(adId).then(result => {
      setAd(result)
    })
  }

  const startAd = () => {
    opsClient!.putAdActive(adId).then(result => {
      setAd(result)
    })
  }

  const deleteAd = () => {
    opsClient!.putAdDeleted(adId).then(result => {
      setAd(result)
    })
  }

  const adSchema = Yup.object().shape({
    destinationUrl: Yup.string()
      .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
      .max(2048)
  })

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const onVideoFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingVideo(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())
      const url = blockBlobClient!.url.split('?')[0]
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingVideo(false)
          const tempTvCommercial = { ...initial }
          tempTvCommercial.uploadedUrl = url
          setInitial(tempTvCommercial)
        })
    }
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    let update: UpdateUploadAdTv = {
      destinationUrl: values.destinationUrl,
      uploadedUrl: values.uploadedUrl
    }
    opsClient!.updateUploadAdTv(adId, update).then(result => {
      setAd(result)
      setSubmitting(false)
    })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {ad && campaign && (
        <Formik initialValues={initial} enableReinitialize validationSchema={adSchema} onSubmit={doSubmit}>
          {props => (
            <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
              <EuiFormRow label='Organization'>
                <EuiLink onClick={() => history.push(`/customers/organizations/${ad!.organizationId}`)}>{ad!.organizationName}</EuiLink>
              </EuiFormRow>
              <EuiFormRow label='Account'>
                <EuiLink onClick={() => history.push(`/customers/accounts/${ad!.accountId}`)}>{ad!.accountName}</EuiLink>
              </EuiFormRow>
              <EuiFormRow label='Campaign'>
                <EuiLink onClick={() => history.push(`/customers/campaigns/${ad!.campaignId}`)}>{ad!.campaignName}</EuiLink>
              </EuiFormRow>
              <EuiFormRow label='Status'>
                <React.Fragment>
                  <EuiText size='s'>
                    {getAdStatusReadable(ad.status)} | {ad.status !== AdStatus.Inactive && ad.status !== AdStatus.Deleted && canUpdate && <EuiLink onClick={pauseAd}>pause this ad</EuiLink>}
                    {ad.status === AdStatus.Inactive && canUpdate && <EuiLink onClick={startAd}>start this ad</EuiLink>}{' '}
                    {ad.status !== AdStatus.Deleted && userProfile!.isAdmin && (
                      <React.Fragment>
                        |{' '}
                        <EuiLink onClick={deleteAd} color='danger'>
                          force delete this ad
                        </EuiLink>
                      </React.Fragment>
                    )}
                    {ad.status === AdStatus.Deleted && userProfile!.isAdmin && (
                      <React.Fragment>
                        |{' '}
                        <EuiLink onClick={pauseAd} color='warning'>
                          un-delete this ad
                        </EuiLink>
                      </React.Fragment>
                    )}
                  </EuiText>
                </React.Fragment>
              </EuiFormRow>
              <EuiFormRow label='Video Preview'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <video controls style={{ width: 640 }}>
                      <source src={initial.uploadedUrl!} type={`video/mp4`} />
                    </video>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiButtonIcon title={'Download'} iconType={'download'} onClick={() => downloadImage(`${ad?.adId ?? 'downloadedAd'}.mp4`)} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
              <EuiFormRow label='Video Details' fullWidth>
                <EuiDescriptionList compressed type='inline'>
                  <EuiDescriptionListTitle>Number of Frames</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.numberFrames}</EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>Frame Rate</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.frameRate}</EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>Duration</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.duration}</EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>Width</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.width}</EuiDescriptionListDescription>
                  <EuiDescriptionListTitle>Height</EuiDescriptionListTitle>
                  <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.height}</EuiDescriptionListDescription>
                </EuiDescriptionList>
              </EuiFormRow>
              <EuiFormRow label='Commercial Upload'>
                <React.Fragment>
                  <EuiLink href={initial.uploadedUrl!}>{initial.uploadedUrl}</EuiLink>
                  <EuiFilePicker id='uploadedUrl' display='default' multiple={false} isInvalid={!!props.errors.uploadedUrl} initialPromptText={!initial?.uploadedUrl ? 'Insert video here' : 'Replace video by dropping new one here'} onChange={onVideoFileChange} isLoading={isUploadingVideo} />
                </React.Fragment>
              </EuiFormRow>
              <EuiSpacer />
              <EuiFormRow label='Destination URL' isInvalid={!!props.errors.destinationUrl} error={props.errors.destinationUrl}>
                <EuiFieldText name='destinationUrl' value={props.values.destinationUrl} onChange={props.handleChange} isInvalid={!!props.errors.destinationUrl} />
              </EuiFormRow>
              <EuiSpacer size='m' />
              <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate || isUploadingVideo}>
                Save
              </EuiButton>
            </EuiForm>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}

export default AdTvUploadedDetailsTab
