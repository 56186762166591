import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiBreadcrumbs, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiLink, EuiPageTemplate, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign, useOpsClient } from 'api'
import { getCampaignStatusReadable } from 'utils/EnumToFriendly'
import { localTimestamp } from 'utils/dateUtils'

const CampaignsNeedingOutrosPage: React.FC = () => {
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const opsClient = useOpsClient()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    if (opsClient) {
      setIsLoadingCampaigns(true)
      opsClient.getCampaignsNeedingOutros().then(c => {
        setCampaigns(c)
        setIsLoadingCampaigns(false)
      })
    }
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => (
        <EuiFlexGroup direction='column' gutterSize={'xs'}>
          <EuiFlexItem>
            <EuiLink href={`/customers/campaigns/${c.campaignId}`} target='_blank' style={{}}>
              {c.campaignName}
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiBreadcrumbs
              breadcrumbs={[
                { text: c.organizationName, href: `/customers/organizations/${c.organizationId}` },
                { text: c.accountName, href: `/customers/accounts/${c.accountId}` }
              ]}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      name: `Status`,
      width: '150',
      render: (c: Campaign) => getCampaignStatusReadable(c)
    },
    {
      name: `Budget`,
      width: '150',
      render: (c: Campaign) => formatter.format(c.budget)
    },
    {
      name: `Created Date`,
      render: (c: Campaign) => <React.Fragment>{c.created && localTimestamp(c.created)}</React.Fragment>,
      sortable: (c: Campaign) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString(),
      width: '230'
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'campaignName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='TV Campaigns Requiring Outros' restrictWidth={false}></EuiPageTemplate.Header>
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiInMemoryTable loading={isLoadingCampaigns} items={campaigns} columns={columns} pagination={pagination} sorting={sorting} />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default CampaignsNeedingOutrosPage
