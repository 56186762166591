import React, { FC } from 'react'

import { SortDirection } from '@elastic/eui'

import { SalesStage } from 'api'
import { useGetOrganizationProposalsQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import OrganizationProposalsGrid from 'features/accountManagerDashboard/leads/OrganizationProposalsGrid'
import moment from "moment/moment";

const Lost: FC = () => {
  const { isFetching, data: proposals } = useGetOrganizationProposalsQuery({
    salesStages: [SalesStage.Lost],
    sentAfter: moment().add(-7, 'day').format('MM/DD/yyyy')
  })

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'lastContacted',
      direction: SortDirection.ASC
    }
  }

  return (
    <React.Fragment>
      <OrganizationProposalsGrid isLoading={isFetching} data={proposals} sorting={sorting} />
    </React.Fragment>
  )
}

export default Lost
