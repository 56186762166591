import moment from 'moment'
import React from 'react'

import { Axis, Chart, CurveType, LineSeries, PartialTheme, Settings } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem, euiPaletteForLightBackground, EuiPanel, EuiSpacer, EuiStat, EuiTitle, useEuiBackgroundColor } from '@elastic/eui'
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme'

import { timespanKeyExecPackage, timespanKeyPackage, timespanSumKeyPackage } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardPackageQuery, useGetExecutiveDashboardPackageSumQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'

import ExecutiveDashboardPackageReportChangeFlyOut from '../ExecutiveDashboardPackageReportChangeFlyout'
import ExecutiveDashboardPackageReportFlyOut from '../ExecutiveDashboardPackageReportFlyout'

export interface PackageOverviewPanelProps {
  forDate: string
  timespan: string
}

const currencyStyle = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }

const PackageOverviewPanel: React.FC<PackageOverviewPanelProps> = ({ forDate, timespan }) => {
  const { data: totals } = useGetExecutiveDashboardPackageQuery({ forDate })
  const { data: historicalTotals } = useGetExecutiveDashboardPackageSumQuery({ daysOfHistory: 7, forDate })

  const dashboardBaseTheme: PartialTheme = {
    crosshair: {
      band: {
        visible: false
      }
    },
    lineSeriesStyle: {
      point: {
        radius: 2,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    highlighter: {
      point: {
        radius: 4,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    axes: {
      gridLine: {
        vertical: {
          visible: false
        },
        horizontal: {
          visible: false
        }
      }
    }
  }
  const blueBackground: PartialTheme = {
    background: {
      color: useEuiBackgroundColor('primary')
    }
  }
  const greyBackground: PartialTheme = {
    background: {
      color: useEuiBackgroundColor('subdued')
    }
  }
  const theme = [dashboardBaseTheme, blueBackground, EUI_CHARTS_THEME_LIGHT.theme]
  const greyTheme = [dashboardBaseTheme, greyBackground, EUI_CHARTS_THEME_LIGHT.theme]

  return (
    <EuiPanel hasShadow={false} color='primary' grow={false}>
      {totals && historicalTotals && (
        <React.Fragment>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiTitle size='xs'>
                <h1>Packages</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ExecutiveDashboardPackageReportFlyOut forDate={forDate} packageIds={totals.activeRevenue.packageIds.concat(totals.onboardingRevenue.packageIds)} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat description='Current Count' title={totals.totalActive + totals.totalOnboarding} titleSize='xs' />
            </EuiFlexItem>
            <EuiFlexItem>
              <Chart size={{ height: 40 }}>
                <Settings theme={theme} showLegend={false} />
                <Axis
                  id='number'
                  groupId='number'
                  domain={{
                    max: Math.max(...historicalTotals.map(x => x.totalActivePackages + x.totalOnboardingPackages)) * 1.05,
                    min: Math.min(...historicalTotals.map(x => x.totalActivePackages + x.totalOnboardingPackages)) * 0.95
                  }}
                  hide={true}
                />
                <LineSeries id='totalPackages' name='Packages' data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y.totalActivePackages + y.totalOnboardingPackages]} color={[euiPaletteForLightBackground()[1]]} groupId='number' curve={CurveType.CURVE_MONOTONE_X} />
              </Chart>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xs' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat description={`Current ${timespan} Revenue`} title={(totals.activeRevenue[timespan.toLowerCase() as timespanKeyPackage] + totals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPackage]).toLocaleString('en-US', currencyStyle)} titleSize='xs' />
            </EuiFlexItem>
            <EuiFlexItem>
              <Chart size={{ height: 40 }}>
                <Settings theme={theme} showLegend={false} />
                <Axis
                  id='total'
                  groupId='total'
                  domain={{
                    min: Math.min(...historicalTotals.map(x => x[`totalActive${timespan}Revenue` as timespanSumKeyPackage] + x[`totalOnboarding${timespan}Revenue` as timespanSumKeyPackage])) * 0.95,
                    max: Math.max(...historicalTotals.map(x => x[`totalActive${timespan}Revenue` as timespanSumKeyPackage] + x[`totalOnboarding${timespan}Revenue` as timespanSumKeyPackage])) * 1.05
                  }}
                  hide={true}
                />
                <LineSeries id='totalRevenue' name={`${timespan} Revenue`} data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y[`totalActive${timespan}Revenue` as timespanSumKeyPackage] + y[`totalOnboarding${timespan}Revenue` as timespanSumKeyPackage]]} color={[euiPaletteForLightBackground()[0]]} groupId='total' curve={CurveType.CURVE_MONOTONE_X} tickFormat={d => `${Number(d).toLocaleString('en-US', currencyStyle)}`} />
              </Chart>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xs' />
          <EuiPanel color='transparent' hasBorder={true}>
            <EuiFlexGroup>
              <EuiFlexItem grow={2}>
                <EuiStat description={`MTD Purchases`} title={totals.totalMtdPurchases} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiStat description={`MTD ${timespan} Revenue`} title={totals[`totalMtd${timespan}Revenue` as timespanKeyExecPackage].toLocaleString('en-US', currencyStyle)} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={1}>
                <ExecutiveDashboardPackageReportFlyOut forDate={forDate} packageIds={totals.mtdPurchasesPackageIds} title='view mtd packages' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='xs' />
          <EuiPanel color='transparent' hasBorder={true}>
            <EuiFlexGroup>
              <EuiFlexItem grow={2}>
                <EuiStat description={`Previous Month Purchases`} title={totals.totalPreviousMtdPurchases} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiStat description={`Previous Month ${timespan} Revenue`} title={totals[`totalPreviousMtd${timespan}Revenue` as timespanKeyExecPackage].toLocaleString('en-US', currencyStyle)} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={1}>
                <ExecutiveDashboardPackageReportFlyOut forDate={forDate} packageIds={totals.mtdPurchasesPackageIds} title='view previous packages' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>{' '}
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>Active Packages</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description={`${timespan} Revenue`} title={totals.activeRevenue[timespan.toLowerCase() as timespanKeyPackage].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Count' title={totals.totalActive} titleSize='xxxs' />
              </EuiFlexItem>
              <EuiFlexItem>
                <ExecutiveDashboardPackageReportFlyOut forDate={forDate} packageIds={totals.activeRevenue.packageIds} title='view active packages' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>Onboarding Packages</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description={`${timespan} Revenue`} title={totals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPackage].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Count' title={totals.totalOnboarding} titleSize='xxxs' />
              </EuiFlexItem>
              <EuiFlexItem>
                <ExecutiveDashboardPackageReportFlyOut forDate={forDate} packageIds={totals.onboardingRevenue.packageIds} title='view onboarding packages' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>Problem Packages</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description={`${timespan} Revenue`} title={totals.problemRevenue[timespan.toLowerCase() as timespanKeyPackage].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Count' title={totals.totalProblem} titleSize='xxxs' />
              </EuiFlexItem>
              <EuiFlexItem>
                <ExecutiveDashboardPackageReportFlyOut forDate={forDate} packageIds={totals.problemRevenue.packageIds} title='view problem packages' />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='m' />
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false} />
            <EuiFlexItem grow={false}>
              <ExecutiveDashboardPackageReportChangeFlyOut forDate={forDate} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
    </EuiPanel>
  )
}
export default PackageOverviewPanel
