import moment from 'moment'

export interface Ad {
  adId: string
  adType: string
  campaignId: string
  campaignName: string
  accountId: string
  accountName: string
  organizationId: string
  organizationName: string
  highQualityUrl: string
  thumbnailUrl: string
  squareUrl: string
  largeSquareUrl: string
  destinationUrl: string
  mediaType: MediaType
  name: string
  mimeType: string
  status: AdStatus
  bidderAuditStatus: BidderAuditStatus
  auditReasons: Array<string>
  audited: moment.Moment | null
  nativeDetails: NativeDetails
  displayAdDetails: DisplayAdDetails
  tvCommercialDetails: TvCommercialDetails
  tvCommercialUploadedDetails: TvCommercialUploadedDetails
  billboardDetails: BillboardDetails
  created: moment.Moment
  activated: moment.Moment | null
  isDeployed: boolean
  deployed: moment.Moment | null
}

export enum AdStatus {
  PendingApproval = 'PendingApproval',
  PendingNetworkApproval = 'PendingNetworkApproval',
  Live = 'Live',
  Disapproved = 'Disapproved',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  WaitingForTVOutro = 'WaitingForTVOutro'
}

export enum BidderAuditStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Approved = 'Approved'
}

export interface InternetAd {
  accountId: string
  campaignId: string
  destinationUrl: string
  imageId: string
  highQualityUrl: string
  organizationId: string
  mimeType: string
  nativeDetails: NativeDetails
  squareUrl: string
  thumbnailUrl: string
}

export interface UpdateAd {
  highQualityUrl: string
  thumbnailUrl: string
  squareUrl: string
  largeSquareUrl: string
  destinationUrl: string
  headline: string
  body: string
  companyName: string
  callToAction: string
  name: string
}

export interface UpdateAdTv {
  sections: string[]
  destinationUrl: string
  voiceOverUrl: string | null
  outroUrl: string | null
}

export interface UpdateUploadAdBillboard {
  destinationUrl: string
  uploadedUrl: string | null
}

export interface UpdateUploadAdTv {
  destinationUrl: string
  uploadedUrl: string | null
}

export interface PendingAuditInternetAd {
  adId: string
  headline: string
  body: string
  callToAction: string
  highQualityUrl: string
  thumbnailUrl: string
  destinationUrl: string
  companyName: string
  created: moment.Moment
  activated: moment.Moment
  budget: number
  budgetFrequency: string
  targetingTemplate: string
  campaignId: string
  campaignName: string
  campaignType: string
  accountId: string
  accountName: string
  organizationId: string
  organizationName: string
  isFirstInAccount: boolean
  displayAdUrl: string
  name: string
  auditStatus: string
}

export interface PendingAuditTvCommercial {
  adId: string
  name: string
  isUploaded: boolean
  isMadeInOps: boolean
  sections: string[]
  previewUrl: string
  outroUrl: string | null
  voiceOverUrl: string | null
  thumbnailUrl: string
  destinationUrl: string
  created: moment.Moment
  activated: moment.Moment
  budget: number
  budgetFrequency: string
  targetingTemplate: string
  numberFrames: number
  frameRate: number
  duration: string
  width: number
  height: number
  campaignId: string
  campaignName: string
  campaignType: string
  accountId: string
  accountName: string
  organizationId: string
  organizationName: string
  tvCommercialUploadedDetails: TvCommercialUploadedDetails | null
  auditStatus: string
}

export interface PendingAuditBillboardAd {
  adId: string
  name: string
  thumbnailUrl: string
  previewUrl: string
  destinationUrl: string
  created: moment.Moment
  activated: moment.Moment
  budget: number
  budgetFrequency: string
  targetingTemplate: string
  campaignId: string
  campaignName: string
  campaignType: string
  accountId: string
  accountName: string
  organizationId: string
  organizationName: string
  yahooDspAdvertiserId: number
  auditStatus: string
}

export interface NativeDetails {
  headline: string
  body: string
  companyName: string
  callToAction: string
  destinationUrl: string
  adId?: string
}

export interface NativeDetailsWithImage extends NativeDetails {
  uploadedUrl: string
}

export interface DisplayAdDetails {
  uploadedUrl: string
  destinationUrl: string
  width: number
  height: number
}

export interface BillboardDetails {
  uploadedUrl: string | null
  previewUrl: string | null
}

export interface TvCommercialDetails {
  tvCommercialId: string | null
  tvCommercialName: string | null
  thumbnailUrl: string | null
  type: string
  kind: string
  sections: string[]
  destinationUrl: string | null
  previewUrl: string | null
  outroUrl: string | null
  voiceOverUrl: string | null
  finalUrl: string | null
  name: string | null
  phone: string | null
  email: string | null
  script?: string
}

export interface TvCommercialUploadedDetails {
  previewUrl: string | null
  uploadedUrl: string | null
  thumbnailUrl: string | null
  finalUrl: string | null
  duration: string | null
  numberFrames: number | null
  frameRate: number | null
  height: number | null
  width: number | null
}

export type MediaType = 'Native' | 'Display' | 'Video'
