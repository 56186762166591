import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiHorizontalRule, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignType } from 'api'
import { Bundle, CampaignSlotSimple } from 'api/entities/Bundle'
import { CreateCampaignSlotPanel } from 'components/Bundles/CreateCampaignSlotPanel'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { AcStatPanel } from 'components/Stats/AcStatPanel'
import { formatCurrency } from 'utils/AcFormatters'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IBundleReSlotFlyout {
  bundle: Bundle
  onCloseFlyout?: () => void
  onSaveBundle?: (paidSlots: CampaignSlotSimple[], bonusSlots: CampaignSlotSimple[]) => void
  isLoading?: boolean
}

export enum Comparison {
  Equal = 'Equal',
  LessThan = 'LessThan',
  GreaterThan = 'GreaterThan'
}

export interface ReSlotValidation {
  paidComparison: Comparison
  bonusComparison: Comparison
}

export const BundleReSlotFlyout: React.FC<IBundleReSlotFlyout> = props => {
  const bundleTypeName = useMemo(() => insertSpaces(props.bundle.bundleType), [props.bundle.bundleType])
  const [reSlotValidation, setReSlotValidation] = useState<ReSlotValidation>({ paidComparison: Comparison.LessThan, bonusComparison: Comparison.LessThan })

  const [paidSlots, setPaidSlots] = useState<CampaignSlotSimple[]>([])
  const [bonusSlots, setBonusSlots] = useState<CampaignSlotSimple[]>([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const comparisonColor = useCallback((comparison: Comparison) => {
    switch (comparison) {
      case Comparison.Equal:
        return 'success'
      case Comparison.LessThan:
        return 'default'
      case Comparison.GreaterThan:
        return 'danger'
    }
  }, [])

  const defaultMonthlyBudget = 1000

  useEffect(() => {
    const validation: ReSlotValidation = {
      paidComparison: Comparison.LessThan,
      bonusComparison: Comparison.LessThan
    }

    const existingPaidBudget = props.bundle.campaignSlots.filter(s => !s.isBonus).reduce((acc, slot) => acc + slot.budget, 0)
    const existingBonusBudget = props.bundle.campaignSlots.filter(s => s.isBonus).reduce((acc, slot) => acc + slot.budget, 0)
    const newPaidBudget = paidSlots.reduce((acc, slot) => acc + slot.budget, 0)
    const newBonusBudget = bonusSlots.reduce((acc, slot) => acc + slot.budget, 0)

    if (newPaidBudget === existingPaidBudget) {
      validation.paidComparison = Comparison.Equal
    } else if (newPaidBudget < existingPaidBudget) {
      validation.paidComparison = Comparison.LessThan
    } else {
      validation.paidComparison = Comparison.GreaterThan
    }

    if (newBonusBudget === existingBonusBudget) {
      validation.bonusComparison = Comparison.Equal
    } else if (newBonusBudget < existingBonusBudget) {
      validation.bonusComparison = Comparison.LessThan
    } else {
      validation.bonusComparison = Comparison.GreaterThan
    }

    setReSlotValidation(validation)
  }, [paidSlots, bonusSlots, props.bundle.campaignSlots])

  const onCloseClicked = () => {
    if (props.onCloseFlyout) {
      props.onCloseFlyout()
    }
  }

  const onCreateClicked = () => {
    setShowConfirmModal(true)
  }

  const onConfirmCreate = () => {
    props.onSaveBundle?.(paidSlots, bonusSlots)
  }

  const onAddPaidSlotClicked = () => {
    setPaidSlots([...paidSlots, { id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: defaultMonthlyBudget }])
  }

  const onAddBonusSlotClicked = () => {
    setBonusSlots([
      ...bonusSlots,
      {
        id: uuidv4(),
        campaignType: CampaignType.Internet,
        isBonus: true,
        budget: defaultMonthlyBudget
      }
    ])
  }

  const onPaidSlotUpdate = (slot: CampaignSlotSimple) => {
    const stateSlot = paidSlots.find(s => s.id === slot.id)

    if (stateSlot) {
      stateSlot.campaignType = slot.campaignType
      stateSlot.budget = slot.budget
      stateSlot.assignedCampaignId = slot.assignedCampaignId

      setPaidSlots([...paidSlots])
    }
  }

  const onBonusSlotUpdate = (slot: CampaignSlotSimple) => {
    const stateSlot = bonusSlots.find(s => s.id === slot.id)

    if (stateSlot) {
      stateSlot.campaignType = slot.campaignType
      stateSlot.budget = slot.budget
      stateSlot.assignedCampaignId = slot.assignedCampaignId

      setBonusSlots([...bonusSlots])
    }
  }

  const onRemovePaidSlotClicked = (slotId: string) => {
    setPaidSlots(paidSlots.filter(s => s.id !== slotId))
  }

  const onRemoveBonusSlotClicked = (slotId: string) => {
    setBonusSlots(bonusSlots.filter(s => s.id !== slotId))
  }
  const bundleIsValid = useCallback(() => {
    return reSlotValidation.paidComparison === Comparison.Equal && reSlotValidation.bonusComparison === Comparison.Equal
  }, [reSlotValidation])

  const totalPaidBudget = paidSlots.reduce((acc, slot) => acc + slot.budget, 0)
  const totalBonusBudget = bonusSlots.reduce((acc, slot) => acc + slot.budget, 0)

  const existingPaidBudget = props.bundle.campaignSlots.filter(s => !s.isBonus).reduce((acc, slot) => acc + slot.budget, 0)
  const existingBonusBudget = props.bundle.campaignSlots.filter(s => s.isBonus).reduce((acc, slot) => acc + slot.budget, 0)

  return (
    <EuiFlyout onClose={onCloseClicked} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={false}>
      <EuiFlyoutHeader>
        <EuiTitle size='s'>
          <h2>
            Re-Slot {bundleTypeName} ({props.bundle.name})
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFormRow fullWidth>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <AcStatPanel description={'Paid'} title={`${formatCurrency(totalPaidBudget)} / ${formatCurrency(existingPaidBudget)}`} titleSize={'s'} titleColor={comparisonColor(reSlotValidation.paidComparison)} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <AcStatPanel description={'Matching'} title={`${formatCurrency(totalBonusBudget)} / ${formatCurrency(existingBonusBudget)}`} titleSize={'s'} titleColor={comparisonColor(reSlotValidation.bonusComparison)} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiHorizontalRule />
        <EuiTitle size={'xs'}>
          <h2>Paid Campaign Slots</h2>
        </EuiTitle>
        <EuiFormRow>
          <EuiButtonEmpty iconType={'plus'} onClick={onAddPaidSlotClicked}>
            Add Paid Slot
          </EuiButtonEmpty>
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <EuiFlexGroup wrap>
            {paidSlots.map(slot => (
              <EuiFlexItem grow={false} key={slot.id}>
                <CreateCampaignSlotPanel
                  bundleType={props.bundle.bundleType}
                  slot={slot}
                  onSlotUpdate={onPaidSlotUpdate}
                  onCancelClick={() => {
                    onRemovePaidSlotClicked(slot.id)
                  }}
                  organizationId={props.bundle.organizationId}
                  hideAssignToCampaign
                />
                <EuiSpacer />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiTitle size={'xs'}>
          <h2>Matching Campaign Slots</h2>
        </EuiTitle>
        <EuiFormRow>
          <EuiButtonEmpty iconType={'plus'} onClick={onAddBonusSlotClicked}>
            Add Matching Slot
          </EuiButtonEmpty>
        </EuiFormRow>
        <EuiFormRow fullWidth>
          <EuiFlexGroup wrap>
            {bonusSlots.map(slot => (
              <EuiFlexItem grow={false} key={slot.id}>
                <CreateCampaignSlotPanel
                  bundleType={props.bundle.bundleType}
                  slot={slot}
                  onSlotUpdate={onBonusSlotUpdate}
                  onCancelClick={() => {
                    onRemoveBonusSlotClicked(slot.id)
                  }}
                  organizationId={props.bundle.organizationId}
                  hideAssignToCampaign
                />
                <EuiSpacer />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='close' onClick={onCloseClicked} color='danger' isLoading={props.isLoading}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={onCreateClicked} disabled={!bundleIsValid()} isLoading={props.isLoading}>
              Save
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>

      {showConfirmModal && (
        <AcConfirmModal
          title={`Confirm ${bundleTypeName} Re-Slot`}
          confirmButtonDisabled={props.isLoading}
          confirmButtonText={'Confirm'}
          onConfirm={onConfirmCreate}
          message={`Are you sure you want to update this ${bundleTypeName}?`}
          onCancel={() => {
            setShowConfirmModal(false)
          }}
        />
      )}
    </EuiFlyout>
  )
}
