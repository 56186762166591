import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiIcon, EuiImage, EuiLink, EuiPortal, EuiSelect, EuiSpacer, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { AcImage, Campaign, useOpsClient } from 'api'
import { useConvertToDisplayMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import AdImageSelectFlyOut from 'features/customers/ads/AdImageSelectFlyOut'

interface CampaignCreateNativeAdFlyOutParams {
  campaign: Campaign
  onCreated: () => void
}

interface InternetAdFormValues {
  headline: string
  body: string
  image: string
  destinationUrl: string
  companyName: string
  callToAction: string
}

const CampaignCreateNativeAdFlyOut: React.FC<CampaignCreateNativeAdFlyOutParams> = ({ campaign, onCreated }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [mimeType, setMimeType] = useState<string>('')
  const [isLoadingCallToActions, setIsLoadingCallToActions] = useState(false)
  const [callToActions, setCallToActions] = useState<string[]>([])
  const [image, setImage] = useState<AcImage | null>(null)
  const [confirmConvertModalOpen, setConfirmConvertModalOpen] = useState(false)
  const opsClient = useOpsClient()
  const [convertToDisplay, convertToDisplayRequest] = useConvertToDisplayMutation()

  useEffect(() => {
    setIsLoadingCallToActions(true)
    opsClient?.getCallToActions().then(c => {
      setCallToActions(c)
      setIsLoadingCallToActions(false)
    })
  }, [opsClient])

  useEffect(() => {
    if (convertToDisplayRequest.isLoading) {
      return
    }

    setConfirmConvertModalOpen(false)
  }, [convertToDisplayRequest.isLoading])

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  const selectImage = (i: AcImage) => {
    setImage(i)
    setMimeType(`image/${i.highQualityUrl.split('.').pop()}`)
  }

  const doSubmitInternetAd = (values: InternetAdFormValues, { setSubmitting }: FormikHelpers<InternetAdFormValues>) => {
    if (campaign && image) {
      opsClient
        ?.postInternetAd({
          accountId: campaign.accountId,
          campaignId: campaign.campaignId,
          destinationUrl: values.destinationUrl,
          imageId: image.id,
          mimeType: mimeType,
          nativeDetails: {
            headline: values.headline,
            body: values.body,
            companyName: values.companyName,
            callToAction: values.callToAction,
            destinationUrl: values.destinationUrl
          },
          organizationId: campaign.organizationId,
          highQualityUrl: image.highQualityUrl,
          squareUrl: image.squareUrl,
          thumbnailUrl: image.thumbnailUrl
        })
        .then(r => {
          if (r) {
            setImage(null)
            onCreated()
          }
        })
      closeFlyout()
      setSubmitting(false)
    } else {
      setSubmitting(false)
    }
  }

  const onClickConvertToDisplay = () => {
    convertToDisplay(campaign!.campaignId)
  }

  const adSchema = Yup.object().shape({
    headline: Yup.string().required('Please enter a headline'),
    body: Yup.string().required('Please enter a body'),
    destinationUrl: Yup.string()
      .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
      .max(2048),
    callToAction: Yup.string().required('Please select a call to action')
  })

  let flyout
  if (isFlyoutVisible && campaign) {
    flyout = (
      <EuiPortal>
        <Formik
          initialValues={{
            headline: '',
            body: '',
            image: '',
            destinationUrl: '',
            companyName: campaign!.accountName,
            callToAction: ''
          }}
          onSubmit={doSubmitInternetAd}
          validationSchema={adSchema}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Internet Ad</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit}>
                  <EuiFormRow label='Headline' isInvalid={!!props.errors.headline} error={props.errors.headline}>
                    <EuiFieldText name='headline' value={props.values.headline} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.headline} />
                  </EuiFormRow>
                  <EuiFormRow label='Body' isInvalid={!!props.errors.body} error={props.errors.body}>
                    <EuiFieldText name='body' value={props.values.body} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.body} />
                  </EuiFormRow>
                  <EuiFormRow style={{ textAlign: 'center' }} label='Image' helpText={<AdImageSelectFlyOut accountId={campaign!.accountId} businessTypeId={campaign!.businessTypeId} selectImage={selectImage} />}>
                    <EuiFlexItem style={{ alignItems: 'center' }}>{!image ? <EuiIcon type='image' size='xxl' /> : <EuiImage alt={''} url={image?.thumbnailUrl} size={400} hasShadow={false} allowFullScreen />}</EuiFlexItem>
                  </EuiFormRow>
                  <EuiFormRow label='Company Name' isInvalid={!!props.errors.companyName} error={props.errors.headline}>
                    <EuiFieldText name='companyName' value={props.values.companyName} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.companyName} />
                  </EuiFormRow>
                  <EuiFormRow label='Destination URL' isInvalid={!!props.errors.destinationUrl} error={props.errors.destinationUrl}>
                    <EuiFieldText name='destinationUrl' value={props.values.destinationUrl} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.destinationUrl} />
                  </EuiFormRow>
                  <EuiFormRow label='Call To Action' isInvalid={!!props.errors.callToAction} error={props.errors.callToAction}>
                    <EuiSelect name='callToAction' isLoading={isLoadingCallToActions} options={callToActions.map(ca => ({ value: ca, label: ca, text: ca } as EuiSelectOption))} value={props.values.callToAction} onChange={props.handleChange} isInvalid={props.touched.callToAction && !!props.errors.callToAction} hasNoInitialSelection={true} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill isLoading={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize='l'>
        <EuiFlexItem grow={false}>
          <EuiLink onClick={showFlyout} color='primary'>
            Create Native Ad
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiLink onClick={() => setConfirmConvertModalOpen(true)} color='primary'>
            Convert to Display
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      {confirmConvertModalOpen && (
        <AcConfirmModal
          title={`Conversion to Display`}
          isLoading={convertToDisplayRequest.isLoading}
          confirmButtonDisabled={convertToDisplayRequest.isLoading}
          confirmButtonText={'Confirm'}
          onConfirm={onClickConvertToDisplay}
          message={`Are you sure you want to convert this campaign to display?`}
          cancelButtonText={'Cancel'}
          onCancel={() => {
            setConfirmConvertModalOpen(false)
          }}
        />
      )}

      {flyout}
    </React.Fragment>
  )
}

export default CampaignCreateNativeAdFlyOut
