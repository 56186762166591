import React, { FC, useEffect, useState } from 'react'

import { EuiSpacer, EuiTabbedContent } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useAccountManagerDashboardTabs } from 'features/accountManagerDashboard/definitions/AccountManagerTabs'
import history from 'services/HistoryService'
import {useToolsTabs} from "features/tools/useToolsTabs";

export const DefaultSpacerForTabbedContent = () => <EuiSpacer size='xxl' />

export interface IToolsTabs {
  currentTab: string
}

export const ToolsTabs: FC<IToolsTabs> = ({ currentTab }) => {
  const tabs = useToolsTabs()
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs.current.filter(t => t.id === currentTab)[0])

  useEffect(() => {
    setSelectedTab(tabs.current.filter(t => t.id === currentTab)[0])
  })

  const onTabClick = (selectedTab: EuiTabbedContentTab) => {
    history.push(`/tools/${selectedTab.id}`)
  }

  return <EuiTabbedContent tabs={tabs.current} selectedTab={selectedTab} onTabClick={onTabClick} />
}
