import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { AadUser } from 'api/entities/User'
import config from 'app/config'
import AuthService from 'services/AuthService'

async function getToken() {
  const accounts = AuthService.getAccounts()

  if (accounts && accounts.length > 0) {
    try {
      const authResult = await AuthService.requestSilentTokenGraph(accounts[0])

      return authResult.accessToken
    } catch (error) {
      console.error(error)
    }
  }
}

export const graphClientApi = createApi({
  reducerPath: 'graphClientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.graph.BASEURL}`,
    prepareHeaders: async (headers, _) => {
      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')
      headers.set('Authorization', `Bearer ${await getToken()}`)
      return headers
    }
  }),
  tagTypes: ['CurrentUser'],
  endpoints: build => ({
    getCurrentUser: build.query<AadUser, string | void>({
      query: () => 'me',
      transformResponse: (results, error, arg) => {
        if (arg) {
          return {
            mail: arg
          } as AadUser
        }

        return results as AadUser
      },
      providesTags: (result, error, arg) => [{ type: 'CurrentUser', id: arg ?? 'me' }]
    }),
    getCampaignAdvisors: build.query<AadUser[], void>({
      query: () => 'groups/a1b60488-8641-4eb2-bc7a-446f07e9c2c4/members',
      transformResponse: (result: { value: AadUser[] }) => result.value
    }),
    getAccountManagers: build.query<AadUser[], void>({
      query: () => 'groups/af0dece3-f4ec-46d6-a08c-64cbecb2da65/members',
      transformResponse: (result: { value: AadUser[] }) => result.value
    })
  })
})

export const { useGetCurrentUserQuery, useGetCampaignAdvisorsQuery, useGetAccountManagersQuery } = graphClientApi
