import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Account } from 'api'
import { useGetActiveCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { CampaignMetricsChart } from 'features/customers/organizations/Snapshot/CampaignMetricsChart'

export interface IAccountCampaignsPacingChart {
  account: Account
}
export const AccountCampaignsPacingChart: React.FC<IAccountCampaignsPacingChart> = props => {
  const campaignsQuery = useGetActiveCampaignsQuery({ accountId: props.account.accountId })

  return (
    <React.Fragment>
      <EuiTitle className='eui-textCenter' size='s'>
        <h3>Current Campaigns</h3>
      </EuiTitle>
      <EuiSpacer />
      {campaignsQuery.data ? (
        <EuiFlexGroup wrap>
          {campaignsQuery.data.map(campaign => (
            <EuiFlexItem>
              <CampaignMetricsChart campaign={campaign} />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      ) : (
        <EuiLoadingSpinner size='l' />
      )}
    </React.Fragment>
  )
}
