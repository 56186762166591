const dev = {
  CLIENTID: '1de96adf-8413-4abe-bbfe-c74d7d0d968f',
  OPS_SCOPES: ['openid', 'profile', 'api://1de96adf-8413-4abe-bbfe-c74d7d0d968f/user_impersonation'],
  BASEURL: 'http://localhost:3001/',
  PLATFORMURL: 'http://localhost:3000/',
  VIDEOURL: 'https://accdevstore.blob.core.windows.net/',
  AGENCYURL: 'http://localhost:3000/',
  DOTCOMURL: 'http://localhost:8080/',
  api: {
    BASEURL: 'https://localhost:5040/'
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_test_r8aqvoMgb3iB6S54PPbatw0B'
  },
  features: {
    billboardSuggestionsEnabled: true
  }
}

const stage = {
  CLIENTID: '37ec1fea-0169-43b1-892e-d87843310b38',
  OPS_SCOPES: ['openid', 'profile', 'api://37ec1fea-0169-43b1-892e-d87843310b38/user_impersonation'],
  BASEURL: 'https://ops.stage.adcritter.com/',
  PLATFORMURL: 'https://platform.stage.adcritter.com/',
  VIDEOURL: 'https://accstagestore.blob.core.windows.net/',
  AGENCYURL: 'https://agencyapp.stage.adcritter.com/',
  DOTCOMURL: 'https://www.stage.adcritter.com/',
  api: {
    BASEURL: 'https://ops-api.stage.adcritter.com/'
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_test_r8aqvoMgb3iB6S54PPbatw0B'
  },
  features: {
    billboardSuggestionsEnabled: true
  }
}

const uat = {
  CLIENTID: '6077debc-357e-4252-969d-9a4e8869d50a',
  OPS_SCOPES: ['openid', 'profile', 'api://6077debc-357e-4252-969d-9a4e8869d50a/user_impersonation'],
  BASEURL: 'https://ops.uat.adcritter.com/',
  PLATFORMURL: 'https://platform.uat.adcritter.com/',
  VIDEOURL: 'https://files.uat.adcritter.com/',
  AGENCYURL: 'https://agencyapp.uat.adcritter.com/',
  DOTCOMURL: 'https://www.uat.adcritter.com/',
  api: {
    BASEURL: 'https://ops-api.uat.adcritter.com/'
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_test_r8aqvoMgb3iB6S54PPbatw0B'
  },
  features: {
    billboardSuggestionsEnabled: true
  }
}

const prod = {
  CLIENTID: 'ac2fb145-5d0c-4827-a9bb-3bb9f0669d96',
  OPS_SCOPES: ['openid', 'profile', 'api://ac2fb145-5d0c-4827-a9bb-3bb9f0669d96/user_impersonation'],
  BASEURL: 'https://ops.adcritter.com/',
  PLATFORMURL: 'https://platform.adcritter.com/',
  VIDEOURL: 'https://files.adcritter.com/',
  AGENCYURL: 'https://agencyapp.adcritter.com/',
  DOTCOMURL: 'https://www.adcritter.com/',
  api: {
    BASEURL: 'https://ops-api.adcritter.com/'
  },
  stripe: {
    PUBLISHABLEKEY: 'pk_live_IBsLniZKbA5vruudkfBGt99s'
  },
  features: {
    billboardSuggestionsEnabled: true
  }
}

let config

switch (process.env.REACT_APP_ENV) {
  case 'UAT':
    config = uat
    break

  case 'Staging':
    config = stage
    break

  case 'Development':
    config = dev
    break

  default:
    config = prod
}

const defaultConfig = {
  graph: {
    BASEURL: 'https://graph.microsoft.com/v1.0/'
  },
  TIMEOUT: 1500,
  GRAPH_SCOPES: ['openid', 'profile', 'User.Read', 'GroupMember.Read.All'],
  googleMaps: {
    APIKEY: 'AIzaSyBJ-T89Z2B1MH6p4sk_zScWx1mtcWuv3wI'
  },
  ...config
}

export default defaultConfig
