import React, { useEffect, useState } from 'react'

import { EuiButton, EuiCallOut, EuiCheckbox, EuiFieldText, EuiFormRow, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AdvancedTargeting, Campaign } from 'api'
import { useGetAccountQuery } from 'api/rtkQueryApi/opsApi/accountsApi'
import { useGetAccountRetargetingCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'

export interface IRetargetingTab {
  campaign: Campaign
  isLoading?: boolean
  onCampaignSave?: (targeting: AdvancedTargeting) => void
}

export const RetargetingTab: React.FC<IRetargetingTab> = props => {
  const accountQuery = useGetAccountQuery(props.campaign.accountId)
  const getCampaignsQuery = useGetAccountRetargetingCampaignsQuery(props.campaign.accountId)

  const [selectedCampaignIds, setSelectedCampaignId] = useState<string[]>(props.campaign.advancedTargeting?.retargetingCampaignIds ?? [])
  const [targetEntireSite, setTargetEntireSite] = useState<boolean>(props.campaign?.advancedTargeting?.retargetingType === 'All')
  const [targetSpecificPages, setTargetSpecificPages] = useState<boolean>(props.campaign?.advancedTargeting?.retargetingType === 'Specific')
  const [websiteUrls, setWebsiteUrls] = useState<string[]>(props.campaign?.advancedTargeting?.urlParts ?? [])
  const [websiteUrl, setWebsiteUrl] = useState<string>()

  useEffect(() => {
    setSelectedCampaignId(props.campaign.advancedTargeting?.retargetingCampaignIds ?? [])
  }, [props.campaign.advancedTargeting?.retargetingCampaignIds])

  useEffect(() => {
    setTargetEntireSite(props.campaign?.advancedTargeting?.retargetingType === 'All')
    setTargetSpecificPages(props.campaign?.advancedTargeting?.retargetingType === 'Specific')
  }, [props.campaign.advancedTargeting?.retargetingType])

  useEffect(() => {
    setWebsiteUrls(props.campaign?.advancedTargeting?.urlParts ?? [])
  }, [props.campaign?.advancedTargeting?.urlParts])

  const onCampaignSelected = (optionId: string) => {
    if (selectedCampaignIds.some(id => id === optionId)) {
      setSelectedCampaignId(selectedCampaignIds.filter(id => id !== optionId))
    } else {
      setSelectedCampaignId([...selectedCampaignIds, optionId])
    }
  }

  const onUrlAdded = () => {
    if (websiteUrlIsValid) {
      setWebsiteUrls([...websiteUrls.filter(url => url !== websiteUrl), websiteUrl])
      setWebsiteUrl('')
    }
  }

  const onUrlRemoved = (url: string) => {
    setWebsiteUrls(websiteUrls.filter(u => u !== url))
  }

  const onUpdateCampaign = () => {
    if (props.campaign.advancedTargeting) {
      props.onCampaignSave?.({
        ...props.campaign.advancedTargeting,
        retargetingCampaignIds: selectedCampaignIds,
        retargetingType: targetEntireSite ? 'All' : targetSpecificPages ? 'Specific' : 'None',
        urlParts: websiteUrls
      })
    }
  }

  const websiteUrlIsValid = websiteUrl && websiteUrl.length > 0 && websiteUrl.startsWith('https://')

  const canUpdate = selectedCampaignIds.length > 0 || targetSpecificPages || targetEntireSite

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiTitle size='s'>
        <h3>Retarget campaigns</h3>
      </EuiTitle>
      <EuiText size='s'>Select the campaigns to retarget.</EuiText>
      {getCampaignsQuery.data && getCampaignsQuery.data.length === 0 && (
        <EuiCallOut title='Account does not have any retargeting campaigns' color={'primary'}>
          <p>Retargeting requires a previously active TV or Internet campaign to have generated an audience.</p>
        </EuiCallOut>
      )}
      <EuiSpacer />
      <EuiFormRow>
        <div style={{ maxHeight: 600, overflow: 'auto' }}>{getCampaignsQuery.data ? getCampaignsQuery.data.map(campaign => <EuiCheckbox key={campaign.campaignId} id={campaign.campaignId} label={`${campaign.campaignName} ($${campaign.budget} ${campaign.campaignType})`} checked={selectedCampaignIds.some(id => id === campaign.campaignId)} onChange={() => onCampaignSelected(campaign.campaignId)} />) : getCampaignsQuery.isLoading ? <EuiLoadingSpinner /> : <React.Fragment />}</div>
      </EuiFormRow>
      <EuiSpacer />
      <EuiTitle size='s'>
        <h3>Retarget website</h3>
      </EuiTitle>
      <EuiText size='s'>Select how to retarget the website</EuiText>
      {!accountQuery.data?.hasVisits && <EuiCallOut title='The Tracking Pixel must be installed in order to retarget a website' color={'primary'} />}
      <EuiSpacer />
      <EuiFormRow label={'Target an audience generated from your website'}>
        <React.Fragment>
          <EuiCheckbox
            id='website'
            label='Entire Site'
            checked={targetEntireSite}
            onChange={() => {
              setTargetEntireSite(!targetEntireSite)
            }}
            disabled={targetSpecificPages || !accountQuery.data?.hasVisits}
          />
          <EuiCheckbox
            id='specificPages'
            label='Specific Pages'
            checked={targetSpecificPages}
            onChange={() => {
              setTargetSpecificPages(!targetSpecificPages)
            }}
            disabled={targetEntireSite || !accountQuery.data?.hasVisits}
          />
        </React.Fragment>
      </EuiFormRow>
      {targetSpecificPages && (
        <React.Fragment>
          <EuiFormRow label={"Add the url's of the pages you would like to target"} helpText={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid ? 'Url must begin with https://' : undefined}>
            <EuiFieldText
              placeholder='https://'
              value={websiteUrl}
              onChange={e => {
                setWebsiteUrl(e.target.value)
              }}
              isInvalid={!!websiteUrl && websiteUrl.length > 0 && !websiteUrlIsValid}
              append={
                <EuiButton size='s' disabled={!websiteUrlIsValid} onClick={onUrlAdded}>
                  Add
                </EuiButton>
              }
            />
          </EuiFormRow>
          <EuiListGroup>
            {websiteUrls.map(url => (
              <EuiListGroupItem
                key={url}
                label={url}
                extraAction={{
                  color: 'text',
                  onClick: () => onUrlRemoved(url),
                  iconType: 'cross',
                  iconSize: 's',
                  'aria-label': 'Favorite link2',
                  alwaysShow: true
                }}
              />
            ))}
          </EuiListGroup>
        </React.Fragment>
      )}
      <EuiButton onClick={onUpdateCampaign} fill disabled={!canUpdate} isLoading={props.isLoading}>
        Save
      </EuiButton>
    </React.Fragment>
  )
}
