import React, { useEffect } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle } from '@elastic/eui'

import { CampaignType, IXandrTargeting, LocationCity, LocationDma, LocationPostalCode, LocationRegion, LocationTarget, SegmentTarget } from 'api'
import { AcBadgeGroup } from 'components/Basic/AcBadgeGroup'
import { CityFinderXandr } from 'components/Finders/CityFinderXandr'
import { DMAFinderXandr } from 'components/Finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from 'components/Finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from 'components/Finders/RegionFinderXandr'
import { SegmentFinder } from 'components/Finders/SegmentFinder'

export enum XandrTargetingType {
  Region = 'Region',
  DMA = 'DMA',
  City = 'City',
  Zip = 'Zip',
  Audience = 'Audience'
}

export interface IUpdateXandrTargetingModal {
  targetingTypes: XandrTargetingType[]
  campaignType: CampaignType
  initialValues?: IXandrTargeting
  onClose?: () => void
  onSaved?: (targeting: IXandrTargeting) => void
}

export const UpdateXandrTargetingModal: React.FC<IUpdateXandrTargetingModal> = props => {
  const [regions, setRegions] = React.useState<LocationRegion[]>([])
  const [dmas, setDmas] = React.useState<LocationDma[]>([])
  const [cities, setCities] = React.useState<LocationCity[]>([])
  const [zips, setZips] = React.useState<LocationPostalCode[]>([])
  const [segments, setSegments] = React.useState<SegmentTarget[]>([])

  useEffect(() => {
    if (props.initialValues) {
      setRegions(props.initialValues.locationTarget.regions ?? [])
      setDmas(props.initialValues.locationTarget.dmas ?? [])
      setCities(props.initialValues.locationTarget.cities ?? [])
      setZips(props.initialValues.locationTarget.postalCodes ?? [])
      setSegments(props.initialValues.segmentTargets ?? [])
    }
  }, [props.initialValues])

  const onModalClose = () => {
    props.onClose?.()
  }

  const onRegionSelected = (region: LocationRegion) => {
    setRegions([...regions, region])
  }

  const onDmaSelected = (dma: LocationDma) => {
    setDmas([...dmas, dma])
  }

  const onCitySelected = (city: LocationCity) => {
    setCities([...cities, city])
  }

  const onZipSelected = (zip: LocationPostalCode) => {
    setZips([...zips, zip])
  }

  const onSegmentSelected = (segment: SegmentTarget) => {
    setSegments([...segments, segment])
  }

  return (
    <EuiModal onClose={onModalClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Add Targeting</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        {props.targetingTypes.includes(XandrTargetingType.Region) && (
          <React.Fragment>
            <EuiFormRow fullWidth label={'Region'}>
              <RegionFinderXandr onOptionClick={onRegionSelected} />
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <AcBadgeGroup
                items={regions.map(r => r.name)}
                onRemoveClick={name => {
                  setRegions(regions.filter(r => r.name !== name))
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        {props.targetingTypes.includes(XandrTargetingType.DMA) && (
          <React.Fragment>
            <EuiFormRow fullWidth label={'DMAs'}>
              <DMAFinderXandr onOptionClick={onDmaSelected} />
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <AcBadgeGroup
                items={dmas.map(r => r.name)}
                onRemoveClick={name => {
                  setDmas(dmas.filter(r => r.name !== name))
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        {props.targetingTypes.includes(XandrTargetingType.City) && (
          <React.Fragment>
            <EuiFormRow fullWidth label={'Cities'}>
              <CityFinderXandr onOptionClick={onCitySelected} />
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <AcBadgeGroup
                items={cities.map(r => `${r.name}, ${r.regionCode}`)}
                onRemoveClick={name => {
                  setCities(cities.filter(r => `${r.name}, ${r.regionCode}` !== name))
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        {props.targetingTypes.includes(XandrTargetingType.Zip) && (
          <React.Fragment>
            <EuiFormRow fullWidth label={'Zips'}>
              <PostalCodeFinderXandr onOptionClick={onZipSelected} />
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <AcBadgeGroup
                items={zips.map(r => r.name)}
                onRemoveClick={name => {
                  setZips(zips.filter(r => r.name !== name))
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        {props.targetingTypes.includes(XandrTargetingType.Audience) && (
          <React.Fragment>
            <EuiFormRow fullWidth label={'Audience Segments'}>
              <SegmentFinder isInvalid={false} onSegmentClicked={onSegmentSelected} mode={props.campaignType === CampaignType.Internet ? 'Internet' : 'Tv'} />
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <AcBadgeGroup
                items={segments.map(r => r.name)}
                onRemoveClick={name => {
                  setSegments(segments.filter(r => r.name !== name))
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup justifyContent={'flexEnd'}>
          <EuiFlexItem grow={false}>
            <EuiButton
              color={'primary'}
              onClick={() => {
                props.onSaved?.({
                  locationTarget: {
                    regions: regions,
                    dmas: dmas,
                    cities: cities,
                    postalCodes: zips
                  } as LocationTarget,
                  segmentTargets: segments
                })
              }}>
              Update
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}
