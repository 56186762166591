import React, { FC } from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiImage, EuiLink, EuiPanel, EuiText } from '@elastic/eui'

export interface IDisplayAdPanelPreview {
  url: string
  widthDescription?: number
  heightDescription?: number
  displaySize: number
  onImageClick?: (url: string) => void
  showAsInvalid?: boolean
  onImageRemove?: () => void
}
export const DisplayAdPanelPreview: FC<IDisplayAdPanelPreview> = props => {
  const onImageRemove = () => {
    if (props.onImageRemove) {
      props.onImageRemove()
    }
  }
  const onImageClick = () => {
    if (props.onImageClick) {
      props.onImageClick(props.url)
    }
  }

  return (
    <EuiPanel style={{ backgroundColor: props.showAsInvalid ? '#e8e8e8' : undefined }}>
      <EuiFlexGroup direction={'column'} style={{ height: '100%' }} gutterSize={'xs'}>
        {props.showAsInvalid && (
          <EuiFlexItem grow={false}>
            <EuiText size={'xs'} color={'warning'}>
              <p>Invalid Dimensions</p>
            </EuiText>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiLink onClick={onImageClick}>
            <EuiImage alt={'preview'} url={props.url} size={140} />
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem />
        {props.widthDescription && props.heightDescription && (
          <EuiFlexItem grow={false}>
            <EuiText size={'xs'}>{`${props.widthDescription} x ${props.heightDescription}`}</EuiText>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty iconType={'cross'} style={{ margin: 0, padding: 0 }} color={'danger'} size={'xs'} onClick={onImageRemove}>
            Remove
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
