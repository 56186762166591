import { AcDomain, DomainList } from 'api/entities/Domains'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const domainsApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['Domains'] })

const domainsApi = domainsApiTags.injectEndpoints({
  endpoints: build => ({
    getDomains: build.query<DomainList, void>({
      query: () => `domains`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Domains' }]
    }),
    upsertDomains: build.mutation<DomainList, { domains: AcDomain[] }>({
      query: request => ({
        url: `domains`,
        method: 'POST',
        body: {
          domains: request.domains
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'Domains' }]
    })
  })
})

export const { useGetDomainsQuery, useUpsertDomainsMutation } = domainsApi
