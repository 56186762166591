import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiIcon, EuiDatePicker, EuiButton, EuiDatePickerRange, EuiPageTemplate } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { useOpsClient } from 'api'

import { insertSpaces } from '../../utils/EnumToFriendly'

const PurchasesPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [purchases, setPurchases] = useState<any>([])
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment())
  const opsClient = useOpsClient()

  function refresh() {
    setIsLoading(true)
    opsClient?.getPurchasesReport(start.format('yyyy-MM-DD'), end.format('yyyy-MM-DD')).then(c => {
      setPurchases(c)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    refresh()
  }, [opsClient])

  const columns: EuiDataGridColumn[] = [
    {
      id: 'organizationId',
      displayAsText: 'Organization ID'
    },
    {
      id: 'organizationName',
      displayAsText: 'Organization'
    },
    {
      id: 'firstName',
      displayAsText: 'First Name'
    },
    {
      id: 'lastName',
      displayAsText: 'Last Name'
    },
    {
      id: 'email',
      displayAsText: 'Email'
    },
    {
      id: 'phone',
      displayAsText: 'Phone'
    },
    {
      id: 'hasActivation',
      displayAsText: 'Has Activation'
    },
    {
      id: 'signupDate',
      displayAsText: 'Signup Date',
      schema: 'date'
    },
    {
      id: 'purchaseDate',
      displayAsText: 'Purchase Date',
      schema: 'date'
    },
    {
      id: 'bundleId',
      displayAsText: 'ID'
    },
    {
      id: 'price',
      displayAsText: 'Price',
      schema: 'number'
    },
    {
      id: 'priceDescription',
      displayAsText: 'Desc.',
      schema: 'string'
    },
    {
      id: 'bundleStatus',
      displayAsText: 'Status',
      schema: 'string'
    },
    {
      id: 'method',
      displayAsText: 'Method'
    },
    {
      id: 'source',
      displayAsText: 'ACID'
    }
  ]

  const [visibleColumns, setVisibleColumns] = useState(['firstName', 'lastName', 'email', 'phone', 'signupDate', 'purchaseDate', 'bundleId', 'price', 'priceDescription', 'bundleStatus', 'isBundle', 'method', 'source'])

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const [sortingColumns, setSortingColumns] = useState([])
  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (purchases.hasOwnProperty(rowIndex)) {
        if (typeof purchases[rowIndex][columnId] == 'boolean') {
          return purchases[rowIndex][columnId] ? 'true' : 'false'
        }

        if (typeof purchases[rowIndex][columnId] == 'number') {
          return purchases[rowIndex][columnId] ? purchases[rowIndex][columnId] + '' : ''
        }

        if (schema === 'date') {
          return purchases[rowIndex][columnId] ? moment(purchases[rowIndex][columnId]).format('YYYY-MM-DD') + '' : ''
        }

        if (columnId === 'bundleStatus' || columnId === 'bundleCategory') {
          return purchases[rowIndex][columnId] ? insertSpaces(purchases[rowIndex][columnId]) : ''
        }

        if (typeof purchases[rowIndex][columnId] == 'object') {
          return ''
        }
        return purchases[rowIndex][columnId] ? purchases[rowIndex][columnId] : null
      }
    }
  }, [purchases])

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header
        iconType='tableDensityNormal'
        pageTitle='Plan & Package Purchases'
        restrictWidth={false}
        bottomBorder={true}
        rightSideItems={[
          <EuiButton id='update' fill={true} onClick={refresh} iconType='refresh' isLoading={isLoading}>
            Update
          </EuiButton>,
          <EuiDatePickerRange
            fullWidth={false}
            startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
            endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
          />
        ]}
      />
      <EuiPageTemplate.Section restrictWidth={false} bottomBorder={false} grow={true}>
        {isLoading && <EuiPageTemplate.EmptyPrompt restrictWidth={false} grow={true} hasShadow={false} color='plain' hasBorder={false} iconType='tableDensityNormal' title={<h2>Loading Plan & Package Purchases</h2>} body={<p>Please wait while purchases are retrieved.</p>} />}
        {!isLoading && (
          <EuiDataGrid
            aria-label='Plan & Package Purchases Report'
            columns={columns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal' }}
            rowCount={purchases.length}
            renderCellValue={renderCellValue}
            inMemory={{ level: 'sorting' }}
            sorting={{ columns: sortingColumns, onSort }}
            pagination={{
              ...pagination,
              pageSizeOptions: [50, 100, 200, 500],
              onChangeItemsPerPage,
              onChangePage
            }}
            toolbarVisibility={{
              additionalControls: (
                <React.Fragment>
                  <CSVLink data={purchases} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`purchases.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                    <span className='euiButtonContent euiButtonEmpty__content'>
                      <EuiIcon type='download' />
                      <span className='euiButton__text'>Download Report (CSV)</span>
                    </span>
                  </CSVLink>
                </React.Fragment>
              )
            }}
          />
        )}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default PurchasesPage
