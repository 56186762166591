import React, { useEffect, useState } from 'react'

import { EuiLink, EuiSpacer, EuiStat } from '@elastic/eui'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

export const CustomPendingStat: React.FC = () => {
  const [isLoadingInternet, setIsLoadingInternet] = useState(false)
  const [countInternet, setCountInternet] = useState(0)
  const [isLoadingTv, setIsLoadingTv] = useState(false)
  const [countBillboard, setCountBillboard] = useState(0)
  const [isLoadingBillboard, setIsLoadingBillboard] = useState(false)
  const [countTv, setCountTv] = useState(0)
  const opsClient = useOpsClient()

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted) {
      setIsLoadingInternet(true)
      setIsLoadingTv(true)
      setIsLoadingBillboard(true)

      opsClient.getCountAccountsNeedingCustomAds().then(c => {
        if (isMounted) {
          setCountInternet(c)
          setIsLoadingInternet(false)
        }
      })
      opsClient.getCountAccountsNeedingCustomTvCommercials().then(c => {
        if (isMounted) {
          setCountTv(c)
          setIsLoadingTv(false)
        }
      })
      opsClient.getCountAccountsNeedingCustomBillboard().then(c => {
        if (isMounted) {
          setCountBillboard(c)
          setIsLoadingBillboard(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient])

  return (
    <React.Fragment>
      <EuiStat
        description={
          <EuiLink onClick={() => history.push('/customers/accountsNeedingCustomAds')} style={{ whiteSpace: 'nowrap' }}>
            Internet Custom Ads
          </EuiLink>
        }
        id='intCustAds'
        title={countInternet}
        isLoading={isLoadingInternet}
        titleColor='accent'
      />
      <EuiSpacer size='m' />
      <EuiStat
        description={
          <EuiLink onClick={() => history.push('/customers/accountsNeedingCustomTvCommercials')} style={{ whiteSpace: 'nowrap' }}>
            TV Custom Commercials
          </EuiLink>
        }
        title={countTv}
        isLoading={isLoadingTv}
        titleColor='accent'
      />
      <EuiSpacer size='m' />
      <EuiStat
        description={
          <EuiLink onClick={() => history.push('/customers/accountsNeedingCustomBillboardAds')} style={{ whiteSpace: 'nowrap' }}>
            Billboard Custom Ads
          </EuiLink>
        }
        title={countBillboard}
        isLoading={isLoadingBillboard}
        titleColor='accent'
      />
    </React.Fragment>
  )
}
