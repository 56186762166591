import React from 'react'

import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiHighlight, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

export interface IAcSearchSelectorItem {
  label: string
  description: string
  key: string
}

export interface IAcSearchSelector {
  searchTerm: string
  onSearchChange: (value: string) => void
  onItemClicked: (item: IAcSearchSelectorItem) => void
  isLoading?: boolean
  items: IAcSearchSelectorItem[]
}

export const AcSearchSelector: React.FC<IAcSearchSelector> = props => {
  const [searchPopoverOpen, setSearchPopoverOpen] = React.useState(false)

  const onSearchChange = (value: string) => {
    props.onSearchChange(value)
  }

  const onSearchSegment = () => {
    setSearchPopoverOpen(false)
  }

  const render = (option: EuiSelectableOption) => {
    const audience = props.items?.find(a => a.key === option.key)
    if (!audience) return <></>

    return (
      <React.Fragment>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={true} style={{ overflow: 'hidden' }}>
            <EuiHighlight search={props.searchTerm}>{option.label}</EuiHighlight>
            <EuiText style={{ paddingLeft: '10px' }} size='xs'>
              {audience.description}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </React.Fragment>
    )
  }

  const onOptionClicked = (item: IAcSearchSelectorItem) => {
    props.onItemClicked(item)
    setSearchPopoverOpen(false)
  }

  const options = props.items.map(
    i =>
      ({
        label: i.label,
        key: i.key,
        checked: undefined,
        onClick: () => onOptionClicked(i)
      } as EuiSelectableOption)
  )

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={props.searchTerm}
          isLoading={props.isLoading}
          onChange={v => onSearchChange(v.target.value)}
          onFocus={() => setSearchPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchSegment()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
        />
      }
      isOpen={searchPopoverOpen}
      closePopover={() => setSearchPopoverOpen(false)}>
      {props.items.length > 0 ? (
        <EuiSelectable singleSelection options={options} renderOption={render} listProps={{ rowHeight: 60, isVirtualized: true, showIcons: false }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
