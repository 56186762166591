import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHorizontalRule, EuiRadio, EuiSpacer, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { ApiError, Campaign, City, DeviceSettings, FrequencyUnit, InventorySettings, useOpsClient, Zip } from 'api'
import { CityFinder, DmaFinder, StateFinder, ZipFinder } from 'components/Finders'
import { CityTargeter, DmaTargeter, StateTargeter, ZipTargeter } from 'components/Targeters'
import { DefaultDayParts, GetDayPartsFromAdvancedTargeting, IDayPart } from 'components/Targeters/DayPartPicker'

export interface FormValues {
  keywords: string
  negativeKeywords: string
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  inventorySettings: InventorySettings
  deviceSettings: DeviceSettings
  frequency: number
  frequencyUnit: FrequencyUnit
  dayParts: IDayPart[]
}

interface AdvancedSearchTargetingTabProps {
  campaign: Campaign
}

const AdvancedSearchTargetingTab: React.FC<AdvancedSearchTargetingTabProps> = ({ campaign }) => {
  const opsClient = useOpsClient()
  const [formValues, setFormValues] = useState<FormValues>({
    keywords: '',
    negativeKeywords: '',
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    inventorySettings: { targetWeb: true, targetApp: false },
    deviceSettings: { targetPc: true, targetPhone: true, targetTablet: true },
    frequency: 0,
    frequencyUnit: FrequencyUnit.hour,
    dayParts: DefaultDayParts
  })

  useEffect(() => {
    if (campaign && campaign.advancedTargeting && campaign.advancedTargeting.advancedTargetingType === 'Search') {
      setFormValues({
        keywords: campaign.advancedTargeting.keywords.join('\n'),
        negativeKeywords: campaign.advancedTargeting.negativeKeywords.join('\n'),
        locationType: campaign.advancedTargeting.locationType ?? 'USA',
        states: campaign.advancedTargeting.states,
        dmas: campaign.advancedTargeting.dmas,
        cities: campaign.advancedTargeting.cities,
        zips: campaign.advancedTargeting.zips,
        inventorySettings: {
          targetWeb: campaign.advancedTargeting!.inventorySettings?.targetWeb ?? true,
          targetApp: campaign.advancedTargeting!.inventorySettings?.targetApp ?? false
        },
        deviceSettings: {
          targetPc: campaign.advancedTargeting!.deviceSettings?.targetPc ?? true,
          targetPhone: campaign.advancedTargeting!.deviceSettings?.targetPhone ?? true,
          targetTablet: campaign.advancedTargeting!.deviceSettings?.targetTablet ?? true
        },
        frequency: campaign.advancedTargeting!.frequency ?? 0,
        frequencyUnit: campaign.advancedTargeting!.frequencyUnit ?? FrequencyUnit.hour,
        dayParts: GetDayPartsFromAdvancedTargeting(campaign.advancedTargeting!)
      })
    }
  }, [campaign])

  const advancedSearchSchema = Yup.object().shape({
    keywords: Yup.string().required('Please Enter at Least 1 Keyword').max(255, 'Please Ensure the Keyword is Less Than 255 Characters').min(3, 'Please Ensure the Keyword is More Than 3 Characters'),
    negativeKeywords: Yup.string().max(255, 'Please Ensure the Negative Keyword is Less Than 255 Characters').min(3, 'Please Ensure the Negative Keyword is More Than 3 Characters'),
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please Select at Least 1 State').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please Select at Least 1 DMA/Metro Area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please Add at Least 1 City').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please Add at Least 1 Zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    inventorySettings: Yup.object().shape({ targetWeb: Yup.boolean(), targetApp: Yup.boolean() }),
    deviceSettings: Yup.object().shape({
      targetPc: Yup.boolean(),
      targetPhone: Yup.boolean(),
      targetTablet: Yup.boolean()
    }),
    frequency: Yup.number(),
    frequencyUnit: Yup.number()
  })

  const formik = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: advancedSearchSchema,
    onSubmit: (values: FormValues) => {
      opsClient!
        .putCampaignAdvancedTargeting(campaign.campaignId, {
          advancedTargetingType: 'Search',
          locationType: values.locationType,
          states: values.states,
          dmas: values.dmas,
          cities: values.cities.map(c => c.cityId),
          zips: values.zips.map(z => z.zipId),
          geoFences: [],
          geoCircles: [],
          geoRectangles: [],
          geoPolygons: [],
          audiences: [],
          audienceType: null,
          ageRanges: [],
          genders: [],
          householdIncomes: [],
          uploadType: null,
          uploadedData: [],
          retargetingType: null,
          urlParts: [],
          keywords: values.keywords && values.keywords.length > 0 ? values.keywords.split('\n') : [],
          negativeKeywords: values.negativeKeywords && values.negativeKeywords.length > 0 ? values.negativeKeywords.split('\n') : [],
          inventorySettings: null,
          deviceSettings: null,
          frequency: null,
          frequencyUnit: null,
          dayParts: {
            monday: values.dayParts.filter(d => d.day === 0 && d.selected).map(d => d.hour),
            tuesday: values.dayParts.filter(d => d.day === 1 && d.selected).map(d => d.hour),
            wednesday: values.dayParts.filter(d => d.day === 2 && d.selected).map(d => d.hour),
            thursday: values.dayParts.filter(d => d.day === 3 && d.selected).map(d => d.hour),
            friday: values.dayParts.filter(d => d.day === 4 && d.selected).map(d => d.hour),
            saturday: values.dayParts.filter(d => d.day === 5 && d.selected).map(d => d.hour),
            sunday: values.dayParts.filter(d => d.day === 6 && d.selected).map(d => d.hour)
          }
        })
        .then(_ => {
          formik.setSubmitting(false)
          formik.setStatus(null)
        })
        .catch(response => {
          formik.setSubmitting(false)
          response.errors.forEach(function (error: ApiError) {
            formik.setFieldError(error.name, error.message)
          })
        })
    }
  })

  const content = (
    <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
      <EuiFormRow label='Keywords (target users searching these keywords)' fullWidth helpText='One Keyword per Line' isInvalid={formik.touched.keywords && !!formik.errors.keywords} error={formik.errors.keywords}>
        <EuiTextArea name='keywords' value={formik.values.keywords} fullWidth isInvalid={formik.touched.keywords && !!formik.errors.keywords} onBlur={formik.handleBlur} onChange={formik.handleChange} />
      </EuiFormRow>
      <EuiFormRow label='Negative Keywords (block users searching these keywords)' fullWidth helpText='One Negative Keyword per Line' isInvalid={formik.touched.negativeKeywords && !!formik.errors.negativeKeywords} error={formik.errors.negativeKeywords}>
        <EuiTextArea name='negativeKeywords' value={formik.values.negativeKeywords} fullWidth isInvalid={formik.touched.negativeKeywords && !!formik.errors.negativeKeywords} onBlur={formik.handleBlur} onChange={formik.handleChange} />
      </EuiFormRow>

      <EuiHorizontalRule margin='xl' />

      <EuiTitle size='xs'>
        <h2>Location Restriction</h2>
      </EuiTitle>
      <EuiSpacer size='s' />
      <EuiRadio
        id='usa'
        name='locationType'
        value='USA'
        label={
          <EuiText size='s'>
            <strong>Entire USA</strong>
          </EuiText>
        }
        checked={formik.values.locationType === 'USA'}
        onChange={() => {
          formik.setFieldValue('locationType', 'USA', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>State(s)</strong>
          </EuiText>
        }
        checked={formik.values.locationType === 'State'}
        onChange={() => {
          formik.setFieldValue('locationType', 'State', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>DMA(s)</strong>
          </EuiText>
        }
        checked={formik.values.locationType === 'DMA'}
        onChange={() => {
          formik.setFieldValue('locationType', 'DMA', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>City(s)</strong>
          </EuiText>
        }
        checked={formik.values.locationType === 'City'}
        onChange={() => {
          formik.setFieldValue('locationType', 'City', true)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Zip Code(s)</strong>
          </EuiText>
        }
        checked={formik.values.locationType === 'Zip'}
        onChange={() => {
          formik.setFieldValue('locationType', 'Zip', true)
        }}
      />

      <div hidden={formik.values.locationType !== 'State'}>
        <EuiSpacer />
        <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
          <StateFinder addState={stateToAdd => formik.setFieldValue('states', [...formik.values.states, stateToAdd])} isInvalid={!!formik.errors.states} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these States:' fullWidth>
          <StateTargeter
            states={formik.values.states}
            onStateRemoved={stateToRemove =>
              formik.setFieldValue(
                'states',
                formik.values.states.filter(x => x !== stateToRemove),
                true
              )
            }
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'DMA'}>
        <EuiSpacer />
        <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
          <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these DMAs:' fullWidth>
          <DmaTargeter
            dmas={formik.values.dmas}
            onDmaRemoved={dmaToRemove =>
              formik.setFieldValue(
                'dmas',
                formik.values.dmas.filter(x => x !== dmaToRemove),
                true
              )
            }
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'City'}>
        <EuiSpacer />
        <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
          <CityFinder addCity={city => formik.setFieldValue('cities', [...formik.values.cities, city])} isInvalid={!!formik.errors.cities} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these cities:' fullWidth>
          <CityTargeter
            cities={formik.values.cities}
            onCityRemoved={city => {
              formik.setFieldValue(
                'cities',
                formik.values.cities.filter(c => c.cityId !== city.cityId)
              )
            }}
          />
        </EuiFormRow>
      </div>

      <div hidden={formik.values.locationType !== 'Zip'}>
        <EuiSpacer />
        <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
          <ZipFinder addZip={zip => formik.setFieldValue('zips', [...formik.values.zips, zip])} isInvalid={!!formik.errors.zips} />
        </EuiFormRow>
        <EuiFormRow label='The campaign will target these zip codes:' fullWidth>
          <ZipTargeter
            zips={formik.values.zips}
            onZipRemoved={zip => {
              formik.setFieldValue(
                'zips',
                formik.values.zips.filter(z => z.zipId !== zip.zipId)
              )
            }}
          />
        </EuiFormRow>
      </div>

      <EuiSpacer />

      <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
        Save
      </EuiButton>
    </EuiForm>
  )

  return (
    <>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>{content}</EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Keywords</h5>
            <p>Keywords are search terms you want to have matched with the ads. In other words, you can target users looking for specific products or services.</p>
            <h5>Negative Keywords</h5>
            <p>Negative keywords are search terms you want to prevent from being used to match to the ads. In other words, you can block keywords that are not relevant to the products or services.</p>
            <h5>Broad Match Keywords</h5>
            <p>Keywords you enter are considered broad match by default. This means that the ads will show up when a user searches on the keywords and relevant/related words (including singular/plural forms of the words, misspellings, acronyms, synonyms, and other variants.</p>
            <h5>Phrase Match Keywords</h5>
            <p>If you want to match a phrase, simply place the phrase inside quotation marks. For example, "red sports car".</p>
            <h5>Exact Match Keywords</h5>
            <p>If you want to match an exact word or phrase, simply place it inside brackets. For example, [life insurance].</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default AdvancedSearchTargetingTab
