import React, { useEffect, useState } from 'react'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Campaign, CampaignMigrationResponse, CampaignType, City, IXandrTargeting, ThirdPartyAudience, Zip } from 'api'
import { useMigrateToXandrMutation, useRevertXandrMigrationMutation, useUpdateCampaignXandrTargetingMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { UpdateXandrTargetingModal, XandrTargetingType } from 'components/ACTools/UpdateXandrTargetingModal'
import { AcBadgeGroup } from 'components/Basic/AcBadgeGroup'

export interface ICampaignMigrationTab {
  campaign: Campaign
}

interface ILegacyTargeting {
  country: string
  states: string[]
  cities: City[]
  zips: Zip[]
  dmas: string[]
  audiences: ThirdPartyAudience[]
}

const xanderDspName: 'Yahoo' | 'Xandr' = 'Xandr'

export const CampaignMigrationTab: React.FC<ICampaignMigrationTab> = props => {
  const [migrateToXandr, migrateToXandrRequest] = useMigrateToXandrMutation()
  const [updateXandrTargeting, updateXandrTargetingRequest] = useUpdateCampaignXandrTargetingMutation()
  const [revertXandrMigration, revertXandrMigrationRequest] = useRevertXandrMigrationMutation()
  const [migrationResponse, setMigrationResponse] = useState<CampaignMigrationResponse>()

  const [showAddTargeting, setShowAddTargeting] = useState(false)

  const onMigrateToXandrClicked = () => {
    migrateToXandr(props.campaign.campaignId)
  }

  useEffect(() => {
    if (migrateToXandrRequest.data) {
      setMigrationResponse(migrateToXandrRequest.data)
    }
  }, [migrateToXandrRequest])

  const legacyTargeting: ILegacyTargeting | undefined = (() => {
    const campaign = props.campaign
    if (campaign.campaignType === 'Internet') {
      if (campaign.advancedTargeting) {
        return {
          country: 'US',
          states: campaign.advancedTargeting.states,
          cities: campaign.advancedTargeting.cities,
          zips: campaign.advancedTargeting.zips,
          dmas: campaign.advancedTargeting.dmas,
          audiences: campaign.advancedTargeting.audiences
        }
      } else if (campaign.smartTargeting) {
        return {
          country: 'US',
          states: [campaign.smartTargeting.state],
          cities: [
            {
              stateCode: campaign.smartTargeting.state,
              name: campaign.smartTargeting.city
            } as City
          ],
          zips: [
            {
              zipId: campaign.smartTargeting.zip
            } as Zip
          ],
          dmas: [],
          audiences: []
        }
      }
    } else if (campaign.campaignType === 'Billboard' && campaign.billboardTargeting) {
      return {
        country: 'US',
        states: campaign.billboardTargeting.states,
        cities: campaign.billboardTargeting.cities,
        zips: campaign.billboardTargeting.zips,
        dmas: campaign.billboardTargeting.dmas,
        audiences: []
      }
    } else if (campaign.campaignType === 'TV' && campaign.tvTargeting) {
      return {
        country: 'US',
        states: campaign.tvTargeting.states,
        cities: campaign.tvTargeting.cities,
        zips: campaign.tvTargeting.zips,
        dmas: campaign.tvTargeting.dmas,
        audiences: campaign.tvTargeting.audiences
      }
    }
    return undefined
  })()

  const modernTargeting: IXandrTargeting | undefined = (() => {
    const campaign = props.campaign
    if (campaign.campaignType === 'Internet') {
      if (campaign.advancedTargeting) {
        return {
          locationTarget: campaign.advancedTargeting.locations,
          segmentTargets: campaign.advancedTargeting.segments ?? []
        } as IXandrTargeting
      } else if (campaign.smartTargeting) {
        return {
          locationTarget: campaign.smartTargeting.locations,
          segmentTargets: []
        } as IXandrTargeting
      }
    } else if (campaign.campaignType === 'Billboard' && campaign.billboardTargeting) {
      return {
        locationTarget: campaign.billboardTargeting.locations,
        segmentTargets: []
      } as IXandrTargeting
    } else if (campaign.campaignType === 'TV' && campaign.tvTargeting) {
      return {
        locationTarget: campaign.tvTargeting.locations,
        segmentTargets: campaign.tvTargeting.segments ?? []
      } as IXandrTargeting
    }
    return undefined
  })()

  const onRevertMigrationClick = () => {
    revertXandrMigration(props.campaign.campaignId)
    setMigrationResponse(undefined)
  }

  const onAddTargetingClick = () => {
    setShowAddTargeting(true)
  }

  const onUpdateTargetingSaved = (targeting: IXandrTargeting) => {
    setShowAddTargeting(false)
    updateXandrTargeting({ campaignId: props.campaign.campaignId, locationTarget: targeting.locationTarget })
  }

  return (
    <React.Fragment>
      {showAddTargeting && <UpdateXandrTargetingModal campaignType={props.campaign.campaignType as CampaignType} initialValues={modernTargeting} targetingTypes={[XandrTargetingType.Region, XandrTargetingType.City, XandrTargetingType.DMA, XandrTargetingType.Zip, XandrTargetingType.Audience]} onClose={() => setShowAddTargeting(false)} onSaved={onUpdateTargetingSaved} />}
      <EuiFlexGroup wrap>
        <EuiFlexItem grow={false}>
          <EuiPanel borderRadius={'none'} color={'transparent'} style={{ minWidth: 400 }}>
            <EuiTitle size={'s'}>
              <h2>Legacy Targeting</h2>
            </EuiTitle>
            <EuiSpacer />
            {legacyTargeting ? (
              <React.Fragment>
                {legacyTargeting.states.length > 0 && (
                  <EuiFormRow label={'States'}>
                    <AcBadgeGroup items={legacyTargeting.states} />
                  </EuiFormRow>
                )}
                {legacyTargeting.dmas.length > 0 && (
                  <EuiFormRow label={'DMAs'} fullWidth>
                    <AcBadgeGroup items={legacyTargeting.dmas} />
                  </EuiFormRow>
                )}
                {legacyTargeting.cities.length > 0 && (
                  <EuiFormRow label={'Cities'}>
                    <AcBadgeGroup items={legacyTargeting.cities.map(c => `${c.name}, ${c.stateCode}`)} />
                  </EuiFormRow>
                )}
                {legacyTargeting.zips.length > 0 && (
                  <EuiFormRow label={'Zips'}>
                    <AcBadgeGroup items={legacyTargeting.zips.map(z => z.name)} />
                  </EuiFormRow>
                )}
                {legacyTargeting.audiences.length > 0 && (
                  <EuiFormRow label={'Audiences'}>
                    <AcBadgeGroup items={legacyTargeting.audiences.map(a => a.name)} />
                  </EuiFormRow>
                )}
              </React.Fragment>
            ) : (
              <EuiText>No legacy location</EuiText>
            )}
            {props.campaign.dsp !== xanderDspName && (
              <EuiButton fill color='danger' onClick={onMigrateToXandrClicked} isLoading={migrateToXandrRequest.isLoading}>
                Migrate to Bidder-X
              </EuiButton>
            )}
          </EuiPanel>
        </EuiFlexItem>
        {props.campaign && (
          <EuiFlexItem grow={false}>
            <EuiPanel color={'warning'} borderRadius={'none'} style={{ minWidth: 400 }}>
              <EuiTitle size={'s'}>
                <h2>Modern Targeting</h2>
              </EuiTitle>
              <EuiSpacer />
              {modernTargeting && modernTargeting.locationTarget && modernTargeting.segmentTargets ? (
                <React.Fragment>
                  {modernTargeting.locationTarget.regions?.length >= 0 && (
                    <EuiFormRow label={'Regions'}>
                      <AcBadgeGroup items={modernTargeting.locationTarget.regions.map(r => r.name)} />
                    </EuiFormRow>
                  )}
                  {modernTargeting.locationTarget.dmas?.length >= 0 && (
                    <EuiFormRow label={'DMAs'}>
                      <AcBadgeGroup items={modernTargeting.locationTarget.dmas.map(r => r.name)} />
                    </EuiFormRow>
                  )}
                  {modernTargeting.locationTarget.cities?.length >= 0 && (
                    <EuiFormRow label={'Cities'}>
                      <AcBadgeGroup items={modernTargeting.locationTarget.cities.map(c => `${c.name}, ${c.regionCode}`)} />
                    </EuiFormRow>
                  )}
                  {modernTargeting.locationTarget.postalCodes?.length >= 0 && (
                    <EuiFormRow label={'Zips'}>
                      <AcBadgeGroup items={modernTargeting.locationTarget.postalCodes.map(z => z.name)} />
                    </EuiFormRow>
                  )}
                  {modernTargeting.segmentTargets?.length >= 0 && (
                    <EuiFormRow label={'Audience Segments'}>
                      <AcBadgeGroup items={modernTargeting.segmentTargets.map(s => s.name)} />
                    </EuiFormRow>
                  )}
                  <EuiFormRow>
                    <EuiButtonEmpty onClick={onAddTargetingClick} iconType='plus' isLoading={updateXandrTargetingRequest.isLoading}>
                      Update Targeting
                    </EuiButtonEmpty>
                  </EuiFormRow>
                </React.Fragment>
              ) : (
                <EuiText>No Modern Targeting</EuiText>
              )}
              {props.campaign.dsp === xanderDspName && (
                <React.Fragment>
                  <EuiFormRow>
                    <EuiFlexGroup>
                      <EuiFlexItem />
                      <EuiFlexItem grow={false}>
                        <EuiButton onClick={onRevertMigrationClick} color={'danger'} isLoading={revertXandrMigrationRequest.isLoading}>
                          Revert to Bidder-Y
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFormRow>
                </React.Fragment>
              )}
            </EuiPanel>
          </EuiFlexItem>
        )}
        {migrationResponse && (
          <EuiFlexItem grow={false}>
            <EuiPanel color={'success'} borderRadius={'none'} style={{ minWidth: 400 }}>
              <EuiTitle size={'s'}>
                <h2>Successfully Mapped</h2>
              </EuiTitle>
              <EuiSpacer />
              {modernTargeting ? (
                <React.Fragment>
                  {migrationResponse.migratedStateCodes.length > 0 && (
                    <EuiFormRow label={'Regions'}>
                      <AcBadgeGroup items={migrationResponse.migratedStateCodes} />
                    </EuiFormRow>
                  )}
                  {migrationResponse.migratedDmas.length > 0 && (
                    <EuiFormRow label={'DMAs'}>
                      <AcBadgeGroup items={migrationResponse.migratedDmas} />
                    </EuiFormRow>
                  )}
                  {migrationResponse.migratedCities.length > 0 && (
                    <EuiFormRow label={'Cities'}>
                      <AcBadgeGroup items={migrationResponse.migratedCities} />
                    </EuiFormRow>
                  )}
                  {migrationResponse.migratedZipIds.length > 0 && (
                    <EuiFormRow label={'Zips'}>
                      <AcBadgeGroup items={migrationResponse.migratedZipIds} />
                    </EuiFormRow>
                  )}
                  {migrationResponse.migratedAudiences.length > 0 && (
                    <EuiFormRow label={'Audience Segments'}>
                      <AcBadgeGroup items={migrationResponse.migratedAudiences} />
                    </EuiFormRow>
                  )}
                </React.Fragment>
              ) : (
                <EuiText>No legacy location</EuiText>
              )}
            </EuiPanel>
          </EuiFlexItem>
        )}
        {migrationResponse && (migrationResponse.unmappedCities.length > 0 || migrationResponse.unmappedDmas.length > 0 || migrationResponse.unmappedZipIds.length > 0 || migrationResponse.unmappedDmas.length > 0 || migrationResponse.unmappedAudiences.length > 0) && (
          <EuiFlexItem grow={false}>
            <EuiPanel color={'danger'} borderRadius={'none'} style={{ minWidth: 400 }}>
              <EuiTitle size={'s'}>
                <h2>Could Not Map</h2>
              </EuiTitle>
              <EuiSpacer />
              {migrationResponse.unmappedStateCodes.length > 0 && (
                <EuiFormRow label={'States'}>
                  <AcBadgeGroup items={migrationResponse.unmappedStateCodes} />
                </EuiFormRow>
              )}
              {migrationResponse.unmappedDmas.length > 0 && (
                <EuiFormRow label={'DMAs'}>
                  <AcBadgeGroup items={migrationResponse.unmappedDmas} />
                </EuiFormRow>
              )}
              {migrationResponse.unmappedCities.length > 0 && (
                <EuiFormRow label={'Cities'}>
                  <AcBadgeGroup items={migrationResponse.unmappedCities} />
                </EuiFormRow>
              )}
              {migrationResponse.unmappedZipIds.length > 0 && (
                <EuiFormRow label={'Zips'}>
                  <AcBadgeGroup items={migrationResponse.unmappedZipIds} />
                </EuiFormRow>
              )}
              {migrationResponse.unmappedAudiences.length > 0 && (
                <EuiFormRow label={'Audience Segments'}>
                  <AcBadgeGroup items={migrationResponse.unmappedAudiences} />
                </EuiFormRow>
              )}
            </EuiPanel>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </React.Fragment>
  )
}
