import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiButtonIcon, EuiDatePicker, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiSwitch, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { Note } from 'api/entities/Notes'

export const defaultNote = () => ({
  id: uuidv4(),
  name: moment().format('LLL'),
  organizationId: '',
  followUp: false,
  text: '',
  secondaryReferenceIds: [],
  changeList: [],
  associatedDocumentType: '',
  primaryReferenceId: '',
  createdBy: ''
})

export interface IAcNote {
  editable?: boolean
  hideSave?: boolean
  hideDelete?: boolean
  onNoteDelete?: (note: Note) => void
  onNoteSave?: (note: Note) => void
  note?: Note
  buttonText?: string
  onNoteChange?: (note: Note) => void
  isLoading?: boolean
}

export const AcNote: React.FC<IAcNote> = props => {
  const [deltaNote, setDeltaNote] = useState<Note>(defaultNote)
  const [editingName, setEditingName] = useState(false)

  useEffect(() => {
    if (props.note) {
      const tempNote = { ...props.note }
      tempNote.changeList = [...tempNote.changeList]
      tempNote.secondaryReferenceIds = [...tempNote.secondaryReferenceIds]
      setDeltaNote(tempNote)
    } else {
      setDeltaNote({ id: '', name: moment().format('LLL'), organizationId: '', followUp: false, text: '', secondaryReferenceIds: [], changeList: [], associatedDocumentType: '', primaryReferenceId: '', createdBy: '' })
    }
  }, [props.note])

  useEffect(() => {
    if (props.onNoteChange) {
      props.onNoteChange(deltaNote)
    }
  }, [deltaNote])

  const onNameChange = (name: string) => {
    if (props.editable) {
      setDeltaNote({ ...deltaNote, name: name })
    }
  }
  const onFollowUpChange = (checked: boolean) => {
    if (props.editable) {
      setDeltaNote({ ...deltaNote, followUp: checked })
    }
  }

  const onFollowUpDateChange = (date: moment.Moment) => {
    if (props.editable) {
      setDeltaNote({ ...deltaNote, followUpDate: date })
    }
  }

  const onTextChange = (text: string) => {
    if (props.editable) {
      setDeltaNote({ ...deltaNote, text: text })
    }
  }

  const onSaveButtonClick = () => {
    if (!!props.onNoteSave) {
      props.onNoteSave(deltaNote)
    }
  }

  const onDeleteButtonClick = () => {
    if (props.onNoteDelete) {
      props.onNoteDelete(deltaNote)
    }
  }

  return (
    <EuiPanel>
      <EuiFlexGroup>
        <EuiFlexItem>
          {editingName && props.editable ? (
            <EuiFlexGroup alignItems='center' responsive={false}>
              <EuiFlexItem>
                <EuiFieldText
                  fullWidth
                  placeholder='Name...'
                  value={deltaNote.name}
                  onChange={e => {
                    onNameChange(e.target.value)
                  }}
                  aria-label='Name Field'
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  color={'success'}
                  onClick={() => {
                    setEditingName(false)
                  }}
                  iconType='save'
                  aria-label='Save'
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <EuiFlexGroup alignItems='center' responsive={false}>
              <EuiFlexItem grow={false}>
                <EuiTitle size={'s'}>
                  <h2>{deltaNote.name}</h2>
                </EuiTitle>
              </EuiFlexItem>
              {props.editable && (
                <EuiFlexItem>
                  <EuiButtonIcon
                    color={'success'}
                    onClick={() => {
                      setEditingName(true)
                    }}
                    iconType='pencil'
                    aria-label='Edit'
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiSwitch
            label={'Follow Up'}
            checked={deltaNote.followUp}
            onChange={e => {
              onFollowUpChange(e.target.checked)
            }}
          />
          {deltaNote.followUp && (
            <React.Fragment>
              <EuiSpacer />
              <EuiDatePicker showTimeSelect fullWidth selected={moment(deltaNote.followUpDate)} onChange={onFollowUpDateChange} placeholder='Select a Follow Up Date' />
            </React.Fragment>
          )}
        </EuiFlexItem>
        {!props.hideDelete && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              color={'danger'}
              onClick={() => {
                onDeleteButtonClick()
              }}
              iconType='trash'
              aria-label='Delete'
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiTextArea
        compressed
        style={{ minHeight: '20em' }}
        value={deltaNote.text}
        onChange={e => {
          onTextChange(e.target.value)
        }}
        readOnly={!props.editable}
        fullWidth
      />
      <EuiText size={'xs'}>
        <p>
          Created By: {deltaNote.createdBy}
          {deltaNote.created ? ` on ${deltaNote.created}` : ''}
        </p>
      </EuiText>
      {props.editable && !props.hideSave && (
        <React.Fragment>
          <EuiSpacer />
          <EuiButton
            isLoading={props.isLoading}
            onClick={() => {
              onSaveButtonClick()
            }}>
            {props.buttonText ?? 'Save'}
          </EuiButton>
        </React.Fragment>
      )}
    </EuiPanel>
  )
}
