import React from 'react'

import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

export interface ICommunicationTypeButtonGroup {
  onTextClick?: () => void
  onEmailClick?: () => void
  onCallClick?: () => void
  onVoicemailClick?: () => void
}

export const CommunicationTypeButtonGroup: React.FC<ICommunicationTypeButtonGroup> = props => {
  return (
    <EuiFlexGroup wrap gutterSize={'s'}>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty style={{ border: 'solid 1px #0061a6' }} size='xs' color='primary' onClick={props.onVoicemailClick} aria-label='Voicemail' title={'Left Voicemail'}>
          V
        </EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty style={{ border: 'solid 1px #0061a6' }} size='xs' color='primary' onClick={props.onTextClick} aria-label='Text' title={'Texted'}>
          T
        </EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty style={{ border: 'solid 1px #0061a6' }} size='xs' color='primary' onClick={props.onEmailClick} aria-label='Email' title={'Sent Email'}>
          E
        </EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty style={{ border: 'solid 1px #0061a6' }} size='xs' color='primary' onClick={props.onCallClick} aria-label='Call' title={'Call Completed'}>
          C
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
