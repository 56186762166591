import React, { useEffect, useState } from 'react'

import { EuiSpacer, EuiLink, SortDirection, EuiInMemoryTable } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign, useOpsClient } from 'api'
import history from 'services/HistoryService'
import { getCampaignStatusReadable } from 'utils/EnumToFriendly'

interface BusinessTypeCampaignsTabParams {
  businessTypeId: string
}

const BusinessTypeCampaignsTab: React.FC<BusinessTypeCampaignsTabParams> = ({ businessTypeId }) => {
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    setIsLoadingCampaigns(true)
    opsClient?.getCampaignsByBusinessTypeId(businessTypeId).then(c => {
      setCampaigns(c)
      setIsLoadingCampaigns(false)
    })
  }, [opsClient, businessTypeId])

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => <EuiLink onClick={() => history.push(`/customers/campaigns/${c.campaignId}`)}>{c.campaignName}</EuiLink>
    },
    {
      name: `Status`,
      render: (c: Campaign) => getCampaignStatusReadable(c)
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'campaignName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiInMemoryTable loading={isLoadingCampaigns} items={campaigns} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}

export default BusinessTypeCampaignsTab
