import React, { FC, useState } from 'react'

import { EuiButton, EuiSpacer } from '@elastic/eui'

import { Campaign } from 'api'
import { Note } from 'api/entities/Notes'
import { useCampaignAddNoteMutation, useDeleteNoteMutation, useGetNotesForIdQuery, useUpdateNoteContentMutation } from 'api/rtkQueryApi/opsApi/noteApi'
import { AcNote } from 'components/ACTools/AcNote'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'

export interface CampaignNotesTabProps {
  campaign: Campaign
}

const CampaignNotesTab: FC<CampaignNotesTabProps> = ({ campaign }) => {
  const [saveCampaignNote] = useCampaignAddNoteMutation()
  const campaignNotesResults = useGetNotesForIdQuery(campaign.campaignId)
  const [updateNote] = useUpdateNoteContentMutation()
  const [deleteNote] = useDeleteNoteMutation()
  const [addingNote, setAddingNote] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [noteToDelete, setNoteToDelete] = useState<Note>()

  const onAddNoteClick = () => {
    setAddingNote(true)
  }

  const onCancelAddNoteClick = () => {
    setAddingNote(false)
  }

  const addNoteClick = (note: Note) => {
    saveCampaignNote({
      campaignId: campaign.campaignId,
      secondaryReferenceIds: [],
      followUpDate: note.followUpDate,
      followUp: note.followUp,
      text: note.text,
      name: note.name
    })
    setAddingNote(false)
  }

  const updateNoteClick = (note: Note) => {
    updateNote({ followUp: note.followUp, text: note.text, name: note.name, followUpDate: note.followUpDate, noteId: note.id })
  }

  const deleteNoteClick = (note: Note) => {
    setNoteToDelete(note)
    setShowDeleteConfirm(true)
  }

  const onDeleteNoteConfirm = () => {
    if (noteToDelete?.id) {
      setShowDeleteConfirm(false)
      deleteNote(noteToDelete.id)
    }
  }

  const onDeleteNoteCancel = () => {
    setShowDeleteConfirm(false)
  }

  return (
    <React.Fragment>
      {!addingNote && (
        <React.Fragment>
          <EuiSpacer />
          <EuiButton onClick={onAddNoteClick}>Add Note</EuiButton>
        </React.Fragment>
      )}
      <EuiSpacer />
      {addingNote && <AcNote editable buttonText='Add' onNoteSave={addNoteClick} onNoteDelete={onCancelAddNoteClick} />}
      {campaignNotesResults.data?.map(note => <AcNote editable key={note.id} note={note} onNoteSave={updateNoteClick} onNoteDelete={deleteNoteClick} /> ?? [])}
      {showDeleteConfirm && <AcConfirmModal onConfirm={onDeleteNoteConfirm} onCancel={onDeleteNoteCancel} title={'Delete Note'} message={'Are you sure you would like to delete this note?'} confirmButtonText={'Delete'} />}
    </React.Fragment>
  )
}

export default CampaignNotesTab
