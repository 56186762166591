import { Moment } from 'moment'

import { Invoice } from 'api/entities/Invoice'
import { defaultTransformFn, opsApi } from './opsApi'

const invoicesTag = opsApi.enhanceEndpoints({ addTagTypes: ['OrganizationInvoices'] })

export interface IInvoicePayment {
  amount: number
  payDate: Moment
}

const invoicesApi = invoicesTag.injectEndpoints({
  endpoints: build => ({
    getOrganizationInvoices: build.query<Invoice[], string>({
      query: organizationId => `organizations/${organizationId}/invoices`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'OrganizationInvoices', id: organizationId }]
    }),
    splitInvoice: build.mutation<boolean, { organizationId: string; invoiceId: string; invoicePayments: IInvoicePayment[] }>({
      query: request => ({
        url: `organizations/${request.organizationId}/splitInvoice`,
        method: 'POST',
        body: {
          invoiceId: request.invoiceId,
          paymentSplits: request.invoicePayments
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'OrganizationInvoices', id: request.organizationId }]
    }),
    refundInvoice: build.mutation<boolean, { organizationId: string; invoiceId: string; refundAmount: number; isFraudulent: boolean; isDuplicate: boolean; isRequestedByCustomer: boolean }>({
      query: request => ({
        url: `organizations/${request.organizationId}/refundInvoice`,
        method: 'POST',
        body: request
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, request) => [{ type: 'OrganizationInvoices', id: request.organizationId }]
    })
  }),
  overrideExisting: false
})

export const { useGetOrganizationInvoicesQuery, useLazyGetOrganizationInvoicesQuery, useSplitInvoiceMutation, useRefundInvoiceMutation } = invoicesApi
