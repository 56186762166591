import React, { useEffect, useState } from 'react'

import { EuiConfirmModal, EuiFieldText, EuiForm, EuiFormRow, EuiOverlayMask } from '@elastic/eui'

import { Campaign, useOpsClient } from 'api'

export interface IAcDuplicateCampaign {
  accountId: string
  campaignId: string
  organizationId: string
  srcCampaignName: string
  isVisible: boolean
  cancelModal: () => void
  finishDuplicating?: (result: Campaign) => void
}

export const AcDuplicateCampaignModal: React.FC<IAcDuplicateCampaign> = props => {
  const opsClient = useOpsClient()
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false)
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null)
  const [duplicateCampaignName, setDuplicateCampaignName] = useState('')

  useEffect(() => {
    if (props.isVisible) {
      showDuplicateModal(props.campaignId)
    } else if (!props.isVisible && isDuplicateModalVisible) {
      cancelDuplicateModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible])

  useEffect(() => {
    if (opsClient && isDuplicateLoading && duplicateCampaignName !== '' && selectedCampaign) {
      opsClient.apiPostCampaignDuplicate(props.accountId, selectedCampaign, duplicateCampaignName).then(res => {
        setIsDuplicateLoading(false)
        cancelDuplicateModal()
        if (props.finishDuplicating) props.finishDuplicating(res.data)
      })
    } else {
      setIsDuplicateLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuplicateLoading, props, duplicateCampaignName, selectedCampaign, opsClient])

  const showDuplicateModal = (campaignId: string) => {
    setIsDuplicateModalVisible(true)
    setSelectedCampaign(campaignId)
    setDuplicateCampaignName(`Copy of ${props.srcCampaignName}`)
  }

  const cancelDuplicateModal = () => {
    setDuplicateCampaignName('')
    setIsDuplicateModalVisible(false)
    setSelectedCampaign(null)
    props.cancelModal()
  }

  const confirmDuplicateModal = () => {
    setIsDuplicateLoading(true)
  }

  let duplicateModal
  if (isDuplicateModalVisible) {
    duplicateModal = (
      <EuiOverlayMask>
        <EuiConfirmModal title='Duplicate Campaign' onCancel={cancelDuplicateModal} onConfirm={confirmDuplicateModal} isLoading={isDuplicateLoading} cancelButtonText='Cancel' confirmButtonText='Confirm' buttonColor='primary'>
          <EuiForm component='form'>
            <EuiFormRow label='Campaign Name' fullWidth>
              <EuiFieldText
                name='duplicateCampaignName'
                value={duplicateCampaignName}
                onChange={e => {
                  setDuplicateCampaignName(e.target.value)
                }}
                placeholder=''
                fullWidth
              />
            </EuiFormRow>
          </EuiForm>
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  return <React.Fragment>{duplicateModal}</React.Fragment>
}
