import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { GranularPhrase, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface GranularPhraseDetailsTabParams {
  granularPhraseId: string
}

interface FormValues {
  name: string
  description: string | null
}

const GranularPhraseDetailsTab: React.FC<GranularPhraseDetailsTabParams> = ({ granularPhraseId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [granularPhrase, setGranularPhrase] = useState<GranularPhrase>()
  const [initial, setInitial] = useState<FormValues>({ name: '', description: '' })
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getGranularPhrase(granularPhraseId).then(result => {
        setInitial({ name: result.name, description: result.description })
        setGranularPhrase(result)
        setIsLoading(false)
      })
    }
  }, [opsClient, granularPhraseId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const granularPhraseSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the granular phrase name'),
    description: Yup.string().nullable()
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.updateGranularPhrase(granularPhraseId, {
        name: values.name,
        description: values.description
      })
      .then(result => {
        setSubmitting(false)
        history.push(`/data/granularPhrases/${result.id}`)
      })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={initial} enableReinitialize validationSchema={granularPhraseSchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
              <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
            </EuiFormRow>
            <EuiFormRow label='Code'>
              <EuiText size='s' color='accent'>
                <strong>
                  {'{{'}
                  {granularPhrase?.code}
                  {'}}'}
                </strong>
              </EuiText>
            </EuiFormRow>
            <EuiFormRow label='Description' isInvalid={!!props.errors.description} error={props.errors.description}>
              <EuiTextArea name='description' value={props.values.description ?? ''} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.description} />
            </EuiFormRow>
            <EuiSpacer />
            <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
              Save
            </EuiButton>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default GranularPhraseDetailsTab
