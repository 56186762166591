import React, { useEffect, useState } from 'react'

import { EuiSpacer, SortDirection, EuiInMemoryTable, EuiLink } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Account, Organization, useOpsClient } from 'api'
import history from 'services/HistoryService'

interface OrganizationAccountsTabParams {
  organization: Organization
}

const OrganizationAccountsTab: React.FC<OrganizationAccountsTabParams> = ({ organization }) => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false)
  const [accounts, setAccounts] = useState<Account[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && organization) {
      setIsLoadingAccounts(true)

      opsClient.getAccounts(organization.organizationId).then(c => {
        if (isMounted) {
          setAccounts(c)
          setIsLoadingAccounts(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, organization])

  const columns: Array<EuiBasicTableColumn<Account>> = [
    {
      name: 'Account',
      render: (c: Account) => <EuiLink onClick={() => history.push(`/customers/accounts/${c.accountId}`)}>{c.accountName}</EuiLink>
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'accountName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 10,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiInMemoryTable loading={isLoadingAccounts} items={accounts} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}

export default OrganizationAccountsTab
