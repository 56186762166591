import { Cohort } from 'api/entities/Cohort'

import { defaultTransformFn, opsApi } from './opsApi'

const cohortsTags = opsApi.enhanceEndpoints({ addTagTypes: ['Cohorts', 'Current'] })

const cohortsApi = cohortsTags.injectEndpoints({
  endpoints: build => ({
    getCohorts: build.query<Cohort[], void>({
      query: () => `cohorts`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Cohorts' }]
    })
  }),
  overrideExisting: false
})

export const { useGetCohortsQuery } = cohortsApi
