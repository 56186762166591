import { Moment } from 'moment'
import moment from 'moment/moment'
import React, { FC, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiButtonEmpty, EuiButtonIcon, EuiDatePicker, EuiDatePickerRange, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiFormRow, EuiIcon, EuiInMemoryTable, EuiLink, EuiPanel, EuiSpacer, EuiStat, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CommissionEvent, CommissionTriggerType } from 'api/entities/SalesAgent'
import { useGetCommissionEventsForSalesAgentQuery, useGetSalesAgentQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import { CommissionEventCreateModal } from 'features/salesManagerDashboard/CommissionEventCreateModal'
import { CommissionEventDeleteModal } from 'features/salesManagerDashboard/CommissionEventDeleteModal'
import { CommissionEventEditModal } from 'features/salesManagerDashboard/CommissionEventEditModal'
import { CommissionEventsFilterGroup } from 'features/salesManagerDashboard/CommissionEventsFilterGroup'
import { formatCurrency } from 'utils/AcFormatters'

export interface ISalesAgentDetailsFlyout {
  salesAgentId: string
  onClose: () => void
}

export const SalesAgentDetailsFlyout: FC<ISalesAgentDetailsFlyout> = props => {
  const salesAgentQuery = useGetSalesAgentQuery(props.salesAgentId)
  const commissionEventsQuery = useGetCommissionEventsForSalesAgentQuery({ salesAgentId: props.salesAgentId })
  const [filteredCommissionEvents, setFilteredCommissionEvents] = React.useState<CommissionEvent[]>([])
  const [start, setStart] = React.useState<Moment>(moment().startOf('month'))
  const [end, setEnd] = React.useState<Moment>(moment())
  const [selectedEvent, setSelectedEvent] = React.useState<CommissionEvent>()
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false)
  const [showNewEventModal, setShowNewEventModal] = React.useState<boolean>(false)

  const [activeFilters, setActiveFilters] = React.useState<CommissionTriggerType[]>([])
  const [search, setSearch] = React.useState<string>('')

  useEffect(() => {
    if (commissionEventsQuery.data) {
      const agentEvents = commissionEventsQuery.data.filter(ce => ce.salesAgentId === props.salesAgentId)
      const filtered = agentEvents.filter(ce => moment(ce.eventDate).isBetween(start, end, 'day', '[]') && (activeFilters.length === 0 || activeFilters.includes(ce.commissionTriggerType)) && ((ce.organizationName && ce.organizationName.toLowerCase().includes(search.toLowerCase())) || ce.commissionItemType.toLowerCase().includes(search.toLowerCase())))

      setFilteredCommissionEvents(filtered)
    }
  }, [commissionEventsQuery.data, start, end, search, activeFilters])

  const salesAgent = salesAgentQuery.data

  const onFilterClicked = (filter: CommissionTriggerType) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter(f => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const onResetFilters = () => {
    setActiveFilters([])
  }

  const columns: Array<EuiBasicTableColumn<CommissionEvent>> = [
    {
      name: 'Date',
      field: 'eventDate',
      render: (date: Moment) => moment(date).format('MM/DD/YYYY hh:mm A'),
      sortable: true
    },
    {
      name: 'Organization',
      render: (event: CommissionEvent) =>
        event.commissionTriggerType === CommissionTriggerType.Manual ? (
          ''
        ) : (
          <EuiLink href={`/customers/organizations/${event.organizationId}`} target={'_blank'}>
            {event.organizationName}
          </EuiLink>
        ),
      sortable: (event: CommissionEvent) => event.organizationName
    },
    {
      name: 'Type',
      field: 'commissionTriggerType',
      render: (type: CommissionTriggerType) => {
        switch (type) {
          case CommissionTriggerType.BundlePurchase:
            return 'Purchase'
          case CommissionTriggerType.BundleRecur:
            return 'Recur'
          case CommissionTriggerType.BundleRefund:
            return 'Refund'
          case CommissionTriggerType.Manual:
            return 'Manual'
        }
      },
      sortable: true
    },
    {
      name: 'Amount',
      field: 'saleAmount',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Commission Percent',
      field: 'commissionPercentage',
      render: (amount: number) => `${amount * 100}%`,
      sortable: true
    },
    {
      name: 'Commission Amount',
      field: 'commissionAmount',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Bonus Commission',
      field: 'commissionBonus',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: '',
      render: (event: CommissionEvent) => (
        <EuiFlexGroup gutterSize={'s'}>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon iconType={'pencil'} onClick={() => onEditClick(event)} title={'Edit'} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon iconType={'cross'} onClick={() => onDeleteClick(event)} title={'Delete'} color={'danger'} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'eventDate',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const onEditClick = (event: CommissionEvent) => {
    setSelectedEvent(event)
    setShowEditModal(true)
  }

  const onEditClose = () => {
    setShowEditModal(false)
    setSelectedEvent(undefined)
  }

  const onDeleteClick = (event: CommissionEvent) => {
    setSelectedEvent(event)
    setShowDeleteModal(true)
  }

  const onDeleteClose = () => {
    setShowDeleteModal(false)
    setSelectedEvent(undefined)
  }

  const onNewEventClick = () => {
    setShowNewEventModal(true)
  }

  const onNewEventClose = () => {
    setShowNewEventModal(false)
  }

  const revenue = filteredCommissionEvents.filter(ce => ce.saleAmount >= 0).reduce((acc, ce) => acc + ce.saleAmount, 0)
  const refunds = filteredCommissionEvents.filter(ce => ce.saleAmount < 0).reduce((acc, ce) => acc + ce.saleAmount, 0)
  const netRevenue = filteredCommissionEvents.reduce((acc, ce) => acc + ce.saleAmount, 0)
  const commission = filteredCommissionEvents.reduce((acc, ce) => acc + ce.commissionAmount, 0)
  const bonus = filteredCommissionEvents.reduce((acc, ce) => acc + ce.commissionBonus, 0)

  return (
    <React.Fragment>
      <EuiFlyout ownFocus onClose={props.onClose} size={'l'}>
        <EuiFlyoutHeader>
          <EuiTitle size='l'>
            <h2>{salesAgent?.name}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFormRow label={'Search'}>
            <EuiFieldSearch value={search} onChange={e => setSearch(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <CommissionEventsFilterGroup commissionEvents={commissionEventsQuery.data ?? []} activeFilters={activeFilters} onFilterClicked={onFilterClicked} onResetFilters={onResetFilters} />
          </EuiFormRow>
          <EuiFormRow>
            <EuiDatePickerRange
              fullWidth={false}
              startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
              endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup wrap>
              <EuiFlexItem grow={false}>
                <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
                  <EuiStat title={formatCurrency(revenue)} description='Revenue' titleSize='s' />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
                  <EuiStat title={formatCurrency(refunds)} description='Refunds' titleSize='s' />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
                  <EuiStat title={`${netRevenue < 0 ? '-' : ''}${formatCurrency(netRevenue)}`} description='Net Revenue' titleSize='s' />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
                  <EuiStat title={`${commission < 0 ? '-' : ''}${formatCurrency(commission)}`} description='Commission' titleSize='s' />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
                  <EuiStat title={formatCurrency(bonus)} description='Bonus' titleSize='s' />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow>
            <EuiButtonEmpty iconType={'plus'} onClick={onNewEventClick}>
              New Event
            </EuiButtonEmpty>
          </EuiFormRow>
          <EuiSpacer />
          {filteredCommissionEvents.length > 0 && (
            <CSVLink data={filteredCommissionEvents} headers={Object.getOwnPropertyNames(filteredCommissionEvents[0]).map(c => ({ label: c, key: c } as LabelKeyObject))} filename={`${salesAgent?.name}_commission.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
              <span className='euiButtonContent euiButtonEmpty__content'>
                <EuiIcon type='download' />
                <span className='euiButton__text'>Download Report (CSV)</span>
              </span>
            </CSVLink>
          )}
          <EuiInMemoryTable loading={salesAgentQuery.isLoading} items={filteredCommissionEvents} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
        </EuiFlyoutBody>
      </EuiFlyout>
      {showEditModal && selectedEvent && salesAgent && <CommissionEventEditModal commissionEvent={selectedEvent} salesAgent={salesAgent} onClose={onEditClose} />}
      {showDeleteModal && selectedEvent && salesAgent && <CommissionEventDeleteModal commissionEvent={selectedEvent} salesAgent={salesAgent} onClose={onDeleteClose} />}
      {showNewEventModal && salesAgent && <CommissionEventCreateModal salesAgent={salesAgent} onClose={onNewEventClose} />}
    </React.Fragment>
  )
}
