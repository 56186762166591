import { extractColors } from 'extract-colors'
import React, { useCallback, useEffect } from 'react'

import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiIcon, EuiPanel, EuiRange, EuiText, EuiTextArea } from '@elastic/eui'

import { NativeDetailsWithImage } from 'api'
import { AcColorPalettePicker } from 'components/ACTools/AcColorPalettePicker'
import { ColorPalette, ContentFocus, ContentSpecifications, FocusArea } from 'components/AdCreation/DisplayMaker'
import { CreateColorPalette, GetDarkerColor, GetLighterColor, GetTriadicColors } from 'utils/colorUtils'

export interface IDisplayAdMakerPanel {
  details: DisplayAdMakerDetails
  displayWidth?: number
  onBuilderChange?: (details: DisplayAdMakerDetails) => void
  onCreativeChange?: (details: DisplayAdMakerDetails) => void
}

export interface DisplayAdMakerDetails {
  nativeDetails: NativeDetailsWithImage
  colorPalette: ColorPalette
  contentSpecifications: ContentSpecifications
}

export const DisplayAdMakerPanel: React.FC<IDisplayAdMakerPanel> = props => {
  const [colorOptions, setColorOptions] = React.useState<string[]>([])
  const [prominentColor, setProminentColor] = React.useState<string>('')
  const [saturation, setSaturation] = React.useState<number>(0)
  const width = props.displayWidth ?? 400

  const nativeDetails = props.details.nativeDetails
  const colorPalette = props.details.colorPalette

  useEffect(() => {
    const options = {
      pixels: 64000,
      distance: 0.22,
      colorValidator: (red: any, green: any, blue: any, alpha = 255) => alpha > 250,
      saturationDistance: 0.2,
      lightnessDistance: 0.2,
      hueDistance: 0.083333333,
      crossOrigin: 'Anonymous'
    } as any

    extractColors(props.details.nativeDetails.uploadedUrl, options).then(colors => {
      //random color from colors
      setColorOptions(colors.map(c => c.hex))
      const color = colors[Math.floor(Math.random() * colors.length)]
      setProminentColor(color.hex)
    })
  }, [props.details.nativeDetails.uploadedUrl])

  useEffect(() => {
    const palette = CreateColorPalette(prominentColor)

    if (props.onBuilderChange) {
      props.onBuilderChange({
        nativeDetails: props.details.nativeDetails,
        colorPalette: palette,
        contentSpecifications: props.details.contentSpecifications
      })
    }
  }, [prominentColor])

  const applyLightness = (lightness: number, palette: ColorPalette) => {
    const bgColor = !lightness ? GetLighterColor(palette.baseColor, 0.1) : lightness >= 0 ? GetLighterColor(palette.baseColor, lightness) : GetDarkerColor(palette.baseColor, Math.abs(lightness))
    const btnColor = GetTriadicColors(bgColor)[0]

    const bgColor2 = !lightness ? GetLighterColor(palette.baseColor2, 0.1) : lightness >= 0 ? GetLighterColor(palette.baseColor2, lightness) : GetDarkerColor(palette.baseColor2, Math.abs(lightness))
    const btnColor2 = GetTriadicColors(bgColor2)[0]

    return {
      prominentColor: palette.prominentColor,
      baseColor: palette.baseColor,
      baseColor2: palette.baseColor2,
      backgroundColor: bgColor,
      backgroundColor2: bgColor2,
      buttonColor: btnColor,
      buttonColor2: btnColor2
    }
  }

  const onColorPaletteChange = useCallback(
    (palette: ColorPalette) => {
      if (props.onBuilderChange) {
        props.onBuilderChange({
          nativeDetails: props.details.nativeDetails,
          colorPalette: applyLightness(saturation, palette),
          contentSpecifications: props.details.contentSpecifications
        })
      }
    },
    [saturation, props.onBuilderChange]
  )

  const onSaturationChange = (newSaturation: number) => {
    setSaturation(newSaturation)
    const newPalette = applyLightness(newSaturation, colorPalette)
    if (props.onBuilderChange) {
      props.onBuilderChange({
        nativeDetails: props.details.nativeDetails,
        colorPalette: newPalette,
        contentSpecifications: props.details.contentSpecifications
      })
    }
  }

  const onDetailsChange = (newDetails: NativeDetailsWithImage) => {
    if (props.onBuilderChange) {
      props.onBuilderChange({
        nativeDetails: newDetails,
        colorPalette: props.details.colorPalette,
        contentSpecifications: props.details.contentSpecifications
      })
    }
  }

  const onFocusAreaChange = (focusArea: FocusArea) => {
    if (props.onBuilderChange) {
      props.onBuilderChange({
        nativeDetails: props.details.nativeDetails,
        colorPalette: props.details.colorPalette,
        contentSpecifications: { ...props.details.contentSpecifications, focusArea }
      })
    }
  }

  const onContentFocusChange = (contentFocus: ContentFocus) => {
    if (props.onBuilderChange) {
      props.onBuilderChange({
        nativeDetails: props.details.nativeDetails,
        colorPalette: props.details.colorPalette,
        contentSpecifications: { ...props.details.contentSpecifications, contentFocus }
      })
    }
  }

  return (
    <EuiPanel style={{ width: width }} hasBorder={true} hasShadow={false}>
      <EuiFormRow label='Company Name' key={'companyName'}>
        <EuiFieldText value={nativeDetails.companyName} onChange={e => onDetailsChange({ ...nativeDetails, companyName: e.target.value })} />
      </EuiFormRow>
      <EuiFormRow label='Headline' key={'headline'}>
        <EuiFieldText value={nativeDetails.headline} onChange={e => onDetailsChange({ ...nativeDetails, headline: e.target.value })} />
      </EuiFormRow>
      <EuiFormRow label='Body' key={'body'}>
        <EuiTextArea value={nativeDetails.body} onChange={e => onDetailsChange({ ...nativeDetails, body: e.target.value })} />
      </EuiFormRow>
      <EuiFormRow label='Destination Url' key={'destinationUrl'}>
        <EuiFieldText value={nativeDetails.destinationUrl} onChange={e => onDetailsChange({ ...nativeDetails, destinationUrl: e.target.value })} />
      </EuiFormRow>
      <EuiFormRow label='Call To Action' key={'callToAction'}>
        <EuiFieldText value={nativeDetails.callToAction} onChange={e => onDetailsChange({ ...nativeDetails, callToAction: e.target.value })} />
      </EuiFormRow>
      <EuiFormRow label='Color Palette' key={'colorPalette'}>
        <AcColorPalettePicker colors={colorOptions} onColorPaletteChange={onColorPaletteChange} />
      </EuiFormRow>
      <EuiFormRow label='Saturation' key={'saturation'}>
        <EuiRange fullWidth step={0.01} max={1} min={-1} value={saturation} onChange={e => onSaturationChange(parseFloat(e.currentTarget.value))} />
      </EuiFormRow>
      <EuiFormRow label='Focus Area' key={'focusArea'}>
        <EuiFlexGroup gutterSize={'s'} wrap>
          <EuiFlexItem grow={false}>
            <EuiPanel title={'Left'} color={props.details.contentSpecifications.focusArea === FocusArea.Left ? 'success' : undefined} hasBorder={true} hasShadow={false} onClick={() => onFocusAreaChange(FocusArea.Left)}>
              <EuiIcon type={'menuLeft'} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel title={'Center'} color={props.details.contentSpecifications.focusArea === FocusArea.Center ? 'success' : undefined} hasBorder={true} hasShadow={false} onClick={() => onFocusAreaChange(FocusArea.Center)}>
              <EuiIcon type={'fullScreen'} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel title={'Right'} color={props.details.contentSpecifications.focusArea === FocusArea.Right ? 'success' : undefined} hasBorder={true} hasShadow={false} onClick={() => onFocusAreaChange(FocusArea.Right)}>
              <EuiIcon type={'menuRight'} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow label='Content Focus' key={'contentFocus'}>
        <EuiFlexGroup gutterSize={'s'} wrap>
          <EuiFlexItem grow={false}>
            <EuiPanel title={'Headline'} color={props.details.contentSpecifications.contentFocus === ContentFocus.Headline ? 'success' : undefined} hasBorder={true} hasShadow={false} onClick={() => onContentFocusChange(ContentFocus.Headline)}>
              <EuiText>Headline</EuiText>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel title={'Company Name'} color={props.details.contentSpecifications.contentFocus === ContentFocus.CompanyName ? 'success' : undefined} hasBorder={true} hasShadow={false} onClick={() => onContentFocusChange(ContentFocus.CompanyName)}>
              <EuiText>Company Name</EuiText>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel title={'Image'} color={props.details.contentSpecifications.contentFocus === ContentFocus.Image ? 'success' : undefined} hasBorder={true} hasShadow={false} onClick={() => onContentFocusChange(ContentFocus.Image)}>
              <EuiText>Image</EuiText>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </EuiPanel>
  )
}
