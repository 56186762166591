import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EuiBreadcrumbs, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiTabbedContent, EuiBadge, EuiCopy } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

import BusinessTypeCategoryDetailsTab from './BusinessTypeCategoryDetailsTab'
import BusinessTypeCategoryImagesTab from './BusinessTypeCategoryImagesTab'

interface BusinessTypeCategoryPageParams {
  businessTypeCategoryId: string
}

const BusinessTypePage: React.FC = () => {
  let { businessTypeCategoryId } = useParams<BusinessTypeCategoryPageParams>()
  const [name, setName] = useState('')
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      opsClient.getBusinessTypeCategory(businessTypeCategoryId).then(result => {
        setName(result.name)
      })
    }
  }, [opsClient, businessTypeCategoryId])

  const tabs: EuiTabbedContentTab[] = [
    {
      id: 'details',
      name: 'Details',
      content: <BusinessTypeCategoryDetailsTab businessTypeCategoryId={businessTypeCategoryId} />
    },
    {
      id: 'images',
      name: 'Images',
      content: <BusinessTypeCategoryImagesTab businessTypeCategoryId={businessTypeCategoryId} />
    }
  ]

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Data' },
              {
                text: 'Business Type Categories',
                href: '/data/businessTypeCategories',
                onClick: () => history.push('/data/businessTypeCategories')
              },
              {
                text: name,
                href: `/data/businessTypeCategories/${businessTypeCategoryId}`,
                onClick: () => history.push(`/data/businessTypeCategories/${businessTypeCategoryId}`)
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Business Type Category</h1>
              </EuiTitle>
              <EuiCopy textToCopy={businessTypeCategoryId}>
                {copy => (
                  <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Business Type Category ID'>
                    {businessTypeCategoryId}
                  </EuiBadge>
                )}
              </EuiCopy>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default BusinessTypePage
