import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IPayment } from 'api/entities/Invoice'
import { InvoiceSplitterContent } from 'components/Invoices/InvoiceSplitterContent'
import { localDate } from 'utils/dateUtils'

export interface IPaymentSplitterInline {
  amountDue: number
  maxDate: Moment
  onValidChanged?: (isValid: boolean) => void
  onPaymentsChanged?: (payments: IPayment[]) => void
}

export const PaymentSplitterInline: React.FC<IPaymentSplitterInline> = props => {
  const [payments, setPayments] = useState<IPayment[]>([])
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(isValidSplit())
    props.onPaymentsChanged?.(payments)
  }, [payments])

  useEffect(() => {
    props.onValidChanged?.(isValid)
  }, [isValid])

  const onAddPaymentClicked = () => {
    setPayments([...payments, { key: uuidv4(), amount: 500, payDate: moment().add(1, 'day') }])
  }

  const onPaymentAmountChanged = (value: number, payment: IPayment) => {
    setPayments(payments => {
      const clonePayments = [...payments]
      const foundItem = clonePayments.find(p => p.key === payment.key)
      if (foundItem) {
        foundItem.amount = value
      }
      return clonePayments
    })
  }

  const onPayDateChanged = (value: Moment, payment: IPayment) => {
    if (value) {
      setPayments(payments => {
        const clonePayments = [...payments]
        const foundItem = clonePayments.find(p => p.key === payment.key)
        if (foundItem) {
          foundItem.payDate = value
        }
        return clonePayments
      })
    }
  }

  const onDeletePayment = (payment: IPayment) => {
    setPayments(payments.filter(p => p.key !== payment.key))
  }

  const paymentIsValid = (payment: IPayment) => {
    return payment.amount > 99 && payment.payDate > moment().add(-1, 'day') && payment.payDate <= props.maxDate
  }

  const getInvalidText = (payment: IPayment) => {
    if (payment.amount < 100) {
      return 'Cannot process payments less than $100'
    }

    if (payment.payDate <= moment().add(-1, 'day')) {
      return 'Payment must occur in the future'
    }

    if (payment.payDate > props.maxDate) {
      return `Payment must occur before ${localDate(props.maxDate)}`
    }

    return undefined
  }

  const isValidSplit = () => {
    if (payments.reduce((partialSum, a) => partialSum + a.amount, 0) != props.amountDue) {
      return false
    }

    return payments.every(p => paymentIsValid(p))
  }

  return <InvoiceSplitterContent payments={payments} amountToPay={props.amountDue} maxDate={props.maxDate} onAddPaymentClicked={onAddPaymentClicked} getInvalidText={getInvalidText} onPaymentAmountChanged={onPaymentAmountChanged} onPayDateChanged={onPayDateChanged} onDeletePayment={onDeletePayment} />
}
