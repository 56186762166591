import React, { useState } from 'react'

import { EuiHeaderLink, EuiIcon, EuiKeyPadMenuItem, htmlIdGenerator } from '@elastic/eui'
import { EuiKeyPadMenu, EuiPopover } from '@elastic/eui'
import { IconSize, IconType } from '@elastic/eui/src/components/icon/icon'

export interface INavSelectorItem {
  type: IconType
  size: IconSize
  onClick: () => void
  label: string
  color?: string
}

interface INavSelectorDD {
  items: INavSelectorItem[]
  buttonText: string
}

export const NavSelectorDropDown: React.FC<INavSelectorDD> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const idGenerator = htmlIdGenerator()
  const popoverId = idGenerator('popover')
  const keypadId = idGenerator('keypad')
  const keypadMenuItemId = idGenerator('menuItem')
  let counter = 1

  const button = (
    <EuiHeaderLink
      aria-controls={keypadId}
      aria-expanded={isOpen}
      aria-haspopup='true'
      onClick={() => {
        setIsOpen(!isOpen)
      }}>
      {props.buttonText}
    </EuiHeaderLink>
  )

  return (
    <EuiPopover
      id={popoverId}
      ownFocus
      button={button}
      isOpen={isOpen}
      anchorPosition='downRight'
      closePopover={() => {
        setIsOpen(false)
      }}>
      <EuiKeyPadMenu id={keypadId} style={{ width: 288 }}>
        {props.items.map(item => {
          return (
            <EuiKeyPadMenuItem
              key={`${keypadMenuItemId}_${counter++}`}
              label={item.label}
              onClick={() => {
                item.onClick()
                setIsOpen(false)
              }}>
              <EuiIcon type={item.type} size={item.size} color={item.color} />
            </EuiKeyPadMenuItem>
          )
        })}
      </EuiKeyPadMenu>
    </EuiPopover>
  )
}
