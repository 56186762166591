import { CampaignMonitor } from 'api/entities/Monitor'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const monitorsApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['CampaignMonitors'] })

const monitorsApi = monitorsApiTags.injectEndpoints({
  endpoints: build => ({
    getActiveCampaignMonitors: build.query<CampaignMonitor[], void>({
      query: () => `monitors/campaign`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'CampaignMonitors' }]
    }),
    resolveMonitor: build.mutation<CampaignMonitor, { monitorId: string }>({
      query: request => ({
        url: `monitors/${request.monitorId}/resolve`,
        method: 'POST',
        body: {}
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'CampaignMonitors' }]
    })
  })
})

export const { useGetActiveCampaignMonitorsQuery, useResolveMonitorMutation } = monitorsApi
