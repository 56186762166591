import React, { useRef, useState } from 'react'

import { EuiText } from '@elastic/eui'

import { AdvancedTargeting, DayPartitioning, TvTargeting } from 'api'

export interface IDayPartPicker {
  onSelectedDayPartsChange: (dayParts: IDayPart[]) => void
  dayParts: IDayPart[]
}

export interface IDayPart {
  day: number
  hour: number
  selected: boolean
  hovered?: boolean
}

export interface AcAxis {
  text: string
  value: number
}
export const DayPartDays: AcAxis[] = [
  { text: 'Mon', value: 0 },
  { text: 'Tue', value: 1 },
  { text: 'Wed', value: 2 },
  { text: 'Thu', value: 3 },
  { text: 'Fri', value: 4 },
  { text: 'Sat', value: 5 },
  { text: 'Sun', value: 6 }
]
export const DayPartHours: AcAxis[] = [
  { text: '12am', value: 0 },
  { text: '1am', value: 1 },
  { text: '2am', value: 2 },
  { text: '3am', value: 3 },
  { text: '4am', value: 4 },
  { text: '5am', value: 5 },
  { text: '6am', value: 6 },
  { text: '7am', value: 7 },
  { text: '8am', value: 8 },
  { text: '9am', value: 9 },
  { text: '10am', value: 10 },
  { text: '11am', value: 11 },
  { text: '12pm', value: 12 },
  { text: '1pm', value: 13 },
  { text: '2pm', value: 14 },
  { text: '3pm', value: 15 },
  { text: '4pm', value: 16 },
  { text: '5pm', value: 17 },
  { text: '6pm', value: 18 },
  { text: '7pm', value: 19 },
  { text: '8pm', value: 20 },
  { text: '9pm', value: 21 },
  { text: '10pm', value: 22 },
  { text: '11pm', value: 23 }
]

const dayPartCellWidth = 36
const dayPartCellMargin = 2
const dayPartCellBorder = '1px solid #d7d7d7'

const dayPartSelectedHoveredColor = '#6092C0'
const dayPartSelectedColor = '#006BB4'
const dayPartHoveredColor = '#98A2B3'
const dayPartBaseColor = 'white'

export const GetDayPartsFromTvTargeting = (targeting: TvTargeting) => getDayPartsFromDayPartitioning(targeting?.dayParts ?? undefined)

export const GetDayPartsFromAdvancedTargeting = (targeting: AdvancedTargeting) => getDayPartsFromDayPartitioning(targeting?.dayParts ?? undefined)

export const getDayPartsFromDayPartitioning = (dayPartitioning?: DayPartitioning) => {
  const dayParts: IDayPart[] = []
  for (let day of DayPartDays) {
    for (let hour of DayPartHours) {
      let selected: boolean | undefined = false
      switch (day.value) {
        case 0:
          selected = dayPartitioning?.monday.includes(hour.value)
          break
        case 1:
          selected = dayPartitioning?.tuesday.includes(hour.value)
          break
        case 2:
          selected = dayPartitioning?.wednesday.includes(hour.value)
          break
        case 3:
          selected = dayPartitioning?.thursday.includes(hour.value)
          break
        case 4:
          selected = dayPartitioning?.friday.includes(hour.value)
          break
        case 5:
          selected = dayPartitioning?.saturday.includes(hour.value)
          break
        case 6:
          selected = dayPartitioning?.sunday.includes(hour.value)
          break
      }
      dayParts.push({
        day: day.value,
        hour: hour.value,
        selected: selected ?? false
      })
    }
  }
  return dayParts
}

export const DefaultDayParts = (() => {
  const dayParts: IDayPart[] = []
  for (let day of DayPartDays) {
    for (let hour of DayPartHours) {
      dayParts.push({
        day: day.value,
        hour: hour.value,
        selected: hour.value >= 6 && hour.value <= 22
      })
    }
  }
  return dayParts
})()

export const DayPartPicker: React.FC<IDayPartPicker> = props => {
  const [boxStart, setBoxStart] = useState<IDayPart>()
  const overDay = useRef(0)
  const overHour = useRef(0)
  const mouseDown = useRef(0)
  const containerRef: React.RefObject<any> = useRef()

  const mouseDownFunc = () => {
    mouseDown.current = 1
    const currentBox = props.dayParts.find(d => d.day === overDay.current && d.hour === overHour.current)
    if (currentBox) {
      currentBox.selected = !currentBox.selected
      setBoxStart({ ...currentBox })
      if (props.onSelectedDayPartsChange) {
        props.onSelectedDayPartsChange([...props.dayParts])
      }
    }
  }

  const mouseUpFunc = () => {
    setBoxStart(undefined)
    mouseDown.current = 0
  }

  const inRange = (x: number, pt1: number, pt2: number) => {
    const low = pt1 < pt2 ? pt1 : pt2
    const high = pt1 > pt2 ? pt1 : pt2

    return x >= low && x <= high
  }

  const getBackgroundColorForDayPart = (day: number, hour: number) => {
    const dayPart = props.dayParts.find(d => d.day === day && d.hour === hour)
    if (dayPart) {
      if (dayPart.selected) {
        if (dayPart.hovered) {
          return dayPartSelectedHoveredColor
        } else {
          return dayPartSelectedColor
        }
      } else if (dayPart.hovered) {
        return dayPartHoveredColor
      }
    }
    return dayPartBaseColor
  }

  const cellTotalWidth = (dayPartCellWidth + 2 * dayPartCellMargin) * (DayPartHours.length + 1)

  return (
    <div style={{ width: cellTotalWidth + 'px', overflow: 'auto' }} ref={containerRef} onMouseDown={mouseDownFunc} onMouseUp={mouseUpFunc}>
      <div style={{ width: '100%', float: 'left' }} key={'hourRow'}>
        <div
          style={{
            float: 'left',
            width: dayPartCellWidth + 'px',
            height: dayPartCellWidth + 'px',
            margin: dayPartCellMargin + 'px',
            textAlign: 'center',
            lineHeight: dayPartCellWidth - 4 + 'px'
          }}
          key={'blank'}
        />
        {DayPartHours.map(hour => (
          <div
            style={{
              float: 'left',
              width: dayPartCellWidth + 'px',
              height: dayPartCellWidth + 'px',
              margin: dayPartCellMargin + 'px',
              border: dayPartCellBorder,
              borderRadius: '2px',
              textAlign: 'center',
              lineHeight: dayPartCellWidth - 4 + 'px'
            }}
            key={hour.text}>
            <EuiText size='xs'>{hour.text}</EuiText>
          </div>
        ))}
      </div>
      {DayPartDays.map(day => (
        <React.Fragment key={day.text + ' row'}>
          <div style={{ width: '100%', float: 'left' }} key={day.text + ' row'}>
            <div
              style={{
                float: 'left',
                width: dayPartCellWidth + 'px',
                height: dayPartCellWidth + 'px',
                margin: dayPartCellMargin + 'px',
                border: dayPartCellBorder,
                borderRadius: '2px',
                textAlign: 'center',
                lineHeight: dayPartCellWidth - 4 + 'px'
              }}
              key={day.text}>
              <EuiText size='xs'>{day.text}</EuiText>
            </div>
            {DayPartHours.map(hour => (
              <div
                key={day.value + ':' + hour.value}
                style={{
                  float: 'left',
                  width: dayPartCellWidth + 'px',
                  height: dayPartCellWidth + 'px',
                  margin: dayPartCellMargin + 'px',
                  border: dayPartCellBorder,
                  borderRadius: '2px',
                  textAlign: 'center',
                  backgroundColor: getBackgroundColorForDayPart(day.value, hour.value)
                }}
                onMouseEnter={() => {
                  const currentBox = props.dayParts.find(d => d.day === day.value && d.hour === hour.value)
                  if (!currentBox) {
                    return
                  }
                  overHour.current = hour.value
                  overDay.current = day.value
                  if (mouseDown.current > 0) {
                    if (boxStart) {
                      const dayRange = DayPartDays.filter(d => inRange(d.value, currentBox.day, boxStart.day))
                      const hourRange = DayPartHours.filter(h => inRange(h.value, currentBox.hour, boxStart.hour))
                      for (let tday of dayRange) {
                        for (let thour of hourRange) {
                          const updateItem = props.dayParts.find(d => d.day === tday.value && d.hour === thour.value)
                          if (updateItem) {
                            updateItem.selected = boxStart.selected
                          }
                        }
                      }
                      props.onSelectedDayPartsChange([...props.dayParts])
                    } else {
                      setBoxStart({ ...currentBox })
                    }
                  } else {
                    currentBox.hovered = true
                    props.onSelectedDayPartsChange([...props.dayParts])
                  }
                }}
                onMouseLeave={() => {
                  const currentBox = props.dayParts.find(d => d.day === day.value && d.hour === hour.value)
                  if (currentBox) {
                    currentBox.hovered = false
                    props.onSelectedDayPartsChange([...props.dayParts])
                  }
                }}
              />
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
