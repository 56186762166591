import React, { useEffect, useState } from 'react'

import { EuiButton, EuiEmptyPrompt, EuiFieldSearch, EuiFlexGrid, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiImage, EuiInputPopover, EuiLink, EuiListGroup, EuiListGroupItem, EuiLoadingSpinner, EuiPagination, EuiPortal, EuiSpacer, EuiTab, EuiTabs, EuiText, EuiTitle } from '@elastic/eui'

import { AcImage, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface AdImageSelectFlyOutParams {
  accountId: string
  businessTypeId: string | null

  selectImage(selection: AcImage): void
}

const AdImageSelectFlyOut: React.FC<AdImageSelectFlyOutParams> = ({ accountId, businessTypeId, selectImage }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [searchId, setSearchId] = useState<string>('')
  const [suggestions, setSuggestions] = useState<string[]>([])
  const debouncedSearch = useDebounce(search, 500)
  const [shutterstockImages, setShutterstockImages] = useState<AcImage[]>([])
  const [accountImages, setAccountImages] = useState<AcImage[]>([])
  const [businessTypeImages, setBusinessTypeImages] = useState<AcImage[]>([])
  const [selectedImage, setSelectedImage] = useState<AcImage | null>(null)
  const [searchPopoverOpen, setSearchPopoverOpen] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const pageSize = 20
  const [selectedTab, setSelectedTab] = useState<string>('shutterstock')
  const [needsLicense, setNeedsLicense] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      opsClient.getImages(accountId).then(result => {
        setAccountImages(result)
      })

      if (businessTypeId) {
        opsClient.getImages(businessTypeId).then(result => {
          setBusinessTypeImages(result)
        })
      }
    }
  }, [opsClient, accountId, businessTypeId])

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  const onSearch = () => {
    if (opsClient) {
      setCurrentPage(1)
      setSearchPopoverOpen(false)
      setIsSearching(true)
      opsClient.searchImages(search, pageSize, currentPage).then(result => {
        setSearchId(result.searchId)
        setTotalCount(result.totalCount)
        setShutterstockImages(result.images)
        setIsSearching(false)
      })
    }
  }

  const selectImageInternal = () => {
    if (opsClient && selectedImage) {
      setIsWorking(true)
      if (needsLicense) {
        opsClient!.licenseImageForAccount(selectedImage.id, accountId, searchId).then(result => {
          setSearch('')
          selectImage(result)
          setIsFlyoutVisible(false)
          setIsWorking(false)
        })
      } else {
        setIsFlyoutVisible(false)
        selectImage(selectedImage)
        setIsWorking(false)
      }
    }
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  useEffect(() => {
    if (debouncedSearch && opsClient) {
      setIsLoadingSuggestions(true)
      opsClient.searchImageSuggestions(debouncedSearch).then(result => {
        setSuggestions(result)
        setIsLoadingSuggestions(false)
      })
    }
  }, [debouncedSearch, opsClient])

  const gotoPage = (page: number) => {
    setCurrentPage(page)
    setIsSearching(true)
    opsClient!.searchImages(search, pageSize, currentPage).then(result => {
      setSearchId(result.searchId)
      setTotalCount(result.totalCount)
      setShutterstockImages(result.images)
      setIsSearching(false)
    })
  }

  const onSelectedTabChanged = (id: string) => {
    setSelectedTab(id)
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size='s'>
              <h2>Select An Image</h2>
            </EuiTitle>
            <EuiTabs style={{ marginBottom: '-25px' }}>
              <EuiTab isSelected={selectedTab === 'shutterstock'} key={1} id='searchImageTab' aria-controls='searchImageTabPanel' onClick={() => onSelectedTabChanged('shutterstock')}>
                Search Shutterstock
              </EuiTab>
              <EuiTab isSelected={selectedTab === 'account'} key={2} id='uploadImageTab' aria-controls='uploadImageTabPanel' onClick={() => onSelectedTabChanged('account')}>
                Uploaded Account Images
              </EuiTab>
              <EuiTab isSelected={selectedTab === 'businessType'} key={3} id='businessTypeImageTab' aria-controls='uploadImageTabPanel' onClick={() => onSelectedTabChanged('businessType')}>
                Business Type Images
              </EuiTab>
            </EuiTabs>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <div role='tabpanel' id='shutterstockTabPanel' aria-labelledby='shutterstock' hidden={selectedTab !== 'shutterstock'}>
              <EuiSpacer />
              <EuiInputPopover fullWidth input={<EuiFieldSearch fullWidth value={search} isLoading={isLoadingSuggestions} onChange={v => onSearchChange(v.target.value)} isClearable={true} onFocus={() => setSearchPopoverOpen(true)} incremental={false} onSearch={() => onSearch()} />} isOpen={searchPopoverOpen} closePopover={() => setSearchPopoverOpen(false)}>
                {suggestions.length ? (
                  <EuiListGroup flush={true}>
                    {suggestions.map(h => (
                      <EuiListGroupItem
                        key={h}
                        label={h}
                        onClick={() => {
                          setSearch(h)
                          onSearch()
                        }}
                      />
                    ))}
                  </EuiListGroup>
                ) : (
                  <EuiText color='subdued' size='xs'>
                    <p>Start typing a search, hints will appear here</p>
                  </EuiText>
                )}
              </EuiInputPopover>
              <EuiSpacer />
              {shutterstockImages && shutterstockImages.length > 0 && (
                <React.Fragment>
                  {isSearching && <EuiLoadingSpinner size='xl' />}
                  {!isSearching && (
                    <EuiFlexGrid>
                      {shutterstockImages!.map(i => (
                        <EuiFlexItem key={`${i.id}-ss`}>
                          <EuiImage
                            alt={''}
                            url={i.thumbnailUrl}
                            hasShadow={true}
                            onClick={() => {
                              setSelectedImage(i.thumbnailUrl === selectedImage?.thumbnailUrl ? null : i)
                              setNeedsLicense(true)
                            }}
                            className={selectedImage === i ? 'selectableImage selectableImage-selected' : 'selectableImage'}
                          />
                        </EuiFlexItem>
                      ))}
                    </EuiFlexGrid>
                  )}
                  <EuiSpacer />
                  <EuiPagination aria-label='Image search result pages' pageCount={Math.floor(totalCount / pageSize)} activePage={currentPage - 1} onPageClick={activePage => gotoPage(activePage + 1)} />
                </React.Fragment>
              )}
            </div>
            <div role='tabpanel' id='accountTabPanel' aria-labelledby='account' hidden={selectedTab !== 'account'}>
              <EuiSpacer />
              <EuiFlexGrid>
                {accountImages &&
                  accountImages.map(i => (
                    <EuiFlexItem>
                      <EuiImage
                        key={`${i.id}-a`}
                        alt={''}
                        url={i.thumbnailUrl}
                        hasShadow={true}
                        onClick={() => {
                          setSelectedImage(i.thumbnailUrl === selectedImage?.thumbnailUrl ? null : i)
                          setNeedsLicense(false)
                        }}
                        className={selectedImage === i ? 'selectableImage selectableImage-selected' : 'selectableImage'}
                      />
                    </EuiFlexItem>
                  ))}
                {(!accountImages || accountImages.length === 0) && (
                  <EuiEmptyPrompt
                    iconType='image'
                    body={
                      <EuiText size='s'>
                        <p>No images found in this account</p>
                      </EuiText>
                    }
                  />
                )}
              </EuiFlexGrid>
            </div>
            <div role='tabpanel' id='businessTypeTabPanel' aria-labelledby='businessType' hidden={selectedTab !== 'businessType'}>
              <EuiSpacer />
              <EuiFlexGrid>
                {businessTypeImages &&
                  businessTypeImages.map(i => (
                    <EuiFlexItem>
                      <EuiImage
                        key={`${i.id}-bt`}
                        alt={''}
                        url={i.thumbnailUrl}
                        hasShadow={true}
                        onClick={() => {
                          setSelectedImage(i.thumbnailUrl === selectedImage?.thumbnailUrl ? null : i)
                          setNeedsLicense(false)
                        }}
                        className={selectedImage === i ? 'selectableImage selectableImage-selected' : 'selectableImage'}
                      />
                    </EuiFlexItem>
                  ))}
                {(!businessTypeImages || businessTypeImages.length === 0) && (
                  <EuiEmptyPrompt
                    iconType='image'
                    body={
                      <EuiText size='s'>
                        <p>No images found for this business type</p>
                      </EuiText>
                    }
                  />
                )}
              </EuiFlexGrid>
            </div>
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            <EuiButton fill disabled={isWorking || selectedImage === null} onClick={selectImageInternal} isLoading={isWorking}>
              Choose Selected Image
            </EuiButton>
          </EuiFlyoutFooter>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showFlyout} color='primary'>
        Select image
      </EuiLink>
      {flyout}
    </React.Fragment>
  )
}

export default AdImageSelectFlyOut
