import { Moment } from 'moment'

export interface SalesAgent {
  id: string
  name: string
  title: string
  phone: string
  email: string
  isActive: boolean
  commissionStructure: CommissionStructure
}

export interface CommissionEvent {
  id: string
  commissionTriggerName: string
  commissionTriggerType: CommissionTriggerType
  salesAgentId: string
  commissionItemId: string
  organizationId: string
  organizationName: string
  commissionItemType: CommissionItemType
  eventDate: Moment
  saleAmount: number
  commissionAmount: number
  commissionPercentage: number
  commissionBonus: number
  isActive: boolean
  changes: CommissionEventChange[]
}

export interface CommissionEventChange {
  changed: Moment
  username: string
  changeType: CommissionEventChangeType
  oldValue: string
  newValue: string
}

export enum CommissionEventChangeType {
  CommissionRate = 'CommissionRate',
  SaleAmount = 'SaleAmount',
  CommissionBonus = 'CommissionBonus',
  ActiveStatus = 'ActiveStatus',
  EventDate = 'EventDate',
  SalesAgent = 'SalesAgent'
}

export interface CommissionStructure {
  maxYearlyCommission?: number
  maxMonthlyCommission?: number
  minYearlyCommission?: number
  minMonthlyCommission?: number
  commissionRules: CommissionRule[]
}

export interface CommissionRule {
  id: string
  name: string
  commissionTriggerType: CommissionTriggerType
  commissionPercentage: number
  maxCommission?: number
  minCommission?: number
  commissionItemType: CommissionItemType
  isActive: boolean
}

export enum CommissionTriggerType {
  BundlePurchase = 'BundlePurchase',
  BundleRecur = 'BundleRecur',
  BundleRefund = 'BundleRefund',
  Manual = 'Manual'
}

export enum CommissionItemType {
  Bundle = 'Bundle',
  Manual = 'Manual'
}
