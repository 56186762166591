import React, { useState } from 'react'

import { EuiPageTemplate } from '@elastic/eui'

import { AcTab } from 'components/ACTools/AcTab'

import CampaignTab from './CampaignTab'
import PackageTab from './PackageTab'
import PlanTab from './PlanTab'

const PerformanceDashboardPage: React.FC = () => {
  const [tab, setTab] = useState(0)

  const tabs: AcTab[] = [
    { label: 'Plans', isSelected: tab === 0, onClick: () => setTab(0) },
    { label: 'Packages', isSelected: tab === 1, onClick: () => setTab(1) },
    { label: 'Campaigns', isSelected: tab === 2, onClick: () => setTab(2) }
  ]

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='advancedSettingsApp' pageTitle='Performance Dashboard' tabs={tabs} restrictWidth={false} bottomBorder={false} />
      {tab === 0 && <PlanTab />}
      {tab === 1 && <PackageTab />}
      {tab === 2 && <CampaignTab />}
    </EuiPageTemplate>
  )
}
export default PerformanceDashboardPage
