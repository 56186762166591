import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiIcon, EuiLink, EuiPortal, EuiText, EuiTitle } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { Category } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardCustomerQuery, useGetExecutiveDashboardPlanQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'
import { insertSpaces } from 'utils/EnumToFriendly'

interface FlyOutParams {
  forDate: string
  title?: string
  organizationIds: string[] | null
}

const columns: EuiDataGridColumn[] = [
  {
    id: 'organizationId',
    displayAsText: 'Organization ID',
    isSortable: false
  },
  {
    id: 'organizationName',
    displayAsText: 'Organization Name'
  },
  {
    id: 'organizationLink',
    displayAsText: 'Organization',
    isSortable: false,
    isExpandable: false
  },
  {
    id: 'firstPaymentType',
    displayAsText: 'Type',
    isExpandable: false
  },
  {
    id: 'firstPaymentDate',
    displayAsText: 'Date',
    schema: 'date',
    isExpandable: false
  },
  {
    id: 'initialActivationAmount',
    displayAsText: 'IA Amount',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'initialActivationDate',
    displayAsText: 'IA Date',
    schema: 'date',
    isExpandable: false
  },
  {
    id: 'planPurchaseAmount',
    displayAsText: 'Plan Amount',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'planPurchaseDate',
    displayAsText: 'Plan Date',
    schema: 'date',
    isExpandable: false
  },
  {
    id: 'packagePurchaseAmount',
    displayAsText: 'Package Amount',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'packagePurchaseDate',
    displayAsText: 'Package Date',
    schema: 'date',
    isExpandable: false
  }
]

const ExecutiveDashboardCustomerReportFlyout: React.FC<FlyOutParams> = ({ forDate, title = 'view customers', organizationIds }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const { data } = useGetExecutiveDashboardCustomerQuery({ forDate })
  const [customers, setCustomers] = useState<any>([])
  const [visibleColumns, setVisibleColumns] = useState(['organizationId', 'organizationName', 'organizationLink', 'firstPaymentType', 'firstPaymentDate', 'initialActivationDate', 'initialActivationAmount', 'planPurchaseDate', 'planPurchaseAmount', 'packagePurchaseDate', 'packagePurchaseAmount'])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const [sortingColumns, setSortingColumns] = useState<Array<{ id: string; direction: 'asc' | 'desc' }>>([{ id: 'firstPaymentDate', direction: 'asc' }])

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  useEffect(() => {
    if (data) {
      let raw: any[]
      if (organizationIds) {
        raw = data.allCustomers.filter(cr => organizationIds.includes(cr.organizationId))
      } else {
        raw = data.allCustomers
      }

      setCustomers(
        raw.map(pr => ({
          organizationLink: (
            <EuiLink href={`/customers/organizations/${pr.organizationId}`} target='_blank'>
              view
            </EuiLink>
          ),
          ...pr
        }))
      )
    }
  }, [data, organizationIds])

  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (customers.hasOwnProperty(rowIndex)) {
        if (typeof customers[rowIndex][columnId] == 'boolean') {
          return customers[rowIndex][columnId] ? 'true' : 'false'
        }

        if (typeof customers[rowIndex][columnId] === 'number') {
          if (schema === 'currency') {
            return customers[rowIndex][columnId] ? customers[rowIndex][columnId].toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + '' : ''
          }

          return customers[rowIndex][columnId] ? customers[rowIndex][columnId] + '' : ''
        }

        if (schema === 'date') {
          return customers[rowIndex][columnId] ? moment(customers[rowIndex][columnId]).format('YYYY-MM-DD') + '' : ''
        }

        return customers[rowIndex][columnId] ? customers[rowIndex][columnId] : null
      }
    }
  }, [customers])

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
          <EuiFlyoutHeader>
            <EuiTitle size='s'>
              <h2>Customers</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiDataGrid
              aria-label='Customers Report'
              columns={columns}
              columnVisibility={{ visibleColumns, setVisibleColumns }}
              gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal', rowHover: 'highlight' }}
              rowCount={customers.length}
              renderCellValue={renderCellValue}
              inMemory={{ level: 'sorting' }}
              sorting={{ columns: sortingColumns, onSort }}
              pagination={{
                ...pagination,
                pageSizeOptions: [50, 100, 200, 500],
                onChangeItemsPerPage,
                onChangePage
              }}
              toolbarVisibility={{
                additionalControls: (
                  <React.Fragment>
                    <CSVLink data={customers} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`customers_report.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                      <span className='euiButtonContent euiButtonEmpty__content'>
                        <EuiIcon type='download' />
                        <span className='euiButton__text'>Download Report (CSV)</span>
                      </span>
                    </CSVLink>
                  </React.Fragment>
                )
              }}
            />
          </EuiFlyoutBody>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showFlyout} color='primary'>
        <EuiText size='xs'>{title}</EuiText>
      </EuiLink>
      {flyout}
    </React.Fragment>
  )
}

export default ExecutiveDashboardCustomerReportFlyout
