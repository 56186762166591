import { AdvertisingSnapshot } from 'api/entities/AdvertisingSnapshot'
import { Account } from 'api/index'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const accountsTag = opsApi.enhanceEndpoints({ addTagTypes: ['Accounts', 'Account', 'AccountSnapshot'] })

const accountsApi = accountsTag.injectEndpoints({
  endpoints: build => ({
    getAccounts: build.query<Account[], void>({
      query: () => 'accounts',
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Accounts' }]
    }),
    getAccount: build.query<Account, string | undefined>({
      query: id => `accounts/${id}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'Account', id }]
    }),
    getAdvertisingSnapshot: build.query<AdvertisingSnapshot, string>({
      query: id => `accounts/${id}/snapshot`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'AccountSnapshot', id }]
    })
  }),
  overrideExisting: false
})

export const { useGetAccountsQuery, useGetAccountQuery, useGetAdvertisingSnapshotQuery } = accountsApi
