import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiSpacer, EuiSwitch, EuiText, EuiTitle } from '@elastic/eui'

import { CampaignType } from 'api'
import { BundleCategory, Bundle, BundleActiveStatus, CampaignSlotSimple, TargetingPriority } from 'api/entities/Bundle'
import { useUpdateIsWatchListedMutation, useUpdateBundleCategoryMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { useGetSalesAgentQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import { RootState } from 'app/rootReducer'
import BundlePriceDisplay from 'components/BundlePriceDisplay'
import { CampaignSlotDetailsPanel } from 'components/Bundles/CampaignSlotDetailsPanel'
import { CreateCampaignSlotPanel } from 'components/Bundles/CreateCampaignSlotPanel'
import { CreditSalesAgentModal } from 'components/Bundles/CreditSalesAgentModal'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { BundleCategoryPicker } from 'features/customers/bundles/BundleCategoryPicker'
import { GetBundleCategoryProperties, GetTargetingPriorityProperties } from 'features/customers/bundles/BundleEnumsProperties'
import { TargetingPrioritySelect } from 'features/customers/bundles/TargetingPrioritySelect'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IBundleDetailsFlyout {
  onClose?: () => void
  onSave?: (bundleId: string, addedSlots: CampaignSlotSimple[], removedSlots: CampaignSlotSimple[]) => void
  bundle: Bundle
}

export const BundleDetailsFlyout: React.FC<IBundleDetailsFlyout> = props => {
  const [addedSlots, setAddedSlots] = useState<CampaignSlotSimple[]>([])
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [addedPrice, setAddedPrice] = useState<number>(0)
  const [originalPrice, setOriginalPrice] = useState<number>(0)
  const [showSaveWarning, setShowSaveWarning] = useState<boolean>(false)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [isWatchListed, setIsWatchListed] = useState(props.bundle.isWatchListed)
  const [packageCategory, setPackageCategory] = useState<BundleCategory>(props.bundle.bundleCategory)
  const [targetingPriority, setTargetingPriority] = useState<TargetingPriority>(props.bundle.targetingPriority)
  const salesAgentQuery = useGetSalesAgentQuery(props.bundle.salesAgentId, { skip: !props.bundle.salesAgentId || !userProfile?.roles.includes('adcritter-ops-csuite') })
  const [updateIsWatchListed, updateIsWatchListedRequest] = useUpdateIsWatchListedMutation()
  const [updateBundleCategory, updateBundleCategoryRequest] = useUpdateBundleCategoryMutation()
  const [showCreditSalesAgentModal, setShowCreditSalesAgentModal] = useState<boolean>(false)

  useEffect(() => {
    setIsWatchListed(props.bundle.isWatchListed)
  }, [props.bundle.isWatchListed])

  useEffect(() => {
    setPackageCategory(props.bundle.bundleCategory)
  }, [props.bundle.bundleCategory])

  useEffect(() => {
    setTargetingPriority(props.bundle.targetingPriority)
  }, [props.bundle.targetingPriority])

  useEffect(() => {
    const allCampaignSlots = [...addedSlots, ...props.bundle.campaignSlots]
    if (allCampaignSlots.length <= 0) {
      setTotalPrice(0)
    }

    const originalBudget = props.bundle.campaignSlots.reduce((partialSum, a) => {
      if (a.isBonus) {
        return partialSum
      }

      return partialSum + a.budget
    }, 0)

    const totalBudget = allCampaignSlots.reduce((partialSum, a) => {
      if (a.isBonus) {
        return partialSum
      }

      return partialSum + a.budget
    }, 0)

    setTotalPrice(totalBudget)
    setAddedPrice(totalBudget - originalBudget)
    setOriginalPrice(originalBudget)
  }, [addedSlots, props.bundle.campaignSlots])

  const onCloseFlyout = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const onSaveFlyout = async () => {
    if (props.bundle.isWatchListed !== isWatchListed) {
      await updateIsWatchListed({ organizationId: props.bundle.organizationId, bundleId: props.bundle.id, isWatchListed: isWatchListed })
    }

    if (props.bundle.bundleCategory !== packageCategory || props.bundle.targetingPriority !== targetingPriority) {
      await updateBundleCategory({ organizationId: props.bundle.organizationId, bundleId: props.bundle.id, bundleCategory: packageCategory, targetingPriority: targetingPriority })
    }

    if (props.onSave) {
      props.onSave(props.bundle.id, addedSlots, [])
    }
  }

  const onSaveClick = () => {
    if (originalPrice !== totalPrice) {
      setShowSaveWarning(true)
    } else {
      onSaveFlyout()
    }
  }

  const onAddCampaignSlotClicked = () => {
    setAddedSlots([{ id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: 1000 }, ...addedSlots])
  }

  const onAddedCampaignSlotUpdate = (slot: CampaignSlotSimple) => {
    const stateSlot = addedSlots.find(s => s.id === slot.id)

    if (stateSlot) {
      stateSlot.campaignType = slot.campaignType
      stateSlot.budget = slot.budget
      stateSlot.isBonus = slot.isBonus

      setAddedSlots([...addedSlots])
    }
  }

  const onRemoveAddedCampaignSlotClicked = (slotId: string) => {
    setAddedSlots(addedSlots.filter(s => s.id !== slotId))
  }

  const packageCategoryProperties = GetBundleCategoryProperties(packageCategory)
  const targetingPriorityProperties = GetTargetingPriorityProperties(targetingPriority)

  const onBundleCategoryChange = (category: BundleCategory) => {
    setPackageCategory(category)
  }

  const onTargetingPriorityChange = (priority: TargetingPriority) => {
    setTargetingPriority(priority)
  }

  const onAssignToAccountManagerClick = () => {
    setShowCreditSalesAgentModal(true)
  }

  const onCreditSalesAgentModalClose = () => {
    setShowCreditSalesAgentModal(false)
  }

  return (
    <React.Fragment>
      <EuiFlyout onClose={onCloseFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h2>View {insertSpaces(props.bundle.bundleType)}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFormRow fullWidth>
            <EuiSwitch label={'Watchlisted'} checked={isWatchListed} onChange={() => setIsWatchListed(!isWatchListed)} id={'isWatchListed'} />
          </EuiFormRow>
          {userProfile?.roles.includes('adcritter-ops-csuite') || userProfile?.roles.includes('adcritter-ops-salesmanager') ? (
            <React.Fragment>
              <EuiFormRow fullWidth label='Category'>
                <BundleCategoryPicker bundleType={props.bundle.bundleType} onBadgeClick={onBundleCategoryChange} selectedKey={packageCategory} />
              </EuiFormRow>
              <EuiFormRow fullWidth label='Targeting'>
                <TargetingPrioritySelect onChange={onTargetingPriorityChange} selectedKey={targetingPriority} />
              </EuiFormRow>
              {props.bundle.salesAgentId ? (
                <React.Fragment>
                  {salesAgentQuery.data ? (
                    <EuiFormRow label='Account Manager'>
                      <EuiText>{salesAgentQuery.data?.name}</EuiText>
                    </EuiFormRow>
                  ) : (
                    <EuiFormRow label='Account Manager'>
                      <EuiText>Loading...</EuiText>
                    </EuiFormRow>
                  )}
                </React.Fragment>
              ) : (
                <EuiFormRow>
                  <EuiButton fill onClick={onAssignToAccountManagerClick}>
                    Assign To Account Manager
                  </EuiButton>
                </EuiFormRow>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <EuiFormRow label={'Category'} fullWidth>
                <EuiText size={'s'}>{packageCategoryProperties.text}</EuiText>
              </EuiFormRow>
              <EuiFormRow fullWidth label={'Targeting'}>
                <EuiText size={'s'}>{targetingPriorityProperties.text}</EuiText>
              </EuiFormRow>
            </React.Fragment>
          )}

          <EuiSpacer />
          <EuiTitle size={'xs'}>
            <h2>Status</h2>
          </EuiTitle>
          <EuiText>
            <p>
              {props.bundle?.campaignSlots.filter(c => c.campaignId).length} / {props.bundle?.campaignSlots.length} slots used
            </p>
          </EuiText>
          <EuiSpacer />
          <EuiTitle size={'xs'}>
            <h2>Billing Cycle</h2>
          </EuiTitle>
          <BundlePriceDisplay bundle={props.bundle} totalPrice={totalPrice} addedPrice={addedPrice} originalPrice={originalPrice} />
          <EuiSpacer />
          {props.bundle.billingSchedule.nextEventDate && (
            <React.Fragment>
              <EuiFormRow label={'Next Billing Date'}>
                <EuiText>
                  <p>{moment(props.bundle.billingSchedule.nextEventDate).format('MM/DD/YYYY')}</p>
                </EuiText>
              </EuiFormRow>
              <EuiSpacer />
            </React.Fragment>
          )}
          {props.bundle.billingSchedule.lastIntervalDate && (
            <React.Fragment>
              <EuiFormRow label={'Last Billed Date'}>
                <EuiText>
                  <p>{moment(props.bundle.billingSchedule.lastIntervalDate).format('MM/DD/YYYY')}</p>
                </EuiText>
              </EuiFormRow>
              <EuiSpacer />
            </React.Fragment>
          )}
          <EuiTitle size={'s'}>
            <h2>Campaign Slots</h2>
          </EuiTitle>
          <EuiButtonEmpty iconType={'plus'} onClick={onAddCampaignSlotClicked}>
            Add Campaign Slot
          </EuiButtonEmpty>
          <EuiSpacer />
          {addedSlots.length > 0 && (
            <React.Fragment>
              <EuiTitle size={'xs'}>
                <h2>Campaign Slots To Add</h2>
              </EuiTitle>
              <EuiSpacer />
              {addedSlots.map(slot => (
                <React.Fragment key={slot.id}>
                  <CreateCampaignSlotPanel
                    bundleType={props.bundle.bundleType}
                    allowMatchingOption
                    slot={slot}
                    isBonusSlot={slot.isBonus ?? false}
                    onSlotUpdate={onAddedCampaignSlotUpdate}
                    onCancelClick={() => {
                      onRemoveAddedCampaignSlotClicked(slot.id)
                    }}
                  />
                  <EuiSpacer />
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
          {addedSlots.length > 0 && (
            <React.Fragment>
              <EuiTitle size={'xs'}>
                <h2>Existing Campaign Slots</h2>
              </EuiTitle>
              <EuiSpacer />
            </React.Fragment>
          )}
          {props.bundle?.campaignSlots.map(slot => (
            <React.Fragment key={slot.id}>
              <CampaignSlotDetailsPanel bundle={props.bundle!} slot={slot} organizationId={props.bundle!.organizationId} editable={!slot.isBonus && !slot.campaignId} />
              <EuiSpacer />
            </React.Fragment>
          ))}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={onSaveClick} isLoading={updateIsWatchListedRequest.isLoading || updateBundleCategoryRequest.isLoading}>
                Save
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
      {showSaveWarning && (
        <AcConfirmModal
          title={'Confirm Price Increase'}
          message={`Are you sure you want to INCREASE the price by $${addedPrice.toLocaleString()}? ${props.bundle.bundleActiveStatus === BundleActiveStatus.Active ? `The organization will be charged $${addedPrice.toLocaleString()} now and the monthly price will increase to $${totalPrice.toLocaleString()}.` : `The monthly price will increase to $${totalPrice.toLocaleString()}.`}`}
          onCancel={() => {
            setShowSaveWarning(false)
          }}
          confirmButtonText={'Confirm'}
          cancelButtonText={'Cancel'}
          onConfirm={onSaveFlyout}
        />
      )}
      {showCreditSalesAgentModal && <CreditSalesAgentModal bundle={props.bundle} onClose={onCreditSalesAgentModalClose} onCompleted={onCreditSalesAgentModalClose} />}
    </React.Fragment>
  )
}
