import React, { FC } from 'react'

import { EuiButton } from '@elastic/eui'

import { OrganizationProposal, SalesStage } from 'api'
import { useSetOrganizationSalesStageMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'

export interface LostButtonProps {
  proposal: OrganizationProposal
}

const LostButton: FC<LostButtonProps> = ({ proposal }) => {
  const [setOrganizationSalesStage, salesStageResult] = useSetOrganizationSalesStageMutation()

  return (
    <EuiButton
      color={'danger'}
      onClick={() =>
        setOrganizationSalesStage({
          organizationId: proposal.organizationId,
          salesStage: SalesStage.Lost
        })
      }
      isLoading={salesStageResult.originalArgs?.organizationId === proposal.organizationId && salesStageResult.isLoading}>
      Lost
    </EuiButton>
  )
}

export default LostButton
