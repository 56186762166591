import React, { useEffect } from 'react'

import { EuiButton, EuiButtonIcon, EuiCheckbox, EuiFieldSearch, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiPanel, EuiPopover, EuiSelect, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { AcDomain, DomainList, NetworkType } from 'api/entities/Domains'
import { useGetDomainsQuery, useUpsertDomainsMutation } from 'api/rtkQueryApi/opsApi/domainsApi'
import { ImportDomainsModal } from 'features/opsManagerDashboard/ImportDomainsModal'
import { ShowcaseDomainFilterGroup } from 'features/opsManagerDashboard/ShowcaseDomainFilterGroup'
import { ShowcaseDomainItem } from 'features/opsManagerDashboard/ShowcaseDomainItem'
import { insertSpaces } from 'utils/EnumToFriendly'
import { useDebounceEffect } from 'utils/useDebounceEffect'

enum SortType {
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc',
  NetworkType = 'networkType'
}

export const DomainManagementPage: React.FC = () => {
  const domainListQuery = useGetDomainsQuery()
  const [upsertDomainList] = useUpsertDomainsMutation()
  const [domainList, setDomainList] = React.useState<DomainList>()
  const [filteredDomainList, setFilteredDomainList] = React.useState<DomainList>({ domains: [] })
  const [updatedDomainList, setUpdatedDomainList] = React.useState<DomainList>()
  const [search, setSearch] = React.useState('')
  const [addDomainText, setAddDomainText] = React.useState('')
  const [addAsNetworkType, setAddAsNetworkType] = React.useState<NetworkType>(NetworkType.NotSet)
  const [activeFilters, setActiveFilters] = React.useState<NetworkType[]>([])
  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false)
  const [showSortOptions, setShowSortOptions] = React.useState(false)
  const [selectedSortType, setSelectedSortType] = React.useState<SortType>(SortType.NameAsc)
  const [selectedDomains, setSelectedDomains] = React.useState<AcDomain[]>([])
  const [selectAllChecked, setSelectAllChecked] = React.useState(false)

  useEffect(() => {
    if (updatedDomainList) {
      setDomainList(updatedDomainList)
    }
  }, [updatedDomainList])

  useEffect(() => {
    if (domainListQuery.data) {
      setDomainList(domainList => (domainList ? domainList : domainListQuery.data))
    }
  }, [domainListQuery.data])

  useEffect(() => {
    if (domainList) {
      let filtered = domainList.domains.filter(domain => domain.domain.includes(search) && (activeFilters.length === 0 || activeFilters.includes(domain.networkType)))
      filtered = filtered.sort((a, b) => {
        switch (selectedSortType) {
          case SortType.NameAsc:
            return a.domain.localeCompare(b.domain)
          case SortType.NameDesc:
            return b.domain.localeCompare(a.domain)
          case SortType.NetworkType:
            return a.networkType.localeCompare(b.networkType)
          default:
            return 0
        }
      })
      setFilteredDomainList({
        domains: filtered
      })
    }
  }, [domainList, search, activeFilters, selectedSortType])

  useEffect(() => {
    setSelectedDomains([...selectedDomains.filter(d => filteredDomainList.domains.find(f => f.domain === d.domain))])
  }, [filteredDomainList])

  useEffect(() => {
    if (selectAllChecked) {
      setSelectedDomains([...filteredDomainList.domains])
    } else {
      setSelectedDomains([])
    }
  }, [selectAllChecked])

  useDebounceEffect(
    500,
    () => {
      if (!updatedDomainList) {
        return
      }
      upsertDomainList(updatedDomainList)
    },
    [updatedDomainList]
  )

  const onImportDomainListClick = () => {
    setIsImportModalOpen(true)
  }

  const onImportDomainListConfirm = (importedDomains: string[]) => {
    if (domainListQuery.isLoading) {
      return
    }
    setIsImportModalOpen(false)
    const updateDomainList: DomainList = { domains: domainList ? [...domainList.domains] : [] }
    importedDomains.forEach(domain => {
      updateDomainList.domains.push({ domain, isShowcase: true, networkType: addAsNetworkType })
    })
    updateDomainList.domains = updateDomainList.domains.filter((domain, index, self) => self.findIndex(t => t.domain === domain.domain) === index)
    setUpdatedDomainList(updateDomainList)
  }

  const onAddDomainTextChange = (value: string) => {
    setAddDomainText(value)
  }

  const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addDomainDomainText(addDomainText)
      setAddDomainText('')
    }
  }

  const onAddDomain = (value: string) => {
    addDomainDomainText(value)
    setAddDomainText('')
  }

  const addDomainDomainText = (value: string) => {
    if (domainListQuery.isLoading || !value) {
      return
    }
    const updateDomainList: DomainList = { domains: domainList ? [...domainList.domains] : [] }
    const newDomain = !value.startsWith('https://') ? `https://${value}` : value
    updateDomainList.domains.push({ domain: newDomain, isShowcase: true, networkType: addAsNetworkType })
    updateDomainList.domains = updateDomainList.domains.filter((domain, index, self) => self.findIndex(t => t.domain === domain.domain) === index)
    setUpdatedDomainList(updateDomainList)
  }

  const onRemoveDomainClick = (domain: AcDomain) => {
    if (domainListQuery.isLoading) {
      return
    }
    const updateDomainList: DomainList = { domains: domainList ? [...domainList.domains] : [] }
    updateDomainList.domains = updateDomainList.domains.filter(d => d.domain !== domain.domain)
    setUpdatedDomainList(updateDomainList)
  }

  const onUpdateDomain = (domain: AcDomain) => {
    if (domainListQuery.isLoading) {
      return
    }
    const updateDomainList: DomainList = { domains: domainList ? [...domainList.domains] : [] }
    updateDomainList.domains = updateDomainList.domains.map(d => (d.domain === domain.domain ? domain : d))
    updateDomainList.domains = updateDomainList.domains.map(d => (selectedDomains.find(sd => sd.domain === d.domain) ? { ...d, networkType: domain.networkType } : d))
    setUpdatedDomainList(updateDomainList)
  }

  const networkTypeOptions = Object.values(NetworkType).map(value => ({ value, text: insertSpaces(value) }))
  const onAddAsNetworkTypeChange = (value: string) => {
    setAddAsNetworkType(value as NetworkType)
  }

  const onFilterClicked = (networkType: NetworkType) => {
    if (activeFilters.includes(networkType)) {
      setActiveFilters(activeFilters.filter(f => f !== networkType))
    } else {
      setActiveFilters([...activeFilters, networkType])
    }
  }

  const onResetFilters = () => {
    setActiveFilters([])
  }

  const onSortMouseEnter = () => {
    setShowSortOptions(true)
  }

  const onSortMouseLeave = () => {
    setShowSortOptions(false)
  }

  const onSortTypeClick = (value: string) => {
    setSelectedSortType(value as SortType)
    setShowSortOptions(false)
  }

  const onDomainSelected = (domain: AcDomain) => {
    if (selectedDomains.find(d => d.domain === domain.domain)) {
      setSelectedDomains([...selectedDomains.filter(d => d.domain !== domain.domain)])
    } else {
      setSelectedDomains([...selectedDomains, domain])
    }
  }

  const sortTypeIcon = (value: SortType) => {
    switch (value) {
      case SortType.NameAsc:
        return 'sortAscending'
      case SortType.NameDesc:
        return 'sortDescending'
      case SortType.NetworkType:
        return 'article'
      default:
        return 'sortAscending'
    }
  }

  return (
    <React.Fragment>
      <EuiTitle size={'s'}>
        <h1>Showcase Domains</h1>
      </EuiTitle>
      <EuiSpacer />
      <EuiFormRow label={'Add Domain'}>
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiSelect compressed options={networkTypeOptions} onChange={e => onAddAsNetworkTypeChange(e.target.value)} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon onClick={onImportDomainListClick} iconType={'importAction'} title={'Upload CSV'} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size={'xs'} />
          <EuiFlexGroup gutterSize={'xs'}>
            <EuiFlexItem>
              <EuiFieldText prepend={'https://'} onKeyDown={onEnterKeyDown} placeholder={''} value={addDomainText} onChange={e => onAddDomainTextChange(e.target.value)} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton fill onClick={() => onAddDomain(addDomainText)}>
                Add
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      </EuiFormRow>
      <EuiHorizontalRule />
      <EuiFormRow label={'Search'}>
        <EuiFlexGroup gutterSize={'xs'} onMouseLeave={onSortMouseLeave}>
          <EuiFlexItem>
            <EuiFieldSearch value={search} onChange={e => setSearch(e.target.value)} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPopover button={<EuiButtonIcon size='xs' color='primary' iconType={sortTypeIcon(selectedSortType)} onMouseEnter={onSortMouseEnter} onClick={() => {}} />} isOpen={showSortOptions} closePopover={onSortMouseLeave} display='block'>
              <EuiFlexGroup wrap gutterSize={'s'}>
                {Object.values(SortType).map(value => (
                  <EuiFlexItem grow={false} key={value}>
                    <EuiButtonIcon iconType={sortTypeIcon(value)} style={{ border: 'solid 1px #0061a6', backgroundColor: value == selectedSortType ? '#ADDDFF' : undefined }} size='xs' color='primary' onClick={() => onSortTypeClick(value)} aria-label={value} title={insertSpaces(value)} />
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <ShowcaseDomainFilterGroup domains={domainList?.domains ?? []} activeFilters={activeFilters} onFilterClicked={onFilterClicked} onResetFilters={onResetFilters} />
      </EuiFormRow>
      <EuiSpacer size={'s'} />
      <EuiPanel hasShadow={false} hasBorder={false} style={{ height: 600, overflow: 'auto', paddingLeft: 0 }}>
        {domainListQuery.isLoading ? (
          <EuiText>
            <p>Loading...</p>
          </EuiText>
        ) : (
          <React.Fragment>
            <EuiCheckbox id={'selectAll'} onChange={() => setSelectAllChecked(!selectAllChecked)} checked={selectAllChecked} label={''} />
            <EuiSpacer size={'s'} />
            {filteredDomainList && filteredDomainList.domains.map((domain, index) => <ShowcaseDomainItem isEven={index % 2 == 0} isSelected={!!selectedDomains.find(d => d.domain === domain.domain)} acDomain={domain} onUpdateDomain={onUpdateDomain} onRemoveDomain={onRemoveDomainClick} onDomainSelected={() => onDomainSelected(domain)} key={index} />)}
          </React.Fragment>
        )}
      </EuiPanel>
      {isImportModalOpen && <ImportDomainsModal onClose={() => setIsImportModalOpen(false)} onConfirmImport={onImportDomainListConfirm} />}
    </React.Fragment>
  )
}
