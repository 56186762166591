import React, { FC } from 'react'
import { useParams } from 'react-router'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody } from '@elastic/eui'

import { AgencyManagerTabs } from 'features/agencyManagerDashboard/AgencyManagerTabs'
import { CohortsFlyout } from 'features/agencyManagerDashboard/CohortsFlyout'

export interface IAgencyManagerDashboardPage {
  tab?: string
}

export const AgencyManagerDashboardPage: FC = () => {
  const { tab } = useParams<IAgencyManagerDashboardPage>()
  const [showCohorts, setShowCohorts] = React.useState<boolean>(false)

  const onCohortsClicked = () => {
    setShowCohorts(true)
  }

  const onCohortFlyoutClose = () => {
    setShowCohorts(false)
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <EuiFlexGroup direction={'rowReverse'}>
              <EuiFlexItem grow={false}>
                <EuiButton fill onClick={onCohortsClicked}>
                  Cohorts
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <AgencyManagerTabs currentTab={tab ?? ''} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
      {showCohorts && <CohortsFlyout onClose={onCohortFlyoutClose} />}
    </EuiPage>
  )
}
