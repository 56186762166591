import { PartialTheme } from '@elastic/charts'

export const currencyStyle = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
export const percentStyle = { style: 'percent', maximumFractionDigits: 2 }

export const dashboardBaseTheme: PartialTheme = {
  crosshair: {
    band: {
      visible: false
    }
  },
  lineSeriesStyle: {
    point: {
      radius: 1,
      opacity: 100,
      fill: '__use__series__color__'
    }
  },
  highlighter: {
    point: {
      radius: 2,
      opacity: 100,
      fill: '__use__series__color__'
    }
  },
  axes: {
    gridLine: {
      vertical: {
        visible: false
      },
      horizontal: {
        visible: false
      }
    }
  }
}
