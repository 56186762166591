import { AgencyCategory, AgencyDetails, AgencyOrganization, Campaign, CreateOrganization, CreateOrganizationProposal, GetProposalViewsEstimateRequest, Organization, OrganizationProposal, OrganizationProposalViewsEstimate, OrganizationSpendData, Permission, SalesStage } from 'api/index'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'
import { addQueryArg } from 'utils/UrlUtils'

const organizationsTag = opsApi.enhanceEndpoints({ addTagTypes: ['Organizations', 'OrganizationAgencyDetails', 'OrganizationSpendData', 'Organization', 'OrganizationProposals', 'OrganizationProposal', 'OrganizationsByAdvisor', 'CampaignsWithoutBundlesForOrgId', 'OrganizationContacts', 'OrganizationByBundleId', 'PostOrganizationContact', 'PutOrganizationContact', 'OrganizationProposalViewsEstimate', 'Agencies', 'AgencyOrganizations', 'AgencyOrganization'] })

export interface IGetOrganizationProposals {
  hasBundle?: boolean
  ignoredSalesStages?: SalesStage[]
  sentBefore?: string
  sentAfter?: string
  salesStages?: SalesStage[]
}

export const organizationsApi = organizationsTag.injectEndpoints({
  endpoints: build => ({
    getOrganizations: build.query<Organization[], void>({
      query: () => 'organizations',
      transformResponse: defaultTransformFn,
      providesTags: result => (result ? [...result.map(({ organizationId }) => ({ type: 'Organization' as const, id: organizationId })), { type: 'Organizations' }] : [{ type: 'Organizations' }])
    }),
    getOrganization: build.query<Organization, string | undefined>({
      query: id => `organizations/${id}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'Organization', id }]
    }),
    getOrganizationProposals: build.query<OrganizationProposal[], IGetOrganizationProposals>({
      query: ({ hasBundle, salesStages, ignoredSalesStages, sentBefore, sentAfter }) => {
        let url = 'organizations/proposals'

        if (hasBundle !== undefined) {
          url = addQueryArg(url, 'hasBundle', hasBundle.toString())
        }

        if (salesStages) {
          salesStages.forEach(salesStage => {
            url = addQueryArg(url, 'salesStage', salesStage)
          })
        }

        if (ignoredSalesStages) {
          ignoredSalesStages.forEach(salesStage => {
            url = addQueryArg(url, 'ignoredSalesStage', salesStage)
          })
        }

        if (sentBefore) {
          url = addQueryArg(url, 'sentBefore', sentBefore)
        }

        if (sentAfter) {
          url = addQueryArg(url, 'sentAfter', sentAfter)
        }

        return url
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { hasBundle, salesStages, ignoredSalesStages }) => (result ? [...result.map(({ organizationId }) => ({ type: 'OrganizationProposal' as const, id: organizationId })), { type: 'OrganizationProposals', id: `${hasBundle}|${salesStages?.map(s => s)}|${ignoredSalesStages?.map(s => s)}` }] : [{ type: 'OrganizationProposals', id: `${hasBundle}|${salesStages?.map(s => s)}|${ignoredSalesStages?.map(s => s)}` }])
    }),
    getOrganizationProposal: build.query<OrganizationProposal, string>({
      query: id => `organizations/${id}/proposal`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'OrganizationProposal', id }]
    }),
    getOrganizationsByAdvisor: build.query<Organization[], string>({
      query: advisorEmail => `organizations/advisor/${encodeURIComponent(advisorEmail)}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, advisorEmail) => [{ type: 'OrganizationsByAdvisor', id: advisorEmail }]
    }),
    getOrganizationByBundleId: build.query<Organization, string>({
      query: bundleId => `bundles/${encodeURIComponent(bundleId)}/organization`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, bundleId) => [{ type: 'OrganizationByBundleId', id: bundleId }]
    }),
    getOrganizationSpendData: build.query<OrganizationSpendData, string>({
      query: organizationId => `organizations/${organizationId}/spendData`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'OrganizationSpendData', id: organizationId }]
    }),
    getCampaignsWithoutBundlesForOrganization: build.query<Campaign[], string>({
      query: organizationId => `organizations/${encodeURIComponent(organizationId)}/campaignsWithoutBundles`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'CampaignsWithoutBundlesForOrgId', id: organizationId }]
    }),
    organizationCancelSubscription: build.mutation<boolean, { organizationId: string }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/cancelSubscription`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', organizationId }, { type: 'Organizations' }]
    }),
    getProposalViewsEstimate: build.query<OrganizationProposalViewsEstimate, GetProposalViewsEstimateRequest>({
      query: request => {
        return {
          url: 'organizations/proposalViewsEstimate',
          method: 'POST',
          body: request
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { planPrice, planTVPrice, planBillboardPrice, planMatching, campaignMatchingType }) => [{ type: 'OrganizationProposalViewsEstimate', id: `${planPrice}|${planTVPrice}|${planBillboardPrice}|${planMatching}|${campaignMatchingType}` }]
    }),
    setOrganizationSalesStage: build.mutation<Organization, { organizationId: string; salesStage: SalesStage }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/salesStage`,
          method: 'PUT',
          body: {
            salesStage: query.salesStage
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [
        { type: 'Organization', organizationId },
        { type: 'OrganizationProposal', organizationId }
      ]
    }),
    postOrganizationProposal: build.mutation<OrganizationProposal, { organizationId: string; proposal: CreateOrganizationProposal }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/proposal`,
          method: 'POST',
          body: query.proposal
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'OrganizationProposal', organizationId }]
    }),
    postOrganizationProposalSend: build.mutation<OrganizationProposal, { organizationId: string }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/proposal/send`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'OrganizationProposal', organizationId }]
    }),
    setOrganizationProposalContacted: build.mutation<OrganizationProposal, string>({
      query: organizationId => {
        return {
          url: `organizations/${organizationId}/proposal/contacted`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, organizationId) => [{ type: 'OrganizationProposal', organizationId }]
    }),
    setOrganizationPermissions: build.mutation<Organization, { organizationId: string; permissions: Permission[] }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/setPermissions`,
          method: 'POST',
          body: {
            permissions: query.permissions
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', organizationId }]
    }),
    setOrganizationIncludedCampaigns: build.mutation<Organization, { organizationId: string; includedCampaignIds: string[] }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/includedCampaignIds`,
          method: 'PUT',
          body: {
            includedCampaignIds: query.includedCampaignIds
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', organizationId }]
    }),
    getAgencyOrganizations: build.query<AgencyOrganization[], { agencyCategory?: AgencyCategory; query?: string }>({
      query: query => `organizations/agencies?${query.agencyCategory ? `agencyCategory=${query.agencyCategory}` : ''}${query.query ? `&query=${query.query}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, query) => (result ? [...result.map(item => ({ type: 'AgencyOrganization' as const, id: item.organization.organizationId })), { type: 'AgencyOrganizations', id: `Category:${query.agencyCategory}|Query:${query.query}` }] : [{ type: 'AgencyOrganizations', id: `Category:${query.agencyCategory}|Query:${query.query}` }])
    }),
    getAgencyOrganization: build.query<AgencyOrganization, string>({
      query: organizationId => `organizations/agencies/${organizationId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'AgencyOrganization', id: organizationId }]
    }),
    setAgencyDetails: build.mutation<AgencyOrganization, { organizationId: string; agencyDetails: AgencyDetails }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/setAgencyDetails`,
          method: 'POST',
          body: query.agencyDetails
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', id: organizationId }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: organizationId }, { type: 'AgencyOrganizations' }, { type: 'AgencyOrganization', id: organizationId }]
    }),
    revertAgency: build.mutation<AgencyOrganization, { organizationId: string }>({
      query: query => {
        return {
          url: `organizations/${query.organizationId}/revertAgency`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'Organization', id: organizationId }, { type: 'Agencies' }, { type: 'OrganizationAgencyDetails', id: organizationId }, { type: 'AgencyOrganizations' }, { type: 'AgencyOrganization', id: organizationId }]
    }),
    createOrganization: build.mutation<Organization, CreateOrganization>({
      query: request => {
        return {
          url: `organizations`,
          method: 'POST',
          body: {
            email: request.email,
            company: request.company,
            phone: request.phone,
            firstName: request.firstName,
            lastName: request.lastName,
            signupMethod: request.signupMethod
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'Agencies' }, { type: 'AgencyOrganizations' }, { type: 'Organizations' }]
    }),
    getOrganizationAgencyDetails: build.query<AgencyDetails, string>({
      query: id => `organizations/${id}/getAgencyDetails`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'OrganizationAgencyDetails', id: id }]
    }),
    uploadOrganizationToPipedrive: build.mutation<boolean, { organizationId: string }>({
      query: request => {
        return {
          url: `organizations/${request.organizationId}/pushPipedrive`,
          method: 'POST',
          body: {}
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: () => []
    })
  }),
  overrideExisting: false
})

export const {
  useGetOrganizationsQuery,
  useGetOrganizationSpendDataQuery,
  useOrganizationCancelSubscriptionMutation,
  useGetOrganizationQuery,
  useGetOrganizationsByAdvisorQuery,
  useGetCampaignsWithoutBundlesForOrganizationQuery,
  usePostOrganizationProposalSendMutation,
  useLazyGetProposalViewsEstimateQuery,
  useGetOrganizationProposalQuery,
  usePostOrganizationProposalMutation,
  useSetOrganizationPermissionsMutation,
  useGetOrganizationProposalsQuery,
  useSetOrganizationProposalContactedMutation,
  useSetOrganizationSalesStageMutation,
  useGetAgencyOrganizationsQuery,
  useGetAgencyOrganizationQuery,
  useSetAgencyDetailsMutation,
  useRevertAgencyMutation,
  useGetOrganizationAgencyDetailsQuery,
  useSetOrganizationIncludedCampaignsMutation,
  useCreateOrganizationMutation,
  useUploadOrganizationToPipedriveMutation
} = organizationsApi
