import { Note } from 'api/entities/Notes'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'
import { AdAddNoteRequest, BundleAddNoteRequest, CampaignAddNoteRequest, ContactAddNoteRequest, DealAddNoteRequest, OrganizationAddNoteRequest, SalesAgencyDetailsAddNoteRequest, UpdateNoteContentRequest } from 'api/rtkQueryApi/requestModels/NoteModels'

const noteTag = opsApi.enhanceEndpoints({ addTagTypes: ['Notes', 'NotesByRef', 'RelatedNotesByRef', 'Note'] })

const noteApi = noteTag.injectEndpoints({
  endpoints: build => ({
    getNotes: build.query<Note[], void>({
      query: () => 'notes',
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'Notes' }]
    }),
    getNotesForId: build.query<Note[], string>({
      query: refId => `notes?primaryReferenceId=${refId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, refId) => [{ type: 'NotesByRef', id: refId }]
    }),
    getRelatedNotesForId: build.query<Note[], string>({
      query: refId => `notes?relatedReferenceId=${refId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, refId) => [{ type: 'RelatedNotesByRef', id: refId }]
    }),
    getNoteById: build.query<Note, string>({
      query: id => `notes/${id}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'Note', id: id }]
    }),
    updateNoteContent: build.mutation<Note, UpdateNoteContentRequest>({
      query: (note: UpdateNoteContentRequest) => ({
        url: `notes/${note.noteId}/content`,
        method: 'PUT',
        body: {
          name: note.name,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef' }, { type: 'RelatedNotesByRef' }, { type: 'Note', id: note.noteId }]
    }),
    deleteNote: build.mutation<void, string>({
      query: id => ({
        url: `notes/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Notes' }, { type: 'NotesByRef' }, { type: 'RelatedNotesByRef' }, { type: 'Note', id: id }]
    }),
    contactAddNote: build.mutation<Note, ContactAddNoteRequest>({
      query: (note: ContactAddNoteRequest) => ({
        url: `contacts/${note.contactId}/addNote`,
        method: 'POST',
        body: {
          name: note.name,
          secondaryReferenceIds: note.secondaryReferenceIds,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.contactId }, { type: 'RelatedNotesByRef', id: note.contactId }]
    }),
    dealAddNote: build.mutation<Note, DealAddNoteRequest>({
      query: (note: DealAddNoteRequest) => ({
        url: `deals/${note.dealId}/addNote`,
        method: 'POST',
        body: {
          name: note.name,
          secondaryReferenceIds: note.secondaryReferenceIds,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.dealId }, { type: 'RelatedNotesByRef', id: note.dealId }]
    }),
    campaignAddNote: build.mutation<Note, CampaignAddNoteRequest>({
      query: (note: CampaignAddNoteRequest) => ({
        url: `campaigns/${note.campaignId}/addNote`,
        method: 'POST',
        body: {
          name: note.name,
          secondaryReferenceIds: note.secondaryReferenceIds,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.campaignId }, { type: 'RelatedNotesByRef', id: note.campaignId }]
    }),
    adAddNote: build.mutation<Note, AdAddNoteRequest>({
      query: (note: AdAddNoteRequest) => ({
        url: `ads/${note.adId}/addNote`,
        method: 'POST',
        body: {
          name: note.name,
          secondaryReferenceIds: note.secondaryReferenceIds,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.adId }, { type: 'RelatedNotesByRef', id: note.adId }]
    }),
    organizationAddNote: build.mutation<Note, OrganizationAddNoteRequest>({
      query: (note: OrganizationAddNoteRequest) => ({
        url: `organizations/${note.organizationId}/addNote`,
        method: 'POST',
        body: {
          name: note.name,
          secondaryReferenceIds: note.secondaryReferenceIds,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.organizationId }, { type: 'RelatedNotesByRef', id: note.organizationId }]
    }),
    bundleAddNote: build.mutation<Note, BundleAddNoteRequest>({
      query: (note: BundleAddNoteRequest) => ({
        url: `bundles/${note.organizationId}/${note.bundleId}/addNote`,
        method: 'POST',
        body: {
          name: note.name,
          secondaryReferenceIds: note.secondaryReferenceIds,
          text: note.text,
          followUp: note.followUp,
          followUpDate: note.followUpDate
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.bundleId }, { type: 'RelatedNotesByRef', id: note.bundleId }]
    }),
    salesAgencyDetailsAddNote: build.mutation<Note, SalesAgencyDetailsAddNoteRequest>({
      query: (note: SalesAgencyDetailsAddNoteRequest) => ({
        url: `salesAgencyDetails/${note.salesAgencyDetailsId}/addNote`,
        method: 'POST',
        body: note
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, note) => [{ type: 'Notes' }, { type: 'NotesByRef', id: note.salesAgencyDetailsId }, { type: 'RelatedNotesByRef', id: note.salesAgencyDetailsId }]
    })
  }),
  overrideExisting: false
})

export const { useGetNoteByIdQuery, useGetNotesQuery, useContactAddNoteMutation, useDealAddNoteMutation, useCampaignAddNoteMutation, useAdAddNoteMutation, useDeleteNoteMutation, useGetRelatedNotesForIdQuery, useGetNotesForIdQuery, useLazyGetNotesForIdQuery, useUpdateNoteContentMutation, useOrganizationAddNoteMutation, useBundleAddNoteMutation, useSalesAgencyDetailsAddNoteMutation } = noteApi
