import React from 'react'

import { EuiButton, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiPanel, EuiText } from '@elastic/eui'

export interface IImportDomainsModal {
  onClose: () => void
  onConfirmImport: (domains: string[]) => void
}
export const ImportDomainsModal: React.FC<IImportDomainsModal> = props => {
  const [importedDomains, setImportedDomains] = React.useState<string[]>([])
  const [isUploading, setIsUploading] = React.useState(false)
  const onModalClose = () => {
    props.onClose()
  }

  const onConfirmImport = () => {
    props.onConfirmImport(importedDomains)
  }

  const onDomainCsvUploaded = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      setIsUploading(true)
      reader.onload = e => {
        let csv = e.target?.result as string
        csv = csv.replaceAll(/\n/g, ',')
        csv = csv.replaceAll(/\s/g, ',')
        const uniqueDomains = csv
          .split(',')
          .filter((v, i, a) => a.indexOf(v) === i && v.length > 0)
          .map(domain => (!domain.startsWith('https://') ? `https://${domain}` : domain))
        setImportedDomains(uniqueDomains)
        setIsUploading(false)
      }
      reader.readAsText(file)
    } else {
      setImportedDomains([])
    }
  }

  return (
    <EuiModal onClose={onModalClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Import Domain List</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow>
          <EuiFilePicker id={'filePicker'} initialPromptText='Select or drag and drop file' onChange={onDomainCsvUploaded} display={'default'} aria-label='file picker' isLoading={isUploading} />
        </EuiFormRow>
        {importedDomains.length > 0 && (
          <React.Fragment>
            <EuiText size={'xs'}>Imported {importedDomains.length} domains</EuiText>
            <EuiPanel hasShadow={false} hasBorder={false}>
              {importedDomains.slice(0, 5).map((domain, index) => (
                <EuiText key={index} size={'xs'}>
                  {domain}
                </EuiText>
              ))}
            </EuiPanel>
          </React.Fragment>
        )}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup justifyContent={'flexEnd'}>
          <EuiFlexItem grow={false}>
            <EuiButton color={'primary'} fill isDisabled={importedDomains.length <= 0} onClick={onConfirmImport}>
              Confirm
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}
