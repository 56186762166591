import {useGetCampaignQuery} from "api/rtkQueryApi/opsApi/campaignsApi";
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSelect,
  EuiSpacer,
  EuiTitle
} from "@elastic/eui";
import React, {useEffect} from "react";
import ReactJson, {ThemeKeys} from "react-json-view";

export interface ICampaignDocumentViewer {
  campaignId: string
}

export const CampaignDocumentViewer : React.FC<ICampaignDocumentViewer> = props => {
  const campaignQuery = useGetCampaignQuery(props.campaignId)
  const [selectedTheme, setSelectedTheme] = React.useState(localStorage.getItem("doc_viewer") ?? 'monokai')

  useEffect(() => {
    localStorage.setItem("doc_viewer", selectedTheme);
  }, [selectedTheme]);

  const themes = [{
    value: "apathy",
    label: "apathy"
  }, {
    value: "apathy:inverted",
    label: "apathy:inverted"
  }, {
    value: "ashes",
    label: "ashes"
  }, {
    value: "bespin",
    label: "bespin"
  }, {
    value: "brewer",
    label: "brewer"
  }, {
    value: "bright:inverted",
    label: "bright:inverted"
  }, {
    value: "bright",
    label: "bright"
  }, {
    value: "chalk",
    label: "chalk"
  }, {
    value: "codeschool",
    label: "codeschool"
  }, {
    value: "colors",
    label: "colors"
  }, {
    value: "eighties",
    label: "eighties"
  }, {
    value: "embers",
    label: "embers"
  }, {
    value: "flat",
    label: "flat"
  }, {
    value: "google",
    label: "google"
  }, {
    value: "grayscale",
    label: "grayscale"
  }, {
    value: "grayscale:inverted",
    label: "grayscale:inverted"
  }, {
    value: "greenscreen",
    label: "greenscreen"
  }, {
    value: "harmonic",
    label: "harmonic"
  }, {
    value: "hopscotch",
    label: "hopscotch"
  }, {
    value: "isotope",
    label: "isotope"
  }, {
    value: "marrakesh",
    label: "marrakesh"
  }, {
    value: "mocha",
    label: "mocha"
  }, {
    value: "monokai",
    label: "monokai"
  }, {
    value: "ocean",
    label: "ocean"
  }, {
    value: "paraiso",
    label: "paraiso"
  }, {
    value: "pop",
    label: "pop"
  }, {
    value: "railscasts",
    label: "railscasts"
  }, {
    value: "rjv-default",
    label: "rjv-default"
  }, {
    value: "shapeshifter",
    label: "shapeshifter"
  }, {
    value: "shapeshifter:inverted",
    label: "shapeshifter:inverted"
  }, {
    value: "solarized",
    label: "solarized"
  }, {
    value: "summerfruit",
    label: "summerfruit"
  }, {
    value: "summerfruit:inverted",
    label: "summerfruit:inverted"
  }, {
    value: "threezerotwofour",
    label: "threezerotwofour"
  }, {
    value: "tomorrow",
    label: "tomorrow"
  }, {
    value: "tube",
    label: "tube"
  }, {
    value: "twilight",
    label: "twilight"
  }]

  const exportAsJsonClick = () => {
    if (!campaignQuery.data) {
      return
    }
    const campaign = campaignQuery.data
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(campaignQuery.data)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = `${campaign.campaignName}_${campaign.campaignId.slice(0, 8)}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  return (
    <React.Fragment>
      <EuiTitle>
        <h2>Document View  <EuiIcon type={'magnifyWithExclamation'}/></h2>
      </EuiTitle>
      <EuiSpacer/>
      <EuiFormRow label={"Theme"}>
        <EuiSelect
          options={themes.map(t => ({value: t.value, text: t.label}))}
          value={selectedTheme}
          onChange={e => setSelectedTheme(e.target.value)}
        />
      </EuiFormRow>
      <EuiSpacer/>
      <EuiButtonEmpty iconType={'download'} onClick={exportAsJsonClick}>Export as JSON</EuiButtonEmpty>
      <EuiFlexGroup>
        <EuiFlexItem>
          <ReactJson theme={selectedTheme as ThemeKeys} src={campaignQuery.data ?? {}} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )

}