import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiPortal, EuiSpacer, EuiSuperSelect, EuiTitle } from '@elastic/eui'
import { EuiSuperSelectOption } from '@elastic/eui/src/components/form/super_select/super_select_control'

import { BusinessTypeCategory, useOpsClient } from 'api'
import history from 'services/HistoryService'

interface FormValues {
  name: string
}

const BusinessTypeCategoryCreateFlyOut: React.FC = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const [isLoadingBusinessTypeCategories, setIsLoadingBusinessTypeCategories] = useState(false)
  const [businessTypeCategories, setBusinessTypeCategories] = useState<BusinessTypeCategory[]>([])
  const [categories, setCategories] = useState<EuiSuperSelectOption<string>[]>([])
  const [chosenCategory, setChosenCategory] = useState<string>('')
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      setIsLoadingBusinessTypeCategories(true)
      opsClient.getBusinessTypeCategories().then(result => {
        setBusinessTypeCategories(result)
        setIsLoadingBusinessTypeCategories(false)
      })
    }
  }, [opsClient])

  useEffect(() => {
    if (businessTypeCategories) {
      const p = businessTypeCategories.map(
        btcg =>
          ({
            value: btcg.id,
            inputDisplay: btcg.name
          } as EuiSuperSelectOption<string>)
      )
      setCategories(p)
    }
  }, [businessTypeCategories])

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const closeFlyout = () => setIsFlyoutVisible(false)

  const businessTypeSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the business type name')
  })

  const selectCategory = (categoryId: string) => {
    setChosenCategory(categoryId)
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.createBusinessType({ name: values.name, categoryId: chosenCategory }).then(result => {
      setSubmitting(false)
      setIsFlyoutVisible(false)
      history.push(`/data/businessTypes/${result.id}`)
    })
  }
  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={{ name: '' }} validationSchema={businessTypeSchema} onSubmit={doSubmit}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Business Type</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Category'>
                    <EuiSuperSelect options={categories} onChange={selectCategory} valueOfSelected={chosenCategory} isLoading={isLoadingBusinessTypeCategories} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiButton id='createBusinessTypeCategoryFlyOut' onClick={showFlyout} fill>
        Create
      </EuiButton>
      {flyout}
    </React.Fragment>
  )
}

export default BusinessTypeCategoryCreateFlyOut
