import { Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiSwitch, EuiText } from '@elastic/eui'

import { User, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface UserDetailsTabParams {
  userId: string
}

interface FormValues {
  isAdmin: boolean
  isStaff: boolean
  isBlocked: boolean
}

const UserDetailsTab: React.FC<UserDetailsTabParams> = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState<User>()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [initial, setInitial] = useState<FormValues>({
    isAdmin: false,
    isBlocked: false,
    isStaff: false
  })
  const opsClient = useOpsClient()

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getUser(userId).then(result => {
        setInitial({
          isAdmin: result.isAdmin,
          isStaff: result.isStaff,
          isBlocked: result.isBlocked
        })
        setUser(result)
        setIsLoading(false)
      })
    }
  }, [opsClient, userId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const userSchema = Yup.object().shape({
    isAdmin: Yup.boolean(),
    isStaff: Yup.boolean(),
    isBlocked: Yup.boolean()
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.updateUser(userId, values).then(result => {
      setSubmitting(false)
      history.push(`/customers/users/${userId}`)
    })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {user && (
        <Formik initialValues={initial} enableReinitialize validationSchema={userSchema} onSubmit={doSubmit}>
          {props => (
            <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
              <EuiFormRow label='Email'>
                <EuiText size='s'>
                  <p>{user.email}</p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label='First Name'>
                <EuiText size='s'>
                  <p>{user.firstName}</p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label='Last Name'>
                <EuiText size='s'>
                  <p>{user.lastName}</p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label='Last Login'>
                <EuiText size='s'>
                  <p>{moment(user.lastLogin).format('MMM Do YYYY HH:mm:ss')}</p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label='Created'>
                <EuiText size='s'>
                  <p>{moment(user.created).format('MMM Do YYYY HH:mm:ss')}</p>
                </EuiText>
              </EuiFormRow>
              <EuiSpacer />
              <EuiSwitch onChange={e => props.setFieldValue('isAdmin', e.target.checked)} checked={props.values.isAdmin} label='Is Administrator' />
              <EuiSpacer size='s' />
              <EuiSwitch onChange={e => props.setFieldValue('isStaff', e.target.checked)} checked={props.values.isStaff} label='Is Staff' />
              <EuiSpacer size='s' />
              <EuiSwitch onChange={e => props.setFieldValue('isBlocked', e.target.checked)} checked={props.values.isBlocked} label='Is Blocked' />
              <EuiSpacer />
              <EuiButton isLoading={props.isSubmitting} isDisabled={!canUpdate} fill type='submit'>
                Save
              </EuiButton>
            </EuiForm>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}

export default UserDetailsTab
