import {EuiBadge, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiSpacer, EuiTitle} from "@elastic/eui";
import React from "react";
import {INavigationItem, NavigationItem} from "components/Header/NavigationPages/NavigationItem";
import {insertSpaces} from "utils/EnumToFriendly";
import history from "services/HistoryService";
import CampaignDetailsTab from "features/customers/campaigns/CampaignDetailsTab";
import AdvancedLocationTargetingTab from "features/customers/campaigns/targeting/AdvancedLocationTargetingTab";
import AdvancedAudienceTargetingTab from "features/customers/campaigns/targeting/AdvancedAudienceTargetingTab";
import AdvancedSearchTargetingTab from "features/customers/campaigns/targeting/AdvancedSearchTargetingTab";
import BillboardTargetingTab from "features/customers/campaigns/targeting/BillboardTargetingTab";
import TvTargetingTab from "features/customers/campaigns/targeting/TvTargetingTab";
import SmartTargetingTab from "features/customers/campaigns/targeting/SmartTargetingTab";
import CampaignAdsTab from "features/customers/campaigns/CampaignAdsTab";
import CampaignBidderTab from "features/customers/campaigns/CampaignBidderTab";
import CampaignBidderXTab from "features/customers/campaigns/CampaignBidderXTab";
import CampaignHistoryTab from "features/customers/campaigns/CampaignHistoryTab";
import {CampaignMigrationTab} from "features/customers/campaigns/CampaignMigrationTab";
import CampaignTrafficRawTab from "features/customers/campaigns/CampaignTrafficRawTab";
import {CampaignTrafficDimensionedTab} from "features/customers/campaigns/CampaignTrafficTab";
import {CampaignTransactionsTab} from "features/customers/campaigns/CampaignTransactionsTab";
import CampaignNotesTab from "features/customers/campaigns/CampaignNotesTab";
import {ModernTargetingTab} from "features/customers/campaigns/targeting/ModernTargetingTab";
import {CampaignRetargetingTab} from "features/customers/campaigns/CampaignRetargetingTab";
import {CampaignDocumentViewer} from "features/customers/campaigns/CampaignDocumentViewer";

export interface INavigationPage {
  title: string
  navigationItems: INavigationItem[]
}

export const NavigationPage: React.FC<INavigationPage> = (props) => {
  return (
    <React.Fragment>
      <EuiPageTemplate>
        <EuiPageTemplate.Header
          pageTitle={
            <EuiTitle>
              <h1>{props.title}</h1>
            </EuiTitle>
          }
          restrictWidth={false}
        />
        <EuiPageTemplate.Section restrictWidth={false}>
          <EuiFlexGroup wrap>
            {props.navigationItems.map((item, index) => {
              return (
                <EuiFlexItem key={index} grow={false} style={{minWidth: 244}}>
                  <NavigationItem
                    key={index}
                    title={item.title}
                    route={item.route}
                    icon={item.icon}
                  />
                </EuiFlexItem>
              )
            })}
          </EuiFlexGroup>
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    </React.Fragment>
  )
}