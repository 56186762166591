import moment from 'moment'

import { BundleType, ScheduleEvent } from 'api/entities/Bundle'
import { CampaignInternalStatus } from 'api/rtkQueryApi/requestModels/CampaignSetInternalStatusModel'

import { Address, Competitor } from './Address'
import { ThirdPartyAudience } from './Audiences'
import { Change } from './Change'
import { City, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, Zip } from './Geo'

export interface Campaign {
  campaignId: string
  campaignType: string
  campaignSubType: CampaignSubType
  accountId: string
  organizationId: string
  businessTypeId: string
  creativeIds: Array<string>
  businessTypeName: string
  bundleId: string
  bundleType?: BundleType
  isMatching: boolean
  campaignName: string
  accountName: string
  organizationName: string
  targetingTemplate: string
  advancedTargeting: AdvancedTargeting | null
  tvTargeting: TvTargeting
  smartTargeting: SmartTargeting | null
  billboardTargeting: BillboardTargeting | null
  budget: number
  budgetFrequency: string
  isActivated: boolean
  activated: moment.Moment | null
  billingAnniversary: moment.Moment | null
  lastBilled: moment.Moment | null
  firstViewDate: moment.Moment | null
  status: CampaignStatus
  activeStatus: CampaignActiveStatus
  updated: moment.Moment
  placement: string
  changes: Array<Change>
  created: moment.Moment
  campaignAdvisorId: string | null
  mostRecentPausedDate: moment.Moment | null
  endDate?: moment.Moment
  startDate?: moment.Moment
  campaignContactInfo: CampaignContactInfo
  uploadedAudienceUrl: string | null
  lastCampaignAdvisorReviewedDate: moment.Moment | null
  performanceStatuses: string[]
  totalAudienceSize: number | null
  internalStatus?: CampaignInternalStatus
  needsAudienceUpload: boolean
  endingOnDate: moment.Moment | null
  isWatchListed: boolean
  dsp: 'Yahoo' | 'Xandr'
  flightSchedule: ScheduleEvent
  audience: Audience
  bidStrategy?: BidStrategy
}

export enum CampaignSubType {
  Default = 'Default',
  NativeOrDisplay = 'NativeOrDisplay',
  Native = 'Native',
  Display = 'Display',
  TV = 'TV',
  Billboard = 'Billboard',
  Audio = 'Audio'
}

export interface BidStrategy {
  optimizationGoal?: number
  optimizationType: OptimizationType
}

export enum OptimizationType {
  Unset = 'Unset',
  Cpm = 'Cpm',
  Cpc = 'Cpc'
}

export interface Audience {
  segmentLogic: SegmentLogic
  curatedAudienceIds: string[]
}

export enum CampaignStatus {
  NoApprovedAds = 'NoApprovedAds',
  EndingOn = 'EndingOn',
  PaymentFailed = 'PaymentFailed',
  SavedForLater = 'SavedForLater',
  CampaignEnded = 'CampaignEnded',
  PendingAdApproval = 'PendingAdApproval',
  Archived = 'Archived',
  Scheduled = 'Scheduled',
  PendingNetworkApproval = 'PendingNetworkApproval',
  Live = 'Live',
  Deleted = 'Deleted',
  WaitingForCustomAds = 'WaitingForCustomAds',
  WaitingForTVOutro = 'WaitingForTVOutro'
}

export enum CampaignActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export interface AdvancedTargeting {
  advancedTargetingType: string
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  geoFences: Array<GeoFence>
  geoCircles: Array<GeoCircle>
  geoRectangles: Array<GeoRectangle>
  geoPolygons: Array<GeoPolygon>
  audienceType: string | null
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  uploadType: string | null
  uploadedAudienceUrl: string | null
  uploadedData: Array<string>
  retargetingType: string | null
  urlParts: Array<string>
  retargetingCampaignIds?: string[]
  keywords: Array<string>
  negativeKeywords: Array<string>
  inventorySettings: InventorySettings | null
  deviceSettings: DeviceSettings | null
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
  locations?: LocationTarget
  segments?: SegmentTarget[]
}

export interface UpdateAdvancedTargeting {
  advancedTargetingType: string
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<string>
  zips: Array<string>
  geoFences: Array<GeoFence>
  geoCircles: Array<GeoCircle>
  geoRectangles: Array<GeoRectangle>
  geoPolygons: Array<GeoPolygon>
  audienceType: string | null
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  uploadType: string | null
  uploadedData: Array<string>
  retargetingType: string | null
  urlParts: Array<string>
  retargetingCampaignIds?: string[]
  keywords: Array<string>
  negativeKeywords: Array<string>
  inventorySettings: InventorySettings | null
  deviceSettings: DeviceSettings | null
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
}

export interface DayPartitioning {
  sunday: number[]
  monday: number[]
  tuesday: number[]
  wednesday: number[]
  thursday: number[]
  friday: number[]
  saturday: number[]
}

export interface InventorySettings {
  targetWeb: boolean
  targetApp: boolean
}

export interface DeviceSettings {
  targetPc: boolean
  targetPhone: boolean
  targetTablet: boolean
}

export enum FrequencyUnit {
  hour = 0,
  day = 1,
  week = 2
}

export interface IXandrTargeting {
  locationTarget: LocationTarget
  segmentTargets: SegmentTarget[]
}

export interface TvTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  frequency: number | null
  frequencyUnit: FrequencyUnit | null
  dayParts: DayPartitioning | null
  uploadedAudienceUrl?: string
  locations?: LocationTarget
  segments?: SegmentTarget[]
}

export interface Data {
  data: string
}

export interface UpdateCampaign {
  campaignName: string
  budget: number
  campaignAdvisorId: string | null
  totalAudienceSize: number | null
  isWatchListed: boolean
}

export interface ExcludedAudience {
  audienceId: number
  audienceName: string
  isExcluded: boolean
}

export interface ExcludedGeo {
  geoId: number
  isExcluded: boolean
}

export interface CampaignContactInfo {
  name: string
  email: string
  phone: string
}

export interface UpdateTvTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cityIds: Array<string>
  zips: Array<string>
  audiences: Array<ThirdPartyAudience>
  ageRanges: Array<string>
  genders: Array<string>
  householdIncomes: Array<string>
  uploadedAudienceUrl?: string
}

export interface SmartTargeting {
  targetArea?: SmartTargeterAreaType
  streetAddress: string
  city: string
  state: string
  zip: string
  walkInTargeting: boolean
  walkInUsePrimary: boolean
  walkInAddresses: Array<Address>
  competitorTargeting: boolean
  competitors: Array<Competitor>
  locations?: LocationTarget
}

export enum SmartTargeterAreaType {
  Mile5 = '5',
  Mile20 = '20',
  Mile50 = '50',
  State = 'State',
  Country = 'Country'
}

export interface BillboardTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  locations?: LocationTarget
}

export interface IDemographics {
  ageRanges: string[]
  genders: string[]
  householdIncomes: string[]
}

export interface LocationTarget {
  countries: LocationCountry[]
  regions: LocationRegion[]
  dmas: LocationDma[]
  cities: LocationCity[]
  postalCodes: LocationPostalCode[]
  uploadedAudienceUrl?: string
}

export interface GeoLocationTarget extends LocationTarget {
  geoCircles?: GeoCircle[]
  geoRectangles?: GeoRectangle[]
  geoPolygons?: GeoPolygon[]
  geoFences?: GeoFence[]
}

export interface LocationCountry {
  id: number
  code: string
  name: string
}

export interface LocationRegion {
  id: number
  code: string
  name: string
}

export interface LocationDma {
  id: number
  name: string
}

export interface LocationCity {
  id: number
  name: string
  regionCode: string
}

export interface AddressAutoComplete {
  placeId: string
  description: string
}

export interface LocationGeoFence {
  placeId: string
  description: string
}

export interface LocationPostalCode {
  id: number
  code: string
  name: string
}

export interface PostalCodeList {
  id: number
  count: number
}

export interface UpdateBillboardTargeting {
  locationType: string | null
  states: Array<string>
  dmas: Array<string>
  cities: Array<string>
  zips: Array<string>
}

export interface CampaignByDayAndDimension {
  forDate: Date
  dimensionValue: string
  clicks: number
  impressions: number
  spend: number
}

export interface CampaignBidderX {
  bidderCampaignId?: number
  targetedSegments: SegmentTarget[]
  additionalSegments: SegmentTarget[]
  hiddenSegments: SegmentTarget[]
  excludedSegmentIds: number[]
  targetedRegions: LocationRegion[]
  additionalRegions: LocationRegion[]
  hiddenRegions: LocationRegion[]
  excludedRegionIds: number[]
  targetedDmas: LocationDma[]
  additionalDmas: LocationDma[]
  hiddenDmas: LocationDma[]
  excludedDmaIds: number[]
  targetedCities: LocationCity[]
  additionalCities: LocationCity[]
  hiddenCities: LocationCity[]
  excludedCityIds: number[]
  targetedPostalCodes: LocationPostalCode[]
  additionalPostalCodes: LocationPostalCode[]
  hiddenPostalCodes: LocationPostalCode[]
  excludedPostalCodeIds: number[]
  postalCodeLists: PostalCodeList[]
  mapPostalCodeList?: PostalCodeList
  status: BidderXStatus
  campaignCreativesAuditStatus: BidderXCampaignCreativesAuditStatus
  lastError: string
  pacing: CampaignBidderPacing
  start?: string
  end?: string
}

export interface CampaignBidder {
  bidderCampaignId?: number
  targetedSegments: ThirdPartyAudience[]
  additionalSegments: ThirdPartyAudience[]
  hiddenSegments: ThirdPartyAudience[]
  excludedSegmentIds: number[]
  bidderSegments: string[]
  targetedStates: string[]
  additionalStates: string[]
  hiddenStates: string[]
  excludedStates: string[]
  targetedDmas: string[]
  additionalDmas: string[]
  hiddenDmas: string[]
  excludedDmas: string[]
  targetedCities: City[]
  additionalCities: City[]
  hiddenCities: City[]
  excludedCityIds: string[]
  targetedZips: Zip[]
  additionalZips: Zip[]
  hiddenZips: Zip[]
  excludedZips: string[]
  bidderLocations: string[]
  bidderDeals: string[]
  onlyTargetBidderLocations: boolean
  onlyTargetBidderSegments: boolean
  onlyTargetBidderDeals: boolean
  status: string
  lastError: string
  pacing: CampaignBidderPacing
  creatives: CreativeBidder[]
  start?: string
  end?: string
  boost?: number
}

export interface SegmentTarget {
  id: number
  name: string
  description?: string
  hierarchy: string[]
}

export interface SegmentLogic {
  segmentIds: number[]
  logicGroups: SegmentLogic[]
  operator: SegmentOperator
  isNot: boolean
}

export enum SegmentOperator {
  And = 'And',
  Or = 'Or'
}

export interface CampaignBidderPacing {
  flightBudget: number
  flightBoost?: number
  flightPacing: number
  flightImpressions: number
  flightClicks: number
  flightSpend: number
  flightTotalDays: number
  flightCurrentDay: number
  flightDayProgress: number
  flightSpendProgress: number
  flightCpm: number
  flightCpc: number
  flightStart: moment.Moment
  flightEnd: moment.Moment
  lifetimeBudget: number
  lifetimePacing: number
  lifetimeImpressions: number
  lifetimeClicks: number
  lifetimeSpend: number
  lifetimeTotalDays: number
  lifetimeCurrentDay: number
  lifetimeDayProgress: number
  lifetimeSpendProgress: number
  lifetimeCpm: number
  lifetimeCpc: number
  lifetimeStart: moment.Moment
  lifetimeEnd: moment.Moment
  todaysImpressions: number
  todaysClicks: number
  todaysSpend: number
  todaysCpm: number
  todaysCpc: number
  boost?: number
}

export interface CreativeBidder {
  bidderCreativeId?: number
  status: string
  lastError: string
}

export interface UpdateCampaignBidder {
  additionalSegmentIds: number[]
  hiddenSegmentIds: number[]
  excludedSegmentIds: number[]
  additionalStates: string[]
  hiddenStates: string[]
  excludedStates: string[]
  additionalDmas: string[]
  hiddenDmas: string[]
  excludedDmas: string[]
  additionalCityIds: string[]
  hiddenCityIds: string[]
  excludedCityIds: string[]
  additionalZips: string[]
  hiddenZips: string[]
  excludedZips: string[]
  onlyTargetBidderLocations: boolean
  onlyTargetBidderSegments: boolean
  onlyTargetBidderDeals: boolean
}

export interface UpdateCampaignBidderX {
  additionalSegmentIds: number[]
  hiddenSegmentIds: number[]
  excludedSegmentIds: number[]
  additionalRegionIds: number[]
  hiddenRegionIds: number[]
  excludedRegionIds: number[]
  additionalDmaIds: number[]
  hiddenDmaIds: number[]
  excludedDmaIds: number[]
  additionalCityIds: number[]
  hiddenCityIds: number[]
  excludedCityIds: number[]
  additionalPostalCodeIds: number[]
  hiddenPostalCodeIds: number[]
  excludedPostalCodeIds: number[]
}

export interface CampaignByDayAndDimensionReport {
  forDate: Date
  dimensionValue: string
  clicks: number
  impressions: number
  spend: number
}

export interface CampaignByDayAndDimensionReportRequest {
  campaignId: string
  dimension: string
  startDate: Date
  endDate: Date
}

export interface CampaignTrafficRequest {
  campaignId: string
  startDate: Date
  endDate: Date
}

export interface CampaignTraffic {
  forDate: Date
  impressions: number
  clicks: number
  conversions: number
  spend: number
  uniqueViewers: number
  averageFrequency: number
}

export enum CampaignType {
  Internet = 'Internet',
  TV = 'TV',
  Billboard = 'Billboard'
}

export interface CampaignMigrationResponse {
  campaign: Campaign
  migratedCities: string[]
  migratedDmas: string[]
  migratedStateCodes: string[]
  migratedZipIds: string[]
  migratedAudiences: string[]
  unmappedCities: string[]
  unmappedDmas: string[]
  unmappedStateCodes: string[]
  unmappedZipIds: string[]
  unmappedAudiences: string[]
}

export enum BidderXStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Unknown = 'Unknown',
  Banned = 'Banned',
  PartiallyInactive = 'PartiallyInactive',
  CreativeNotPushed = 'CreativeNotPushed',
  CreativeNotAttached = 'CreativeNotAttached'
}

export enum BidderXCampaignCreativesAuditStatus {
  Ok = 'Ok',
  OneOrMoreCreativesRejected = 'OneOrMoreCreativesRejected',
  OneOrMoreCreativesPending = 'OneOrMoreCreativesPending'
}
