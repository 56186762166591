import React, { createContext, useContext } from 'react'

import { GraphClient } from './GraphClient'

const GraphClientContext = createContext<GraphClient | undefined>(undefined)

export const useGraphClient = () => useContext(GraphClientContext)

interface Props {
  children: any
}

export const GraphClientProvider: React.FC<Props> = ({ children }) => {
  const graphClient = new GraphClient()

  return <GraphClientContext.Provider value={graphClient}>{children}</GraphClientContext.Provider>
}
