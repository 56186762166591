import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiTitle } from '@elastic/eui'

import { BundleCategory, IntervalUnit, TargetingPriority } from 'api/entities/Bundle'
import { SelfServicePackageStatus, SelfServicePackageType } from 'api/entities/SelfServicePackage'
import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'
import { useArchiveSelfServicePackageTemplateMutation, useGetSelfServicePackageTemplateQuery, useUpdateSelfServicePackageTemplateMutation } from 'api/rtkQueryApi/opsApi/bundleTemplatesApi'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { SelfServicePackageTemplateForm } from 'features/customers/bundles/SelfServicePackageTemplateForm'
import { useSelfServicePackageIsValid } from 'features/templates/useSelfServicePackageIsValid'

export interface IEditSelfServePackageTemplateFlyout {
  onCloseFlyout: () => void
  onUpdated: () => void
  bundleTemplateId: string
}

export const EditSelfServePackageTemplateFlyout: React.FC<IEditSelfServePackageTemplateFlyout> = props => {
  const [updateTemplate, updateTemplateRequest] = useUpdateSelfServicePackageTemplateMutation()
  const getSelfServicePackageTemplateQuery = useGetSelfServicePackageTemplateQuery({ templateId: props.bundleTemplateId })
  const [bundleTemplate, setBundleTemplate] = useState<SelfServicePackageTemplate>({
    id: '',
    salesTitle: '',
    salesDescription: '',
    salesShortDescription: '',
    proposalText: '',
    selfServicePackageType: SelfServicePackageType.Percent,
    selfServicePackageStatus: SelfServicePackageStatus.Available,
    subscriptionIncentiveDuration: 12,
    matchingIncentiveDuration: 12,
    campaignSlots: [],
    matchingPercentage: 100,
    minimumAmount: 1000,
    maximumAmount: 10000,
    expirationDate: moment().add(1, 'month'),
    includeSubscription: false,
    includeMatching: false,
    intervalUnit: IntervalUnit.Month,
    totalIntervals: 12,
    intervalUnitFrequency: 1,
    foreverBundle: true,
    templateName: '',
    bundleCategory: BundleCategory.SelfService,
    targetingPriority: TargetingPriority.Location
  })
  const [showArchiveConfirm, setShowArchiveConfirm] = useState<boolean>(false)
  const [archiveTemplate] = useArchiveSelfServicePackageTemplateMutation()
  const bundleIsValid = useSelfServicePackageIsValid(bundleTemplate)

  useEffect(() => {
    if (getSelfServicePackageTemplateQuery.data) {
      const template = getSelfServicePackageTemplateQuery.data
      setBundleTemplate({ ...template, expirationDate: template.expirationDate ? moment(template.expirationDate) : undefined })
    }
  }, [getSelfServicePackageTemplateQuery.data])

  const onConfirmArchive = () => {
    archiveTemplate({ templateId: props.bundleTemplateId }).then(() => {
      props.onUpdated?.()
    })
  }
  const onCloseClicked = () => {
    props.onCloseFlyout?.()
  }
  const onSaveClicked = () => {
    if (bundleIsValid && bundleTemplate) {
      updateTemplate({
        templateId: props.bundleTemplateId,
        template: {
          ...bundleTemplate
        }
      }).then(() => {
        props.onUpdated?.()
      })
    }
  }

  const onBundleTemplateChange = (bundleTemplate: SelfServicePackageTemplate) => {
    setBundleTemplate(bundleTemplate)
  }

  if (getSelfServicePackageTemplateQuery.isLoading || !getSelfServicePackageTemplateQuery.data || !bundleTemplate) {
    return <React.Fragment />
  }

  return (
    <EuiFlyout onClose={onCloseClicked} size='m' aria-labelledby='flyoutLargeTitle' className='flyout'>
      <EuiFlyoutHeader>
        <EuiTitle size='s'>
          <h2>Update Bundle Template</h2>
        </EuiTitle>
        <EuiFormRow>
          <EuiButtonEmpty
            color={'danger'}
            iconType={'database'}
            onClick={() => {
              setShowArchiveConfirm(true)
            }}>
            Archive
          </EuiButtonEmpty>
        </EuiFormRow>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <SelfServicePackageTemplateForm bundleTemplate={bundleTemplate} onChange={onBundleTemplateChange} hideTemplateSelect />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='close' onClick={onCloseClicked} color='danger' isDisabled={updateTemplateRequest.isLoading}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={onSaveClicked} disabled={!bundleIsValid} isLoading={updateTemplateRequest.isLoading}>
              Save
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
      {showArchiveConfirm && (
        <AcConfirmModal
          message={'Are you want to archive this template?'}
          onCancel={() => {
            setShowArchiveConfirm(false)
          }}
          onConfirm={onConfirmArchive}
          title={'Archive this Template'}
          confirmButtonText={'Yes, Archive'}
          cancelButtonText={'Cancel'}
        />
      )}
    </EuiFlyout>
  )
}
