import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { EuiBadge, EuiButton, EuiCallOut, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiLink, EuiSpacer, EuiTextArea, EuiTitle } from '@elastic/eui'

import { Anomaly, AnomalyReferenceType, AnomalyStatus } from 'api/entities/Anomaly'
import { useGetBundleForIdQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { RootState } from 'app/rootReducer'
import { AnomalyHistoryChart } from 'components/Anomaly/AnomalyHistoryChart'
import { AnomalyStatusBadge } from 'components/Anomaly/AnomalyStatusBadge'
import { AcDatePickerModal } from 'components/Modals/AcDatePickerModal'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface ICampaignAnomalyDetails {
  anomaly: Anomaly
  onIgnoreClicked?: (ignoreReason: string, ignoreUntil: moment.Moment) => void
  onResolveClicked?: (anomaly: Anomaly, details: string) => void
  onEscalateClicked?: (anomaly: Anomaly, details: string) => void
  onReActivateClicked?: (anomaly: Anomaly, details: string) => void
  isSubmitting?: boolean
  hideOrganization?: boolean
  showStatus?: boolean
}

export const CampaignAnomalyDetails: React.FC<ICampaignAnomalyDetails> = props => {
  const getCampaignQuery = useGetCampaignQuery(props.anomaly.referenceId, { skip: props.anomaly.referenceType !== AnomalyReferenceType.Campaign })
  const getBundleQuery = useGetBundleForIdQuery(props.anomaly.referenceId, { skip: props.anomaly.referenceType !== AnomalyReferenceType.Bundle })
  const anomaly = props.anomaly
  const [showHelp, setShowHelp] = React.useState<boolean>(false)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canIgnore, setCanIgnore] = React.useState<boolean>(false)

  const [details, setDetails] = React.useState<string>('')
  const [showIgnoreModal, setShowIgnoreModal] = React.useState<boolean>(false)
  const [ignoreUntilDate, setIgnoreUntilDate] = React.useState<moment.Moment>(props.anomaly.ignoreUntil ? moment(props.anomaly.ignoreUntil) : moment().add(1, 'days'))

  useEffect(() => {
    if (userProfile) {
      setCanIgnore(userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    setDetails('')
    setShowHelp(false)
  }, [props.anomaly.id])

  useEffect(() => {
    setIgnoreUntilDate(props.anomaly.ignoreUntil ? moment(props.anomaly.ignoreUntil) : moment().add(1, 'days'))
  }, [anomaly])

  useEffect(() => {
    if (details.length >= 1) {
      setShowHelp(false)
    }
  }, [details])

  const onResolveClicked = () => {
    if (details.length >= 1) {
      props.onResolveClicked?.(anomaly, details)
    } else {
      setShowHelp(true)
    }
  }

  const onEscalateClicked = () => {
    if (details.length >= 1) {
      props.onEscalateClicked?.(anomaly, details)
    } else {
      setShowHelp(true)
    }
  }

  const onReactivateClicked = () => {
    if (details.length >= 1) {
      props.onReActivateClicked?.(anomaly, details)
    } else {
      setShowHelp(true)
    }
  }

  const onIgnoredDateChanged = (ignoreUntil: moment.Moment) => {
    setIgnoreUntilDate(ignoreUntil)
  }

  const onIsIgnoredClick = () => {
    if (details.length >= 1) {
      setShowIgnoreModal(true)
    } else {
      setShowHelp(true)
    }
  }

  const onConfirmIgnore = () => {
    props.onIgnoreClicked?.(details, ignoreUntilDate)
    setShowIgnoreModal(false)
  }

  return (
    <React.Fragment>
      {!props.hideOrganization && (
        <React.Fragment>
          <EuiCopy textToCopy={anomaly.organizationId}>
            {copy => (
              <EuiTitle>
                <h2 onClick={copy}>{anomaly.organizationName}</h2>
              </EuiTitle>
            )}
          </EuiCopy>
          <EuiFormRow>
            <EuiLink href={`/customers/organizations/${anomaly.organizationId}`} target={'_blank'}>
              View Organization
            </EuiLink>
          </EuiFormRow>
          <EuiSpacer />
        </React.Fragment>
      )}
      {anomaly.referenceType === AnomalyReferenceType.Campaign && (
        <React.Fragment>
          <EuiCopy textToCopy={getCampaignQuery.data?.campaignId ?? ''}>
            {copy => (
              <EuiTitle>
                <h2 onClick={copy}>{getCampaignQuery.isLoading || getCampaignQuery.isFetching ? '' : getCampaignQuery.data?.campaignName ?? ''}</h2>
              </EuiTitle>
            )}
          </EuiCopy>
          {getCampaignQuery.data?.campaignId && (
            <EuiFormRow>
              <EuiLink href={`/customers/campaigns/${getCampaignQuery.data?.campaignId}`} target={'_blank'}>
                View Campaign
              </EuiLink>
            </EuiFormRow>
          )}
        </React.Fragment>
      )}
      {anomaly.referenceType === AnomalyReferenceType.Bundle && (
        <React.Fragment>
          <EuiCopy textToCopy={getBundleQuery.data?.id ?? ''}>
            {copy => (
              <EuiTitle>
                <h2 onClick={copy}>{getCampaignQuery.isLoading || getCampaignQuery.isFetching ? '' : getBundleQuery.data?.name ?? ''}</h2>
              </EuiTitle>
            )}
          </EuiCopy>
        </React.Fragment>
      )}
      {props.anomaly.status === AnomalyStatus.Escalated && (
        <EuiFormRow fullWidth>
          <EuiFlexGroup wrap>
            <EuiFlexItem grow={false}>
              <EuiBadge color='danger'>Escalated</EuiBadge>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      )}
      <EuiHorizontalRule />
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          {props.showStatus && (
            <EuiFormRow fullWidth>
              <EuiFlexGroup wrap>
                <EuiFlexItem grow={false}>
                  <AnomalyStatusBadge anomaly={anomaly} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormRow>
          )}
          <EuiTitle>
            <h2>{insertSpaces(anomaly.anomalyType)}</h2>
          </EuiTitle>
          <EuiTitle size={'xxs'}>
            <h2>{anomaly.description}</h2>
          </EuiTitle>
          <EuiSpacer />
          {showHelp && (
            <EuiFormRow>
              <EuiCallOut title='Details required' color='danger' iconType='user' />
            </EuiFormRow>
          )}
          <EuiFormRow label={'Details'}>
            <EuiTextArea
              value={details}
              onChange={e => {
                setDetails(e.target.value)
              }}
            />
          </EuiFormRow>
          <EuiSpacer />
          <EuiFlexGroup>
            {(anomaly.status === AnomalyStatus.ActiveIssue || anomaly.status === AnomalyStatus.Escalated || anomaly.status === AnomalyStatus.Ignored) && (
              <React.Fragment>
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={onResolveClicked} isLoading={props.isSubmitting}>
                    Resolve
                  </EuiButton>
                </EuiFlexItem>
              </React.Fragment>
            )}
            {anomaly.status === AnomalyStatus.ActiveIssue && (
              <EuiFlexItem grow={false}>
                <EuiButton onClick={onEscalateClicked} iconType={'warning'} color={'danger'} isLoading={props.isSubmitting}>
                  Escalate
                </EuiButton>
              </EuiFlexItem>
            )}
            {(anomaly.status === AnomalyStatus.Responded || anomaly.status === AnomalyStatus.Resolved || anomaly.status === AnomalyStatus.Ignored) && (
              <EuiFlexItem grow={false}>
                <EuiButton iconType={'warning'} color={'danger'} onClick={onReactivateClicked} isLoading={props.isSubmitting}>
                  Re-Activate
                </EuiButton>
              </EuiFlexItem>
            )}
            {canIgnore && (
              <EuiFlexItem grow={false}>
                <EuiButton id={'ignoreCb'} iconType={'eyeClosed'} color={'warning'} onClick={onIsIgnoredClick} disabled={props.isSubmitting}>
                  {props.anomaly.status === 'Ignored' ? 'Extend Ignore' : 'Ignore'}
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiFormRow fullWidth label={'History'} style={{ maxHeight: 600, overflow: 'auto' }}>
            <AnomalyHistoryChart anomaly={anomaly} />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      {showIgnoreModal && canIgnore && <AcDatePickerModal onConfirm={onConfirmIgnore} onCancel={() => setShowIgnoreModal(false)} title={'Ignore Until'} message={'Anomaly will ReActivate if still active'} confirmButtonText={'Ignore'} minDate={moment().add(1, 'days')} maxDate={moment().add(3, 'months')} onChangeDate={onIgnoredDateChanged} dateValue={ignoreUntilDate} />}
    </React.Fragment>
  )
}
