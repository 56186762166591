import moment from 'moment'
import { Moment } from 'moment/moment'
import React, { useEffect } from 'react'

import { EuiDatePicker, EuiDatePickerRange, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiInMemoryTable, EuiLink, EuiPanel, EuiSpacer, EuiStat, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CommissionEvent, CommissionTriggerType } from 'api/entities/SalesAgent'
import { useGetCommissionEventsForSalesAgentQuery, useGetSalesAgentQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import { CommissionEventsFilterGroup } from 'features/salesManagerDashboard/CommissionEventsFilterGroup'

export interface IAccountManagerSalesPage {
  salesAgentId: string
}

export const AccountManagerSalesPage: React.FC<IAccountManagerSalesPage> = props => {
  const salesAgentQuery = useGetSalesAgentQuery(props.salesAgentId)
  const commissionEventsQuery = useGetCommissionEventsForSalesAgentQuery({ salesAgentId: props.salesAgentId })
  const [filteredCommissionEvents, setFilteredCommissionEvents] = React.useState<CommissionEvent[]>([])
  const [start, setStart] = React.useState<Moment>(moment().startOf('month'))
  const [end, setEnd] = React.useState<Moment>(moment())

  const [activeFilters, setActiveFilters] = React.useState<CommissionTriggerType[]>([])
  const [search, setSearch] = React.useState<string>('')

  useEffect(() => {
    if (commissionEventsQuery.data) {
      const agentEvents = commissionEventsQuery.data.filter(ce => ce.salesAgentId === props.salesAgentId)
      const filtered = agentEvents.filter(ce => moment(ce.eventDate).isBetween(start, end, 'day', '[]') && (activeFilters.length === 0 || activeFilters.includes(ce.commissionTriggerType)) && ((ce.organizationName && ce.organizationName.toLowerCase().includes(search.toLowerCase())) || ce.commissionItemType.toLowerCase().includes(search.toLowerCase())))
      setFilteredCommissionEvents(filtered)
    }
  }, [commissionEventsQuery.data, start, end, search, activeFilters])

  const salesAgent = salesAgentQuery.data

  const formatCurrency = (amount: number) => {
    return `$${Math.abs(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`
  }

  const onFilterClicked = (filter: CommissionTriggerType) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(activeFilters.filter(f => f !== filter))
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  const onResetFilters = () => {
    setActiveFilters([])
  }

  const columns: Array<EuiBasicTableColumn<CommissionEvent>> = [
    {
      name: 'Date',
      field: 'eventDate',
      render: (date: Moment) => moment(date).format('MM/DD/YYYY hh:mm A'),
      sortable: true
    },
    {
      name: 'Organization',
      render: (event: CommissionEvent) =>
        event.commissionTriggerType === CommissionTriggerType.Manual ? (
          ''
        ) : (
          <EuiLink href={`/customers/organizations/${event.organizationId}`} target={'_blank'}>
            {event.organizationName}
          </EuiLink>
        ),
      sortable: (event: CommissionEvent) => event.organizationName
    },
    {
      name: 'Type',
      field: 'commissionTriggerType',
      render: (type: CommissionTriggerType) => {
        switch (type) {
          case CommissionTriggerType.BundlePurchase:
            return 'Purchase'
          case CommissionTriggerType.BundleRecur:
            return 'Recur'
          case CommissionTriggerType.BundleRefund:
            return 'Refund'
          case CommissionTriggerType.Manual:
            return 'Manual'
        }
      },
      sortable: true
    },
    {
      name: 'Amount',
      field: 'saleAmount',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Commission Percent',
      field: 'commissionPercentage',
      render: (amount: number) => `${amount * 100}%`,
      sortable: true
    },
    {
      name: 'Commission Amount',
      field: 'commissionAmount',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    },
    {
      name: 'Bonus Commission',
      field: 'commissionBonus',
      render: (amount: number) => formatCurrency(amount),
      sortable: true
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'eventDate',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 25,
    pageSizeOptions: [25, 50, 100]
  }

  const revenue = filteredCommissionEvents.filter(ce => ce.saleAmount >= 0).reduce((acc, ce) => acc + ce.saleAmount, 0)
  const refunds = filteredCommissionEvents.filter(ce => ce.saleAmount < 0).reduce((acc, ce) => acc + ce.saleAmount, 0)
  const netRevenue = filteredCommissionEvents.reduce((acc, ce) => acc + ce.saleAmount, 0)
  const commission = filteredCommissionEvents.reduce((acc, ce) => acc + ce.commissionAmount, 0)
  const bonus = filteredCommissionEvents.reduce((acc, ce) => acc + ce.commissionBonus, 0)

  const monthData = (() => {
    const allEvents = commissionEventsQuery.data ?? []
    const agentEvents = allEvents.filter(ce => ce.salesAgentId === props.salesAgentId)
    const lastMonthEvents = agentEvents.filter(ce => moment(ce.eventDate).month() === moment().subtract(1, 'month').month() && moment(ce.eventDate).year() === moment().subtract(1, 'month').year())
    const lastMonthRefunds = lastMonthEvents.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundleRefund)
    const lastMonthNotRefundedBundleEvents = lastMonthEvents.filter(ce => !lastMonthRefunds.some(r => r.commissionItemId === ce.commissionItemId))
    const estimatedStartingMRR = lastMonthNotRefundedBundleEvents.reduce((acc, ce) => acc + ce.saleAmount, 0)

    const agentEventsThisMonth = agentEvents.filter(ce => moment(ce.eventDate).month() === moment().month() && moment(ce.eventDate).year() === moment().year())
    const bundlesPurchasedThisMonth = agentEventsThisMonth.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundlePurchase)
    const newRevenueThisMonth = agentEventsThisMonth.filter(ce => bundlesPurchasedThisMonth.some(b => b.commissionItemId === ce.commissionItemId)).reduce((acc, ce) => acc + ce.saleAmount, 0)

    return {
      plansSoldMTD: agentEventsThisMonth.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundlePurchase).length,
      newMRR: newRevenueThisMonth,
      estimatedStartingMRR: estimatedStartingMRR,
      refundMTD: agentEventsThisMonth.filter(ce => ce.commissionTriggerType === CommissionTriggerType.BundleRefund).reduce((acc, ce) => acc + ce.saleAmount, 0) * -1,
      revenueMTD: agentEventsThisMonth.filter(ce => ce.saleAmount >= 0).reduce((acc, ce) => acc + ce.saleAmount, 0),
      netRevenueMTD: agentEventsThisMonth.reduce((acc, ce) => acc + ce.saleAmount, 0)
    }
  })()

  return (
    <React.Fragment>
      <EuiSpacer size='s' />
      <EuiPanel hasBorder={false} hasShadow={false} style={{ backgroundColor: '#efefef' }}>
        <EuiTitle size='m'>
          <h2>{salesAgent?.name}</h2>
        </EuiTitle>
        <EuiFormRow fullWidth>
          <EuiFlexGroup wrap>
            <EuiFlexItem grow={false}>
              <EuiPanel hasShadow={false} hasBorder={false} style={{ minWidth: 160, backgroundColor: '#efefef' }}>
                <EuiStat title={monthData.plansSoldMTD} description='Plans Sold MTD' titleSize='xs' />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiPanel hasShadow={false} hasBorder={false} style={{ minWidth: 160, backgroundColor: '#efefef' }}>
                <EuiStat title={formatCurrency(monthData.estimatedStartingMRR)} description='Estimated Starting MRR' titleSize='xs' />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiPanel hasShadow={false} hasBorder={false} style={{ minWidth: 160, backgroundColor: '#efefef' }}>
                <EuiStat title={formatCurrency(monthData.newMRR)} description='New MRR' titleSize='xs' />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiPanel hasShadow={false} hasBorder={false} style={{ minWidth: 160, backgroundColor: '#efefef' }}>
                <EuiStat title={formatCurrency(monthData.revenueMTD)} description='Revenue MTD' titleSize='xs' />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiPanel hasShadow={false} hasBorder={false} style={{ minWidth: 160, backgroundColor: '#efefef' }}>
                <EuiStat title={formatCurrency(monthData.refundMTD)} description='Refund MTD' titleSize='xs' />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiPanel hasShadow={false} hasBorder={false} style={{ minWidth: 160, backgroundColor: '#efefef' }}>
                <EuiStat title={formatCurrency(monthData.netRevenueMTD)} description='Net Revenue MTD' titleSize='xs' />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiPanel>
      <EuiSpacer />
      <EuiFormRow>
        <EuiDatePickerRange
          fullWidth={false}
          startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
          endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup wrap>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
              <EuiStat title={formatCurrency(revenue)} description='Revenue' titleSize='s' />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
              <EuiStat title={formatCurrency(refunds)} description='Refunds' titleSize='s' />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
              <EuiStat title={`${netRevenue < 0 ? '-' : ''}${formatCurrency(netRevenue)}`} description='Net Revenue' titleSize='s' />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
              <EuiStat title={`${commission < 0 ? '-' : ''}${formatCurrency(commission)}`} description='Commission' titleSize='s' />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true} style={{ minWidth: 240 }}>
              <EuiStat title={formatCurrency(bonus)} description='Bonus' titleSize='s' />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow label={'Search'} fullWidth>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFieldSearch value={search} onChange={e => setSearch(e.target.value)} style={{ minWidth: 400 }} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <CommissionEventsFilterGroup commissionEvents={commissionEventsQuery.data ?? []} activeFilters={activeFilters} onFilterClicked={onFilterClicked} onResetFilters={onResetFilters} />
          </EuiFlexItem>
          <EuiFlexItem />
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiSpacer />
      <EuiInMemoryTable loading={salesAgentQuery.isLoading} items={filteredCommissionEvents} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}
