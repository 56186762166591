import { Moment } from 'moment'

import { Anomaly } from 'api/entities/Anomaly'

import { defaultTransformFn, opsApi } from './opsApi'

const anomaliesTag = opsApi.enhanceEndpoints({ addTagTypes: ['OpenAnomalies', 'AnomaliesForCampaign', 'Anomaly', 'OrganizationAnomalies'] })

const anomaliesApi = anomaliesTag.injectEndpoints({
  endpoints: build => ({
    getOpenAnomalies: build.query<Anomaly[], void>({
      query: () => `anomalies/open`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'OpenAnomalies' }]
    }),
    getAnomaliesForCampaign: build.query<Anomaly[], string>({
      query: campaignId => `anomalies/${campaignId}/campaign`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, campaignId) => [{ type: 'AnomaliesForCampaign', id: campaignId }]
    }),
    getAnomaly: build.query<Anomaly, string>({
      query: anomalyId => `anomalies/${anomalyId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, anomalyId) => [{ type: 'Anomaly', id: anomalyId }]
    }),
    getOrganizationAnomalies: build.query<Anomaly[], string>({
      query: organizationId => `organizations/${organizationId}/anomalies`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'OrganizationAnomalies', id: organizationId }]
    }),
    escalateAnomaly: build.mutation<Anomaly, { anomalyId: string; escalationReason: string }>({
      query: ({ anomalyId, escalationReason }) => ({
        url: `anomalies/escalate`,
        method: 'POST',
        body: {
          anomalyId: anomalyId,
          description: escalationReason
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { anomalyId }) => [{ type: 'OrganizationAnomalies', id: result?.organizationId }, { type: 'Anomaly', id: anomalyId }, { type: 'AnomaliesForCampaign', id: result?.referenceId }, { type: 'OpenAnomalies' }]
    }),
    resolveAnomaly: build.mutation<Anomaly, { anomalyId: string; resolutionReason: string }>({
      query: ({ anomalyId, resolutionReason }) => {
        return {
          url: `anomalies/resolve`,
          method: 'POST',
          body: {
            anomalyId: anomalyId,
            description: resolutionReason
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { anomalyId }) => [{ type: 'OrganizationAnomalies', id: result?.organizationId }, { type: 'Anomaly', id: anomalyId }, { type: 'AnomaliesForCampaign', id: result?.referenceId }, { type: 'OpenAnomalies' }]
    }),
    ignoreAnomaly: build.mutation<Anomaly, { anomalyId: string; ignoreReason: string; ignoreUntil?: Moment }>({
      query: ({ anomalyId, ignoreReason, ignoreUntil }) => {
        console.log('ignoreUntil', ignoreUntil)
        return {
          url: `anomalies/ignore`,
          method: 'POST',
          body: {
            anomalyId: anomalyId,
            description: ignoreReason,
            ignoreUntil: ignoreUntil
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { anomalyId }) => [{ type: 'OrganizationAnomalies', id: result?.organizationId }, { type: 'Anomaly', id: anomalyId }, { type: 'AnomaliesForCampaign', id: result?.referenceId }, { type: 'OpenAnomalies' }]
    }),
    reactivateAnomaly: build.mutation<Anomaly, { anomalyId: string; reactivationReason: string }>({
      query: ({ anomalyId, reactivationReason }) => ({
        url: `anomalies/reactivate`,
        method: 'POST',
        body: {
          anomalyId: anomalyId,
          description: reactivationReason
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { anomalyId }) => [{ type: 'OrganizationAnomalies', id: result?.organizationId }, { type: 'Anomaly', id: anomalyId }, { type: 'AnomaliesForCampaign', id: result?.referenceId }, { type: 'OpenAnomalies' }]
    }),
    regenerateAnomalies: build.mutation<void, void>({
      query: () => ({
        url: `anomalies/regenerate`,
        method: 'POST',
        body: {}
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'Anomaly' }, { type: 'AnomaliesForCampaign' }, { type: 'OpenAnomalies' }]
    })
  }),
  overrideExisting: false
})

export const { useGetOpenAnomaliesQuery: useGetOpenAnomaliesQuery, useGetAnomaliesForCampaignQuery, useGetOrganizationAnomaliesQuery, useGetAnomalyQuery, useResolveAnomalyMutation, useEscalateAnomalyMutation, useReactivateAnomalyMutation, useIgnoreAnomalyMutation, useRegenerateAnomaliesMutation } = anomaliesApi
