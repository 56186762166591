import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { EuiFieldSearch, EuiHighlight, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { SegmentTarget, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface SegmentTargeterProps {
  isInvalid: boolean
  onSegmentClicked(segments: SegmentTarget): void
  onSegmentsFound?: (segments: SegmentTarget[]) => void
  mode: 'Internet' | 'Tv'
}

export const SegmentFinder = ({ onSegmentClicked, isInvalid, onSegmentsFound, mode }: SegmentTargeterProps) => {
  const minimumLengthForSearch = 2
  const [segmentSearchTerm, setSegmentSearchTerm] = useState<string>('')
  const debouncedSegmentSearchTerm = useDebounce(segmentSearchTerm, 750)
  const [searchSegmentPopoverOpen, setSearchSegmentPopoverOpen] = useState<boolean>(false)
  const [segments, setSegments] = useState<Array<SegmentTarget>>([])
  const [selectableSegments, setSelectableSegments] = useState<Array<EuiSelectableOption>>([])
  const [isLoadingSegments, setIsLoadingSegments] = useState<boolean>(false)
  const opsClient = useOpsClient()
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
    []
  )

  const onSearchSegment = () => {
    setSearchSegmentPopoverOpen(false)
  }

  const onSearchSegmentChange = (hint: string) => {
    setSegmentSearchTerm(hint)
    if (!!hint) {
      setSearchSegmentPopoverOpen(true)
    }
  }

  const onSearchSegmentClick = useCallback(
    (segment: SegmentTarget) => {
      setSearchSegmentPopoverOpen(false)
      setSegmentSearchTerm('')
      onSegmentClicked(segment)
    },
    [onSegmentClicked]
  )

  useEffect(() => {
    if (opsClient && debouncedSegmentSearchTerm && opsClient && debouncedSegmentSearchTerm.length > minimumLengthForSearch) {
      if (mode == 'Internet') {
        setIsLoadingSegments(true)
        opsClient.apiGetDataSegments(debouncedSegmentSearchTerm).then(r => {
          setSegments(r.data)
          setIsLoadingSegments(false)
          if (onSegmentsFound) {
            onSegmentsFound(r.data)
          }
        })
      }

      if (mode == 'Tv') {
        setIsLoadingSegments(true)
        opsClient.apiGetDataTvSegments(debouncedSegmentSearchTerm).then(r => {
          setSegments(r.data)
          setIsLoadingSegments(false)
          if (onSegmentsFound) {
            onSegmentsFound(r.data)
          }
        })
      }
    } else {
      setSegments([])
      if (onSegmentsFound) {
        onSegmentsFound([])
      }
    }
  }, [opsClient, debouncedSegmentSearchTerm, onSegmentsFound])

  useEffect(() => {
    if (segments) {
      setSelectableSegments(
        segments.map(
          a =>
            ({
              label: a.name,
              key: a.id.toString(),
              checked: undefined,
              onClick: () => onSearchSegmentClick(a)
            } as EuiSelectableOption)
        )
      )
    }
  }, [segments, onSearchSegmentClick, formatter])

  const renderAudienceOption = (option: EuiSelectableOption, _: string) => {
    const segment = segments.find(a => a.id.toString() === option.key)
    if (!segment) return <React.Fragment />

    return (
      <React.Fragment>
        <EuiHighlight search={segmentSearchTerm}>{option.label}</EuiHighlight>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={segmentSearchTerm}>{segment.description ?? ''}</EuiHighlight>
        </EuiText>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={segmentSearchTerm}>{segment.hierarchy.join(' \\ ')}</EuiHighlight>
        </EuiText>
      </React.Fragment>
    )
  }

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={segmentSearchTerm}
          isLoading={isLoadingSegments}
          onChange={v => onSearchSegmentChange(v.target.value)}
          onFocus={() => setSearchSegmentPopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchSegment()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchSegmentPopoverOpen}
      closePopover={() => setSearchSegmentPopoverOpen(false)}>
      {segments && segments.length ? (
        <EuiSelectable singleSelection options={selectableSegments} renderOption={renderAudienceOption} listProps={{ rowHeight: 60, isVirtualized: true, showIcons: false }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
