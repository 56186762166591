import React from 'react'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiRadio,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import { CampaignType, SegmentTarget, Zip } from 'api'
import { ZipFinder } from 'components/Finders'
import { AcCreateOrganizationModal } from 'components/Modals/AcCreateOrganizationModal'
import TargetingMap from 'components/TargetingMap'
import { BrandMetricsWidget } from 'features/tools/tools/BrandMetricsWidget'
import history from 'services/HistoryService'
import { SegmentFinder } from '../../components/Finders/SegmentFinder'
import { SegmentTargeter } from '../../components/Targeters/SegmentTargeter'

export const ToolsTab: React.FC = () => {
  const [segments, setSegments] = React.useState<SegmentTarget[]>([])
  const [selectedZip, setSelectedZip] = React.useState<Zip>()
  const [showNewOrganizationModal, setShowNewOrganizationModal] = React.useState<boolean>(false)
  const [segmentType, setSegmentType] = React.useState<CampaignType>(CampaignType.Internet)

  const onNewOrganizationClick = () => {
    setShowNewOrganizationModal(true)
  }

  const onNewOrganizationClose = () => {
    setShowNewOrganizationModal(false)
  }

  const onOrgCreated = (orgId: string) => {
    setShowNewOrganizationModal(false)
    history.push(`/customers/organizations/${orgId}`)
  }

  const onSegmentSelected = (segment: SegmentTarget) => {
    setSegments([...segments, segment])
  }

  const onSegmentRemoved = (segment: SegmentTarget) => {
    setSegments(segments.filter(s => s.id !== segment.id))
  }

  return (
    <React.Fragment>
      <EuiButtonEmpty iconType={'plus'} size={'s'} onClick={onNewOrganizationClick}>
        New Organization
      </EuiButtonEmpty>
      <EuiSpacer />
      <EuiTitle size={'xs'}>
        <h2>Audience Builder</h2>
      </EuiTitle>
      <EuiFormRow fullWidth>
        <EuiFlexGroup responsive={false} wrap>
          <EuiFlexItem grow={false}>
            <EuiRadio label={'Internet'} id={'Internet Segments'} onChange={() => setSegmentType(CampaignType.Internet)} checked={segmentType === CampaignType.Internet} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiRadio label={'TV'} id={'Tv Segments'} onChange={() => setSegmentType(CampaignType.TV)} checked={segmentType === CampaignType.TV} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow label={'Audience Segments'} fullWidth>
        <SegmentFinder isInvalid={false} onSegmentClicked={onSegmentSelected} mode={segmentType === CampaignType.Internet ? 'Internet' : 'Tv'} />
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <SegmentTargeter segments={segments} onSegmentRemoved={onSegmentRemoved} />
      </EuiFormRow>
      <EuiSpacer />
      <EuiHorizontalRule />
      <EuiFormRow fullWidth>
        <BrandMetricsWidget />
      </EuiFormRow>
      <EuiSpacer />
      <EuiHorizontalRule />
      <EuiFormRow label='Zip Finder'>
        <ZipFinder
          addZip={zip => {
            setSelectedZip(zip)
          }}
          isInvalid={false}
        />
      </EuiFormRow>
      <EuiSpacer />
      <EuiFormRow label={'Billboard Map'} fullWidth>
        <TargetingMap hideDrawingTools hideLocationSearch suggestedPlaces={selectedZip ? [selectedZip.name] : []} includeBillboards geoCircles={[]} addCircle={() => {}} removeCircle={() => {}} modifyCircle={() => {}} geoRectangles={[]} addRectangle={() => {}} removeRectangle={() => {}} modifyRectangle={() => {}} geoPolygons={[]} addPolygon={() => {}} removePolygon={() => {}} modifyPolygon={() => {}} />
      </EuiFormRow>
      {showNewOrganizationModal && <AcCreateOrganizationModal onClose={onNewOrganizationClose} onOrgCreated={onOrgCreated} />}
    </React.Fragment>
  )
}
