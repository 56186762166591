import React from 'react'

import { EuiAvatar, EuiButton, EuiFormRow, EuiPanel, EuiSpacer, EuiText, EuiTimeline, EuiTimelineItem, EuiTitle } from '@elastic/eui'

export const AdvertingRoadmap: React.FC = () => {
  const activeColor = '#006BB4'

  return (
    <React.Fragment>
      <EuiTitle size={'s'}>
        <h3>Advertising Roadmap</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiTimeline>
        <EuiTimelineItem verticalAlign={'top'} icon={<EuiAvatar name={'checked'} iconType={'check'} color={'subdued'} />}>
          <React.Fragment>
            <EuiTitle size={'s'}>
              <h3>Stage 1</h3>
            </EuiTitle>
            <EuiText size='s'>
              <p>Launch</p>
            </EuiText>
          </React.Fragment>
        </EuiTimelineItem>
        <EuiTimelineItem verticalAlign={'top'} icon={<EuiAvatar name={'checked'} iconType={'check'} color={activeColor} />}>
          <React.Fragment>
            <EuiTitle size={'s'}>
              <h3>Stage 2</h3>
            </EuiTitle>
            <EuiText size='s'>
              <p>Early Signals</p>
            </EuiText>
            <EuiPanel hasBorder={false} hasShadow={false}>
              <EuiFormRow label={'What to expect'}>
                <EuiText size='s'>
                  <p>In stage 2, you should begin evaluating the audience and making sure the ads are showing up multiple times for each person.</p>
                  <p>You should also make sure your ads are showing up in the right places.</p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label={'Recommended Steps'}>
                <EuiText size='s'>
                  <p>Schedule a consultation with an Account Manager to understand how advanced insights can help you advertise most effectively.</p>
                  <p>This is a free opportunity to get professional help with your advertising.</p>
                </EuiText>
              </EuiFormRow>
            </EuiPanel>
          </React.Fragment>
        </EuiTimelineItem>
        <EuiTimelineItem verticalAlign={'top'} icon={<EuiAvatar name={'checked'} iconType={'check'} color={'subdued'} />}>
          <React.Fragment>
            <EuiTitle size={'s'}>
              <h3>Stage 3</h3>
            </EuiTitle>
            <EuiText size='s'>
              <p>Enable Advanced Insights</p>
            </EuiText>
          </React.Fragment>
        </EuiTimelineItem>
        <EuiTimelineItem verticalAlign={'top'} icon={<EuiAvatar name={'checked'} iconType={'check'} color={'subdued'} />}>
          <React.Fragment>
            <EuiTitle size={'s'}>
              <h3>Stage 4</h3>
            </EuiTitle>
            <EuiText size='s'>
              <p>Monitor Saturation</p>
            </EuiText>
          </React.Fragment>
        </EuiTimelineItem>
        <EuiTimelineItem verticalAlign={'top'} icon={<EuiAvatar name={'checked'} iconType={'check'} color={'subdued'} />}>
          <React.Fragment>
            <EuiTitle size={'s'}>
              <h3>Stage 5</h3>
            </EuiTitle>
            <EuiText size='s'>
              <p>Evaluate Impact & Expand</p>
            </EuiText>
          </React.Fragment>
        </EuiTimelineItem>
      </EuiTimeline>
      <EuiSpacer />
      <React.Fragment>
        <EuiTitle size={'s'}>
          <h3>Want Help?</h3>
        </EuiTitle>
        <EuiSpacer size={'s'} />
        <EuiText size='s'>
          <p>Did you know you have a team of experts at your disposal?</p>
          <p>Yep, loads of free help... that's right, no additional cost to you.</p>
          <p>We can help you create custome ads, target your campaigns, and even get you connected with matching incentives when they are available.</p>
          <p>Schedule a call to unlock the full power of AdCritter</p>
        </EuiText>
        <EuiSpacer size={'s'} />
        <EuiFormRow>
          <EuiButton onClick={() => {}} fill>
            Schedule a Call
          </EuiButton>
        </EuiFormRow>
      </React.Fragment>
    </React.Fragment>
  )
}
