import React, { FC, useEffect, useState } from 'react'

import { EuiSpacer, EuiTabbedContent } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useAccountManagerDashboardTabs } from 'features/accountManagerDashboard/definitions/AccountManagerTabs'
import history from 'services/HistoryService'

export const DefaultSpacerForTabbedContent = () => <EuiSpacer size='xxl' />

export interface AccountManagerDashboardTabsProps {
  currentTab: string
}

const AccountManagerDashboardTabs: FC<AccountManagerDashboardTabsProps> = ({ currentTab }) => {
  const tabs = useAccountManagerDashboardTabs()
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs.filter(t => t.id === currentTab)[0])

  useEffect(() => {
    setSelectedTab(tabs.filter(t => t.id === currentTab)[0])
  })

  const onTabClick = (selectedTab: EuiTabbedContentTab) => {
    history.push(`/account-manager/dashboard/${selectedTab.id}`)
  }

  return <EuiTabbedContent tabs={tabs} selectedTab={selectedTab} onTabClick={onTabClick} />
}

export default AccountManagerDashboardTabs
