import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiBreadcrumbs, EuiImage, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTabbedContent, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { PendingAuditBillboardAd, PendingAuditInternetAd, PendingAuditTvCommercial, useOpsClient } from 'api'
import history from 'services/HistoryService'
import { localTimestamp } from 'utils/dateUtils'

const AdsPendingPage: React.FC = () => {
  const [isLoadingPendingInternetAds, setIsLoadingPendingInternetAds] = useState(false)
  const [pendingInternetAds, setPendingInternetAds] = useState<PendingAuditInternetAd[]>([])
  const [isLoadingPendingTvCommercials, setIsLoadingPendingTvCommercials] = useState(false)
  const [pendingTvCommercials, setPendingTvCommercials] = useState<PendingAuditTvCommercial[]>([])
  const [isLoadingPendingBillboardAds, setIsLoadingPendingBillboardAds] = useState(false)
  const [pendingBillboardAds, setPendingBillboardAds] = useState<PendingAuditBillboardAd[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    loadInternetAds()
    loadTvCommercials()
    loadBillboardAds()
  }, [opsClient])

  const loadInternetAds = () => {
    setIsLoadingPendingInternetAds(true)

    opsClient?.getInternetAdsPendingAudit().then(pa => {
      setPendingInternetAds(pa)
      setIsLoadingPendingInternetAds(false)
    })
  }

  const loadTvCommercials = () => {
    setIsLoadingPendingTvCommercials(true)

    opsClient?.getTvCommercialsPendingAudit().then(pa => {
      setPendingTvCommercials(pa)
      setIsLoadingPendingTvCommercials(false)
    })
  }

  const loadBillboardAds = () => {
    setIsLoadingPendingBillboardAds(true)

    opsClient?.getBillboardAdsPendingAudit().then(pa => {
      setPendingBillboardAds(pa)
      setIsLoadingPendingBillboardAds(false)
    })
  }

  const internetColumns: Array<EuiBasicTableColumn<PendingAuditInternetAd>> = [
    {
      name: '',
      render: (c: PendingAuditInternetAd) => {
        return <EuiImage url={c.displayAdUrl ? c.displayAdUrl : c.thumbnailUrl} size={41} alt='thumbnail' />
      },
      width: '49'
    },
    {
      name: `Ad`,
      render: (c: PendingAuditInternetAd) => (
        <EuiLink href={`/customers/pending/ads/audit/${c.adId}`} target='_blank'>
          {c.headline ? c.headline : c.name}
        </EuiLink>
      ),
      sortable: (c: PendingAuditInternetAd) => c.headline
    },
    {
      name: 'Company',
      field: 'companyName'
    },
    {
      name: 'Ad Created Date',
      width: '150',
      render: (c: PendingAuditInternetAd) => localTimestamp(c.created),
      sortable: (c: PendingAuditInternetAd) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    },
    {
      name: 'Campaign Activated Date',
      render: (c: PendingAuditInternetAd) => localTimestamp(c.activated),
      sortable: (c: PendingAuditInternetAd) => moment(c.activated, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    }
  ]

  const tvColumns: Array<EuiBasicTableColumn<PendingAuditTvCommercial>> = [
    {
      name: '',
      render: (c: PendingAuditTvCommercial) =>
        c.tvCommercialUploadedDetails ? (
          <video id='uploadedVideo' width='100%' hidden={false} style={{ width: 'auto', maxWidth: 41, maxHeight: 41 }}>
            <source src={c.tvCommercialUploadedDetails.uploadedUrl ? c.tvCommercialUploadedDetails.uploadedUrl : ''} type='video/mp4' />
          </video>
        ) : (
          <EuiImage url={c.thumbnailUrl} size={41} alt='thumbnail' />
        ),
      width: '49'
    },
    {
      name: `Ad`,
      render: (c: PendingAuditTvCommercial) => (
        <EuiLink href={`/customers/pending/ads/audit/${c.adId}`} target='_blank'>
          {c.name}
        </EuiLink>
      ),
      sortable: (c: PendingAuditTvCommercial) => c.name
    },
    {
      name: 'Company',
      field: 'accountName'
    },
    {
      name: 'Ad Created Date',
      width: '150',
      render: (c: PendingAuditTvCommercial) => localTimestamp(c.created),
      sortable: (c: PendingAuditTvCommercial) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    },
    {
      name: 'Campaign Activated Date',
      width: '150',
      render: (c: PendingAuditTvCommercial) => localTimestamp(c.activated),
      sortable: (c: PendingAuditTvCommercial) => moment(c.activated, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    }
  ]

  const billboardColumns: Array<EuiBasicTableColumn<PendingAuditBillboardAd>> = [
    {
      name: '',
      render: (c: PendingAuditBillboardAd) => <EuiImage url={c.thumbnailUrl} size={41} alt='thumbnail' />,
      width: '49'
    },
    {
      name: `Ad`,
      render: (c: PendingAuditBillboardAd) => (
        <EuiLink href={`/customers/pending/ads/audit/${c.adId}`} target='_blank'>
          {c.name}
        </EuiLink>
      ),
      sortable: (c: PendingAuditBillboardAd) => c.name
    },
    {
      name: 'Company',
      field: 'accountName'
    },
    {
      name: 'Ad Created Date',
      width: '150',
      render: (c: PendingAuditBillboardAd) => localTimestamp(c.created),
      sortable: (c: PendingAuditBillboardAd) => moment(c.created, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    },
    {
      name: 'Campaign Activated Date',
      width: '150',
      render: (c: PendingAuditBillboardAd) => localTimestamp(c.activated),
      sortable: (c: PendingAuditBillboardAd) => moment(c.activated, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString()
    }
  ]

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'created',
      direction: SortDirection.DESC
    }
  }

  const tabs: EuiTabbedContentTab[] = [
    {
      id: 'internet',
      name: 'Internet',
      content: (
        <React.Fragment>
          <EuiSpacer size='s' />
          <EuiInMemoryTable loading={isLoadingPendingInternetAds} items={pendingInternetAds} columns={internetColumns} pagination={pagination} sorting={sorting} />
        </React.Fragment>
      )
    },
    {
      id: 'tv',
      name: 'TV',
      content: (
        <React.Fragment>
          <EuiSpacer size='s' />
          <EuiInMemoryTable loading={isLoadingPendingTvCommercials} items={pendingTvCommercials} columns={tvColumns} pagination={pagination} sorting={sorting} />
        </React.Fragment>
      )
    },
    {
      id: 'billboard',
      name: 'Billboard',
      content: (
        <React.Fragment>
          <EuiSpacer size='s' />
          <EuiInMemoryTable loading={isLoadingPendingBillboardAds} items={pendingBillboardAds} columns={billboardColumns} pagination={pagination} sorting={sorting} />
        </React.Fragment>
      )
    }
  ]

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Customers' },
              {
                text: 'Ads',
                href: '/customers/ads',
                onClick: () => history.push('/customers/ads')
              },
              {
                text: 'Pending',
                href: '/customers/pending/ads',
                onClick: () => history.push('/customers/pending/ads')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Ads Pending Audit</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default AdsPendingPage
