import {EuiCard, EuiIcon, EuiPanel} from "@elastic/eui";
import {IconType} from "@elastic/eui/src/components/icon/icon";
import history from 'services/HistoryService'
import React from "react";

export interface INavigationItem {
  title: string
  route: string
  icon: IconType
}
export const NavigationItem: React.FC<INavigationItem> = (props) => {
  return (
    <EuiCard
      icon={<EuiIcon size={'xxl'} type={props.icon}/>}
      title={props.title}
      description={''}
      onClick={() => {
        history.push(props.route)
      }}
    />
  )
}