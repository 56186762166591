import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiButtonIcon, EuiCallOut, EuiComboBox, EuiDescriptionList, EuiDescriptionListDescription, EuiDescriptionListTitle, EuiFieldNumber, EuiFieldText, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLink, EuiLoadingContent, EuiProgress, EuiSelect, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui'
import { EuiComboBoxOptionOption } from '@elastic/eui/src/components/combo_box/types'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { TvCommercial, UpdateTvCommercial, useOpsClient } from 'api'

interface TvCommercialDetailsTabParams {
  tvCommercialId: string
}

interface FormValues {
  name: string
  kind: string
  quality: string
  outroDuration: number
  onlyForOrganizationIds: string[]
  destinationUrl: string | undefined
}

const TvCommercialDetailsTab: React.FC<TvCommercialDetailsTabParams> = ({ tvCommercialId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tvCommercial, setTvCommercial] = useState<TvCommercial>()
  const [isLoadingTvCommercials, setIsLoadingTvCommercials] = useState(false)
  const [tvCommercials, setTvCommercials] = useState<TvCommercial[]>([])
  const [ads, setCommercials] = useState<EuiComboBoxOptionOption[]>([])
  const [chosenCompanionTvCommercials, setChosenCompanionTvCommercials] = useState<EuiComboBoxOptionOption[]>([])
  const [initial, setInitial] = useState<FormValues>({
    name: '',
    kind: 'Seconds15',
    quality: 'Normal',
    outroDuration: 3,
    onlyForOrganizationIds: [],
    destinationUrl: undefined
  })
  const [destination, setDestination] = useState<string | null>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploadingVideo, setIsUploadingVideo] = useState(false)
  const [isUploadingAudio, setIsUploadingAudio] = useState(false)
  const [isUploadingVoiceOver, setIsUploadingVoiceOver] = useState(false)
  const [isUploadingOutro, setIsUploadingOutro] = useState(false)
  const [sourceVideo, setSourceVideo] = useState<string | null>(null)
  const [sourceAudio, setSourceAudio] = useState<string | null>(null)
  const [sourceVoiceOver, setSourceVoiceOver] = useState<string | null>(null)
  const [sourceOutro, setSourceOutro] = useState<string | null>(null)
  const [isGeneratingSample, setIsGeneratingSample] = useState(false)
  const [isExportingSample, setIsExportingSample] = useState(false)
  const [sampleUrl, setSampleUrl] = useState<string | null>(null)
  const [approvedOrgId, setApprovedOrgId] = useState(true)
  const opsClient = useOpsClient()

  const refresh = () => {
    setIsLoading(true)
    setIsLoadingTvCommercials(true)
    opsClient!.getTvCommercial(tvCommercialId).then(result => {
      setInitial({
        name: result.name,
        kind: result.kind,
        quality: result.quality,
        outroDuration: result.outroDuration,
        onlyForOrganizationIds: result.onlyForOrganizationIds,
        destinationUrl: undefined
      })
      setTvCommercial(result)
      if (result.sampleUrl) setSampleUrl(result.sampleUrl)
      if (result.sourceVideo) {
        setSourceVideo(result.sourceVideo)
      }
      if (result.sourceAudio) {
        setSourceAudio(result.sourceAudio)
      }
      if (result.sourceVoiceOver) {
        setSourceVoiceOver(result.sourceVoiceOver)
      }
      if (result.sourceOutro) {
        setSourceOutro(result.sourceOutro)
      }
      opsClient!.getTvCommercials().then(data => {
        setTvCommercials(data.filter(d => d.id !== tvCommercialId))
        setIsLoadingTvCommercials(false)
      })
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsClient, tvCommercialId])

  useEffect(() => {
    if (tvCommercials) {
      const p = tvCommercials.map(
        x =>
          ({
            key: x.id,
            label: x.name
          } as EuiComboBoxOptionOption)
      )
      setCommercials(p)
      if (tvCommercial) {
        setChosenCompanionTvCommercials(
          tvCommercials
            .filter(p => p.id === tvCommercial.companionTvCommercialId)
            .map(
              x =>
                ({
                  key: x.id,
                  label: x.name
                } as EuiComboBoxOptionOption)
            )
        )
      }
    }
  }, [tvCommercials, tvCommercial])

  useEffect(() => {
    if (opsClient && tvCommercialId) {
      opsClient.getTvCommercialUpload(tvCommercialId).then(data => setDestination(data))
    }
  }, [opsClient, tvCommercialId])

  useEffect(() => {
    if (destination) {
      const storageClient = new BlobServiceClient(destination)
      setContainerClient(storageClient.getContainerClient(tvCommercialId))
    }
  }, [destination, tvCommercialId])

  const selectCompanionTvCommercial = (options: EuiComboBoxOptionOption[]) => {
    setChosenCompanionTvCommercials(options)
  }

  const generateSample = () => {
    setSampleUrl(null)
    setIsGeneratingSample(true)
    setIsExportingSample(true)
    opsClient!.generateSampleTvCommercial(tvCommercialId).then(() => {
      setIsGeneratingSample(false)
    })
    const checkSampleExist = setInterval(async function () {
      let url = await opsClient!.getTvCommercialSampleUrl(tvCommercialId)
      if (url) {
        setSampleUrl(url)
        setIsExportingSample(false)
        clearInterval(checkSampleExist)
      }
    }, 1000)
  }

  const tvCommercialSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the TV Commercial name'),
    onlyForOrganizationIds: Yup.array().of(Yup.string().min(36))
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const update: UpdateTvCommercial = {
      name: values.name,
      kind: values.kind,
      quality: values.quality,
      destinationUrl: undefined,
      companionTvCommercialId: chosenCompanionTvCommercials.length === 0 ? null : chosenCompanionTvCommercials[0].key!,
      sourceVideo: sourceVideo,
      sourceAudio: sourceAudio,
      sourceVoiceOver: sourceVoiceOver,
      sourceOutro: sourceOutro,
      outroDuration: values.outroDuration,
      onlyForOrganizationIds: values.onlyForOrganizationIds
    }

    opsClient?.updateTvCommercial(tvCommercialId, update).then(result => {
      setTvCommercial(result)
      setSubmitting(false)
    })
  }

  const onVideoFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingVideo(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())

      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingVideo(false)
          setSourceVideo(blockBlobClient!.url!)
        })
    }
  }

  const onAudioFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingAudio(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())

      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingAudio(false)
          setSourceAudio(blockBlobClient!.url!)
        })
    }
  }

  const onVoiceOverFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingVoiceOver(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())

      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingVoiceOver(false)
          setSourceVoiceOver(blockBlobClient!.url!)
        })
    }
  }

  const onOutroFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingOutro(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())

      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingOutro(false)
          setSourceOutro(blockBlobClient!.url!)
        })
    }
  }

  const kinds: EuiSelectOption[] = [
    { value: 'Seconds15', text: '15 Secs' },
    { value: 'Seconds30', text: '30 Secs' }
  ]

  const qualities: EuiSelectOption[] = [
    { value: 'Normal', text: 'Normal' },
    { value: 'VeryGood', text: 'Very Good' },
    { value: 'Awesome', text: 'Awesome' }
  ]

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const orgIdValidation = (e: any) => e.length !== 36

  return (
    <React.Fragment>
      <EuiSpacer />
      {tvCommercial && (
        <EuiFlexGroup>
          <EuiFlexItem>
            <Formik initialValues={initial} enableReinitialize validationSchema={tvCommercialSchema} onSubmit={doSubmit}>
              {props => (
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Type'>
                    <EuiText size='s'>{tvCommercial.type}</EuiText>
                  </EuiFormRow>
                  <EuiFormRow label='Status'>
                    <EuiText size='s'>{tvCommercial.status}</EuiText>
                  </EuiFormRow>
                  <EuiFormRow label='Production Quality' isInvalid={!!props.errors.quality} error={props.errors.quality}>
                    <EuiSelect name='quality' isInvalid={!!props.errors.quality} options={qualities} value={props.values.quality} onChange={e => props.setFieldValue('quality', e.target.value)} />
                  </EuiFormRow>
                  <EuiFormRow label='Length' isInvalid={!!props.errors.kind} error={props.errors.kind}>
                    <EuiSelect name='kind' isInvalid={!!props.errors.kind} options={kinds} value={props.values.kind} onChange={e => props.setFieldValue('kind', e.target.value)} />
                  </EuiFormRow>
                  <EuiFormRow label='Companion TV Commercial'>
                    <EuiComboBox singleSelection={{ asPlainText: true }} options={ads} selectedOptions={chosenCompanionTvCommercials} onChange={selectCompanionTvCommercial} isLoading={isLoadingTvCommercials} />
                  </EuiFormRow>
                  <EuiSpacer size='m' />

                  {tvCommercial.sourceVideo && (
                    <React.Fragment>
                      <EuiFormRow label='Source Video'>
                        <EuiLink target='_blank' href={tvCommercial.sourceVideo}>
                          {tvCommercial.sourceVideo.split('/').pop()}
                        </EuiLink>
                      </EuiFormRow>
                      <EuiSpacer size='s' />
                      {tvCommercial.sourceVideoDetails && (
                        <React.Fragment>
                          <EuiDescriptionList type='inline' compressed={true}>
                            <EuiDescriptionListTitle>Duration</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceVideoDetails.duration}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Width</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceVideoDetails.width}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Height</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceVideoDetails.height}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Video Codec</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceVideoDetails.videoCodec}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Frames per sec.</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceVideoDetails.framesPerSecond}</EuiDescriptionListDescription>
                          </EuiDescriptionList>
                          <EuiSpacer size='s' />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {!tvCommercial.sourceVideo && (
                    <EuiText size='xs'>
                      <strong>Source Video:</strong>
                    </EuiText>
                  )}
                  <EuiFilePicker id='sourceVideo' display='default' multiple={false} initialPromptText={!tvCommercial?.sourceVideo ? 'Drop video here' : 'Replace video by dropping new one here'} onChange={onVideoFileChange} isLoading={isUploadingVideo} />
                  <EuiSpacer size='m' />

                  {tvCommercial.sourceAudio && (
                    <React.Fragment>
                      <EuiFormRow label='Source Audio'>
                        <EuiLink target='_blank' href={tvCommercial.sourceAudio}>
                          {tvCommercial.sourceAudio.split('/').pop()}
                        </EuiLink>
                      </EuiFormRow>
                      <EuiSpacer size='s' />
                      {tvCommercial.sourceAudioDetails && (
                        <React.Fragment>
                          <EuiDescriptionList type='inline' compressed={true}>
                            <EuiDescriptionListTitle>Duration</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceAudioDetails.duration}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Audio Codec</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceAudioDetails.audioCodec}</EuiDescriptionListDescription>
                          </EuiDescriptionList>
                          <EuiSpacer size='s' />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {!tvCommercial.sourceAudio && (
                    <EuiText size='xs'>
                      <strong>Source Audio:</strong>
                    </EuiText>
                  )}
                  <EuiFilePicker id='sourceAudio' display='default' multiple={false} initialPromptText={!tvCommercial?.sourceAudio ? 'Drop audio here' : 'Replace audio by dropping new one here'} onChange={onAudioFileChange} isLoading={isUploadingAudio} />
                  <EuiSpacer size='m' />

                  {tvCommercial.type === 'VoiceOver' && (
                    <React.Fragment>
                      {tvCommercial.sourceVoiceOver && (
                        <React.Fragment>
                          <EuiFormRow label='Source Voice-over'>
                            <EuiLink target='_blank' href={tvCommercial.sourceVoiceOver}>
                              {tvCommercial.sourceVoiceOver.split('/').pop()}
                            </EuiLink>
                          </EuiFormRow>
                          <EuiSpacer size='s' />
                          {tvCommercial.sourceVoiceOverDetails && (
                            <React.Fragment>
                              <EuiDescriptionList type='inline' compressed={true}>
                                <EuiDescriptionListTitle>Duration</EuiDescriptionListTitle>
                                <EuiDescriptionListDescription>{tvCommercial.sourceVoiceOverDetails.duration}</EuiDescriptionListDescription>
                                <EuiDescriptionListTitle>Audio Codec</EuiDescriptionListTitle>
                                <EuiDescriptionListDescription>{tvCommercial.sourceVoiceOverDetails.audioCodec}</EuiDescriptionListDescription>
                              </EuiDescriptionList>
                              <EuiSpacer size='s' />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      {!tvCommercial.sourceVoiceOver && (
                        <EuiText size='xs'>
                          <strong>Source Voice-over:</strong>
                        </EuiText>
                      )}
                      <EuiFilePicker id='sourceVoiceOver' display='default' multiple={false} initialPromptText={!tvCommercial?.sourceVoiceOver ? 'Drop voice-over here' : 'Replace voice-over by dropping new one here'} onChange={onVoiceOverFileChange} isLoading={isUploadingVoiceOver} />
                      <EuiSpacer size='m' />
                    </React.Fragment>
                  )}

                  {tvCommercial.sourceOutro && (
                    <React.Fragment>
                      <EuiFormRow label='Outro'>
                        <EuiLink target='_blank' href={tvCommercial.sourceOutro}>
                          {tvCommercial.sourceOutro.split('/').pop()}
                        </EuiLink>
                      </EuiFormRow>
                      <EuiSpacer size='s' />
                      {tvCommercial.sourceOutroDetails && (
                        <React.Fragment>
                          <EuiDescriptionList type='inline' compressed={true}>
                            <EuiDescriptionListTitle>Image Codec</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceOutroDetails.imageCodec}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Width</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceOutroDetails.width}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Height</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{tvCommercial.sourceOutroDetails.height}</EuiDescriptionListDescription>
                          </EuiDescriptionList>
                          <EuiSpacer size='s' />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {!tvCommercial.sourceOutro && (
                    <EuiText size='xs'>
                      <strong>Outro:</strong>
                    </EuiText>
                  )}
                  <EuiFilePicker id='sourceOutro' display='default' multiple={false} initialPromptText={!tvCommercial?.sourceOutro ? 'Drop outro picture here' : 'Replace outro picture by dropping new one here'} onChange={onOutroFileChange} isLoading={isUploadingOutro} />
                  <EuiSpacer size='m' />

                  <EuiFormRow label='Outro Duration (secs)' isInvalid={!!props.errors.outroDuration} error={props.errors.outroDuration}>
                    <EuiFieldNumber name='outroDuration' value={props.values.outroDuration} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.outroDuration} step={'any'} min={1} />
                  </EuiFormRow>

                  <EuiSpacer />

                  <EuiText size='xs'>
                    <strong>Map To Organization(s):</strong>
                  </EuiText>
                  <FieldArray
                    name='onlyForOrganizationIds'
                    render={arrayHelpers => (
                      <React.Fragment>
                        {props.values.onlyForOrganizationIds &&
                          props.values.onlyForOrganizationIds.length > 0 &&
                          props.values.onlyForOrganizationIds.map((text, index) => (
                            <EuiFlexGroup gutterSize='xs' key={index} style={{ marginTop: 5, marginBottom: 5 }}>
                              <EuiFlexItem grow={false}>
                                <EuiFlexGroup alignItems='center'>
                                  <EuiFlexItem grow={false}>
                                    <EuiToolTip position='top' content='Remove this Organization'>
                                      <EuiButtonIcon
                                        id='remove'
                                        iconType='cross'
                                        aria-label='remove'
                                        color='warning'
                                        onClick={() => {
                                          arrayHelpers.remove(index)
                                        }}
                                      />
                                    </EuiToolTip>
                                    <input type='hidden' name={`texts[${index}].id`} value={`texts[${index}].id`} />
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiFlexItem>
                              <EuiFlexItem>
                                <EuiFormRow label={index === 0 ? 'Organization ID' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `onlyForOrganizationIds[${index}]`) && !!getIn(props.errors, `onlyForOrganizationIds[${index}]`)} error={getIn(props.errors, `onlyForOrganizationIds[${index}]`)}>
                                  <EuiFieldText fullWidth name={`onlyForOrganizationIds[${index}]`} value={getIn(props.values, `onlyForOrganizationIds[${index}]`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `onlyForOrganizationIds[${index}]`) && !!getIn(props.errors, `onlyForOrganizationIds[${index}]`)} />
                                </EuiFormRow>
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          ))}
                        {setApprovedOrgId(props.values.onlyForOrganizationIds.some(orgIdValidation))}
                        {approvedOrgId && props.values.onlyForOrganizationIds && props.values.onlyForOrganizationIds.length > 0 && <EuiCallOut color='warning' iconType='alert' title='Please add valid Organization ID(s), or remove all invalid organization IDs'></EuiCallOut>}
                        <EuiSpacer size='s' />
                        <EuiButtonEmpty
                          id='addText'
                          size='s'
                          onClick={() => {
                            arrayHelpers.push('ID')
                          }}
                          iconType='listAdd'>
                          Add {props.values.onlyForOrganizationIds.length === 0 ? 'New' : 'Another'} Organization
                        </EuiButtonEmpty>
                      </React.Fragment>
                    )}
                  />

                  <EuiSpacer />

                  <EuiFlexGroup gutterSize='s'>
                    <EuiFlexItem grow={false}>
                      <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={isUploadingVideo || isUploadingAudio || isUploadingVoiceOver || isUploadingOutro}>
                        Save
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='success' iconType='refresh' onClick={refresh} />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='accent' onClick={generateSample} isLoading={isGeneratingSample} isDisabled={isGeneratingSample}>
                        Generate Sample
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiForm>
              )}
            </Formik>
          </EuiFlexItem>
          <EuiFlexItem>
            {sampleUrl && <video src={sampleUrl} controls style={{ width: 640 }} />}
            {isExportingSample && (
              <div style={{ width: 640, height: 360, backgroundColor: '#000' }}>
                <EuiProgress />
              </div>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </React.Fragment>
  )
}

export default TvCommercialDetailsTab
