import React, { useEffect, useState } from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { useOpsClient } from 'api'

interface StateTargeterProps {
  states: Array<string>

  onStateRemoved(state: string): void
}

export const StateTargeter = ({ states, onStateRemoved }: StateTargeterProps) => {
  const opsClient = useOpsClient()
  const [selectableStates, setSelectableStates] = useState<Array<EuiSelectableOption>>()

  useEffect(() => {
    if (opsClient) {
      opsClient.getStates().then(r => {
        setSelectableStates(
          r.map(
            s =>
              ({
                label: s.name,
                key: s.stateId
              } as EuiSelectableOption)
          )
        )
      })
    }
  }, [opsClient])

  return (
    <React.Fragment>
      {selectableStates && states && states.length > 0 && (
        <EuiListGroup bordered maxWidth='100%' gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
          {states.map(s => (
            <EuiListGroupItem
              size='xs'
              key={s}
              label={`${selectableStates.filter(ss => ss.key === s).map(r => r.label)}`}
              extraAction={{
                onClick: () => {
                  onStateRemoved(s)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
