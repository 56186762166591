import React, { useEffect } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiIcon, EuiPanel, EuiSpacer, EuiStat, EuiText, EuiTextColor, EuiTitle } from '@elastic/eui'

import { Account } from 'api'
import { ImpressionBucket } from 'api/entities/AdvertisingSnapshot'
import { useGetAdvertisingSnapshotQuery } from 'api/rtkQueryApi/opsApi/accountsApi'
import { AccountCampaignsPacingChart } from 'features/customers/organizations/Snapshot/AccountCampaignsPacingChart'
import { AdvertisingSnapshotCitiesPieChart } from 'features/customers/organizations/Snapshot/AdvertisingSnapshotCitiesPieChart'
import { AdvertisingSnapshotReachChart } from 'features/customers/organizations/Snapshot/AdvertisingSnapshotReachChart'
import { AdvertisingTargetingChart } from 'features/customers/organizations/Snapshot/AdvertisingTargetingChart'
import { ShowcaseDomainsChart } from 'features/customers/organizations/Snapshot/ShowcaseDomainsChart'

export interface IAdvertisingSnapshotDetails {
  account: Account
}
export const AdvertisingSnapshotDetails: React.FC<IAdvertisingSnapshotDetails> = props => {
  const snapshotQuery = useGetAdvertisingSnapshotQuery(props.account.accountId)
  const [topCity, setTopCity] = React.useState<ImpressionBucket>()
  const [saturationScore, setSaturationScore] = React.useState<number>(0)

  useEffect(() => {
    if (snapshotQuery.data) {
      if (snapshotQuery.data.cityBuckets.length > 0) {
        setTopCity([...snapshotQuery.data.cityBuckets].sort((a, b) => b.impressions - a.impressions)[0])
      }
      setSaturationScore((snapshotQuery.data.totalWith2PlusViews * 100) / snapshotQuery.data.totalUniques)
    }
  }, [snapshotQuery.data])

  return (
    <React.Fragment>
      <React.Fragment>
        <EuiTitle size={'s'}>
          <h3>Prepared for: {props.account.organizationName}</h3>
        </EuiTitle>
        <EuiSpacer size={'s'} />
        <EuiText size={'s'} style={{ paddingRight: 144 }}>
          <p>This snapshot provides a top-level perspective of advertising efforts and is useful to ensure campaigns are running properly, and ads are bing shown to the right people in the right places.</p>
        </EuiText>
      </React.Fragment>
      <EuiHorizontalRule />
      <React.Fragment>
        <div>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false} style={{ backgroundColor: '#e5e5e5' }}>
                <EuiStat description={'7 Day Audience'} title={snapshotQuery.data?.totalUniques.toLocaleString()} isLoading={snapshotQuery.isLoading} />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false} style={{ backgroundColor: '#e5e5e5' }}>
                <EuiStat description={'Audience with Multiple Views'} title={snapshotQuery.data?.totalWith2PlusViews.toLocaleString()} isLoading={snapshotQuery.isLoading} />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false} style={{ backgroundColor: '#e5e5e5' }}>
                <EuiStat
                  description={'Saturation'}
                  title={
                    <EuiTextColor>
                      {saturationScore.toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}
                      {'% '}
                      {saturationScore > 11 ? <EuiIcon size={'l'} type={'sortUp'} color={'success'} /> : saturationScore < 6 && <EuiIcon size={'l'} type={'sortDown'} color={'danger'} />}
                    </EuiTextColor>
                  }
                  isLoading={snapshotQuery.isLoading}
                />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false} style={{ backgroundColor: '#e5e5e5' }}>
                <EuiStat description={`Top City (${topCity?.impressions ? topCity.impressions.toLocaleString() : '...'} views)`} title={`${topCity?.name}`} isLoading={snapshotQuery.isLoading} titleSize={'s'} />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        <EuiSpacer />
        <div>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false}>
                <AdvertisingSnapshotCitiesPieChart snapshot={snapshotQuery.data} isLoading={snapshotQuery.isLoading} />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false}>
                <AdvertisingTargetingChart snapshot={snapshotQuery.data} isLoading={snapshotQuery.isLoading} />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false}>
                <ShowcaseDomainsChart snapshot={snapshotQuery.data} isLoading={snapshotQuery.isLoading} />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        <EuiSpacer />
        <div>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false}>
                <AccountCampaignsPacingChart account={props.account} />
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiPanel hasBorder={true} hasShadow={false}>
                <AdvertisingSnapshotReachChart account={props.account} saturationPercentage={saturationScore} />
              </EuiPanel>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}
