import { BillboardGeo, BillboardGeosInRange } from 'api/entities/Billboards'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const geoDataApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['BillboardGeosInRange'] })

const gepDataApi = geoDataApiTags.injectEndpoints({
  endpoints: build => ({
    getBillboardGeosInRange: build.query<BillboardGeo[], BillboardGeosInRange>({
      query: geosInRange => {
        return {
          url: `dictionary/billboardsInRange`,
          method: 'POST',
          body: geosInRange
        }
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, geosInRange) => [{ type: 'BillboardGeosInRange', id: `Lng:${geosInRange.lng} Lat:${geosInRange.lat} Range:${geosInRange.radiusMeters}` }]
    })
  })
})

export const { useLazyGetBillboardGeosInRangeQuery } = gepDataApi
