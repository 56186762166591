import moment from 'moment-timezone'
import React from 'react'

import { EuiButtonEmpty, EuiInMemoryTable } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { EuiInMemoryTableProps } from '@elastic/eui/src/components/basic_table/in_memory_table'

import { BundleActivation, BundleStatus } from 'api/entities/Bundle'
import { useGetBundleActivationsQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import history from 'services/HistoryService'

export const OnboardingPage: React.FC = () => {
  const bundleActivationsRequest = useGetBundleActivationsQuery({ ignoreAgencies: false })
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const bundleActivationData = !bundleActivationsRequest.data
    ? []
    : [...bundleActivationsRequest.data]
        ?.filter(b => b.bundleStatus === BundleStatus.Onboarding || b.bundleStatus === BundleStatus.PartiallyOnboarded)
        .sort((a, b) => {
          if (a.activated && b.activated) {
            return moment(b.activated).valueOf() - moment(a.activated).valueOf()
          } else if (b.activated) {
            return -1
          } else if (a.activated) {
            return 1
          } else {
            return 0
          }
        })

  const bundlePurchasesColumns: Array<EuiBasicTableColumn<BundleActivation>> = [
    {
      name: 'Organization',
      render: (p: BundleActivation) => (
        <EuiButtonEmpty onClick={() => history.push(`/customers/organizations/${p.organizationId}`)} size='s'>
          {p.organizationName}
        </EuiButtonEmpty>
      ),
      sortable: (b: BundleActivation) => b.organizationName
    },
    {
      name: 'Name',
      field: 'bundleName',
      sortable: true
    },
    {
      name: 'Status',
      field: 'bundleStatus',
      sortable: true
    },
    {
      name: `Budget`,
      render: (p: BundleActivation) => formatter.format(p.budget),
      width: '150',
      sortable: (b: BundleActivation) => b.budget
    },
    {
      name: `Budget Frequency`,
      field: 'budgetFrequency',
      sortable: true
    },
    {
      name: `Purchase Date`,
      render: (p: BundleActivation) => moment.utc(p.activated).utc().tz(moment.tz.guess()).format('L'),
      sortable: (p: BundleActivation) => moment(p.activated).valueOf()
    }
  ]

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100],
    sort: true
  }

  const sorting: EuiInMemoryTableProps<BundleActivation>['sorting'] = {
    sort: {
      field: 'activated',
      direction: 'desc'
    }
  }

  return (
    <React.Fragment>
      <EuiInMemoryTable sorting={sorting} loading={bundleActivationsRequest.isLoading} items={bundleActivationData} columns={bundlePurchasesColumns} pagination={pagination} />
    </React.Fragment>
  )
}
