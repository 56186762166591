import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { BusinessTypeCategory, OpsClient } from 'api'

export type BusinessTypeCategoriesState = {
  isLoadingBusinessTypeCategories: boolean
  businessTypeCategories: BusinessTypeCategory[]
}

const initialState: BusinessTypeCategoriesState = {
  isLoadingBusinessTypeCategories: false,
  businessTypeCategories: []
}

export const fetchBusinessTypeCategories = createAsyncThunk('data/businessTypeCategories', async (opsClient: OpsClient) => {
  return opsClient!.getBusinessTypeCategories()
})

const businessTypeCategoriesSlice = createSlice({
  name: 'businessTypeCategories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBusinessTypeCategories.pending, state => {
      state.isLoadingBusinessTypeCategories = true
    })
    builder.addCase(fetchBusinessTypeCategories.fulfilled, (state, action) => {
      state.isLoadingBusinessTypeCategories = false
      state.businessTypeCategories = action.payload || []
    })
    builder.addCase(fetchBusinessTypeCategories.rejected, state => {
      state.isLoadingBusinessTypeCategories = false
      state.businessTypeCategories = []
    })
  }
})

//export const {} = businessTypeCategoriesSlice.actions

export default businessTypeCategoriesSlice.reducer
