import React from 'react'

import { EuiTitle, EuiFlexItem, EuiFlexGroup, EuiFlyoutHeader, EuiFlyoutBody, EuiFlyoutFooter, EuiButtonEmpty, EuiFlyout } from '@elastic/eui'

import { Invoice } from 'api/entities/Invoice'
import InvoicePage from 'components/Invoices/InvoicePage'

interface InvoiceFlyoutParams {
  selectedInvoice: Invoice
  onFlyoutClose: () => void
}

const InvoiceFlyout: React.FC<InvoiceFlyoutParams> = ({ selectedInvoice, onFlyoutClose }) => {
  return (
    <EuiFlyout onClose={onFlyoutClose} size='l' aria-labelledby='flyoutLargeTitle' ownFocus hideCloseButton>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle>
          <h4>Invoice - {selectedInvoice?.number}</h4>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <InvoicePage invoice={selectedInvoice} />
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty id='close' iconType='cross' onClick={onFlyoutClose} flush='left'>
              Close
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )
}

export default InvoiceFlyout
