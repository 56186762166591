import moment, { Moment } from 'moment/moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiDatePicker, EuiDatePickerRange, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingContent, EuiSpacer, EuiSuperSelect } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { Campaign, useOpsClient } from 'api'

interface CampaignRawTrafficTabParams {
  campaign: Campaign
}
const CampaignRawTrafficTab: React.FC<CampaignRawTrafficTabParams> = ({ campaign }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [traffic, setTraffic] = useState<any>([])
  const [start, setStart] = useState<Moment>(moment().add(-7, 'd'))
  const [end, setEnd] = useState<Moment>(moment().add(0, 'd'))
  const [visibleColumns, setVisibleColumns] = useState(['forDate', 'impressions', 'clicks', 'spend', 'uniqueViewers'])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const opsClient = useOpsClient()
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && start && end) {
      setIsLoading(true)

      opsClient.getCampaignTraffic(campaign.campaignId, start, end).then(r => {
        if (isMounted) {
          setTraffic(r)
          setIsLoading(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, campaign, start, end])

  const columns: EuiDataGridColumn[] = [
    {
      id: 'forDate',
      displayAsText: 'Date',
      schema: 'date'
    },
    {
      id: 'impressions',
      displayAsText: 'Views',
      schema: 'numeric'
    },
    {
      id: 'clicks',
      displayAsText: 'Clicks',
      schema: 'numeric'
    },
    {
      id: 'spend',
      displayAsText: 'Spend',
      schema: 'currency'
    },
    {
      id: 'uniqueViewers',
      displayAsText: 'Unique Viewers',
      schema: 'numeric'
    }
  ]

  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )

  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const [sortingColumns, setSortingColumns] = useState([])
  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (traffic.hasOwnProperty(rowIndex)) {
        if (schema == 'currency') {
          return usdFormatter.format(traffic[rowIndex][columnId])
        }
        if (schema == 'numeric') {
          return traffic[rowIndex][columnId] ? traffic[rowIndex][columnId] + '' : ''
        }
        if (schema == 'date') {
          return moment(traffic[rowIndex][columnId]).format('l')
        }
        return traffic[rowIndex][columnId] ? traffic[rowIndex][columnId] : null
      }
    }
  }, [traffic])

  const dateRanges = [
    {
      value: 'last_7',
      inputDisplay: 'Last 7 Days'
    },
    {
      value: 'week',
      inputDisplay: 'This Week (so far)'
    },
    {
      value: 'last_30',
      inputDisplay: 'Last 30 Days'
    },
    {
      value: 'month',
      inputDisplay: 'This Month (so far)'
    },
    {
      value: 'last_90',
      inputDisplay: 'Last 90 Days'
    },
    {
      value: 'lifetime',
      inputDisplay: 'Lifetime'
    },
    {
      value: 'custom',
      inputDisplay: 'Custom'
    }
  ]
  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[0].value)
  const onDateRangeChange = (value: string) => {
    let today = moment()
    let end = moment()
    switch (value) {
      case 'last_7':
        setStart(today.add(-7, 'd'))
        setEnd(end)
        break

      case 'week':
        setStart(today.startOf('week'))
        setEnd(end)
        break

      case 'last_30':
        setStart(today.add(-31, 'd'))
        setEnd(end)
        break

      case 'month':
        setStart(today.startOf('month'))
        setEnd(end)
        break

      case 'last_90':
        setStart(today.add(-91, 'd'))
        setEnd(end)
        break

      case 'lifetime':
        setStart(today.add(-50, 'y'))
        setEnd(end)
        break
    }
    setSelectedDateRange(value)
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem>
          <EuiSuperSelect options={dateRanges} valueOfSelected={selectedDateRange} onChange={value => onDateRangeChange(value)} style={{ borderRadius: 0 }} />
        </EuiFlexItem>
        {selectedDateRange === 'custom' && (
          <EuiFlexItem>
            <EuiDatePickerRange
              fullWidth={false}
              startDateControl={<EuiDatePicker selected={start} onChange={(d: Moment) => setStart(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='Start date' showTimeSelect={false} className='whiteCalendarInput' />}
              endDateControl={<EuiDatePicker selected={end} onChange={(d: Moment) => setEnd(d)} startDate={start} endDate={end} isInvalid={start > end} aria-label='End date' showTimeSelect={false} className='whiteCalendarInput' />}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      <EuiSpacer />

      {isLoading && <EuiLoadingContent />}
      {!isLoading && (
        <EuiFlexGroup>
          <EuiFlexItem style={{ maxHeight: '600px' }}>
            <EuiDataGrid
              aria-label='Traffic'
              columns={columns}
              columnVisibility={{ visibleColumns, setVisibleColumns }}
              gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal' }}
              rowCount={traffic.length}
              renderCellValue={renderCellValue}
              inMemory={{ level: 'sorting' }}
              sorting={{ columns: sortingColumns, onSort }}
              pagination={{
                ...pagination,
                pageSizeOptions: [100, 200, 500],
                onChangeItemsPerPage,
                onChangePage
              }}
              toolbarVisibility={{
                additionalControls: (
                  <React.Fragment>
                    <CSVLink
                      data={traffic}
                      headers={columns.map(
                        c =>
                          ({
                            label: c.displayAsText,
                            key: c.id
                          } as LabelKeyObject)
                      )}
                      filename={`traffic.csv`}
                      target='_blank'
                      className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                      <span className='euiButtonContent euiButtonEmpty__content'>
                        <EuiIcon type='download' />
                        <span className='euiButton__text'>Download Report (CSV)</span>
                      </span>
                    </CSVLink>
                  </React.Fragment>
                )
              }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </React.Fragment>
  )
}
export default CampaignRawTrafficTab
