import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiBadge, EuiButtonIcon, EuiContextMenuItem, EuiContextMenuPanel, EuiFieldSearch, EuiInMemoryTable, EuiLink, EuiPageTemplate, EuiPopover, EuiSpacer, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Campaign, useOpsClient } from 'api'
import { AcDuplicateCampaignModal } from 'components/Modals/AcDuplicateCampaignModal'
import { IAcDictionary } from 'utils/AcDictionary'
import { getCampaignStatusReadable, insertSpaces } from 'utils/EnumToFriendly'
import { localTimestamp } from 'utils/dateUtils'
import useDebounce from 'utils/useDebounce'

const CampaignsPage: React.FC = () => {
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const opsClient = useOpsClient()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [popoverOpens, setPopoverOpens] = useState<IAcDictionary>({})
  const [duplicateOpens, setDuplicateOpens] = useState<IAcDictionary>({})
  const [refreshKey, setRefreshKey] = useState(0)
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && debouncedSearch) {
      setIsLoadingCampaigns(true)

      opsClient.getCampaigns(null, null, debouncedSearch).then(c => {
        if (isMounted) {
          setCampaigns(c)
          setIsLoadingCampaigns(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, debouncedSearch, refreshKey])

  const togglePopover = (campaignId: string) => {
    setPopoverOpens(popoverOpens => {
      popoverOpens[campaignId] = !popoverOpens[campaignId]
      return { ...popoverOpens }
    })
  }

  const toggleDuplicate = (campaignId: string) => {
    setDuplicateOpens(duplicateOpens => {
      duplicateOpens[campaignId] = !duplicateOpens[campaignId]
      return { ...duplicateOpens }
    })
  }

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/campaigns/${c.campaignId}`} target='_blank'>
          {c.campaignName}
          {c.bundleId && !c.isMatching ? (
            <>
              {' '}
              <EuiBadge color='default'>{insertSpaces(c.bundleType)}</EuiBadge>
            </>
          ) : (
            ''
          )}
          {c.isMatching ? (
            <>
              {' '}
              <EuiBadge color='success'>Matching</EuiBadge>
            </>
          ) : (
            ''
          )}
        </EuiLink>
      )
    },
    {
      name: 'Account',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/accounts/${c.accountId}`} target='_blank'>
          {c.accountName}
        </EuiLink>
      )
    },
    {
      name: 'Organization',
      render: (c: Campaign) => (
        <EuiLink href={`/customers/organizations/${c.organizationId}`} target='_blank'>
          {c.organizationName}
        </EuiLink>
      )
    },
    {
      name: `Campaign Type`,
      render: (c: Campaign) => c.campaignType,
      width: '130'
    },
    {
      name: `Targeting Type`,
      render: (c: Campaign) => c.targetingTemplate,
      width: '130'
    },
    {
      name: `Status`,
      render: (c: Campaign) => getCampaignStatusReadable(c),
      width: '130'
    },
    {
      name: `Budget`,
      render: (c: Campaign) => formatter.format(c.budget) + ' ' + c.budgetFrequency.toLowerCase(),
      sortable: (c: Campaign) => c.budget,
      width: '130'
    },
    {
      name: `Activation Date`,
      render: (c: Campaign) => <React.Fragment>{c.activated && localTimestamp(c.activated)}</React.Fragment>,
      sortable: (c: Campaign) => (c.activated ? moment(c.activated, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString() : null),
      width: '230'
    },
    {
      width: '50px',
      render: (c: Campaign) => (
        <React.Fragment>
          <EuiPopover button={<EuiButtonIcon aria-label='Actions' iconType='gear' size='s' color='text' onClick={() => togglePopover(c.campaignId)} />} isOpen={popoverOpens[c.campaignId]} closePopover={() => togglePopover(c.campaignId)} panelPaddingSize='none' anchorPosition='leftCenter'>
            <EuiContextMenuPanel
              items={[
                <EuiContextMenuItem
                  key='A'
                  icon='copy'
                  onClick={() => {
                    toggleDuplicate(c.campaignId)
                  }}>
                  Duplicate
                </EuiContextMenuItem>
              ]}
            />
          </EuiPopover>
          <AcDuplicateCampaignModal accountId={c.accountId} campaignId={c.campaignId} organizationId={c.organizationId} srcCampaignName={c.campaignName} isVisible={duplicateOpens[c.campaignId]} cancelModal={() => toggleDuplicate(c.campaignId)} finishDuplicating={() => setRefreshKey(k => k + 1)} />
        </React.Fragment>
      )
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'campaignName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='Campaigns' restrictWidth={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiFieldSearch fullWidth placeholder='Please start by searching for a campaign.' value={search} isLoading={isLoadingCampaigns} onChange={v => onSearchChange(v.target.value)} isClearable={true} incremental={true} />
        <EuiSpacer />
        {campaigns && campaigns.length > 0 && <EuiInMemoryTable loading={isLoadingCampaigns} items={campaigns} columns={columns} sorting={sorting} pagination={pagination} />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}
export default CampaignsPage
