import moment from 'moment-timezone'
import React from 'react'

import { EuiButtonEmpty, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiInMemoryTable, EuiSwitch } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { EuiInMemoryTableProps } from '@elastic/eui/src/components/basic_table/in_memory_table'

import { Bundle, BundleActiveStatus, BundleStatus, BundleType } from 'api/entities/Bundle'
import { useGetAllBundlesQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { BundleStatusFilterGroup } from 'features/advisorDashboard/components/BundleStatusFilterGroup'

export const ActivatedPlansPage: React.FC = () => {
  const allBundlesQuery = useGetAllBundlesQuery()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [filteredBundles, setFilteredBundles] = React.useState<Bundle[]>([])
  const [searchText, setSearchText] = React.useState('')
  const [showInactive, setShowInactive] = React.useState(false)
  const [statusTypeFilters, setStatusTypeFilters] = React.useState<BundleStatus[]>([])
  const [filteredWithStatus, setFilteredWithStatus] = React.useState<Bundle[]>([])

  React.useEffect(() => {
    if (allBundlesQuery.data) {
      const bundles = [...allBundlesQuery.data]
        .filter(b => showInactive || b.bundleActiveStatus === BundleActiveStatus.Active)
        .filter(b => moment(b.billingSchedule.startDate).isValid())
        .filter(b => b.bundleType === BundleType.Plan)
        .filter(b => b.name?.toLowerCase().includes(searchText.toLowerCase()) || b.organizationId.toLowerCase().includes(searchText.toLowerCase()))
      setFilteredBundles(bundles)

      setFilteredWithStatus(bundles.filter(b => statusTypeFilters.includes(b.bundleStatus) || statusTypeFilters.length === 0))
    }
  }, [allBundlesQuery.data, searchText, showInactive, statusTypeFilters])

  const openLink = (url: string) => {
    window.open(url, '_blank')
  }

  const bundleColumns: Array<EuiBasicTableColumn<Bundle>> = [
    {
      name: 'Name',
      render: (b: Bundle) => (
        <EuiButtonEmpty onClick={() => openLink(`/customers/organizations/${b.organizationId}`)} size='s'>
          {b.name ?? b.organizationId}
        </EuiButtonEmpty>
      ),
      sortable: b => b.name
    },
    {
      name: 'Status',
      field: 'bundleStatus',
      sortable: true
    },
    {
      name: `Price`,
      render: (p: Bundle) => formatter.format(p.price),
      sortable: (b: Bundle) => b.price
    },
    {
      name: `Budget Frequency`,
      render: (p: Bundle) => `${p.billingSchedule.intervalUnitFrequency} ${p.billingSchedule.intervalUnit}`
    },
    {
      name: `Start Date`,
      render: (p: Bundle) => moment.utc(p.billingSchedule.startDate).utc().tz(moment.tz.guess()).format('L'),
      sortable: (p: Bundle) => moment(p.billingSchedule.startDate).valueOf()
    },
    {
      name: `Next Bill Date`,
      render: (p: Bundle) => moment.utc(p.billingSchedule.nextEventDate).utc().tz(moment.tz.guess()).format('L'),
      sortable: (p: Bundle) => moment(p.billingSchedule.nextEventDate).valueOf()
    }
  ]

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100],
    sort: true
  }

  const sorting: EuiInMemoryTableProps<Bundle>['sorting'] = {
    sort: {
      field: 'Start Date',

      direction: 'desc'
    }
  }

  const OnStatusTypeFilterClick = (status: BundleStatus) => {
    if (statusTypeFilters.includes(status)) {
      setStatusTypeFilters(statusTypeFilters.filter(f => f !== status))
    } else {
      setStatusTypeFilters([...statusTypeFilters, status])
    }
  }

  const OnResetStatusTypeFilters = () => {
    setStatusTypeFilters([])
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <EuiFlexGroup gutterSize={'m'}>
          <EuiFlexItem grow={false}>
            <EuiFieldSearch placeholder={'Search'} aria-label={'Package Search'} value={searchText} onChange={e => setSearchText(e.target.value)} />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ paddingTop: 6 }}>
            <EuiSwitch checked={showInactive} onChange={() => setShowInactive(!showInactive)} label={'Show Inactive'} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <BundleStatusFilterGroup bundleStatusItems={filteredBundles} activeFilters={statusTypeFilters} onFilterClicked={OnStatusTypeFilterClick} onResetFilters={OnResetStatusTypeFilters} />
      </EuiFormRow>
      <EuiInMemoryTable sorting={sorting} loading={allBundlesQuery.isLoading} items={filteredWithStatus} columns={bundleColumns} pagination={pagination} />
    </React.Fragment>
  )
}
