import React from 'react'

import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { DefaultSpacerForTabbedContent } from 'features/accountManagerDashboard/AccountManagerDashboardTabs'
import { SelfServicePackageTemplatesPage } from 'features/templates/SelfServicePackageTemplatesPage'

export const useTemplatesTabs = () => {
  const [tabs] = React.useState<EuiTabbedContentTab[]>([
    {
      id: 'selfServePackages',
      name: `Self Serve Packages`,
      content: (
        <React.Fragment>
          <DefaultSpacerForTabbedContent />
          <SelfServicePackageTemplatesPage />
        </React.Fragment>
      )
    }
  ])

  return tabs
}
