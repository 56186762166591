import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiSpacer, SortDirection, EuiInMemoryTable, EuiLink, EuiPopover, EuiButtonIcon, EuiContextMenuPanel, EuiContextMenuItem, EuiBadge } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Account, Campaign, useOpsClient } from 'api'
import { AcDuplicateCampaignModal } from 'components/Modals/AcDuplicateCampaignModal'
import history from 'services/HistoryService'
import { IAcDictionary } from 'utils/AcDictionary'
import { getCampaignStatusReadable, insertSpaces } from 'utils/EnumToFriendly'
import { localTimestamp } from 'utils/dateUtils'

interface AccountCampaignsTabParams {
  account: Account
}

const AccountCampaignsTab: React.FC<AccountCampaignsTabParams> = ({ account }) => {
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const opsClient = useOpsClient()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [popoverOpens, setPopoverOpens] = useState<IAcDictionary>({})
  const [duplicateOpens, setDuplicateOpens] = useState<IAcDictionary>({})
  const [refreshKey, setRefreshKey] = useState(0)

  const togglePopover = (campaignId: string) => {
    setPopoverOpens(popoverOpens => {
      popoverOpens[campaignId] = !popoverOpens[campaignId]
      return { ...popoverOpens }
    })
  }

  const toggleDuplicate = (campaignId: string) => {
    setDuplicateOpens(duplicateOpens => {
      duplicateOpens[campaignId] = !duplicateOpens[campaignId]
      return { ...duplicateOpens }
    })
  }

  useEffect(() => {
    let isMounted = true
    setIsLoadingCampaigns(true)
    if (opsClient && isMounted) {
      opsClient.getCampaigns(null, account.accountId).then(c => {
        if (isMounted) {
          setCampaigns(c)
          setIsLoadingCampaigns(false)
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [opsClient, account, refreshKey])

  const columns: Array<EuiBasicTableColumn<Campaign>> = [
    {
      name: 'Campaign',
      render: (c: Campaign) => (
        <>
          <EuiLink onClick={() => history.push(`/customers/campaigns/${c.campaignId}`)}>{c.campaignName}</EuiLink> {c.bundleId && !c.isMatching ? <EuiBadge color='default'>{insertSpaces(c.bundleType)}</EuiBadge> : ''}
          {c.isMatching ? <EuiBadge color='success'>Matching</EuiBadge> : ''}
        </>
      )
    },
    {
      name: `Campaign Type`,
      render: (c: Campaign) => c.campaignType
    },
    {
      name: `Status`,
      render: (c: Campaign) => getCampaignStatusReadable(c)
    },
    {
      name: `Budget`,
      render: (c: Campaign) => formatter.format(c.budget) + ' ' + c.budgetFrequency,
      sortable: (c: Campaign) => c.budget
    },
    {
      name: `Activation Date`,
      render: (c: Campaign) => <React.Fragment>{c.activated && localTimestamp(c.activated)}</React.Fragment>,
      sortable: (c: Campaign) => (c.activated ? moment(c.activated, 'YYYY-MM-DD, h:mm:ss').toDate().toISOString() : null),
      width: '230'
    },
    {
      width: '50px',
      render: (c: Campaign) => (
        <React.Fragment>
          <EuiPopover button={<EuiButtonIcon aria-label='Actions' iconType='gear' size='s' color='text' onClick={() => togglePopover(c.campaignId)} />} isOpen={popoverOpens[c.campaignId]} closePopover={() => togglePopover(c.campaignId)} panelPaddingSize='none' anchorPosition='leftCenter'>
            <EuiContextMenuPanel
              items={[
                <EuiContextMenuItem
                  key='c.campaignId'
                  icon='copy'
                  onClick={() => {
                    toggleDuplicate(c.campaignId)
                  }}>
                  Duplicate
                </EuiContextMenuItem>
              ]}
            />
          </EuiPopover>
          <AcDuplicateCampaignModal accountId={c.accountId} campaignId={c.campaignId} organizationId={c.organizationId} srcCampaignName={c.campaignName} isVisible={duplicateOpens[c.campaignId]} cancelModal={() => toggleDuplicate(c.campaignId)} finishDuplicating={() => setRefreshKey(k => k + 1)} />
        </React.Fragment>
      )
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'campaignName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiInMemoryTable loading={isLoadingCampaigns} items={campaigns} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}

export default AccountCampaignsTab
