import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import '@elastic/charts/dist/theme_light.css'

import config from 'app/config'
import store from 'app/store'
import history from 'services/HistoryService'

const stripePromise = loadStripe(config.stripe.PUBLISHABLEKEY)

function render() {
  const App = require('./app/App').default
  ReactDOM.render(
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Router history={history}>
          <App />
        </Router>
      </Elements>
    </Provider>,
    document.getElementById('🌎')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render)
}
