import numeral from 'numeral'
import React, { useCallback, useEffect, useState } from 'react'

import { EuiBadge, EuiFieldSearch, EuiHighlight, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { ThirdPartyAudience, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface TvAudienceTargeterProps {
  isInvalid: boolean

  onAudienceClicked(audience: ThirdPartyAudience): void
}

export const TvAudienceFinder = ({ onAudienceClicked, isInvalid }: TvAudienceTargeterProps) => {
  const [audienceSearchTerm, setAudienceSearchTerm] = useState<string>('')
  const debouncedAudienceSearchTerm = useDebounce(audienceSearchTerm, 750)
  const [searchAudiencePopoverOpen, setSearchAudiencePopoverOpen] = useState<boolean>(false)
  const [audiences, setAudiences] = useState<Array<ThirdPartyAudience>>([])
  const [selectableAudiences, setSelectableAudiences] = useState<Array<EuiSelectableOption>>([])
  const [isLoadingTvAudiences, setIsLoadingTvAudiences] = useState<boolean>(false)
  const opsClient = useOpsClient()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const onSearchAudience = () => {
    setSearchAudiencePopoverOpen(false)
  }

  const onSearchAudienceChange = (hint: string) => {
    setAudienceSearchTerm(hint)
  }

  const onSearchAudienceClick = useCallback(
    (audience: ThirdPartyAudience) => {
      setSearchAudiencePopoverOpen(false)
      setAudienceSearchTerm('')
      onAudienceClicked(audience)
    },
    [onAudienceClicked]
  )

  useEffect(() => {
    if (debouncedAudienceSearchTerm && opsClient) {
      setIsLoadingTvAudiences(true)
      opsClient.apiGetDataTvAudiences(debouncedAudienceSearchTerm).then(r => {
        setAudiences(r.data)
        setIsLoadingTvAudiences(false)
      })
    } else {
      setAudiences([])
    }
  }, [debouncedAudienceSearchTerm, opsClient])

  useEffect(() => {
    if (audiences) {
      let copyOfAudiences = audiences.map(
        a =>
          ({
            label: a.name,
            key: a.id.toString(),
            checked: undefined,
            append: (
              <>
                <EuiBadge>{a.reachCount > 0 ? numeral(a.reachCount).format('0a') : '~'} people</EuiBadge>
                <EuiBadge color='hollow'>{formatter.format(a.price)}</EuiBadge>
              </>
            ),
            onClick: () => onSearchAudienceClick({ id: a.id, name: a.name, hierarchy: a.hierarchy, description: a.description, reachCount: a.reachCount, provider: a.provider, price: a.price, sourceDetails: a.sourceDetails })
          } as EuiSelectableOption)
      )
      setSelectableAudiences(copyOfAudiences)
    }
  }, [audiences, onSearchAudienceClick])

  const renderAudienceOption = (option: EuiSelectableOption) => {
    const audience = audiences.find(a => a.id.toString() === option.key)
    if (!audience) return <></>

    return (
      <React.Fragment>
        <EuiHighlight search={audienceSearchTerm}>{option.label}</EuiHighlight>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={audienceSearchTerm}>{audience.description}</EuiHighlight>
        </EuiText>
      </React.Fragment>
    )
  }

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={audienceSearchTerm}
          isLoading={isLoadingTvAudiences}
          onChange={v => onSearchAudienceChange(v.target.value)}
          onFocus={() => setSearchAudiencePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchAudience()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchAudiencePopoverOpen}
      closePopover={() => setSearchAudiencePopoverOpen(false)}>
      {audiences && audiences.length > 0 ? (
        <EuiSelectable singleSelection options={selectableAudiences} renderOption={renderAudienceOption} listProps={{ rowHeight: 50, isVirtualized: true, showIcons: false }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
