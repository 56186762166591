import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiButtonEmpty, EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiText, EuiTextArea, EuiToolTip } from '@elastic/eui'

import { AnchorPhrase, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface AnchorPhraseDetailsTabParams {
  anchorPhraseId: string
}

interface FormValues {
  name: string
  description: string | null
  values: string[]
}

const AnchorPhraseDetailsTab: React.FC<AnchorPhraseDetailsTabParams> = ({ anchorPhraseId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [anchorPhrase, setAnchorPhrase] = useState<AnchorPhrase>()
  const [initial, setInitial] = useState<FormValues>({ name: '', description: '', values: [''] })
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getAnchorPhrase(anchorPhraseId).then(result => {
        setInitial({ name: result.name, description: result.description, values: result.values })
        setAnchorPhrase(result)
        setIsLoading(false)
      })
    }
  }, [opsClient, anchorPhraseId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const anchorPhraseSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the anchor phrase name'),
    description: Yup.string().nullable(),
    values: Yup.array().min(1).of(Yup.string().required('Please enter a value'))
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient
      ?.updateAnchorPhrase(anchorPhraseId, {
        name: values.name,
        description: values.description,
        values: values.values
      })
      .then(result => {
        setSubmitting(false)
        history.push(`/data/anchorPhrases/${result.id}`)
      })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={initial} enableReinitialize validationSchema={anchorPhraseSchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
              <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
            </EuiFormRow>
            <EuiFormRow label='Code'>
              <EuiText size='s' color='accent'>
                <strong>
                  {'{'}
                  {anchorPhrase?.code}
                  {'}'}
                </strong>
              </EuiText>
            </EuiFormRow>
            <EuiFormRow label='Description' isInvalid={!!props.errors.description} error={props.errors.description}>
              <EuiTextArea name='description' value={props.values.description ?? ''} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.description} />
            </EuiFormRow>
            <EuiSpacer size='s' />
            <FieldArray
              name='values'
              render={arrayHelpers => (
                <React.Fragment>
                  {props.values.values &&
                    props.values.values.length > 0 &&
                    props.values.values.map((value, index) => (
                      <EuiFlexGroup gutterSize='xs' key={index}>
                        <EuiFlexItem grow={false}>
                          <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={false}>
                              <EuiToolTip position='top' content='Remove this value'>
                                <EuiButtonIcon id='removeValue' iconType='cross' aria-label='remove' color='warning' onClick={() => arrayHelpers.remove(index)} />
                              </EuiToolTip>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiFormRow label={index === 0 ? 'Values' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `values[${index}]`) && !!getIn(props.errors, `values[${index}]`)} error={getIn(props.errors, `values[${index}]`)}>
                            <EuiFieldText name={`values[${index}]`} value={getIn(props.values, `values[${index}]`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `phrases[${index}]`) && !!getIn(props.errors, `values[${index}]`)} />
                          </EuiFormRow>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ))}
                  <EuiSpacer size='s' />
                  <EuiButtonEmpty id='addValue' size='s' onClick={() => arrayHelpers.push('')} isDisabled={!canUpdate}>
                    Add {props.values.values.length > 0 && 'Another'} Value
                  </EuiButtonEmpty>
                </React.Fragment>
              )}
            />
            <EuiSpacer />
            <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
              Save
            </EuiButton>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default AnchorPhraseDetailsTab
