import { PerformanceDashboardPlan, PerformanceDashboardPackage, CampaignPerformance } from 'api/entities/PerformanceDashboard'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const performanceDashboardTags = opsApi.enhanceEndpoints({ addTagTypes: ['PerformanceDashboardPlans', 'PerformanceDashboardPackages', 'PerformanceDashboardCampaigns', 'PerformanceDashboardCampaignsToMigrate'] })

const performanceDashboardApi = performanceDashboardTags.injectEndpoints({
  endpoints: build => ({
    getPerformanceDashboardPlans: build.query<PerformanceDashboardPlan[], void>({
      query: () => `performance/plan`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'PerformanceDashboardPlans' }]
    }),
    getPerformanceDashboardPackages: build.query<PerformanceDashboardPackage[], void>({
      query: () => `performance/package`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'PerformanceDashboardPackages' }]
    }),
    getPerformanceDashboardCampaigns: build.query<CampaignPerformance[], void>({
      query: () => `performance/campaign`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'PerformanceDashboardCampaigns' }]
    }),
    getPerformanceDashboardCampaignsToMigrate: build.query<CampaignPerformance[], void>({
      query: () => `performance/campaign/toMigrate`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'PerformanceDashboardCampaignsToMigrate' }]
    })
  }),
  overrideExisting: false
})

export const { useGetPerformanceDashboardPlansQuery, useGetPerformanceDashboardPackagesQuery, useGetPerformanceDashboardCampaignsQuery, useGetPerformanceDashboardCampaignsToMigrateQuery } = performanceDashboardApi
