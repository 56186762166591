import React, { FC, useEffect, useState } from 'react'

import { EuiLoadingSpinner, EuiTabbedContent } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useSalesManagerTabs } from 'features/salesManagerDashboard/definitions/useSalesManagerTabs'
import history from 'services/HistoryService'

export interface SalesManagerTabsProps {
  currentTab: string
}

export const SalesManagerTabs: FC<SalesManagerTabsProps> = ({ currentTab }) => {
  const tabs = useSalesManagerTabs()
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs.filter(t => t.id === currentTab)[0])

  useEffect(() => {
    setSelectedTab(tabs.filter(t => t.id === currentTab)[0])
  })

  const onTabClick = (selectedTab: EuiTabbedContentTab) => {
    history.push(`/sales-manager/dashboard/${selectedTab.id}`)
  }

  if (tabs.length === 0) {
    return <EuiLoadingSpinner size='xl' />
  }
  return <EuiTabbedContent tabs={tabs} selectedTab={selectedTab} onTabClick={onTabClick} />
}
