import { BundleCategory, TargetingPriority } from 'api/entities/Bundle'

export interface EnumProperties {
  key: string
  primaryColor: string
  disabledColor: string
  text: string
  iconType: string
  abbreviation: string
}

export const GetBundleCategoryProperties = (bundleCategory: BundleCategory): EnumProperties => {
  switch (bundleCategory) {
    case BundleCategory.Regular:
      return {
        key: 'Regular',
        primaryColor: '#7AC2F5',
        disabledColor: '#CBDAF2',
        text: 'Regular',
        iconType: 'launch',
        abbreviation: 'R'
      }
    case BundleCategory.LeadGeneration:
      return {
        key: 'LeadGeneration',
        primaryColor: '#AED9E0',
        disabledColor: '#E1F1F4',
        text: 'Lead Generation',
        iconType: 'reporter',
        abbreviation: 'LG'
      }
    case BundleCategory.Event:
      return {
        key: 'Event',
        primaryColor: '#F58790',
        disabledColor: '#F2C4BF',
        text: 'Event',
        iconType: 'calendar',
        abbreviation: 'E'
      }
    case BundleCategory.ShortTerm:
      return {
        key: 'ShortTerm',
        primaryColor: '#F5B85F',
        disabledColor: '#F2EADA',
        text: 'Short Term',
        iconType: 'clock',
        abbreviation: 'ST'
      }
    case BundleCategory.Political:
      return {
        key: 'Political',
        primaryColor: '#F4F262',
        disabledColor: '#EFF2E4',
        text: 'Political',
        iconType: 'flag',
        abbreviation: 'P'
      }
    default:
      return {
        key: 'Regular',
        primaryColor: '#7AC2F5',
        disabledColor: '#CBDAF2',
        text: 'Regular',
        iconType: 'launch',
        abbreviation: 'R'
      }
  }
}

export const GetTargetingPriorityProperties = (targetingPriority: TargetingPriority): EnumProperties => {
  switch (targetingPriority) {
    case TargetingPriority.Location:
      return {
        key: 'Location',
        primaryColor: '#B266F5',
        disabledColor: '#EAD2F4',
        text: 'Location',
        iconType: 'mapMarker',
        abbreviation: 'L'
      }
    case TargetingPriority.AudienceSegment:
      return {
        key: 'AudienceSegment',
        primaryColor: '#8CF5AE',
        disabledColor: '#AEF5D1',
        text: 'Audience Segment',
        iconType: 'users',
        abbreviation: 'AS'
      }
    default:
      return {
        key: 'Location',
        primaryColor: '#B266F5',
        disabledColor: '#EAD2F4',
        text: 'Location',
        iconType: 'mapMarker',
        abbreviation: 'L'
      }
  }
}
