import React, { FC, useEffect, useState } from 'react'

import { EuiSpacer, EuiTabbedContent } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useAdvisorDashboardTabs } from 'features/advisorDashboard/definitions/useAdvisorDashboardTabs'
import history from 'services/HistoryService'

export const DefaultSpacerForTabbedContent = () => <EuiSpacer size='xxl' />

export interface AdvisorTabsProps {
  currentTab: string
}

const AdvisorDashboardTabs: FC<AdvisorTabsProps> = ({ currentTab }) => {
  const tabs = useAdvisorDashboardTabs()
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs.current.filter(t => t.id === currentTab)[0])

  useEffect(() => {
    setSelectedTab(tabs.current.filter(t => t.id === currentTab)[0])
  }, [currentTab])

  const onTabClick = (selectedTab: EuiTabbedContentTab) => {
    history.push(`/advisor/dashboard/${selectedTab.id}`)
  }

  return <EuiTabbedContent tabs={tabs.current} selectedTab={selectedTab} onTabClick={onTabClick} />
}

export default AdvisorDashboardTabs
