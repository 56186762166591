import { ActiveCampaignsByDayAndOrganizationType } from 'api/entities/CampaignExecutiveDashboardByOrganizationType'
import { opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

export interface IGetExecutiveDashboardCampaigns {
  ignoreCache: boolean
  pastXDays?: number
  query?: string
}

const transformFn = (response: { data: ActiveCampaignsByDayAndOrganizationType }) => {
  return response.data
}

const executiveDashboardTags = opsApi.enhanceEndpoints({ addTagTypes: ['TotalActiveExecutiveDashboardCampaigns', 'ActiveExecutiveDashboardCampaigns', 'InactiveExecutiveDashboardCampaigns', 'EndedExecutiveDashboardCampaigns'] })

const executiveDashboardApi = executiveDashboardTags.injectEndpoints({
  endpoints: build => ({
    getTotalActiveExecutiveDashboardCampaignsByDay: build.query<ActiveCampaignsByDayAndOrganizationType, IGetExecutiveDashboardCampaigns>({
      query: ({ ignoreCache, pastXDays, query }) => {
        let url = `executiveDashboard/totalActiveCampaignsByDay?ignoreCache=${ignoreCache}`

        if (query) url += `&query=${query}`

        if (pastXDays) url += `&pastXDays=${pastXDays}`

        return url
      },
      transformResponse: transformFn,
      providesTags: (result, error, { pastXDays }) => [{ type: 'TotalActiveExecutiveDashboardCampaigns', id: pastXDays }]
    }),
    getActiveExecutiveDashboardCampaignsByDay: build.query<ActiveCampaignsByDayAndOrganizationType, IGetExecutiveDashboardCampaigns>({
      query: ({ ignoreCache, pastXDays, query }) => {
        let url = `executiveDashboard/activeCampaignsByDay?ignoreCache=${ignoreCache}`

        if (query) url += `&query=${query}`

        if (pastXDays) url += `&pastXDays=${pastXDays}`

        return url
      },
      transformResponse: transformFn,
      providesTags: (result, error, { pastXDays }) => [{ type: 'ActiveExecutiveDashboardCampaigns', id: pastXDays }]
    }),
    getInactiveExecutiveDashboardCampaignsByDay: build.query<ActiveCampaignsByDayAndOrganizationType, IGetExecutiveDashboardCampaigns>({
      query: ({ ignoreCache, pastXDays, query }) => {
        let url = `executiveDashboard/inactiveCampaignsByDay?ignoreCache=${ignoreCache}`

        if (query) url += `&query=${query}`

        if (pastXDays) url += `&pastXDays=${pastXDays}`

        return url
      },
      transformResponse: transformFn,
      providesTags: (result, error, { pastXDays }) => [{ type: 'InactiveExecutiveDashboardCampaigns', id: pastXDays }]
    }),
    getEndedExecutiveDashboardCampaignsByDay: build.query<ActiveCampaignsByDayAndOrganizationType, IGetExecutiveDashboardCampaigns>({
      query: ({ ignoreCache, pastXDays, query }) => {
        let url = `executiveDashboard/endedCampaignsByDay?ignoreCache=${ignoreCache}`

        if (query) url += `&query=${query}`

        if (pastXDays) url += `&pastXDays=${pastXDays}`

        return url
      },
      transformResponse: transformFn,
      providesTags: (result, error, { pastXDays }) => [{ type: 'EndedExecutiveDashboardCampaigns', id: pastXDays }]
    })
  }),
  overrideExisting: false
})

export const { useGetTotalActiveExecutiveDashboardCampaignsByDayQuery, useGetActiveExecutiveDashboardCampaignsByDayQuery, useGetInactiveExecutiveDashboardCampaignsByDayQuery, useGetEndedExecutiveDashboardCampaignsByDayQuery } = executiveDashboardApi
