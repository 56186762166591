import { KonvaEventObject } from 'konva/lib/Node'
import React from 'react'
import { Text } from 'react-konva'

export interface IACText {
  fontFamily?: string
  fontSize?: number
  text?: string
  fill?: string
  height?: number
  width?: number
  x?: number
  y?: number
  draggable?: boolean
  onDragStart?: (event: KonvaEventObject<DragEvent>) => void
  onDragEnd?: (event: KonvaEventObject<DragEvent>) => void
  onClick?: () => void
  onTap?: () => void
  getShapeRef?: (ref: React.MutableRefObject<any>) => void
  onTransformEnd?: (event: KonvaEventObject<DragEvent>) => void
  onChange?: (i: {}) => void
  name?: string
  shapeRef?: React.RefObject<any>
  align?: string
}

export const ACText: React.FC<IACText> = props => {
  return <Text x={props.x} y={props.y} ref={props.shapeRef} onDragStart={props.onDragStart} onDragEnd={props.onDragEnd} onClick={props.onClick} onTap={props.onTap} getShapeRef={props.getShapeRef} onTransformEnd={props.onTransformEnd} draggable={props.draggable} text={props.text} fontSize={props.fontSize} fill={props.fill} fontFamily={props.fontFamily} name={props.name} onChange={props.onChange} height={props.height} width={props.width} align={props.align} />
}
