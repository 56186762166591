import { useState } from 'react'

export const useDownloadUrl = (url?: string): [(fileName: string) => void, boolean] => {
  const [isLoading, setIsLoading] = useState(false)

  const downloadUrl = (fileName: string) => {
    if (!url) {
      return
    }
    setIsLoading(true)
    fetch(url, {
      method: 'GET',
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return [downloadUrl, isLoading]
}
