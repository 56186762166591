import { Moment } from 'moment'
import moment from 'moment/moment'
import React, { useEffect } from 'react'

import { AreaSeries, Axis, Chart, PartialTheme, Settings } from '@elastic/charts'
import { EuiFormRow, EuiHealth, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Account, CampaignTraffic, useOpsClient } from 'api'
import { useGetActiveCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useGetAccountCampaignTargeting } from 'utils/useGetAccountCampaignTargeting'

export interface IAdvertisingSnapshotReachChart {
  account: Account
  saturationPercentage: number
}

interface IReachData {
  forDate: Moment
  dayOfWeek: string
  impressions: number
  clicks: number
}

export interface IStatus {
  key: string
  name: string
  severity: number
}
export const AdvertisingSnapshotReachChart: React.FC<IAdvertisingSnapshotReachChart> = props => {
  const campaignsQuery = useGetActiveCampaignsQuery({ accountId: props.account.accountId })
  const accountTargeting = useGetAccountCampaignTargeting(props.account.accountId)
  const opsClient = useOpsClient()
  const [twoWeekReach, setTwoWeekReach] = React.useState<IReachData[]>([])
  const [allCampaignTraffic, setAllCampaignTraffic] = React.useState<CampaignTraffic[][]>([])
  const [trafficOutliers, setTrafficOutliers] = React.useState<number>(0)
  const [statuses, setStatuses] = React.useState<IStatus[]>([])

  const themeOverrides: PartialTheme = {
    partition: { outerSizeRatio: 1 }
  }

  useEffect(() => {
    if (campaignsQuery.data && opsClient) {
      const startOfLastWeek = moment().add(-14, 'days')
      campaignsQuery.data.forEach(campaign => {
        opsClient.getCampaignTraffic(campaign.campaignId, startOfLastWeek, moment()).then(traffic => {
          setAllCampaignTraffic([...allCampaignTraffic, traffic])
        })
      })
    }
  }, [campaignsQuery.data, opsClient])

  useEffect(() => {
    if (allCampaignTraffic.length === 0) {
      return
    }
    const getDefaultReachData = () => {
      const reachData: IReachData[] = []
      for (let i = 13; i >= 0; i--) {
        reachData.push({
          forDate: moment().add(-i, 'days'),
          dayOfWeek: moment().add(-i, 'days').format('ddd'),
          impressions: 0,
          clicks: 0
        })
      }
      return reachData
    }
    const reachData = twoWeekReach.length > 0 ? [...twoWeekReach] : getDefaultReachData()
    allCampaignTraffic.forEach(traffic => {
      traffic.forEach(t => {
        const existing = reachData.find(r => r.forDate.isSame(t.forDate, 'day'))
        if (existing) {
          existing.impressions += t.impressions
          existing.clicks += t.clicks
        }
      })
    })
    while (reachData[0]?.impressions === 0) {
      reachData.shift()
    }
    setTwoWeekReach(reachData)
  }, [allCampaignTraffic])

  useEffect(() => {
    if (twoWeekReach.length > 0) {
      const impressions = twoWeekReach.map(r => r.impressions)
      let lastImpression = impressions[0]
      let outliers = 0
      for (let i = 1; i < impressions.length; i++) {
        if (impressions[i] > lastImpression * 1.4 || impressions[i] < lastImpression * 0.6) {
          outliers++
        }
        lastImpression = impressions[i]
      }
      setTrafficOutliers(outliers)
    }
  }, [twoWeekReach])

  useEffect(() => {
    const stats = [...statuses]
    if (trafficOutliers > 4) {
      setStatuses([...stats.filter(s => s.key !== 'traffic'), { key: 'traffic', name: 'Surprising Traffic Patterns (this could be good if launching new campaigns)', severity: 2 }])
    } else if (trafficOutliers > 2) {
      setStatuses([...stats.filter(s => s.key !== 'traffic'), { key: 'traffic', name: 'Minor Traffic Deviations (but nothing to worry about)', severity: 2 }])
    } else {
      setStatuses([...stats.filter(s => s.key !== 'traffic'), { key: 'traffic', name: 'Receiving consistent Traffic', severity: 1 }])
    }
  }, [trafficOutliers])

  useEffect(() => {
    const stats = [...statuses]
    const totalGeoTargets = (accountTargeting.locations.geoCircles?.length ?? 0) + (accountTargeting.locations.geoRectangles?.length ?? 0) + (accountTargeting.locations.geoPolygons?.length ?? 0) + (accountTargeting.locations.geoFences?.length ?? 0)
    const hasLocations = totalGeoTargets > 0 || accountTargeting.locations.countries.length > 0 || accountTargeting.locations.regions.length > 0 || accountTargeting.locations.dmas.length > 0 || accountTargeting.locations.cities.length > 0 || accountTargeting.locations.postalCodes.length > 0
    const hasSegments = accountTargeting.segmentIds.length > 0
    const hasDemographics = accountTargeting.demographics.genders.length > 0 || accountTargeting.demographics.ageRanges.length > 0 || accountTargeting.demographics.householdIncomes.length > 0
    const hasSmartTargeting = accountTargeting.smartTargetings.length > 0
    if (hasSmartTargeting || (hasLocations && (hasSegments || hasDemographics))) {
      setStatuses([...stats.filter(s => s.key !== 'definedAudience'), { key: 'definedAudience', name: 'Well Defined Audience', severity: 1 }])
    } else if (hasLocations) {
      setStatuses([...stats.filter(s => s.key !== 'definedAudience'), { key: 'definedAudience', name: 'Segments will help ensure the right people are seeing your Ads', severity: 2 }])
    } else if (hasSegments) {
      setStatuses([...stats.filter(s => s.key !== 'definedAudience'), { key: 'definedAudience', name: 'Choosing specific Locations will help with attribution', severity: 2 }])
    } else if (hasDemographics) {
      setStatuses([...stats.filter(s => s.key !== 'definedAudience'), { key: 'definedAudience', name: 'Choosing specific Locations will help with attribution', severity: 2 }])
    } else {
      setStatuses([...stats.filter(s => s.key !== 'definedAudience'), { key: 'definedAudience', name: 'Using Locations and Segments will hone in on your ideal Customer ', severity: 3 }])
    }
  }, [accountTargeting])

  useEffect(() => {
    if (!campaignsQuery.data) {
      return
    }
    let stats = [...statuses]
    const hasMultipleCreatives = campaignsQuery.data?.some(c => (c.creativeIds?.length ?? 0) > 1) || campaignsQuery.data?.length > 2
    if (hasMultipleCreatives) {
      stats = [...stats.filter(s => s.key !== 'creativeVariety'), { key: 'creativeVariety', name: 'Excellent Creative Variety', severity: 1 }]
    } else {
      stats = [...stats.filter(s => s.key !== 'creativeVariety'), { key: 'creativeVariety', name: 'Campaigns can support multiple ads, reach out to us, or add a few of your own', severity: 2 }]
    }

    const hasBillboard = campaignsQuery.data?.some(c => c.campaignType === 'Billboard')
    const hasTV = campaignsQuery.data?.some(c => c.campaignType === 'TV')
    const hasInternet = campaignsQuery.data?.some(c => c.campaignType === 'Internet')
    if ((hasInternet && hasTV) || (hasInternet && hasBillboard) || (hasTV && hasBillboard)) {
      stats = [...stats.filter(s => s.key !== 'channels'), { key: 'channels', name: 'Well Placed Ads Across Multiple Channels', severity: 1 }]
    } else if (hasInternet) {
      stats = [...stats.filter(s => s.key !== 'channels'), { key: 'channels', name: 'Add a TV or Billboard campaign to increase credibility', severity: 3 }]
    } else {
      stats = [...stats.filter(s => s.key !== 'channels'), { key: 'channels', name: 'Add an Internet campaign to broaden your audience and increase effectiveness', severity: 3 }]
    }
    setStatuses(stats)
  }, [campaignsQuery.data])

  useEffect(() => {
    if (props.saturationPercentage < 6) {
      setStatuses([...statuses.filter(s => s.key !== 'budget'), { key: 'budget', name: 'Narrow Audience to Maximize Budget Effectiveness', severity: 3 }])
    } else if (props.saturationPercentage < 11) {
      setStatuses([...statuses.filter(s => s.key !== 'budget'), { key: 'budget', name: 'Budget not Maximizing Effectiveness', severity: 2 }])
    } else if (props.saturationPercentage < 25) {
      setStatuses([...statuses.filter(s => s.key !== 'budget'), { key: 'budget', name: 'Good Budget for Audience', severity: 1 }])
    } else {
      setStatuses([...statuses.filter(s => s.key !== 'budget'), { key: 'budget', name: 'Expand Audience', severity: 2 }])
    }
  }, [props.saturationPercentage])

  return (
    <React.Fragment>
      <EuiTitle className='eui-textCenter' size='s'>
        <h3>Advertiser Reach</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFormRow fullWidth>
        <React.Fragment>
          {statuses
            .sort((a, b) => a.severity - b.severity)
            .map(s => (
              <React.Fragment>
                <EuiHealth textSize='s' color={s.severity === 3 ? 'danger' : s.severity === 2 ? 'warning' : 'success'}>
                  {s.name}
                </EuiHealth>
                <EuiSpacer size='s' />
              </React.Fragment>
            ))}
        </React.Fragment>
      </EuiFormRow>
      <EuiSpacer />
      <Chart size={{ height: 200 }}>
        <Settings theme={[themeOverrides]} />
        <AreaSeries id='tech' name='Impressions' data={twoWeekReach} xScaleType='time' xAccessor={r => moment(r.forDate).format('MM/DD')} yAccessors={['impressions']} />
        <Axis id='bottom-axis' position='bottom' />
        <Axis id='left-axis' position='left' tickFormat={d => Number(d).toFixed()} />
      </Chart>
    </React.Fragment>
  )
}
