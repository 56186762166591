import { BillboardTextComponent } from 'api'

export const BillboardTextComponentFontLoaded = (textComponent: BillboardTextComponent) => {
  if (!('fonts' in document)) {
    return true
  }
  if (textComponent && textComponent.fontSize && textComponent.fontFace) {
    return (document as any).fonts.check(`${textComponent.fontSize}px ${textComponent.fontFace}`)
  } else {
    return true
  }
}

export const BillboardTextComponentsFontLoaded = (textComponents: BillboardTextComponent[]) => {
  return textComponents.every(t => BillboardTextComponentFontLoaded(t))
}
