import { OrganizationPrepareCapture, PaymentMethod } from 'api/entities/Organization'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

export interface IPaymentMethodQuery {
  organizationId: string
  accountId?: string
  paymentMethodId: string
}

const paymentMethodsTags = opsApi.enhanceEndpoints({ addTagTypes: ['PaymentMethodsCard', 'PaymentMethodsBankAccount', 'CaptureOrganization', 'CaptureAccount', 'PaymentMethodIdByPaymentProviderSetupIntent', 'PaymentMethodIdForReference'] })

const paymentMethodsApi = paymentMethodsTags.injectEndpoints({
  endpoints: build => ({
    getPaymentMethodCards: build.query<PaymentMethod[], string>({
      query: organizationId => `billing/paymentMethodCards/${organizationId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'PaymentMethodsCard', id: organizationId }]
    }),
    getPaymentMethodBankAccounts: build.query<PaymentMethod[], string>({
      query: organizationId => `billing/paymentMethodBankAccounts/${organizationId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, organizationId) => [{ type: 'PaymentMethodsBankAccount', id: organizationId }]
    }),
    getPaymentMethodCard: build.query<PaymentMethod, IPaymentMethodQuery>({
      query: ({ organizationId, accountId, paymentMethodId }) => `billing/paymentMethodCards/${organizationId}/${paymentMethodId}?accountId=${accountId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { organizationId, accountId, paymentMethodId }) => [{ type: 'PaymentMethodsCard', id: `${organizationId}|${accountId}|${paymentMethodId}` }]
    }),
    getPrepareCaptureOrganization: build.query<OrganizationPrepareCapture, { organizationId: string; accountId?: string; paymentMethodType?: string }>({
      query: ({ organizationId, accountId, paymentMethodType }) => `organizations/${organizationId}/capture?paymentMethodType=${paymentMethodType ?? 'card'}&${!!accountId ? `accountId=${accountId}` : ''}`,
      transformResponse: defaultTransformFn
    }),
    getPaymentMethodIdByPaymentProviderSetupIntent: build.query<string, { organizationId: string; setupIntentId: string; accountId?: string }>({
      query: ({ organizationId, setupIntentId, accountId }) => `organizations/${organizationId}/${setupIntentId}/paymentMethodIdByPaymentProviderSetupIntent?${!!accountId ? `accountId=${accountId}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { organizationId, setupIntentId, accountId }) => [{ type: 'PaymentMethodIdByPaymentProviderSetupIntent', id: `${organizationId}, ${setupIntentId}, ${accountId}` }]
    }),
    syncCards: build.mutation<boolean, { organizationId: string; accountId?: string }>({
      query: ({ organizationId, accountId }) => ({
        url: `organizations/${organizationId}/syncCards?${!!accountId ? `accountId=${accountId}` : ''}`,
        method: 'POST',
        body: {}
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, { organizationId }) => [{ type: 'PaymentMethodsCard', id: organizationId }]
    }),
    getPaymentMethodIdForReference: build.query<string, { organizationId: string; accountId?: string, referenceId: string }>({
      query: ({ organizationId, accountId, referenceId }) => ({
        url: `billing/paymentMethodIdForReference/${organizationId}`,
        method: 'POST',
        body: {
          accountId: accountId,
          referenceId: referenceId
        }
      }),
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { referenceId }) => [{ type: 'PaymentMethodIdForReference', id: referenceId }]
    })
  }),
  overrideExisting: false
})

export const { useGetPaymentMethodCardsQuery, useGetPaymentMethodBankAccountsQuery, useLazyGetPaymentMethodIdByPaymentProviderSetupIntentQuery, useGetPaymentMethodCardQuery, useGetPrepareCaptureOrganizationQuery, useSyncCardsMutation, useGetPaymentMethodIdForReferenceQuery } = paymentMethodsApi
