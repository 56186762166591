import moment from 'moment/moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiBreadcrumbs, EuiDataGrid, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiLoadingContent, EuiIcon } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

const MarketingPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [agencies, setAgencies] = useState<any>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    setIsLoading(true)
    opsClient?.getAgenciesReport().then(c => {
      setAgencies(c)
      setIsLoading(false)
    })
  }, [opsClient])

  const columns: EuiDataGridColumn[] = [
    {
      id: 'organization',
      displayAsText: 'Organization'
    },
    {
      id: 'organizationId',
      displayAsText: 'Organization ID'
    },
    {
      id: 'email',
      displayAsText: 'Email'
    },
    {
      id: 'signupDate',
      displayAsText: 'Signup Date',
      schema: 'date'
    },
    {
      id: 'isAgency',
      displayAsText: 'Is Agency'
    },
    {
      id: 'verifiedAgency',
      displayAsText: 'Verified Agency'
    },
    {
      id: 'receivesChecks',
      displayAsText: 'Receives Discount Checks'
    },
    {
      id: 'subscriptionStatus',
      displayAsText: 'Subscription Status'
    }
  ]

  const [visibleColumns, setVisibleColumns] = useState(['organization', 'organizationId', 'email', 'signupDate', 'isAgency', 'verifiedAgency', 'receivesChecks', 'subscriptionStatus'])

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const [sortingColumns, setSortingColumns] = useState([])
  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (agencies.hasOwnProperty(rowIndex)) {
        if (typeof agencies[rowIndex][columnId] == 'boolean') {
          return agencies[rowIndex][columnId] ? 'true' : 'false'
        }
        if (typeof agencies[rowIndex][columnId] == 'number') {
          return agencies[rowIndex][columnId] ? agencies[rowIndex][columnId] + '' : ''
        }
        if (schema === 'date') {
          return agencies[rowIndex][columnId] ? moment(agencies[rowIndex][columnId]).format('YYYY-MM-DD') + '' : ''
        }
        if (typeof agencies[rowIndex][columnId] == 'object') {
          return ''
        }
        return agencies[rowIndex][columnId] ? agencies[rowIndex][columnId] : null
      }
    }
  }, [agencies])

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Reports' },
              {
                text: 'Agencies',
                href: '/reports/agencies',
                onClick: () => history.push('/reports/agencies')
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Agencies</h1>
              </EuiTitle>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            {isLoading && <EuiLoadingContent />}
            {!isLoading && (
              <EuiDataGrid
                aria-label='Agencies Report'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal' }}
                rowCount={agencies.length}
                renderCellValue={renderCellValue}
                inMemory={{ level: 'sorting' }}
                sorting={{ columns: sortingColumns, onSort }}
                pagination={{
                  ...pagination,
                  pageSizeOptions: [50, 100, 200, 500],
                  onChangeItemsPerPage,
                  onChangePage
                }}
                toolbarVisibility={{
                  additionalControls: (
                    <React.Fragment>
                      <CSVLink data={agencies} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`agencies.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                        <span className='euiButtonContent euiButtonEmpty__content'>
                          <EuiIcon type='download' />
                          <span className='euiButton__text'>Download Report (CSV)</span>
                        </span>
                      </CSVLink>
                    </React.Fragment>
                  )
                }}
              />
            )}
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default MarketingPage
