import React, { ChangeEventHandler, FC } from 'react'

import { EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiSelect } from '@elastic/eui'

import { IntervalUnit } from 'api/entities/Bundle'

export interface IntervalUnitFrequencyProps {
  price: number
  intervalUnitFrequency: number
  onIntervalUnitFrequencyChange: ChangeEventHandler<HTMLInputElement>
  intervalUnit: IntervalUnit
  onIntervalUnitChange: ChangeEventHandler<HTMLSelectElement>
  totalIntervals: number
  onTotalIntervalsChange: ChangeEventHandler<HTMLInputElement>
  foreverBundle: boolean
}

const IntervalUnitFrequency: FC<IntervalUnitFrequencyProps> = ({ price, intervalUnitFrequency, onIntervalUnitFrequencyChange, intervalUnit, onIntervalUnitChange, totalIntervals, onTotalIntervalsChange, foreverBundle }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const intervalUnitOptions = [
    { value: IntervalUnit.Week, text: intervalUnitFrequency > 1 ? 'Weeks' : 'Week' },
    { value: IntervalUnit.Month, text: intervalUnitFrequency > 1 ? 'Months' : 'Month' }
  ]

  return (
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={false}>Bill {price > 0 ? formatter.format(price) : ''} every</EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldNumber value={intervalUnitFrequency} onChange={onIntervalUnitFrequencyChange} min={1} />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSelect options={intervalUnitOptions} value={intervalUnit} onChange={onIntervalUnitChange} />
      </EuiFlexItem>
      {foreverBundle ? (
        <EuiFlexItem>forever</EuiFlexItem>
      ) : (
        <React.Fragment>
          <EuiFlexItem>
            <EuiFieldNumber value={totalIntervals} onChange={onTotalIntervalsChange} append={totalIntervals > 1 ? 'times' : 'time'} />
          </EuiFlexItem>
        </React.Fragment>
      )}
    </EuiFlexGroup>
  )
}

export default IntervalUnitFrequency
