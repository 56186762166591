import React, { useEffect, useState } from 'react'

import { EuiBadge, EuiPopover, EuiSelectable, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

type PhraseChooserProps = {
  possiblePhrases: string[]
  selectedPhrase: string
  setParentState(selectedPhrase: string): void
}

const PhraseChooser: React.FC<PhraseChooserProps> = ({ possiblePhrases, selectedPhrase, setParentState }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [phrases, setPhrases] = useState<EuiSelectableOption[]>([])
  const [phrase, setPhrase] = useState('')

  useEffect(() => {
    if (selectedPhrase) setPhrase(selectedPhrase)
  }, [selectedPhrase])

  useEffect(() => {
    if (possiblePhrases) {
      setPhrases(
        possiblePhrases.map(
          p =>
            ({
              label: p,
              key: p,
              checked: p === selectedPhrase ? 'on' : null
            } as EuiSelectableOption)
        )
      )
    }
  }, [possiblePhrases, selectedPhrase])

  const open = () => setIsOpen(true)

  const close = () => setIsOpen(false)

  const change = (options: Array<EuiSelectableOption>) => {
    setPhrases(options)

    const selectedIndex = options.findIndex(so => so.checked === 'on')
    const selectedOption = options[selectedIndex]
    if (selectedOption.label) {
      setParentState(selectedOption.label)
      setPhrase(selectedOption.label)
      setIsOpen(false)
    }
  }

  return (
    <EuiPopover
      key={htmlIdGenerator()()}
      button={
        <EuiBadge color='warning' onClick={open} onClickAriaLabel='See choices' style={{ whiteSpace: 'nowrap' }}>
          {phrase}
        </EuiBadge>
      }
      isOpen={isOpen}
      closePopover={close}
      panelPaddingSize='s'>
      <EuiSelectable options={phrases} onChange={change} singleSelection='always'>
        {list => <div style={{ width: 240 }}>{list}</div>}
      </EuiSelectable>
    </EuiPopover>
  )
}

export default PhraseChooser
