import React from 'react'

import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

import { CampaignMonitor } from 'api/entities/Monitor'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface ICampaignMonitorItem {
  monitor: CampaignMonitor
  isSelected?: boolean
  onSelected?: () => void
  showStatus?: boolean
}

export const CampaignMonitorItem: React.FC<ICampaignMonitorItem> = props => {
  const monitor = props.monitor

  const onItemClicked = () => {
    props.onSelected?.()
  }

  return (
    <EuiPanel onClick={onItemClicked} style={{ cursor: 'pointer', borderColor: props.isSelected ? 'orange' : undefined }} hasBorder={props.isSelected}>
      <EuiTitle size={'s'}>
        <h4>
          {monitor.campaignName.slice(0, 32)}
          {monitor.campaignName.length > 32 ? '...' : ''}
        </h4>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiFlexGroup wrap gutterSize={'xs'}>
        <EuiFlexItem grow={false}>
          <EuiBadge color={'default'}>{insertSpaces(monitor.message)}</EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
