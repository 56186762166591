import moment, { Moment } from 'moment'
import React, { useState } from 'react'

import { EuiButton, EuiDatePicker, EuiFormRow, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiText } from '@elastic/eui'

import { Bundle } from 'api/entities/Bundle'
import { usePostponeBundleMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'

export interface IPostponePlanModal {
  onCancel?: () => void
  onConfirmComplete?: () => void
  bundle: Bundle
}

export const PostponeBundleModal: React.FC<IPostponePlanModal> = props => {
  const [postponePlan, postponePlanRequest] = usePostponeBundleMutation()
  const [postponeDate, setPostponeDate] = useState<Moment>(moment.max([...props.bundle.campaignSlots.filter(slot => !!slot.flightSchedule.nextEventDate).map(slot => moment(slot.flightSchedule.nextEventDate!)), moment()]).add(1, 'day'))

  const onSaveModal = () => {
    if (!isValidForSave()) {
      return
    }
    postponePlan({ organizationId: props.bundle.organizationId, bundleId: props.bundle.id, untilDate: postponeDate }).then(() => {
      props.onConfirmComplete?.()
    })
  }

  const onCloseModal = () => {
    props.onCancel?.()
  }

  const onDateChange = (date: Moment) => {
    setPostponeDate(date)
  }

  const isValidForSave = () => {
    if (props.bundle.billingSchedule?.nextEventDate) {
      return moment(postponeDate).startOf('day') >= moment(props.bundle.billingSchedule.nextEventDate).startOf('day')
    }
    return true
  }

  return (
    <EuiModal onClose={onCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Postpone Plan</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow fullWidth>
          <EuiText>This Plan's campaigns will finish out their current interval and only resume on or after the set date.</EuiText>
        </EuiFormRow>
        <EuiFormRow fullWidth label={'Postpone until'} helpText={props.bundle.billingSchedule?.nextEventDate ? `Next Billing Date: ${moment(props.bundle.billingSchedule.nextEventDate).format('MM/DD/yyyy')}` : undefined}>
          <EuiDatePicker selected={postponeDate} onChange={onDateChange} minDate={props.bundle.billingSchedule?.nextEventDate ? moment(props.bundle.billingSchedule.nextEventDate) : moment().add(1, 'day')} />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton onClick={onSaveModal} isLoading={postponePlanRequest.isLoading} isDisabled={!isValidForSave()}>
            Confirm
          </EuiButton>
        </EuiFormRow>
      </EuiModalBody>
    </EuiModal>
  )
}
