import moment from 'moment'
import React from 'react'

import { Axis, Chart, CurveType, LineSeries, PartialTheme, Settings } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem, euiPaletteForLightBackground, EuiPanel, EuiSpacer, EuiStat, EuiTitle, useEuiBackgroundColor, EuiSkeletonText } from '@elastic/eui'
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme'

import { Category, timespanKeyExecPlan, timespanKeyPlan, timespanSumKeyPlan } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardPlanQuery, useGetExecutiveDashboardPlanSumQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'

import ExecutiveDashboardPlanReportChangeFlyOut from '../ExecutiveDashboardPlanReportChangeFlyout'
import ExecutiveDashboardPlanReportFlyOut from '../ExecutiveDashboardPlanReportFlyout'

export interface PlanOverviewPanelProps {
  category: Category
  forDate: string
  timespan: string
}

const currencyStyle = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
const PlanOverviewPanel: React.FC<PlanOverviewPanelProps> = ({ category, forDate, timespan }) => {
  const { isLoading, data: totals } = useGetExecutiveDashboardPlanQuery({ category, forDate })
  const { isLoading: isLoadingHistorical, data: historicalTotals } = useGetExecutiveDashboardPlanSumQuery({ category, daysOfHistory: 7, forDate })

  const dashboardBaseTheme: PartialTheme = {
    crosshair: {
      band: {
        visible: false
      }
    },
    lineSeriesStyle: {
      point: {
        radius: 2,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    highlighter: {
      point: {
        radius: 4,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    axes: {
      gridLine: {
        vertical: {
          visible: false
        },
        horizontal: {
          visible: false
        }
      }
    }
  }
  const blueBackground: PartialTheme = {
    background: {
      color: useEuiBackgroundColor('primary')
    }
  }
  const theme = [dashboardBaseTheme, blueBackground, EUI_CHARTS_THEME_LIGHT.theme]

  return (
    <EuiPanel hasShadow={false} color='primary'>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiTitle size='xs'>
            <h1>{category}</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.activeRevenue.planIds.concat(totals.onboardingRevenue.planIds)} title='view all plans' />}</EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiStat description='Current Count' title={totals ? totals.totalActive + totals.totalOnboarding : '--'} titleSize='xs' isLoading={isLoading} />
        </EuiFlexItem>
        <EuiFlexItem>
          {totals && historicalTotals && totals.totalActive + totals.totalOnboarding > 0 && (
            <Chart size={{ height: 40 }}>
              <Settings theme={theme} showLegend={false} />
              <Axis
                id='number'
                groupId='number'
                domain={{
                  max: Math.max(...historicalTotals.map(x => x.totalActivePlans + x.totalOnboardingPlans)) * 1.05,
                  min: Math.min(...historicalTotals.map(x => x.totalActivePlans + x.totalOnboardingPlans)) * 0.95
                }}
                hide={true}
              />
              <LineSeries id='totalPlans' name='Plans' data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y.totalActivePlans + y.totalOnboardingPlans]} color={[euiPaletteForLightBackground()[1]]} groupId='number' curve={CurveType.CURVE_MONOTONE_X} />
            </Chart>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='xs' />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiStat description={`Current ${timespan} Revenue`} title={totals ? (totals.activeRevenue[timespan.toLowerCase() as timespanKeyPlan] + totals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPlan]).toLocaleString('en-US', currencyStyle) : '--'} titleSize='xs' isLoading={isLoading} />
        </EuiFlexItem>
        <EuiFlexItem>
          {totals && historicalTotals && totals.totalActive + totals.totalOnboarding > 0 && (
            <Chart size={{ height: 40 }}>
              <Settings theme={theme} showLegend={false} />
              <Axis
                id='total'
                groupId='total'
                domain={{
                  min: Math.min(...historicalTotals.map(x => x[`totalActive${timespan}Revenue` as timespanSumKeyPlan] + x[`totalOnboarding${timespan}Revenue` as timespanSumKeyPlan])) * 0.95,
                  max: Math.max(...historicalTotals.map(x => x[`totalActive${timespan}Revenue` as timespanSumKeyPlan] + x[`totalOnboarding${timespan}Revenue` as timespanSumKeyPlan])) * 1.05
                }}
                hide={true}
              />
              <LineSeries id='totalRevenue' name={`${timespan} Revenue`} data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y[`totalActive${timespan}Revenue` as timespanSumKeyPlan] + y[`totalOnboarding${timespan}Revenue` as timespanSumKeyPlan]]} color={[euiPaletteForLightBackground()[0]]} groupId='total' curve={CurveType.CURVE_MONOTONE_X} tickFormat={d => `${Number(d).toLocaleString('en-US', currencyStyle)}`} />
            </Chart>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='xs' />
      <EuiPanel color='transparent' hasBorder={true}>
        <EuiTitle size='xxs'>
          <h1>MTD Purchases</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals[`totalMtd${timespan}Revenue` as timespanKeyExecPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description={`Count`} title={totals ? totals.totalMtdPurchases : 0} titleSize='xs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.mtdPurchasesPlanIds} title='view mtd plans' />}
      </EuiPanel>
      <EuiSpacer size='xs' />
      <EuiPanel color='transparent' hasBorder={true}>
        <EuiTitle size='xxs'>
          <h1>Previous Month Purchases</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals[`totalPreviousMtd${timespan}Revenue` as timespanKeyExecPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description={`Count`} title={totals ? totals.totalPreviousMtdPurchases : 0} titleSize='xs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.previousMtdPurchasesPlanIds} title='view previous plans' />}
      </EuiPanel>
      <EuiSpacer size='s' />
      <EuiPanel hasShadow={false} color='plain'>
        <EuiTitle size='xxs'>
          <h1>Active Plans</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals.activeRevenue[timespan.toLowerCase() as timespanKeyPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description='Count' title={totals ? totals.totalActive : 0} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.activeRevenue.planIds} title='view active plans' />}
      </EuiPanel>
      <EuiSpacer size='s' />
      <EuiPanel hasShadow={false} color='plain'>
        <EuiTitle size='xxs'>
          <h1>Onboarding Plans</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description='Count' title={totals ? totals.totalOnboarding : 0} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.onboardingRevenue.planIds} title='view onboarding plans' />}
      </EuiPanel>
      <EuiSpacer size='s' />
      <EuiPanel hasShadow={false} color='plain'>
        <EuiTitle size='xxs'>
          <h1>1 Month Problem Plans</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals.problem1MonthRevenue[timespan.toLowerCase() as timespanKeyPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description='Count' title={totals ? totals.totalProblem1Month : 0} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.problem1MonthRevenue.planIds} title='view 1m problem plans' />}
      </EuiPanel>
      <EuiSpacer size='s' />
      <EuiPanel hasShadow={false} color='plain'>
        <EuiTitle size='xxs'>
          <h1>2 Month Problem Plans</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals.problem2MonthRevenue[timespan.toLowerCase() as timespanKeyPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description='Count' title={totals ? totals.totalProblem2Month : 0} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.problem2MonthRevenue.planIds} title='view 2m problem plans' />}
      </EuiPanel>
      <EuiSpacer size='s' />{' '}
      <EuiPanel hasShadow={false} color='plain'>
        <EuiTitle size='xxs'>
          <h1>Lost Plans (3 Month)</h1>
        </EuiTitle>
        <EuiFlexGroup>
          <EuiFlexItem grow={2}>
            <EuiStat description={`${timespan} Revenue`} title={totals ? totals.lostRevenue[timespan.toLowerCase() as timespanKeyPlan].toLocaleString('en-US', currencyStyle) : '--'} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiStat description='Count' title={totals ? totals.totalLost : 0} titleSize='xxxs' isLoading={isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='xs' />
        {totals && <ExecutiveDashboardPlanReportFlyOut category={category} forDate={forDate} planIds={totals.lostRevenue.planIds} title='view lost plans' />}
      </EuiPanel>
      <EuiSpacer size='m' />
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false} />
        <EuiFlexItem grow={false}>
          <ExecutiveDashboardPlanReportChangeFlyOut category={category} forDate={forDate} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
export default PlanOverviewPanel
