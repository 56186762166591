import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiLink, EuiLoadingContent, EuiSpacer, EuiSelect, EuiText, EuiTextArea, EuiFlexGroup, EuiFlexItem, htmlIdGenerator, EuiEmptyPrompt, EuiProgress, EuiImage, EuiButtonIcon } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select/select'

import { Ad, Campaign, AcImage, UpdateAd, useOpsClient, AdStatus } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { getAdStatusReadable } from 'utils/EnumToFriendly'
import { useDownloadUrl } from 'utils/useDownloadUrl'

import AdImageSelectFlyOut from './AdImageSelectFlyOut'

interface AdDetailsTabParams {
  adId: string
}

interface FormValues {
  headline: string
  body: string
  callToAction: string
  companyName: string
  destinationUrl: string
  name: string
}

export const AdDetailsTab: React.FC<AdDetailsTabParams> = ({ adId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [ad, setAd] = useState<Ad>()
  const [image, setImage] = useState<AcImage | null>(null)
  const [campaign, setCampaign] = useState<Campaign>()
  const [initial, setInitial] = useState<FormValues>({
    headline: '',
    body: '',
    callToAction: '',
    companyName: '',
    destinationUrl: '',
    name: ''
  })
  const [isLoadingCallToActions, setIsLoadingCallToActions] = useState(false)
  const [callToActions, setCallToActions] = useState<string[]>([])
  const opsClient = useOpsClient()
  const [tempUrl, setTempUrl] = useState(false)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)

  const [downloadImage] = useDownloadUrl(ad?.mediaType === 'Display' ? ad?.displayAdDetails?.uploadedUrl : image?.highQualityUrl)

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-customermanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      setIsLoadingCallToActions(true)
      opsClient.getAd(adId).then(result => {
        if (result.mediaType === 'Native') {
          setInitial({
            headline: result.nativeDetails!.headline,
            body: result.nativeDetails!.body,
            callToAction: result.nativeDetails!.callToAction,
            companyName: result.nativeDetails!.companyName,
            destinationUrl: result.destinationUrl,
            name: result.name
          })
          opsClient.getCallToActions().then(r => {
            setCallToActions(r)
            setIsLoadingCallToActions(false)
          })
        } else if (result.mediaType === 'Display') {
          setInitial({
            headline: '',
            body: '',
            callToAction: '',
            companyName: '',
            destinationUrl: result.destinationUrl,
            name: result.name
          })
        }
        setAd(result)
        setImage({
          highQualityUrl: result.highQualityUrl,
          thumbnailUrl: result.thumbnailUrl,
          squareUrl: result.squareUrl,
          largeSquareUrl: result.largeSquareUrl,
          id: htmlIdGenerator()()
        })
        opsClient.getCampaign(result.campaignId).then(result => {
          setCampaign(result)
        })
        setIsLoading(false)
      })
    }
  }, [opsClient, adId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  let adSchema
  if (ad?.mediaType === 'Display') {
    adSchema = Yup.object().shape({
      destinationUrl: Yup.string()
        .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
        .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
        .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
        .max(2048)
    })
  } else {
    adSchema = Yup.object().shape({
      headline: Yup.string().max(50).required('Please enter a headline'),
      body: Yup.string().max(150).required('Please enter the body'),
      companyName: Yup.string().max(35).required('Please enter a company name'),
      callToAction: Yup.string().required('Please select a call-to-action'),
      destinationUrl: Yup.string()
        .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
        .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
        .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
        .max(2048)
    })
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    let update: UpdateAd = {
      headline: values.headline,
      body: values.body,
      callToAction: values.callToAction,
      companyName: values.companyName,
      destinationUrl: values.destinationUrl,
      highQualityUrl: image!.highQualityUrl,
      squareUrl: image!.squareUrl,
      largeSquareUrl: image!.largeSquareUrl,
      thumbnailUrl: image!.thumbnailUrl,
      name: values.name
    }

    opsClient!.updateAd(adId, update).then(result => {
      setAd(result)
      setImage({
        highQualityUrl: result.highQualityUrl,
        thumbnailUrl: result.thumbnailUrl,
        squareUrl: result.squareUrl,
        largeSquareUrl: result.largeSquareUrl,
        id: htmlIdGenerator()()
      })
      setSubmitting(false)
    })
  }

  const doesImageExist = (url: string) => {
    let http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    try {
      http.send()
      return http.status === 200
    } catch {
      return false
    }
  }

  const selectImage = (i: AcImage) => {
    setImage(i)
    if (!doesImageExist(i.thumbnailUrl)) {
      setTempUrl(true)
      const checkExist = setInterval(function () {
        if (doesImageExist(image!.thumbnailUrl)) {
          clearInterval(checkExist)
          setTempUrl(false)
        }
      }, 5000)
    }
  }

  const pauseAd = () => {
    opsClient!.putAdInactive(adId).then(result => {
      setAd(result)
      setImage({
        highQualityUrl: result.highQualityUrl,
        thumbnailUrl: result.thumbnailUrl,
        squareUrl: result.squareUrl,
        largeSquareUrl: result.largeSquareUrl,
        id: htmlIdGenerator()()
      })
    })
  }

  const startAd = () => {
    opsClient!.putAdActive(adId).then(result => {
      setAd(result)
      setImage({
        highQualityUrl: result.highQualityUrl,
        thumbnailUrl: result.thumbnailUrl,
        squareUrl: result.squareUrl,
        largeSquareUrl: result.largeSquareUrl,
        id: htmlIdGenerator()()
      })
    })
  }

  const deleteAd = () => {
    opsClient!.putAdDeleted(adId).then(result => {
      setAd(result)
      setImage({
        highQualityUrl: result.highQualityUrl,
        thumbnailUrl: result.thumbnailUrl,
        squareUrl: result.squareUrl,
        largeSquareUrl: result.largeSquareUrl,
        id: htmlIdGenerator()()
      })
    })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {ad && campaign && (
        <Formik initialValues={initial} enableReinitialize validationSchema={adSchema} onSubmit={doSubmit}>
          {props => (
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Organization'>
                    <EuiLink onClick={() => history.push(`/customers/organizations/${ad!.organizationId}`)}>{ad!.organizationName}</EuiLink>
                  </EuiFormRow>
                  <EuiFormRow label='Account'>
                    <EuiLink onClick={() => history.push(`/customers/accounts/${ad!.accountId}`)}>{ad!.accountName}</EuiLink>
                  </EuiFormRow>
                  <EuiFormRow label='Campaign'>
                    <EuiLink onClick={() => history.push(`/customers/campaigns/${ad!.campaignId}`)}>{ad!.campaignName}</EuiLink>
                  </EuiFormRow>
                  <EuiFormRow label='Status'>
                    <React.Fragment>
                      <EuiText size='s'>
                        {getAdStatusReadable(ad.status)} | {ad.status !== AdStatus.Inactive && ad.status !== AdStatus.Deleted && canUpdate && <EuiLink onClick={pauseAd}>pause this ad</EuiLink>}
                        {ad.status === AdStatus.Inactive && canUpdate && <EuiLink onClick={startAd}>start this ad</EuiLink>}{' '}
                        {ad.status !== AdStatus.Deleted && userProfile!.isAdmin && (
                          <React.Fragment>
                            |{' '}
                            <EuiLink onClick={deleteAd} color='danger'>
                              force delete this ad
                            </EuiLink>
                          </React.Fragment>
                        )}
                        {ad.status === AdStatus.Deleted && userProfile!.isAdmin && (
                          <React.Fragment>
                            |{' '}
                            <EuiLink onClick={pauseAd} color='warning'>
                              un-delete this ad
                            </EuiLink>
                          </React.Fragment>
                        )}
                      </EuiText>
                    </React.Fragment>
                  </EuiFormRow>

                  {ad?.mediaType === 'Display' ? (
                    <EuiFlexItem>
                      <EuiSpacer />
                      <EuiFlexItem style={{ alignItems: 'left' }}>
                        <a href={ad.destinationUrl} target='_blank' rel='noreferrer'>
                          <EuiImage alt='ad' url={ad?.displayAdDetails?.uploadedUrl} />
                        </a>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButtonIcon title={'Download'} iconType={'download'} onClick={() => downloadImage(`${ad?.adId ?? 'downloadedAd'}.png`)} />
                      </EuiFlexItem>
                      <EuiSpacer size='m' />
                      <EuiFormRow
                        label='Display Ad Name'
                        isInvalid={props.touched.name && !!props.errors.name}
                        error={props.errors.name}
                        labelAppend={
                          <EuiText size='xs' color={props.values.name.length <= 50 ? 'default' : 'warning'}>
                            ({props.values.name.length}/50)
                          </EuiText>
                        }>
                        <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} isInvalid={props.touched.name && !!props.errors.name} />
                      </EuiFormRow>
                      <EuiSpacer size='m' />
                      <EuiFormRow
                        label='Destination URL'
                        isInvalid={props.touched.destinationUrl && !!props.errors.destinationUrl}
                        error={props.errors.destinationUrl}
                        labelAppend={
                          <EuiText size='xs' color={props.values.destinationUrl.length <= 2048 ? 'default' : 'warning'}>
                            ({props.values.destinationUrl.length}/2048)
                          </EuiText>
                        }>
                        <EuiFieldText name='destinationUrl' value={props.values.destinationUrl} onChange={props.handleChange} isInvalid={props.touched.destinationUrl && !!props.errors.destinationUrl} />
                      </EuiFormRow>
                      <EuiSpacer />
                    </EuiFlexItem>
                  ) : (
                    <EuiFlexItem>
                      <EuiSpacer />
                      <EuiFormRow label='Image' helpText={<AdImageSelectFlyOut accountId={ad.accountId} businessTypeId={campaign?.businessTypeId} selectImage={selectImage} />}>
                        <EuiFlexGroup>
                          <EuiFlexItem>
                            {tempUrl ? (
                              <EuiEmptyPrompt
                                iconType='image'
                                body={
                                  <EuiText size='xs'>
                                    <div>
                                      Preparing image
                                      <EuiProgress size='xs' color='accent' />
                                    </div>
                                  </EuiText>
                                }
                              />
                            ) : (
                              <EuiImage alt={''} url={image!.highQualityUrl} size={400} hasShadow={false} allowFullScreen />
                            )}
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon title={'Download'} iconType={'download'} onClick={() => downloadImage(`${ad?.adId ?? 'downloadedAd'}.png`)} />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFormRow>
                      <EuiFormRow
                        label='Headline'
                        isInvalid={props.touched.headline && !!props.errors.headline}
                        error={props.errors.headline}
                        labelAppend={
                          <EuiText size='xs' color={props.values.headline.length <= 50 ? 'default' : 'warning'}>
                            ({props.values.headline.length}/50)
                          </EuiText>
                        }>
                        <EuiFieldText name='headline' value={props.values.headline} onChange={props.handleChange} isInvalid={props.touched.headline && !!props.errors.headline} />
                      </EuiFormRow>
                      <EuiFormRow
                        label='Body'
                        isInvalid={props.touched.body && !!props.errors.body}
                        error={props.errors.body}
                        labelAppend={
                          <EuiText size='xs' color={props.values.body.length <= 150 ? 'default' : 'warning'}>
                            ({props.values.body.length}/150)
                          </EuiText>
                        }>
                        <EuiTextArea name='body' value={props.values.body} onChange={props.handleChange} resize='none' rows={3} isInvalid={props.touched.body && !!props.errors.body} />
                      </EuiFormRow>
                      <EuiFormRow
                        label='Company Name'
                        isInvalid={props.touched.companyName && !!props.errors.companyName}
                        error={props.errors.companyName}
                        labelAppend={
                          <EuiText size='xs' color={props.values.companyName.length <= 35 ? 'default' : 'warning'}>
                            ({props.values.companyName.length}/35)
                          </EuiText>
                        }>
                        <EuiFieldText name='companyName' value={props.values.companyName} onChange={props.handleChange} isInvalid={props.touched.companyName && !!props.errors.companyName} />
                      </EuiFormRow>
                      <EuiFormRow label='Call-to-Action Button' isInvalid={props.touched.callToAction && !!props.errors.callToAction} error={props.errors.callToAction}>
                        <EuiSelect name='callToAction' isLoading={isLoadingCallToActions} options={callToActions.map(ca => ({ value: ca, label: ca, text: ca } as EuiSelectOption))} value={props.values.callToAction} onChange={props.handleChange} isInvalid={props.touched.callToAction && !!props.errors.callToAction} hasNoInitialSelection={true} />
                      </EuiFormRow>
                      <EuiFormRow
                        label='Website URL'
                        isInvalid={props.touched.destinationUrl && !!props.errors.destinationUrl}
                        error={props.errors.destinationUrl}
                        labelAppend={
                          <EuiText size='xs' color={props.values.destinationUrl.length <= 2048 ? 'default' : 'warning'}>
                            ({props.values.destinationUrl.length}/2048)
                          </EuiText>
                        }>
                        <EuiFieldText name='destinationUrl' value={props.values.destinationUrl} onChange={props.handleChange} isInvalid={props.touched.destinationUrl && !!props.errors.destinationUrl} />
                      </EuiFormRow>
                      <EuiSpacer />
                    </EuiFlexItem>
                  )}
                  <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
                    Save
                  </EuiButton>
                </EuiForm>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </Formik>
      )}
    </React.Fragment>
  )
}
