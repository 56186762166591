import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'

import { EuiDataGrid, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiIcon, EuiLink, EuiPortal, EuiText, EuiTitle } from '@elastic/eui'
import { EuiDataGridColumn } from '@elastic/eui/src/components/datagrid/data_grid_types'

import { useGetExecutiveDashboardSubscriptionQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'
import { insertSpaces } from 'utils/EnumToFriendly'

interface FlyOutParams {
  forDate: string
  subscriptionIds: string[] | null
}

const columns: EuiDataGridColumn[] = [
  {
    id: 'subscriptionId',
    displayAsText: 'Subscription ID'
  },
  {
    id: 'organizationId',
    displayAsText: 'Organization ID',
    isSortable: false
  },
  {
    id: 'organizationName',
    displayAsText: 'Organization Name'
  },
  {
    id: 'organizationLink',
    displayAsText: 'Organization',
    isSortable: false,
    isExpandable: false
  },
  {
    id: 'subscriptionStatus',
    displayAsText: 'Status',
    isExpandable: false
  },
  {
    id: 'price',
    displayAsText: 'Price',
    schema: 'currency',
    isExpandable: false
  },
  {
    id: 'priceDescription',
    displayAsText: 'Price Desc.',
    isExpandable: false,
    isSortable: false
  },
  {
    id: 'turns',
    displayAsText: 'Number Turns',
    isExpandable: false,
    isSortable: false
  },
  {
    id: 'createdDate',
    displayAsText: 'Signup Date',
    schema: 'date'
  }
]

const ExecutiveDashboardSubscriptionReportFlyOut: React.FC<FlyOutParams> = ({ forDate, subscriptionIds }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const { data } = useGetExecutiveDashboardSubscriptionQuery({ forDate })
  const [subscriptions, setSubscriptions] = useState<any>([])
  const [visibleColumns, setVisibleColumns] = useState(['subscriptionId', 'subscriptionStatus', 'createdDate', 'organizationName', 'organizationLink', 'price', 'priceDescription', 'turns'])
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 })
  const [sortingColumns, setSortingColumns] = useState<Array<{ id: string; direction: 'asc' | 'desc' }>>([{ id: 'organizationName', direction: 'asc' }])

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  useEffect(() => {
    if (data) {
      let raw: any[]
      if (subscriptionIds) {
        raw = data.allSubscriptions.filter(cr => subscriptionIds.includes(cr.subscriptionId))
      } else {
        raw = data.allSubscriptions
      }

      setSubscriptions(
        raw.map(pr => ({
          organizationLink: (
            <EuiLink href={`/customers/organizations/${pr.organizationId}`} target='_blank'>
              view
            </EuiLink>
          ),
          ...pr
        }))
      )
    }
  }, [data, subscriptionIds])

  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  )
  const onChangePage = useCallback(
    pageIndex =>
      setPagination(pagination => ({
        ...pagination,
        pageIndex
      })),
    [setPagination]
  )

  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, schema }: any) => {
      if (subscriptions.hasOwnProperty(rowIndex)) {
        if (typeof subscriptions[rowIndex][columnId] == 'boolean') {
          return subscriptions[rowIndex][columnId] ? 'true' : 'false'
        }

        if (typeof subscriptions[rowIndex][columnId] === 'number') {
          if (schema === 'currency') {
            return subscriptions[rowIndex][columnId] ? subscriptions[rowIndex][columnId].toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + '' : ''
          }

          return subscriptions[rowIndex][columnId] ? subscriptions[rowIndex][columnId] + '' : ''
        }

        if (schema === 'date') {
          return subscriptions[rowIndex][columnId] ? moment(subscriptions[rowIndex][columnId]).format('YYYY-MM-DD') + '' : ''
        }

        if (columnId === 'subscriptionStatus') {
          return subscriptions[rowIndex][columnId] ? insertSpaces(subscriptions[rowIndex][columnId]) : ''
        }

        return subscriptions[rowIndex][columnId] ? subscriptions[rowIndex][columnId] : null
      }
    }
  }, [subscriptions])

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <EuiFlyout onClose={closeFlyout} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
          <EuiFlyoutHeader>
            <EuiTitle size='s'>
              <h2>Subscriptions</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiDataGrid
              aria-label='Subscriptions Report'
              columns={columns}
              columnVisibility={{ visibleColumns, setVisibleColumns }}
              gridStyle={{ cellPadding: 's', fontSize: 's', stripes: true, border: 'horizontal' }}
              rowCount={subscriptions.length}
              renderCellValue={renderCellValue}
              inMemory={{ level: 'sorting' }}
              sorting={{ columns: sortingColumns, onSort }}
              pagination={{
                ...pagination,
                pageSizeOptions: [50, 100, 200, 500],
                onChangeItemsPerPage,
                onChangePage
              }}
              toolbarVisibility={{
                additionalControls: (
                  <React.Fragment>
                    <CSVLink data={subscriptions} headers={columns.map(c => ({ label: c.displayAsText, key: c.id } as LabelKeyObject))} filename={`campaigns_report.csv`} target='_blank' className='euiButtonEmpty euiButtonEmpty--text euiButtonEmpty--xSmall euiDataGrid__controlBtn'>
                      <span className='euiButtonContent euiButtonEmpty__content'>
                        <EuiIcon type='download' />
                        <span className='euiButton__text'>Download Report (CSV)</span>
                      </span>
                    </CSVLink>
                  </React.Fragment>
                )
              }}
            />
          </EuiFlyoutBody>
        </EuiFlyout>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showFlyout} color='primary'>
        <EuiText size='xs'>view subscriptions</EuiText>
      </EuiLink>
      {flyout}
    </React.Fragment>
  )
}

export default ExecutiveDashboardSubscriptionReportFlyOut
