import React, { useEffect, useState } from 'react'

import { EuiButtonGroup } from '@elastic/eui'

interface AgeRangeTargeterProps {
  ageRanges: Array<string>

  setAgeRanges(ageRanges: Array<string>): void
}

export const AgeRangeTargeter = ({ ageRanges, setAgeRanges }: AgeRangeTargeterProps) => {
  const [toggleAgeRangeToSelectedMap, setToggleAgeRangeToSelectedMap] = useState<{ [id: string]: boolean }>({
    age_all: true,
    age_18_24: false,
    age_25_34: false,
    age_35_44: false,
    age_45_54: false,
    age_55_64: false,
    age_65: false
  })

  const ageRangeButtons = [
    {
      id: 'age_all',
      label: 'All'
    },
    {
      id: 'age_18_24',
      label: '18-24'
    },
    {
      id: 'age_25_34',
      label: '25-34'
    },
    {
      id: 'age_35_44',
      label: '35-44'
    },
    {
      id: 'age_45_54',
      label: '45-54'
    },
    {
      id: 'age_55_64',
      label: '55-64'
    },
    {
      id: 'age_65',
      label: '65+'
    }
  ]

  useEffect(() => {
    if (ageRanges) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (ageRanges.length === 0) ageRanges = ['all']

      setToggleAgeRangeToSelectedMap({
        age_all: ageRanges.includes('all'),
        age_18_24: ageRanges.includes('18_24'),
        age_25_34: ageRanges.includes('25_34'),
        age_35_44: ageRanges.includes('35_44'),
        age_45_54: ageRanges.includes('45_54'),
        age_55_64: ageRanges.includes('55_64'),
        age_65: ageRanges.includes('65')
      })
    }
  }, [ageRanges])

  const onChangeAgeRange = (optionId: string) => {
    let ages: Array<string> = []
    if (optionId === 'age_all' && !toggleAgeRangeToSelectedMap[optionId]) {
      setToggleAgeRangeToSelectedMap({
        age_all: true,
        age_18_24: false,
        age_25_34: false,
        age_35_44: false,
        age_45_54: false,
        age_55_64: false,
        age_65: false
      })
      ages.push('all')
    } else {
      const newToggleIdToSelectedMap = {
        ...toggleAgeRangeToSelectedMap,
        ...{
          [optionId]: !toggleAgeRangeToSelectedMap[optionId]
        }
      }
      newToggleIdToSelectedMap['age_all'] =
        (!newToggleIdToSelectedMap.hasOwnProperty('age_18_24') || !newToggleIdToSelectedMap['age_18_24']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('age_25_34') || !newToggleIdToSelectedMap['age_25_34']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('age_35_44') || !newToggleIdToSelectedMap['age_35_44']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('age_45_54') || !newToggleIdToSelectedMap['age_45_54']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('age_55_64') || !newToggleIdToSelectedMap['age_55_64']) &&
        (!newToggleIdToSelectedMap.hasOwnProperty('age_65') || !newToggleIdToSelectedMap['age_65'])
      setToggleAgeRangeToSelectedMap(newToggleIdToSelectedMap)

      if (newToggleIdToSelectedMap.age_18_24) ages.push('18_24')
      if (newToggleIdToSelectedMap.age_25_34) ages.push('25_34')
      if (newToggleIdToSelectedMap.age_35_44) ages.push('35_44')
      if (newToggleIdToSelectedMap.age_45_54) ages.push('45_54')
      if (newToggleIdToSelectedMap.age_55_64) ages.push('55_64')
      if (newToggleIdToSelectedMap.age_65) ages.push('65')
    }
    setAgeRanges(ages)
  }

  return <EuiButtonGroup type='multi' legend='Age Ranges' options={ageRangeButtons} idToSelectedMap={toggleAgeRangeToSelectedMap} onChange={onChangeAgeRange} />
}
