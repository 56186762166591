import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiLink, EuiPanel, EuiSpacer, EuiStat, EuiText, EuiTitle, Search, SortDirection } from '@elastic/eui'
import { EuiTableSortingType } from '@elastic/eui/src/components/basic_table/table_types'
import { SearchFilterConfig } from '@elastic/eui/src/components/search_bar/search_filters'

import { Bundle, BundleBillingView, BundleStatus, BundleType } from 'api/entities/Bundle'
import { Invoice } from 'api/entities/Invoice'
import { Plan } from 'api/entities/Plan'
import { useGetCampaignAdvisorsQuery } from 'api/rtkQueryApi/graphClient/graphClientApi'
import { BundlesView, useGetAllBundlesBillingViewByCampaignAdvisorEmailQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import ACSuperDatePicker, { useAcSuperDatePickerState } from 'components/ACSuperDatePicker'
import BundleStatusBadge from 'components/Bundles/BundleStatusBadge'
import InvoiceFlyout from 'components/Invoices/InvoiceFlyout'
import BundleNotesFlyout, { useBundleNotesFlyoutState } from 'features/customers/bundles/BundleNotesFlyout'
import { CustomFilterIgnoreAgenciesEnum, CustomFilterIncludeAgencies } from 'features/customers/bundles/CustomFilterIncludeAgencies'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface BundlesUIProps {
  view: BundlesView
  emailFilterBasedOnView?: string
  customFilterComponents?: JSX.Element[]
}

const BundlesUI: FC<BundlesUIProps> = ({ view, emailFilterBasedOnView, customFilterComponents }) => {
  const { customFilterDate, onTimeChange } = useAcSuperDatePickerState()
  const { requiredIdsForNote, setRequiredIdsForNote, isBundleNotesFlyoutVisible, showBundleNotesFlyout, closeBundleNotesFlyout } = useBundleNotesFlyoutState()
  const [customFilterIgnoreAgencies, setCustomFilterIgnoreAgencies] = useState(CustomFilterIgnoreAgenciesEnum.IgnoreAgencies)
  const [filteredBundles, setFilteredBundles] = useState<BundleBillingView[]>([])
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>()
  const [isInvoiceOpen, setIsInvoiceOpen] = useState<boolean>(false)

  const { isFetching: isBundleBillingsFetching, data: bundleBillings } = useGetAllBundlesBillingViewByCampaignAdvisorEmailQuery({
    view,
    emailFilterBasedOnView
  })
  const { data: graphData } = useGetCampaignAdvisorsQuery()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  useEffect(() => {
    if (!bundleBillings) {
      return
    }

    let bundlesToBeFiltered = bundleBillings

    if (customFilterDate) {
      bundlesToBeFiltered = bundlesToBeFiltered.filter(bundleBillingView => {
        let dateToUse = moment()

        if (bundleBillingView.bundle.billingSchedule?.lastIntervalDate) {
          dateToUse = moment(bundleBillingView.bundle.billingSchedule.lastIntervalDate)
        } else if (bundleBillingView.bundle.billingSchedule.createdDate) {
          dateToUse = moment(bundleBillingView.bundle.billingSchedule.createdDate)
        }

        const bundlePurchaseDate = dateToUse.startOf('day')
        const start = customFilterDate.start.startOf('day')
        const end = customFilterDate.end.endOf('day')

        return bundlePurchaseDate >= start && moment(bundlePurchaseDate) <= end
      })
    }

    if (view === BundlesView.BusinessDefined) {
      switch (customFilterIgnoreAgencies) {
        case CustomFilterIgnoreAgenciesEnum.IgnoreAgencies:
          bundlesToBeFiltered = bundlesToBeFiltered.filter(bundle => bundle.isAgency === false)
          break
        case CustomFilterIgnoreAgenciesEnum.JustAgencies:
          bundlesToBeFiltered = bundlesToBeFiltered.filter(bundle => bundle.isAgency === true)
          break
        case CustomFilterIgnoreAgenciesEnum.IncludeAgencies:
        default:
          break
      }
    }

    setFilteredBundles(bundlesToBeFiltered)
  }, [customFilterDate, customFilterIgnoreAgencies, bundleBillings])

  const getDisplayName = (mail: string) => {
    if (!graphData) {
      return null
    }

    return graphData.find(user => user.mail === mail)?.displayName
  }

  const getBundleCycle = (bundleBillingView: BundleBillingView) => {
    if (bundleBillingView.bundle.billingSchedule.intervalUnit === 'Month') {
      return `Monthly`
    } else if (bundleBillingView.bundle.billingSchedule.intervalUnit === 'Week') {
      return `Weekly`
    }

    return ''
  }

  const actions = [
    {
      render: (bundleBillingView: BundleBillingView) => {
        return (
          <EuiButtonIcon
            iconType={'notebookApp'}
            color={'success'}
            aria-label={'Contact Notes'}
            onClick={() => {
              setRequiredIdsForNote({
                bundleId: bundleBillingView.bundle.id,
                organizationId: bundleBillingView.bundle.organizationId
              })

              showBundleNotesFlyout()
            }}
          />
        )
      }
    }
  ]

  const maxWidthOnNameColumns = {
    maxWidth: '15em'
  }

  let columns = [
    {
      name: 'Organization Name',
      render: (c: BundleBillingView) => (
        <EuiLink href={`/customers/organizations/${c.bundle.organizationId}`} target='_blank'>
          {c.organizationName}
        </EuiLink>
      ),
      sortable: (c: BundleBillingView) => {
        return c.organizationName ?? ''
      },
      css: maxWidthOnNameColumns
    },
    {
      name: 'Account Name',
      render: (c: BundleBillingView) => {
        if (!c.bundle.accountId) {
          return 'No campaigns allocated'
        }

        return (
          <EuiLink href={`/customers/accounts/${c.bundle.accountId}`} target='_blank'>
            {c.accountName}
          </EuiLink>
        )
      },
      sortable: (c: BundleBillingView) => {
        return c.accountName ?? ''
      },
      css: maxWidthOnNameColumns
    },
    {
      name: 'Status',
      field: 'bundle.bundleStatus',
      render: (bundleStatus: BundleStatus, c: BundleBillingView) => <BundleStatusBadge bundle={c.bundle} />,
      sortable: (c: BundleBillingView) => {
        return c?.bundle.bundleStatus ?? ''
      }
    },
    ...(view !== BundlesView.BusinessDefined
      ? [
          {
            name: 'Cycle',
            render: (p: BundleBillingView) => <React.Fragment>{getBundleCycle(p)}</React.Fragment>
          }
        ]
      : []),
    {
      name: 'Price',
      render: (c: BundleBillingView) => formatter.format(c.bundle.price),
      sortable: (c: BundleBillingView) => {
        return c.bundle.price ?? 0
      }
    },
    {
      name: 'Total Revenue',
      render: (c: BundleBillingView) => formatter.format(c.totalRevenue),
      sortable: (c: BundleBillingView) => {
        return c.totalRevenue
      }
    },
    {
      name: 'Allocated / Slots',
      render: (c: BundleBillingView) => `${c.bundle.campaignSlots.filter(s => s.campaignId).length} / ${c.bundle.campaignSlots.length}`,
      sortable: (c: BundleBillingView) => c.bundle.campaignSlots.filter(s => s.campaignId).length
    },
    {
      name: 'Campaign Advisor',
      render: (c: BundleBillingView) => {
        if (c.bundle.bundleType !== BundleType.Plan) {
          return ''
        }

        const plan = c.bundle as Plan

        return getDisplayName(plan.campaignAdvisorId) ?? plan.campaignAdvisorId
      },
      sortable: (c: BundleBillingView) => {
        if (c.bundle.bundleType !== BundleType.Plan) {
          return ''
        }

        const plan = c.bundle as Plan

        return getDisplayName(plan.campaignAdvisorId) ?? plan.campaignAdvisorId
      }
    },
    {
      name: 'Completed Invoices',
      render: (c: BundleBillingView) => `${c.bundle.billingSchedule.intervalsCompleted} / ${c.bundle.billingSchedule.totalIntervals}`
    },
    {
      name: 'Bundle Purchase Date',
      render: (c: BundleBillingView) => {
        let bundlePurchaseDate = ''

        if (c.bundle.billingSchedule?.startDate) {
          bundlePurchaseDate = moment(c.bundle.billingSchedule?.startDate).format('LL')
        }

        return bundlePurchaseDate
      },
      sortable: (c: BundleBillingView) => {
        if (c.bundle.billingSchedule?.startDate) {
          return moment(c.bundle.billingSchedule?.startDate).unix()
        }

        return ''
      }
    },
    {
      name: 'Next Payment Date',
      render: (c: BundleBillingView) => (c.nextPaymentDate ? moment(c.nextPaymentDate).format('LL') : ''),
      sortable: (c: BundleBillingView) => {
        return c.nextPaymentDate ? moment(c.nextPaymentDate, 'YYYY-MM-DD, h:mm:ss').unix() : ''
      }
    },
    {
      name: 'Is Agency',
      field: 'isAgency',
      render: (isAgency: boolean, c: BundleBillingView) => (c.isAgency ? 'Yes' : 'No'),
      sortable: (c: BundleBillingView) => {
        return c.isAgency
      }
    },
    {
      name: 'Actions',
      actions
    }
  ]

  const onClose = () => {
    setIsInvoiceOpen(false)
    setSelectedInvoice(undefined)
  }

  let sorting = {
    allowNeutralSort: true,
    enableAllColumns: true,
    sort: {
      ...(view === BundlesView.BusinessDefined
        ? {
            field: 'Bundle Purchase Date',
            direction: SortDirection.DESC
          }
        : {
            field: 'Allocated / Slots',
            direction: SortDirection.DESC
          })
    }
  } as EuiTableSortingType<any>

  const pagination = {
    initialPageSize: 20,
    pageSizeOptions: [20, 50, 100]
  }

  let searchFilters = [
    ...(customFilterComponents
      ? [
          ...customFilterComponents.map(component => ({
            type: 'custom_component',
            component: (_: any) => component
          }))
        ]
      : []),
    ...(view === BundlesView.BusinessDefined
      ? [
          {
            type: 'custom_component',
            component: (props: any) => <CustomFilterIncludeAgencies {...props} customFilter={customFilterIgnoreAgencies} onClickCustomFilter={filter => setCustomFilterIgnoreAgencies(filter)} />
          }
        ]
      : []),
    {
      type: 'field_value_toggle_group',
      field: 'bundle.bundleStatus',
      name: 'Status',
      items: Object.keys(BundleStatus).map(bundleStatus => ({
        value: bundleStatus,
        name: insertSpaces(bundleStatus ?? '')
      }))
    },
    {
      type: 'custom_component',
      component: () => {
        return <ACSuperDatePicker customFilterDate={customFilterDate} onTimeChange={onTimeChange} useDefaultCustomCommonDateRanges={true} />
      }
    }
  ] as SearchFilterConfig[]

  const search = {
    box: {
      incremental: true,
      schema: true
    },
    filters: searchFilters
  } as Search

  const countOfFilteredBundlesByBundleStatus = (bundleStatus: BundleStatus) => {
    return filteredBundles.filter(bundleBillingView => bundleBillingView.bundle.bundleStatus === bundleStatus).length
  }

  const totalMonthlyPriceByBundleStatus = (bundleStatus: BundleStatus) => {
    return filteredBundles.filter(bundleBillingView => bundleBillingView.bundle.bundleStatus === bundleStatus).reduce((sum, bundleBillingView) => sum + bundleBillingView.bundle.price, 0)
  }

  const countOfBundlesWithUnfilledSlotsByBundleStatus = (bundleStatus: BundleStatus) => {
    return filteredBundles.filter(bundleBillingView => bundleBillingView.bundle.bundleStatus === bundleStatus && bundleBillingView.bundle.campaignSlots.filter(s => s.campaignId).length < bundleBillingView.bundle.campaignSlots.length).length
  }

  return (
    <React.Fragment>
      <EuiFlexGroup wrap>
        {Object.values(BundleStatus).map(bundleStatus => (
          <EuiFlexItem key={bundleStatus} style={{ minWidth: 260, maxWidth: 260 }}>
            <EuiPanel>
              <EuiFlexGroup>
                <EuiFlexItem />
                <EuiFlexItem grow={false}>
                  <EuiTitle size='s'>
                    <EuiText>{insertSpaces(bundleStatus)}</EuiText>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem />
              </EuiFlexGroup>

              <EuiSpacer size='m' />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiStat isLoading={isBundleBillingsFetching} title={countOfFilteredBundlesByBundleStatus(bundleStatus)} description='Count' textAlign='left' titleSize='s' />
                </EuiFlexItem>
                {view === BundlesView.CampaignAdvisor && (
                  <EuiFlexItem>
                    <EuiStat isLoading={isBundleBillingsFetching} title={countOfBundlesWithUnfilledSlotsByBundleStatus(bundleStatus)} description='Incomplete' textAlign='right' titleSize='s' />
                  </EuiFlexItem>
                )}
                {view !== BundlesView.CampaignAdvisor && (
                  <EuiFlexItem grow={false}>
                    <EuiStat isLoading={isBundleBillingsFetching} title={formatter.format(totalMonthlyPriceByBundleStatus(bundleStatus))} description='Monthly Revenue' textAlign='right' titleSize='s' />
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>

      <EuiSpacer size='l' />

      <EuiFlexGroup direction={'column'}>
        <EuiFlexItem>
          <EuiInMemoryTable loading={isBundleBillingsFetching} items={filteredBundles} columns={columns} sorting={sorting as any} pagination={pagination} search={search} tableLayout='auto' />

          {selectedInvoice && isInvoiceOpen && <InvoiceFlyout selectedInvoice={selectedInvoice} onFlyoutClose={onClose} />}
        </EuiFlexItem>
      </EuiFlexGroup>
      <BundleNotesFlyout requiredIdsForNote={requiredIdsForNote} isFlyoutVisible={isBundleNotesFlyoutVisible} closeFlyout={closeBundleNotesFlyout} />
    </React.Fragment>
  )
}

export default BundlesUI
