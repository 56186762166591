import React from 'react'

import { EuiConfirmModal, EuiFormRow, EuiOverlayMask } from '@elastic/eui'

import { PaymentMethodsRadioGroup } from 'components/Payments/PaymentMethodsRadioGroup'

export interface IAcPaymentModal {
  organizationId: string
  title: string
  message: string
  confirmButtonText: string
  cancelButtonText: string
  onCancel: () => void
  onConfirm: (paymentMethodId: string) => void
  isLoading?: boolean
}

export const AcPaymentModal: React.FC<IAcPaymentModal> = props => {
  const [selectedPaymentMethodId, setPaymentMethodId] = React.useState<string>()

  const onConfirm = () => {
    if (!!selectedPaymentMethodId) {
      props.onConfirm(selectedPaymentMethodId)
    }
  }

  const onCancel = () => {
    if (!props.isLoading) {
      props.onCancel()
    }
  }

  return (
    <EuiOverlayMask>
      <EuiConfirmModal title={props.title} onConfirm={onConfirm} onCancel={onCancel} confirmButtonText={props.confirmButtonText} cancelButtonText={props.cancelButtonText} isLoading={props.isLoading} confirmButtonDisabled={!selectedPaymentMethodId}>
        <p>{props.message}</p>
        <EuiFormRow label={'Payment'} fullWidth>
          <PaymentMethodsRadioGroup
            organizationId={props.organizationId}
            onSelectedPaymentMethodIdChange={id => {
              setPaymentMethodId(id)
            }}
          />
        </EuiFormRow>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}
