import React, { useEffect, useState } from 'react'

import { EuiConfirmModal, EuiFieldText, EuiForm, EuiFormRow, EuiOverlayMask } from '@elastic/eui'

import { Campaign, useOpsClient } from 'api'

export interface IAcCancelCampaignPlan {
  campaignId: string
  isVisible: boolean
  cancelModal: () => void
}

export const AcCancelCampaignBundleModal: React.FC<IAcCancelCampaignPlan> = props => {
  const opsClient = useOpsClient()
  const [isCancelCampaignPlanModalVisible, setIsCancelCampaignPlanModalVisible] = useState(false)
  const [isCancelCampaignPlanLoading, setIsCancelCampaignPlanLoading] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null)

  useEffect(() => {
    if (props.isVisible) {
      showCancelCampaignPlanModal(props.campaignId)
    } else if (!props.isVisible && isCancelCampaignPlanModalVisible) {
      cancelCancelCampaignPlanModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible])

  useEffect(() => {
    if (opsClient && isCancelCampaignPlanLoading && selectedCampaign) {
      opsClient.deleteBundleWithOneCampaignByCampaignId(selectedCampaign).then(res => {
        setIsCancelCampaignPlanLoading(false)
        cancelCancelCampaignPlanModal()
      })
    } else {
      setIsCancelCampaignPlanLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelCampaignPlanLoading, props, selectedCampaign, opsClient])

  const showCancelCampaignPlanModal = (campaignId: string) => {
    setIsCancelCampaignPlanModalVisible(true)
    setSelectedCampaign(campaignId)
  }

  const cancelCancelCampaignPlanModal = () => {
    setIsCancelCampaignPlanModalVisible(false)
    setSelectedCampaign(null)
    props.cancelModal()
  }

  const confirmCancelCampaignPlanModal = () => {
    setIsCancelCampaignPlanLoading(true)
  }

  let cancelCampaignPlanModal
  if (isCancelCampaignPlanModalVisible) {
    cancelCampaignPlanModal = (
      <EuiOverlayMask>
        <EuiConfirmModal title='Are you sure you want to cancel this campaign?' onCancel={cancelCancelCampaignPlanModal} onConfirm={confirmCancelCampaignPlanModal} isLoading={isCancelCampaignPlanLoading} cancelButtonText='Cancel' confirmButtonText='Confirm' buttonColor='primary'>
          <EuiForm component='form'></EuiForm>
        </EuiConfirmModal>
      </EuiOverlayMask>
    )
  }

  return <React.Fragment>{cancelCampaignPlanModal}</React.Fragment>
}
