import React, { useEffect, useRef, useState } from 'react'

import { EuiBasicTable, EuiButton, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiSpacer, EuiText, EuiTitle, EuiToken, EuiTreeView, htmlIdGenerator } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { Node } from '@elastic/eui/src/components/tree_view/tree_view'

import { BusinessTypeTextAssignment, BusinessTypeCategoryNode, TvCommercial, useOpsClient } from 'api'

import AssignTextCell from './assignment/AssignTextCell'
import HealthCell from './assignment/HealthCell'
import PreviewCell from './assignment/PreviewCell'
import TemplateCell from './assignment/TemplateCell'

interface TvCommercialTextAssignmentTabParams {
  tvCommercialId: string
}

const TvCommercialTextAssignmentTab: React.FC<TvCommercialTextAssignmentTabParams> = ({ tvCommercialId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBusinessTypeCategories, setIsLoadingBusinessTypeCategories] = useState(false)
  const [isLoadingBusinessTypeAssignments, setIsLoadingBusinessTypeAssignments] = useState(false)
  const [tvCommercial, setTvCommercial] = useState<TvCommercial>()
  const [businessTypeCategories, setBusinessTypeCategories] = useState<BusinessTypeCategoryNode[]>([])
  const [businessTypeCategoryNodes, setBusinessTypeCategoryNodes] = useState<Node[]>([])
  const [selectedBusinessTypeCategory, setSelectedBusinessTypeCategory] = useState<BusinessTypeCategoryNode | null>(null)
  const [businessTypes, setBusinessTypes] = useState<BusinessTypeTextAssignment[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const tableRef = useRef<EuiBasicTable<BusinessTypeTextAssignment>>(null)
  const opsClient = useOpsClient()

  const refresh = () => {
    setIsLoading(true)
    setIsLoadingBusinessTypeCategories(true)
    opsClient!.getTvCommercial(tvCommercialId).then(result => {
      setTvCommercial(result)
      setIsLoading(false)
    })
    opsClient!.getBusinessTypeCategoryNodes(tvCommercialId).then(result => {
      setBusinessTypeCategories(result)
      setIsLoadingBusinessTypeCategories(false)
    })
  }

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsClient, tvCommercialId])

  useEffect(() => {
    const nodes: Node[] = []

    businessTypeCategories.forEach(btc => {
      const root: Node = {
        id: btc.id,
        label: btc.name,
        children: undefined as Node[] | undefined,
        icon: <EuiToken size='xs' iconType={btc.hasAssignments ? 'tokenBoolean' : 'tokenObject'} />,
        callback(): string {
          setSelectedBusinessTypeCategory(btc)
          return ''
        }
      }
      btc.children.forEach(level1 => {
        const item1: Node = {
          id: level1.id,
          label: level1.name,
          children: undefined as Node[] | undefined,
          icon: <EuiToken size='xs' iconType={level1.hasAssignments ? 'tokenBoolean' : 'tokenObject'} />,
          callback(): string {
            setSelectedBusinessTypeCategory(level1)
            return ''
          }
        }

        level1.children.forEach(level2 => {
          if (item1.children === undefined) {
            item1.children = []
            item1.useEmptyIcon = false
          }
          item1.children!.push({
            id: level2.id,
            label: level2.name,
            icon: <EuiToken size='xs' iconType={level2.hasAssignments ? 'tokenBoolean' : 'tokenObject'} />,
            callback(): string {
              setSelectedBusinessTypeCategory(level2)
              return ''
            }
          })
        })

        if (root.children === undefined) {
          root.children = []
          root.useEmptyIcon = false
        }
        root.children!.push(item1)
      })

      nodes.push(root)
    })

    setBusinessTypeCategoryNodes(nodes)
  }, [businessTypeCategories])

  useEffect(() => {
    if (selectedBusinessTypeCategory && opsClient && tvCommercialId) {
      setIsLoadingBusinessTypeAssignments(true)
      opsClient.getBusinessTypeAssignments(tvCommercialId, selectedBusinessTypeCategory.id).then(result => {
        setBusinessTypes(result)
        setIsLoadingBusinessTypeAssignments(false)
      })
    }
  }, [selectedBusinessTypeCategory, tvCommercialId, opsClient])

  const generate = () => {
    setIsGenerating(true)
    opsClient!.generatePreviewsForBusinessTypeCategory(tvCommercialId, selectedBusinessTypeCategory!.id).then(() => setIsGenerating(false))
  }

  const columns: Array<EuiBasicTableColumn<BusinessTypeTextAssignment>> = [
    {
      name: 'Is Ready?',
      render: (bta: BusinessTypeTextAssignment) => <HealthCell assignment={bta} />,
      width: '100'
    },
    {
      name: 'Business Type Name',
      field: 'name',
      truncateText: true,
      width: '200'
    },
    {
      name: 'Assign?',
      render: (bta: BusinessTypeTextAssignment) => <AssignTextCell assignment={bta} />,
      width: '100'
    },
    {
      name: 'TV Commercial Copy',
      render: (bta: BusinessTypeTextAssignment) => (
        <React.Fragment key={htmlIdGenerator()()}>
          {bta.selections.map((p, i) => (
            <React.Fragment key={htmlIdGenerator()()}>
              <TemplateCell phrase={p} />
              {i < bta.selections.length - 1 && <span style={{ marginLeft: 5, fontWeight: 'bolder' }}>|</span>}
            </React.Fragment>
          ))}
        </React.Fragment>
      )
    },
    {
      name: 'Preview',
      render: (bta: BusinessTypeTextAssignment) => <PreviewCell tvCommercialId={tvCommercialId} assignment={bta} />,
      width: '100'
    }
  ]

  const saveSelections = () => {
    setIsSaving(true)
    opsClient!
      .updateTvCommercialTextAssignments(tvCommercialId, {
        businessTypeCategoryId: selectedBusinessTypeCategory!.id,
        businessTypeTextAssignments: businessTypes.map(a => ({
          businessTypeId: a.id,
          isAssigned: a.isAssigned,
          selections: a.selections.map(x => ({
            id: x.id,
            text: x.text,
            selectedAnchorPhrases: x.selectedAnchorPhrases,
            selectedGranularPhrases: x.selectedGranularPhrases
          }))
        }))
      })
      .then(result => {
        setTvCommercial(result)
        setIsLoadingBusinessTypeAssignments(true)
        opsClient!.getBusinessTypeAssignments(tvCommercialId, selectedBusinessTypeCategory!.id).then(result => {
          setBusinessTypes(result)
          setIsLoadingBusinessTypeAssignments(false)
          setIsSaving(false)
        })
      })
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {tvCommercial && (
        <React.Fragment>
          {tvCommercial && (
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                <video controls src={tvCommercial.textSampleUrl ?? ''} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText>{tvCommercial.notes}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
          <EuiSpacer />
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={false} style={{ width: 250 }}>
              <EuiTitle size='xxs'>
                <h4>Categories</h4>
              </EuiTitle>
              <EuiSpacer size='m' />
              {isLoadingBusinessTypeCategories ? <EuiLoadingContent lines={3} /> : <EuiTreeView items={businessTypeCategoryNodes} aria-label='Business type categories' expandByDefault={true} showExpansionArrows={true} display='compressed' color='primary' />}
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiTitle size='xxs'>
                <h4>Business Types Assignments</h4>
              </EuiTitle>
              <EuiSpacer size='m' />
              {isLoadingBusinessTypeAssignments ? (
                <EuiLoadingContent lines={3} />
              ) : (
                <React.Fragment>
                  <EuiBasicTable ref={tableRef} items={businessTypes} itemId='id' columns={columns} rowHeader='name' />
                  <EuiSpacer />
                  <EuiFlexGroup>
                    {tvCommercial && (
                      <EuiFlexItem grow={false}>
                        <EuiButton onClick={saveSelections} isLoading={isSaving} isDisabled={!isLoadingBusinessTypeAssignments && !selectedBusinessTypeCategory}>
                          Save
                        </EuiButton>
                      </EuiFlexItem>
                    )}
                    <EuiFlexItem grow={false}>
                      <EuiButton onClick={generate} isLoading={isGenerating} isDisabled={!selectedBusinessTypeCategory} color='accent'>
                        Generate Previews
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </React.Fragment>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default TvCommercialTextAssignmentTab
