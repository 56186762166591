import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  EuiForm,
  EuiButton,
  EuiButtonEmpty,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderBreadcrumbs,
  EuiLoadingContent,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPage,
  EuiPageBody,
  EuiPageContent_Deprecated as EuiPageContent,
  EuiPageContentBody_Deprecated as EuiPageContentBody,
  EuiPageContentHeader_Deprecated as EuiPageContentHeader,
  EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiFormRow,
  EuiSuperSelect,
  EuiBreadcrumbs,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiCopy,
  EuiBadge
} from '@elastic/eui'

import { PendingAuditBillboardAd } from 'api'
import { useApproveBillboardAdMutation, useDisapproveBillboardAdMutation, useGetBillboardPendingAuditQuery, useGetBillboardPendingAuditsQuery } from 'api/rtkQueryApi/opsApi/adsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { useDownloadUrl } from 'utils/useDownloadUrl'

interface AuditPageParams {
  adId: string
}

const BillboardAdPendingAuditPage: React.FC<AuditPageParams> = ({ adId }) => {
  const billboardPendingAuditQuery = useGetBillboardPendingAuditsQuery()
  const [disapproveBillboardAd, disapproveBillboardAdQuery] = useDisapproveBillboardAdMutation()
  const [approveBillboardAd, approveBillboardAdQuery] = useApproveBillboardAdMutation()
  const pendingAdQuery = useGetBillboardPendingAuditQuery(adId)
  const [nextPendingAd, setNextPendingAd] = useState<PendingAuditBillboardAd>()
  const [prevPendingAd, setPrevPendingAd] = useState<PendingAuditBillboardAd>()
  const [showDisapprove, setShowDisapprove] = useState(false)
  const [superSelectValue, setSuperSelectValue] = useState('Video quality issue(s)')
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canAudit, setCanAudit] = useState(false)

  const [downloadImage] = useDownloadUrl(pendingAdQuery.data?.previewUrl ?? '')

  useEffect(() => {
    if (billboardPendingAuditQuery.data && billboardPendingAuditQuery.data.length > 0) {
      const index = billboardPendingAuditQuery.data.findIndex(a => a.adId == adId)
      let nextIndex = index + 1
      let prevIndex = index - 1
      if (nextIndex === billboardPendingAuditQuery.data.length) {
        nextIndex = 0
      }
      if (prevIndex < 0) {
        prevIndex = billboardPendingAuditQuery.data.length - 1
      }

      setNextPendingAd(billboardPendingAuditQuery.data[nextIndex])
      setPrevPendingAd(billboardPendingAuditQuery.data[prevIndex])
    }
  }, [billboardPendingAuditQuery.data, adId])

  useEffect(() => {
    if (userProfile) {
      setCanAudit(userProfile.roles.includes('adcritter-ops-auditor') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  const pendingAd = pendingAdQuery.data

  if (!pendingAd || billboardPendingAuditQuery.isLoading) {
    return (
      <EuiPage>
        <EuiPageBody component='div'>
          <EuiPageContent>
            <EuiLoadingContent />
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    )
  }

  const approveAd = () => {
    approveBillboardAd({ adId })
  }

  const disapproveAd = () => {
    disapproveBillboardAd({ adId, reason: superSelectValue }).then(() => {
      setShowDisapprove(false)
    })
  }

  const closeModal = () => setShowDisapprove(false)

  const showModal = () => setShowDisapprove(true)

  const onSuperSelectChange = (value: string) => {
    setSuperSelectValue(value)
  }

  const superSelectOptions = [
    {
      value: 'Image quality issue(s)',
      inputDisplay: 'Image quality issue(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Video quality issue(s)</strong>
        </React.Fragment>
      )
    },
    {
      value: 'Image format issue(s)',
      inputDisplay: 'Image format issue(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Video format issue(s)</strong>
        </React.Fragment>
      )
    },
    {
      value: 'Policy violation(s)',
      inputDisplay: 'Policy violation(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Policy violation(s)</strong>
          <EuiText size='s' color='subdued'>
            <p className='euiTextColor--subdued'>AdCritter cannot approve this ad as it violates our advertising policies.</p>
          </EuiText>
        </React.Fragment>
      )
    }
  ]

  const form = (
    <EuiForm>
      <EuiFormRow label='Please choose the reason you are rejecting this ad'>
        <EuiSuperSelect options={superSelectOptions} valueOfSelected={superSelectValue} onChange={value => onSuperSelectChange(value)} itemLayoutAlign='top' hasDividers />
      </EuiFormRow>
    </EuiForm>
  )

  let modal
  const isWorking = disapproveBillboardAdQuery.isLoading || approveBillboardAdQuery.isLoading || billboardPendingAuditQuery.isLoading

  if (showDisapprove) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Disapprove Ad</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>{form}</EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal} disabled={isWorking}>
              Cancel
            </EuiButtonEmpty>
            <EuiButton onClick={disapproveAd} fill color='danger' isLoading={isWorking}>
              Disapprove
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  const onNextClick = () => {
    if (!!nextPendingAd) {
      history.push(`/customers/pending/ads/audit/${nextPendingAd.adId}`)
    }
  }

  const onPreviousClick = () => {
    if (!!prevPendingAd) {
      history.push(`/customers/pending/ads/audit/${prevPendingAd.adId}`)
    }
  }

  return (
    <React.Fragment>
      <EuiPage>
        <EuiPageBody component='div'>
          <EuiPageContent>
            <EuiBreadcrumbs
              breadcrumbs={[
                { text: 'Customers' },
                {
                  text: 'Ads',
                  href: '/customers/ads',
                  onClick: () => history.push('/customers/ads')
                },
                {
                  text: 'Pending',
                  href: '/customers/pending/ads',
                  onClick: () => history.push('/customers/pending/ads')
                },
                {
                  text: 'Details',
                  href: `/customers/pending/ads/${pendingAd.adId}`,
                  onClick: () => history.push(`/customers/pending/ads/${pendingAd.adId}`)
                }
              ]}
            />
            <EuiSpacer />
            <EuiPageContentHeader>
              <EuiPageContentHeaderSection>
                <EuiTitle size='m'>
                  <h1>Ad Pending Audit</h1>
                </EuiTitle>
                <EuiCopy textToCopy={adId}>
                  {copy => (
                    <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Ad ID'>
                      {adId}
                    </EuiBadge>
                  )}
                </EuiCopy>
              </EuiPageContentHeaderSection>
              <EuiPageContentHeaderSection>
                {pendingAd && (
                  <EuiHeaderBreadcrumbs
                    responsive={false}
                    breadcrumbs={[
                      {
                        text: pendingAd.organizationName,
                        onClick: () => history.push(`/customers/organizations/${pendingAd.organizationId}`),
                        truncate: true
                      },
                      {
                        text: pendingAd.accountName,
                        onClick: () => history.push(`/customers/accounts/${pendingAd.accountId}`),
                        truncate: true
                      },
                      {
                        text: pendingAd.campaignName,
                        onClick: () => history.push(`/customers/campaigns/${pendingAd.campaignId}`),
                        truncate: true
                      }
                    ]}
                  />
                )}
              </EuiPageContentHeaderSection>
              <EuiPageContentHeaderSection>
                <EuiFlexGroup>
                  {pendingAd && (
                    <EuiFlexItem>
                      <EuiButton href={`${config.PLATFORMURL}gotoAd/${pendingAd.organizationId}/${pendingAd.accountId}/${pendingAd.campaignId}/${pendingAd.adId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                        AdCritter Platform
                      </EuiButton>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiPageContentHeaderSection>
            </EuiPageContentHeader>
            <EuiPageContentBody>
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <img src={pendingAd.previewUrl} style={{ width: 640 }} alt='preview' />
                  <EuiFormRow>
                    <EuiButtonEmpty title={'Download'} iconType={'download'} onClick={() => downloadImage(`${pendingAdQuery.data?.adId ?? 'downloadedAd'}.png`)}>
                      Download
                    </EuiButtonEmpty>
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiDescriptionList compressed={true}>
                    <EuiDescriptionListTitle>Audit Status</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{pendingAd.auditStatus}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Ad Created</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{moment.utc(pendingAd.created).tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss z')}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Campaign Activated</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{moment.utc(pendingAd.activated).tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss z')}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Budget</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>
                      {formatter.format(pendingAd.budget)} {pendingAd.budgetFrequency}
                    </EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Targeting Template</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{pendingAd.targetingTemplate}</EuiDescriptionListDescription>
                  </EuiDescriptionList>

                  <EuiSpacer />
                  {(pendingAd.targetingTemplate === 'Shopify' || pendingAd.targetingTemplate === 'ShopifyBillboard' || pendingAd.targetingTemplate === 'ShopifyTV') && (
                    <React.Fragment>
                      <EuiFlexGroup>
                        <EuiFlexItem grow={false}>
                          <EuiBadge color={'warning'} title={'Shopify campaigns often require additional validation from the customer before approval.'}>
                            Shopify Campaign
                          </EuiBadge>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  {pendingAd.auditStatus === 'Pending' && (
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiButton fill color='primary' onClick={approveAd} disabled={!canAudit} isLoading={isWorking}>
                          Approve
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiButton fill color='danger' onClick={showModal} disabled={!canAudit} isLoading={isWorking}>
                          Disapprove
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiButton
                          fill
                          color='text'
                          disabled={isWorking}
                          onClick={() => {
                            history.push(`/customers/ads/${adId}`)
                          }}>
                          Edit
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                  <EuiSpacer />
                  <EuiFlexGroup>
                    <EuiFlexItem />
                    <EuiFlexItem grow={false}>
                      <EuiButton color='text' iconType={'arrowLeft'} disabled={!prevPendingAd || isWorking} onClick={onPreviousClick}>
                        Previous
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='text' iconType={'arrowRight'} iconSide='right' disabled={!nextPendingAd || isWorking} onClick={onNextClick}>
                        Next
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
      {modal}
    </React.Fragment>
  )
}

export default BillboardAdPendingAuditPage
