import { PaymentMethod } from 'api'

export const displayPaymentMethod = (paymentMethod?: PaymentMethod) => {
  if (!paymentMethod) {
    return 'N/A'
  }

  return `${paymentMethod.brand} card ending ${displayPaymentMethodShort(paymentMethod)}`
}

export const displayPaymentMethodShort = (paymentMethod?: PaymentMethod) => {
  if (!paymentMethod) {
    return 'N/A'
  }

  return `xxxx${paymentMethod.last4}, expiring ${paymentMethod.expiryMonth.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })}/${paymentMethod.expiryYear}`
}
