import { AddressAutoComplete, LocationCity, LocationDma, LocationPostalCode, LocationRegion } from 'api/entities/Campaign'
import { GeoFence } from 'api/entities/Geo'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const targetingTag = opsApi.enhanceEndpoints({ addTagTypes: ['Regions', 'Cities', 'DMAs', 'PostalCodes', 'AddressFill', 'GeoFence'] })

const targetingApi = targetingTag.injectEndpoints({
  endpoints: build => ({
    getRegions: build.query<LocationRegion[], { name?: string }>({
      query: ({ name }) => `targeting/regions?${name ? `name=${name}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { name }) => [{ type: 'Regions', id: name }]
    }),
    getCities: build.query<LocationCity[], { name?: string }>({
      query: ({ name }) => `targeting/cities?${name ? `name=${name}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { name }) => [{ type: 'Cities', id: name }]
    }),
    getAutoCompleteAddresses: build.query<AddressAutoComplete[], { query?: string }>({
      query: ({ query }) => `targeting/address/autocomplete?query=${query}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { query }) => [{ type: 'AddressFill', id: query }]
    }),
    getGeoFence: build.query<GeoFence, { placeId: string }>({
      query: ({ placeId }) => `targeting/address/${placeId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { placeId }) => [{ type: 'GeoFence', id: placeId }]
    }),
    getDmas: build.query<LocationDma[], { name?: string }>({
      query: ({ name }) => `targeting/dmas?${name ? `name=${name}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { name }) => [{ type: 'DMAs', id: name }]
    }),
    getPostalCodes: build.query<LocationPostalCode[], { code?: string }>({
      query: ({ code }) => `targeting/postalCodes?${code ? `code=${code}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { code }) => [{ type: 'PostalCodes', id: code }]
    }),
    getPostalCodesCsv: build.query<LocationPostalCode[], { codeCsv?: string }>({
      query: ({ codeCsv }) => `targeting/postalCodesCsv?${codeCsv ? `codeCsv=${codeCsv}` : ''}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, { codeCsv }) => [{ type: 'PostalCodes', id: codeCsv }]
    })
  }),
  overrideExisting: false
})

export const { useGetCitiesQuery, useGetDmasQuery, useGetPostalCodesQuery, useLazyGetPostalCodesQuery, useLazyGetPostalCodesCsvQuery, useGetRegionsQuery, useGetAutoCompleteAddressesQuery, useLazyGetGeoFenceQuery } = targetingApi
