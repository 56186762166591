import React, { useState } from 'react'

import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { LocationDma } from 'api'
import { useGetDmasQuery } from 'api/rtkQueryApi/opsApi/targetingApi'
import { AcSearchField } from 'components/ACTools/AcSearchField'
import useDebounce from 'utils/useDebounce'

export interface IDMAFinderXandr {
  onOptionClick?: (option: LocationDma) => void
}

export const DMAFinderXandr: React.FC<IDMAFinderXandr> = props => {
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useDebounce(searchValue, 750)
  const dmasQuery = useGetDmasQuery({ name: debounceSearch }, { skip: debounceSearch.length < 3 })

  const onSearchValueChanged = (value: string) => {
    setSearchValue(value)
  }

  return (
    <AcSearchField
      value={searchValue}
      isLoading={dmasQuery.isLoading || dmasQuery.isFetching}
      searchOptions={
        dmasQuery.data?.map(
          dma =>
            ({
              label: dma.name,
              key: `${dma.id}`,
              onClick: () => {
                props.onOptionClick?.(dma)
              }
            } as EuiSelectableOption)
        ) ?? []
      }
      onValueChange={onSearchValueChanged}
    />
  )
}
