import React from 'react'
import { useState } from 'react'

import { EuiButtonEmpty, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from '@elastic/eui'

export interface IAcTag {
  key: string
  disabled: boolean
  text: string
}

export interface IAcTagPicker {
  tags: IAcTag[]
  onAddTag?: (tag: IAcTag) => void
  onRemoveTag?: (tag: IAcTag) => void
}

export const AcTagPicker: React.FC<IAcTagPicker> = props => {
  const [fieldValue, setFieldValue] = useState<string>('')

  const onAddTagClick = () => {
    setFieldValue('')
    props.onAddTag?.({ key: fieldValue, disabled: false, text: fieldValue })
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>
        <EuiFieldText
          value={fieldValue}
          onChange={e => {
            setFieldValue(e.target.value)
          }}
          append={<EuiButtonEmpty onClick={onAddTagClick}>Add</EuiButtonEmpty>}
        />
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup gutterSize={'s'} wrap>
          {props.tags.map(tag => {
            return (
              <EuiFlexItem grow={false} key={tag.text}>
                <EuiButtonEmpty
                  iconType={'cross'}
                  onClick={() => {
                    props.onRemoveTag?.(tag)
                  }}>
                  {tag.text}
                </EuiButtonEmpty>
              </EuiFlexItem>
            )
          })}
        </EuiFlexGroup>
      </EuiFormRow>
    </React.Fragment>
  )
}
