import { useEffect, useState } from 'react'

import { Audience, CampaignType, GeoLocationTarget, IDemographics, SegmentOperator, SmartTargeting } from 'api'
import { LocationTargetingType, useGetActiveCampaignsQuery, useGetCampaignQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'

export interface IAccountCampaign {
  locations: GeoLocationTarget
  segmentIds: number[]
  demographics: IDemographics
  smartTargetings: SmartTargeting[]
}

export const useGetAccountCampaignTargeting = (id?: string) => {
  const campaignsQuery = useGetActiveCampaignsQuery({ accountId: id }, { skip: !id })

  const [campaignTargeting, setCampaignTargeting] = useState<IAccountCampaign>({
    locations: {
      countries: [],
      regions: [],
      dmas: [],
      cities: [],
      postalCodes: [],
      geoCircles: [],
      geoRectangles: [],
      geoPolygons: [],
      geoFences: []
    },
    segmentIds: [],
    demographics: {
      ageRanges: [],
      genders: [],
      householdIncomes: []
    },
    smartTargetings: []
  })

  useEffect(() => {
    if (!campaignsQuery.data) {
      return
    }

    const targeting: IAccountCampaign = {
      locations: {
        countries: [],
        regions: [],
        dmas: [],
        cities: [],
        postalCodes: [],
        geoCircles: [],
        geoRectangles: [],
        geoPolygons: [],
        geoFences: []
      },
      segmentIds: [],
      demographics: {
        ageRanges: [],
        genders: [],
        householdIncomes: []
      },
      smartTargetings: []
    }
    const campaigns = campaignsQuery.data
    campaigns.forEach(campaign => {
      const advancedTargeting = campaign.advancedTargeting
      if (campaign.campaignType === 'Internet' && advancedTargeting) {
        targeting.locations = {
          countries: [...targeting.locations?.countries.filter(c => advancedTargeting.locations?.countries?.every(a => a.id !== c.id)), ...(advancedTargeting.locations?.countries ?? [])],
          regions: [...targeting.locations?.regions.filter(c => advancedTargeting.locations?.regions?.every(a => a.id !== c.id)), ...(advancedTargeting.locations?.regions ?? [])],
          dmas: [...targeting.locations?.dmas.filter(c => advancedTargeting.locations?.dmas?.every(a => a.id !== c.id)), ...(advancedTargeting.locations?.dmas ?? [])],
          cities: [...targeting.locations?.cities.filter(c => advancedTargeting.locations?.cities?.every(a => a.id !== c.id)), ...(advancedTargeting.locations?.cities ?? [])],
          postalCodes: [...targeting.locations?.postalCodes.filter(c => advancedTargeting.locations?.postalCodes?.every(a => a.id !== c.id)), ...(advancedTargeting.locations?.postalCodes ?? [])],
          geoFences: [...(targeting.locations?.geoFences?.filter(c => advancedTargeting.geoFences?.every(a => a.placeId !== c.placeId)) ?? []), ...(advancedTargeting.geoFences ?? [])],
          geoCircles: [...(targeting.locations?.geoCircles?.filter(c => advancedTargeting.geoCircles?.every(a => a.id !== c.id)) ?? []), ...(advancedTargeting.geoCircles ?? [])],
          geoPolygons: [...(targeting.locations?.geoPolygons?.filter(c => advancedTargeting.geoPolygons?.every(a => a.id !== c.id)) ?? []), ...(advancedTargeting.geoPolygons ?? [])],
          geoRectangles: [...(targeting.locations?.geoRectangles?.filter(c => advancedTargeting.geoRectangles?.every(a => a.id !== c.id)) ?? []), ...(advancedTargeting.geoRectangles ?? [])],
          uploadedAudienceUrl: advancedTargeting.locations?.uploadedAudienceUrl
        }

        targeting.segmentIds = [...targeting.segmentIds.filter(c => advancedTargeting.segments?.every(a => a.id !== c)), ...(advancedTargeting.segments?.map(s => s.id) ?? [])]

        targeting.demographics = {
          ageRanges: [...targeting.demographics.ageRanges.filter(c => advancedTargeting.ageRanges?.every(a => a !== c)), ...(advancedTargeting.ageRanges ?? [])],
          genders: [...targeting.demographics.genders.filter(g => advancedTargeting.genders?.every(a => a !== g)), ...advancedTargeting.genders],
          householdIncomes: [...targeting.demographics.householdIncomes.filter(h => advancedTargeting.householdIncomes?.every(a => a !== h)), ...(advancedTargeting.householdIncomes ?? [])]
        }
        return
      }

      const billboardTargeting = campaign.billboardTargeting
      if (campaign.targetingTemplate === 'Billboard' && billboardTargeting) {
        console.log(billboardTargeting)
        targeting.locations = {
          countries: [...targeting.locations?.countries],
          regions: [...targeting.locations?.regions.filter(c => billboardTargeting.locations?.regions?.every(a => a.id !== c.id)), ...(billboardTargeting.locations?.regions ?? [])],
          dmas: [...targeting.locations?.dmas.filter(c => billboardTargeting.locations?.dmas?.every(a => a.id !== c.id)), ...(billboardTargeting.locations?.dmas ?? [])],
          cities: [...targeting.locations?.cities.filter(c => billboardTargeting.locations?.cities?.every(a => a.id !== c.id)), ...(billboardTargeting.locations?.cities ?? [])],
          postalCodes: [...targeting.locations?.postalCodes.filter(c => billboardTargeting.locations?.postalCodes?.every(a => a.id !== c.id)), ...(billboardTargeting.locations?.postalCodes ?? [])],
          geoFences: [...(targeting.locations?.geoFences ?? [])],
          geoCircles: [...(targeting.locations?.geoCircles ?? [])],
          geoPolygons: [...(targeting.locations?.geoPolygons ?? [])],
          geoRectangles: [...(targeting.locations?.geoRectangles ?? [])]
        }
        return
      }

      const tvTargeting = campaign.tvTargeting
      if (tvTargeting && (campaign.targetingTemplate === 'TV' || campaign.targetingTemplate === 'ShopifyTV')) {
        targeting.locations = {
          countries: [...targeting.locations?.countries.filter(c => tvTargeting.locations?.countries?.every(a => a.id !== c.id)), ...(tvTargeting.locations?.countries ?? [])],
          regions: [...targeting.locations?.regions.filter(c => tvTargeting.locations?.regions?.every(a => a.id !== c.id)), ...(tvTargeting.locations?.regions ?? [])],
          dmas: [...targeting.locations?.dmas.filter(c => tvTargeting.locations?.dmas?.every(a => a.id !== c.id)), ...(tvTargeting.locations?.dmas ?? [])],
          cities: [...targeting.locations?.cities.filter(c => tvTargeting.locations?.cities?.every(a => a.id !== c.id)), ...(tvTargeting.locations?.cities ?? [])],
          postalCodes: [...targeting.locations?.postalCodes.filter(c => tvTargeting.locations?.postalCodes?.every(a => a.id !== c.id)), ...(tvTargeting.locations?.postalCodes ?? [])],
          geoFences: [...(targeting.locations?.geoFences ?? [])],
          geoCircles: [...(targeting.locations?.geoCircles ?? [])],
          geoPolygons: [...(targeting.locations?.geoPolygons ?? [])],
          geoRectangles: [...(targeting.locations?.geoRectangles ?? [])]
        }

        targeting.segmentIds = [...targeting.segmentIds.filter(c => tvTargeting.segments?.every(a => a.id !== c)), ...(tvTargeting.segments?.map(s => s.id) ?? [])]
        targeting.demographics = {
          ageRanges: [...targeting.demographics.ageRanges.filter(c => tvTargeting.ageRanges?.every(a => a !== c)), ...(tvTargeting.ageRanges ?? [])],
          genders: [...targeting.demographics.genders.filter(g => tvTargeting.genders?.every(a => a !== g)), ...tvTargeting.genders],
          householdIncomes: [...targeting.demographics.householdIncomes.filter(h => tvTargeting.householdIncomes?.every(a => a !== h)), ...(tvTargeting.householdIncomes ?? [])]
        }

        return
      }
      if (campaign.targetingTemplate === 'Standard' && campaign.smartTargeting) {
        targeting.smartTargetings.push(campaign.smartTargeting)
      }
    })
    setCampaignTargeting(targeting)
  }, [campaignsQuery.data])

  return campaignTargeting
}
