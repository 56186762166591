import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

export interface IAcListGroupItem {
  key: string
  label: string
}

export interface IAcTargeterSelection {
  items: IAcListGroupItem[]
  onItemRemoved?: (item: IAcListGroupItem) => void
}

export const AcTargeterSelection: React.FC<IAcTargeterSelection> = props => {
  const onItemRemoved = (item: IAcListGroupItem) => {
    props.onItemRemoved?.(item)
  }

  return (
    <EuiListGroup bordered maxWidth='100%' gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
      {props.items.map(s => (
        <EuiListGroupItem
          size='xs'
          key={s.key}
          label={s.label}
          extraAction={
            props.onItemRemoved
              ? {
                  onClick: () => {
                    onItemRemoved(s)
                  },
                  iconType: 'cross',
                  iconSize: 's',
                  'aria-label': 'Remove',
                  alwaysShow: true
                }
              : undefined
          }
        />
      ))}
    </EuiListGroup>
  )
}
