import Konva from 'konva'
import React, { useEffect } from 'react'
import { Layer, Rect, Stage } from 'react-konva'
import { v4 as uuidv4 } from 'uuid'

import { EuiButtonEmpty, EuiFlexGroup, EuiFormRow, EuiLoadingSpinner } from '@elastic/eui'

import { NativeDetails } from 'api'
import { AdCreatorLargeBanner } from 'components/AdCreation/AdCreators/AdCreatorLargeBanner'
import { AdCreatorLargeTower } from 'components/AdCreation/AdCreators/AdCreatorLargeTower'
import { AdCreatorLongBanner } from 'components/AdCreation/AdCreators/AdCreatorLongBanner'
import { AdCreatorNativeMed } from 'components/AdCreation/AdCreators/AdCreatorNativeMed'
import { AdCreatorRectangle } from 'components/AdCreation/AdCreators/AdCreatorRectangle'
import { AdCreatorSmallBanner } from 'components/AdCreation/AdCreators/AdCreatorSmallBanner'

export enum AdFormat {
  NativeMed = 'NativeMed',
  SmallTower = 'SmallTower',
  LargeTower = 'LargeTower',
  MobileBanner = 'MobileBanner',
  LongBanner = 'LongBanner',
  LargeBanner = 'LargeBanner',
  Rectangle = 'Rectangle',
  LongRectangle = 'LongRectangle'
}

export interface IDisplayMaker {
  maxHeight?: number
  maxWidth?: number
  details: NativeDetails
  uploadedUrl: string
  adWidth: number
  adHeight: number
  format: AdFormat
  onCapture?: (tempAd: TempDisplayAd) => void
  colorPalette: ColorPalette
  contentSpecifications: ContentSpecifications
}

export interface ColorPalette {
  prominentColor: string
  baseColor: string
  baseColor2: string
  backgroundColor: string
  backgroundColor2: string
  buttonColor: string
  buttonColor2: string
}

export enum ContentFocus {
  Headline = 'Headline',
  CompanyName = 'CompanyName',
  Image = 'Image'
}

export enum FocusArea {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right'
}

export interface ContentSpecifications {
  contentFocus: ContentFocus
  focusArea: FocusArea
}

export interface TempDisplayAd {
  key: string
  adUri: string
  width: number
  height: number
  destinationUrl: string
}

export const DisplayMaker: React.FC<IDisplayMaker> = props => {
  const stageRef = React.useRef<Konva.Stage>(null)
  const borderLayerRef = React.useRef<Konva.Layer>(null)

  const [scale, setScale] = React.useState(1)

  const colorPalette = props.colorPalette

  useEffect(() => {
    if (props.maxHeight && !props.maxWidth) {
      setScale(props.adHeight > props.maxHeight ? props.maxHeight / props.adHeight : 1)
    } else if (!props.maxHeight && props.maxWidth) {
      setScale(props.adWidth > props.maxWidth ? props.maxWidth / props.adWidth : 1)
    } else if (props.maxHeight && props.maxWidth) {
      if (props.adWidth > props.maxWidth && props.adHeight <= props.maxHeight) {
        setScale(props.maxWidth / props.adWidth)
      } else if (props.adWidth <= props.maxWidth && props.adHeight > props.maxHeight) {
        setScale(props.maxHeight / props.adHeight)
      } else if (props.adWidth > props.maxWidth && props.adHeight > props.maxHeight) {
        setScale(Math.min(props.maxWidth / props.adWidth, props.maxHeight / props.adHeight))
      }
    }
  }, [props.maxWidth, props.maxHeight, props.adWidth, props.adHeight])

  useEffect(() => {
    if (props.format === AdFormat.NativeMed) {
      const timeout = setTimeout(onItemClicked, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [props.format, scale])

  const onItemClicked = () => {
    if (props.onCapture) {
      props.onCapture({
        key: uuidv4(),
        adUri: stageRef.current?.toDataURL({ pixelRatio: 1 / scale }) ?? '',
        width: props.adWidth,
        height: props.adHeight,
        destinationUrl: props.details.destinationUrl
      })
    }
  }

  const onStageClick = () => {
    if (stageRef.current === null) {
      return
    }
    const uri = stageRef.current.toDataURL({ pixelRatio: 1 / scale })
    const link = document.createElement('a')
    link.download = 'image.png'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (colorPalette.baseColor.length <= 0) return <EuiLoadingSpinner />

  const scaledWidth = props.adWidth * scale
  const scaledHeight = props.adHeight * scale

  return (
    <div style={{ maxWidth: props.maxWidth ?? 400 }}>
      <EuiFlexGroup>
        <EuiButtonEmpty onClick={onItemClicked} size={'s'}>
          Capture
        </EuiButtonEmpty>
      </EuiFlexGroup>
      <EuiFormRow label={`${props.adWidth}x${props.adHeight}`} fullWidth>
        <Stage scaleX={scale} scaleY={scale} width={scaledWidth} height={scaledHeight} ref={stageRef} onClick={onStageClick}>
          {props.format === AdFormat.NativeMed && <AdCreatorNativeMed {...props} />}
          {props.format === AdFormat.MobileBanner && <AdCreatorSmallBanner {...props} />}
          {props.format === AdFormat.LargeBanner && <AdCreatorLargeBanner {...props} />}
          {props.format === AdFormat.Rectangle && <AdCreatorRectangle {...props} />}
          {props.format === AdFormat.LargeTower && <AdCreatorLargeTower {...props} />}
          {props.format === AdFormat.LongRectangle && <AdCreatorSmallBanner {...props} />}
          {props.format === AdFormat.LongBanner && <AdCreatorLongBanner {...props} />}
          <Layer name={'borderLayerRef'} ref={borderLayerRef}>
            <Rect x={0} y={0} width={props.adWidth} height={props.adHeight} stroke={'#000'} strokeWidth={2} />
          </Layer>
        </Stage>
      </EuiFormRow>
    </div>
  )
}
