import React, { useEffect, useState } from 'react'

import { EuiLink, EuiStat } from '@elastic/eui'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

export const TvOutrosPending: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [count, setCount] = useState(0)
  const opsClient = useOpsClient()

  useEffect(() => {
    let isMounted = true
    if (opsClient && isMounted) {
      setIsLoading(true)

      opsClient.getCountAdsPendingOutro().then(c => {
        if (isMounted) {
          setCount(c)
          setIsLoading(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient])

  return (
    <EuiStat
      description={
        <EuiLink onClick={() => history.push('/customers/campaignsNeedingOutros')} style={{ whiteSpace: 'nowrap' }}>
          TV Outros
        </EuiLink>
      }
      title={count}
      isLoading={isLoading}
      titleColor='accent'
    />
  )
}
