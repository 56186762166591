import { Moment } from 'moment'

import { CampaignType } from 'api/entities/Campaign'
import { IPayment } from 'api/entities/Invoice'
import { Package } from 'api/entities/Package'
import { Plan } from 'api/entities/Plan'
import { SelfServicePackage } from 'api/entities/SelfServicePackage'
import { VendastaPackage } from 'api/entities/VendastaPackage'
import { NoteAddRequest } from 'api/rtkQueryApi/requestModels/NoteModels'

export type Bundle = Package | Plan | VendastaPackage | SelfServicePackage

export interface BaseBundle {
  id: string
  name: string
  organizationId: string
  accountId: string
  bundleStatus: BundleStatus
  bundleActiveStatus: BundleActiveStatus
  bundleType: BundleType
  bundleCategory: BundleCategory
  targetingPriority: TargetingPriority
  price: number
  isRollover: boolean
  isIndefinite: boolean
  campaignSlots: CampaignSlot[]
  incentives: Incentive[]
  billingSchedule: ScheduleEvent
  isWatchListed: boolean
  created: Moment | null
  updated: Moment | null
  createdBy: string
  updatedBy: string
  salesAgentId: string
}

export interface Incentive {}

export interface CommissionDefinition {}

export interface ScheduleEvent {
  id: string
  isIndefinite: boolean
  intervalUnit: IntervalUnit
  intervalUnitFrequency: number
  totalIntervals: number
  intervalsCompleted: number
  thirdPartyReferenceId: string
  lastIntervalDate: Moment | null
  nextEventDate: Moment | null
  createdDate: Moment | null
  startDate: Moment | null
  updatedAt: Moment | null
  createdBy: string
  updatedBy: string
}

export interface BundleActivation {
  bundleId: string
  bundleName: string
  bundleStatus: BundleStatus
  accountId: string
  organizationId: string
  accountName: string
  organizationName: string
  businessTypeName: string
  partnerName: string
  partnerGroup: string
  budget: number
  budgetFrequency: string
  activated: Moment | null
}

export interface CreateBundleRequest {
  name: string
  organizationId: string
  accountId?: string
  bundleType: BundleType
  includeSubscription: boolean
  subscriptionIncentiveDuration: number
  isSubscriptionIncentiveIndefinite: boolean
  includeMatching: boolean
  matchingIncentiveDuration: number
  isMatchingIncentiveIndefinite: boolean
  intervalUnitFrequency: number
  intervalUnit: IntervalUnit
  totalIntervals: number
  price: number
  isIndefinite: boolean
  isRollover: boolean
  bundleCategory: BundleCategory
  targetingPriority: TargetingPriority
  isWatchListed: boolean
  paymentMethodId?: string
  isPrepaid: boolean
  campaignSlots: CreateCampaignSlotRequest[]
  paymentSplitRequests: IPayment[]
  noteAddRequest?: NoteAddRequest
  startDate?: Moment
  salesAgentId?: string
}

export interface UpdateBundleRequest {
  bundleId: string
  name: string
  isWatchListed: boolean
  bundleCategory: BundleCategory
  targetingPriority: TargetingPriority
}

export interface CreateCampaignSlotRequest {
  campaignType: string
  budget: number
  isBonus: boolean
  assignedCampaignId?: string
}

export interface BundleBillingView {
  isAgency: boolean
  organizationName: string
  accountName: string
  totalRevenue: number
  lastInvoicePaid: boolean
  nextPaymentDate: Moment | null
  bundle: Bundle
}

export interface OrganizationManagerBundle {
  organizationManagerId: string
  organizationId: string
  organizationName: string
  campaignAdvisorId: string
  firstImpression: Moment | null
  dateOfFulfillment: Moment | null
  bundle: Bundle
}

export interface CampaignSlot {
  id: string
  budget: number
  createdDate: Moment | null
  campaignId: string
  isBonus: boolean
  campaignType: CampaignType
  status: CampaignSlotStatus
  flightSchedule: ScheduleEvent
}
export interface CampaignSlotSimple {
  id: string
  isBonus?: boolean
  campaignType: CampaignType
  budget: number
  assignedCampaignId?: string
}

export enum IntervalUnit {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month'
}

export enum BundleCategory {
  Regular = 'Regular',
  LeadGeneration = 'LeadGeneration',
  Event = 'Event',
  ShortTerm = 'ShortTerm',
  Political = 'Political',
  SelfService = 'SelfService'
}

export enum TargetingPriority {
  Location = 'Location',
  AudienceSegment = 'AudienceSegment'
}

export enum BundleStatus {
  New = 'New',
  RollingOver = 'RollingOver',
  Onboarding = 'Onboarding',
  Onboarded = 'Onboarded',
  EndingOn = 'EndingOn',
  Ended = 'Ended',
  PartiallyOnboarded = 'PartiallyOnboarded',
  Live = 'Live',
  PaymentRetrying = 'PaymentRetrying',
  PaymentFailed = 'PaymentFailed',
  Completed = 'Completed',
  OnHoldUntil = 'OnHoldUntil',
  Deleted = 'Deleted'
}

export enum BundleActiveStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum CampaignSlotStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum BundleType {
  Package = 'Package',
  Plan = 'Plan',
  VendastaPackage = 'VendastaPackage',
  SelfServicePackage = 'SelfServicePackage'
}
