import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiToolTip, EuiButtonIcon, EuiButtonEmpty, htmlIdGenerator, EuiText, EuiCallOut } from '@elastic/eui'

import { Phrase, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface BusinessTypePhrasesTabParams {
  businessTypeId: string
}

interface FormValues {
  phrases: Array<Phrase>
}

const BusinessTypePhrasesTab: React.FC<BusinessTypePhrasesTabParams> = ({ businessTypeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [nounPhrasesInitial, setNounPhrasesInitial] = useState<FormValues>({ phrases: [] })
  const [youActionPhrasesInitial, setYouActionPhrasesInitial] = useState<FormValues>({ phrases: [] })
  const [weActionPhrasesInitial, setWeActionPhrasesInitial] = useState<FormValues>({ phrases: [] })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [canGenerate, setCanGenerate] = useState(false)
  const opsClient = useOpsClient()

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
      setCanGenerate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getBusinessType(businessTypeId).then(result => {
        setNounPhrasesInitial({ phrases: result.nounPhrases })
        setYouActionPhrasesInitial({ phrases: result.youActionPhrases })
        setWeActionPhrasesInitial({ phrases: result.weActionPhrases })
        setIsLoading(false)
      })
    }
  }, [opsClient, businessTypeId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const businessTypePhrasesSchema = Yup.object().shape({
    phrases: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.string().required('Empty phrases are not allowed')
      })
    )
  })

  const doNounPhrasesSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.setBusinessTypeNounPhrases(businessTypeId, { phrases: values.phrases }).then(result => {
      setSubmitting(false)
      history.push(`/data/businessTypes/${businessTypeId}`)
    })
  }

  const doWeActionPhrasesSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.setBusinessTypeWeActionPhrases(businessTypeId, { phrases: values.phrases }).then(result => {
      setSubmitting(false)
      history.push(`/data/businessTypes/${businessTypeId}`)
    })
  }

  const doYouActionPhrasesSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.setBusinessTypeYouActionPhrases(businessTypeId, { phrases: values.phrases }).then(result => {
      setSubmitting(false)
      history.push(`/data/businessTypes/${businessTypeId}`)
    })
  }

  const regenerate = () => {
    setIsGenerating(true)
    opsClient?.regenerateCouponBusinessType(businessTypeId)
    opsClient?.regenerateBusinessType(businessTypeId).then(result => {
      setIsGenerating(false)
    })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <Formik initialValues={nounPhrasesInitial} enableReinitialize validationSchema={businessTypePhrasesSchema} onSubmit={doNounPhrasesSubmit}>
            {props => (
              <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                <EuiCallOut title='Noun Phrase' size='s' color='success'>
                  <EuiText size='s'>
                    The place to find ____
                    <br />
                    When you want ____
                  </EuiText>
                </EuiCallOut>
                <EuiSpacer size='s' />
                <FieldArray
                  name='phrases'
                  render={arrayHelpers => (
                    <React.Fragment>
                      {props.values.phrases &&
                        props.values.phrases.length > 0 &&
                        props.values.phrases.map((phrase, index) => (
                          <EuiFlexGroup gutterSize='xs' key={index}>
                            <EuiFlexItem grow={false}>
                              <EuiFlexGroup alignItems='center'>
                                <EuiFlexItem grow={false}>
                                  <EuiToolTip position='top' content='Remove this Phrase'>
                                    <EuiButtonIcon id='removeNounPhrase' iconType='cross' aria-label='remove' color='warning' onClick={() => arrayHelpers.remove(index)} />
                                  </EuiToolTip>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiFormRow label={index === 0 ? 'Noun Phrase' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `phrases[${index}]`) && !!getIn(props.errors, `phrases[${index}].value`)} error={getIn(props.errors, `phrases[${index}].value`)}>
                                <EuiFieldText name={`phrases[${index}].value`} value={getIn(props.values, `phrases[${index}].value`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `phrases[${index}].value`) && !!getIn(props.errors, `phrases[${index}].value`)} />
                              </EuiFormRow>
                              <input type='hidden' name={`phrases[${index}].id`} value={getIn(props.values, `phrases[${index}].id`)} />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        ))}
                      <EuiSpacer size='s' />
                      <EuiButtonEmpty id='addNounPhrase' size='s' onClick={() => arrayHelpers.push({ value: '', id: htmlIdGenerator()() } as Phrase)} isDisabled={!canUpdate}>
                        Add {props.values.phrases.length > 0 && 'Another'} Noun Phrase
                      </EuiButtonEmpty>
                    </React.Fragment>
                  )}
                />
                <EuiSpacer />
                <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
                  Save Noun Phrases
                </EuiButton>
              </EuiForm>
            )}
          </Formik>
        </EuiFlexItem>
        <EuiFlexItem>
          <Formik initialValues={weActionPhrasesInitial} enableReinitialize validationSchema={businessTypePhrasesSchema} onSubmit={doWeActionPhrasesSubmit}>
            {props => (
              <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                <EuiCallOut title='We Action Phrase' size='s' color='success'>
                  <EuiText size='s'>
                    We will ____
                    <br />
                    The place where we ____
                  </EuiText>
                </EuiCallOut>
                <EuiSpacer size='s' />
                <FieldArray
                  name='phrases'
                  render={arrayHelpers => (
                    <React.Fragment>
                      {props.values.phrases &&
                        props.values.phrases.length > 0 &&
                        props.values.phrases.map((phrase, index) => (
                          <EuiFlexGroup gutterSize='xs' key={index}>
                            <EuiFlexItem grow={false}>
                              <EuiFlexGroup alignItems='center'>
                                <EuiFlexItem grow={false}>
                                  <EuiToolTip position='top' content='Remove this Phrase'>
                                    <EuiButtonIcon id='removeWeActionPhrase' iconType='cross' aria-label='remove' color='warning' onClick={() => arrayHelpers.remove(index)} />
                                  </EuiToolTip>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiFormRow label={index === 0 ? 'We-Action Phrase' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `phrases[${index}]`) && !!getIn(props.errors, `phrases[${index}].value`)} error={getIn(props.errors, `phrases[${index}].value`)}>
                                <EuiFieldText name={`phrases[${index}].value`} value={getIn(props.values, `phrases[${index}].value`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `phrases[${index}].value`) && !!getIn(props.errors, `phrases[${index}].value`)} />
                              </EuiFormRow>
                              <input type='hidden' name={`phrases[${index}].id`} value={getIn(props.values, `phrases[${index}].id`)} />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        ))}
                      <EuiSpacer size='s' />
                      <EuiButtonEmpty id='addWeActionPhrase' size='s' onClick={() => arrayHelpers.push({ value: '', id: htmlIdGenerator()() } as Phrase)} isDisabled={!canUpdate}>
                        Add {props.values.phrases.length > 0 && 'Another'} We-Action Phrase
                      </EuiButtonEmpty>
                    </React.Fragment>
                  )}
                />
                <EuiSpacer />
                <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
                  Save We-Action Phrases
                </EuiButton>
              </EuiForm>
            )}
          </Formik>
        </EuiFlexItem>
        <EuiFlexItem>
          <Formik initialValues={youActionPhrasesInitial} enableReinitialize validationSchema={businessTypePhrasesSchema} onSubmit={doYouActionPhrasesSubmit}>
            {props => (
              <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                <EuiCallOut title='You Action Phrase' size='s' color='success'>
                  <EuiText size='s'>
                    When you want to ____
                    <br />
                    The best place to ____
                  </EuiText>
                </EuiCallOut>
                <EuiSpacer size='s' />
                <FieldArray
                  name='phrases'
                  render={arrayHelpers => (
                    <React.Fragment>
                      {props.values.phrases &&
                        props.values.phrases.length > 0 &&
                        props.values.phrases.map((phrase, index) => (
                          <EuiFlexGroup gutterSize='xs' key={index}>
                            <EuiFlexItem grow={false}>
                              <EuiFlexGroup alignItems='center'>
                                <EuiFlexItem grow={false}>
                                  <EuiToolTip position='top' content='Remove this Phrase'>
                                    <EuiButtonIcon id='removeYouActionPhrase' iconType='cross' aria-label='remove' color='warning' onClick={() => arrayHelpers.remove(index)} />
                                  </EuiToolTip>
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiFormRow label={index === 0 ? 'You-Action Phrase' : ''} fullWidth display='rowCompressed' isInvalid={getIn(props.touched, `phrases[${index}]`) && !!getIn(props.errors, `phrases[${index}].value`)} error={getIn(props.errors, `phrases[${index}].value`)}>
                                <EuiFieldText name={`phrases[${index}].value`} value={getIn(props.values, `phrases[${index}].value`)} onChange={props.handleChange} isInvalid={getIn(props.touched, `phrases[${index}].value`) && !!getIn(props.errors, `phrases[${index}].value`)} />
                              </EuiFormRow>
                              <input type='hidden' name={`phrases[${index}].id`} value={getIn(props.values, `phrases[${index}].id`)} />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        ))}
                      <EuiSpacer size='s' />
                      <EuiButtonEmpty id='addYouActionPhrase' size='s' onClick={() => arrayHelpers.push({ value: '', id: htmlIdGenerator()() } as Phrase)} isDisabled={!canUpdate}>
                        Add {props.values.phrases.length > 0 && 'Another'} You-Action Phrase
                      </EuiButtonEmpty>
                    </React.Fragment>
                  )}
                />
                <EuiSpacer />
                <EuiButton isLoading={props.isSubmitting} isDisabled={!canUpdate} fill type='submit'>
                  Save You-Action Phrases
                </EuiButton>
              </EuiForm>
            )}
          </Formik>
        </EuiFlexItem>
      </EuiFlexGroup>
      <div hidden={!canGenerate}>
        <EuiSpacer />
        <EuiButton isLoading={isGenerating} fill type='button' color='danger' onClick={regenerate}>
          Regenerate
        </EuiButton>
      </div>
    </React.Fragment>
  )
}

export default BusinessTypePhrasesTab
