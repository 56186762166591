import React, { useState } from 'react'

import {
  EuiButton, EuiFieldNumber, EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle, EuiRadioGroup, EuiText,
} from '@elastic/eui'

import {Invoice} from 'api/entities/Invoice'
import {useRefundInvoiceMutation} from "api/rtkQueryApi/opsApi/invoicesApi";

export interface IInvoiceRefundModal {
  organizationId: string
  invoice: Invoice
  onCloseClicked?: () => void
}

export const InvoiceRefundModal: React.FC<IInvoiceRefundModal> = props => {
  const [refundAmount, setRefundAmount] = useState<number>(0)
  const [refundReason, setRefundReason] = useState<string>("customerRequest")
  const remainingAmount = props.invoice.amountPaid - props.invoice.refundAmount
  const [refundInvoice, refundInvoiceRequest] = useRefundInvoiceMutation()

  const onSaveClicked = () => {
    refundInvoice({
      invoiceId: props.invoice.id,
      organizationId: props.organizationId,
      refundAmount: refundAmount,
      isFraudulent: refundReason === "fraudulent",
      isDuplicate: refundReason === "duplicate",
      isRequestedByCustomer: refundReason === "customerRequest",
    }).then(() => {
          props.onCloseClicked?.()
        }
    )
  }

  const isValid = () => {
    return refundAmount > 0 && refundAmount <= remainingAmount
  }

  const onClose = () => {
    if (props.onCloseClicked) {
      props.onCloseClicked()
    }
  }

  const radios = [
    {
      id: "customerRequest",
      label: 'Requested By Customer',
    },
    {
      id: "fraudulent",
      label: 'Fraudulent',
    },
    {
      id: "duplicate",
      label: 'Duplicate',
    },
  ];

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Refund Invoice</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow helpText={`$${refundAmount} of ${remainingAmount} will be refunded`}>
          <EuiText>
            Refund User for the ${remainingAmount} Paid
          </EuiText>
        </EuiFormRow>
        <EuiFormRow label={'Reason'} >
          <EuiRadioGroup
              options={radios}
              idSelected={refundReason}
              onChange={(e) => {
                setRefundReason(e)
              }}
          />
        </EuiFormRow>
        <EuiFormRow label={'Amount to Refund'} helpText={isValid() ? undefined : 'Refund must be less than the paid amount'}>
          <EuiFieldNumber prepend={'$'} value={refundAmount} onChange={e => {setRefundAmount(Number(e.target.value))}}/>
        </EuiFormRow>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onSaveClicked} isDisabled={!isValid()} fill isLoading={refundInvoiceRequest.isLoading}>
          Save
        </EuiButton>
        <EuiButton onClick={onClose} isLoading={refundInvoiceRequest.isLoading}>Cancel</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}
