import moment from 'moment-timezone'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  EuiForm,
  EuiButton,
  EuiButtonEmpty,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderBreadcrumbs,
  EuiImage,
  EuiLink,
  EuiLoadingContent,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPage,
  EuiPageBody,
  EuiPageContent_Deprecated as EuiPageContent,
  EuiPageContentBody_Deprecated as EuiPageContentBody,
  EuiPageContentHeader_Deprecated as EuiPageContentHeader,
  EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiFormRow,
  EuiSuperSelect,
  EuiBreadcrumbs,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiCopy,
  EuiBadge
} from '@elastic/eui'

import { PendingAuditInternetAd } from 'api'
import { useApproveInternetAdMutation, useDisapproveInternetAdMutation, useGetInternetPendingAuditQuery, useGetInternetPendingAuditsQuery } from 'api/rtkQueryApi/opsApi/adsApi'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { getAdDisapproveOptions } from 'features/customers/ads/AdDisapproveOptions'
import history from 'services/HistoryService'
import { useDownloadUrl } from 'utils/useDownloadUrl'
import { useGetCampaignTargeting } from 'utils/useGetCampaignTargeting'

interface IInternetAdPendingAuditPage {
  adId: string
}

const InternetAdPendingAuditPage: React.FC<IInternetAdPendingAuditPage> = ({ adId }) => {
  const internetPendingAuditQuery = useGetInternetPendingAuditsQuery({})
  const [disapproveInternetAd, disapproveInternetAdQuery] = useDisapproveInternetAdMutation()
  const [approveInternetAd, approveInternetAdQuery] = useApproveInternetAdMutation()
  const pendingAdQuery = useGetInternetPendingAuditQuery(adId)
  const [nextPendingAd, setNextPendingAd] = useState<PendingAuditInternetAd>()
  const [prevPendingAd, setPrevPendingAd] = useState<PendingAuditInternetAd>()
  const [showDisapprove, setShowDisapprove] = useState(false)
  const [superSelectValue, setSuperSelectValue] = useState('Image quality issue(s)')
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canAudit, setCanAudit] = useState(false)

  const campaignTargeting = useGetCampaignTargeting(pendingAdQuery.data?.campaignId)

  const [downloadImage] = useDownloadUrl(pendingAdQuery.data?.displayAdUrl ?? pendingAdQuery.data?.highQualityUrl ?? '')

  useEffect(() => {
    if (internetPendingAuditQuery.data && internetPendingAuditQuery.data.length > 0) {
      const index = internetPendingAuditQuery.data.findIndex(a => a.adId == adId)
      let nextIndex = index + 1
      let prevIndex = index - 1
      if (nextIndex === internetPendingAuditQuery.data.length) {
        nextIndex = 0
      }
      if (prevIndex < 0) {
        prevIndex = internetPendingAuditQuery.data.length - 1
      }

      setNextPendingAd(internetPendingAuditQuery.data[nextIndex])
      setPrevPendingAd(internetPendingAuditQuery.data[prevIndex])
    }
  }, [internetPendingAuditQuery.data, adId])

  useEffect(() => {
    if (userProfile) {
      setCanAudit(userProfile.roles.includes('adcritter-ops-auditor') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  const isValidUrl = useCallback(() => {
    if (!pendingAdQuery.data) {
      return false
    }
    try {
      return Boolean(new URL(pendingAdQuery.data.destinationUrl)) && (pendingAdQuery.data.destinationUrl.startsWith('http://') || pendingAdQuery.data.destinationUrl.startsWith('https://'))
    } catch (e) {
      return false
    }
  }, [pendingAdQuery.data])

  if (!pendingAdQuery.data || pendingAdQuery.isLoading) {
    return (
      <EuiPage>
        <EuiPageBody component='div'>
          <EuiPageContent>
            <EuiLoadingContent />
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    )
  }

  const pendingAd = pendingAdQuery.data

  const approveAd = () => {
    approveInternetAd({ adId }).then(() => {
      setShowDisapprove(false)
    })
  }

  const disapproveAd = () => {
    disapproveInternetAd({ adId, reason: superSelectValue }).then(() => {
      setShowDisapprove(false)
    })
  }

  const closeModal = () => setShowDisapprove(false)

  const showModal = () => setShowDisapprove(true)

  const onSuperSelectChange = (value: string) => {
    setSuperSelectValue(value)
  }

  const superSelectOptions = getAdDisapproveOptions()

  let modal

  const isWorking = disapproveInternetAdQuery.isLoading || approveInternetAdQuery.isLoading || internetPendingAuditQuery.isLoading

  if (showDisapprove) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Disapprove Ad</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiForm>
              <EuiFormRow label='Please choose the reason you are rejecting this ad'>
                <EuiSuperSelect options={superSelectOptions} valueOfSelected={superSelectValue} onChange={value => onSuperSelectChange(value)} itemLayoutAlign='top' hasDividers />
              </EuiFormRow>
            </EuiForm>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal} disabled={isWorking}>
              Cancel
            </EuiButtonEmpty>
            <EuiButton onClick={disapproveAd} fill color='danger' isLoading={isWorking}>
              Disapprove
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  const onNextClick = () => {
    if (!!nextPendingAd) {
      history.push(`/customers/pending/ads/audit/${nextPendingAd.adId}`)
    }
  }

  const onPreviousClick = () => {
    if (!!prevPendingAd) {
      history.push(`/customers/pending/ads/audit/${prevPendingAd.adId}`)
    }
  }

  return (
    <React.Fragment>
      <EuiPage>
        <EuiPageBody component='div'>
          <EuiPageContent>
            <EuiBreadcrumbs
              breadcrumbs={[
                { text: 'Customers' },
                {
                  text: 'Ads',
                  href: '/customers/ads',
                  onClick: () => history.push('/customers/ads')
                },
                {
                  text: 'Pending',
                  href: '/customers/pending/ads',
                  onClick: () => history.push('/customers/pending/ads')
                },
                {
                  text: pendingAd.headline,
                  href: `/customers/pending/ads/${pendingAd.adId}`,
                  onClick: () => history.push(`/customers/pending/ads/${pendingAd.adId}`)
                }
              ]}
            />
            <EuiSpacer />
            <EuiPageContentHeader>
              <EuiPageContentHeaderSection>
                <EuiTitle size='m'>
                  <h1>Ad Pending Audit</h1>
                </EuiTitle>
                <EuiCopy textToCopy={adId}>
                  {copy => (
                    <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Ad ID'>
                      {adId}
                    </EuiBadge>
                  )}
                </EuiCopy>
              </EuiPageContentHeaderSection>
              <EuiPageContentHeaderSection>
                {pendingAdQuery && (
                  <EuiHeaderBreadcrumbs
                    responsive={false}
                    breadcrumbs={[
                      {
                        text: pendingAd.organizationName,
                        onClick: () => history.push(`/customers/organizations/${pendingAd.organizationId}`),
                        truncate: true
                      },
                      {
                        text: pendingAd.accountName,
                        onClick: () => history.push(`/customers/accounts/${pendingAd.accountId}`),
                        truncate: true
                      },
                      {
                        text: pendingAd.campaignName,
                        onClick: () => history.push(`/customers/campaigns/${pendingAd.campaignId}`),
                        truncate: true
                      }
                    ]}
                  />
                )}
              </EuiPageContentHeaderSection>
              <EuiPageContentHeaderSection>
                <EuiFlexGroup>
                  {pendingAd && (
                    <EuiFlexItem>
                      <EuiButton href={`${config.PLATFORMURL}gotoAd/${pendingAd.organizationId}/${pendingAd.accountId}/${pendingAd.campaignId}/${pendingAd.adId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text'>
                        AdCritter Platform
                      </EuiButton>
                    </EuiFlexItem>
                  )}
                </EuiFlexGroup>
              </EuiPageContentHeaderSection>
            </EuiPageContentHeader>
            <EuiPageContentBody>
              <EuiFlexGroup>
                {pendingAd!.displayAdUrl ? (
                  <EuiFlexItem>
                    <EuiPanel paddingSize='none' color='plain'>
                      <EuiFlexGroup responsive={false} style={{ paddingTop: 10 }}>
                        <EuiFlexItem style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <EuiText size='s'>
                            <strong>{pendingAd.name}</strong>
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiSpacer size='m' />
                      <EuiFlexItem style={{ alignItems: 'center' }}>
                        <a rel='noreferrer' href={pendingAd!.destinationUrl} target='_blank'>
                          <EuiImage alt='ad' url={pendingAd!.displayAdUrl!} style={{ padding: 20 }} />
                        </a>
                        <EuiFormRow>
                          <EuiButtonEmpty title={'Download'} iconType={'download'} onClick={() => downloadImage(`${pendingAdQuery.data?.adId ?? 'downloadedAd'}.png`)}>
                            Download
                          </EuiButtonEmpty>
                        </EuiFormRow>
                      </EuiFlexItem>
                      <EuiSpacer size='m' />
                    </EuiPanel>
                  </EuiFlexItem>
                ) : (
                  <EuiFlexItem>
                    <EuiPanel paddingSize='none' color='plain'>
                      <EuiFlexGroup responsive={false} style={{ paddingTop: 10 }}>
                        <EuiFlexItem style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <EuiText size='s' color='warning'>
                            <strong>Sponsored</strong>
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiFlexGroup responsive={false}>
                        <EuiFlexItem style={{ paddingRight: 10, paddingLeft: 10, paddingBottom: 10 }}>
                          <EuiText size='s'>
                            <h3>{pendingAd!.headline}</h3>
                            <p>{pendingAd!.body}</p>
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiImage alt='ad' url={pendingAd!.highQualityUrl!} size='fullWidth' />
                      <EuiSpacer size='s' />
                      <EuiFlexGroup responsive={false} style={{ paddingBottom: 10 }}>
                        <EuiFlexItem grow={3} style={{ paddingLeft: 10, justifyContent: 'center' }}>
                          <EuiText size='m' color='subdued'>
                            <strong>{pendingAd!.companyName}</strong>
                          </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={1} style={{ paddingRight: 10 }}>
                          <EuiButton href={pendingAd!.destinationUrl} target='_blank' fullWidth={false} style={{ borderRadius: 0 }}>
                            {pendingAd!.callToAction}
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiPanel>
                    <EuiFormRow>
                      <EuiButtonEmpty title={'Download'} iconType={'download'} onClick={() => downloadImage(`${pendingAdQuery.data?.adId ?? 'downloadedAd'}.png`)}>
                        Download
                      </EuiButtonEmpty>
                    </EuiFormRow>
                  </EuiFlexItem>
                )}
                <EuiFlexItem>
                  <EuiDescriptionList compressed={true}>
                    <EuiDescriptionListTitle>Audit Status</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{pendingAd.auditStatus}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Ad Created</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{moment.utc(pendingAd.created).tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss z')}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Campaign Activated</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{moment.utc(pendingAd.activated).tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss z')}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Budget</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>
                      {formatter.format(pendingAd.budget)} {pendingAd.budgetFrequency}
                    </EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Targeting Template</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>{pendingAd.targetingTemplate}</EuiDescriptionListDescription>
                    <EuiDescriptionListTitle>Destination Url</EuiDescriptionListTitle>
                    <EuiDescriptionListDescription>
                      <React.Fragment>
                        {pendingAd.destinationUrl}{' '}
                        <EuiLink href={pendingAd.destinationUrl} target='_blank'>
                          open
                        </EuiLink>{' '}
                      </React.Fragment>
                    </EuiDescriptionListDescription>
                  </EuiDescriptionList>
                  <EuiSpacer />
                  {(pendingAd.targetingTemplate === 'Shopify' || pendingAd.targetingTemplate === 'ShopifyBillboard' || pendingAd.targetingTemplate === 'ShopifyTV') && (
                    <EuiFlexGroup>
                      <EuiFlexItem grow={false}>
                        <EuiBadge color={'warning'} title={'Shopify campaigns often require additional validation from the customer before approval.'}>
                          Shopify Campaign
                        </EuiBadge>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                  {campaignTargeting.locations?.uploadedAudienceUrl && (
                    <React.Fragment>
                      <EuiFlexGroup>
                        <EuiFlexItem grow={false}>
                          <EuiBadge color={'warning'}>Uploaded Audience</EuiBadge>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiSpacer />
                    </React.Fragment>
                  )}
                  {!isValidUrl() && (
                    <EuiText color={'danger'} size={'s'}>
                      Needs a valid url. (Starts with 'http://' or 'https://')
                    </EuiText>
                  )}
                  {pendingAd.auditStatus === 'Pending' && (
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiButton fill color='primary' onClick={approveAd} disabled={!canAudit || !isValidUrl()} isLoading={isWorking}>
                          Approve
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiButton fill color='danger' onClick={showModal} disabled={!canAudit} isLoading={isWorking}>
                          Disapprove
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiButton fill color='text' onClick={() => history.push(`/customers/ads/${adId}`)} disabled={isWorking}>
                          Edit
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                  <EuiSpacer />
                  <EuiFlexGroup>
                    <EuiFlexItem />
                    <EuiFlexItem grow={false}>
                      <EuiButton color='text' iconType={'arrowLeft'} disabled={!prevPendingAd || isWorking} onClick={onPreviousClick}>
                        Previous
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color='text' iconType={'arrowRight'} iconSide='right' disabled={!nextPendingAd || isWorking} onClick={onNextClick}>
                        Next
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
      {modal}
    </React.Fragment>
  )
}

export default InternetAdPendingAuditPage
