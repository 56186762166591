import { INavigationItem } from './NavigationItem'

export const CustomersNavigation: INavigationItem[] = [
  {
    title: 'Organizations',
    route: '/customers/organizations',
    icon: 'indexPatternApp'
  },
  {
    title: 'Accounts',
    route: '/customers/accounts',
    icon: 'grokApp'
  },
  {
    title: 'Campaigns',
    route: '/customers/campaigns',
    icon: 'savedObjectsApp'
  },
  {
    title: 'Active Campaigns',
    route: '/customers/campaigns/active',
    icon: 'savedObjectsApp'
  },
  {
    title: 'Ads',
    route: '/customers/ads',
    icon: 'machineLearningApp'
  },
  {
    title: 'Plans & Packages',
    route: '/customers/plansAndPackages',
    icon: 'savedObjectsApp'
  }
]

export const DataNavigation: INavigationItem[] = [
  {
    title: 'Business Type Categories',
    route: '/data/businessTypeCategories',
    icon: 'sqlApp'
  },
  {
    title: 'Business Types',
    route: '/data/businessTypes',
    icon: 'sqlApp'
  },
  {
    title: 'Partners',
    route: '/data/partners',
    icon: 'sqlApp'
  },
  {
    title: 'Anchor Phrases',
    route: '/data/anchorPhrases',
    icon: 'sqlApp'
  },
  {
    title: 'Granular Phrases',
    route: '/data/granularPhrases',
    icon: 'sqlApp'
  }
]

export const AssetNavigation: INavigationItem[] = [
  {
    title: 'TV Commercials',
    route: '/assets/tvcommercials',
    icon: 'auditbeatApp'
  }
]

export const ReportNavigation: INavigationItem[] = [
  {
    title: 'Agencies',
    route: '/reports/agencies',
    icon: 'reportingApp'
  },
  {
    title: 'Signups',
    route: '/reports/signups',
    icon: 'reportingApp'
  },
  {
    title: 'Activations',
    route: '/reports/activations',
    icon: 'reportingApp'
  },
  {
    title: 'Plan & Package Purchases',
    route: '/reports/purchases',
    icon: 'reportingApp'
  }
]

export const SettingsNavigation: INavigationItem[] = [
  {
    title: 'Vistar Media DSP',
    route: '/settings/vistarMediaDsp',
    icon: 'managementApp'
  },
  {
    title: 'Communication',
    route: '/settings/communication',
    icon: 'email'
  }
]
