import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiButton, EuiForm, EuiFormRow, EuiLoadingContent, EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiSwitch } from '@elastic/eui'

import { Template, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

interface BusinessTypeHeadlinesTabParams {
  businessTypeId: string
}

interface FormValues {
  templates: Array<Template>
}

const BusinessTypeHeadlinesTab: React.FC<BusinessTypeHeadlinesTabParams> = ({ businessTypeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [headlinesInitial, setHeadlinesInitial] = useState<FormValues>({ templates: [] })
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const opsClient = useOpsClient()

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getBusinessType(businessTypeId).then(result => {
        setHeadlinesInitial({ templates: result.headlines })
        setIsLoading(false)
      })
    }
  }, [opsClient, businessTypeId])

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const businessTypeHeadlinesSchema = Yup.object().shape({
    templates: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        value: Yup.string().required('Empty headlines are not allowed'),
        isDisabled: Yup.boolean(),
        templateType: Yup.string()
      })
    )
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.setBusinessTypeHeadlines(businessTypeId, { templates: values.templates }).then(result => {
      setSubmitting(false)
      history.push(`/data/businessTypes/${businessTypeId}`)
    })
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <Formik initialValues={headlinesInitial} enableReinitialize validationSchema={businessTypeHeadlinesSchema} onSubmit={doSubmit}>
        {props => (
          <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
            <FieldArray
              name='templates'
              render={arrayHelpers => (
                <React.Fragment>
                  {props.values.templates &&
                    props.values.templates.length > 0 &&
                    props.values.templates.map((template, index) => (
                      <EuiFlexGroup gutterSize='m' key={index} alignItems='baseline'>
                        <EuiFlexItem grow={true}>
                          <EuiFormRow fullWidth label={index === 0 ? 'Is Disabled?' : ''} display='rowCompressed' isInvalid={getIn(props.touched, `templates[${index}].isDisabled`) && !!getIn(props.errors, `templates[${index}].isDisabled`)} error={getIn(props.errors, `templates[${index}].isDisabled`)}>
                            <EuiSwitch name={`templates[${index}].isDisabled`} label={getIn(props.values, `templates[${index}].value`)} showLabel={true} checked={getIn(props.values, `templates[${index}].isDisabled`)} onChange={e => props.setFieldValue(`templates[${index}].isDisabled`, e.target.checked)} labelProps={{ style: { whiteSpace: 'nowrap' } }} />
                          </EuiFormRow>
                          <input type='hidden' name={`templates[${index}].id`} value={getIn(props.values, `templates[${index}].id`)} />
                          <input type='hidden' name={`templates[${index}].value`} value={getIn(props.values, `templates[${index}].value`)} />
                          <input type='hidden' name={`templates[${index}].templateType`} value={getIn(props.values, `templates[${index}].templateType`)} />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ))}
                </React.Fragment>
              )}
            />
            <EuiSpacer />
            <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate}>
              Save Headlines
            </EuiButton>
          </EuiForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default BusinessTypeHeadlinesTab
