import { Contact } from 'api/entities/Contact'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

interface IDeleteContactArgs {
  contactId: string
  organizationId: string
}

const contactsApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['Contacts', 'TimeZones'] })

export const contactsApi = contactsApiTags.injectEndpoints({
  endpoints: build => ({
    getContacts: build.query<Contact[], string>({
      query: orgId => `contacts/?organizationId=${encodeURIComponent(orgId)}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, orgId) => [{ type: 'Contacts', id: orgId }]
    }),
    postContact: build.mutation<Contact, Contact>({
      query: contact => ({
        url: `contacts`,
        method: 'POST',
        body: contact
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, contact) => [{ type: 'Contacts', id: contact.organizationId }]
    }),
    putContact: build.mutation<Contact, Contact>({
      query: contact => ({
        url: `contacts/${contact.id}`,
        method: 'PUT',
        body: contact
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, contact) => [{ type: 'Contacts', id: contact.organizationId }]
    }),
    getTimeZones: build.query<string[], void>({
      query: () => 'contacts/timezones',
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'TimeZones' }]
    }),
    deleteContact: build.mutation<void, IDeleteContactArgs>({
      query: ({ contactId }) => ({
        url: `contacts/${contactId}`,
        method: 'DELETE'
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => ['Contacts'],
      async onQueryStarted({ contactId, organizationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactsApi.util.updateQueryData('getContacts', organizationId, draft => {
            draft.splice(
              draft.findIndex(c => c.id === contactId),
              1
            )
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  }),
  overrideExisting: false
})

export const { useLazyGetContactsQuery, usePostContactMutation, usePutContactMutation, useGetTimeZonesQuery, useDeleteContactMutation } = contactsApi
