import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'

import { Account } from 'api'
import { AdvertingRoadmap } from 'features/customers/organizations/Snapshot/AdvertingRoadmap'
import { AdvertisingSnapshotDetails } from 'features/customers/organizations/Snapshot/AdvertisingSnapshotDetails'
import { BusinessImpactSignals } from 'features/customers/organizations/Snapshot/BusinessImpactSignals'

export interface IAccountSnapshotTab {
  account: Account
}

export const AccountSnapshotTab: React.FC<IAccountSnapshotTab> = props => {
  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem grow={3}>
          <AdvertisingSnapshotDetails account={props.account} />
        </EuiFlexItem>
        <EuiFlexItem grow={1} style={{ borderLeft: '1px solid #e5e5e5', paddingLeft: 18 }}>
          <AdvertingRoadmap />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule />
      <BusinessImpactSignals />
    </React.Fragment>
  )
}
