import { EuiBadge } from '@elastic/eui'

import { Anomaly, AnomalyStatus } from 'api/entities/Anomaly'
import { insertSpaces } from 'utils/EnumToFriendly'

export interface IAnomalyStatusBadge {
  anomaly: Anomaly
}

export const AnomalyStatusBadge: React.FC<IAnomalyStatusBadge> = props => {
  const color: string = props.anomaly.status === AnomalyStatus.Escalated ? 'danger' : props.anomaly.status === AnomalyStatus.ActiveIssue ? 'warning' : props.anomaly.status === AnomalyStatus.Resolved ? 'success' : props.anomaly.status === AnomalyStatus.Responded ? 'success' : 'default'

  return <EuiBadge color={color}>{insertSpaces(props.anomaly.status)}</EuiBadge>
}
