import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiText } from '@elastic/eui'

import { AssetNavigation, CustomersNavigation, DataNavigation, ReportNavigation, SettingsNavigation } from 'components/Header/NavigationPages/NavigationDefinitions'
import { NavigationPage } from 'components/Header/NavigationPages/NavigationPage'
import AccountManagerDashboardPage from 'features/accountManagerDashboard/AccountManagerDashboardPage'
import ActivationsPage from 'features/accountManagerDashboard/ActivationsPage'
import AdvisorDashboardPage from 'features/advisorDashboard/AdvisorDashboardPage'
import { AgencyManagerDashboardPage } from 'features/agencyManagerDashboard/AgencyManagerDashboardPage'
import BillboardPage from 'features/assets/billboards/BillboardPage'
import BillboardsPage from 'features/assets/billboards/BillboardsPage'
import TvCommercialPage from 'features/assets/tvCommercials/TvCommercialPage'
import TvCommercialsPage from 'features/assets/tvCommercials/TvCommercialsPage'
import AccountPage from 'features/customers/accounts/AccountPage'
import AccountsPage from 'features/customers/accounts/AccountsPage'
import { AdAuditPage } from 'features/customers/ads/AdAuditPage'
import AdPage from 'features/customers/ads/AdPage'
import AdsPage from 'features/customers/ads/AdsPage'
import AdsPendingPage from 'features/customers/ads/AdsPendingPage'
import BundlesPage from 'features/customers/bundles/BundlesPage'
import AllActiveCampaignsPage from 'features/customers/campaigns/AllActiveCampaignsPage'
import CampaignPage from 'features/customers/campaigns/CampaignPage'
import CampaignsNeedingCustomAdsPage from 'features/customers/campaigns/CampaignsNeedingCustomAdsPage'
import CampaignsNeedingCustomBillboardAdsPage from 'features/customers/campaigns/CampaignsNeedingCustomBillboardAdsPage'
import CampaignsNeedingCustomTvCommercialsPage from 'features/customers/campaigns/CampaignsNeedingCustomTvCommercialsPage'
import CampaignsNeedingOutrosPage from 'features/customers/campaigns/CampaignsNeedingOutrosPage'
import CampaignsPage from 'features/customers/campaigns/CampaignsPage'
import OrganizationPage from 'features/customers/organizations/OrganizationPage'
import OrganizationRostersPage from 'features/customers/organizations/OrganizationRostersPage'
import OrganizationsPage from 'features/customers/organizations/OrganizationsPage'
import UserPage from 'features/customers/users/UserPage'
import UsersPage from 'features/customers/users/UsersPage'
import DashboardPage from 'features/dashboard/DashboardPage'
import AnchorPhrasePage from 'features/data/anchorPhrases/AnchorPhrasePage'
import AnchorPhrasesPage from 'features/data/anchorPhrases/AnchorPhrasesPage'
import BusinessTypeCategoriesPage from 'features/data/businessTypeCategories/BusinessTypeCategoriesPage'
import BusinessTypeCategoryPage from 'features/data/businessTypeCategories/BusinessTypeCategoryPage'
import BusinessTypePage from 'features/data/businessTypes/BusinessTypePage'
import BusinessTypesPage from 'features/data/businessTypes/BusinessTypesPage'
import GranularPhrasePage from 'features/data/granularPhrases/GranularPhrasePage'
import GranularPhrasesPage from 'features/data/granularPhrases/GranularPhrasesPage'
import PartnersPage from 'features/data/partners/PartnersPage'
import ExecutiveDashboardPage from 'features/executiveDashboard/ExecutiveDashboardPage'
import NewExecutiveDashboardPage from 'features/executiveDashboard/NewExecutiveDashboardPage'
import { OpsManagerDashboardPage } from 'features/opsManagerDashboard/OpsManagerDashboardPage'
import PerformanceDashboardPage from 'features/performanceDashboard/PerformanceDashboardPage'
import AgenciesPage from 'features/reports/AgenciesPage'
import SignupsPage from 'features/reports/SignupsPage'
import { SalesManagerDashboardPage } from 'features/salesManagerDashboard/SalesManagerDashboardPage'
import CommunicationPage from 'features/settings/communication/CommunicationPage'
import VistarMediaPage from 'features/settings/vistarMediaDsp/VistarMediaPage'
import { TemplatesPage } from 'features/templates/TemplatesPage'
import { ToolsPage } from 'features/tools/ToolsPage'
import { AuthStatus } from 'services/AuthService'

import PurchasesPage from '../features/reports/PurchasesPage'
import { signIn } from './appSlice'
import config from './config'
import { RootState } from './rootReducer'

const Routes: React.FC = () => {
  const dispatch = useDispatch()
  const { status, userProfile } = useSelector((state: RootState) => state.app)

  if (status === AuthStatus.Authenticated) {
    return (
      <Switch>
        <Route exact={true} path='/'>
          <DashboardPage />
        </Route>
        <Route exact={true} path={'/advisor/dashboard/:tab?'}>
          <AdvisorDashboardPage />
        </Route>
        <Route exact={true} path={'/account-manager/dashboard/:tab?'}>
          <AccountManagerDashboardPage />
        </Route>
        <Route exact={true} path={'/agency-manager/dashboard/:tab?'}>
          <AgencyManagerDashboardPage />
        </Route>
        <Route exact={true} path={'/executive/dashboard/'}>
          <ExecutiveDashboardPage />
        </Route>
        <Route exact={true} path='/tools/:tab?'>
          <ToolsPage />
        </Route>
        <Route exact={true} path='/customers/organizations'>
          <OrganizationsPage />
        </Route>
        <Route exact={true} path='/customers/organizations/:organizationId/:tabName?'>
          <OrganizationPage />
        </Route>
        <Route exact={true} path='/customers/accounts'>
          <AccountsPage />
        </Route>
        <Route exact={true} path='/customers/accountsNeedingCustomAds'>
          <CampaignsNeedingCustomAdsPage />
        </Route>
        <Route exact={true} path='/customers/accountsNeedingCustomTvCommercials'>
          <CampaignsNeedingCustomTvCommercialsPage />
        </Route>
        <Route exact={true} path='/customers/accountsNeedingCustomBillboardAds'>
          <CampaignsNeedingCustomBillboardAdsPage />
        </Route>
        <Route exact={true} path='/customers/accounts/:accountId'>
          <AccountPage />
        </Route>
        <Route exact={true} path='/customers/campaigns'>
          <CampaignsPage />
        </Route>
        <Route exact={true} path='/customers/campaigns/active'>
          <AllActiveCampaignsPage />
        </Route>
        <Route exact={true} path='/customers/campaignsNeedingOutros'>
          <CampaignsNeedingOutrosPage />
        </Route>
        <Route exact={true} path='/customers/campaigns/:campaignId'>
          <CampaignPage />
        </Route>
        <Route exact={true} path='/customers/ads'>
          <AdsPage />
        </Route>
        <Route exact={true} path='/customers/ads/:adId'>
          <AdPage />
        </Route>
        <Route exact={true} path='/customers/pending/ads'>
          <AdsPendingPage />
        </Route>
        <Route exact={true} path='/customers/pending/ads/audit/:adId'>
          <AdAuditPage />
        </Route>
        <Route exact={true} path='/customers/users'>
          <UsersPage />
        </Route>
        <Route exact={true} path='/customers/users/:userId'>
          <UserPage />
        </Route>
        <Route exact={true} path='/customers/plansAndPackages'>
          <BundlesPage />
        </Route>

        {/* Legacy Routes */}
        {userProfile && (userProfile.roles.includes('adcritter-ops-csuite') || userProfile.roles.includes('adcritter-ops-vistarupload')) && (
          <Route exact={true} path='/settings'>
            <NavigationPage navigationItems={SettingsNavigation.filter(sn => sn.title !== 'Communication' || userProfile.roles.includes('adcritter-ops-csuite'))} title={'Settings'} />
          </Route>
        )}

        {userProfile && (userProfile.roles.includes('adcritter-ops-csuite') || userProfile.roles.includes('adcritter-ops-vistarupload')) && (
          <Route exact={true} path='/settings/vistarMediaDsp'>
            <VistarMediaPage />
          </Route>
        )}

        {userProfile && (userProfile.roles.includes('adcritter-ops-csuite') || userProfile.roles.includes('adcritter-ops-finance')) && (
          <Route exact={true} path={'/sales-manager/dashboard/:tab?'}>
            <SalesManagerDashboardPage />
          </Route>
        )}

        {userProfile && userProfile.roles.includes('adcritter-ops-csuite') && (
          <React.Fragment>
            <Route exact={true} path={'/ops-manager/dashboard/:tab?'}>
              <OpsManagerDashboardPage />
            </Route>
            <Route exact={true} path={'/organizationRosters'}>
              <OrganizationRostersPage />
            </Route>
            <Route exact={true} path={'/executive/dashboard/new'}>
              <NewExecutiveDashboardPage />
            </Route>
            <Route exact={true} path={'/performance/dashboard'}>
              <PerformanceDashboardPage />
            </Route>
            <Route exact={true} path='/customers'>
              <NavigationPage navigationItems={CustomersNavigation} title={'Customers'} />
            </Route>
            <Route exact={true} path='/data'>
              <NavigationPage navigationItems={DataNavigation} title={'Data'} />
            </Route>
            <Route exact={true} path='/data/businessTypeCategories'>
              <BusinessTypeCategoriesPage />
            </Route>
            <Route exact={true} path='/data/businessTypeCategories/:businessTypeCategoryId'>
              <BusinessTypeCategoryPage />
            </Route>
            <Route exact={true} path='/data/businessTypes'>
              <BusinessTypesPage />
            </Route>
            <Route exact={true} path='/data/businessTypes/:businessTypeId'>
              <BusinessTypePage />
            </Route>
            <Route exact={true} path='/data/partners'>
              <PartnersPage />
            </Route>
            <Route exact={true} path='/data/anchorPhrases'>
              <AnchorPhrasesPage />
            </Route>
            <Route exact={true} path='/data/anchorPhrases/:anchorPhraseId'>
              <AnchorPhrasePage />
            </Route>
            <Route exact={true} path='/data/granularPhrases'>
              <GranularPhrasesPage />
            </Route>
            <Route exact={true} path='/data/granularPhrases/:granularPhraseId'>
              <GranularPhrasePage />
            </Route>
            <Route exact={true} path='/reports'>
              <NavigationPage navigationItems={ReportNavigation} title={'Reports'} />
            </Route>
            <Route exact={true} path='/reports/agencies'>
              <AgenciesPage />
            </Route>
            <Route exact={true} path='/reports/signups'>
              <SignupsPage />
            </Route>
            <Route exact={true} path='/reports/activations'>
              <ActivationsPage />
            </Route>
            <Route exact={true} path='/reports/purchases'>
              <PurchasesPage />
            </Route>
            <Route exact={true} path='/settings/communication'>
              <CommunicationPage />
            </Route>
            <Route exact={true} path={'/templates/:tab?'}>
              <TemplatesPage />
            </Route>
            <Route exact={true} path='/assets'>
              <NavigationPage navigationItems={AssetNavigation} title={'Assets'} />
            </Route>
            <Route exact={true} path='/assets/tvcommercials'>
              <TvCommercialsPage />
            </Route>
            <Route exact={true} path='/assets/tvcommercials/:tvCommercialId'>
              <TvCommercialPage />
            </Route>
            {config.features.billboardSuggestionsEnabled && (
              <React.Fragment>
                <Route exact={true} path='/assets/billboards'>
                  <BillboardsPage />
                </Route>
                <Route exact={true} path='/assets/billboards/:billboardId'>
                  <BillboardPage />
                </Route>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Switch>
    )
  } else if (status === AuthStatus.Authenticating) {
    return (
      <EuiText size='s'>
        <p style={{ margin: 30 }}>
          <EuiLoadingSpinner size='m' /> Logging in, please wait...
        </p>
      </EuiText>
    )
  } else {
    return (
      <EuiFlexGroup style={{ margin: 100 }}>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => {
              dispatch(signIn())
            }}
            iconType={'user'}>
            Please click here to login...
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
    )
  }
}

export default Routes
