import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { EuiBadge, EuiButton, EuiCopy, EuiPageTemplate } from '@elastic/eui'

import { CampaignStatus } from 'api'
import { useGetCampaignBidderQuery, useGetCampaignBidderXQuery, useGetCampaignQuery, useRefreshCampaignStatusMutation, useSetCampaignInternalStatusMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useGetOrganizationQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { CampaignInternalStatus } from 'api/rtkQueryApi/requestModels/CampaignSetInternalStatusModel'
import config from 'app/config'
import { RootState } from 'app/rootReducer'
import { AcTab } from 'components/ACTools/AcTab'
import CampaignBidderTab from 'features/customers/campaigns/CampaignBidderTab'
import CampaignBidderXTab from 'features/customers/campaigns/CampaignBidderXTab'
import { CampaignDocumentViewer } from 'features/customers/campaigns/CampaignDocumentViewer'
import { CampaignMigrationTab } from 'features/customers/campaigns/CampaignMigrationTab'
import { CampaignRetargetingTab } from 'features/customers/campaigns/CampaignRetargetingTab'
import CampaignTrafficRawTab from 'features/customers/campaigns/CampaignTrafficRawTab'
import { ModernTargetingTab } from 'features/customers/campaigns/targeting/ModernTargetingTab'
import history from 'services/HistoryService'
import { insertSpaces } from 'utils/EnumToFriendly'

import CampaignAdsTab from './CampaignAdsTab'
import CampaignDetailsTab from './CampaignDetailsTab'
import CampaignHistoryTab from './CampaignHistoryTab'
import CampaignNotesTab from './CampaignNotesTab'
import { CampaignTrafficDimensionedTab } from './CampaignTrafficTab'
import { CampaignTransactionsTab } from './CampaignTransactionsTab'
import AdvancedAudienceTargetingTab from './targeting/AdvancedAudienceTargetingTab'
import AdvancedLocationTargetingTab from './targeting/AdvancedLocationTargetingTab'
import AdvancedSearchTargetingTab from './targeting/AdvancedSearchTargetingTab'
import BillboardTargetingTab from './targeting/BillboardTargetingTab'
import SmartTargetingTab from './targeting/SmartTargetingTab'
import TvTargetingTab from './targeting/TvTargetingTab'

interface CampaignPageParams {
  campaignId: string
}

const CampaignPage: React.FC = () => {
  let { campaignId } = useParams<CampaignPageParams>()

  const { data: campaign } = useGetCampaignQuery(campaignId, {
    skip: !campaignId,
    refetchOnMountOrArgChange: true
  })

  const { data: campaignBidder } = useGetCampaignBidderQuery(campaignId, {
    skip: !campaignId
  })

  const { data: campaignBidderX } = useGetCampaignBidderXQuery(campaignId, {
    skip: !campaignId
  })

  const { data: organization } = useGetOrganizationQuery(campaign?.organizationId, {
    skip: !campaign?.organizationId
  })

  const [setInternalStatus] = useSetCampaignInternalStatusMutation()
  const [refreshCampaignStatus, refreshCampaignStatusResult] = useRefreshCampaignStatusMutation()

  const { userProfile } = useSelector((state: RootState) => state.app)
  const [tabs, setTabs] = useState<AcTab[]>([])
  const [tab, setTab] = useState(0)
  const [headerButtons, setHeaderButtons] = useState<ReactNode[]>([])

  useEffect(() => {
    if (config && campaign && organization) {
      let b: ReactNode[] = []
      if (!organization.isAgency) {
        b.push(
          <EuiButton href={`${config.PLATFORMURL}gotoCampaign/${campaign.organizationId}/${campaign.accountId}/${campaign.campaignId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text' fill>
            AdCritter Platform
          </EuiButton>
        )
      } else {
        b.push(
          <EuiButton href={`${config.AGENCYURL}gotoCampaign/${campaign.organizationId}/${campaign.accountId}/${campaign.campaignId}`} target='_blank' size='s' iconType='share' iconSide='right' color='text' fill>
            AdCritter for Agencies
          </EuiButton>
        )
      }
      b.push(
        <EuiButton target='_blank' size='s' iconType='magnifyWithExclamation' iconSide='right' color={campaign.internalStatus === CampaignInternalStatus.Escalated ? 'ghost' : 'danger'} fill onClick={onEscalateClick}>
          {campaign.internalStatus === CampaignInternalStatus.Escalated ? 'De-Escalate' : 'Escalate'}
        </EuiButton>
      )

      if (userProfile?.roles.includes('adcritter-ops-admin') && campaign.status !== CampaignStatus.EndingOn) {
        b.push(
          <EuiButton isLoading={refreshCampaignStatusResult.isLoading} target='_blank' size='s' iconType='refresh' iconSide='right' color='ghost' fill onClick={onRefreshStatusClick}>
            Refresh Campaign Status
          </EuiButton>
        )
      }

      setHeaderButtons(b)
    }
  }, [config, campaign, organization, refreshCampaignStatusResult.isLoading])

  useEffect(() => {
    if (campaign && organization && userProfile) {
      let t: AcTab[] = [{ label: 'Details', isSelected: tab === 0, onClick: () => setTab(0) }]

      const advancedTargeting = campaign.advancedTargeting
      if (campaign.campaignType === 'Internet' && advancedTargeting) {
        const advancedTargetingType = advancedTargeting.advancedTargetingType
        if (advancedTargeting.advancedTargetingType === 'Retarget') {
          t.push({ label: 'Retargeting', isSelected: tab === 17, onClick: () => setTab(17) })
        } else if (advancedTargeting.locations) {
          t.push({ label: 'Targeting', isSelected: tab === 16, onClick: () => setTab(16) })
        } else {
          if (advancedTargetingType === 'Location') {
            t.push({ label: 'Location Based Targeting', isSelected: tab === 1, onClick: () => setTab(1) })
          }

          if (advancedTargetingType === 'Audience') {
            t.push({ label: 'Audience Based Targeting', isSelected: tab === 2, onClick: () => setTab(2) })
          }

          if (advancedTargetingType === 'Search') {
            t.push({ label: 'Search Based Targeting', isSelected: tab === 3, onClick: () => setTab(3) })
          }
        }
      }

      if (campaign.targetingTemplate === 'Billboard') {
        if (campaign.billboardTargeting?.locations) {
          t.push({ label: 'Targeting', isSelected: tab === 16, onClick: () => setTab(16) })
        } else {
          t.push({ label: 'Targeting', isSelected: tab === 4, onClick: () => setTab(4) })
        }
      }
      if (campaign.targetingTemplate === 'TV' || campaign.targetingTemplate === 'ShopifyTV') {
        if (campaign.tvTargeting?.locations) {
          t.push({ label: 'Targeting', isSelected: tab === 16, onClick: () => setTab(16) })
        } else {
          t.push({ label: 'Targeting', isSelected: tab === 5, onClick: () => setTab(5) })
        }
      }
      if (campaign.targetingTemplate === 'Standard') {
        t.push({ label: 'Targeting', isSelected: tab === 6, onClick: () => setTab(6) })
      }
      t.push({ label: 'Ads', isSelected: tab === 7, onClick: () => setTab(7) })

      if (userProfile.roles.includes('adcritter-ops-performance')) {
        if (campaign.dsp === 'Yahoo') {
          t.push({ label: 'Bidder', isSelected: tab === 11, onClick: () => setTab(11) })
        }
        if (campaign.dsp === 'Xandr') {
          t.push({ label: 'Bidder', isSelected: tab === 9, onClick: () => setTab(9) })
        }
      }

      t.push({ label: 'History', isSelected: tab === 10, onClick: () => setTab(10) })

      if (campaign.firstViewDate) {
        t.push({ label: 'Traffic', isSelected: tab === 12, onClick: () => setTab(12) })
        t.push({ label: 'Traffic Breakdown', isSelected: tab === 13, onClick: () => setTab(13) })
      }
      if (userProfile.roles.includes('adcritter-ops-billing')) {
        t.push({ label: 'Transactions', isSelected: tab === 14, onClick: () => setTab(14) })
      }
      t.push({ label: 'Notes', isSelected: tab === 15, onClick: () => setTab(15) })
      if (userProfile.roles.includes('adcritter-ops-campaign-doc-viewer')) {
        t.push({ label: 'Document View', isSelected: tab === 18, onClick: () => setTab(18) })
      }
      setTabs(t)
    }
  }, [campaignId, campaign, organization, userProfile, tab])

  const onEscalateClick = () => {
    setInternalStatus({ campaignId: campaignId, internalStatus: campaign?.internalStatus === CampaignInternalStatus.Escalated ? CampaignInternalStatus.None : CampaignInternalStatus.Escalated })
  }

  const onRefreshStatusClick = () => {
    refreshCampaignStatus(campaignId)
  }

  return (
    <React.Fragment>
      {campaign && organization && (
        <EuiPageTemplate>
          <EuiPageTemplate.Header
            iconType='savedObjectsApp'
            pageTitle={
              <React.Fragment>
                Campaign
                {campaign.bundleId && !campaign.isMatching ? <EuiBadge color='default'>{insertSpaces(campaign.bundleType)}</EuiBadge> : ''}
                {campaign.isMatching ? <EuiBadge color='success'>Matching</EuiBadge> : ''}
                <br />
                <EuiCopy textToCopy={campaignId!}>
                  {copy => (
                    <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Campaign ID'>
                      {campaignId}
                    </EuiBadge>
                  )}
                </EuiCopy>
              </React.Fragment>
            }
            breadcrumbs={[
              {
                text: campaign.organizationName,
                onClick: () => history.push(`/customers/organizations/${campaign.organizationId}`)
              },
              {
                text: campaign.accountName,
                onClick: () => history.push(`/customers/accounts/${campaign.accountId}`)
              }
            ]}
            tabs={tabs}
            rightSideItems={headerButtons}
            restrictWidth={false}
          />
          <EuiPageTemplate.Section restrictWidth={false}>
            <React.Fragment>
              {tab === 0 && <CampaignDetailsTab campaign={campaign} organization={organization} />}
              {tab === 1 && <AdvancedLocationTargetingTab campaign={campaign} />}
              {tab === 2 && <AdvancedAudienceTargetingTab campaign={campaign} />}
              {tab === 3 && <AdvancedSearchTargetingTab campaign={campaign} />}
              {tab === 4 && <BillboardTargetingTab campaign={campaign} />}
              {tab === 5 && <TvTargetingTab campaign={campaign} />}
              {tab === 6 && <SmartTargetingTab campaign={campaign} />}
              {tab === 7 && <CampaignAdsTab campaign={campaign} />}
              {tab === 8 && <CampaignBidderTab campaign={campaign} campaignBidder={campaignBidder} />}
              {tab === 9 && <CampaignBidderXTab campaign={campaign} campaignBidder={campaignBidderX} />}
              {tab === 10 && <CampaignHistoryTab campaign={campaign} />}
              {tab === 11 && <CampaignMigrationTab campaign={campaign} />}
              {tab === 12 && <CampaignTrafficRawTab campaign={campaign} />}
              {tab === 13 && <CampaignTrafficDimensionedTab campaign={campaign} />}
              {tab === 14 && <CampaignTransactionsTab campaign={campaign} />}
              {tab === 15 && <CampaignNotesTab campaign={campaign} />}
              {tab === 16 && <ModernTargetingTab campaign={campaign} />}
              {tab === 17 && <CampaignRetargetingTab campaign={campaign} />}
              {tab === 18 && <CampaignDocumentViewer campaignId={campaign.campaignId} />}
            </React.Fragment>
          </EuiPageTemplate.Section>
        </EuiPageTemplate>
      )}
    </React.Fragment>
  )
}

export default CampaignPage
