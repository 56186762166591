import React from 'react'

import { EuiHealth, EuiToolTip } from '@elastic/eui'

import { currencyStyle } from 'utils/styles.'

import { CampaignPerformance } from '../../api/entities/PerformanceDashboard'

export const TodayHealth: React.FC<{ campaign: CampaignPerformance }> = ({ campaign }) => {
  return (
    <React.Fragment>
      {campaign.campaignType === 'Billboard' && <EuiHealth color='ghost' />}
      {campaign.campaignType !== 'Billboard' && campaign.todaysImpressions > 0 && (
        <EuiToolTip
          content={
            <React.Fragment>
              <div>{campaign.todaysImpressions} Impressions</div>
              <div>{(campaign.todaysSpend ?? 0).toLocaleString('en-US', currencyStyle)} Spend</div>
            </React.Fragment>
          }
          position='left'>
          <EuiHealth color='success' />
        </EuiToolTip>
      )}
      {campaign.campaignType !== 'Billboard' && campaign.todaysImpressions === 0 && (
        <EuiToolTip content='No traffic today' position='left'>
          <EuiHealth color='danger' />
        </EuiToolTip>
      )}
    </React.Fragment>
  )
}
