import React, { useState } from 'react'

import { EuiButton, EuiButtonEmpty, EuiFormRow, EuiIcon, EuiPanel, EuiSelect, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Campaign, CampaignType } from 'api'
import { Bundle, CampaignSlot } from 'api/entities/Bundle'
import { useAddCampaignToCampaignSlotMutation, useChangeCampaignSlotTypeMutation, useUnbindBundleCampaignSlotMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { AssignCampaignToSlotModal } from 'components/Bundles/AssignCampaignToSlotModal'
import { SlotAllocationItem } from 'components/Bundles/SlotAllocationItem'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'

export interface ICampaignSlotDetailsPanel {
  bundle: Bundle
  slot: CampaignSlot
  organizationId: string
  editable?: boolean
}

export const CampaignSlotDetailsPanel: React.FC<ICampaignSlotDetailsPanel> = ({ bundle, slot, organizationId, editable }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [changeCampaignSlotTypeRequest, changeCampaignSlotTypeQuery] = useChangeCampaignSlotTypeMutation()
  const [unbindCampaign, unbindCampaignQuery] = useUnbindBundleCampaignSlotMutation()
  const [addCampaignToCampaignSlotRequest, addCampaignToCampaignSlotQuery] = useAddCampaignToCampaignSlotMutation()
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignType>(slot.campaignType)
  const [showRemoveCampaignModal, setShowRemoveCampaignModal] = useState(false)

  const onAssignCampaignClick = () => {
    setShowAssignModal(true)
  }

  const onAssignCampaignModalCancel = () => {
    setShowAssignModal(false)
  }

  const onAssignCampaignModalSave = (campaign: Campaign) => {
    if (campaign.campaignId && slot.id) {
      addCampaignToCampaignSlotRequest({ campaignId: campaign.campaignId, campaignSlotId: slot.id, bundleId: bundle.id }).then(() => {
        setShowAssignModal(false)
      })
    }
  }

  const campaignTypeOptions = [
    { value: CampaignType.TV, text: CampaignType.TV },
    { value: CampaignType.Internet, text: CampaignType.Internet },
    { value: CampaignType.Billboard, text: CampaignType.Billboard }
  ]

  const onCampaignTypeChange = (campaignType: string) => {
    setSelectedCampaignType(campaignType as CampaignType)
  }

  const onSaveClick = () => {
    changeCampaignSlotTypeRequest({ organizationId: bundle.organizationId, campaignSlotId: slot.id, bundleId: bundle.id, campaignType: selectedCampaignType }).then(() => {
      setIsEditing(false)
    })
  }

  const onRemoveCampaignClick = () => {
    setShowRemoveCampaignModal(true)
  }

  const onRemoveCampaignConfirmClick = () => {
    unbindCampaign({ organizationId: organizationId, campaignSlotId: slot.id, bundleId: bundle.id }).then(() => {
      setShowRemoveCampaignModal(false)
    })
  }

  return (
    <React.Fragment>
      {showRemoveCampaignModal && <AcConfirmModal message={'Are you sure you want to remove this campaign?'} onCancel={() => setShowRemoveCampaignModal(false)} onConfirm={onRemoveCampaignConfirmClick} title={'Remove Campaign'} confirmButtonText={'Remove'} isLoading={unbindCampaignQuery.isLoading} />}
      <EuiPanel hasShadow={false} hasBorder={true}>
        <EuiTitle size={'s'}>
          <h2>
            ${slot.budget} {selectedCampaignType} Campaign {slot.isBonus ? <EuiIcon type='starFilled' color={'primary'} /> : ''}
          </h2>
        </EuiTitle>
        <EuiSpacer size={'xs'} />
        {editable && !isEditing && (
          <React.Fragment>
            <EuiButtonEmpty
              color={'primary'}
              onClick={() => {
                setIsEditing(true)
              }}>
              Edit
            </EuiButtonEmpty>
          </React.Fragment>
        )}
        {isEditing && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow fullWidth label={'Campaign Type'}>
              <EuiSelect
                options={campaignTypeOptions}
                value={selectedCampaignType}
                onChange={e => {
                  onCampaignTypeChange(e.target.value)
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        <EuiSpacer />

        {editable && isEditing ? (
          <EuiFormRow>
            <EuiButton onClick={onSaveClick} isLoading={changeCampaignSlotTypeQuery.isLoading}>
              Save
            </EuiButton>
          </EuiFormRow>
        ) : slot.campaignId ? (
          <EuiFormRow label={'Campaign'}>
            <SlotAllocationItem campaignId={slot.campaignId} canRemove={!slot.flightSchedule || slot.flightSchedule.intervalsCompleted < 1} onRemoveClick={onRemoveCampaignClick} />
          </EuiFormRow>
        ) : (
          <EuiButton onClick={onAssignCampaignClick}>Assign Campaign</EuiButton>
        )}
        {showAssignModal && <AssignCampaignToSlotModal organizationId={organizationId} saveLoading={addCampaignToCampaignSlotQuery.isLoading} slot={slot} onClose={onAssignCampaignModalCancel} onSave={onAssignCampaignModalSave} />}
      </EuiPanel>
    </React.Fragment>
  )
}
