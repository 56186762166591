import React, { useEffect, useState } from 'react'

import { EuiButton, EuiFormRow, EuiLoadingContent, EuiRadioGroup, EuiRadioGroupOption, EuiSpacer, EuiTitle } from '@elastic/eui'

import { Campaign, useOpsClient } from 'api'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'

interface AddCampaignToBundlePageParams {
  campaign: Campaign
  onSubmit: () => void
}

const AddCampaignToBundlePage: React.FC<AddCampaignToBundlePageParams> = ({ campaign, onSubmit }) => {
  const opsClient = useOpsClient()
  const organizationBundlesRequest = useGetOrganizationBundlesQuery(campaign.organizationId)
  const [availableCampaignSlots, setAvailableCampaignSlots] = useState<EuiRadioGroupOption[]>([])
  const [selectedCampaignSlotId, setSelectedCampaignSlotId] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (organizationBundlesRequest.data && !organizationBundlesRequest.isFetching && !organizationBundlesRequest.isLoading) {
      let availableSlots: EuiRadioGroupOption[] = []
      organizationBundlesRequest.data.forEach(bundle => {
        availableSlots = [
          ...bundle.campaignSlots
            .filter(slot => !slot.campaignId)
            .map(slot => ({
              id: slot.id,
              label: `$${slot.budget} ${slot.campaignType} Campaign Slot`
            })),
          ...availableSlots
        ]
      })
      setAvailableCampaignSlots(availableSlots)

      if (availableSlots.length > 0) {
        setSelectedCampaignSlotId(availableSlots[0].id)
      }
    }
  }, [organizationBundlesRequest.data, organizationBundlesRequest.isLoading, organizationBundlesRequest.isFetching])

  const onActivate = () => {
    setIsSubmitting(true)
    opsClient!.apiAddCampaignToCampaignSlot(campaign.campaignId, selectedCampaignSlotId).finally(() => {
      setIsSubmitting(false)
      if (onSubmit) {
        onSubmit()
      }
    })
  }

  return (
    <React.Fragment>
      {organizationBundlesRequest.isLoading || organizationBundlesRequest.isFetching ? (
        <EuiLoadingContent lines={3} />
      ) : (
        <React.Fragment>
          <EuiTitle size={'s'}>
            <h2>Select Available Credit</h2>
          </EuiTitle>
          <EuiSpacer />
          <EuiFormRow label='' fullWidth>
            <EuiRadioGroup options={availableCampaignSlots} onChange={id => setSelectedCampaignSlotId(id)} idSelected={selectedCampaignSlotId} />
          </EuiFormRow>
          <EuiSpacer />
          <EuiFormRow label='' fullWidth>
            <EuiButton isLoading={isSubmitting} fill type='submit' onClick={onActivate}>
              Activate
            </EuiButton>
          </EuiFormRow>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AddCampaignToBundlePage
