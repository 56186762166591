import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { Axis, Chart, CurveType, LineSeries, PartialTheme, Settings } from '@elastic/charts'
import { useEuiTheme, EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiPageTemplate, euiPaletteForDarkBackground, EuiPanel, EuiSpacer, EuiStat, EuiSuperSelect, EuiThemeProvider } from '@elastic/eui'
import { EUI_CHARTS_THEME_DARK } from '@elastic/eui/dist/eui_charts_theme'

import { Category, Channel, timespanKeyCampaign, timespanKeyPackage, timespanKeyPlan, timespanKeySubscription, timespanSumKeyCampaign, timespanSumKeyPackage, timespanSumKeyPlan, timespanSumKeySubscription } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardDatesQuery, useGetExecutiveDashboardCampaignQuery, useGetExecutiveDashboardCampaignSumQuery, useGetExecutiveDashboardCustomerQuery, useGetExecutiveDashboardPackageQuery, useGetExecutiveDashboardPackageSumQuery, useGetExecutiveDashboardPlanQuery, useGetExecutiveDashboardPlanSumQuery, useGetExecutiveDashboardSubscriptionQuery, useGetExecutiveDashboardSubscriptionSumQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'
import { AcTab } from 'components/ACTools/AcTab'

import ExecutiveDashboardCustomerReportFlyout from './ExecutiveDashboardCustomerReportFlyout'
import CampaignChannelOverviewPanel from './panels/CampaignChannelOverviewPanel'
import PackageOverviewPanel from './panels/PackageOverviewPanel'
import PlanOverviewPanel from './panels/PlanOverviewPanel'
import SubscriptionOverviewPanel from './panels/SubscriptionOverviewPanel'

const timespans = [
  {
    value: 'Daily',
    inputDisplay: (
      <EuiHealth color='primary' style={{ lineHeight: 'inherit' }}>
        Daily
      </EuiHealth>
    ),
    'executiveDashboard-test-subj': 'option-primary'
  },
  {
    value: 'Monthly',
    inputDisplay: (
      <EuiHealth color='success' style={{ lineHeight: 'inherit' }}>
        Monthly
      </EuiHealth>
    ),
    'executiveDashboard-test-subj': 'option-success'
  },
  {
    value: 'Annual',
    inputDisplay: (
      <EuiHealth color='accent' style={{ lineHeight: 'inherit' }}>
        Annual
      </EuiHealth>
    ),
    'executiveDashboard-test-subj': 'option-accent'
  }
]

const currencyStyle = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }

interface Totals {
  date: moment.Moment
  total: number
}

const NewExecutiveDashboardPage: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(moment().hour() < 7 ? moment().add(-1, 'days') : moment())
  const [forDate, setForDate] = useState(selectedDate.format('YYYY-MM-DD'))
  const { isLoading: isLoadingExecutiveDashboardDates, data: dates } = useGetExecutiveDashboardDatesQuery()
  const { isLoading: isLoadingExecutiveDashboardCustomer, data: customers } = useGetExecutiveDashboardCustomerQuery({ forDate })
  const { data: campaignTotals } = useGetExecutiveDashboardCampaignQuery({ channel: Channel.All, forDate })
  const { isLoading: isLoadingExecutiveDashboardCampaignDirect, data: directCampaignTotals } = useGetExecutiveDashboardCampaignQuery({ channel: Channel.Direct, forDate })
  const { isLoading: isLoadingExecutiveDashboardCampaignAgency, data: agencyCampaignTotals } = useGetExecutiveDashboardCampaignQuery({ channel: Channel.Agency, forDate })
  const { isLoading: isLoadingExecutiveDashboardCampaignPartner, data: partnerCampaignTotals } = useGetExecutiveDashboardCampaignQuery({ channel: Channel.Partner, forDate })
  const { data: historicCampaignTotals } = useGetExecutiveDashboardCampaignSumQuery({ channel: Channel.All, daysOfHistory: 7, forDate })
  const { isLoading: isLoadingExecutiveDashboardRegularPlan, data: regularPlanTotals } = useGetExecutiveDashboardPlanQuery({ category: Category.Regular, forDate })
  const { data: regularHistoricPlanTotals } = useGetExecutiveDashboardPlanSumQuery({ category: Category.Regular, daysOfHistory: 7, forDate })
  const { isLoading: isLoadingExecutiveDashboardNonRegularPlan, data: nonRegularPlanTotals } = useGetExecutiveDashboardPlanQuery({ category: Category.NonRegular, forDate })
  const { data: nonRegularHistoricPlanTotals } = useGetExecutiveDashboardPlanSumQuery({ category: Category.NonRegular, daysOfHistory: 7, forDate })
  const { isLoading: isLoadingExecutiveDashboardPackage, data: packageTotals } = useGetExecutiveDashboardPackageQuery({ forDate })
  const { data: historicPackageTotals } = useGetExecutiveDashboardPackageSumQuery({ daysOfHistory: 7, forDate })
  const { isLoading: isLoadingExecutiveDashboardSubscription, data: subscriptionTotals } = useGetExecutiveDashboardSubscriptionQuery({ forDate })
  const { data: historicSubscriptionTotals } = useGetExecutiveDashboardSubscriptionSumQuery({ daysOfHistory: 7, forDate })
  const [timespan, setTimespan] = useState(timespans[1].value)
  const [total, setTotal] = useState(0)
  const [totalIsLoading, setTotalIsLoading] = useState(true)
  const [totalPartnerCampaigns, setTotalPartnerCampaigns] = useState(0)
  const [totals, setTotals] = useState<Totals[]>()
  const [tab, setTab] = useState(0)

  const euiTheme = useEuiTheme()
  const headingColor = euiTheme.euiTheme.colors.success

  const tabs: AcTab[] = [
    { label: 'Plans', isSelected: tab === 0, onClick: () => setTab(0) },
    { label: 'Packages', isSelected: tab === 1, onClick: () => setTab(1) },
    { label: 'Campaigns', isSelected: tab === 2, onClick: () => setTab(2) },
    { label: 'Subscriptions', isSelected: tab === 3, onClick: () => setTab(3) }
  ]

  useEffect(() => {
    setForDate(selectedDate.format('YYYY-MM-DD'))
  }, [selectedDate])

  useEffect(() => {
    if (timespan && regularHistoricPlanTotals && nonRegularHistoricPlanTotals && historicPackageTotals && historicCampaignTotals && historicSubscriptionTotals) {
      let innerTotals: Totals[] = []

      regularHistoricPlanTotals.forEach(planTotal => {
        innerTotals.push({ date: planTotal.forDate, total: planTotal[`totalActive${timespan}Revenue` as timespanSumKeyPlan] + planTotal[`totalOnboarding${timespan}Revenue` as timespanSumKeyPlan] })
      })

      nonRegularHistoricPlanTotals.forEach(planTotal => {
        let innerTotal = innerTotals.find(y => y.date === planTotal.forDate)
        if (innerTotal) {
          innerTotal.total = innerTotal.total + planTotal[`totalActive${timespan}Revenue` as timespanSumKeyPlan]
          innerTotal.total = innerTotal.total + planTotal[`totalOnboarding${timespan}Revenue` as timespanSumKeyPlan]
        }
      })

      historicPackageTotals.forEach(packageTotal => {
        let innerTotal = innerTotals.find(y => y.date === packageTotal.forDate)
        if (innerTotal) {
          innerTotal.total = innerTotal.total + packageTotal[`totalActive${timespan}Revenue` as timespanSumKeyPackage]
          innerTotal.total = innerTotal.total + packageTotal[`totalOnboarding${timespan}Revenue` as timespanSumKeyPackage]
        }
      })

      historicCampaignTotals.forEach(campaignTotal => {
        let innerTotal = innerTotals.find(y => y.date === campaignTotal.forDate)
        if (innerTotal) {
          innerTotal.total = innerTotal.total + campaignTotal[`total${timespan}Revenue` as timespanSumKeyCampaign]
        }
      })

      historicSubscriptionTotals.forEach(subscriptionTotal => {
        let innerTotal = innerTotals.find(y => y.date === subscriptionTotal.forDate)
        if (innerTotal) {
          innerTotal.total = innerTotal.total + subscriptionTotal[`totalActive${timespan}Revenue` as timespanSumKeySubscription]
        }
      })

      setTotals(innerTotals)
    }
  }, [timespan, regularHistoricPlanTotals, nonRegularHistoricPlanTotals, historicPackageTotals, historicCampaignTotals, historicSubscriptionTotals])

  useEffect(() => {
    if (campaignTotals && regularPlanTotals && nonRegularPlanTotals && packageTotals && subscriptionTotals && directCampaignTotals && agencyCampaignTotals && partnerCampaignTotals) {
      setTotal(
        campaignTotals.totalRevenue[timespan.toLowerCase() as timespanKeyCampaign] +
          regularPlanTotals.activeRevenue[timespan.toLowerCase() as timespanKeyPlan] +
          regularPlanTotals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPlan] +
          nonRegularPlanTotals.activeRevenue[timespan.toLowerCase() as timespanKeyPlan] +
          nonRegularPlanTotals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPlan] +
          packageTotals.activeRevenue[timespan.toLowerCase() as timespanKeyPackage] +
          packageTotals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPackage] +
          subscriptionTotals.activeRevenue[timespan.toLowerCase() as timespanKeySubscription]
      )
      setTotalIsLoading(false)
    }
  }, [timespan, campaignTotals, regularPlanTotals, nonRegularPlanTotals, packageTotals, subscriptionTotals, agencyCampaignTotals, directCampaignTotals, partnerCampaignTotals])

  const onChangeForDate = (date: moment.Moment) => {
    setSelectedDate(date)
  }

  const onChangeTimespan = (value: string) => {
    setTimespan(value)
  }

  const dashboardBaseTheme: PartialTheme = {
    crosshair: {
      band: {
        visible: false
      }
    },
    lineSeriesStyle: {
      point: {
        radius: 2,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    highlighter: {
      point: {
        radius: 4,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    axes: {
      gridLine: {
        vertical: {
          visible: false
        },
        horizontal: {
          visible: false
        }
      }
    }
  }
  const darkBackground: PartialTheme = {
    background: {
      color: 'transparent'
    }
  }
  const darkTheme = [dashboardBaseTheme, darkBackground, EUI_CHARTS_THEME_DARK.theme]

  return (
    <EuiPageTemplate panelled={true}>
      <EuiPageTemplate.Sidebar minWidth={320}>
        <EuiThemeProvider colorMode='dark'>
          <EuiPanel hasShadow={false} hasBorder={true} color='success'>
            <EuiStat description={<span style={{ color: headingColor }}>{`Current ${timespan} Revenue`}</span>} title={total.toLocaleString('en-US', currencyStyle)} isLoading={totalIsLoading} />
            {totals && (
              <Chart size={{ height: 40 }}>
                <Settings theme={darkTheme} showLegend={false} />
                <Axis
                  id='total'
                  groupId='total'
                  domain={{
                    min: Math.min(...totals.map(x => x.total)) * 0.95,
                    max: Math.max(...totals.map(x => x.total)) * 1.05
                  }}
                  hide={true}
                />
                <LineSeries id='totalRevenue' name={`${timespan} Revenue`} data={totals} xAccessor={x => moment(x.date).utc().format('YYYY-MM-DD')} yAccessors={[y => y.total]} color={[euiPaletteForDarkBackground()[0]]} groupId='total' curve={CurveType.CURVE_MONOTONE_X} tickFormat={d => `${Number(d).toLocaleString('en-US', currencyStyle)}`} />
              </Chart>
            )}
          </EuiPanel>
        </EuiThemeProvider>
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='plain'>
          <EuiStat description={`MTD Signups`} title={subscriptionTotals ? subscriptionTotals.totalMtdSignups : 0} titleSize='m' isLoading={isLoadingExecutiveDashboardSubscription}>
            Previous MTD {subscriptionTotals ? subscriptionTotals.totalPreviousMtdSignups : '--'} ({subscriptionTotals ? subscriptionTotals.totalPreviousSignups : '--'} total)
          </EuiStat>
        </EuiPanel>
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='plain'>
          <EuiStat description={`MTD New Customers`} title={customers ? customers.totalMtdNewCustomers : 0} titleSize='m' isLoading={isLoadingExecutiveDashboardCustomer}>
            Previous MTD {customers ? customers.totalPreviousMtdNewCustomers : '--'} ({customers ? customers.totalPreviousTotalNewCustomers : '--'} total)
          </EuiStat>
          <EuiSpacer size='xs' />
          <EuiFlexGroup justifyContent='spaceBetween' gutterSize='none'>
            <EuiFlexItem grow={false}>{customers && <ExecutiveDashboardCustomerReportFlyout forDate={forDate} organizationIds={customers.mtdNewCustomerIds} title={'view mtd'} />}</EuiFlexItem>
            <EuiFlexItem grow={false}>{customers && <ExecutiveDashboardCustomerReportFlyout forDate={forDate} organizationIds={customers.previousTotalNewCustomerIds} title={'view previous'} />}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <EuiStat description={`Regular Plans ${timespan} Revenue`} title={regularPlanTotals ? (regularPlanTotals.activeRevenue[timespan.toLowerCase() as timespanKeyPlan] + regularPlanTotals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPlan]).toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardRegularPlan} />
        </EuiPanel>
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <EuiStat description={`Non-regular Plans ${timespan} Revenue`} title={nonRegularPlanTotals ? (nonRegularPlanTotals.activeRevenue[timespan.toLowerCase() as timespanKeyPlan] + nonRegularPlanTotals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPlan]).toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardNonRegularPlan} />
        </EuiPanel>
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <EuiStat description={`Packages ${timespan} Revenue`} title={packageTotals ? (packageTotals.activeRevenue[timespan.toLowerCase() as timespanKeyPackage] + packageTotals.onboardingRevenue[timespan.toLowerCase() as timespanKeyPackage]).toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardPackage} />
        </EuiPanel>
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <EuiStat description={`Direct Campaigns ${timespan} Revenue`} title={directCampaignTotals ? directCampaignTotals.totalRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardCampaignDirect} />
        </EuiPanel>
        <EuiSpacer size='m' />
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <EuiStat description={`Agency Campaigns ${timespan} Revenue`} title={agencyCampaignTotals ? agencyCampaignTotals.totalRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardCampaignAgency} />
        </EuiPanel>
        {totalPartnerCampaigns > 0 && (
          <React.Fragment>
            <EuiSpacer size='m' />
            <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
              <EuiStat description={`Partner Campaigns ${timespan} Revenue`} title={partnerCampaignTotals ? partnerCampaignTotals.totalRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardCampaignPartner} />
            </EuiPanel>
          </React.Fragment>
        )}
        <EuiSpacer size='l' />
        <EuiPanel hasShadow={false} hasBorder={true} color='primary'>
          <EuiStat description={`Subscriptions ${timespan} Revenue`} title={subscriptionTotals ? subscriptionTotals.activeRevenue[timespan.toLowerCase() as timespanKeySubscription].toLocaleString('en-US', currencyStyle) : '--'} titleSize='s' isLoading={isLoadingExecutiveDashboardSubscription} />
        </EuiPanel>
      </EuiPageTemplate.Sidebar>
      <EuiPageTemplate.Header
        iconType='advancedSettingsApp'
        pageTitle='Executive Dashboard'
        tabs={tabs}
        restrictWidth={false}
        bottomBorder={false}
        rightSideItems={[<EuiDatePicker selected={selectedDate} onChange={onChangeForDate} maxDate={dates ? moment(dates.forDates[0]) : selectedDate} minDate={dates ? moment(dates.forDates[dates.forDates.length - 1]) : selectedDate} isLoading={isLoadingExecutiveDashboardDates} />, <EuiSuperSelect options={timespans} valueOfSelected={timespan} onChange={value => onChangeTimespan(value)} />]}
      />
      <EuiPageTemplate.Section restrictWidth={false} bottomBorder={false} grow={true}>
        {tab === 0 && (
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <PlanOverviewPanel category={Category.Regular} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem>
              <PlanOverviewPanel category={Category.LeadGeneration} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem>
              <PlanOverviewPanel category={Category.ShortTerm} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem>
              <PlanOverviewPanel category={Category.Event} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem>
              <PlanOverviewPanel category={Category.Political} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        {tab === 1 && (
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <PackageOverviewPanel forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem />
          </EuiFlexGroup>
        )}
        {tab === 2 && (
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <CampaignChannelOverviewPanel channel={Channel.All} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem>
              <CampaignChannelOverviewPanel channel={Channel.Direct} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem>
              <CampaignChannelOverviewPanel channel={Channel.Agency} forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            {partnerCampaignTotals && partnerCampaignTotals.totalActive > 0 && (
              <EuiFlexItem>
                <CampaignChannelOverviewPanel channel={Channel.Partner} forDate={forDate} timespan={timespan} />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        )}
        {tab === 3 && (
          <EuiFlexGroup gutterSize='xs'>
            <EuiFlexItem>
              <SubscriptionOverviewPanel forDate={forDate} timespan={timespan} />
            </EuiFlexItem>
            <EuiFlexItem />
            <EuiFlexItem />
          </EuiFlexGroup>
        )}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}
export default NewExecutiveDashboardPage
