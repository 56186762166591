import { Moment } from 'moment'

export interface Anomaly {
  id: string
  organizationId: string
  organizationName: string
  referenceType: AnomalyReferenceType
  referenceId: string
  anomalyType: AnomalyType
  description: string
  createdAt: Moment
  status: AnomalyStatus
  timeline: AnomalyTimelineItem[]
  ignoreUntil?: Moment
}

export interface AnomalyTimelineItem {
  id: string
  createdAt: Moment
  createdBy: string
  lastStatus: AnomalyStatus
  newStatus: AnomalyStatus
  description: string
}

export enum AnomalyReferenceType {
  Campaign = 'Campaign',
  Bundle = 'Bundle'
}

export enum AnomalyType {
  NoFlight = 'NoFlight',
  NotDelivering = 'NotDelivering',
  UnexpectedDelivery = 'UnexpectedDelivery',
  ZeroPercentPacing = 'ZeroPercentPacing',
  UnderPacing = 'UnderPacing',
  PacingNotDelivering = 'PacingNotDelivering',
  BundleCampaignNotActive = 'BundleCampaignNotActive',
  BundleUnexpectedCampaignActive = 'BundleUnexpectedCampaignActive',
  BundleIdMismatch = 'BundleIdMismatch',
  BundleMultipleMatchingSlotIds = 'BundleMultipleMatchingSlotIds',
  HighCpm = 'HighCpm',
  LowCpm = 'LowCpm',
  NoBillingOrder = 'NoBillingOrder',
  UnexpectedBillingOrder = 'UnexpectedBillingOrder',
  MismatchedBillingOrder = 'MismatchedBillingOrder'
}

export enum AnomalyStatus {
  ActiveIssue = 'ActiveIssue',
  Responded = 'Responded',
  Resolved = 'Resolved',
  Escalated = 'Escalated',
  Ignored = 'Ignored'
}
