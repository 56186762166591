import { EuiCheckboxGroup } from '@elastic/eui'

export interface IAcCheckBoxOption {
  key: string
  text: string
  checked?: boolean
}

export interface IAcCheckBoxGroup {
  options: IAcCheckBoxOption[]
  onChange: (option: IAcCheckBoxOption) => void
}

export const AcCheckBoxGroup: React.FC<IAcCheckBoxGroup> = props => {
  const selectedItemMap: any = {}

  const checkboxes = props.options.map(option => {
    return {
      id: option.key,
      label: option.text
    }
  })

  props.options.forEach(option => {
    selectedItemMap[option.key] = option.checked
  })

  const onCheckboxChange = (optionId: string) => {
    const checkbox = { ...props.options.find(option => option.key === optionId) } as IAcCheckBoxOption
    checkbox.checked = !checkbox.checked
    if (checkbox) {
      props.onChange(checkbox)
    }
  }

  return <EuiCheckboxGroup options={checkboxes} idToSelectedMap={selectedItemMap} onChange={onCheckboxChange} />
}
