import Konva from 'konva'
import React, { useCallback, useEffect } from 'react'
import { Layer, Rect, Text } from 'react-konva'

import { IAdCreator } from 'components/AdCreation/AdCreators/IAdCreator'
import { ContentFocus, FocusArea } from 'components/AdCreation/DisplayMaker'
import { ACImage } from 'components/Basic/ACImage'
import { IsDark } from 'utils/colorUtils'

export const AdCreatorRectangle: React.FC<IAdCreator> = props => {
  const backgroundLayerRef = React.useRef<Konva.Layer>(null)
  const imageLayerRef = React.useRef<Konva.Layer>(null)
  const nameLayerRef = React.useRef<Konva.Layer>(null)

  const [baseWidth, setBaseWidth] = React.useState(0)
  const [baseHeight, setBaseHeight] = React.useState(0)

  const colorPalette = props.colorPalette

  useEffect(() => {
    const image = new Image()
    image.src = props.uploadedUrl
    image.onload = () => {
      setBaseWidth(image.width)
      setBaseHeight(image.height)
    }
  }, [props.uploadedUrl])

  const getScaledWidth = useCallback(
    (height: number) => {
      if (!baseHeight || baseHeight <= 0) {
        return 1
      }
      return (baseWidth / baseHeight) * height
    },
    [baseWidth, baseHeight]
  )

  const xOffSet = (() => {
    if (props.contentSpecifications.focusArea === FocusArea.Left) {
      return 0
    } else if (props.contentSpecifications.focusArea === FocusArea.Center) {
      return (-1 * (props.adWidth - getScaledWidth(props.adHeight))) / 2
    } else if (props.contentSpecifications.focusArea === FocusArea.Right) {
      return -1 * (props.adWidth - getScaledWidth(props.adHeight))
    }
    return 0
  })()

  const baseScale = props.scale ?? 1

  return (
    <React.Fragment>
      <Layer
        name={'image'}
        ref={imageLayerRef}
        offsetX={(props.xOffSet ?? 0) + xOffSet}
        offsetY={props.yOffSet}
        scale={{ x: baseScale, y: baseScale }}
        clip={{
          x: xOffSet,
          y: 0,
          width: props.adWidth,
          height: props.adHeight
        }}>
        <ACImage backgroundImageUrl={props.uploadedUrl} key={'backgroundImage'} viewportHeight={props.adHeight} viewportWidth={props.adWidth} height={props.adHeight} width={getScaledWidth(props.adHeight)} />
      </Layer>
      {props.contentSpecifications.contentFocus === ContentFocus.CompanyName && (
        <React.Fragment>
          <Layer name={'background'} ref={backgroundLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: baseScale, y: baseScale }}>
            <Rect x={0} y={0} width={props.adWidth} height={props.adHeight} fill={'#000000' + '5F'} />
          </Layer>
          <Layer name={'name'} ref={nameLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: baseScale, y: baseScale }}>
            <Text x={12} y={24} text={props.details.companyName} fontSize={36} fill={'#FFF'} height={72} width={props.adWidth - 12 - 12} fontStyle={'bold'} />
            <Text x={12} y={props.adHeight - 24 - 40 - 72} text={props.details.headline} fontSize={26} fill={'#FFF'} height={22 + 72} width={props.adWidth - 12 - 12} lineHeight={1.1} />
            <Rect cornerRadius={24} x={props.adWidth / 4} y={props.adHeight - 24 - 40} width={props.adWidth / 2} height={48} fill={colorPalette.buttonColor + 'dd'} />
            <Text align={'center'} fontSize={24} text={props.details.callToAction} x={props.adWidth / 4} y={props.adHeight - 24 - 40 + 12} width={props.adWidth / 2} height={48} fill={IsDark(colorPalette.buttonColor) ? '#FFF' : '#000'} />
          </Layer>
        </React.Fragment>
      )}
      {props.contentSpecifications.contentFocus === ContentFocus.Headline && (
        <React.Fragment>
          <Layer name={'background'} ref={backgroundLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: baseScale, y: baseScale }}>
            <Rect x={0} y={0} width={props.adWidth} height={props.adHeight} fill={'#000000' + '5F'} />
          </Layer>
          <Layer name={'name'} ref={nameLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: baseScale, y: baseScale }}>
            <Text x={12} y={24} text={props.details.headline} fontSize={32} fill={'#FFF'} height={110} width={props.adWidth - 12 - 12} fontStyle={'bold'} lineHeight={1.1} />
            <Text x={12} y={24 + 110 + 18} text={props.details.companyName} fontSize={28} fill={'#FFF'} height={28 + 72} width={props.adWidth - 12 - 12} />
            <Rect cornerRadius={24} x={props.adWidth / 4} y={props.adHeight - 24 - 40} width={props.adWidth / 2} height={48} fill={colorPalette.buttonColor + 'dd'} />
            <Text align={'center'} fontSize={24} text={props.details.callToAction} x={props.adWidth / 4} y={props.adHeight - 24 - 40 + 12} width={props.adWidth / 2} height={48} fill={IsDark(colorPalette.buttonColor) ? '#FFF' : '#000'} />
          </Layer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
