import React, {useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {EuiButton, EuiCallOut, EuiSpacer} from "@elastic/eui";
import {
  useGetPrepareCaptureOrganizationQuery, useSyncCardsMutation
} from "api/rtkQueryApi/opsApi/paymentMethodsApi";

export interface IAddPaymentCard {
  organizationId: string
  accountId?: string
  onCardAdded?: (stripeCardId: string) => void
}

export const AddPaymentCard : React.FC<IAddPaymentCard> = (props: IAddPaymentCard) => {
  const stripe = useStripe()
  const elements = useElements()
  const [token, setToken] = useState<string>('')
  const prepareCaptureOrganizationRequest = useGetPrepareCaptureOrganizationQuery({organizationId: props.organizationId, accountId: props.accountId}, {refetchOnMountOrArgChange: true})
  const [syncCards, syncCardsRequest] = useSyncCardsMutation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  useEffect(() => {
    if (prepareCaptureOrganizationRequest.data) {
      setToken(prepareCaptureOrganizationRequest.data.clientSecret)
    }
  }, [prepareCaptureOrganizationRequest.data])

  const AddCardElement = () => {
    if (!stripe || !elements || !token) {
      return
    }

    setIsLoading(true)

    const cardElement = elements.getElement(CardElement)

    const payment_method = { card: cardElement }
    stripe.confirmCardSetup(token, { payment_method } as any).then((payload: any) => {
      if (payload.error) {
        setError(payload.error.message)
      } else {
        setError(undefined)
        syncCards({organizationId: props.organizationId, accountId: props.accountId}).then(
          () => {
            props.onCardAdded?.(payload.setupIntent.id)
          }
        )
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return <React.Fragment>
    <CardElement
      options={{
        classes: {
          base: 'creditCard',
          complete: 'creditCard',
          empty: 'creditCard',
          focus: 'creditCard-focus',
          invalid: 'creditCard-error'
        }
      }}
    />
    {!!error &&
    <EuiCallOut title="Issue processing card" color="danger" iconType="error">
        <p>
          {error}
        </p>
    </EuiCallOut>
    }
    <EuiSpacer/>
    <EuiButton onClick={AddCardElement} isDisabled={!stripe || !elements || !token} isLoading={syncCardsRequest.isLoading || isLoading}>Add Card</EuiButton>
  </React.Fragment>
}