import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EuiBreadcrumbs, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiTabbedContent, EuiBadge, EuiCopy, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

import BusinessTypeCampaignsTab from './BusinessTypeCampaignsTab'
import BusinessTypeDetailsTab from './BusinessTypeDetailsTab'
import BusinessTypeTargetingTab from './BusinessTypeTargetingTab'
import BusinessTypeBodiesTab from './general/BusinessTypeBodiesTab'
import BusinessTypeHeadlinesTab from './general/BusinessTypeHeadlinesTab'
import BusinessTypeImagesTab from './general/BusinessTypeImagesTab'
import BusinessTypePhrasesTab from './general/BusinessTypePhrasesTab'

interface BusinessTypePageParams {
  businessTypeId: string
}

const BusinessTypePage: React.FC = () => {
  let { businessTypeId } = useParams<BusinessTypePageParams>()
  const [name, setName] = useState('')
  const opsClient = useOpsClient()
  const [adTypeTabs, setAdTypeTabs] = useState<EuiTabbedContentTab[]>([])

  useEffect(() => {
    if (opsClient) {
      opsClient.getBusinessType(businessTypeId).then(result => {
        setName(result.name)
      })
    }
  }, [opsClient, businessTypeId])

  useEffect(() => {
    let adTypeTabs: EuiTabbedContentTab[] = []

    adTypeTabs.push({
      id: 'details',
      name: 'Details',
      content: <BusinessTypeDetailsTab businessTypeId={businessTypeId} />
    })
    adTypeTabs.push({
      id: 'phrases',
      name: 'Phrases',
      content: <BusinessTypePhrasesTab businessTypeId={businessTypeId} />
    })
    adTypeTabs.push({
      id: 'headlines',
      name: 'Headlines',
      content: <BusinessTypeHeadlinesTab businessTypeId={businessTypeId} />
    })
    adTypeTabs.push({
      id: 'bodies',
      name: 'Bodies',
      content: <BusinessTypeBodiesTab businessTypeId={businessTypeId} />
    })
    adTypeTabs.push({
      id: 'images',
      name: 'Images',
      content: <BusinessTypeImagesTab businessTypeId={businessTypeId} />
    })
    adTypeTabs.push({
      id: 'targeting',
      name: 'Smart Targeting',
      content: <BusinessTypeTargetingTab businessTypeId={businessTypeId} />
    })
    adTypeTabs.push({
      id: 'campaigns',
      name: 'Campaigns',
      content: <BusinessTypeCampaignsTab businessTypeId={businessTypeId} />
    })

    setAdTypeTabs(adTypeTabs)
  }, [businessTypeId])

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Data' },
              {
                text: 'Business Types',
                href: '/data/businessTypes',
                onClick: () => history.push('/data/businessTypes')
              },
              {
                text: name,
                href: `/data/businessTypes/${businessTypeId}`,
                onClick: () => history.push(`/data/businessTypes/${businessTypeId}`)
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>Business Type</h1>
              </EuiTitle>
              <EuiFlexGroup gutterSize='m'>
                <EuiFlexItem>
                  <EuiCopy textToCopy={businessTypeId}>
                    {copy => (
                      <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy Business Type ID'>
                        {businessTypeId}
                      </EuiBadge>
                    )}
                  </EuiCopy>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>{adTypeTabs && adTypeTabs.length > 0 && <EuiTabbedContent tabs={adTypeTabs} initialSelectedTab={adTypeTabs[0]} />}</EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default BusinessTypePage
