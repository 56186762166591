import React from 'react'

import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'

import { Zip } from 'api'

interface ZipTargeterProps {
  zips: Array<Zip>

  onZipRemoved(zip: Zip): void
}

export const ZipTargeter = ({ zips, onZipRemoved }: ZipTargeterProps) => {
  return (
    <React.Fragment>
      {zips && zips.length > 0 && (
        <EuiListGroup bordered={true} maxWidth={false} gutterSize='none' size='s' flush={true} style={{ height: 174, overflow: 'auto' }}>
          {zips.map(w => (
            <EuiListGroupItem
              size='xs'
              key={w.zipId.toString()}
              label={w.name}
              extraAction={{
                onClick: () => {
                  onZipRemoved(w)
                },
                iconType: 'cross',
                iconSize: 's',
                'aria-label': 'Remove',
                alwaysShow: true
              }}
            />
          ))}
        </EuiListGroup>
      )}
    </React.Fragment>
  )
}
