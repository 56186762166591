import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import { INavSelectorItem, NavSelectorDropDown } from './NavSelectorDropDown'

export const LegacyMenu: React.FC = () => {
  const { userProfile } = useSelector((state: RootState) => state.app)

  let legacyItems: INavSelectorItem[] = [
    {
      type: 'indexPatternApp',
      size: 'l',
      onClick: () => {
        history.push('/organizationRosters')
      },
      label: 'Org Rosters'
    },
    {
      type: 'visualizeApp',
      size: 'l',
      onClick: () => {
        history.push('/executive/dashboard/new')
      },
      label: 'Exec Dashboard'
    },
    {
      type: 'monitoringApp',
      size: 'l',
      onClick: () => {
        history.push('/performance/dashboard')
      },
      label: 'Perf Dashboard'
    },
    {
      type: 'usersRolesApp',
      size: 'l',
      onClick: () => {
        history.push('/customers')
      },
      label: 'Customers'
    },
    {
      type: 'spacesApp',
      size: 'l',
      onClick: () => {
        history.push('/templates')
      },
      label: 'Templates'
    },
    {
      type: 'sqlApp',
      size: 'l',
      onClick: () => {
        history.push('/data')
      },
      label: 'Data'
    },
    {
      type: 'metricsApp',
      size: 'l',
      onClick: () => {
        history.push('/assets')
      },
      label: 'Assets'
    },
    {
      type: 'casesApp',
      size: 'l',
      onClick: () => {
        history.push('/reports')
      },
      label: 'Reports'
    },
    {
      type: 'devToolsApp',
      size: 'l',
      onClick: () => {
        history.push('/settings')
      },
      label: 'Settings'
    }
  ]

  if (!userProfile || !userProfile.roles.includes('adcritter-ops-csuite')) {
    if (userProfile && userProfile.roles.includes('adcritter-ops-vistarupload')) {
      legacyItems = legacyItems.filter(item => item.label === 'Settings')
    } else {
      return <></>
    }
  }

  return <NavSelectorDropDown buttonText='Legacy' items={legacyItems} />
}
