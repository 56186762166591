import Konva from 'konva'
import React, { useCallback, useEffect } from 'react'
import { Layer, Rect, Text } from 'react-konva'

import { IAdCreator } from 'components/AdCreation/AdCreators/IAdCreator'
import { ACImage } from 'components/Basic/ACImage'
import { IsDark } from 'utils/colorUtils'

export const AdCreatorSmallBanner: React.FC<IAdCreator> = props => {
  const backgroundLayerRef = React.useRef<Konva.Layer>(null)
  const imageLayerRef = React.useRef<Konva.Layer>(null)
  const nameLayerRef = React.useRef<Konva.Layer>(null)

  const [baseWidth, setBaseWidth] = React.useState(0)
  const [baseHeight, setBaseHeight] = React.useState(0)

  const colorPalette = props.colorPalette

  useEffect(() => {
    const image = new Image()
    image.src = props.uploadedUrl
    image.onload = () => {
      setBaseWidth(image.width)
      setBaseHeight(image.height)
    }
  }, [props.uploadedUrl])

  const getScaledWidth = useCallback(
    (height: number) => {
      if (!baseHeight || baseHeight <= 0) {
        return 1
      }
      return (baseWidth / baseHeight) * height
    },
    [baseWidth, baseHeight]
  )

  return (
    <React.Fragment>
      <Layer name={'background'} ref={backgroundLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Rect x={0} y={0} width={props.adWidth} height={props.adHeight} fill={colorPalette.backgroundColor} />
      </Layer>
      <Layer name={'image'} ref={imageLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <ACImage backgroundImageUrl={props.uploadedUrl} key={'backgroundImage'} height={props.adHeight} width={getScaledWidth(props.adHeight)} />
      </Layer>
      <Layer name={'name'} ref={nameLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Text x={getScaledWidth(props.adHeight) + 8} y={10} text={props.details.headline} fontSize={10} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} verticalAlign={'center'} height={28} width={props.adWidth - getScaledWidth(props.adHeight) - 8} />
        <Text x={getScaledWidth(props.adHeight) + 8} y={props.adHeight - 24} text={props.details.companyName} fontSize={16} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} verticalAlign={'center'} height={props.adHeight - 24} width={props.adWidth - getScaledWidth(props.adHeight) - 8} fontStyle={'bold'} />
      </Layer>
    </React.Fragment>
  )
}
