import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiButton, EuiCallOut, EuiFieldText, EuiFilePicker, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiLink, EuiPortal, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Campaign, useOpsClient } from 'api'

interface AdCreateTvFlyOutParams {
  campaign: Campaign
  onCreated: () => void
}

interface TvAdFormValues {
  name: string
}

const CampaignCreateTvAdFlyOut: React.FC<AdCreateTvFlyOutParams> = ({ campaign, onCreated }) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const opsClient = useOpsClient()

  const showFlyout = () => setIsFlyoutVisible(true)

  const closeFlyout = () => setIsFlyoutVisible(false)

  const [isUploadingUploadedUrl, setIsUploadingUploadedUrl] = useState(false)
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null)
  const [tvContainerClient, setTVContainerClient] = useState<ContainerClient | null>(null)
  const [duration, setDuration] = useState<number>(0)
  const [videoId, setVideoId] = useState<string>('')
  const [uploadedUrlError, setUploadedUrlError] = useState<string | null>(null)
  const [createDisabled, setCreateDisabled] = useState<boolean>(true)
  const [fileTypeError, setFileTypeError] = useState<string>('')

  useEffect(() => {
    if (campaign?.accountId) {
      opsClient?.getTvCommercialUpload(campaign.accountId).then(data => {
        const storageClient = new BlobServiceClient(data)
        const containerClient = storageClient.getContainerClient(campaign.accountId)
        setTVContainerClient(containerClient)
      })
    }
  }, [campaign?.accountId, opsClient])

  useEffect(() => {
    if (uploadedUrl) {
      setTimeout(() => {
        let vid = document.getElementById('durationId') as HTMLVideoElement
        let rounded = Math.round(vid?.duration)
        setDuration(rounded)
      }, 1500)
    }
  }, [uploadedUrl])

  const onuploadedUrlChange = (files: FileList | null) => {
    if (tvContainerClient && files && files.length === 1 && files[0].type === 'video/mp4') {
      setIsUploadingUploadedUrl(true)
      const blockBlobClient = tvContainerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())
      let url = blockBlobClient!.url!.split('?')[0]
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingUploadedUrl(false)
          setUploadedUrl(url)
          setUploadedUrlError(null)
          setVideoId('durationId')
        })
    }
    if (tvContainerClient && files && files.length === 1 && files[0].type !== 'video/mp4') {
      setFileTypeError(`This file type is not supported.`)
    }

    if (tvContainerClient && files && files.length === 0) {
      setUploadedUrl(null)
      setUploadedUrlError('Please upload a complete TV commercial')
      setFileTypeError('')
      setIsUploadingUploadedUrl(false)
    }
  }

  const tvAdSchema = Yup.object().shape({
    name: Yup.string().required()
  })

  useEffect(() => {
    if (uploadedUrl) {
      setCreateDisabled(false)
    } else {
      setCreateDisabled(true)
    }
  }, [uploadedUrl])

  const create = (values: TvAdFormValues, { setSubmitting }: FormikHelpers<TvAdFormValues>) => {
    if (campaign) {
      opsClient
        ?.postTVAd({
          name: values.name,
          type: 'VoiceOver',
          uploadedUrl: uploadedUrl,
          accountId: campaign.accountId,
          campaignId: campaign.campaignId,
          organizationId: campaign.organizationId,
          duration: duration
        })
        .then((r: any) => {
          setSubmitting(false)
          if (r) {
            setUploadedUrl(null)
            closeFlyout()
            onCreated()
          }
        })
    }
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={{ name: '' }} onSubmit={create} validationSchema={tvAdSchema}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create TV Ad</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiSpacer />
                  {uploadedUrl && (
                    <video id={videoId} width='100%' controls hidden={true}>
                      <source src={uploadedUrl} type={`video/mp4`} />
                    </video>
                  )}
                  <EuiFormRow label='Complete TV Commercial' fullWidth isInvalid={!!uploadedUrlError} error={uploadedUrlError}>
                    <EuiFilePicker id='uploadedUrl' fullWidth display='large' multiple={false} initialPromptText='Select or drag and drop commercial here' onChange={onuploadedUrlChange} isLoading={isUploadingUploadedUrl} />
                  </EuiFormRow>
                  <EuiSpacer />

                  {fileTypeError !== '' && (
                    <EuiCallOut title={fileTypeError} color='danger' iconType='documentEdit'>
                      <EuiText size={'s'}>Please remove file, and then upload an mp4 file.</EuiText>
                    </EuiCallOut>
                  )}

                  <EuiSpacer />
                  <EuiButton type='submit' fill isLoading={props.isSubmitting} isDisabled={createDisabled}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showFlyout} color='primary'>
        Create TV Commercial
      </EuiLink>
      {flyout}
    </React.Fragment>
  )
}

export default CampaignCreateTvAdFlyOut
