import numeral from 'numeral'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { EuiBadge, EuiFieldSearch, EuiHighlight, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

import { ThirdPartyAudience, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface AudienceTargeterProps {
  isInvalid: boolean
  onAudienceClicked(audience: ThirdPartyAudience): void
  onAudiencesFound?: (audiences: ThirdPartyAudience[]) => void
}

export const AudienceFinder = ({ onAudienceClicked, isInvalid, onAudiencesFound }: AudienceTargeterProps) => {
  const minimumLengthForSearch = 2
  const [audienceSearchTerm, setAudienceSearchTerm] = useState<string>('')
  const debouncedAudienceSearchTerm = useDebounce(audienceSearchTerm, 750)
  const [searchAudiencePopoverOpen, setSearchAudiencePopoverOpen] = useState<boolean>(false)
  const [audiences, setAudiences] = useState<Array<ThirdPartyAudience>>([])
  const [selectableAudiences, setSelectableAudiences] = useState<Array<EuiSelectableOption>>([])
  const [isLoadingAudiences, setIsLoadingAudiences] = useState<boolean>(false)
  const opsClient = useOpsClient()
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }),
    []
  )

  const onSearchAudience = () => {
    setSearchAudiencePopoverOpen(false)
  }

  const onSearchAudienceChange = (hint: string) => {
    setAudienceSearchTerm(hint)
  }

  const onSearchAudienceClick = useCallback(
    (audience: ThirdPartyAudience) => {
      setSearchAudiencePopoverOpen(false)
      setAudienceSearchTerm('')
      onAudienceClicked(audience)
    },
    [onAudienceClicked]
  )

  useEffect(() => {
    if (opsClient && debouncedAudienceSearchTerm && opsClient && debouncedAudienceSearchTerm.length > minimumLengthForSearch) {
      opsClient.apiGetDataAudiences(debouncedAudienceSearchTerm).then(r => {
        setAudiences(r.data)
        setIsLoadingAudiences(false)
        if (onAudiencesFound) {
          onAudiencesFound(r.data)
        }
      })
    } else {
      setAudiences([])
      if (onAudiencesFound) {
        onAudiencesFound([])
      }
    }
  }, [opsClient, debouncedAudienceSearchTerm, onAudiencesFound])

  useEffect(() => {
    if (audiences) {
      setSelectableAudiences(
        audiences.map(
          a =>
            ({
              label: a.name,
              key: a.id.toString(),
              checked: undefined,
              append: (
                <>
                  <EuiBadge>{a.reachCount > 0 ? numeral(a.reachCount).format('0a') : '~'} people</EuiBadge>
                  <EuiBadge color='hollow'>{formatter.format(a.price)}</EuiBadge>
                </>
              ),
              onClick: () => onSearchAudienceClick(a)
            } as EuiSelectableOption)
        )
      )
    }
  }, [audiences, onSearchAudienceClick, formatter])

  const renderAudienceOption = (option: EuiSelectableOption, _: string) => {
    const audience = audiences.find(a => a.id.toString() === option.key)
    if (!audience) return <></>

    return (
      <React.Fragment>
        <EuiHighlight search={audienceSearchTerm}>{option.label}</EuiHighlight>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={audienceSearchTerm}>{audience.description}</EuiHighlight>
        </EuiText>
        <EuiText style={{ paddingLeft: '10px' }} size='xs'>
          <EuiHighlight search={audienceSearchTerm}>{audience.hierarchy.join(' \\ ')}</EuiHighlight>
        </EuiText>
      </React.Fragment>
    )
  }

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={audienceSearchTerm}
          isLoading={isLoadingAudiences}
          onChange={v => onSearchAudienceChange(v.target.value)}
          onFocus={() => setSearchAudiencePopoverOpen(true)}
          incremental={false}
          onSearch={() => onSearchAudience()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={isInvalid}
        />
      }
      isOpen={searchAudiencePopoverOpen}
      closePopover={() => setSearchAudiencePopoverOpen(false)}>
      {audiences && audiences.length ? (
        <EuiSelectable singleSelection options={selectableAudiences} renderOption={renderAudienceOption} listProps={{ rowHeight: 60, isVirtualized: true, showIcons: false }}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search, results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
