import { Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { EuiButton, EuiFieldText, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiPortal, EuiSpacer, EuiTitle } from '@elastic/eui'

import { useOpsClient } from 'api'
import history from 'services/HistoryService'

interface FormValues {
  name: string
  code: string
}

const AnchorPhraseCreateFlyOut: React.FC = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState<boolean>(false)
  const opsClient = useOpsClient()

  const showFlyout = () => {
    setIsFlyoutVisible(true)
  }

  const closeFlyout = () => setIsFlyoutVisible(false)

  const anchorPhraseTypeSchema = Yup.object().shape({
    name: Yup.string().max(128).required('Please enter the anchor phrase name'),
    code: Yup.string().max(128).required('Please enter the anchor phrase code')
  })

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    opsClient?.createAnchorPhrase({ name: values.name, code: values.code }).then(result => {
      setSubmitting(false)
      setIsFlyoutVisible(false)
      history.push(`/data/anchorPhrases/${result.id}`)
    })
  }

  let flyout
  if (isFlyoutVisible) {
    flyout = (
      <EuiPortal>
        <Formik initialValues={{ name: '', code: '' }} validationSchema={anchorPhraseTypeSchema} onSubmit={doSubmit}>
          {props => (
            <EuiFlyout onClose={closeFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
              <EuiFlyoutHeader>
                <EuiTitle size='s'>
                  <h2>Create Anchor Phrase</h2>
                </EuiTitle>
              </EuiFlyoutHeader>
              <EuiFlyoutBody>
                <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                  <EuiFormRow label='Name' isInvalid={!!props.errors.name} error={props.errors.name}>
                    <EuiFieldText name='name' value={props.values.name} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.name} />
                  </EuiFormRow>
                  <EuiFormRow label='Code' isInvalid={!!props.errors.code} error={props.errors.code}>
                    <EuiFieldText name='code' value={props.values.code} onChange={props.handleChange} onBlur={props.handleBlur} isInvalid={!!props.errors.code} prepend={'{'} append={'}'} />
                  </EuiFormRow>
                  <EuiSpacer />
                  <EuiButton type='submit' fill disabled={props.isSubmitting}>
                    Create
                  </EuiButton>
                </EuiForm>
              </EuiFlyoutBody>
            </EuiFlyout>
          )}
        </Formik>
      </EuiPortal>
    )
  }

  return (
    <React.Fragment>
      <EuiButton id='createAnchorPhraseFlyOut' onClick={showFlyout} fill>
        Create
      </EuiButton>
      {flyout}
    </React.Fragment>
  )
}

export default AnchorPhraseCreateFlyOut
