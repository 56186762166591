import moment, { Moment } from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiButtonEmpty, EuiDatePicker, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiHorizontalRule, EuiLink, EuiRadioGroup, EuiSelect, EuiSpacer, EuiSwitch, EuiText, EuiTextArea, EuiTextColor, EuiTitle } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { CampaignType, Organization } from 'api'
import { BundleCategory, BundleType, CampaignSlotSimple, CreateCampaignSlotRequest, IntervalUnit, TargetingPriority } from 'api/entities/Bundle'
import { IPayment } from 'api/entities/Invoice'
import { Note } from 'api/entities/Notes'
import { CreatePackageRequest } from 'api/entities/Package'
import { CreatePlanRequest } from 'api/entities/Plan'
import { CreateSelfServicePackageRequest, selfServicePackageTemplates, SelfServicePackageType } from 'api/entities/SelfServicePackage'
import { useGetCurrentUserQuery } from 'api/rtkQueryApi/graphClient/graphClientApi'
import { useGetOrganizationBundlesQuery } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { useGetCampaignsQuery } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useGetAllSalesAgentsQuery } from 'api/rtkQueryApi/opsApi/salesApi'
import { NoteAddRequest } from 'api/rtkQueryApi/requestModels/NoteModels'
import { RootState } from 'app/rootReducer'
import { AcNote, defaultNote } from 'components/ACTools/AcNote'
import { CreateCampaignSlotPanel } from 'components/Bundles/CreateCampaignSlotPanel'
import CreateCampaignSlotTypePanel from 'components/Bundles/CreateCampaignSlotTypePanel'
import IntervalUnitFrequency from 'components/IntervalUnitFrequency'
import { OrganizationPaymentMethodsRadioGroup, PaymentMode } from 'components/Invoices/OrganizationPaymentMethodsRadioGroup'
import { PaymentSplitterInline } from 'components/Invoices/PaymentSplitterInline'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { BundleCategoryPicker } from 'features/customers/bundles/BundleCategoryPicker'
import { TargetingPrioritySelect } from 'features/customers/bundles/TargetingPrioritySelect'
import { BonusBudgetLessThanBudget, MatchingSlotsAreInternet, SlotAssignmentsAreUnique, SlotBudgetEqualBudget, SlotsHaveBonusAndPrimary } from 'utils/BundleUtils'
import { insertSpaces } from 'utils/EnumToFriendly'
import { escapeRegExp } from 'utils/regexUtils'

const bundleCreateFlyoutDefaultNote = {
  ...defaultNote(),
  text: `Geo:\n\n\n\n\nSegments:\n\n\n\n\nOther:`
}

export interface BundleCreateFlyoutParams {
  organization: Organization
  bundleType: BundleType
  onCloseFlyout?: () => void
  onSaveBundle?: (bundle: CreatePlanRequest | CreatePackageRequest | CreateSelfServicePackageRequest) => void
  isLoading?: boolean
}

enum PaymentOption {
  Now = 'now',
  Prepaid = 'prepaid',
  Rollover = 'rollover',
  Split = 'split',
  StartDate = 'startDate'
}

export const BundleCreateFlyout: React.FC<BundleCreateFlyoutParams> = props => {
  const { isCSuite } = useSelector((state: RootState) => state.app)
  const salesAgentsQuery = useGetAllSalesAgentsQuery()
  const selfServicePackageMatchingPercent = props.organization.selfServicePackageMatchingPercent ?? 0.5

  const { data: bundles } = useGetOrganizationBundlesQuery(props.organization.organizationId)
  const bundleTypeName = useMemo(() => insertSpaces(props.bundleType), [props.bundleType])
  const organizationCampaignsQuery = useGetCampaignsQuery({ organizationId: props.organization.organizationId })

  const defaultBundleName = useMemo(() => {
    let name = props.bundleType === BundleType.SelfServicePackage ? 'Un-named Package' : props.organization.organizationName + ' : ' + moment().format('LL')
    const safeName = escapeRegExp(name)
    const regex = new RegExp(`^(${safeName}|${safeName} \\(\\d+\\))$`)
    const existing = bundles?.filter(bundle => regex.test(bundle.name))

    if (existing && existing.length > 0) {
      name += ` (${existing.length})`
    }

    return name
  }, [bundles, props.bundleType])

  const [foreverBundle, setForeverBundle] = useState(true)
  const [intervalUnitFrequency, setIntervalUnitFrequency] = useState(1)
  const [totalIntervals, setTotalIntervals] = useState(12)
  const [intervalUnit, setIntervalUnit] = useState(IntervalUnit.Month)
  const [includeSubscription, setIncludeSubscription] = useState(false)
  const [subscriptionIncentiveDuration, setSubscriptionIncentiveDuration] = useState(12)
  const [includeMatching, setIncludeMatching] = useState(true)
  const [matchingIncentiveDuration, setMatchingIncentiveDuration] = useState(12)
  const [paidSlots, setPaidSlots] = useState<CampaignSlotSimple[]>(props.bundleType === BundleType.SelfServicePackage ? [] : [{ id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: 1000 }])
  const [bonusSlots, setBonusSlots] = useState<CampaignSlotSimple[]>(props.bundleType === BundleType.SelfServicePackage ? [] : [{ id: uuidv4(), campaignType: CampaignType.Internet, isBonus: true, budget: 1000 }])
  const [noteAddRequest, setNoteAddRequest] = useState<NoteAddRequest>()
  const [bundleName, setBundleName] = useState<string>(defaultBundleName)
  const isMounted = useRef(false)
  const [selectedPaymentOptionId, setSelectedPaymentOptionId] = useState<PaymentOption>(PaymentOption.Now)
  const [payments, setPayments] = useState<IPayment[]>([])
  const [paymentSplitterValid, setPaymentSplitterValid] = useState<boolean>(false)
  const [confirmCreateOpen, setConfirmCreateOpen] = useState<boolean>(false)
  const [paymentMethodId, setPaymentMethodId] = useState<string>()
  const [isWatchListed, setIsWatchListed] = useState(false)
  const [packageCategory, setBundleCategory] = useState<BundleCategory>(props.bundleType == BundleType.SelfServicePackage ? BundleCategory.SelfService : BundleCategory.Regular)
  const [targetingPriority, setTargetingPriority] = useState<TargetingPriority>(TargetingPriority.Location)
  const [startDate, setStartDate] = useState<Moment>(moment())
  const [nextCampaignFlightDate, setNextCampaignFlightDate] = useState<Moment>()
  const [campaignSlots, setCampaignSlots] = useState<CampaignSlotSimple[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState('None')

  const [selectedSelfServicePackageOptionId, setSelectedSelfServicePackageOptionId] = useState<string>(SelfServicePackageType.Percent)
  const [salesTitle, setSalesTitle] = useState<string | undefined>()
  const [salesDescription, setSalesDescription] = useState<string | undefined>()
  const [matchingPercentage, setMatchingPercentage] = useState(props.bundleType === BundleType.SelfServicePackage ? selfServicePackageMatchingPercent * 100 : undefined)
  const [minimumAmount, setMinimumAmount] = useState(props.bundleType === BundleType.SelfServicePackage ? 1000 : undefined)
  const [maximumAmount, setMaximumAmount] = useState(props.bundleType === BundleType.SelfServicePackage ? 10000 : undefined)
  const [paidSlotCampaignTypes, setPaidSlotCampaignTypes] = useState<{ id: string; campaignType: CampaignType }[]>([{ id: uuidv4(), campaignType: CampaignType.TV }])
  const [matchingSlotCampaignTypes, setMatchingSlotCampaignTypes] = useState<{ id: string; campaignType: CampaignType }[]>([{ id: uuidv4(), campaignType: CampaignType.Internet }])
  const [expirationDate, setExpirationDate] = useState(props.bundleType === BundleType.SelfServicePackage ? moment('2023/11/30') : undefined)
  const [selectedSalesAgentId, setSelectedSalesAgentId] = useState<string>('')
  const [salesAgentOptions, setSalesAgentOptions] = useState<EuiSelectOption[]>([{ value: '', text: 'Unassigned' }])
  const meQuery = useGetCurrentUserQuery()

  const defaultMonthlyBudget = 1000

  useEffect(() => {
    if (salesAgentsQuery.data) {
      const options = [
        { value: '', text: 'Unassigned' },
        ...salesAgentsQuery.data.map(agent => {
          return {
            value: agent.id,
            text: agent.name
          }
        })
      ]

      setSalesAgentOptions(options)
      if (meQuery.data) {
        const salesAgent = salesAgentsQuery.data?.find(agent => agent.email === meQuery.data?.mail)
        if (salesAgent) {
          setSelectedSalesAgentId(salesAgent.id)
        }
      }
    }
  }, [salesAgentsQuery.data, meQuery.data])

  useEffect(() => {
    setCampaignSlots([...paidSlots, ...bonusSlots])

    if (organizationCampaignsQuery.data) {
      const campaignIds = [...paidSlots, ...bonusSlots].map(slot => slot.assignedCampaignId)
      if (campaignIds.length > 0) {
        const foundCampaigns = organizationCampaignsQuery.data.filter(campaign => campaignIds.includes(campaign.campaignId))
        const eventDates = foundCampaigns.filter(c => c.flightSchedule?.nextEventDate).map(c => c.flightSchedule.nextEventDate)
        if (eventDates.length > 0) {
          const nextBillDate = eventDates.reduce((prev, curr) => {
            return prev! < curr! ? prev : curr
          })
          setNextCampaignFlightDate(moment(nextBillDate))
        } else {
          setNextCampaignFlightDate(undefined)
        }
      } else {
        setNextCampaignFlightDate(undefined)
      }
    }
  }, [paidSlots, bonusSlots, organizationCampaignsQuery.data])

  useEffect(() => {
    const template = selfServicePackageTemplates.find(template => template.salesTitle === selectedTemplate)

    if (!template) {
      return
    }

    setSalesTitle(template.salesTitle)
    setSalesDescription(template.salesDescription)
    setMatchingPercentage(template.matchingPercentage ? template.matchingPercentage * 100 : undefined)
    setPaidSlotCampaignTypes(template.paidSlotCampaignTypes?.map(campaignType => ({ id: uuidv4(), campaignType })) ?? [])
    setMatchingSlotCampaignTypes(template.matchingSlotCampaignTypes?.map(campaignType => ({ id: uuidv4(), campaignType })) ?? [])
  }, [selectedTemplate])

  const totalBudget = useMemo(() => {
    return campaignSlots.reduce((partialSum, a) => {
      if (a.isBonus) {
        return partialSum
      }

      return partialSum + a.budget
    }, 0)
  }, [campaignSlots])

  const convertToMonthlyDuration = (weeklyDuration: number) => {
    let converted = Math.round(weeklyDuration / (52 / 12))

    if (converted < 1) {
      converted = 1
    }

    return converted
  }

  const convertToWeeklyDuration = (monthlyDuration: number) => {
    let converted = Math.round(monthlyDuration * (52 / 12))

    if (converted < 1) {
      converted = 1
    }

    return converted
  }

  const closest50 = (num: number) => {
    return Math.round(num / 50) * 50
  }

  const convertToMonthlyBudget = (weeklyBudget: number) => {
    return closest50((weeklyBudget * 52) / 12)
  }

  const convertToWeeklyBudget = (monthlyBudget: number) => {
    return closest50((monthlyBudget * 12) / 52)
  }

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      return
    }

    if (intervalUnit === IntervalUnit.Month) {
      setTotalIntervals(convertToMonthlyDuration(totalIntervals))
      setSubscriptionIncentiveDuration(12)
      setMatchingIncentiveDuration(12)

      setPaidSlots(paidSlots.map(slot => ({ ...slot, budget: convertToMonthlyBudget(slot.budget) })))
      setBonusSlots(bonusSlots.map(slot => ({ ...slot, budget: convertToMonthlyBudget(slot.budget) })))
    } else if (intervalUnit === IntervalUnit.Week) {
      setTotalIntervals(convertToWeeklyDuration(totalIntervals))
      setSubscriptionIncentiveDuration(52)
      setMatchingIncentiveDuration(52)

      setPaidSlots(paidSlots.map(slot => ({ ...slot, budget: convertToWeeklyBudget(slot.budget) })))
      setBonusSlots(bonusSlots.map(slot => ({ ...slot, budget: convertToWeeklyBudget(slot.budget) })))
    }
  }, [intervalUnit])

  const onSalesTitleChange = (value: string) => {
    setSalesTitle(value)
  }

  const onSalesDescriptionChange = (value: string) => {
    setSalesDescription(value)
  }

  const onNameChange = (value: string) => {
    setBundleName(value)
  }

  const onSelfServicePackageTypeChange = (value: string) => {
    const selfServicePackageType = value as SelfServicePackageType

    if (selfServicePackageType === SelfServicePackageType.Specific) {
      const bonusBudget = props.bundleType === BundleType.SelfServicePackage ? Math.round(defaultMonthlyBudget * selfServicePackageMatchingPercent) : defaultMonthlyBudget

      setPaidSlots([{ id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: defaultMonthlyBudget }])
      setBonusSlots([{ id: uuidv4(), campaignType: CampaignType.Internet, isBonus: true, budget: bonusBudget }])

      setPaidSlotCampaignTypes([])
      setMatchingSlotCampaignTypes([])
    }

    if (selfServicePackageType === SelfServicePackageType.Percent) {
      setPaidSlotCampaignTypes([{ id: uuidv4(), campaignType: CampaignType.TV }])
      setMatchingSlotCampaignTypes([{ id: uuidv4(), campaignType: CampaignType.Internet }])

      setPaidSlots([])
      setBonusSlots([])
    }

    setSelectedSelfServicePackageOptionId(value as SelfServicePackageType)
  }

  const onSubscriptionIncentiveDurationChange = (value: number) => {
    if (value > 0) {
      setSubscriptionIncentiveDuration(value)
    }
  }

  const onMatchingIncentiveDurationChange = (value: number) => {
    if (value > 0) {
      setMatchingIncentiveDuration(value)
    }
  }

  const onCloseClicked = () => {
    if (props.onCloseFlyout) {
      props.onCloseFlyout()
    }
  }

  const onForeverBundleChange = () => {
    setForeverBundle(!foreverBundle)
  }

  const onCreateClicked = () => {
    setConfirmCreateOpen(true)
  }

  const onConfirmCreate = () => {
    if (props.onSaveBundle) {
      props.onSaveBundle({
        name: bundleName,
        organizationId: props.organization.organizationId,
        bundleType: props.bundleType,
        price: totalBudget,
        organizationManagerId: props.organization.organizationManagerId,
        campaignAdvisorId: props.organization.campaignAdvisorId,
        isPrepaid: selectedPaymentOptionId === PaymentOption.Prepaid,
        isRollover: selectedPaymentOptionId === PaymentOption.Rollover,
        isIndefinite: foreverBundle,
        intervalUnitFrequency: intervalUnitFrequency,
        intervalUnit: intervalUnit,
        totalIntervals: totalIntervals,
        includeSubscription: includeSubscription,
        subscriptionIncentiveDuration: subscriptionIncentiveDuration,
        isSubscriptionIncentiveIndefinite: false,
        includeMatching: includeMatching,
        matchingIncentiveDuration: matchingIncentiveDuration,
        isMatchingIncentiveIndefinite: false,
        campaignSlots: campaignSlots
          .filter(slot => includeMatching || (!includeMatching && !slot.isBonus))
          .map<CreateCampaignSlotRequest>(slot => ({
            campaignType: slot.campaignType,
            budget: slot.budget,
            isBonus: !!slot.isBonus,
            assignedCampaignId: slot.assignedCampaignId
          })),
        noteAddRequest: noteAddRequest
          ? {
              name: noteAddRequest.name,
              secondaryReferenceIds: noteAddRequest.secondaryReferenceIds,
              text: noteAddRequest.text,
              followUp: noteAddRequest.followUp,
              followUpDate: noteAddRequest.followUpDate
            }
          : undefined,
        paymentSplitRequests: selectedPaymentOptionId === PaymentOption.Split ? payments : [],
        paymentMethodId: paymentMethodId,
        bundleCategory: packageCategory,
        targetingPriority: targetingPriority,
        isWatchListed: isWatchListed,
        startDate: selectedPaymentOptionId === PaymentOption.StartDate ? startDate : undefined,
        selfServicePackageType: selectedSelfServicePackageOptionId,
        matchingPercentage: matchingPercentage ? matchingPercentage / 100 : undefined,
        salesTitle: salesTitle,
        salesDescription: salesDescription,
        minimumAmount: minimumAmount,
        maximumAmount: maximumAmount,
        paidSlotCampaignTypes: paidSlotCampaignTypes.map(slot => slot.campaignType),
        matchingSlotCampaignTypes: matchingSlotCampaignTypes.map(slot => slot.campaignType),
        expirationDate: props.bundleType === BundleType.SelfServicePackage ? expirationDate : undefined,
        salesAgentId: selectedSalesAgentId
      } as CreatePlanRequest | CreatePackageRequest | CreateSelfServicePackageRequest)
    }
  }

  const onAddPaidSlotClicked = () => {
    if (props.bundleType === BundleType.SelfServicePackage && selectedSelfServicePackageOptionId !== SelfServicePackageType.Specific) {
      setPaidSlotCampaignTypes([...paidSlotCampaignTypes, { id: uuidv4(), campaignType: CampaignType.TV }])
    } else {
      if (intervalUnit === IntervalUnit.Month) {
        setPaidSlots([...paidSlots, { id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: defaultMonthlyBudget }])
      } else if (intervalUnit === IntervalUnit.Week) {
        setPaidSlots([...paidSlots, { id: uuidv4(), campaignType: CampaignType.TV, isBonus: false, budget: (defaultMonthlyBudget * 12) / 52 }])
      }
    }
  }

  const onAddBonusSlotClicked = () => {
    if (props.bundleType === BundleType.SelfServicePackage && selectedSelfServicePackageOptionId !== SelfServicePackageType.Specific) {
      setMatchingSlotCampaignTypes([...matchingSlotCampaignTypes, { id: uuidv4(), campaignType: CampaignType.Internet }])
    } else {
      if (intervalUnit === IntervalUnit.Month) {
        setBonusSlots([
          ...bonusSlots,
          {
            id: uuidv4(),
            campaignType: CampaignType.Internet,
            isBonus: true,
            budget: defaultMonthlyBudget
          }
        ])
      } else if (intervalUnit === IntervalUnit.Week) {
        setBonusSlots([
          ...bonusSlots,
          {
            id: uuidv4(),
            campaignType: CampaignType.Internet,
            isBonus: true,
            budget: (defaultMonthlyBudget * 12) / 52
          }
        ])
      }
    }
  }

  const onPaidSlotUpdate = (slot: CampaignSlotSimple) => {
    const stateSlot = paidSlots.find(s => s.id === slot.id)

    if (stateSlot) {
      stateSlot.campaignType = slot.campaignType
      stateSlot.budget = slot.budget
      stateSlot.assignedCampaignId = slot.assignedCampaignId

      setPaidSlots([...paidSlots])
    }
  }

  const onBonusSlotUpdate = (slot: CampaignSlotSimple) => {
    const stateSlot = bonusSlots.find(s => s.id === slot.id)

    if (stateSlot) {
      stateSlot.campaignType = slot.campaignType
      stateSlot.budget = slot.budget
      stateSlot.assignedCampaignId = slot.assignedCampaignId

      setBonusSlots([...bonusSlots])
    }
  }

  const onRemovePaidSlotClicked = (slotId: string) => {
    setPaidSlots(paidSlots.filter(s => s.id !== slotId))
  }

  const onRemoveBonusSlotClicked = (slotId: string) => {
    setBonusSlots(bonusSlots.filter(s => s.id !== slotId))
  }

  const userIsValid = () => {
    return (!includeMatching || BonusBudgetLessThanBudget(campaignSlots, totalBudget)) && MatchingSlotsAreInternet(campaignSlots) && SlotBudgetEqualBudget(campaignSlots, totalBudget) && SlotsHaveBonusAndPrimary(campaignSlots) && SlotAssignmentsAreUnique(campaignSlots) && (selectedPaymentOptionId !== PaymentOption.Split || paymentSplitterValid) && (selectedPaymentOptionId !== PaymentOption.Rollover || campaignSlots.some(s => s.assignedCampaignId)) && paymentMethodId !== '-1'
  }

  const cSuiteIsValid = () => {
    return (!includeMatching || BonusBudgetLessThanBudget(campaignSlots, totalBudget)) && SlotBudgetEqualBudget(campaignSlots, totalBudget) && SlotAssignmentsAreUnique(campaignSlots) && (selectedPaymentOptionId !== PaymentOption.Split || paymentSplitterValid) && (selectedPaymentOptionId !== PaymentOption.Rollover || campaignSlots.some(s => s.assignedCampaignId)) && paymentMethodId !== '-1'
  }

  const bundleIsValid = isCSuite ? cSuiteIsValid : userIsValid

  const onFirstNoteChange = (note: Note) => {
    setNoteAddRequest({
      organizationId: note.organizationId!,
      name: note.name,
      secondaryReferenceIds: note.secondaryReferenceIds,
      text: note.text,
      followUp: note.followUp,
      followUpDate: note.followUpDate
    })
  }

  const selfServicePackageTypeOptionRadios = Object.keys(SelfServicePackageType).map(key => {
    return {
      id: key,
      label: insertSpaces(key)
    }
  })

  const paymentOptionRadios = [
    {
      id: PaymentOption.Now,
      label: 'Pay Now'
    },
    {
      id: PaymentOption.StartDate,
      label: 'Start Date'
    },
    {
      id: PaymentOption.Rollover,
      label: 'Rollover'
    },
    {
      id: PaymentOption.Prepaid,
      label: 'Prepaid'
    },
    {
      id: PaymentOption.Split,
      label: 'Split Payment'
    }
  ]

  const paymentOptionHelpText = (id: PaymentOption) => {
    switch (id) {
      case PaymentOption.Now:
        return `Pay for and Activate the ${bundleTypeName} now`
      case PaymentOption.Rollover:
        return `Pay for and Activate the ${bundleTypeName} at the start of its next campaign billing cycle`
      case PaymentOption.Prepaid:
        return `Activate the ${bundleTypeName} without paying, the ${bundleTypeName} will still charge on future intervals`
      case PaymentOption.Split:
        return `Split the ${bundleTypeName} activation into multiple smaller payments`
      case PaymentOption.StartDate:
        return `Pay for and Activate the ${bundleTypeName} on a specific date`
    }
  }

  const onPaymentsChanged = (payments: IPayment[]) => {
    setPayments(payments)
  }

  const onPaymentSplitterValidChanged = (valid: boolean) => {
    setPaymentSplitterValid(valid)
  }

  const templateOptions: EuiSelectOption[] = [
    { value: 'None', text: 'None' },
    ...selfServicePackageTemplates.map(template => {
      return {
        value: template.salesTitle,
        text: template.salesTitle
      }
    })
  ]

  return (
    <EuiFlyout onClose={onCloseClicked} size='l' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={false}>
      <EuiFlyoutHeader>
        <EuiTitle size='s'>
          <h2>Create {bundleTypeName}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexGroup gutterSize='xl' wrap>
          <EuiFlexItem style={{ minWidth: '500px' }}>
            <EuiForm fullWidth>
              {!bundleIsValid() && (
                <EuiFormRow fullWidth>
                  <React.Fragment>
                    {includeMatching && !BonusBudgetLessThanBudget(campaignSlots, totalBudget) && (
                      <p>
                        <EuiTextColor color='warning'>Bonus budget must be less than {bundleTypeName}'s total budget</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}
                    {!SlotBudgetEqualBudget(campaignSlots, totalBudget) && (
                      <p>
                        <EuiTextColor color='warning'>Paid campaign slot budget must equal the {bundleTypeName}'s total budget</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}
                    {includeMatching && !campaignSlots.find(s => s.isBonus) && (
                      <p>
                        <EuiTextColor color='warning'>Missing Bonus Campaign Slot</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}
                    {!campaignSlots.find(s => !s.isBonus) && (
                      <p>
                        <EuiTextColor color='warning'>Missing Paid Campaign Slot</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}
                    {!SlotAssignmentsAreUnique(campaignSlots) && (
                      <p>
                        <EuiTextColor color='warning'>Campaigns cannot be assigned to multiple Campaign Slots</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}

                    {selectedPaymentOptionId === PaymentOption.Split && !paymentSplitterValid && (
                      <p>
                        <EuiTextColor color='warning'>Invalid Payment Split</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}
                    {selectedPaymentOptionId === PaymentOption.Rollover && !campaignSlots.some(s => s.assignedCampaignId) && (
                      <p>
                        <EuiTextColor color='warning'>Missing Rollover Campaign Assignment</EuiTextColor>
                        <EuiSpacer size={'s'} />
                      </p>
                    )}
                  </React.Fragment>
                </EuiFormRow>
              )}
              {props.bundleType === BundleType.SelfServicePackage && selectedSelfServicePackageOptionId === SelfServicePackageType.Percent && (
                <EuiFormRow label='Use Template'>
                  <EuiSelect
                    options={templateOptions}
                    value={selectedTemplate}
                    onChange={e => {
                      setSelectedTemplate(e.target.value)
                    }}
                  />
                </EuiFormRow>
              )}
              {props.bundleType === BundleType.Plan && (
                <EuiFormRow label='Account Manager'>
                  <EuiSelect
                    options={salesAgentOptions}
                    value={selectedSalesAgentId}
                    onChange={e => {
                      setSelectedSalesAgentId(e.target.value)
                    }}
                  />
                </EuiFormRow>
              )}
              <EuiFormRow label={`${bundleTypeName} Name`} fullWidth>
                <EuiFieldText
                  value={bundleName}
                  onChange={e => {
                    onNameChange(e.target.value)
                  }}
                />
              </EuiFormRow>
              <EuiFormRow fullWidth>
                <EuiSwitch label={'Watchlisted'} checked={isWatchListed} onChange={() => setIsWatchListed(!isWatchListed)} id={'isWatchListed'} />
              </EuiFormRow>
              {props.bundleType === BundleType.SelfServicePackage && (
                <React.Fragment>
                  <EuiFormRow label='Self Service Package Type' fullWidth>
                    <EuiRadioGroup options={selfServicePackageTypeOptionRadios} idSelected={selectedSelfServicePackageOptionId} onChange={onSelfServicePackageTypeChange} name='type radio group' />
                  </EuiFormRow>
                  <EuiFormRow label='Title' fullWidth>
                    <EuiFieldText
                      value={salesTitle}
                      onChange={e => {
                        onSalesTitleChange(e.target.value)
                      }}
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Description' fullWidth>
                    <EuiTextArea
                      value={salesDescription}
                      onChange={e => {
                        onSalesDescriptionChange(e.target.value)
                      }}
                    />
                  </EuiFormRow>
                  {selectedSelfServicePackageOptionId === SelfServicePackageType.Percent && (
                    <React.Fragment>
                      <EuiFormRow label='Minimum Amount' fullWidth>
                        <EuiFieldNumber
                          prepend={
                            <EuiText size='xs'>
                              <strong>$</strong>
                            </EuiText>
                          }
                          placeholder={'Minimum Amount'}
                          value={minimumAmount}
                          onChange={e => setMinimumAmount(parseInt(e.target.value))}
                        />
                      </EuiFormRow>
                      <EuiFormRow label='Maximum Amount' fullWidth>
                        <EuiFieldNumber
                          prepend={
                            <EuiText size='xs'>
                              <strong>$</strong>
                            </EuiText>
                          }
                          placeholder={'Maximum Amount'}
                          value={maximumAmount}
                          onChange={e => setMaximumAmount(parseInt(e.target.value))}
                        />
                      </EuiFormRow>
                      <EuiFormRow label='Matching Percentage' fullWidth>
                        <EuiFieldNumber
                          prepend={
                            <EuiText size='xs'>
                              <strong>%</strong>
                            </EuiText>
                          }
                          placeholder={'Matching Percentage'}
                          value={matchingPercentage}
                          onChange={e => setMatchingPercentage(parseInt(e.target.value))}
                        />
                      </EuiFormRow>
                    </React.Fragment>
                  )}
                  <EuiFormRow fullWidth label='Expiration Date'>
                    <EuiDatePicker fullWidth selected={expirationDate} onChange={date => setExpirationDate(date ?? undefined)} />
                  </EuiFormRow>
                </React.Fragment>
              )}
              <EuiFormRow fullWidth label='Category'>
                <BundleCategoryPicker
                  bundleType={props.bundleType}
                  onBadgeClick={key => {
                    setBundleCategory(key)
                  }}
                  selectedKey={packageCategory}
                />
              </EuiFormRow>
              <EuiFormRow fullWidth label='Targeting'>
                <TargetingPrioritySelect
                  onChange={key => {
                    setTargetingPriority(key)
                  }}
                  selectedKey={targetingPriority}
                />
              </EuiFormRow>
              {(props.bundleType !== BundleType.SelfServicePackage || selectedSelfServicePackageOptionId === SelfServicePackageType.Specific) && (
                <EuiFormRow label='Price' fullWidth>
                  <EuiFieldNumber
                    prepend={
                      <EuiText size='xs'>
                        <strong>$</strong>
                      </EuiText>
                    }
                    placeholder={'Price'}
                    value={totalBudget}
                    disabled={true}
                  />
                </EuiFormRow>
              )}
              <EuiSpacer />
              <EuiTitle size={'xs'}>
                <h2>Billing Information</h2>
              </EuiTitle>
              <EuiHorizontalRule margin={'s'} />
              {props.bundleType !== BundleType.SelfServicePackage && (
                <React.Fragment>
                  <EuiFormRow label='Payment Options' fullWidth helpText={paymentOptionHelpText(selectedPaymentOptionId)}>
                    <EuiRadioGroup
                      options={paymentOptionRadios}
                      idSelected={selectedPaymentOptionId}
                      onChange={id => {
                        setSelectedPaymentOptionId(id as PaymentOption)
                      }}
                      name='radio group'
                    />
                  </EuiFormRow>
                  {selectedPaymentOptionId === PaymentOption.Split && (
                    <EuiFormRow fullWidth>
                      <PaymentSplitterInline amountDue={totalBudget} maxDate={moment().add(1, 'week')} onValidChanged={onPaymentSplitterValidChanged} onPaymentsChanged={onPaymentsChanged} />
                    </EuiFormRow>
                  )}
                  {selectedPaymentOptionId === PaymentOption.StartDate && (
                    <EuiFormRow label='Start Date' fullWidth>
                      <React.Fragment>
                        <EuiDatePicker selected={startDate} onChange={date => setStartDate(moment(date))} minDate={moment()} />
                        <EuiSpacer size={'s'} />
                        {nextCampaignFlightDate && (
                          <EuiText size={'xs'} color={'subdued'}>
                            Next cycle starts{' '}
                            <EuiLink
                              onClick={() => {
                                setStartDate(moment(nextCampaignFlightDate))
                              }}>
                              {nextCampaignFlightDate.format('MM/DD/yyyy')}
                            </EuiLink>
                          </EuiText>
                        )}
                      </React.Fragment>
                    </EuiFormRow>
                  )}
                </React.Fragment>
              )}
              {props.bundleType !== BundleType.SelfServicePackage && (
                <EuiFormRow fullWidth>
                  <EuiSwitch label={`Forever ${bundleTypeName}`} onChange={onForeverBundleChange} checked={foreverBundle} id='foreverSwitch' />
                </EuiFormRow>
              )}
              <EuiFormRow label='Billing Cycle'>
                <IntervalUnitFrequency price={totalBudget} intervalUnitFrequency={intervalUnitFrequency} onIntervalUnitFrequencyChange={e => setIntervalUnitFrequency(parseInt(e.target.value))} intervalUnit={intervalUnit} onIntervalUnitChange={e => setIntervalUnit(e.target.value as IntervalUnit)} totalIntervals={totalIntervals} onTotalIntervalsChange={e => setTotalIntervals(parseInt(e.target.value))} foreverBundle={foreverBundle} />
              </EuiFormRow>
              {props.bundleType !== BundleType.SelfServicePackage && (
                <React.Fragment>
                  <EuiFormRow label={'Payment'} fullWidth>
                    <OrganizationPaymentMethodsRadioGroup
                      organizationId={props.organization.organizationId}
                      onSelectedPaymentMethodIdChange={id => {
                        setPaymentMethodId(id)
                      }}
                      disabledModes={selectedPaymentOptionId === PaymentOption.Rollover ? [PaymentMode.Select, PaymentMode.New] : []}
                    />
                  </EuiFormRow>
                </React.Fragment>
              )}
              <EuiSpacer size={'s'} />
              <EuiTitle size={'xs'}>
                <h2>Incentives</h2>
              </EuiTitle>
              <EuiHorizontalRule margin={'s'} />
              <EuiFormRow fullWidth helpText={includeSubscription ? `Subscription price is included with the ${bundleTypeName}.` : undefined}>
                <EuiSwitch label={'Include Subscription'} checked={includeSubscription} onChange={() => setIncludeSubscription(!includeSubscription)} id={'includeSubscription'} />
              </EuiFormRow>
              <EuiFormRow
                label='Subscription Incentive Duration'
                style={
                  includeSubscription
                    ? undefined
                    : {
                        display: 'none'
                      }
                }>
                <EuiFieldNumber
                  placeholder={'Subscription Incentive Duration'}
                  value={subscriptionIncentiveDuration}
                  step={1}
                  onChange={e => {
                    onSubscriptionIncentiveDurationChange(parseInt(e.target.value))
                  }}
                  append={subscriptionIncentiveDuration > 1 ? intervalUnit + 's' : intervalUnit}
                />
              </EuiFormRow>

              <EuiFormRow fullWidth>
                <EuiSwitch label={'Include Matching'} checked={includeMatching} onChange={() => setIncludeMatching(!includeMatching)} id={'includeMatching'} />
              </EuiFormRow>

              <EuiFormRow
                label='Matching Incentive Duration'
                style={
                  includeMatching
                    ? undefined
                    : {
                        display: 'none'
                      }
                }>
                <EuiFieldNumber
                  placeholder={'Matching Incentive Duration'}
                  value={matchingIncentiveDuration}
                  step={1}
                  onChange={e => {
                    onMatchingIncentiveDurationChange(parseInt(e.target.value))
                  }}
                  append={matchingIncentiveDuration > 1 ? intervalUnit + 's' : intervalUnit}
                />
              </EuiFormRow>

              <EuiSpacer />

              <EuiTitle size={'xs'}>
                <h2>Paid Campaign Slots</h2>
              </EuiTitle>

              <EuiSpacer size='xs' />

              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty iconType={'plus'} onClick={onAddPaidSlotClicked}>
                    Add Paid Slot
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem />
              </EuiFlexGroup>

              <EuiSpacer size='xs' />

              {props.bundleType === BundleType.SelfServicePackage &&
                selectedSelfServicePackageOptionId !== SelfServicePackageType.Specific &&
                paidSlotCampaignTypes?.map(({ id, campaignType }) => {
                  return (
                    <React.Fragment key={id}>
                      <CreateCampaignSlotTypePanel
                        campaignType={campaignType}
                        isBonusSlot={false}
                        onCampaignTypeUpdate={campaignType => {
                          setPaidSlotCampaignTypes(
                            paidSlotCampaignTypes?.map(slot => {
                              if (slot.id === id) {
                                slot.campaignType = campaignType
                              }
                              return slot
                            })
                          )
                        }}
                        onCancelClick={() => setPaidSlotCampaignTypes(paidSlotCampaignTypes.filter(slot => slot.id !== id))}
                      />

                      <EuiSpacer />
                    </React.Fragment>
                  )
                })}

              {(props.bundleType !== BundleType.SelfServicePackage || selectedSelfServicePackageOptionId === SelfServicePackageType.Specific) &&
                paidSlots.map(slot => (
                  <React.Fragment key={slot.id}>
                    <CreateCampaignSlotPanel
                      bundleType={props.bundleType}
                      slot={slot}
                      isBonusSlot={false}
                      onSlotUpdate={onPaidSlotUpdate}
                      onCancelClick={() => {
                        onRemovePaidSlotClicked(slot.id)
                      }}
                      organizationId={props.organization.organizationId}
                    />
                    <EuiSpacer />
                  </React.Fragment>
                ))}

              <EuiSpacer />

              {includeMatching && (
                <React.Fragment>
                  <EuiTitle size={'xs'}>
                    <h2>Matching Campaign Slots</h2>
                  </EuiTitle>

                  <EuiSpacer size='xs' />

                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiButtonEmpty iconType={'plus'} onClick={onAddBonusSlotClicked}>
                        Add Matching Slot
                      </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem />
                  </EuiFlexGroup>

                  <EuiSpacer size='xs' />

                  {props.bundleType === BundleType.SelfServicePackage &&
                    selectedSelfServicePackageOptionId !== SelfServicePackageType.Specific &&
                    matchingSlotCampaignTypes?.map(({ id, campaignType }) => {
                      return (
                        <React.Fragment key={id}>
                          <CreateCampaignSlotTypePanel
                            campaignType={campaignType}
                            isBonusSlot={true}
                            onCampaignTypeUpdate={campaignType => {
                              setMatchingSlotCampaignTypes(
                                matchingSlotCampaignTypes?.map(slot => {
                                  if (slot.id === id) {
                                    slot.campaignType = campaignType
                                  }
                                  return slot
                                })
                              )
                            }}
                            onCancelClick={() => setMatchingSlotCampaignTypes(matchingSlotCampaignTypes.filter(slot => slot.id !== id))}
                          />

                          <EuiSpacer />
                        </React.Fragment>
                      )
                    })}

                  {(props.bundleType !== BundleType.SelfServicePackage || selectedSelfServicePackageOptionId === SelfServicePackageType.Specific) &&
                    bonusSlots.map(slot => (
                      <React.Fragment key={slot.id}>
                        <CreateCampaignSlotPanel
                          bundleType={props.bundleType}
                          slot={slot}
                          isBonusSlot={!isCSuite}
                          onSlotUpdate={onBonusSlotUpdate}
                          onCancelClick={() => {
                            onRemoveBonusSlotClicked(slot.id)
                          }}
                          organizationId={props.organization.organizationId}
                        />
                        <EuiSpacer />
                      </React.Fragment>
                    ))}
                </React.Fragment>
              )}
            </EuiForm>
          </EuiFlexItem>
          {props.bundleType !== BundleType.SelfServicePackage && (
            <React.Fragment>
              <EuiFlexItem style={{ minWidth: '500px' }} grow={2}>
                <EuiFlexGroup direction='column'>
                  <EuiFlexItem grow={false}>
                    <AcNote editable={true} hideSave={true} hideDelete={true} onNoteChange={onFirstNoteChange} note={bundleCreateFlyoutDefaultNote} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </React.Fragment>
          )}
        </EuiFlexGroup>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton id='close' onClick={onCloseClicked} color='danger' isLoading={props.isLoading}>
              Cancel
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={onCreateClicked} disabled={!bundleIsValid()} isLoading={props.isLoading}>
              Create
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>

      {confirmCreateOpen && props.bundleType === BundleType.SelfServicePackage && selectedSelfServicePackageOptionId === SelfServicePackageType.Percent && (
        <AcConfirmModal
          title={`Confirm ${bundleTypeName} Creation`}
          confirmButtonDisabled={props.isLoading}
          confirmButtonText={'Create'}
          onConfirm={onConfirmCreate}
          message={`Are you sure you want to create a ${matchingPercentage}% matching ${bundleTypeName}?`}
          onCancel={() => {
            setConfirmCreateOpen(false)
          }}
        />
      )}

      {confirmCreateOpen && (props.bundleType !== BundleType.SelfServicePackage || selectedSelfServicePackageOptionId === SelfServicePackageType.Specific) && (
        <AcConfirmModal
          title={isCSuite && !userIsValid() && bundleIsValid() ? `Confirm ${bundleTypeName} Creation (This is not normally valid)` : `Confirm ${bundleTypeName} Creation`}
          confirmButtonDisabled={props.isLoading}
          confirmButtonText={'Create'}
          onConfirm={onConfirmCreate}
          message={`Are you sure you want to create a $${totalBudget} every ${intervalUnitFrequency} ${intervalUnit}(s) ${bundleTypeName}?`}
          onCancel={() => {
            setConfirmCreateOpen(false)
          }}
        />
      )}
    </EuiFlyout>
  )
}
