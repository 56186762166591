import React from 'react'

import { EuiBadge } from '@elastic/eui'

export const ProblemBadge: React.FC<{ problem: string }> = ({ problem }) => {
  return (
    <React.Fragment>
      {problem === 'Empty Slot' && <EuiBadge color='text'>Empty Slot</EuiBadge>}
      {problem === 'Slow Pacing' && <EuiBadge color='warning'>Slow Pacing</EuiBadge>}
      {problem === 'Not Delivering' && <EuiBadge color='danger'>Not Delivering</EuiBadge>}
      {problem === 'Segment Mismatched' && <EuiBadge color='primary'>Segment Mismatched</EuiBadge>}
      {problem === 'No Traffic Today' && <EuiBadge color='primary'>No Traffic Today</EuiBadge>}
      {problem === 'Bidder Error' && <EuiBadge color='danger'>Bidder Error</EuiBadge>}
    </React.Fragment>
  )
}
