import moment from 'moment-timezone'
import React, { FC, useEffect, useState } from 'react'

import { EuiSelect } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'
import { EuiSelectProps } from '@elastic/eui/src/components/form/select/select'

import { useGetTimeZonesQuery } from 'api/rtkQueryApi/opsApi/contactsApi'

export const TimeZoneSelector: FC<EuiSelectProps> = props => {
  const { isLoading: isTimeZonesLoading, data: timeZones } = useGetTimeZonesQuery()
  const [tzOptions, setTzOptions] = useState<EuiSelectOption[]>([])

  useEffect(() => {
    if (isTimeZonesLoading || !timeZones) {
      return
    }

    setTzOptions(
      timeZones
        .map(tz => ({
          value: tz,
          text: `(GMT${moment.tz(tz).format('Z')}) ${tz}`
        }))
        .sort((a, b) => {
          const aOffset = moment.tz(a.value).format('Z')
          const bOffset = moment.tz(b.value).format('Z')
          return aOffset > bOffset ? 1 : -1
        })
    )
  }, [isTimeZonesLoading, timeZones])

  return <EuiSelect {...props} options={tzOptions} />
}
