import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui'

import { ColorPalette } from 'components/AdCreation/DisplayMaker'
import { CreateColorPalette } from 'utils/colorUtils'

export interface IAcColorPalettePicker {
  colors: string[]
  onColorPaletteChange: (colorPalette: ColorPalette) => void
}

export const AcColorPalettePicker: React.FC<IAcColorPalettePicker> = props => {
  return (
    <EuiFlexGroup wrap>
      {props.colors.map(color => {
        const tempPalette = CreateColorPalette(color)
        return (
          <EuiFlexItem grow={false} key={color}>
            <EuiPanel hasShadow={false} hasBorder={false} style={{ padding: 0, width: 50 }} onClick={() => props.onColorPaletteChange(tempPalette)}>
              <EuiFlexGroup wrap gutterSize={'none'}>
                <EuiFlexItem grow={false}>
                  <div style={{ width: 10, height: 30, backgroundColor: tempPalette.prominentColor }} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ width: 10, height: 30, backgroundColor: tempPalette.baseColor }} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ width: 10, height: 30, backgroundColor: tempPalette.backgroundColor }} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ width: 10, height: 30, backgroundColor: tempPalette.buttonColor }} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ width: 10, height: 30, backgroundColor: tempPalette.baseColor2 }} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        )
      })}
    </EuiFlexGroup>
  )
}
