import React, { ReactNodeArray, useEffect, useState } from 'react'
import reactStringReplace from 'react-string-replace'

import { EuiBadge, htmlIdGenerator } from '@elastic/eui'

import { BusinessTypeTextAssignmentSelection } from 'api'

import PhraseChooser from './PhraseChooser'
import PhraseEnterer from './PhraseEnterer'

type TemplateCellType = {
  phrase: BusinessTypeTextAssignmentSelection
}

const TemplateCell: React.FC<TemplateCellType> = ({ phrase }) => {
  const [template, setTemplate] = useState<ReactNodeArray>([])

  useEffect(() => {
    let node: React.ReactNodeArray = [phrase.text]
    let phrasesFound = false

    for (const p in phrase.possibleAnchorPhrases) {
      phrasesFound = true
      node = reactStringReplace(node, `{${p}}`, () => (
        <React.Fragment key={htmlIdGenerator()()}>
          <PhraseChooser possiblePhrases={phrase.possibleAnchorPhrases[p]} selectedPhrase={phrase.selectedAnchorPhrases[p]} setParentState={x => (phrase.selectedAnchorPhrases[p] = x)} />
        </React.Fragment>
      ))
    }

    for (const p in phrase.selectedGranularPhrases) {
      phrasesFound = true
      node = reactStringReplace(node, `{{${p}}}`, () => (
        <React.Fragment key={htmlIdGenerator()()}>
          <PhraseEnterer selectedPhrase={phrase.selectedGranularPhrases[p]} setParentState={x => (phrase.selectedGranularPhrases[p] = x)} />
        </React.Fragment>
      ))
    }

    const reg = new RegExp(/<(CN|BN|BT|BC)>/g)
    if (reg.test(phrase.text)) {
      phrasesFound = true
      node = reactStringReplace(node, reg, match => (
        <EuiBadge key={htmlIdGenerator()()} color='hollow' style={{ marginLeft: 5, marginTop: 2, whiteSpace: 'nowrap' }}>
          {'<'}
          {match}
          {'>'}
        </EuiBadge>
      ))
    }

    if (!phrasesFound) {
      node = [
        <EuiBadge key={htmlIdGenerator()()} style={{ whiteSpace: 'nowrap' }}>
          {phrase.text}
        </EuiBadge>
      ]
    }

    setTemplate(node)
  }, [phrase])

  return <React.Fragment key={phrase.id}>{template}</React.Fragment>
}

export default TemplateCell
