import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { EuiForm, EuiFormRow, EuiLink, EuiLoadingContent, EuiSpacer, EuiText, EuiFlexGroup, EuiFlexItem, EuiImage, EuiFilePicker, EuiButton, EuiButtonEmpty } from '@elastic/eui'

import { Ad, AdStatus, Campaign, UpdateUploadAdBillboard, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { getAdStatusReadable } from 'utils/EnumToFriendly'
import { useDownloadUrl } from 'utils/useDownloadUrl'

interface AdBillboardDetailsTabParams {
  adId: string
}

interface FormValues {
  destinationUrl: string
  uploadedUrl: string | null
}

const AdBillboardDetailsTab: React.FC<AdBillboardDetailsTabParams> = ({ adId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [ad, setAd] = useState<Ad>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [destination, setDestination] = useState<string | null>(null)
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [isAuditor, setIsAuditor] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [initial, setInitial] = useState<FormValues>({
    destinationUrl: '',
    uploadedUrl: ''
  })

  const [downloadImage] = useDownloadUrl(initial?.uploadedUrl ?? '')

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-customermanager') || userProfile.roles.includes('adcritter-ops-admin'))
      setIsAuditor(userProfile.roles.includes('adcritter-ops-auditor') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (destination) {
      const storageClient = new BlobServiceClient(destination)
      setContainerClient(storageClient.getContainerClient(adId))
    }
  }, [destination, adId])

  useEffect(() => {
    if (opsClient && adId) {
      opsClient.getBillboardUpload(adId).then(data => setDestination(data))
    }
  }, [opsClient, adId])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getAd(adId).then(result => {
        setInitial({
          destinationUrl: result.destinationUrl ?? '',
          uploadedUrl: result.billboardDetails.uploadedUrl ?? result.billboardDetails.previewUrl
        })
        setAd(result)
        opsClient.getCampaign(result.campaignId).then(result => {
          setCampaign(result)
        })
        setIsLoading(false)
      })
    }
  }, [opsClient, adId])

  const onBillboardImageFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploading(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          const tempBillboard = { ...initial }
          tempBillboard.uploadedUrl = url
          setInitial(tempBillboard)
          setIsUploading(false)
        })
    }
  }

  const pauseAd = () => {
    opsClient!.putAdInactive(adId).then(result => {
      setAd(result)
    })
  }

  const startAd = () => {
    opsClient!.putAdActive(adId).then(result => {
      setAd(result)
    })
  }

  const deleteAd = () => {
    opsClient!.putAdDeleted(adId).then(result => {
      setAd(result)
    })
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    let update: UpdateUploadAdBillboard = {
      destinationUrl: values.destinationUrl,
      uploadedUrl: values.uploadedUrl
    }
    opsClient!.updateAdBillboard(adId, update).then(result => {
      setAd(result)
      setSubmitting(false)
    })
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {ad && campaign && (
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Formik initialValues={initial} enableReinitialize onSubmit={doSubmit}>
                {props => (
                  <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                    <EuiFormRow label='Organization'>
                      <EuiLink onClick={() => history.push(`/customers/organizations/${ad!.organizationId}`)}>{ad!.organizationName}</EuiLink>
                    </EuiFormRow>
                    <EuiFormRow label='Account'>
                      <EuiLink onClick={() => history.push(`/customers/accounts/${ad!.accountId}`)}>{ad!.accountName}</EuiLink>
                    </EuiFormRow>
                    <EuiFormRow label='Campaign'>
                      <EuiLink onClick={() => history.push(`/customers/campaigns/${ad!.campaignId}`)}>{ad!.campaignName}</EuiLink>
                    </EuiFormRow>
                    <EuiFormRow label='Status'>
                      <React.Fragment>
                        <EuiText size='s'>
                          {getAdStatusReadable(ad.status)} | {ad.status !== AdStatus.Inactive && ad.status !== AdStatus.Deleted && canUpdate && <EuiLink onClick={pauseAd}>pause this ad</EuiLink>}
                          {ad.status === AdStatus.Inactive && canUpdate && <EuiLink onClick={startAd}>start this ad</EuiLink>}{' '}
                          {ad.status !== AdStatus.Deleted && userProfile!.isAdmin && (
                            <React.Fragment>
                              |{' '}
                              <EuiLink onClick={deleteAd} color='danger'>
                                force delete this ad
                              </EuiLink>
                            </React.Fragment>
                          )}
                          {ad.status === AdStatus.Deleted && userProfile!.isAdmin && (
                            <React.Fragment>
                              |{' '}
                              <EuiLink onClick={pauseAd} color='warning'>
                                un-delete this ad
                              </EuiLink>
                            </React.Fragment>
                          )}
                        </EuiText>
                      </React.Fragment>
                    </EuiFormRow>
                    <EuiFormRow label='Billboard Upload'>
                      <EuiFilePicker id='uploadedUrl' display='default' multiple={false} isInvalid={!!props.errors.uploadedUrl} initialPromptText={!initial?.uploadedUrl ? 'Insert billboard image here' : 'Replace billboard image by dropping new one here'} onChange={onBillboardImageFileChange} isLoading={isUploading} />
                    </EuiFormRow>
                    <EuiFormRow>
                      <EuiButtonEmpty title={'Download'} iconType={'download'} onClick={() => downloadImage(`${ad?.adId ?? 'downloadedAd'}.png`)}>
                        Download Billboard
                      </EuiButtonEmpty>
                    </EuiFormRow>
                    <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={isUploading}>
                      Save
                    </EuiButton>
                  </EuiForm>
                )}
              </Formik>
            </EuiFlexItem>
            <EuiFlexItem>{initial.uploadedUrl && <EuiImage alt={'uploaded'} src={initial.uploadedUrl} />}</EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AdBillboardDetailsTab
