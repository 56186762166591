import moment from 'moment'

export interface Contact {
  id: string
  organizationId: string
  position: string
  firstName: string
  lastName: string
  contactMethods: ContactMethod[]
  timeZone: string
  address: string
  city: string
  state: string
  zipCode: string
  changes: ContactChange[]
  customFields: CustomField[]
}

export interface CustomField {
  key: string
  value: string
}

export interface ContactMethod {
  id: string
  type: string
  communicationPreference: string
  info: string
  isPrimary: boolean
}

export interface ContactChange {
  changeType: string
  changed: moment.Moment
  newValue: string
  oldValue: string
  username: string
}

export enum ContactMethodType {
  HomePhone = 'HomePhone',
  PersonalCellPhone = 'PersonalCellPhone',
  WorkPhone = 'WorkPhone',
  WorkCellPhone = 'WorkCellPhone',
  HomeFax = 'HomeFax',
  WorkFax = 'WorkFax',
  PersonalEmail = 'PersonalEmail',
  WorkEmail = 'WorkEmail',
  Other = 'Other'
}

export enum CommunicationPreference {
  Email = 'Email',
  Call = 'Call',
  Text = 'Text'
}
export const transformContactMethodTypes = () => {
  return Object.values(ContactMethodType)
    .map(type => ({
      value: type,
      text: type
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase()
    }))
    .sort((a, b) => {
      if (a.value === 'Other') return 1
      if (a.value === b.value) return 0
      if (a.value > b.value) return 1
      return -1
    })
}

export const transformContactMethodCommunicationPreferences = (filter: (cp: CommunicationPreference) => boolean) => {
  return Object.values(CommunicationPreference)
    .filter(filter)
    .map(cp => ({
      value: cp,
      text: cp
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase()
    }))
    .sort((a, b) => {
      if (a.value === b.value) return 0
      if (a.value > b.value) return 1
      return -1
    })
}
