import moment from 'moment'

import { AdStatus, Campaign, CampaignStatus } from 'api'

export const insertSpaces = (value?: string) => {
  if (!value) {
    return ''
  }

  value = value.replace(/([a-z])([A-Z])/g, '$1 $2')
  value = value.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  return value
}

export const getCampaignStatusReadable = (campaign: Campaign) => {
  if (campaign.status !== CampaignStatus.EndingOn || !campaign.endingOnDate) {
    return insertSpaces(campaign.status)
  }

  return `Ending On ${formatEndingOnDate(moment(campaign.endingOnDate))}`
}

export const getAdStatusReadable = (adStatus: AdStatus) => {
  return insertSpaces(adStatus)
}

export const CampaignStatusToElasticColor = (status: CampaignStatus) => {
  switch (status) {
    case CampaignStatus.NoApprovedAds:
    case CampaignStatus.PaymentFailed:
      return 'danger'
    case CampaignStatus.EndingOn:
    case CampaignStatus.CampaignEnded:
    case CampaignStatus.SavedForLater:
    case CampaignStatus.PendingAdApproval:
    case CampaignStatus.Archived:
    case CampaignStatus.Deleted:
    case CampaignStatus.WaitingForCustomAds:
    case CampaignStatus.WaitingForTVOutro:
      return 'default'
    case CampaignStatus.Live:
      return 'success'
    case CampaignStatus.Scheduled:
    case CampaignStatus.PendingNetworkApproval:
    default:
      return 'warning'
  }
}

export const CreativeStatusToElasticColor = (status: AdStatus) => {
  switch (status) {
    case AdStatus.WaitingForTVOutro:
    case AdStatus.PendingApproval:
      return 'default'
    case AdStatus.Live:
      return 'success'
    case AdStatus.Disapproved:
      return 'danger'
    case AdStatus.PendingNetworkApproval:
    case AdStatus.Inactive:
      return 'warning'
  }
}

export const formatEndingOnDate = (date: moment.Moment | null) => {
  return date?.format('MMMM Do YYYY')
}
