import {useState} from "react";
import {EuiConfirmModal, EuiFormRow, EuiOverlayMask, EuiRadioGroup} from "@elastic/eui";
import {EuiConfirmModalProps} from "@elastic/eui/src/components/modal/confirm_modal";

export interface IAcRadioOption {
  text: string
  key: string
}

export interface IAcRadioModal extends EuiConfirmModalProps {
  message: string
  onConfirmWithRadios: (selectedKey?: string) => void
  radioOptions: IAcRadioOption[]
}

export const AcRadioModal: React.FC<IAcRadioModal> = props => {
  const [selectedKey, setSelectedKey] = useState<string>()

  const onConfirm = () => {
    if (props.onConfirmWithRadios) {
      props.onConfirmWithRadios(selectedKey)
    }
  }

  const onRadioSelected = (key: string) => {
    setSelectedKey(key)
  }

  return (
    <EuiOverlayMask>
      <EuiConfirmModal {...{...props, onConfirm: onConfirm}} confirmButtonDisabled={!selectedKey}>
        <p>{props.message}</p>
          <EuiFormRow>
            <EuiRadioGroup
              options={props.radioOptions.map(r => ({id: r.key, label: r.text}))}
              idSelected={selectedKey}
              onChange={(id) => onRadioSelected(id)}
              name="radio group"
            />
          </EuiFormRow>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}