import { DependencyList, EffectCallback, useEffect } from 'react'

export function useDebounceEffect(delay: number, callback: EffectCallback, deps?: DependencyList) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, deps)
}
