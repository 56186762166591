import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EuiBreadcrumbs, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, EuiTabbedContent, EuiCopy, EuiBadge } from '@elastic/eui'
import { EuiTabbedContentTab } from '@elastic/eui/src/components/tabs/tabbed_content/tabbed_content'

import { TvCommercial, useOpsClient } from 'api'
import history from 'services/HistoryService'

import TvCommercialDetailsTab from './TvCommercialDetailsTab'
import TvCommercialTextAssignmentTab from './TvCommercialTextAssignmentTab'
import TvCommercialTextsTab from './TvCommercialTextsTab'
import TvCommercialVoiceOverAssignmentTab from './TvCommercialVoiceOverAssignmentTab'
import TvCommercialVoiceOverScriptTab from './TvCommercialVoiceOverScriptTab'

interface TvCommercialPageParams {
  tvCommercialId: string
}

const TvCommercialPage: React.FC = () => {
  let { tvCommercialId } = useParams<TvCommercialPageParams>()
  const [tvCommercial, setTvCommercial] = useState<TvCommercial>()
  const [tabs, setTabs] = useState<EuiTabbedContentTab[]>([])
  const opsClient = useOpsClient()

  useEffect(() => {
    if (opsClient) {
      opsClient.getTvCommercial(tvCommercialId).then(result => {
        setTvCommercial(result)
      })
    }
  }, [opsClient, tvCommercialId])

  useEffect(() => {
    let t: EuiTabbedContentTab[] = [
      {
        id: 'details',
        name: 'Details',
        content: <TvCommercialDetailsTab tvCommercialId={tvCommercialId} />
      }
    ]

    if (tvCommercial && tvCommercial.type === 'Text') {
      t.push({ id: 'texts', name: 'Text Sections', content: <TvCommercialTextsTab tvCommercialId={tvCommercialId} /> })
      t.push({
        id: 'businessTypes',
        name: 'Business Types',
        content: <TvCommercialTextAssignmentTab tvCommercialId={tvCommercialId} />
      })
    }

    if (tvCommercial && tvCommercial.type === 'VoiceOver') {
      t.push({
        id: 'script',
        name: 'Voice-over Script',
        content: <TvCommercialVoiceOverScriptTab tvCommercialId={tvCommercialId} />
      })
      t.push({
        id: 'businessTypes',
        name: 'Business Types',
        content: <TvCommercialVoiceOverAssignmentTab tvCommercialId={tvCommercialId} />
      })
    }

    setTabs(t)
  }, [tvCommercialId, tvCommercial])

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Assets' },
              {
                text: 'TV Commercials',
                href: '/assets/tvcommercials',
                onClick: () => history.push('/assets/tvcommercials')
              },
              {
                text: tvCommercial?.name || '',
                href: `/assets/tvcommercials/${tvCommercialId}`,
                onClick: () => history.push(`/assets/tvcommercials/${tvCommercialId}`)
              }
            ]}
          />
          <EuiSpacer />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiTitle size='m'>
                <h1>TV Commercial</h1>
              </EuiTitle>
              <EuiCopy textToCopy={tvCommercialId}>
                {copy => (
                  <EuiBadge color='default' onClick={() => copy()} onClickAriaLabel='Copy TV Commercial ID'>
                    {tvCommercialId}
                  </EuiBadge>
                )}
              </EuiCopy>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>{tabs && tabs.length > 0 && <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} />}</EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default TvCommercialPage
