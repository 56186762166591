import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBasicTable, EuiButton, EuiButtonIcon, EuiCheckbox, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPortal, EuiSpacer, EuiText, EuiToolTip, htmlIdGenerator } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { AgencyCategory, Organization } from 'api'
import { Bundle, BundleActiveStatus, BundleStatus, BundleType, CampaignSlotSimple } from 'api/entities/Bundle'
import { CreatePackageRequest } from 'api/entities/Package'
import { CreatePlanRequest } from 'api/entities/Plan'
import { CreateSelfServicePackageRequest, SelfServicePackage } from 'api/entities/SelfServicePackage'
import { useAddCampaignSlotsToBundleMutation, useCreateBundleMutation, useDeleteBundleMutation, useEndBundleMutation, useForceEndBundleMutation, useGetOrganizationBundlesQuery, useChangeBundleCampaignSlotsMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { useGetAgencyOrganizationQuery, useOrganizationCancelSubscriptionMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { RootState } from 'app/rootReducer'
import { BundleDetailsFlyout } from 'components/Bundles/BundleDetailsFlyout'
import BundleStatusBadge from 'components/Bundles/BundleStatusBadge'
import { PostponeBundleModal } from 'components/Bundles/PostponeBundleModal'
import { ReactivateBundleModal } from 'components/Bundles/ReactivateBundleModal'
import SelfServicePackageDetailsFlyout from 'components/Bundles/SelfServicePackageDetailsFlyout'
import { AcConfirmModal } from 'components/Modals/AcConfirmModal'
import { AcRadioModal } from 'components/Modals/AcRadioModal'
import { BundleCreateFlyout } from 'features/customers/bundles/BundleCreateFlyout'
import { GetBundleCategoryProperties } from 'features/customers/bundles/BundleEnumsProperties'
import { BundleReSlotFlyout } from 'features/customers/bundles/BundleReSlotFlyout'
import { SelfServicePackageCreateFlyout } from 'features/customers/bundles/SelfServicePackageCreateFlyout'

interface OrganizationBundlesTabParams {
  organization: Organization
}

const OrganizationBundlesTab: React.FC<OrganizationBundlesTabParams> = ({ organization }) => {
  const organizationBundlesRequest = useGetOrganizationBundlesQuery(organization.organizationId)
  const { data: agencyOrganization } = useGetAgencyOrganizationQuery(organization.organizationId)
  const [createBundle, createBundleRequest] = useCreateBundleMutation()
  const [endBundle, endBundleRequest] = useEndBundleMutation()
  const [changeBundleCampaignSlots, changeBundleCampaignSlotsRequest] = useChangeBundleCampaignSlotsMutation()
  const [immediatelyEndBundle, immediatelyEndBundleRequest] = useForceEndBundleMutation()
  const [deleteBundle, deleteBundleRequest] = useDeleteBundleMutation()
  const [addCampaignSlots] = useAddCampaignSlotsToBundleMutation()
  const [cancelSubscription, cancelSubscriptionRequest] = useOrganizationCancelSubscriptionMutation()
  const [showEndBundleModal, setShowEndBundleModal] = useState<boolean>(false)
  const [showDeleteBundleModal, setShowDeleteBundleModal] = useState<boolean>(false)
  const [showReActivateModal, setShowReActivateModal] = useState<boolean>(false)
  const [showPostponeModal, setShowPostponeModal] = useState<boolean>(false)
  const [showEndNowModal, setShowEndNowModal] = useState<boolean>(false)
  const [isFlyoutAddPlanOpen, setIsFlyoutAddPlanOpen] = useState<boolean>(false)
  const [isFlyoutAddPackageOpen, setIsFlyoutAddPackageOpen] = useState<boolean>(false)
  const [isFlyoutAddSelfServicePackageOpen, setIsFlyoutAddSelfServicePackageOpen] = useState(false)
  const [isFlyoutReSlotOpen, setIsFlyoutReSlotOpen] = useState<boolean>(false)
  const [selectedBundle, setSelectedBundle] = useState<Bundle>()
  const [isViewBundleOpen, setIsViewBundleOpen] = useState<boolean>(false)
  const { userProfile } = useSelector((state: RootState) => state.app)

  useEffect(() => {
    if (organizationBundlesRequest.isLoading) {
      return
    }
    if (selectedBundle && organizationBundlesRequest.data) {
      setSelectedBundle(organizationBundlesRequest.data.find(p => p.id === selectedBundle.id))
    }
  }, [organizationBundlesRequest.data, organizationBundlesRequest.isLoading])

  const columns: Array<EuiBasicTableColumn<Bundle>> = [
    {
      name: 'Name',
      render: (bundle: Bundle) => <EuiCopy textToCopy={bundle.id}>{copy => <EuiText onClick={copy}>{bundle.name}</EuiText>}</EuiCopy>,
      sortable: (bundle: Bundle) => bundle.name
    },
    {
      name: 'Status',
      sortable: (bundle: Bundle) => bundle.bundleStatus,
      render: (bundle: Bundle) => <BundleStatusBadge bundle={bundle} />
    },
    {
      name: 'Category',
      sortable: (bundle: Bundle) => bundle.bundleCategory,
      render: (bundle: Bundle) => {
        const packageCategoryProperties = GetBundleCategoryProperties(bundle.bundleCategory)
        return <EuiText size={'s'}>{packageCategoryProperties.text}</EuiText>
      }
    },
    {
      name: 'Slots Filled',
      render: (p: Bundle) => `${p.campaignSlots.filter(slot => slot.campaignId).length} / ${p.campaignSlots.length}`
    },
    {
      name: 'Interval Price',
      field: 'price',
      render: (price: number) => `$${price}`
    },
    ...(agencyOrganization?.agencyDetails && agencyOrganization.agencyDetails.agencyCategory !== AgencyCategory.Suspected
      ? [
          {
            name: 'Expiration Date',
            render: (p: Bundle) => {
              const selfServicePackage = p as SelfServicePackage

              return selfServicePackage?.expirationDate ? moment(selfServicePackage.expirationDate).calendar() : 'N/A'
            }
          }
        ]
      : []),
    {
      name: 'Watchlist',
      render: (bundle: Bundle) => <EuiCheckbox checked={bundle.isWatchListed} disabled id={htmlIdGenerator()()} onChange={() => true} />
    },
    {
      name: 'View Details',
      render: (bundle: Bundle) => <a onClick={() => openViewBundle(bundle)}>View</a>
    },
    ...(userProfile?.roles.includes('adcritter-ops-csuite') || userProfile?.roles.includes('adcritter-ops-customersuccessmanager')
      ? [
          {
            name: '',
            render: (bundle: Bundle) => (
              <EuiFlexGroup>
                {bundle.bundleStatus !== BundleStatus.Deleted && bundle.bundleStatus !== BundleStatus.PaymentFailed && bundle.bundleStatus !== BundleStatus.Ended && bundle.bundleStatus !== BundleStatus.EndingOn && (
                  <EuiFlexItem grow={false}>
                    <EuiToolTip position='top' content={`End ${bundle.bundleType}`}>
                      <EuiButtonIcon
                        color={'danger'}
                        onClick={() => {
                          onEndBundleClicked(bundle)
                        }}
                        iconType={'stopFilled'}
                      />
                    </EuiToolTip>
                  </EuiFlexItem>
                )}
                {(bundle.bundleStatus === BundleStatus.PaymentFailed || bundle.bundleStatus === BundleStatus.Ended) && bundle.billingSchedule && (bundle.billingSchedule.intervalsCompleted < bundle.billingSchedule.totalIntervals || bundle.billingSchedule.isIndefinite) && (
                  <EuiFlexItem grow={false}>
                    <EuiToolTip position='top' content={`Reactivate ${bundle.bundleType}`}>
                      <EuiButtonIcon
                        color={'primary'}
                        onClick={() => {
                          onReactivateBundleClicked(bundle)
                        }}
                        iconType={'playFilled'}
                      />
                    </EuiToolTip>
                  </EuiFlexItem>
                )}
                {bundle.bundleActiveStatus === BundleActiveStatus.Active && bundle.bundleStatus !== BundleStatus.OnHoldUntil && (
                  <EuiFlexItem grow={false}>
                    <EuiToolTip position='top' content='Postpone'>
                      <EuiButtonIcon
                        color={'primary'}
                        onClick={() => {
                          onPostponeBundleClicked(bundle)
                        }}
                        iconType={'calendar'}
                      />
                    </EuiToolTip>
                  </EuiFlexItem>
                )}
                {bundle.campaignSlots.filter(slot => slot.campaignId).length === 0 && bundle.bundleStatus !== BundleStatus.Ended && bundle.bundleStatus !== BundleStatus.EndingOn && (
                  <EuiFlexItem grow={false}>
                    <EuiToolTip position='top' content={`Re-Slot ${bundle.bundleType}`}>
                      <EuiButtonIcon
                        onClick={() => {
                          onReSlotBundleClicked(bundle)
                        }}
                        iconType={'unlink'}
                      />
                    </EuiToolTip>
                  </EuiFlexItem>
                )}
                {bundle.bundleStatus !== BundleStatus.Deleted && bundle.bundleStatus !== BundleStatus.Ended && (
                  <EuiFlexItem grow={false}>
                    <EuiToolTip position='top' content={`End ${bundle.bundleType} Now`}>
                      <EuiButtonIcon
                        color={'danger'}
                        onClick={() => {
                          onEndNowClicked(bundle)
                        }}
                        iconType={'warning'}
                      />
                    </EuiToolTip>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            )
          },
          {
            name: '',
            render: (bundle: Bundle) =>
              bundle.bundleActiveStatus === BundleActiveStatus.Inactive && bundle.bundleStatus !== BundleStatus.Deleted ? (
                <EuiButton
                  color={'danger'}
                  iconType={'trash'}
                  onClick={() => {
                    onDeleteBundleClicked(bundle)
                  }}>
                  Delete
                </EuiButton>
              ) : (
                ''
              )
          }
        ]
      : [])
  ]

  const onEndBundleClicked = (bundle: Bundle) => {
    setShowEndBundleModal(true)
    setSelectedBundle(bundle)
  }

  const onReSlotBundleClicked = (bundle: Bundle) => {
    setSelectedBundle(bundle)
    setIsFlyoutReSlotOpen(true)
  }

  const onConfirmEndBundle = (endSubscription: boolean) => {
    if (!!selectedBundle) {
      endBundle({ organizationId: organization.organizationId, bundleId: selectedBundle.id }).then(() => {
        if (endSubscription) {
          cancelSubscription({ organizationId: organization.organizationId }).then(() => {
            setShowEndBundleModal(false)
            setSelectedBundle(undefined)
          })
        } else {
          setShowEndBundleModal(false)
          setSelectedBundle(undefined)
        }
      })
    }
  }

  const onConfirmEndBundleNow = () => {
    if (!!selectedBundle) {
      immediatelyEndBundle({ organizationId: organization.organizationId, bundleId: selectedBundle.id }).then(() => {
        setShowEndNowModal(false)
        setSelectedBundle(undefined)
      })
    }
  }

  const onCancelConfirmEndBundle = () => {
    setSelectedBundle(undefined)
    setShowEndBundleModal(false)
  }

  const onReactivateBundleClicked = (bundle: Bundle) => {
    setShowReActivateModal(true)
    setSelectedBundle(bundle)
  }

  const onConfirmReactivateBundle = () => {
    if (!!selectedBundle) {
      setSelectedBundle(undefined)
      setShowReActivateModal(false)
    }
  }

  const onCancelConfirmReactivateBundle = () => {
    setSelectedBundle(undefined)
    setShowReActivateModal(false)
  }

  const onPostponeBundleClicked = (bundle: Bundle) => {
    setShowPostponeModal(true)
    setSelectedBundle(bundle)
  }

  const onEndNowClicked = (bundle: Bundle) => {
    setShowEndNowModal(true)
    setSelectedBundle(bundle)
  }

  const onConfirmPostponeBundle = () => {
    if (!!selectedBundle) {
      setSelectedBundle(undefined)
      setShowPostponeModal(false)
    }
  }

  const onCancelConfirmPostponeBundle = () => {
    setSelectedBundle(undefined)
    setShowPostponeModal(false)
  }

  const onCancelEndNowModal = () => {
    setSelectedBundle(undefined)
    setShowEndNowModal(false)
  }

  const onDeleteBundleClicked = (bundle: Bundle) => {
    setShowDeleteBundleModal(true)
    setSelectedBundle(bundle)
  }

  const onConfirmDeleteBundle = () => {
    if (!!selectedBundle) {
      deleteBundle({ organizationId: organization.organizationId, bundleId: selectedBundle.id }).then(() => {
        setShowDeleteBundleModal(false)
        setSelectedBundle(undefined)
      })
    }
  }

  const onCancelConfirmDeleteBundle = () => {
    setSelectedBundle(undefined)
    setShowDeleteBundleModal(false)
  }

  const onAddBundle = (createBundleRequestObj: CreatePlanRequest | CreatePackageRequest | CreateSelfServicePackageRequest) => {
    createBundle(createBundleRequestObj).finally(() => {
      onCloseAddBundle()
    })
  }
  const onCloseAddBundle = () => {
    setIsFlyoutAddPlanOpen(false)
    setIsFlyoutAddPackageOpen(false)
    setIsFlyoutAddSelfServicePackageOpen(false)
  }

  const onReSlotBundleSave = (paidSlots: CampaignSlotSimple[], bonusSlots: CampaignSlotSimple[]) => {
    changeBundleCampaignSlots({ bundleId: selectedBundle?.id ?? '', organizationId: organization.organizationId, campaignSlots: [...paidSlots, ...bonusSlots] }).then(() => {
      setIsFlyoutReSlotOpen(false)
    })
  }

  let flyoutAddPlan
  if (isFlyoutAddPlanOpen) {
    flyoutAddPlan = (
      <EuiPortal>
        <BundleCreateFlyout
          organization={organization}
          bundleType={BundleType.Plan}
          onCloseFlyout={() => {
            setIsFlyoutAddPlanOpen(false)
          }}
          onSaveBundle={onAddBundle}
          isLoading={createBundleRequest.isLoading}
        />
      </EuiPortal>
    )
  }

  let flyoutAddPackage
  if (isFlyoutAddPackageOpen) {
    flyoutAddPackage = (
      <EuiPortal>
        <BundleCreateFlyout
          organization={organization}
          bundleType={BundleType.Package}
          onCloseFlyout={() => {
            setIsFlyoutAddPackageOpen(false)
          }}
          onSaveBundle={onAddBundle}
          isLoading={createBundleRequest.isLoading}
        />
      </EuiPortal>
    )
  }

  let flyoutAddSelfServicePackage
  if (isFlyoutAddSelfServicePackageOpen) {
    flyoutAddSelfServicePackage = (
      <EuiPortal>
        <SelfServicePackageCreateFlyout
          organization={organization}
          onCloseFlyout={() => {
            setIsFlyoutAddSelfServicePackageOpen(false)
          }}
          onCreated={() => {
            setIsFlyoutAddSelfServicePackageOpen(false)
          }}
        />
      </EuiPortal>
    )
  }

  let flyoutReSlot
  if (isFlyoutReSlotOpen && selectedBundle) {
    flyoutReSlot = (
      <EuiPortal>
        <BundleReSlotFlyout
          bundle={selectedBundle}
          onCloseFlyout={() => {
            setIsFlyoutReSlotOpen(false)
          }}
          isLoading={changeBundleCampaignSlotsRequest.isLoading}
          onSaveBundle={onReSlotBundleSave}
        />
      </EuiPortal>
    )
  }

  const openViewBundle = (bundle: Bundle) => {
    setIsViewBundleOpen(true)
    setSelectedBundle(bundle)
  }
  const closeViewBundle = () => {
    setIsViewBundleOpen(false)
  }

  const onSaveBundle = (bundleId: string, addedSlots: CampaignSlotSimple[]) => {
    addCampaignSlots({ bundleId: bundleId, organizationId: organization.organizationId, campaignSlots: addedSlots })
    setIsViewBundleOpen(false)
  }

  let flyoutViewBundle
  if (isViewBundleOpen && selectedBundle) {
    let children
    if (selectedBundle.bundleType === BundleType.SelfServicePackage) {
      children = <SelfServicePackageDetailsFlyout selfServicePackage={selectedBundle as SelfServicePackage} isFlyoutOpen={isViewBundleOpen} onCloseFlyout={closeViewBundle} />
    } else {
      children = <BundleDetailsFlyout onClose={closeViewBundle} bundle={selectedBundle} onSave={onSaveBundle} />
    }

    flyoutViewBundle = <EuiPortal>{children}</EuiPortal>
  }

  let content

  if (organizationBundlesRequest.isLoading) {
    content = <EuiLoadingContent lines={3} />
  } else {
    content = (
      <React.Fragment>
        <EuiBasicTable loading={organizationBundlesRequest.isLoading || organizationBundlesRequest.isFetching} items={organizationBundlesRequest.data ?? []} columns={columns} />
        <EuiSpacer />
        <EuiFlexGroup justifyContent={'flexStart'}>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={() => setIsFlyoutAddPlanOpen(true)}>Create Plan</EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton onClick={() => setIsFlyoutAddPackageOpen(true)}>Create Package</EuiButton>
          </EuiFlexItem>
          {agencyOrganization?.agencyDetails && agencyOrganization.agencyDetails.agencyCategory !== AgencyCategory.Suspected && (
            <EuiFlexItem grow={false}>
              <EuiButton onClick={() => setIsFlyoutAddSelfServicePackageOpen(true)}>Create Self Service Package</EuiButton>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>

        {flyoutViewBundle}
        {flyoutAddPlan}
        {flyoutAddPackage}
        {flyoutAddSelfServicePackage}
        {flyoutReSlot}
        {showEndNowModal && (
          <AcConfirmModal
            message={`This operation will immediately end and unbind all campaigns associated with this ${selectedBundle?.bundleType}. Are you sure you wish to IMMEDIATELY end this ${selectedBundle?.bundleType}?`}
            onCancel={onCancelEndNowModal}
            onConfirm={() => {
              onConfirmEndBundleNow()
            }}
            title={`Immediately End ${selectedBundle?.bundleType}`}
            confirmButtonText={`Yes, end this ${selectedBundle?.bundleType} now`}
            isLoading={immediatelyEndBundleRequest.isLoading}
          />
        )}
        {showEndBundleModal && (
          <React.Fragment>
            {organizationBundlesRequest.data?.some(bundle => bundle.id !== selectedBundle?.id && bundle.bundleActiveStatus === BundleActiveStatus.Active && bundle.bundleStatus !== BundleStatus.EndingOn) ? (
              <AcConfirmModal
                message={`Are you sure you wish to end this ${selectedBundle?.bundleType}?`}
                onCancel={onCancelConfirmEndBundle}
                onConfirm={() => {
                  onConfirmEndBundle(false)
                }}
                title={'End'}
                confirmButtonText={`Yes, end this ${selectedBundle?.bundleType}`}
                isLoading={endBundleRequest.isLoading}
              />
            ) : (
              <AcRadioModal
                message={`Are you sure you wish to end this ${selectedBundle?.bundleType}?`}
                onCancel={onCancelConfirmEndBundle}
                onConfirmWithRadios={selectedKey => {
                  onConfirmEndBundle(selectedKey === 'cancel')
                }}
                title={'End Bundle'}
                confirmButtonText={`Yes, end this ${selectedBundle?.bundleType}`}
                isLoading={endBundleRequest.isLoading || cancelSubscriptionRequest.isLoading}
                radioOptions={[
                  { text: 'Resume billing this organization for their subscription', key: 'resume' },
                  { text: "Cancel this organization's subscription", key: 'cancel' }
                ]}
              />
            )}
          </React.Fragment>
        )}
        {showDeleteBundleModal && <AcConfirmModal message={`Are you sure you wish to DELETE this ${selectedBundle?.bundleType}?`} buttonColor={'danger'} onCancel={onCancelConfirmDeleteBundle} onConfirm={onConfirmDeleteBundle} title={`Delete`} confirmButtonText={`Yes, DELETE this ${selectedBundle?.bundleType}`} isLoading={deleteBundleRequest.isLoading} />}
        {showReActivateModal && selectedBundle && <ReactivateBundleModal bundle={selectedBundle} onCancel={onCancelConfirmReactivateBundle} onConfirmComplete={onConfirmReactivateBundle} />}
        {showPostponeModal && selectedBundle && <PostponeBundleModal bundle={selectedBundle} onCancel={onCancelConfirmPostponeBundle} onConfirmComplete={onConfirmPostponeBundle} />}
      </React.Fragment>
    )
  }

  return <div style={{ marginTop: '10px' }}>{content}</div>
}

export default OrganizationBundlesTab
