import { Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { EuiLoadingContent, EuiSpacer, EuiDescriptionList, EuiDescriptionListTitle, EuiDescriptionListDescription, EuiForm, EuiFormRow, EuiFieldNumber, EuiButton, EuiFlexItem, EuiFlexGroup, EuiText, EuiBasicTable, EuiBasicTableColumn, EuiCheckbox, EuiHorizontalRule, EuiFieldText } from '@elastic/eui'

import { Organization, PaymentMethod, Subscription, useOpsClient } from 'api'
import { Invoice } from 'api/entities/Invoice'
import { Product } from 'api/entities/Product'
import { useGetProductForSubscriptionQuery, useSetProductForSubscriptionMutation } from 'api/rtkQueryApi/opsApi/productsApi'
import { RootState } from 'app/rootReducer'
import { OrganizationSetSubscriptionPanel } from 'features/customers/organizations/OrganizationSetSubscriptionPanel'
import { displayPaymentMethodShort } from 'utils/InvoiceUtils'

interface OrganizationSubscriptionTabParams {
  organization: Organization
}

interface FormValues {
  turns: number
  resetSnapDate: boolean
}

const OrganizationSubscriptionTab: React.FC<OrganizationSubscriptionTabParams> = ({ organization }) => {
  const subscriptionProductRequest = useGetProductForSubscriptionQuery(organization.subscriptionId)
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const [setSubscriptionProduct, setSubscriptionProductQuery] = useSetProductForSubscriptionMutation()
  const [showEditSubscriptionPanel, setShowEditSubscriptionPanel] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [subscription, setSubscription] = useState<Subscription>()
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [isInvoicesLoading, setIsInvoicesLoading] = useState<boolean>(false)

  const isFinance = !!userProfile && (userProfile.roles.includes('adcritter-ops-finance') || userProfile.roles.includes('adcritter-ops-admin'))

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const [initial] = useState<FormValues>({
    turns: 0,
    resetSnapDate: false
  })

  const organizationSchema = Yup.object().shape({
    turns: Yup.number().min(0)
  })

  const columns: Array<EuiBasicTableColumn<Invoice>> = [
    {
      name: 'Date',
      field: 'created',
      render: (item: string) => moment(item).format('DD MMM yyyy')
    },
    {
      name: 'Number',
      field: 'number'
    },
    {
      name: 'Amount',
      field: 'amount',
      render: (item: number) => formatter.format(item)
    },
    {
      name: 'Status',
      field: 'status'
    },
    {
      name: 'Payment Method',
      field: 'paymentMethod',
      render: (paymentMethod: PaymentMethod) => displayPaymentMethodShort(paymentMethod)
    },
    {
      name: '',
      field: '',
      render: () => <div>Platform Access</div>
    }
  ]

  useEffect(() => {
    if (subscriptionProductRequest?.data && !subscriptionProductRequest.isLoading && !subscriptionProductRequest.isFetching) {
      setSelectedProduct(subscriptionProductRequest.data)
    }
  }, [subscriptionProductRequest.data, subscriptionProductRequest.isLoading, subscriptionProductRequest.isFetching])

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-finance') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    let isMounted = true

    if (opsClient && isMounted && organization) {
      setIsInvoicesLoading(true)
      setIsLoading(true)

      opsClient.getOrganizationSubscription(organization.organizationId).then(result => {
        if (isMounted) {
          setSubscription(result)
          setIsLoading(false)
        }
      })

      opsClient.apiGetOrganizationSubscriptionInvoices(organization.organizationId).then(result => {
        if (isMounted) {
          result.sort((a: Invoice, b: Invoice) => (moment(b.created).unix() ?? 0) - (moment(a.created).unix() ?? 0))
          setInvoices(result)
          setIsInvoicesLoading(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, organization])

  const doSubmit = (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
    if (values.turns > 0) {
      opsClient
        ?.updateOrganizationTrialSubscription(organization.organizationId, {
          turns: values.turns.toString(),
          resetSnapDate: values.resetSnapDate
        })
        .then(() => {
          setSubmitting(false)
          opsClient.getOrganizationSubscription(organization.organizationId).then(result => {
            setSubscription(result)
            setIsLoading(false)
            resetForm()
          })
        })
    } else {
      setSubmitting(false)
    }
  }

  const onEditSubscriptionCancel = () => {
    setShowEditSubscriptionPanel(false)
  }

  const onEditSubscriptionProductClick = () => {
    setShowEditSubscriptionPanel(true)
  }

  const onSaveEditSubscription = (productDetails: { selectedProductId: string; activateNow: boolean; activateAndBillNow: boolean }) => {
    if (selectedProduct?.id && isFinance) {
      setSubscriptionProduct({ subscriptionId: organization.subscriptionId, productId: productDetails.selectedProductId, chargeAndActivateNow: productDetails.activateAndBillNow, activateNow: productDetails.activateNow }).finally(() => {
        setShowEditSubscriptionPanel(false)
      })
    } else {
      setShowEditSubscriptionPanel(false)
    }
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {subscription && (
        <React.Fragment>
          {selectedProduct && (
            <React.Fragment>
              {showEditSubscriptionPanel && <OrganizationSetSubscriptionPanel isLoading={setSubscriptionProductQuery.isLoading} subscriptionProductId={selectedProduct.id} onCancelClick={onEditSubscriptionCancel} onSaveClick={onSaveEditSubscription} />}
              <EuiFormRow label='Name'>
                <EuiText>
                  <p>{organization.organizationName}</p>
                </EuiText>
              </EuiFormRow>
              <EuiFormRow label={'Subscription Type'} helpText={`Organization will be billed $${selectedProduct.intervalPrice} every ${selectedProduct.intervalUnitFrequency} ${selectedProduct.intervalUnit}s`}>
                <EuiFieldText append={isFinance ? <EuiButton onClick={onEditSubscriptionProductClick}>Edit</EuiButton> : undefined} disabled value={`${selectedProduct.name}: $${selectedProduct.intervalPrice} every ${selectedProduct.intervalUnitFrequency} ${selectedProduct.intervalUnit}s`} readOnly={true} />
              </EuiFormRow>
              <EuiHorizontalRule />
              <EuiSpacer />
            </React.Fragment>
          )}
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <Formik initialValues={initial} enableReinitialize validationSchema={organizationSchema} onSubmit={doSubmit}>
                {props => (
                  <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                    <EuiDescriptionList>
                      <EuiDescriptionListTitle>Stripe Customer ID</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{subscription.customerId}</EuiDescriptionListDescription>
                      <EuiDescriptionListTitle>Stripe Subscription ID</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{subscription.subscriptionId}</EuiDescriptionListDescription>
                      <EuiDescriptionListTitle>Status</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{subscription.status}</EuiDescriptionListDescription>
                      {subscription?.canceledAt !== null && (
                        <React.Fragment>
                          <EuiDescriptionListTitle>Canceling at:</EuiDescriptionListTitle>
                          <EuiDescriptionListDescription>{moment(subscription?.canceledAt).format('MMM Do YYYY')}</EuiDescriptionListDescription>
                        </React.Fragment>
                      )}
                      <EuiDescriptionListTitle>Upcoming Invoice</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>
                        {moment.utc(subscription.nextBillDate).format('MMM Do YYYY')} for {formatter.format(subscription.nextBillAmount || 0)}
                      </EuiDescriptionListDescription>
                      <EuiDescriptionListTitle>Started</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{moment.utc(subscription.created).format('MMM Do YYYY')}</EuiDescriptionListDescription>
                      <EuiDescriptionListTitle>Trial Ended/Ending</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>{moment.utc(subscription.trialEnd).format('MMM Do YYYY')}</EuiDescriptionListDescription>
                      <EuiDescriptionListTitle>Trialing Billing Cycle</EuiDescriptionListTitle>
                      <EuiDescriptionListDescription>
                        {subscriptionProductRequest.data?.trialIntervalUnitFrequency} {subscriptionProductRequest.data?.intervalUnit}
                      </EuiDescriptionListDescription>
                      <EuiSpacer />
                      <EuiFormRow label={'Extend trial X ' + subscriptionProductRequest.data?.intervalUnit + '(s)'}>
                        <EuiFieldNumber placeholder='Turns' value={props.values.turns} onChange={e => props.setFieldValue('turns', e.target.value)} aria-label='Trialing End Date' />
                      </EuiFormRow>
                      <EuiFormRow label={'Reset Snap Date'}>
                        <EuiCheckbox id={'reset snap date'} checked={props.values.resetSnapDate} onChange={e => props.setFieldValue('resetSnapDate', e.target.checked)} />
                      </EuiFormRow>
                      <EuiSpacer />
                    </EuiDescriptionList>
                    <EuiFlexGroup gutterSize='s'>
                      <EuiFlexItem grow={false}>
                        <EuiButton isLoading={props.isSubmitting || setSubscriptionProductQuery.isLoading} fill type='submit' isDisabled={!canUpdate}>
                          Save
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiForm>
                )}
              </Formik>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>Subscription Invoices</EuiText>
              <EuiBasicTable style={{ marginTop: '10px' }} loading={isInvoicesLoading} items={invoices ?? []} columns={columns} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default OrganizationSubscriptionTab
