import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiRadio, EuiSpacer, EuiText, EuiForm, EuiTitle, EuiFormRow, EuiButton, EuiFilePicker } from '@elastic/eui'

import { ApiError, Campaign, City, ThirdPartyAudience, useOpsClient, Zip } from 'api'
import { CityFinder, DmaFinder, StateFinder, TvAudienceFinder, ZipFinder } from 'components/Finders'
import { AgeRangeTargeter, AudienceTargeterDetailed, CityTargeter, DmaTargeter, GenderTargeter, HouseholdIncomeTargeter, StateTargeter, ZipTargeter } from 'components/Targeters'

interface TvTargetingTabParams {
  campaign: Campaign
}

interface FormValues {
  locationType: string
  states: Array<string>
  dmas: Array<string>
  cities: Array<City>
  zips: Array<Zip>
  ageRanges: string[]
  genders: string[]
  householdIncomes: string[]
  audiences: Array<ThirdPartyAudience>
  uploadedAudience?: string
}

const TvTargetingTab: React.FC<TvTargetingTabParams> = ({ campaign }) => {
  const opsClient = useOpsClient()
  const [initialValues, setInitialValues] = useState<FormValues>({
    locationType: 'USA',
    states: [],
    dmas: [],
    cities: [],
    zips: [],
    ageRanges: ['all'],
    genders: ['all'],
    householdIncomes: ['all'],
    audiences: []
  })
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)

  useEffect(() => {
    if (campaign) {
      opsClient?.getTvCommercialUpload(campaign.accountId).then(data => {
        const storageClient = new BlobServiceClient(data)
        const containerClient = storageClient.getContainerClient(campaign.accountId)
        setContainerClient(containerClient)
      })
    }
  }, [campaign])

  useEffect(() => {
    if (opsClient && campaign && campaign.tvTargeting) {
      setInitialValues({
        locationType: campaign.tvTargeting.locationType ?? 'USA',
        states: campaign.tvTargeting.states,
        dmas: campaign.tvTargeting.dmas,
        cities: campaign.tvTargeting.cities,
        zips: campaign.tvTargeting.zips,
        ageRanges: campaign.tvTargeting.ageRanges,
        genders: campaign.tvTargeting.genders,
        householdIncomes: campaign.tvTargeting.householdIncomes,
        audiences: campaign.tvTargeting.audiences,
        uploadedAudience: campaign.tvTargeting.uploadedAudienceUrl
      })
    }
  }, [opsClient, campaign])

  const advancedLocationSchema = Yup.object().shape({
    locationType: Yup.string().required(),
    states: Yup.array().when('locationType', {
      is: 'State',
      then: Yup.array().min(1, 'Please select at least 1 state').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    dmas: Yup.array().when('locationType', {
      is: 'DMA',
      then: Yup.array().min(1, 'Please select at least 1 DMA/metro area').of(Yup.string()),
      otherwise: Yup.array().of(Yup.string())
    }),
    cities: Yup.array().when('locationType', {
      is: 'City',
      then: Yup.array().min(1, 'Please add at least 1 city').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    zips: Yup.array().when('locationType', {
      is: 'Zip',
      then: Yup.array().min(1, 'Please add at least 1 zip').of(Yup.object()),
      otherwise: Yup.array().of(Yup.object())
    }),
    ageRanges: Yup.array().of(Yup.string()),
    genders: Yup.array().of(Yup.string()),
    householdIncomes: Yup.array().of(Yup.string()),
    audiences: Yup.array().of(Yup.object())
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: advancedLocationSchema,
    onSubmit: (values: FormValues) => {
      if (campaign) {
        opsClient
          ?.apiPutCampaignTvTargeting(campaign.campaignId, {
            locationType: values.locationType,
            states: values.states,
            dmas: values.dmas,
            cityIds: values.cities.map(c => c.cityId),
            zips: values.zips.map(c => c.zipId),
            audiences: values.audiences,
            ageRanges: values.ageRanges,
            genders: values.genders,
            householdIncomes: values.householdIncomes,
            uploadedAudienceUrl: values.uploadedAudience
          })
          .then(() => {
            formik.setSubmitting(false)
            formik.setStatus(null)
          })
          .catch(response => {
            if (response) {
              formik.setSubmitting(false)
              response.errors?.forEach(function (error: ApiError) {
                formik.setFieldError(error.name, error.message)
              })
            }
          })
      }
    }
  })

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      if (files[0].size > 500000) {
        return
      }
      const blockBlobClient = containerClient!.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())!
      const url = blockBlobClient!.url.split('?')[0]
      blockBlobClient!.uploadData(files[0], {
        concurrency: 20
      })
      setInitialValues({ ...initialValues, uploadedAudience: url })
    }
    if (files && files.length < 1) {
      setInitialValues({ ...initialValues, uploadedAudience: undefined })
    }
  }

  return (
    <React.Fragment>
      <EuiForm component='form' onSubmit={formik.handleSubmit} onChange={formik.handleChange} onBlur={formik.handleBlur}>
        <EuiTitle size='xs'>
          <span>Location Targeting</span>
        </EuiTitle>
        <EuiSpacer size='xs' />
        <EuiRadio
          id='usa'
          name='locationType'
          value='USA'
          label={
            <EuiText size='s'>
              <strong>USA</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'USA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'USA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='state'
          name='locationType'
          value='State'
          label={
            <EuiText size='s'>
              <strong>State</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'State'}
          onChange={() => {
            formik.setFieldValue('locationType', 'State', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='dma'
          name='locationType'
          value='DMA'
          label={
            <EuiText size='s'>
              <strong>DMA/Metro Area</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'DMA'}
          onChange={() => {
            formik.setFieldValue('locationType', 'DMA', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='city'
          name='locationType'
          value='City'
          label={
            <EuiText size='s'>
              <strong>City</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'City'}
          onChange={() => {
            formik.setFieldValue('locationType', 'City', true)
          }}
        />
        <EuiSpacer size='s' />
        <EuiRadio
          id='zip'
          name='locationType'
          value='Zip'
          label={
            <EuiText size='s'>
              <strong>Zip Code</strong>
            </EuiText>
          }
          checked={formik.values.locationType === 'Zip'}
          onChange={() => {
            formik.setFieldValue('locationType', 'Zip', true)
          }}
        />

        {formik.values.locationType === 'State' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a State' fullWidth isInvalid={!!formik.errors.states} error={formik.errors.states}>
              <StateFinder
                addState={stateToAdd => {
                  formik.setFieldValue('states', [...formik.values.states, stateToAdd])
                }}
                isInvalid={!!formik.errors.states}
              />
            </EuiFormRow>
            {formik.values.states && (
              <EuiFormRow label='Campaign will target these States:' fullWidth>
                <StateTargeter
                  states={formik.values.states}
                  onStateRemoved={stateToRemove =>
                    formik.setFieldValue(
                      'states',
                      formik.values.states.filter(x => x !== stateToRemove),
                      true
                    )
                  }
                />
              </EuiFormRow>
            )}
          </React.Fragment>
        )}

        {formik.values.locationType === 'DMA' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a DMA' fullWidth isInvalid={!!formik.errors.dmas} error={formik.errors.dmas}>
              <DmaFinder addDma={dmaToAdd => formik.setFieldValue('dmas', [...formik.values.dmas, dmaToAdd])} isInvalid={!!formik.errors.dmas} />
            </EuiFormRow>
            <EuiFormRow label='Campaign will target these DMAs:' fullWidth>
              <DmaTargeter
                dmas={formik.values.dmas}
                onDmaRemoved={dmaToRemove =>
                  formik.setFieldValue(
                    'dmas',
                    formik.values.dmas.filter(x => x !== dmaToRemove),
                    true
                  )
                }
              />
            </EuiFormRow>
          </React.Fragment>
        )}

        {formik.values.locationType === 'City' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a city' fullWidth isInvalid={!!formik.errors.cities} error={formik.errors.cities}>
              <CityFinder
                addCity={city => {
                  formik.setFieldValue('cities', [...formik.values.cities, city])
                }}
                isInvalid={!!formik.errors.cities}
              />
            </EuiFormRow>
            <EuiFormRow label='Campaign will target these cities:' fullWidth>
              <CityTargeter
                cities={formik.values.cities}
                onCityRemoved={city => {
                  formik.setFieldValue(
                    'cities',
                    formik.values.cities.filter(c => c.cityId !== city.cityId)
                  )
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}

        {formik.values.locationType === 'Zip' && (
          <React.Fragment>
            <EuiSpacer />
            <EuiFormRow label='Search for a zip code' fullWidth isInvalid={!!formik.errors.zips} error={formik.errors.zips}>
              <ZipFinder
                addZip={zip => {
                  formik.setFieldValue('zips', [...formik.values.zips, zip.zipId])
                }}
                isInvalid={!!formik.errors.zips}
              />
            </EuiFormRow>
            <EuiFormRow label='Campaign will target these zip codes:' fullWidth>
              <ZipTargeter
                zips={formik.values.zips}
                onZipRemoved={zip => {
                  formik.setFieldValue(
                    'zips',
                    formik.values.zips.filter(z => z.zipId !== zip.zipId).map(z => z.zipId)
                  )
                }}
              />
            </EuiFormRow>
          </React.Fragment>
        )}
        <EuiSpacer />

        <EuiTitle size='xs'>
          <span>Demographic Prioritization</span>
        </EuiTitle>
        <EuiSpacer size='xs' />
        <EuiFormRow label='Age ranges' fullWidth>
          <AgeRangeTargeter
            ageRanges={formik.values.ageRanges}
            setAgeRanges={ageRanges => {
              formik.setFieldValue('ageRanges', ageRanges, true)
            }}
          />
        </EuiFormRow>
        <EuiSpacer size='s' />
        <EuiFormRow label='Gender' fullWidth>
          <GenderTargeter
            genders={formik.values.genders}
            setGenders={genders => {
              formik.setFieldValue('genders', genders, true)
            }}
          />
        </EuiFormRow>

        <EuiSpacer size='s' />
        <EuiFormRow label='Household income' fullWidth>
          <HouseholdIncomeTargeter
            householdIncomes={formik.values.householdIncomes}
            setHouseholdIncomes={householdIncomes => {
              formik.setFieldValue('householdIncomes', householdIncomes, true)
            }}
          />
        </EuiFormRow>
        <EuiSpacer />

        <EuiTitle size='xs'>
          <h2>Traffic Prioritization</h2>
        </EuiTitle>
        <EuiSpacer size='xs' />
        <EuiFormRow label='Search for individual characteristics to refine ideal audience.' fullWidth>
          <TvAudienceFinder
            onAudienceClicked={audience => {
              formik.setFieldValue('audiences', [...formik.values.audiences, audience])
            }}
            isInvalid={false}
          />
        </EuiFormRow>
        {formik.values.audiences && formik.values.audiences.length > 0 && (
          <EuiFormRow label='Campaign will prioritize individuals in any of these categories:' fullWidth>
            <AudienceTargeterDetailed
              audiences={formik.values.audiences}
              onAudienceRemoved={audience => {
                formik.setFieldValue(
                  'audiences',
                  formik.values.audiences.filter(a => a.id !== audience.id)
                )
              }}
            />
          </EuiFormRow>
        )}

        <EuiSpacer />
        <EuiTitle size='xs'>
          <span>Audience Upload</span>
        </EuiTitle>
        {formik.values.uploadedAudience && (
          <EuiFormRow>
            <a href={formik.values.uploadedAudience} target={'_blank'}>
              Link
            </a>
          </EuiFormRow>
        )}
        <EuiFormRow label='Upload an Audience'>
          <EuiFilePicker
            id='file'
            initialPromptText='Select or drag and drop your text file'
            onChange={files => {
              onFileChange(files)
            }}
            display='large'
            aria-label='Select or drag and drop your text file'
          />
        </EuiFormRow>

        <EuiSpacer />
        <EuiButton id='save' fill type='submit' isLoading={formik.isSubmitting}>
          Save
        </EuiButton>
      </EuiForm>
    </React.Fragment>
  )
}

export default TvTargetingTab
