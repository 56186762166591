import Konva from 'konva'
import React, { useCallback, useEffect } from 'react'
import { Layer, Rect, Text } from 'react-konva'

import { IAdCreator } from 'components/AdCreation/AdCreators/IAdCreator'
import { ContentFocus } from 'components/AdCreation/DisplayMaker'
import { ACImage } from 'components/Basic/ACImage'
import { IsDark } from 'utils/colorUtils'

export const AdCreatorLargeBanner: React.FC<IAdCreator> = props => {
  const backgroundLayerRef = React.useRef<Konva.Layer>(null)
  const imageLayerRef = React.useRef<Konva.Layer>(null)
  const nameLayerRef = React.useRef<Konva.Layer>(null)

  const [baseWidth, setBaseWidth] = React.useState(0)
  const [baseHeight, setBaseHeight] = React.useState(0)

  const colorPalette = props.colorPalette

  useEffect(() => {
    const image = new Image()
    image.src = props.uploadedUrl
    image.onload = () => {
      setBaseWidth(image.width)
      setBaseHeight(image.height)
    }
  }, [props.uploadedUrl])

  const getScaledWidth = useCallback(
    (height: number) => {
      if (!baseHeight || baseHeight <= 0) {
        return 1
      }
      return (baseWidth / baseHeight) * height
    },
    [baseWidth, baseHeight]
  )

  return (
    <React.Fragment>
      <Layer name={'background'} ref={backgroundLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Rect x={0} y={0} width={props.adWidth} height={props.adHeight} fill={colorPalette.backgroundColor} />
      </Layer>
      <Layer name={'image'} ref={imageLayerRef} offsetX={props.xOffSet} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
        <Rect x={20} y={8} height={props.adHeight - 64} width={getScaledWidth(props.adHeight - 64)} fill={colorPalette.backgroundColor2} />
        <ACImage x={48} y={32} backgroundImageUrl={props.uploadedUrl} key={'backgroundImage'} height={props.adHeight - 32 - 24} width={getScaledWidth(props.adHeight - 32 - 24)} />
      </Layer>
      {(props.contentSpecifications.contentFocus === ContentFocus.CompanyName || props.contentSpecifications.contentFocus === ContentFocus.Image) && (
        <Layer name={'name'} ref={nameLayerRef} offsetX={(props.xOffSet ?? 0) - 80 - getScaledWidth(props.adHeight - 64 - 12)} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
          <Text x={32} y={12} text={props.details.headline} fontSize={48} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} height={144} width={props.adWidth - getScaledWidth(props.adHeight) - 32} fontStyle={'bold'} align={'left'} verticalAlign={'middle'} />
          <Text x={32} y={12 + 144 + 12} align={'left'} text={props.details.companyName} fontSize={28} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} height={props.adHeight - (12 + 144 + 12) - 12} width={(props.adWidth - getScaledWidth(props.adHeight) - 32) / 2} verticalAlign={'middle'} />
          <Rect cornerRadius={8} x={48 + (props.adWidth - getScaledWidth(props.adHeight) - 32) / 2} y={12 + 144 + 12} width={(props.adWidth - getScaledWidth(props.adHeight) - 16) / 2} height={props.adHeight - (12 + 144 + 12) - 12} fill={colorPalette.buttonColor + 'dd'} />
          <Text fontSize={34} text={props.details.callToAction} x={48 + (props.adWidth - getScaledWidth(props.adHeight) - 32) / 2} y={12 + 144 + 12} width={(props.adWidth - getScaledWidth(props.adHeight) - 16) / 2} height={props.adHeight - (12 + 144 + 12) - 12} fill={IsDark(colorPalette.buttonColor) ? '#FFF' : '#000'} align={'center'} verticalAlign={'middle'} />
        </Layer>
      )}
      {props.contentSpecifications.contentFocus === ContentFocus.Headline && (
        <Layer name={'name'} ref={nameLayerRef} offsetX={(props.xOffSet ?? 0) - 80 - getScaledWidth(props.adHeight - 64 - 12)} offsetY={props.yOffSet} scale={{ x: props.scale ?? 1, y: props.scale ?? 1 }}>
          <Text x={32} y={12} text={props.details.headline} fontSize={48} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} height={144} width={props.adWidth - getScaledWidth(props.adHeight) - 32} fontStyle={'bold'} align={'left'} verticalAlign={'middle'} />
          <Text x={32} y={12 + 144 + 12} align={'left'} text={props.details.companyName} fontSize={28} fill={IsDark(colorPalette.backgroundColor) ? '#FFF' : '#000'} height={props.adHeight - (12 + 144 + 12) - 12} width={(props.adWidth - getScaledWidth(props.adHeight) - 32) / 2} verticalAlign={'middle'} />
          <Rect cornerRadius={8} x={48 + (props.adWidth - getScaledWidth(props.adHeight) - 32) / 2} y={12 + 144 + 12} width={(props.adWidth - getScaledWidth(props.adHeight) - 16) / 2} height={props.adHeight - (12 + 144 + 12) - 12} fill={colorPalette.buttonColor + 'dd'} />
          <Text fontSize={34} text={props.details.callToAction} x={48 + (props.adWidth - getScaledWidth(props.adHeight) - 32) / 2} y={12 + 144 + 12} width={(props.adWidth - getScaledWidth(props.adHeight) - 16) / 2} height={props.adHeight - (12 + 144 + 12) - 12} fill={IsDark(colorPalette.buttonColor) ? '#FFF' : '#000'} align={'center'} verticalAlign={'middle'} />
        </Layer>
      )}
    </React.Fragment>
  )
}
