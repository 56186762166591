import React, { FC } from 'react'
import { useParams } from 'react-router'

import { EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody } from '@elastic/eui'

import { OpsManagerTabs } from 'features/opsManagerDashboard/OpsManagerTabs'

export interface IOpsManagerDashboardPage {
  tab?: string
}

export const OpsManagerDashboardPage: FC = () => {
  const { tab } = useParams<IOpsManagerDashboardPage>()

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <OpsManagerTabs currentTab={tab ?? ''} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}
