import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiButtonEmpty, EuiDatePicker, EuiFieldNumber, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiFormRow, EuiSpacer, EuiSwitch, EuiText, EuiTextArea, EuiTitle } from '@elastic/eui'

import { CampaignType } from 'api'
import { BundleCategory, TargetingPriority } from 'api/entities/Bundle'
import { SelfServicePackage, SelfServicePackageType, UpdateSelfServicePackageRequest } from 'api/entities/SelfServicePackage'
import { useUpdateSelfServicePackageMutation } from 'api/rtkQueryApi/opsApi/bundlesApi'
import { CampaignSlotDetailsPanel } from 'components/Bundles/CampaignSlotDetailsPanel'
import CreateCampaignSlotTypePanel from 'components/Bundles/CreateCampaignSlotTypePanel'
import { BundleCategoryPicker } from 'features/customers/bundles/BundleCategoryPicker'
import { TargetingPrioritySelect } from 'features/customers/bundles/TargetingPrioritySelect'

export interface SelfServicePackageDetailsFlyoutProps {
  selfServicePackage: SelfServicePackage
  isFlyoutOpen: boolean
  onCloseFlyout: () => void
}

const SelfServicePackageDetailsFlyout: FC<SelfServicePackageDetailsFlyoutProps> = ({ selfServicePackage, isFlyoutOpen, onCloseFlyout }) => {
  const [name, setName] = useState(selfServicePackage.name)
  const [isWatchListed, setIsWatchListed] = useState(selfServicePackage.isWatchListed)
  const [packageCategory, setPackageCategory] = useState(selfServicePackage.bundleCategory)
  const [targetingPriority, setTargetingPriority] = useState(selfServicePackage.targetingPriority)
  const [salesTitle, setSalesTitle] = useState(selfServicePackage.salesTitle)
  const [salesShortDescription, setSalesShortDescription] = useState(selfServicePackage.salesShortDescription)
  const [salesDescription, setSalesDescription] = useState(selfServicePackage.salesDescription)
  const [minimumAmount, setMinimumAmount] = useState(selfServicePackage.minimumAmount)
  const [maximumAmount, setMaximumAmount] = useState(selfServicePackage.maximumAmount)
  const [matchingPercentage, setMatchingPercentage] = useState(selfServicePackage.matchingPercentage ? selfServicePackage.matchingPercentage * 100 : undefined)
  const [expirationDate, setExpirationDate] = useState(selfServicePackage.expirationDate ? moment(selfServicePackage.expirationDate) : undefined)

  const [paidSlotCampaignTypes, setPaidSlotCampaignTypes] = useState(
    selfServicePackage.paidSlotCampaignTypes?.map(campaignType => {
      return {
        id: uuidv4(),
        campaignType: campaignType
      }
    })
  )

  const [matchingSlotCampaignTypes, setMatchingSlotCampaignTypes] = useState(
    selfServicePackage.matchingSlotCampaignTypes?.map(campaignType => {
      return {
        id: uuidv4(),
        campaignType: campaignType
      }
    })
  )

  const [updateSelfServicePackage, updateSelfServicePackageRequest] = useUpdateSelfServicePackageMutation()

  useEffect(() => {
    if (updateSelfServicePackageRequest.isError || updateSelfServicePackageRequest.isSuccess) {
      onCloseFlyout()
    }
  }, [updateSelfServicePackageRequest.isError, updateSelfServicePackageRequest.isSuccess])

  const onBundleCategoryChange = (category: BundleCategory) => {
    setPackageCategory(category)
  }

  const onTargetingPriorityChange = (priority: TargetingPriority) => {
    setTargetingPriority(priority)
  }

  const onAddPaidSlotClicked = () => {
    if (selfServicePackage.selfServicePackageType !== SelfServicePackageType.Specific) {
      setPaidSlotCampaignTypes([...paidSlotCampaignTypes, { id: uuidv4(), campaignType: CampaignType.TV }])
    }
  }

  const onAddBonusSlotClicked = () => {
    if (selfServicePackage.selfServicePackageType !== SelfServicePackageType.Specific) {
      setMatchingSlotCampaignTypes([...matchingSlotCampaignTypes, { id: uuidv4(), campaignType: CampaignType.Internet }])
    }
  }

  const onSaveClick = () => {
    const request = {
      bundleId: selfServicePackage.id,
      name: name,
      isWatchListed: isWatchListed,
      bundleCategory: packageCategory,
      targetingPriority: targetingPriority,
      salesTitle: salesTitle,
      salesShortDescription: salesShortDescription,
      salesDescription: salesDescription,
      minimumAmount: minimumAmount,
      maximumAmount: maximumAmount,
      matchingPercentage: matchingPercentage ? matchingPercentage / 100 : undefined,
      paidSlotCampaignTypes: paidSlotCampaignTypes?.map(slot => slot.campaignType),
      matchingSlotCampaignTypes: matchingSlotCampaignTypes?.map(slot => slot.campaignType),
      expirationDate: expirationDate
    } as UpdateSelfServicePackageRequest

    updateSelfServicePackage(request)
  }

  let flyout
  if (isFlyoutOpen) {
    flyout = (
      <EuiFlyout onClose={onCloseFlyout} size='s' aria-labelledby='flyoutLargeTitle' className='flyout' ownFocus={true}>
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h2>{selfServicePackage.name}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
          <EuiFormRow label='Name' fullWidth>
            <EuiFieldText
              fullWidth
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </EuiFormRow>

          <EuiFormRow fullWidth>
            <EuiSwitch label='Watchlisted' checked={isWatchListed} onChange={() => setIsWatchListed(!isWatchListed)} id={'isWatchListed'} />
          </EuiFormRow>

          <EuiFormRow label='Title' fullWidth>
            <EuiFieldText
              fullWidth
              value={salesTitle}
              onChange={e => {
                setSalesTitle(e.target.value)
              }}
            />
          </EuiFormRow>
          <EuiFormRow label='Description' fullWidth>
            <EuiTextArea
              fullWidth
              value={salesDescription}
              onChange={e => {
                setSalesDescription(e.target.value)
              }}
            />
          </EuiFormRow>
          <EuiFormRow label='Short Description' fullWidth>
            <EuiFieldText
              fullWidth
              value={salesShortDescription}
              onChange={e => {
                setSalesShortDescription(e.target.value)
              }}
            />
          </EuiFormRow>

          {selfServicePackage.selfServicePackageType === SelfServicePackageType.Percent && (
            <React.Fragment>
              <EuiFormRow label='Minimum Amount' fullWidth>
                <EuiFieldNumber
                  fullWidth
                  prepend={
                    <EuiText size='xs'>
                      <strong>$</strong>
                    </EuiText>
                  }
                  placeholder={'Minimum Amount'}
                  value={minimumAmount}
                  onChange={e => setMinimumAmount(parseInt(e.target.value))}
                />
              </EuiFormRow>
              <EuiFormRow label='Maximum Amount' fullWidth>
                <EuiFieldNumber
                  fullWidth
                  prepend={
                    <EuiText size='xs'>
                      <strong>$</strong>
                    </EuiText>
                  }
                  placeholder={'Maximum Amount'}
                  value={maximumAmount}
                  onChange={e => setMaximumAmount(parseInt(e.target.value))}
                />
              </EuiFormRow>
              <EuiFormRow label='Matching Percentage' fullWidth>
                <EuiFieldNumber
                  fullWidth
                  prepend={
                    <EuiText size='xs'>
                      <strong>%</strong>
                    </EuiText>
                  }
                  placeholder={'Matching Percentage'}
                  value={matchingPercentage}
                  onChange={e => setMatchingPercentage(parseInt(e.target.value))}
                />
              </EuiFormRow>
            </React.Fragment>
          )}

          <EuiFormRow fullWidth label='Expiration Date'>
            <EuiDatePicker fullWidth minDate={moment()} selected={expirationDate} onChange={date => setExpirationDate(date ?? undefined)} />
          </EuiFormRow>

          <EuiFormRow fullWidth label='Category'>
            <BundleCategoryPicker fullWidth bundleType={selfServicePackage.bundleType} onBadgeClick={onBundleCategoryChange} selectedKey={packageCategory} checkIsCSuite />
          </EuiFormRow>
          <EuiFormRow fullWidth label='Targeting'>
            <TargetingPrioritySelect fullWidth onChange={onTargetingPriorityChange} selectedKey={targetingPriority} checkIsCSuite />
          </EuiFormRow>

          <EuiSpacer />

          {selfServicePackage.selfServicePackageType === SelfServicePackageType.Percent && (
            <React.Fragment>
              <EuiTitle size={'xs'}>
                <h2>Paid Campaign Slots</h2>
              </EuiTitle>

              <EuiSpacer size='xs' />

              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty iconType={'plus'} onClick={onAddPaidSlotClicked}>
                    Add Paid Slot
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem />
              </EuiFlexGroup>

              <EuiSpacer size='xs' />

              {paidSlotCampaignTypes?.map(({ id, campaignType }) => {
                return (
                  <React.Fragment key={id}>
                    <CreateCampaignSlotTypePanel
                      campaignType={campaignType}
                      isBonusSlot={false}
                      onCampaignTypeUpdate={campaignType => {
                        setPaidSlotCampaignTypes(
                          paidSlotCampaignTypes?.map(slot => {
                            if (slot.id === id) {
                              slot.campaignType = campaignType
                            }
                            return slot
                          })
                        )
                      }}
                      onCancelClick={() => setPaidSlotCampaignTypes(paidSlotCampaignTypes.filter(slot => slot.id !== id))}
                    />

                    <EuiSpacer />
                  </React.Fragment>
                )
              })}

              <EuiTitle size={'xs'}>
                <h2>Matching Campaign Slots</h2>
              </EuiTitle>

              <EuiSpacer size='xs' />

              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty iconType={'plus'} onClick={onAddBonusSlotClicked}>
                    Add Matching Slot
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem />
              </EuiFlexGroup>

              <EuiSpacer size='xs' />

              {matchingSlotCampaignTypes?.map(({ id, campaignType }) => {
                return (
                  <React.Fragment key={id}>
                    <CreateCampaignSlotTypePanel
                      campaignType={campaignType}
                      isBonusSlot={true}
                      onCampaignTypeUpdate={campaignType => {
                        setMatchingSlotCampaignTypes(
                          matchingSlotCampaignTypes?.map(slot => {
                            if (slot.id === id) {
                              slot.campaignType = campaignType
                            }
                            return slot
                          })
                        )
                      }}
                      onCancelClick={() => setMatchingSlotCampaignTypes(matchingSlotCampaignTypes.filter(slot => slot.id !== id))}
                    />

                    <EuiSpacer />
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )}

          {selfServicePackage.selfServicePackageType !== SelfServicePackageType.Percent && (
            <React.Fragment>
              <EuiTitle size={'s'}>
                <h2>Campaign Slots</h2>
              </EuiTitle>
              <EuiSpacer />
              {selfServicePackage?.campaignSlots.map(slot => (
                <React.Fragment key={slot.id}>
                  <CampaignSlotDetailsPanel bundle={selfServicePackage} slot={slot} organizationId={selfServicePackage.organizationId} editable={!slot.isBonus && !slot.campaignId} />
                  <EuiSpacer />
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </EuiFlyoutBody>

        <EuiFlyoutFooter>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton onClick={onSaveClick} isLoading={updateSelfServicePackageRequest.isLoading}>
                Save
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  return <React.Fragment>{flyout}</React.Fragment>
}

export default SelfServicePackageDetailsFlyout
