export interface DomainList {
  domains: AcDomain[]
}

export interface AcDomain {
  domain: string
  isShowcase: boolean
  networkType: NetworkType
}

export enum NetworkType {
  NotSet = 'NotSet',
  Information = 'Information',
  Sports = 'Sports',
  Entertainment = 'Entertainment',
  Lifestyle = 'Lifestyle',
  News = 'News',
  Business = 'Business',
  Shopping = 'Shopping',
  Miscellaneous = 'Miscellaneous',
  General = 'General'
}
