import React from 'react'

import { Chart, PartialTheme, Partition, PartitionLayout, Settings } from '@elastic/charts'
import { EuiLoadingSpinner, EuiTitle } from '@elastic/eui'

import { AdvertisingSnapshot } from 'api/entities/AdvertisingSnapshot'
import { GetFillColor } from 'features/customers/organizations/Snapshot/AdvertisingSnapshotColorHelper'

export interface IAdvertisingSnapshotCitiesPieChart {
  isLoading?: boolean
  snapshot?: AdvertisingSnapshot
}

export const AdvertisingSnapshotCitiesPieChart: React.FC<IAdvertisingSnapshotCitiesPieChart> = props => {
  const [cityPercents, setCityPercents] = React.useState<{ city: string; impressions: number }[]>([])

  React.useEffect(() => {
    if (props.snapshot) {
      const topCities = [...props.snapshot.cityBuckets].sort((a, b) => b.impressions - a.impressions).slice(0, 8)
      const cityPercents = topCities.map(city => {
        return {
          city: city.name,
          impressions: city.impressions
        }
      })
      setCityPercents(cityPercents)
    } else {
      setCityPercents([])
    }
  }, [props.snapshot])

  const themeOverrides: PartialTheme = {
    partition: { outerSizeRatio: 1 }
  }
  return (
    <React.Fragment>
      <EuiTitle className='eui-textCenter' size='s'>
        <h3>Top Audience Locations</h3>
      </EuiTitle>
      {props.isLoading ? (
        <EuiLoadingSpinner size='l' />
      ) : (
        <Chart size={{ height: 240 }}>
          <Settings theme={[themeOverrides]} showLegend showLegendExtra />
          <Partition
            id='donutByLanguage'
            data={cityPercents}
            layout={PartitionLayout.sunburst}
            valueAccessor={d => Number(d.impressions)}
            valueFormatter={() => ''}
            layers={[
              {
                groupByRollup: (d: { city: string; impressions: number }) => d.city,
                shape: {
                  fillColor: (_, sortIndex) => GetFillColor(sortIndex)
                }
              }
            ]}
            clockwiseSectors={false}
          />
        </Chart>
      )}
    </React.Fragment>
  )
}
