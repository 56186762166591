import { Moment } from 'moment'

import { BaseBundle, CreateBundleRequest, UpdateBundleRequest } from 'api/entities/Bundle'
import { CampaignType } from 'api/entities/Campaign'

export interface SelfServicePackage extends BaseBundle {
  salesTitle: string
  salesShortDescription: string
  salesDescription: string
  selfServicePackageStatus: SelfServicePackageStatus
  selfServicePackageType: SelfServicePackageType
  matchingPercentage?: number
  minimumAmount?: number
  maximumAmount?: number
  paidSlotCampaignTypes: CampaignType[]
  matchingSlotCampaignTypes: CampaignType[]
  expirationDate?: Moment
}

export interface CreateSelfServicePackageRequest extends CreateBundleRequest {
  salesTitle?: string
  salesShortDescription?: string
  salesDescription?: string
  proposalText?: string
  selfServicePackageType: SelfServicePackageType
  matchingPercentage?: number
  minimumAmount?: number
  maximumAmount?: number
  paidSlotCampaignTypes: CampaignType[]
  matchingSlotCampaignTypes: CampaignType[]
  expirationDate?: Moment
}

export interface UpdateSelfServicePackageRequest extends UpdateBundleRequest {
  salesTitle?: string
  salesDescription?: string
  matchingPercentage?: number
  minimumAmount?: number
  maximumAmount?: number
  paidSlotCampaignTypes: CampaignType[]
  matchingSlotCampaignTypes: CampaignType[]
  expirationDate?: Moment
}

export enum SelfServicePackageStatus {
  Reserved = 'Reserved',
  Available = 'Available',
  Ready = 'Ready',
  Launching = 'Launching',
  Live = 'Live',
  EndingOn = 'EndingOn',
  Ended = 'Ended',
  Expired = 'Expired',
  PaymentFailed = 'PaymentFailed'
}

export enum SelfServicePackageType {
  Specific = 'Specific',
  Percent = 'Percent'
}

export const selfServicePackageTemplates = [
  {
    salesTitle: 'Free CTV Incentive Package',
    salesDescription: 'This package offers dollar-for-dollar matching on CTV. Connect any Internet campaign and the budget will be matched 100% on Connected TV.\n\nThis package also includes the creation of a 15 second commercial at no additional charge. AdCritter will create the commercial and you will be able to show it to your client. If it needs revisions, we will take care of that too.',
    matchingPercentage: 1,
    paidSlotCampaignTypes: [CampaignType.Internet],
    matchingSlotCampaignTypes: [CampaignType.TV]
  },
  {
    salesTitle: 'Digital Billboards with 50% Matching',
    salesDescription: 'Run a digital billboard campaign and get 50% matching on a corresponding Internet campaign to help your client gain exposure in multiple channels.\n\nThis is a great package for local businesses that want to reach the entire city.',
    matchingPercentage: 0.5,
    paidSlotCampaignTypes: [CampaignType.Billboard],
    matchingSlotCampaignTypes: [CampaignType.Internet]
  },
  {
    salesTitle: '50% off Geo-Targeting',
    salesDescription: 'Run a geo-targeted campaign at half the cost. Specify a specific area you want to run ads and you will get a second geo-targeting campaign for the same amount for free. You can target the same area or pick a new one.\n\nThis package includes the creation of up to 7 display ads in the most popular sizes.',
    matchingPercentage: 1,
    paidSlotCampaignTypes: [CampaignType.Internet],
    matchingSlotCampaignTypes: [CampaignType.Internet]
  },
  {
    salesTitle: 'Free Cross-Device Retargeting for Internet Campaigns',
    salesDescription: 'Get a free cross-device retargeting campaign by associating it with an Internet campaign of any type. For example, create an Audience Based Internet campaign and use this package to retarget people who saw the ad on all of their devices.\n\nThis package is also eligible for free ad designs upon request.',
    matchingPercentage: 1,
    paidSlotCampaignTypes: [CampaignType.Internet],
    matchingSlotCampaignTypes: [CampaignType.Internet]
  },
  {
    salesTitle: 'Free Cross-Device Retargeting for CTV Campaigns',
    salesDescription: 'Get a free cross-device retargeting campaign by associating it with a Connected TV campaign. For example, create an Audience Based TV campaign and use this package to show display ads on the other devices (phones, tablets, computers) to people who saw the TV commercial.\n\nThis package is also eligible for free ad designs upon request.',
    matchingPercentage: 1,
    paidSlotCampaignTypes: [CampaignType.TV],
    matchingSlotCampaignTypes: [CampaignType.Internet]
  }
] as Partial<CreateSelfServicePackageRequest>[]
