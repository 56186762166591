import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EuiButton, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle } from '@elastic/eui'

import { Invoice, IPayment } from 'api/entities/Invoice'
import { useSplitInvoiceMutation } from 'api/rtkQueryApi/opsApi/invoicesApi'
import { InvoiceSplitterContent } from 'components/Invoices/InvoiceSplitterContent'
import { localDate } from 'utils/dateUtils'

export interface IInvoiceSplitter {
  organizationId: string
  invoice: Invoice
  onCloseClicked?: () => void
}

export const InvoiceSplitterModal: React.FC<IInvoiceSplitter> = props => {
  const [payments, setPayments] = useState<IPayment[]>([])
  const [isValid, setIsValid] = useState(false)
  const [splitInvoice, splitInvoiceRequest] = useSplitInvoiceMutation()

  const amountToPay = props.invoice.amountDue
  const maxDate = moment(props.invoice.created).add(1, 'week') ?? moment()

  useEffect(() => {
    setIsValid(isValidSplit())
  }, [payments])

  const onAddPaymentClicked = () => {
    setPayments([...payments, { key: uuidv4(), amount: 500, payDate: moment().add(1, 'day') }])
  }

  const onPaymentAmountChanged = (value: number, payment: IPayment) => {
    setPayments(payments => {
      const clonePayments = [...payments]
      const foundItem = clonePayments.find(p => p.key === payment.key)
      if (foundItem) {
        foundItem.amount = value
      }
      return clonePayments
    })
  }

  const onPayDateChanged = (value: Moment, payment: IPayment) => {
    if (value) {
      setPayments(payments => {
        const clonePayments = [...payments]
        const foundItem = clonePayments.find(p => p.key === payment.key)
        if (foundItem) {
          foundItem.payDate = value
        }
        return clonePayments
      })
    }
  }

  const onDeletePayment = (payment: IPayment) => {
    setPayments(payments.filter(p => p.key !== payment.key))
  }

  const paymentIsValid = (payment: IPayment) => {
    return payment.amount > 99 && payment.payDate > moment().add(-1, 'day') && payment.payDate <= maxDate
  }

  const getInvalidText = (payment: IPayment) => {
    if (payment.amount < 100) {
      return 'Cannot process payments less than $100'
    }

    if (payment.payDate <= moment().add(-1, 'day')) {
      return 'Payment must occur in the future'
    }

    if (payment.payDate > maxDate) {
      return `Payment must occur before ${localDate(maxDate)}`
    }

    return undefined
  }

  const isValidSplit = () => {
    if (payments.reduce((partialSum, a) => partialSum + a.amount, 0) != amountToPay) {
      return false
    }

    return payments.every(p => paymentIsValid(p))
  }

  const onClose = () => {
    if (props.onCloseClicked) {
      props.onCloseClicked()
    }
  }

  const saveSplit = () => {
    splitInvoice({ organizationId: props.organizationId, invoiceId: props.invoice.id, invoicePayments: payments.map(p => ({ amount: p.amount, payDate: p.payDate })) }).then(() => {
      if (props.onCloseClicked) {
        props.onCloseClicked()
      }
    })
  }

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Split Payment</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <InvoiceSplitterContent payments={payments} amountToPay={amountToPay} maxDate={maxDate} onAddPaymentClicked={onAddPaymentClicked} getInvalidText={getInvalidText} onPaymentAmountChanged={onPaymentAmountChanged} onPayDateChanged={onPayDateChanged} onDeletePayment={onDeletePayment} />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={saveSplit} isDisabled={!isValid} isLoading={splitInvoiceRequest.isLoading} fill>
          Save
        </EuiButton>
        <EuiButton onClick={onClose}>Cancel</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}
