import React, { useEffect } from 'react'

import { EuiFilterButton, EuiFilterGroup } from '@elastic/eui'

import { insertSpaces } from 'utils/EnumToFriendly'

export interface IAcFilterGroup {
  items: any[]
  getGroup: (item: any) => string
  onFilterClicked?: (filter: string) => void
  onResetFilters?: () => void
  activeFilters: string[]
  hideCounts?: boolean
}

interface FilterGroupItem {
  filter: string
  items: any[]
}

export const AcFilterGroup: React.FC<IAcFilterGroup> = props => {
  const [filterGroup, setFilterGroup] = React.useState<FilterGroupItem[]>([])

  useEffect(() => {
    const itemsByType: FilterGroupItem[] = []
    props.items.forEach(item => {
      const filter = props.getGroup(item)
      const group = itemsByType.find((i: any) => i.filter === filter)
      if (!group) {
        itemsByType.push({
          filter: filter,
          items: [item]
        })
      } else {
        group.items.push(item)
      }
    })
    setFilterGroup(itemsByType)
  }, [props.items])

  const onFilterClick = (anomalyType: string) => {
    props.onFilterClicked?.(anomalyType)
  }

  const onTotalClick = () => {
    props.onResetFilters?.()
  }

  return (
    <EuiFilterGroup>
      <EuiFilterButton onClick={onTotalClick} hasActiveFilters={props.activeFilters.length === 0} numActiveFilters={props.hideCounts ? undefined : props.items.length} color='text'>
        Total
      </EuiFilterButton>
      {filterGroup.map((group, index) => (
        <EuiFilterButton
          key={index}
          hasActiveFilters={props.activeFilters.includes(group.filter)}
          onClick={() => {
            onFilterClick(group.filter)
          }}
          numActiveFilters={props.hideCounts ? undefined : group.items.length}
          color='text'>
          {insertSpaces(group.filter)}
        </EuiFilterButton>
      ))}
    </EuiFilterGroup>
  )
}
