import React from 'react'

import { EuiPageTemplate } from '@elastic/eui'

import { BundlesView } from 'api/rtkQueryApi/opsApi/bundlesApi'
import BundlesUI from 'features/customers/bundles/BundlesUI'

const BundlesPage: React.FC = () => {
  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='sqlApp' pageTitle='Monthly Recurring Plans & Packages' restrictWidth={false}></EuiPageTemplate.Header>
      <EuiPageTemplate.Section restrictWidth={false}>
        <BundlesUI view={BundlesView.BusinessDefined} />
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default BundlesPage
