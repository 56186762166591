import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBetaBadge, EuiFilterButton, EuiFilterGroup, EuiFlexGroup, EuiFlexItem, EuiLink, EuiPageTemplate, EuiPanel, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui'

import { PerformanceDashboardPackage } from 'api/entities/PerformanceDashboard'
import { useGetPerformanceDashboardPackagesQuery } from 'api/rtkQueryApi/opsApi/performanceDashboardApi'
import { RootState } from 'app/rootReducer'
import localStoreManagerService from 'services/LocalStoreManagerService'
import { currencyStyle, percentStyle } from 'utils/styles.'

import { PackageBadge } from './PackageBadge'
import { ProblemBadge } from './ProblemBadge'
import { TodayHealth } from './TodayHealth'

const PackageTab: React.FC = () => {
  const { data, isLoading, isFetching } = useGetPerformanceDashboardPackagesQuery()
  const [results, setResults] = useState<PerformanceDashboardPackage[]>()
  const { userProfile } = useSelector((state: RootState) => state.app)

  const [isEmptySlotFilterOn, setIsEmptySlotFilterOn] = useState(false)
  const [isNotDeliveringFilterOn, setIsNotDeliveringFilterOn] = useState(true)
  const [isSlowPacingFilterOn, setIsSlowPacingFilterOn] = useState(true)
  const [isNoTrafficTodayFilterOn, setIsNoTrafficTodayFilterOn] = useState(true)
  const [isSegmentMismatchedFilterOn, setIsSegmentMismatchedFilterOn] = useState(true)
  const [isBidderErrorFilterOn, setIsBidderErrorFilterOn] = useState(true)

  const [emptySlotCount, setEmptySlotCount] = useState(0)
  const [notDeliveringCount, setNotDeliveringCount] = useState(0)
  const [slowPacingCount, setSlowPacingCount] = useState(0)
  const [noTrafficCount, setNoTrafficCount] = useState(0)
  const [segmentMismatchedCount, setSegmentMismatchedCount] = useState(0)
  const [bidderErrorCount, setBidderErrorCount] = useState(0)

  const [clickedIds, setClickedIds] = useState<string[]>(localStoreManagerService.getDataObject<string[]>('clickedCampaigns') ?? [])

  useEffect(() => {
    let lastClicked = localStoreManagerService.getData('lastCampaigns')

    if (lastClicked) {
      let d = moment(lastClicked)
      if (d.isBefore(moment().utc().date())) {
        localStoreManagerService.deleteData('clickedCampaigns')
        setClickedIds([])
      }
    }
  }, [])

  useEffect(() => {
    localStoreManagerService.savePermanentData('clickedCampaigns', clickedIds)
    localStoreManagerService.savePermanentData('lastCampaigns', moment().utc().format('l'))
  }, [clickedIds])

  const toggleEmptySlotFilter = () => {
    setIsEmptySlotFilterOn(!isEmptySlotFilterOn)
  }

  const toggleNotDeliveringFilter = () => {
    setIsNotDeliveringFilterOn(!isNotDeliveringFilterOn)
  }

  const toggleSlowPacingFilter = () => {
    setIsSlowPacingFilterOn(!isSlowPacingFilterOn)
  }

  const toggleNoTrafficTodayFilter = () => {
    setIsNoTrafficTodayFilterOn(!isNoTrafficTodayFilterOn)
  }

  const toggleSegmentMismatchedFilter = () => {
    setIsSegmentMismatchedFilterOn(!isSegmentMismatchedFilterOn)
  }

  const toggleBidderErrorFilter = () => {
    setIsBidderErrorFilterOn(!isBidderErrorFilterOn)
  }

  useEffect(() => {
    if (data) {
      const emptySlot = data.filter(d => d.problems.includes('Empty Slot')).length
      setEmptySlotCount(emptySlot)

      const notDelivering = data.filter(d => d.problems.includes('Not Delivering')).length
      setNotDeliveringCount(notDelivering)

      const slowPacing = data.filter(d => d.problems.includes('Slow Pacing')).length
      setSlowPacingCount(slowPacing)

      const noTraffic = data.filter(d => d.problems.includes('No Traffic Today')).length
      setNoTrafficCount(noTraffic)

      const segmentMismatched = data.filter(d => d.problems.includes('Segment Mismatched')).length
      setSegmentMismatchedCount(segmentMismatched)

      const bidderError = data.filter(d => d.problems.includes('Bidder Error')).length
      setBidderErrorCount(bidderError)

      if ((!isEmptySlotFilterOn || emptySlot === 0) && (!isNotDeliveringFilterOn || notDelivering === 0) && (!isSlowPacingFilterOn || slowPacing === 0) && (!isNoTrafficTodayFilterOn || noTraffic === 0) && (!isSegmentMismatchedFilterOn || segmentMismatched === 0) && (!isBidderErrorFilterOn || bidderError === 0)) {
        setResults(data)
      } else {
        setResults(data.filter(d => (isEmptySlotFilterOn && d.problems.includes('Empty Slot')) || (isNotDeliveringFilterOn && d.problems.includes('Not Delivering')) || (isSlowPacingFilterOn && d.problems.includes('Slow Pacing')) || (isNoTrafficTodayFilterOn && d.problems.includes('No Traffic Today')) || (isSegmentMismatchedFilterOn && d.problems.includes('Segment Mismatched')) || (isBidderErrorFilterOn && d.problems.includes('Bidder Error'))))
      }
    }
  }, [data, isEmptySlotFilterOn, isNotDeliveringFilterOn, isSlowPacingFilterOn, isNoTrafficTodayFilterOn, isSegmentMismatchedFilterOn, isBidderErrorFilterOn])

  const gotoCampaign = (campaignId: string) => {
    setClickedIds([campaignId, ...clickedIds])
    window.open(`/customers/campaigns/${campaignId}`, '_blank')
  }

  return (
    <EuiPageTemplate.Section restrictWidth={false} bottomBorder={false} grow={true}>
      {!isLoading && !isFetching && results && (
        <React.Fragment>
          <EuiFilterGroup>
            <EuiFilterButton hasActiveFilters={isEmptySlotFilterOn} onClick={toggleEmptySlotFilter} numActiveFilters={emptySlotCount} isDisabled={emptySlotCount === 0} color='text'>
              Empty Slot
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isNotDeliveringFilterOn} onClick={toggleNotDeliveringFilter} numActiveFilters={notDeliveringCount} isDisabled={notDeliveringCount === 0} color='danger'>
              Not Delivering
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isSlowPacingFilterOn} onClick={toggleSlowPacingFilter} numActiveFilters={slowPacingCount} isDisabled={slowPacingCount === 0} color='warning'>
              Slow Pacing
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isNoTrafficTodayFilterOn} onClick={toggleNoTrafficTodayFilter} numActiveFilters={noTrafficCount} isDisabled={noTrafficCount === 0} color='primary'>
              No Traffic Today
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isSegmentMismatchedFilterOn} onClick={toggleSegmentMismatchedFilter} numActiveFilters={segmentMismatchedCount} isDisabled={segmentMismatchedCount === 0} color='primary'>
              Segment Mismatched
            </EuiFilterButton>
            <EuiFilterButton hasActiveFilters={isBidderErrorFilterOn} onClick={toggleBidderErrorFilter} numActiveFilters={bidderErrorCount} isDisabled={bidderErrorCount === 0} color='danger'>
              Bidder Error
            </EuiFilterButton>
          </EuiFilterGroup>
          <EuiSpacer />
          {results.map(pack => (
            <React.Fragment key={htmlIdGenerator()()}>
              <EuiPanel hasShadow={false} hasBorder={true} paddingSize='s'>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <div>
                      <strong>{pack.organizationName}</strong> <PackageBadge pack={pack} />
                    </div>
                    <div>
                      {pack.price.toLocaleString('en-US', currencyStyle)} {pack.priceDescription}
                    </div>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <div>
                      {pack.problems.map(p => (
                        <ProblemBadge problem={p} />
                      ))}
                    </div>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer size='s' />
                {pack.slotPerformances.map(slot => (
                  <React.Fragment key={htmlIdGenerator()()}>
                    <EuiText size='xs'>
                      <EuiPanel hasShadow={false} hasBorder={false} color='subdued' paddingSize='xs'>
                        <EuiFlexGroup alignItems='center'>
                          <EuiFlexItem grow={false} style={{ width: 50 }}>
                            {slot.slotType}
                          </EuiFlexItem>
                          <EuiFlexItem grow={false} style={{ width: 150 }}>
                            {slot.budget.toLocaleString('en-US', currencyStyle)} {slot.isBonus && 'matched'}
                          </EuiFlexItem>
                          {slot.campaignPerformance && (
                            <EuiFlexItem>
                              <EuiPanel hasShadow={false} hasBorder={false} color={clickedIds.some(id => slot.campaignPerformance.campaignId === id) ? 'success' : 'primary'} paddingSize='none'>
                                <EuiFlexGroup alignItems='center'>
                                  <EuiFlexItem grow={false} style={{ width: 150 }}>
                                    <EuiBetaBadge label={slot.campaignPerformance.campaignStatus} size='s' color='hollow' style={{ marginTop: 3, marginLeft: 3 }} />
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false} style={{ width: 100 }}>
                                    {slot.campaignPerformance.dsp} {slot.campaignPerformance.dspStatus}
                                  </EuiFlexItem>
                                  <EuiFlexItem>
                                    {moment(slot.campaignPerformance.flightStartDate!).utc().format('l')}-{moment(slot.campaignPerformance.flightEndDate!).utc().format('l')}
                                  </EuiFlexItem>
                                  <EuiFlexItem>
                                    {slot.campaignPerformance.flightSpend.toLocaleString('en-US', currencyStyle)} / {slot.campaignPerformance.flightBudget.toLocaleString('en-US', currencyStyle)}
                                  </EuiFlexItem>
                                  <EuiFlexItem>
                                    {slot.campaignPerformance.flightPacing.toLocaleString('en-US', percentStyle)} pacing {slot.campaignPerformance.previousFlightPacing > 0 && <React.Fragment>({slot.campaignPerformance.previousFlightPacing.toLocaleString('en-US', percentStyle)} previous)</React.Fragment>}
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false} style={{ width: 100 }}>
                                    {userProfile?.roles.includes('adcritter-ops-csuite') && slot.campaignPerformance.boost && <React.Fragment>{slot.campaignPerformance.boost.toLocaleString('en-US', currencyStyle)}</React.Fragment>}
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false} style={{ width: 20 }}>
                                    <TodayHealth campaign={slot.campaignPerformance} />
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false} style={{ width: 50 }}>
                                    <EuiLink onClick={() => gotoCampaign(slot.campaignPerformance.campaignId)}>view</EuiLink>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiPanel>
                            </EuiFlexItem>
                          )}
                          {!slot.campaignPerformance && <EuiFlexItem>&lt;slot empty&gt;</EuiFlexItem>}
                        </EuiFlexGroup>
                      </EuiPanel>
                    </EuiText>
                    <EuiSpacer size='xs' />
                  </React.Fragment>
                ))}
              </EuiPanel>
              <EuiSpacer size='s' />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
      {(isLoading || isFetching) && <EuiPageTemplate.EmptyPrompt restrictWidth={false} grow={true} hasShadow={false} hasBorder={false} iconType='advancedSettingsApp' title={<h2>Loading Packages Performance</h2>} body={<p>Please wait while numbers are retrieved and calculated.</p>} />}
    </EuiPageTemplate.Section>
  )
}
export default PackageTab
