import React from 'react'

import { EuiText } from '@elastic/eui'

export const getAdDisapproveOptions = () => {
  return [
    {
      value: 'Image quality issue(s)',
      inputDisplay: 'Image quality issue(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Image quality issue(s)</strong>
        </React.Fragment>
      )
    },
    {
      value: 'Image text issue(s)',
      inputDisplay: 'Image text issue(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Image text issue(s)</strong>
        </React.Fragment>
      )
    },
    {
      value: 'Landing page issue(s)',
      inputDisplay: 'Landing page issue(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Landing page issue(s)</strong>
        </React.Fragment>
      )
    },
    {
      value: 'Landing page requires Privacy Policy',
      inputDisplay: 'Landing page requires Privacy Policy',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Landing page requires Privacy Policy</strong>
          <EuiText size='s' color='subdued'>
            <p className='euiTextColor--subdued'>Landing pages that ask for any personally identifiable information must have a privacy policy.</p>
          </EuiText>
        </React.Fragment>
      )
    },
    {
      value: 'Company name mismatch',
      inputDisplay: 'Company name mismatch',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Company name mismatch</strong>
          <EuiText size='s' color='subdued'>
            <p className='euiTextColor--subdued'>The company name mentioned on the landing page must match exactly to the company name given in the ad.</p>
          </EuiText>
        </React.Fragment>
      )
    },
    {
      value: 'Ad text issue(s)',
      inputDisplay: 'Ad text issue(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Ad text issue(s)</strong>
          <EuiText size='s' color='subdued'>
            <p className='euiTextColor--subdued'>Spelling, formatting and/or casing issues in the headline, body or company name of the ad.</p>
          </EuiText>
        </React.Fragment>
      )
    },
    {
      value: 'Policy violation(s)',
      inputDisplay: 'Policy violation(s)',
      dropdownDisplay: (
        <React.Fragment>
          <strong>Policy violation(s)</strong>
          <EuiText size='s' color='subdued'>
            <p className='euiTextColor--subdued'>AdCritter cannot approve this ad as it violates our advertising policies.</p>
          </EuiText>
        </React.Fragment>
      )
    }
  ]
}
