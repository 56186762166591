import React, { useCallback, useEffect } from 'react'

import { EuiBadge, EuiButtonEmpty, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiInMemoryTable, EuiSpacer, EuiSwitch, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { CampaignType } from 'api'
import { SelfServicePackageType } from 'api/entities/SelfServicePackage'
import { SelfServicePackageTemplate } from 'api/entities/SelfServicePackageTemplate'
import { useGetSelfServicePackageTemplatesQuery } from 'api/rtkQueryApi/opsApi/bundleTemplatesApi'
import { CreateSelfServePackageTemplateFlyout } from 'features/templates/CreateSelfServicePackageTemplateFlyout'
import { EditSelfServePackageTemplateFlyout } from 'features/templates/EditSelfServicePackageTemplateFlyout'

export const SelfServicePackageTemplatesPage: React.FC = () => {
  const [packageSearchValue, setPackageSearchValue] = React.useState('')
  const [filteredBundleTemplates, setFilteredBundleTemplates] = React.useState<SelfServicePackageTemplate[]>([])
  const getTemplatesQuery = useGetSelfServicePackageTemplatesQuery()
  const [selectedTemplate, setSelectedTemplate] = React.useState<SelfServicePackageTemplate>()
  const [showEditTemplate, setShowEditTemplate] = React.useState<boolean>(false)
  const [showCreateTemplate, setShowCreateTemplate] = React.useState<boolean>(false)
  const [showArchived, setShowArchived] = React.useState<boolean>(false)

  const getFilteredTemplates = useCallback(() => {
    if (!getTemplatesQuery.data) {
      return []
    }
    let filteredTemplates = getTemplatesQuery.data
    if (!showArchived) {
      filteredTemplates = filteredTemplates.filter(t => !t.isArchived)
    }
    return filteredTemplates.filter(t => t.templateName.toLowerCase().includes(packageSearchValue.toLowerCase()) || t.salesTitle.toLowerCase().includes(packageSearchValue.toLowerCase()))
  }, [getTemplatesQuery.data, packageSearchValue, showArchived])

  useEffect(() => {
    if (!getTemplatesQuery.data) {
      return
    }
    setFilteredBundleTemplates(getFilteredTemplates())
  }, [getTemplatesQuery.data, packageSearchValue, showArchived])

  const onSearchChange = (search: string) => {
    setPackageSearchValue(search)
  }

  const onViewClick = (bundleTemplate: SelfServicePackageTemplate) => {
    setSelectedTemplate(bundleTemplate)
    setShowEditTemplate(true)
  }

  const onCreateTemplateClick = () => {
    setShowCreateTemplate(true)
  }

  const onDismissFlyout = () => {
    setSelectedTemplate(undefined)
    setShowEditTemplate(false)
    setShowCreateTemplate(false)
  }

  const columns: Array<EuiBasicTableColumn<SelfServicePackageTemplate>> = [
    {
      name: 'Template Name',
      field: 'templateName',
      sortable: true
    },
    {
      name: 'Title',
      field: 'salesTitle',
      sortable: true
    },
    {
      name: 'Package Type',
      field: 'selfServicePackageType',
      sortable: true
    },
    {
      name: 'Matching',
      render: (bundleTemplate: SelfServicePackageTemplate) => {
        return `${bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent ? bundleTemplate.matchingPercentage.toLocaleString() : ((bundleTemplate.campaignSlots.filter(s => s.isBonus).reduce((acc, s) => acc + s.budget, 0) / bundleTemplate.campaignSlots.filter(s => !s.isBonus).reduce((acc, s) => acc + s.budget, 0)) * 100).toLocaleString()}%`
      },
      sortable: (bundleTemplate: SelfServicePackageTemplate) => (bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent ? bundleTemplate.matchingPercentage : bundleTemplate.campaignSlots.filter(s => s.isBonus).reduce((acc, s) => acc + s.budget, 0) / bundleTemplate.campaignSlots.filter(s => !s.isBonus).reduce((acc, s) => acc + s.budget, 0)) * 100
    },
    {
      name: 'Min',
      render: (bundleTemplate: SelfServicePackageTemplate) => {
        return `$${
          bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent
            ? bundleTemplate.minimumAmount.toLocaleString()
            : bundleTemplate.campaignSlots
                .filter(s => !s.isBonus)
                .reduce((acc, s) => acc + s.budget, 0)
                .toLocaleString()
        }`
      },
      sortable: (bundleTemplate: SelfServicePackageTemplate) => (bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent ? bundleTemplate.minimumAmount : bundleTemplate.campaignSlots.filter(s => !s.isBonus).reduce((acc, s) => acc + s.budget, 0))
    },
    {
      name: 'Max',
      render: (bundleTemplate: SelfServicePackageTemplate) => {
        return `$${
          bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent
            ? bundleTemplate.maximumAmount.toLocaleString()
            : bundleTemplate.campaignSlots
                .filter(s => !s.isBonus)
                .reduce((acc, s) => acc + s.budget, 0)
                .toLocaleString()
        }`
      },
      sortable: (bundleTemplate: SelfServicePackageTemplate) => (bundleTemplate.selfServicePackageType === SelfServicePackageType.Percent ? bundleTemplate.maximumAmount : bundleTemplate.campaignSlots.filter(s => !s.isBonus).reduce((acc, s) => acc + s.budget, 0))
    },
    {
      name: 'Paid Slots',
      render: (bundleTemplate: SelfServicePackageTemplate) => {
        const internetSlots = bundleTemplate.campaignSlots.filter(s => s.campaignType === CampaignType.Internet && !s.isBonus).length
        const tvSlots = bundleTemplate.campaignSlots.filter(s => s.campaignType === CampaignType.TV && !s.isBonus).length
        const bbSlots = bundleTemplate.campaignSlots.filter(s => s.campaignType === CampaignType.Billboard && !s.isBonus).length

        return (
          <EuiFlexGroup direction={'column'}>
            {internetSlots > 0 && (
              <EuiFlexItem grow={false}>
                <EuiBadge>{internetSlots} x Internet</EuiBadge>
              </EuiFlexItem>
            )}
            {tvSlots > 0 && (
              <EuiFlexItem grow={false}>
                <EuiBadge>{tvSlots} x TV</EuiBadge>
              </EuiFlexItem>
            )}
            {bbSlots > 0 && (
              <EuiFlexItem grow={false}>
                <EuiBadge>{bbSlots} x BB</EuiBadge>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        )
      }
    },
    {
      name: 'Bonus Slots',
      render: (bundleTemplate: SelfServicePackageTemplate) => {
        const internetSlots = bundleTemplate.campaignSlots.filter(s => s.campaignType === CampaignType.Internet && s.isBonus).length
        const tvSlots = bundleTemplate.campaignSlots.filter(s => s.campaignType === CampaignType.TV && s.isBonus).length
        const bbSlots = bundleTemplate.campaignSlots.filter(s => s.campaignType === CampaignType.Billboard && s.isBonus).length
        return (
          <EuiFlexGroup direction={'column'} gutterSize={'xs'}>
            {internetSlots > 0 && (
              <EuiFlexItem grow={false}>
                <EuiBadge>{internetSlots} x Internet</EuiBadge>
              </EuiFlexItem>
            )}
            {tvSlots > 0 && (
              <EuiFlexItem grow={false}>
                <EuiBadge>{tvSlots} x TV</EuiBadge>
              </EuiFlexItem>
            )}
            {bbSlots > 0 && (
              <EuiFlexItem grow={false}>
                <EuiBadge>{bbSlots} x BB</EuiBadge>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        )
      }
    },
    {
      name: ' ',
      render: (bundleTemplate: SelfServicePackageTemplate) => {
        return (
          <EuiButtonEmpty
            onClick={() => {
              onViewClick(bundleTemplate)
            }}>
            View
          </EuiButtonEmpty>
        )
      }
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'agencyDetails.lastContacted',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiFormRow>
        <EuiButtonEmpty iconType={'plus'} onClick={onCreateTemplateClick}>
          Create Template
        </EuiButtonEmpty>
      </EuiFormRow>
      <EuiFormRow fullWidth>
        <EuiFlexGroup wrap>
          <EuiFlexItem grow={false}>
            <EuiFieldSearch style={{ minWidth: 300 }} placeholder='Search' value={packageSearchValue} onChange={e => onSearchChange(e.target.value)} isClearable={true} aria-label='Filter Agencies' />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ paddingTop: 10 }}>
            <EuiSwitch label={'Show Archived'} checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiSpacer />
      <EuiInMemoryTable loading={getTemplatesQuery.isLoading} items={filteredBundleTemplates} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
      {showCreateTemplate && <CreateSelfServePackageTemplateFlyout onCloseFlyout={onDismissFlyout} onCreated={onDismissFlyout} />}
      {selectedTemplate && showEditTemplate && <EditSelfServePackageTemplateFlyout bundleTemplateId={selectedTemplate.id} onCloseFlyout={onDismissFlyout} onUpdated={onDismissFlyout} />}
    </React.Fragment>
  )
}
