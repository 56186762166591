import React from 'react'
import { useParams } from 'react-router'

import { EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody } from '@elastic/eui'

import AdvisorDashboardTabs from 'features/advisorDashboard/AdvisorDashboardTabs'
import history from 'services/HistoryService'

interface AdvisorDashboardPageParams {
  tab?: string
}

const AdvisorDashboardPage: React.FC = () => {
  const { tab } = useParams<AdvisorDashboardPageParams>()

  if (!tab) {
    history.push('/advisor/dashboard/account')
    return <React.Fragment></React.Fragment>
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiPageContentBody>
            <AdvisorDashboardTabs currentTab={tab} />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default AdvisorDashboardPage
