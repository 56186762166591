import { useGraphClient } from 'graph/GraphClientContext'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { EuiFieldSearch, EuiInMemoryTable, EuiLink, EuiPageTemplate, EuiSpacer, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { AadUser, Partner, useOpsClient } from 'api'
import useDebounce from 'utils/useDebounce'

interface OrganizationSummary {
  organizationId: string
  organizationName: string
  billingEmail: string
  phoneNumber: string | null
  status: string
  isAgency: boolean
  adConsultant: string | null
  campaignAdvisor: string | null
  partner: Partner | null
  partnerId: string | null
  created: moment.Moment
}

const OrganizationsPage: React.FC = () => {
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false)
  const [organizations, setOrganizations] = useState<OrganizationSummary[]>([])
  const [isLoadingAdConsultants, setIsLoadingAdConsultants] = useState(true)
  const [adConsultants, setAdConsultants] = useState<AadUser[]>([])
  const [isLoadingCampaignAdvisors, setIsLoadingCampaignAdvisors] = useState(true)
  const [campaignAdvisors, setCampaignAdvisors] = useState<AadUser[]>([])
  const [isLoadingPartners, setIsLoadingPartners] = useState(true)
  const [partners, setPartners] = useState<Partner[]>([])
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const opsClient = useOpsClient()
  const graphClient = useGraphClient()

  useEffect(() => {
    let isMounted = true

    if (opsClient && debouncedSearch && isMounted && !isLoadingCampaignAdvisors && !isLoadingCampaignAdvisors) {
      setIsLoadingOrganizations(true)

      opsClient.getOrganizations(debouncedSearch).then(o => {
        if (isMounted) {
          setOrganizations(
            o.map(
              x =>
                ({
                  organizationId: x.organizationId,
                  organizationName: x.organizationName,
                  billingEmail: x.billingEmail,
                  phoneNumber: x.phoneNumber,
                  isAgency: x.isAgency,
                  adConsultant: x.adConsultantId ? adConsultants.find(a => a.mail === x.adConsultantId)?.displayName : '',
                  campaignAdvisor: x.campaignAdvisorId ? campaignAdvisors.find(a => a.mail === x.campaignAdvisorId)?.displayName : '',
                  partner: x.partnerId ? partners.find(p => p.partnerId === x.partnerId) : null,
                  partnerId: x.partnerId,
                  created: x.created
                } as OrganizationSummary)
            )
          )
          setIsLoadingOrganizations(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient, debouncedSearch, isLoadingCampaignAdvisors, isLoadingAdConsultants, adConsultants, campaignAdvisors, partners])

  useEffect(() => {
    let isMounted = true

    if (graphClient && isMounted) {
      graphClient.getAdConsultants().then(a => {
        if (isMounted) {
          setAdConsultants(a)
          setIsLoadingAdConsultants(false)
        }
      })
      graphClient.getCampaignAdvisors().then(a => {
        if (isMounted) {
          setCampaignAdvisors(a)
          setIsLoadingCampaignAdvisors(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [graphClient])

  useEffect(() => {
    let isMounted = true
    if (opsClient && isMounted) {
      setIsLoadingPartners(true)

      opsClient.getPartners().then(a => {
        if (isMounted) {
          setPartners(a)
          setIsLoadingPartners(false)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<OrganizationSummary>> = [
    {
      name: 'Organization',
      render: (o: OrganizationSummary) => (
        <EuiLink href={`/customers/organizations/${o.organizationId}`} target='_blank'>
          {o.organizationName}
        </EuiLink>
      )
    },
    {
      name: 'Status',
      field: 'status'
    },
    {
      name: 'Partner',
      render: (o: OrganizationSummary) => (o.partner ? `${o.partner?.name} (${o.partner.group})` : ''),
      sortable: (o: OrganizationSummary) => o.partner?.name
    },
    {
      name: 'Agency Platform',
      field: 'isAgency'
    },
    {
      name: 'Ad Consultant',
      field: 'adConsultant'
    },
    {
      name: 'Campaign Manager',
      field: 'campaignAdvisor'
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'organizationName',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const onSearchChange = (hint: string) => {
    setSearch(hint)
  }

  return (
    <EuiPageTemplate>
      <EuiPageTemplate.Header iconType='savedObjectsApp' pageTitle='Organizations' restrictWidth={false} />
      <EuiPageTemplate.Section restrictWidth={false}>
        <EuiFieldSearch fullWidth placeholder='Please start by searching for an organization.' value={search} isLoading={isLoadingOrganizations} onChange={v => onSearchChange(v.target.value)} isClearable={true} incremental={true} />
        <EuiSpacer />
        {organizations && organizations.length > 0 && <EuiInMemoryTable loading={isLoadingOrganizations || isLoadingAdConsultants || isLoadingCampaignAdvisors || isLoadingPartners} items={organizations} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />}
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  )
}

export default OrganizationsPage
