import React, { FC } from 'react'

import { EuiFilterButton } from '@elastic/eui'
import { CustomComponentProps } from '@elastic/eui/src/components/search_bar/filters/custom_component_filter'

export enum CustomFilterIgnoreAgenciesEnum {
  IgnoreAgencies = 'IgnoreAgencies',
  IncludeAgencies = 'IncludeAgencies',
  JustAgencies = 'JustAgencies'
}

export interface CustomFilterIncludeAgenciesProps extends CustomComponentProps {
  customFilter: CustomFilterIgnoreAgenciesEnum
  onClickCustomFilter: (filter: CustomFilterIgnoreAgenciesEnum) => void
}

export const CustomFilterIncludeAgencies: FC<CustomFilterIncludeAgenciesProps> = ({ customFilter, onClickCustomFilter }) => {
  const onIgnoreAgenciesClick = () => {
    onClickCustomFilter(CustomFilterIgnoreAgenciesEnum.IgnoreAgencies)
  }

  const onIncludeAgenciesClick = () => {
    if (customFilter === CustomFilterIgnoreAgenciesEnum.IncludeAgencies) {
      onClickCustomFilter(CustomFilterIgnoreAgenciesEnum.IgnoreAgencies)
    } else {
      onClickCustomFilter(CustomFilterIgnoreAgenciesEnum.IncludeAgencies)
    }
  }

  const onJustAgenciesClick = () => {
    if (customFilter === CustomFilterIgnoreAgenciesEnum.JustAgencies) {
      onClickCustomFilter(CustomFilterIgnoreAgenciesEnum.IgnoreAgencies)
    } else {
      onClickCustomFilter(CustomFilterIgnoreAgenciesEnum.JustAgencies)
    }
  }

  return (
    <React.Fragment>
      <EuiFilterButton hasActiveFilters={customFilter === CustomFilterIgnoreAgenciesEnum.IgnoreAgencies} onClick={onIgnoreAgenciesClick} withNext={true}>
        Ignore Agencies
      </EuiFilterButton>
      <EuiFilterButton hasActiveFilters={customFilter === CustomFilterIgnoreAgenciesEnum.IncludeAgencies} onClick={onIncludeAgenciesClick} withNext={true}>
        Include Agencies
      </EuiFilterButton>
      <EuiFilterButton hasActiveFilters={customFilter === CustomFilterIgnoreAgenciesEnum.JustAgencies} onClick={onJustAgenciesClick}>
        Just Agencies
      </EuiFilterButton>
    </React.Fragment>
  )
}
