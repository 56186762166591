import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiBreadcrumbs, EuiInMemoryTable, EuiLink, EuiPage, EuiPageBody, EuiPageContent_Deprecated as EuiPageContent, EuiPageContentBody_Deprecated as EuiPageContentBody, EuiPageContentHeader_Deprecated as EuiPageContentHeader, EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection, EuiSpacer, EuiTitle, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { FieldValueOptionType } from '@elastic/eui/src/components/search_bar/filters/field_value_selection_filter'

import { TvCommercial, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'

import TvCommercialCreateFlyOut from './TvCommercialCreateFlyOut'

const TvCommercialsPage: React.FC = () => {
  const [isLoadingTvCommercials, setIsLoadingTvCommercials] = useState(false)
  const [tvCommercials, setTvCommercials] = useState<TvCommercial[]>([])
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canCreate, setCanCreate] = useState(false)

  useEffect(() => {
    if (userProfile) {
      setCanCreate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    setIsLoadingTvCommercials(true)
    opsClient?.getTvCommercials().then(c => {
      setTvCommercials(c)
      setIsLoadingTvCommercials(false)
    })
  }, [opsClient])

  const columns: Array<EuiBasicTableColumn<TvCommercial>> = [
    {
      name: 'Name',
      render: (c: TvCommercial) => <EuiLink onClick={() => history.push(`/assets/tvcommercials/${c.id}`)}>{c.name}</EuiLink>
    },
    {
      name: `Type`,
      render: (c: TvCommercial) => c.type
    },
    {
      name: `Length`,
      render: (c: TvCommercial) => c.kind
    },
    {
      name: `Status`,
      render: (c: TvCommercial) => c.status
    }
  ]

  const sorting = {
    allowNeutralSort: true,
    enableAllColumns: false,
    sort: {
      field: 'name',
      direction: SortDirection.ASC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const loadStatus = () => {
    return new Promise<FieldValueOptionType[]>(resolve => {
      resolve(
        [...new Set(tvCommercials.map(c => c.status))].map(s => ({
          value: s,
          view: s
        }))
      )
    })
  }

  const loadType = () => {
    return new Promise<FieldValueOptionType[]>(resolve => {
      resolve(
        [...new Set(tvCommercials.map(c => c.type))].map(s => ({
          value: s,
          view: s
        }))
      )
    })
  }

  return (
    <EuiPage>
      <EuiPageBody component='div'>
        <EuiPageContent>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: 'Assets' },
              {
                text: 'TV Commercials',
                href: '/assets/tvcommercials',
                onClick: () => history.push('/assets/tvcommercials')
              }
            ]}
          />
          <EuiPageContentHeader>
            <EuiPageContentHeaderSection>
              <EuiSpacer />
              <EuiTitle size='s'>
                <h2>TV Commercials</h2>
              </EuiTitle>
            </EuiPageContentHeaderSection>
            <EuiPageContentHeaderSection>{canCreate && <TvCommercialCreateFlyOut />}</EuiPageContentHeaderSection>
          </EuiPageContentHeader>
          <EuiPageContentBody>
            <EuiInMemoryTable
              loading={isLoadingTvCommercials}
              items={tvCommercials}
              columns={columns}
              sorting={sorting}
              pagination={pagination}
              tableLayout='fixed'
              search={{
                box: {
                  incremental: true
                },
                filters: [
                  {
                    type: 'field_value_selection',
                    field: 'type',
                    name: 'Type',
                    multiSelect: 'or',
                    operator: 'exact',
                    options: () => loadType()
                  },
                  {
                    type: 'field_value_selection',
                    field: 'status',
                    name: 'Status',
                    multiSelect: 'or',
                    operator: 'exact',
                    options: () => loadStatus()
                  }
                ]
              }}
            />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  )
}

export default TvCommercialsPage
