import moment from 'moment'
import React from 'react'

import { Axis, Chart, CurveType, LineSeries, PartialTheme, Settings } from '@elastic/charts'
import { EuiFlexGroup, EuiFlexItem, EuiIcon, euiPaletteForLightBackground, EuiPanel, EuiSpacer, EuiStat, EuiTitle, EuiToolTip, useEuiBackgroundColor } from '@elastic/eui'
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme'

import { Channel, timespanKeyCampaign, timespanSumKeyCampaign } from 'api/entities/ExecutiveDashboard'
import { useGetExecutiveDashboardCampaignQuery, useGetExecutiveDashboardCampaignSumQuery } from 'api/rtkQueryApi/opsApi/newExecutiveDashboardApi'

import ExecutiveDashboardCampaignReportChangeFlyOut from '../ExecutiveDashboardCampaignReportChangeFlyout'
import ExecutiveDashboardCampaignReportFlyOut from '../ExecutiveDashboardCampaignReportFlyout'

export interface CampaignChannelOverviewPanelProps {
  channel: Channel
  forDate: string
  timespan: string
}

const currencyStyle = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }
const CampaignChannelOverviewPanel: React.FC<CampaignChannelOverviewPanelProps> = ({ channel, forDate, timespan }) => {
  const { data: totals } = useGetExecutiveDashboardCampaignQuery({ channel, forDate })
  const { data: historicalTotals } = useGetExecutiveDashboardCampaignSumQuery({ channel, daysOfHistory: 7, forDate })

  const dashboardBaseTheme: PartialTheme = {
    crosshair: {
      band: {
        visible: false
      }
    },
    lineSeriesStyle: {
      point: {
        radius: 2,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    highlighter: {
      point: {
        radius: 4,
        opacity: 100,
        fill: '__use__series__color__'
      }
    },
    axes: {
      gridLine: {
        vertical: {
          visible: false
        },
        horizontal: {
          visible: false
        }
      }
    }
  }
  const blueBackground: PartialTheme = {
    background: {
      color: useEuiBackgroundColor('primary')
    }
  }
  const theme = [dashboardBaseTheme, blueBackground, EUI_CHARTS_THEME_LIGHT.theme]

  return (
    <EuiPanel hasShadow={false} color='primary'>
      {totals && historicalTotals && (
        <React.Fragment>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiTitle size='xs'>
                <h1>
                  {channel} Campaigns
                  {channel === Channel.All && (
                    <EuiToolTip position='bottom' content='Activated=true AND CampaignActiveStatus=Active AND IsMatching=false AND FirstViewDate IS NOT NULL'>
                      <EuiIcon type='asterisk' color='success' title='Explanation' />
                    </EuiToolTip>
                  )}
                </h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={null} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='m' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat description='Active Campaigns Count' title={totals.totalActive} titleSize='xs' />
            </EuiFlexItem>
            <EuiFlexItem>
              <Chart size={{ height: 40 }}>
                <Settings theme={theme} showLegend={false} />
                <Axis
                  id='number'
                  groupId='number'
                  domain={{
                    min: Math.min(...historicalTotals.map(x => x.totalActiveCampaigns)) * 0.95,
                    max: Math.max(...historicalTotals.map(x => x.totalActiveCampaigns)) * 1.05
                  }}
                  hide={true}
                />
                <LineSeries id='totalActiveCampaigns' name='Active Campaigns' data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y.totalActiveCampaigns]} color={[euiPaletteForLightBackground()[1]]} groupId='number' curve={CurveType.CURVE_MONOTONE_X} />
              </Chart>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xs' />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat description={`Current ${timespan} Revenue`} title={totals.totalRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle)} titleSize='xs' />
            </EuiFlexItem>
            <EuiFlexItem>
              <Chart size={{ height: 40 }}>
                <Settings theme={theme} showLegend={false} />
                <Axis
                  id='number'
                  groupId='number'
                  domain={{
                    min: Math.min(...historicalTotals.map(x => x[`total${timespan}Revenue` as timespanSumKeyCampaign])) * 0.95,
                    max: Math.max(...historicalTotals.map(x => x[`total${timespan}Revenue` as timespanSumKeyCampaign])) * 1.05
                  }}
                  hide={true}
                />
                <LineSeries id='totalRevenue' name={`${timespan} Revenue`} data={historicalTotals} xAccessor={x => moment(x.forDate).utc().format('YYYY-MM-DD')} yAccessors={[y => y[`total${timespan}Revenue` as timespanSumKeyCampaign]]} color={[euiPaletteForLightBackground()[0]]} groupId='number' curve={CurveType.CURVE_MONOTONE_X} tickFormat={d => `${Number(d).toLocaleString('en-US', currencyStyle)}`} />
              </Chart>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xs' />
          <EuiPanel color='transparent' hasBorder={true}>
            <EuiTitle size='xxs'>
              <h1>Initial Activations</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem grow={2}>
                <EuiStat description={`MTD`} title={totals.totalMtdInitialActivations} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiStat description={`Previous`} title={totals.totalPreviousMtdInitialActivations} titleSize='xs' />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='xs' />
            <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.mtdInitialActivationsCampaignIds} />
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel color='transparent' hasBorder={true}>
            <EuiTitle size='xxs'>
              <h1>Activations</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem grow={2}>
                <EuiStat description={`MTD`} title={totals.totalMtdActivations} titleSize='xs' />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiStat description={`Previous`} title={totals.totalPreviousMtdActivations} titleSize='xs' />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='xs' />
            <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.mtdActivationsCampaignIds} />
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>
                Weekly Ongoing
                {channel === Channel.All && (
                  <EuiToolTip position='bottom' content='Plan IS NULL AND BudgetFrequency=Weekly'>
                    <EuiIcon type='asterisk' title='Explanation' color='success' size='s' />
                  </EuiToolTip>
                )}
              </h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description='Total' title={(totals.weeklyOngoingRecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign] + totals.weeklyOngoingNonrecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign]).toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.weeklyOngoingRecurringRevenue.campaignIds.concat(totals.weeklyOngoingNonrecurringRevenue.campaignIds)} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Recurring' title={totals.weeklyOngoingRecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.weeklyOngoingRecurringRevenue.campaignIds} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Non-recurring' title={totals.weeklyOngoingNonrecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.weeklyOngoingNonrecurringRevenue.campaignIds} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>
                Fixed Duration
                {channel === Channel.All && (
                  <EuiToolTip position='bottom' content='Plan IS NULL AND BudgetFrequency=Total'>
                    <EuiIcon type='asterisk' title='Explanation' color='success' size='s' />
                  </EuiToolTip>
                )}
              </h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description='Total' title={totals.fixedDurationNonrecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.fixedDurationNonrecurringRevenue.campaignIds} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='s' />
          <EuiPanel hasShadow={false} color='plain'>
            <EuiTitle size='xxs'>
              <h1>Other</h1>
            </EuiTitle>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiStat description='Total' title={(totals.otherRecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign] + totals.otherNonrecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign]).toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.otherRecurringRevenue.campaignIds.concat(totals.otherNonrecurringRevenue.campaignIds)} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Recurring' title={totals.otherRecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.otherRecurringRevenue.campaignIds} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiStat description='Non-recurring' title={totals.otherNonrecurringRevenue[timespan.toLowerCase() as timespanKeyCampaign].toLocaleString('en-US', currencyStyle)} titleSize='xxxs' />
                <ExecutiveDashboardCampaignReportFlyOut channel={channel} forDate={forDate} campaignIds={totals.otherNonrecurringRevenue.campaignIds} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
          <EuiSpacer size='m' />
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false} />
            <EuiFlexItem grow={false}>
              <ExecutiveDashboardCampaignReportChangeFlyOut forDate={forDate} channel={channel} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
    </EuiPanel>
  )
}
export default CampaignChannelOverviewPanel
