import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiLoadingContent, EuiSpacer, EuiFlexItem, EuiFlexGrid, EuiImage, EuiFlexGroup, EuiButton, EuiOverlayMask, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiButtonEmpty, EuiText } from '@elastic/eui'

import { AcImage, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'

import BusinessTypeCategoryImageAddFlyOut from './BusinessTypeCategoryImageAddFlyOut'

interface BusinessTypeCategoryImagesTabParams {
  businessTypeCategoryId: string
}

const BusinessTypeCategoryImagesTab: React.FC<BusinessTypeCategoryImagesTabParams> = ({ businessTypeCategoryId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState<Array<AcImage>>([])
  const [selectedImage, setSelectedImage] = useState<AcImage | null>(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const opsClient = useOpsClient()

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-datamanager') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  const refreshImages = useCallback(() => {
    setIsLoading(true)
    opsClient!.getImages(businessTypeCategoryId).then(result => {
      setImages(result)
      setIsLoading(false)
    })
  }, [businessTypeCategoryId, opsClient])

  useEffect(() => {
    if (opsClient) {
      refreshImages()
    }
  }, [opsClient, businessTypeCategoryId, refreshImages])

  const deleteImage = () => {
    setIsWorking(true)
    opsClient!.deleteImageForBusinessType(businessTypeCategoryId, selectedImage!.id).then(() => {
      setShowConfirm(false)
      setIsWorking(false)
      setSelectedImage(null)
      setIsLoading(true)
      opsClient!.getImages(businessTypeCategoryId).then(result => {
        setImages(result)
        setIsLoading(false)
      })
    })
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  let modal

  if (showConfirm) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={() => setShowConfirm(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Remove Image</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiText size='s'>
              <p>Are you sure you want to remove this image?</p>
            </EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={() => setShowConfirm(false)} disabled={isWorking}>
              Cancel
            </EuiButtonEmpty>
            <EuiButton onClick={deleteImage} fill color='danger' isLoading={isWorking}>
              Remove Image
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={refreshImages} fill color='text' size='s'>
            Refresh
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={() => setShowConfirm(true)} fill color='warning' disabled={selectedImage === null} size='s' isDisabled={!canUpdate}>
            Remove Selected Image
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{canUpdate && <BusinessTypeCategoryImageAddFlyOut businessTypeCategoryId={businessTypeCategoryId} />}</EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGrid>
        {images!.map(i => (
          <EuiFlexItem>
            <EuiImage key={i.id} alt={''} url={i.thumbnailUrl} hasShadow={true} onClick={() => setSelectedImage(i.thumbnailUrl === selectedImage?.thumbnailUrl ? null : i)} className={selectedImage === i ? 'selectableImage selectableImage-selected' : 'selectableImage'} />
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
      {modal}
    </React.Fragment>
  )
}

export default BusinessTypeCategoryImagesTab
