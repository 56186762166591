import React, { useEffect, useState, useCallback } from 'react'

import { EuiBasicTable, EuiButton, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiSpacer, EuiTitle, EuiToken, EuiTreeView } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'
import { Node } from '@elastic/eui/src/components/tree_view/tree_view'

import { BusinessTypeTextAssignment, BusinessTypeCategoryNode, useOpsClient, Billboard } from 'api'

import AssignTextCell from './assignment/AssignTextCell'

interface BillboardSpecificAssignmentTabTabParams {
  billboardId: string
}

const BillboardSpecificAssignmentTab: React.FC<BillboardSpecificAssignmentTabTabParams> = ({ billboardId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingBusinessTypeCategories, setIsLoadingBusinessTypeCategories] = useState(false)
  const [isLoadingBusinessTypeAssignments, setIsLoadingBusinessTypeAssignments] = useState(false)
  const [billboard, setBillboard] = useState<Billboard>()
  const [businessTypeCategories, setBusinessTypeCategories] = useState<BusinessTypeCategoryNode[]>([])
  const [businessTypeCategoryNodes, setBusinessTypeCategoryNodes] = useState<Node[]>([])
  const [selectedBusinessTypeCategory, setSelectedBusinessTypeCategory] = useState<BusinessTypeCategoryNode | null>(null)
  const [businessTypes, setBusinessTypes] = useState<BusinessTypeTextAssignment[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const opsClient = useOpsClient()
  const [query, setQuery] = useState<string>('')

  const refresh = () => {
    setIsLoading(true)
    setIsLoadingBusinessTypeCategories(true)
    opsClient!.getBillboard(billboardId).then(result => {
      setBillboard(result)
      setIsLoading(false)
    })
    opsClient!.getBusinessTypeCategoryNodes(billboardId).then(result => {
      setBusinessTypeCategories(result)
      setIsLoadingBusinessTypeCategories(false)
    })
  }

  useEffect(() => {
    if (opsClient) {
      refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsClient, billboardId])

  const GetConditionalDescendantsAsNodes = useCallback((businessTypeNodes: BusinessTypeCategoryNode[], isValidNode: (node: BusinessTypeCategoryNode) => boolean): Node[] => {
    const resNodes: Node[] = []
    businessTypeNodes.forEach(businessTypeNode => {
      const node: Node = {
        id: businessTypeNode.id,
        label: businessTypeNode.name,
        children: undefined as Node[] | undefined,
        icon: <EuiToken size='xs' iconType={businessTypeNode.hasAssignments ? 'tokenBoolean' : 'tokenObject'} />,
        callback(): string {
          setSelectedBusinessTypeCategory(businessTypeNode)
          return ''
        }
      }
      if (isValidNode(businessTypeNode)) {
        if (businessTypeNode.children.length > 0) {
          node.children = GetConditionalDescendantsAsNodes(businessTypeNode.children, () => true)
        }
        resNodes.push(node)
      } else if (businessTypeNode.children.length > 0) {
        const validChildren = GetConditionalDescendantsAsNodes(businessTypeNode.children, isValidNode)
        if (validChildren.length > 0) {
          node.children = validChildren
          resNodes.push(node)
        }
      }
    })
    return resNodes
  },[])

  const SetNodesFromCategoriesAndQuery = useCallback((categories: BusinessTypeCategoryNode[]) => {
    const NodeIsValid = (node: BusinessTypeCategoryNode) => {
      return query.length === 0 || node.name.toUpperCase().indexOf(query.toUpperCase()) >= 0
    }
    const nodes: Node[] = GetConditionalDescendantsAsNodes(categories, NodeIsValid)
    setBusinessTypeCategoryNodes(nodes)
  }, [GetConditionalDescendantsAsNodes, query])

  useEffect(() => {
    SetNodesFromCategoriesAndQuery(businessTypeCategories)
  }, [businessTypeCategories, SetNodesFromCategoriesAndQuery])

  useEffect(() => {
    SetNodesFromCategoriesAndQuery(businessTypeCategories)
  }, [query, businessTypeCategories, SetNodesFromCategoriesAndQuery])

  useEffect(() => {
    if (selectedBusinessTypeCategory && opsClient && billboardId) {
      setIsLoadingBusinessTypeAssignments(true)
      opsClient.getBillboardBusinessTypeAssignments(billboardId, selectedBusinessTypeCategory.id).then(result => {
        setBusinessTypes(result)
        setIsLoadingBusinessTypeAssignments(false)
      })
    }
  }, [selectedBusinessTypeCategory, billboardId, opsClient])

  const columns: Array<EuiBasicTableColumn<BusinessTypeTextAssignment>> = [
    {
      name: 'Business Type Name',
      field: 'name',
      truncateText: true,
      width: '200'
    },
    {
      name: 'Assign?',
      render: (bta: BusinessTypeTextAssignment) => <AssignTextCell assignment={bta} />,
      width: '100'
    }
  ]

  const saveSelections = () => {
    setIsSaving(true)
    opsClient!
      .updateBillboardSpecificAssignments(billboardId, {
        businessTypeCategoryId: selectedBusinessTypeCategory!.id,
        businessTypeVoiceOverAssignments: businessTypes.map(a => ({
          businessTypeId: a.id,
          isAssigned: a.isAssigned
        }))
      })
      .then(result => {
        setBillboard(result)
        setIsLoadingBusinessTypeAssignments(true)
        opsClient!.getBillboardBusinessTypeAssignments(billboardId, selectedBusinessTypeCategory!.id).then(result => {
          setBusinessTypes(result)
          setIsLoadingBusinessTypeAssignments(false)
          setIsSaving(false)
          refresh()
        })
      })
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  const onSearchChange = (search: string) => {
    setQuery(search)
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {billboard && (
        <React.Fragment>
          <img src={billboard.previewUrl ?? ''} alt={'preview billboard'} />
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFieldSearch placeholder='Search for a business type' fullWidth value={query} isLoading={isLoadingBusinessTypeAssignments} onChange={v => onSearchChange(v.target.value)} isClearable={true} incremental={false} onSearch={() => {}} />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={false} style={{ width: 250 }}>
              <EuiTitle size='xxs'>
                <h4>Categories</h4>
              </EuiTitle>
              <EuiSpacer size='m' />
              {isLoadingBusinessTypeCategories ? <EuiLoadingContent lines={3} /> : <EuiTreeView items={businessTypeCategoryNodes} aria-label='Business type categories' expandByDefault={true} showExpansionArrows={true} display='compressed' color='primary' />}
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiTitle size='xxs'>
                <h4>Business Types Assignments</h4>
              </EuiTitle>
              <EuiSpacer size='m' />
              {isLoadingBusinessTypeAssignments ? (
                <EuiLoadingContent lines={3} />
              ) : (
                <React.Fragment>
                  <EuiBasicTable items={businessTypes} itemId='id' columns={columns} rowHeader='name' />
                  <EuiSpacer />
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiButton onClick={saveSelections} isLoading={isSaving} isDisabled={!isLoadingBusinessTypeAssignments && !selectedBusinessTypeCategory}>
                        Save
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </React.Fragment>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default BillboardSpecificAssignmentTab
