import { ColorPalette } from 'components/AdCreation/DisplayMaker'

export const CreateColorPalette = (prominentColor: string) => {
  const triadic = GetTriadicColors(prominentColor)
  const baseColor = triadic[0]
  const bgColor = GetLighterColor(baseColor, 0.1)
  const btnColor = GetTriadicColors(bgColor)[0]

  const baseColor2 = triadic[1]
  const bgColor2 = GetLighterColor(baseColor2, 0.1)
  const btnColor2 = GetTriadicColors(bgColor2)[0]

  const palette: ColorPalette = {
    prominentColor: prominentColor,
    baseColor: baseColor,
    baseColor2: baseColor2,
    backgroundColor: bgColor,
    backgroundColor2: bgColor2,
    buttonColor: btnColor,
    buttonColor2: btnColor2
  }

  return palette
}

export const GetTriadicColors = (hex: string) => {
  const color = hex.replace('#', '')
  const r = color.substring(0, 2)
  const g = color.substring(2, 4)
  const b = color.substring(4, 6)
  return ['#' + g + b + r, '#' + b + r + g]
}

export const IsDark = (hex: string) => {
  const c = hex.replace('#', '') // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  return luma < 175
}

export const GetDarkerColor = (hex: string, percent?: number) => {
  const color = hex.replace('#', '')
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  const amount = percent ? percent : 0.1

  const newR = r - r * amount
  const newG = g - g * amount
  const newB = b - b * amount

  return '#' + intToHex(newR) + intToHex(newG) + intToHex(newB)
}

export const GetLighterColor = (hex: string, percent?: number) => {
  const color = hex.replace('#', '')
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  const amount = percent ? percent : 0.1

  const rDelta = get255Delta(r)
  const gDelta = get255Delta(g)
  const bDelta = get255Delta(b)

  const newR = r + rDelta * amount
  const newG = g + gDelta * amount
  const newB = b + bDelta * amount

  return '#' + intToHex(newR) + intToHex(newG) + intToHex(newB)
}

const intToHex = (i: number) => {
  const hex = Math.min(255, Math.max(0, Math.floor(i))).toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

const get255Delta = (base: number) => {
  return 255 - base
}
