import React from 'react'

import { EuiInMemoryTable, EuiLink, SortDirection } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { AgencyOrganization } from 'api'
import { CohortStatusSymbol } from 'features/agencyManagerDashboard/components/CohortStatusSymbol'

export interface ICohortsGrid {
  agencies: AgencyOrganization[]
  isLoading?: boolean
}

export const CohortsGrid: React.FC<ICohortsGrid> = props => {
  const columns: Array<EuiBasicTableColumn<AgencyOrganization>> = [
    {
      name: 'Agency',
      render: (o: AgencyOrganization) => (
        <EuiLink href={`/customers/organizations/${o.organization.organizationId}`} target='_blank'>
          {o.organization.organizationName}
        </EuiLink>
      ),
      sortable: (o: AgencyOrganization) => o.organization.organizationName
    },
    {
      name: 'Email',
      render: (o: AgencyOrganization) => (
        <EuiLink href={`mailto:${o.organization.billingEmail}`} target='_top'>
          {o.agencyDetails.contactEmail ?? o.organization.billingEmail}
        </EuiLink>
      ),
      sortable: (o: AgencyOrganization) => o.agencyDetails.contactEmail ?? o.organization.billingEmail
    },
    {
      name: 'Phone',
      render: (o: AgencyOrganization) => o.agencyDetails.contactPhone ?? o.organization.phoneNumber,
      sortable: (o: AgencyOrganization) => o.agencyDetails.contactPhone ?? o.organization.phoneNumber
    },
    {
      name: 'Cohort Status',
      render: (o: AgencyOrganization) => o.agencyDetails.cohortId && <CohortStatusSymbol cohortStatus={o.agencyDetails.cohortStatus} />,
      sortable: (o: AgencyOrganization) => o.agencyDetails.cohortStatus ?? ''
    }
  ]

  const sorting = {
    allowNeutralSort: false,
    enableAllColumns: true,
    sort: {
      field: 'agencyDetails.email',
      direction: SortDirection.DESC
    }
  }

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  return (
    <React.Fragment>
      <EuiInMemoryTable loading={props.isLoading} items={props.agencies} columns={columns} sorting={sorting} pagination={pagination} tableLayout='fixed' />
    </React.Fragment>
  )
}
