import { Rectangle, RectangleProps } from '@react-google-maps/api'
import React, { FC, useCallback, useRef, useState } from 'react'

export interface AcRectangleProps extends RectangleProps {
  onRectangleChange: (rectangle: google.maps.Rectangle) => void
}

const AcRectangle: FC<AcRectangleProps> = ({ onLoad, onRectangleChange, ...props }) => {
  const [isDragging, setIsDragging] = useState(false)

  const rectangleRef = useRef<google.maps.Rectangle | null>()

  const updateRectangle = useCallback(() => {
    const rectangle = rectangleRef.current

    if (rectangle) {
      onRectangleChange(rectangle)
    }
  }, [onRectangleChange])

  const onLoadOverride = useCallback(
    (rectangle: google.maps.Rectangle) => {
      rectangleRef.current = rectangle

      if (onLoad) {
        onLoad(rectangle)
      }
    },
    [onLoad]
  )

  const onDragStart = useCallback(() => {
    setIsDragging(true)
  }, [])

  const onDragEnd = useCallback(() => {
    setIsDragging(false)
    updateRectangle()
  }, [updateRectangle])

  const onBoundsChanged = () => {
    if (isDragging) {
      return
    }

    updateRectangle()
  }

  const onUnmount = useCallback(() => {
    rectangleRef.current = null
  }, [])

  return <Rectangle {...props} onLoad={onLoadOverride} onDragStart={onDragStart} onDragEnd={onDragEnd} onBoundsChanged={onBoundsChanged} onUnmount={onUnmount} />
}

export default AcRectangle
