import { FieldArray, FormikProps, getIn } from 'formik'
import React, { useEffect, useState } from 'react'

import { EuiButtonEmpty, EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSelect, EuiSpacer, EuiToolTip } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { State, useOpsClient } from 'api'
import { Address } from 'api/entities/Address'
import { SmartTargeting } from 'api/entities/Campaign'

export interface WalkInAddressesProps {
  formik: FormikProps<SmartTargeting>
}

const WalkInAddresses = ({ formik }: WalkInAddressesProps) => {
  const opsClient = useOpsClient()
  const [isLoadingStates, setIsLoadingStates] = useState(false)
  const [states, setStates] = useState<State[]>([])

  useEffect(() => {
    if (opsClient) {
      setIsLoadingStates(true)
      opsClient.getStates().then(result => {
        setStates(result)
        setIsLoadingStates(false)
      })
    }
  }, [opsClient])

  return (
    <FieldArray
      name='walkInAddresses'
      render={arrayHelpers => (
        <React.Fragment>
          {formik.values.walkInAddresses &&
            formik.values.walkInAddresses.length > 0 &&
            formik.values.walkInAddresses.map((walkInAddress, index) => (
              <EuiFlexGroup gutterSize='xs' key={index} style={{ marginLeft: -30, marginTop: 5, marginBottom: 5 }}>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup alignItems='center'>
                    <EuiFlexItem grow={false}>
                      <EuiToolTip position='top' content='Remove this Location'>
                        <EuiButtonIcon
                          id='removeWalkInAddress'
                          iconType='cross'
                          aria-label='remove'
                          color='warning'
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                        />
                      </EuiToolTip>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'Street Address' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `walkInAddresses[${index}].streetAddress`) && !!getIn(formik.errors, `walkInAddresses[${index}].streetAddress`)} error={getIn(formik.errors, `walkInAddresses[${index}].streetAddress`)}>
                    <EuiFieldText fullWidth name={`walkInAddresses[${index}].streetAddress`} value={getIn(formik.values, `walkInAddresses[${index}].streetAddress`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `walkInAddresses[${index}].streetAddress`) && !!getIn(formik.errors, `walkInAddresses[${index}].streetAddress`)} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'City' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `walkInAddresses[${index}].city`) && !!getIn(formik.errors, `walkInAddresses[${index}].city`)} error={getIn(formik.errors, `walkInAddresses[${index}].city`)}>
                    <EuiFieldText fullWidth name={`walkInAddresses[${index}].city`} value={getIn(formik.values, `walkInAddresses[${index}].city`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `walkInAddresses[${index}].city`) && !!getIn(formik.errors, `walkInAddresses[${index}].city`)} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'State' : ''} display='rowCompressed' fullWidth isInvalid={getIn(formik.touched, `walkInAddresses[${index}].state`) && !!getIn(formik.errors, `walkInAddresses[${index}].state`)} error={getIn(formik.errors, `walkInAddresses[${index}].state`)}>
                    <EuiSelect
                      name={`walkInAddresses[${index}].state`}
                      isLoading={isLoadingStates}
                      options={states.map(
                        s =>
                          ({
                            value: s.code,
                            label: s.name,
                            text: s.name
                          } as EuiSelectOption)
                      )}
                      value={getIn(formik.values, `walkInAddresses[${index}].state`)}
                      onChange={formik.handleChange}
                      fullWidth
                      isInvalid={getIn(formik.touched, `walkInAddresses[${index}].state`) && !!getIn(formik.errors, `walkInAddresses[${index}].state`)}
                      hasNoInitialSelection={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label={index === 0 ? 'Zip Code' : ''} fullWidth display='rowCompressed' isInvalid={getIn(formik.touched, `walkInAddresses[${index}].zip`) && !!getIn(formik.errors, `walkInAddresses[${index}].zip`)} error={getIn(formik.errors, `walkInAddresses[${index}].zip`)}>
                    <EuiFieldText fullWidth name={`walkInAddresses[${index}].zip`} value={getIn(formik.values, `walkInAddresses[${index}].zip`)} onChange={formik.handleChange} isInvalid={getIn(formik.touched, `walkInAddresses[${index}].zip`) && !!getIn(formik.errors, `walkInAddresses[${index}].zip`)} />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            ))}
          <EuiSpacer size='s' />
          <EuiButtonEmpty
            id='addWalkInAddress'
            size='s'
            onClick={() => {
              arrayHelpers.push({ streetAddress: '', city: '', state: '', zip: '', country: 'usa' } as Address)
            }}
            iconType='listAdd'>
            Add {formik.values.walkInAddresses.length === 0 ? 'New' : 'Another'} Location
          </EuiButtonEmpty>
        </React.Fragment>
      )}
    />
  )
}

export default WalkInAddresses
