import React, { FC, useEffect, useState } from 'react'

import { EuiButtonEmpty, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiFormRow, EuiSelect, EuiSelectOption, EuiTitle } from '@elastic/eui'

import { AgencyOrganization, AgencySalesStage } from 'api'
import { useGetCohortsQuery } from 'api/rtkQueryApi/opsApi/cohortsApi'
import { useGetAgencyOrganizationsQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { CohortsGrid } from 'features/agencyManagerDashboard/CohortsGrid'
import { AgencySalesStageFilterGroup } from 'features/agencyManagerDashboard/components/AgencySalesStageFilterGroup'

export interface ICohortsFlyout {
  onClose: () => void
}

export const CohortsFlyout: FC<ICohortsFlyout> = props => {
  const getCohortsQuery = useGetCohortsQuery()
  const agenciesQuery = useGetAgencyOrganizationsQuery({})
  const [filteredAgencyOrganizations, setFilteredAgencyOrganizations] = useState<AgencyOrganization[]>([])
  const [salesStageFilteredAgencyOrganizations, setSalesStageFilteredAgencyOrganizations] = useState<AgencyOrganization[]>([])
  const [selectedCohortId, setSelectedCohortId] = useState<string>('unassigned')
  const [cohortOptions, setCohortOptions] = useState<EuiSelectOption[]>([{ value: 'unassigned', text: 'Unassigned' }])
  const [activeSalesStageFilters, setActiveSalesStageFilters] = React.useState<AgencySalesStage[]>([])
  const [emailsText, setEmailsText] = useState<string>()
  const [phonesText, setPhonesText] = useState<string>()

  useEffect(() => {
    if (getCohortsQuery.data) {
      const options = getCohortsQuery.data.map(cohort => {
        return { value: cohort.id, text: cohort.name }
      })
      setCohortOptions([...options, { value: 'unassigned', text: 'Unassigned' }])
      if (options.length > 0) {
        setSelectedCohortId(options[0].value)
      }
    }
  }, [getCohortsQuery.data])

  useEffect(() => {
    if (getCohortsQuery.data) {
      if (selectedCohortId === 'unassigned') {
        setFilteredAgencyOrganizations(agenciesQuery.data?.filter(agency => agency.agencyDetails.cohortId === null) ?? [])
        return
      }
      const agencyOrgs = agenciesQuery.data?.filter(agency => agency.agencyDetails.cohortId === selectedCohortId)
      setFilteredAgencyOrganizations(agencyOrgs ?? [])
    }
  }, [agenciesQuery.data, selectedCohortId])

  useEffect(() => {
    setSalesStageFilteredAgencyOrganizations(filteredAgencyOrganizations.filter(a => activeSalesStageFilters.length === 0 || activeSalesStageFilters.includes(a.agencyDetails?.agencyStageDetails?.salesStage ?? AgencySalesStage.T)))
  }, [filteredAgencyOrganizations, activeSalesStageFilters])

  useEffect(() => {
    const emails = salesStageFilteredAgencyOrganizations.map(agency => agency.agencyDetails.contactEmail ?? agency.organization.billingEmail)
    const phones = salesStageFilteredAgencyOrganizations.map(agency => agency.agencyDetails.contactPhone ?? agency.organization.phoneNumber)

    setEmailsText(emails.join(`;  \n`))
    setPhonesText(phones.join(`;  \n`))
  }, [salesStageFilteredAgencyOrganizations])

  const onClose = () => {
    props.onClose()
  }
  const onCohortSelectChange = (value: string) => {
    setSelectedCohortId(value)
  }

  const onSalesStageFilterClicked = (salesStage: AgencySalesStage) => {
    if (activeSalesStageFilters.includes(salesStage)) {
      setActiveSalesStageFilters(activeSalesStageFilters.filter(f => f !== salesStage))
    } else {
      setActiveSalesStageFilters([...activeSalesStageFilters, salesStage])
    }
  }

  const onResetSalesStageFilters = () => {
    setActiveSalesStageFilters([])
  }

  return (
    <EuiFlyout ownFocus onClose={onClose} size={'m'}>
      <EuiFlyoutHeader>
        <EuiTitle size='m'>
          <h2>Cohorts</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexGroup direction={'column'}>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={'Select Cohort'}>
              <EuiSelect id={'SalesStageSelect'} options={cohortOptions} value={selectedCohortId} onChange={e => onCohortSelectChange(e.target.value)} aria-label='Agency Cohort' />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow label={'Recruitment Stage'} fullWidth>
              <AgencySalesStageFilterGroup agencies={filteredAgencyOrganizations} activeFilters={activeSalesStageFilters} onFilterClicked={onSalesStageFilterClicked} onResetFilters={onResetSalesStageFilters} />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={emailsText ?? ''}>
                  {copyEmails => (
                    <EuiButtonEmpty onClick={copyEmails} iconType={'email'}>
                      Copy Emails
                    </EuiButtonEmpty>
                  )}
                </EuiCopy>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={phonesText ?? ''}>
                  {copyPhones => (
                    <EuiButtonEmpty onClick={copyPhones} iconType={'mobile'}>
                      Copy Phones
                    </EuiButtonEmpty>
                  )}
                </EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <CohortsGrid agencies={salesStageFilteredAgencyOrganizations} isLoading={agenciesQuery.isLoading} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutBody>
    </EuiFlyout>
  )
}
