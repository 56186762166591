import React, { useState } from 'react'

import { EuiFieldSearch, EuiInputPopover, EuiSelectable, EuiText, htmlIdGenerator } from '@elastic/eui'
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option'

export interface IAcSearchField {
  value: string
  searchOptions: EuiSelectableOption[]
  isInvalid?: boolean
  isLoading?: boolean
  onValueChange?: (value: string) => void
  onSearch?: () => void
}

export const AcSearchField: React.FC<IAcSearchField> = props => {
  const [searchPopoverOpen, setSearchPopoverOpen] = useState<boolean>(false)

  const searchOptions = props.searchOptions.map(option => ({
    ...option,
    onClick: (a: any) => {
      setSearchPopoverOpen(false)
      option.onClick?.(a)
    }
  }))

  const onChange = (value: string) => {
    props.onValueChange?.(value)
  }

  return (
    <EuiInputPopover
      fullWidth
      input={
        <EuiFieldSearch
          id={htmlIdGenerator()()}
          autoComplete='off'
          fullWidth
          compressed
          value={props.value}
          isLoading={props.isLoading}
          onChange={v => {
            onChange(v.target.value)
          }}
          onFocus={() => setSearchPopoverOpen(true)}
          incremental={false}
          onSearch={() => props.onSearch?.()}
          placeholder='Type to begin search...'
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
          isInvalid={props.isInvalid}
        />
      }
      isOpen={searchPopoverOpen}
      closePopover={() => setSearchPopoverOpen(false)}>
      {props.searchOptions.length > 0 || props.isLoading ? (
        <EuiSelectable singleSelection options={searchOptions} isLoading={props.isLoading}>
          {list => list}
        </EuiSelectable>
      ) : (
        <EuiText color='subdued' size='xs'>
          <p>Start typing a search... results will appear here</p>
        </EuiText>
      )}
    </EuiInputPopover>
  )
}
