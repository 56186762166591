import moment from 'moment'

export const localTimestamp = (date: moment.Moment) => {
  return moment.utc(date).tz(moment.tz.guess()).format('MMM Do YYYY HH:mm:ss z')
}

export const localTime12Hour = (date: moment.Moment) => {
  return moment.utc(date).tz(moment.tz.guess()).format('MMMM Do YYYY h:m a z')
}

export const localDate = (date: moment.Moment) => {
  return moment.utc(date).tz(moment.tz.guess()).format('MM/DD/YYYY')
}

export const utcDate = (date: moment.Moment) => {
  return moment.utc(date).format('MM/DD/YYYY')
}
