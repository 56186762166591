import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { FieldArray, Formik, FormikHelpers, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

import { EuiButton, EuiForm, EuiFormRow, EuiLink, EuiLoadingContent, EuiSpacer, EuiText, EuiTextArea, EuiFlexGroup, EuiFlexItem, EuiFilePicker, EuiProgress, EuiFieldText, EuiDescriptionList, EuiDescriptionListTitle, EuiDescriptionListDescription, EuiButtonIcon } from '@elastic/eui'

import { Ad, AdStatus, Campaign, UpdateAdTv, UpdateUploadAdTv, useOpsClient } from 'api'
import { RootState } from 'app/rootReducer'
import history from 'services/HistoryService'
import { getAdStatusReadable } from 'utils/EnumToFriendly'
import { useDownloadUrl } from 'utils/useDownloadUrl'

interface AdTvDetailsTabParams {
  adId: string
}

interface FormValues {
  sections: string[]
  destinationUrl: string
  outroUrl: string | null
  voiceOverUrl: string | null
  uploadedUrl: string | null
}

const AdTvDetailsTab: React.FC<AdTvDetailsTabParams> = ({ adId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [ad, setAd] = useState<Ad>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [initial, setInitial] = useState<FormValues>({
    sections: [],
    destinationUrl: '',
    outroUrl: null,
    voiceOverUrl: null,
    uploadedUrl: null
  })
  const [destination, setDestination] = useState<string | null>(null)
  const [containerClient, setContainerClient] = useState<ContainerClient | null>(null)
  const [isUploadingVoiceOver, setIsUploadingVoiceOver] = useState(false)
  const [isUploadingOutro, setIsUploadingOutro] = useState(false)
  const [sourceVoiceOver, setSourceVoiceOver] = useState<string | null>(null)
  const [sourceOutro, setSourceOutro] = useState<string | null>(null)
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false)
  const [isExportingPreview, setIsExportingPreview] = useState(false)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isGeneratingFinal, setIsGeneratingFinal] = useState(false)
  const [isExportingFinal, setIsExportingFinal] = useState(false)
  const [finalUrl, setFinalUrl] = useState<string | null>(null)
  const [hasFinalUrl, setHasFinalUrl] = useState<boolean>(false)
  const [isUploadingVideo, setIsUploadingVideo] = useState(false)
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [canUpdate, setCanUpdate] = useState(false)
  const [isAuditor, setIsAuditor] = useState(false)
  const [downloadImage] = useDownloadUrl(initial?.uploadedUrl ?? '')

  useEffect(() => {
    if (userProfile) {
      setCanUpdate(userProfile.roles.includes('adcritter-ops-customermanager') || userProfile.roles.includes('adcritter-ops-admin'))
      setIsAuditor(userProfile.roles.includes('adcritter-ops-auditor') || userProfile.roles.includes('adcritter-ops-admin'))
    }
  }, [userProfile])

  useEffect(() => {
    if (opsClient) {
      setIsLoading(true)
      opsClient.getAd(adId).then(result => {
        setInitial({
          sections: result.tvCommercialDetails!.sections,
          destinationUrl: result.destinationUrl ?? '',
          outroUrl: result.tvCommercialDetails!.outroUrl,
          voiceOverUrl: result.tvCommercialDetails!.voiceOverUrl,
          uploadedUrl: result.tvCommercialUploadedDetails?.uploadedUrl
        })
        setAd(result)
        if (result.tvCommercialDetails!.previewUrl) setPreviewUrl(result.tvCommercialDetails!.previewUrl)
        if (result.tvCommercialDetails!.finalUrl) {
          setFinalUrl(result.tvCommercialDetails!.finalUrl)
          setHasFinalUrl(true)
        }
        if (result.tvCommercialDetails!.voiceOverUrl) {
          setSourceVoiceOver(result.tvCommercialDetails!.voiceOverUrl)
        }
        if (result.tvCommercialDetails!.outroUrl) {
          setSourceOutro(result.tvCommercialDetails!.outroUrl)
        }
        opsClient.getCampaign(result.campaignId).then(result => {
          setCampaign(result)
        })
        setIsLoading(false)
      })
    }
  }, [opsClient, adId])

  useEffect(() => {
    if (opsClient && adId) {
      opsClient.getAdUpload(adId).then(data => setDestination(data))
    }
  }, [opsClient, adId])

  useEffect(() => {
    if (destination) {
      const storageClient = new BlobServiceClient(destination)
      setContainerClient(storageClient.getContainerClient(adId))
    }
  }, [destination, adId])

  const generatePreview = () => {
    setPreviewUrl(null)
    setIsGeneratingPreview(true)
    setIsExportingPreview(true)
    opsClient!.generatePreviewAd(adId).then(() => {
      setIsGeneratingPreview(false)
    })
    const checkPreviewExist = setInterval(async function () {
      let url = await opsClient!.getAdPreviewUrl(adId)
      if (url) {
        setPreviewUrl(url)
        setIsExportingPreview(false)
        clearInterval(checkPreviewExist)
      }
    }, 1000)
  }

  const regenerateFinal = () => {
    setFinalUrl(null)
    setIsGeneratingFinal(true)
    setIsExportingFinal(true)
    opsClient!.generateFinalAd(adId).then(() => {
      setIsGeneratingFinal(false)
    })
    const checkFinalExist = setInterval(async function () {
      let url = await opsClient!.getAdFinalUrl(adId)
      if (url) {
        setFinalUrl(url)
        setIsExportingFinal(false)
        clearInterval(checkFinalExist)
      }
    }, 1000)
  }

  const adSchema = Yup.object().shape({
    sections: Yup.array().of(Yup.string().required('Please enter the text to show for this section')),
    destinationUrl: Yup.string()
      .matches(/^(https:\/\/)/, 'Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .url('Please enter a valid website URL including https:// (ex. https://www.domain.com)')
      .required('Please enter the website URL including https:// (ex. https://www.domain.com)')
      .max(2048)
  })

  const onVoiceOverFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingVoiceOver(true)
      const blockBlobClient = containerClient.getBlockBlobClient('VoiceOver.' + files[0].name.split('.').pop())

      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingVoiceOver(false)
          setSourceVoiceOver(blockBlobClient!.url!)
        })
    }
  }

  const onOutroFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingOutro(true)
      const blockBlobClient = containerClient.getBlockBlobClient('Outro.' + files[0].name.split('.').pop())

      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingOutro(false)
          setSourceOutro(blockBlobClient!.url!)
        })
    }
  }

  const onVideoFileChange = (files: FileList | null) => {
    if (containerClient && files && files.length === 1) {
      setIsUploadingVideo(true)
      const blockBlobClient = containerClient.getBlockBlobClient(uuidv4() + '.' + files[0].name.split('.').pop())
      const url = blockBlobClient!.url.split('?')[0]
      blockBlobClient!
        .uploadData(files[0], {
          blockSize: 4 * 1024 * 1024, // 4MB block size
          concurrency: 20
        })
        .then(() => {
          setIsUploadingVideo(false)
          const tempTvCommercial = { ...initial }
          tempTvCommercial.uploadedUrl = url
          setInitial(tempTvCommercial)
        })
    }
  }

  const doSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (initial?.uploadedUrl) {
      let update: UpdateUploadAdTv = {
        destinationUrl: values.destinationUrl,
        uploadedUrl: values.uploadedUrl
      }
      opsClient!.updateUploadAdTv(adId, update).then(result => {
        setAd(result)
        setSubmitting(false)
      })
    } else {
      let update: UpdateAdTv = {
        sections: values.sections,
        destinationUrl: values.destinationUrl,
        voiceOverUrl: sourceVoiceOver,
        outroUrl: sourceOutro
      }
      opsClient!.updateAdTv(adId, update).then(result => {
        setAd(result)
        setSubmitting(false)
      })
    }
  }

  const pauseAd = () => {
    opsClient!.putAdInactive(adId).then(result => {
      setAd(result)
    })
  }

  const startAd = () => {
    opsClient!.putAdActive(adId).then(result => {
      setAd(result)
    })
  }

  const deleteAd = () => {
    opsClient!.putAdDeleted(adId).then(result => {
      setAd(result)
    })
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <EuiSpacer />
        <EuiLoadingContent />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <EuiSpacer />
      {ad && campaign && (
        <React.Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              <Formik initialValues={initial} enableReinitialize validationSchema={adSchema} onSubmit={doSubmit}>
                {props => (
                  <EuiForm component='form' onSubmit={props.handleSubmit} onChange={props.handleChange} onBlur={props.handleBlur}>
                    <EuiFormRow label='Organization'>
                      <EuiLink onClick={() => history.push(`/customers/organizations/${ad!.organizationId}`)}>{ad!.organizationName}</EuiLink>
                    </EuiFormRow>
                    <EuiFormRow label='Account'>
                      <EuiLink onClick={() => history.push(`/customers/accounts/${ad!.accountId}`)}>{ad!.accountName}</EuiLink>
                    </EuiFormRow>
                    <EuiFormRow label='Campaign'>
                      <EuiLink onClick={() => history.push(`/customers/campaigns/${ad!.campaignId}`)}>{ad!.campaignName}</EuiLink>
                    </EuiFormRow>
                    <EuiFormRow label='Status'>
                      <React.Fragment>
                        <EuiText size='s'>
                          {getAdStatusReadable(ad.status)} | {ad.status !== AdStatus.Inactive && ad.status !== AdStatus.Deleted && canUpdate && <EuiLink onClick={pauseAd}>pause this ad</EuiLink>}
                          {ad.status === AdStatus.Inactive && canUpdate && <EuiLink onClick={startAd}>start this ad</EuiLink>}{' '}
                          {ad.status !== AdStatus.Deleted && userProfile!.isAdmin && (
                            <React.Fragment>
                              |{' '}
                              <EuiLink onClick={deleteAd} color='danger'>
                                force delete this ad
                              </EuiLink>
                            </React.Fragment>
                          )}
                          {ad.status === AdStatus.Deleted && userProfile!.isAdmin && (
                            <React.Fragment>
                              |{' '}
                              <EuiLink onClick={pauseAd} color='warning'>
                                un-delete this ad
                              </EuiLink>
                            </React.Fragment>
                          )}
                        </EuiText>
                      </React.Fragment>
                    </EuiFormRow>
                    <EuiFormRow label='Type'>
                      <React.Fragment>
                        <EuiText size='s'>{ad.tvCommercialDetails?.type}</EuiText>
                      </React.Fragment>
                    </EuiFormRow>
                    <EuiFormRow label='Length'>
                      <React.Fragment>
                        <EuiText size='s'>{ad.tvCommercialDetails?.kind}</EuiText>
                      </React.Fragment>
                    </EuiFormRow>
                    {ad.tvCommercialDetails?.type === 'VoiceOver' && !initial.uploadedUrl && (
                      <EuiFormRow label='Script'>
                        <EuiTextArea readOnly>{ad.tvCommercialDetails?.script}</EuiTextArea>
                      </EuiFormRow>
                    )}
                    {initial.uploadedUrl && (
                      <React.Fragment>
                        <EuiFormRow label='Video Preview'>
                          <EuiFlexGroup>
                            <EuiFlexItem>
                              <video controls src={initial.uploadedUrl!} style={{ width: 640 }} />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <EuiButtonIcon title={'Download'} iconType={'download'} onClick={() => downloadImage(`${ad?.adId ?? 'downloadedAd'}.mp4`)} />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFormRow>
                        <EuiFormRow label='Video Details' fullWidth>
                          <EuiDescriptionList compressed type='inline'>
                            <EuiDescriptionListTitle>Number of Frames</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.numberFrames}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Frame Rate</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.frameRate}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Duration</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.duration}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Width</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.width}</EuiDescriptionListDescription>
                            <EuiDescriptionListTitle>Height</EuiDescriptionListTitle>
                            <EuiDescriptionListDescription>{ad.tvCommercialUploadedDetails?.height}</EuiDescriptionListDescription>
                          </EuiDescriptionList>
                        </EuiFormRow>
                      </React.Fragment>
                    )}
                    <EuiFormRow label='Commercial Upload'>
                      <React.Fragment>
                        <EuiLink href={initial?.uploadedUrl!}>{initial?.uploadedUrl}</EuiLink>
                        <EuiFilePicker id='uploadedUrl' display='default' multiple={false} initialPromptText={!initial?.uploadedUrl ? 'Insert video here' : 'Replace video by dropping new one here'} onChange={onVideoFileChange} isLoading={isUploadingVideo} />
                      </React.Fragment>
                    </EuiFormRow>
                    <EuiFormRow label='Destination URL' isInvalid={!!props.errors.destinationUrl} error={props.errors.destinationUrl}>
                      <EuiFieldText name='destinationUrl' value={props.values.destinationUrl} onChange={props.handleChange} isInvalid={!!props.errors.destinationUrl} />
                    </EuiFormRow>
                    <EuiSpacer size='m' />
                    {ad.tvCommercialDetails?.type === 'VoiceOver' && !initial.uploadedUrl && (
                      <React.Fragment>
                        {ad.tvCommercialDetails?.voiceOverUrl && (
                          <React.Fragment>
                            <EuiFormRow label='Voice-over'>
                              <EuiLink target='_blank' href={ad.tvCommercialDetails!.voiceOverUrl}>
                                {ad.tvCommercialDetails?.voiceOverUrl.split('/').pop()}
                              </EuiLink>
                            </EuiFormRow>
                          </React.Fragment>
                        )}
                        {!ad.tvCommercialDetails?.voiceOverUrl && (
                          <EuiText size='xs'>
                            <strong>Voice-over:</strong>
                          </EuiText>
                        )}
                        <EuiFilePicker id='voiceOverUrl' display='default' multiple={false} initialPromptText={!ad.tvCommercialDetails!.voiceOverUrl ? 'Drop voice-over here' : 'Replace voice-over by dropping new one here'} onChange={onVoiceOverFileChange} isLoading={isUploadingVoiceOver} />
                        <EuiSpacer size='m' />
                      </React.Fragment>
                    )}
                    {!initial.uploadedUrl && (
                      <React.Fragment>
                        {ad.tvCommercialDetails?.outroUrl && initial.uploadedUrl && (
                          <React.Fragment>
                            <EuiFormRow label='Outro'>
                              <EuiLink target='_blank' href={ad.tvCommercialDetails!.outroUrl}>
                                {ad.tvCommercialDetails?.outroUrl.split('/').pop()}
                              </EuiLink>
                            </EuiFormRow>
                            <EuiSpacer size='s' />
                          </React.Fragment>
                        )}
                        {!ad.tvCommercialDetails?.outroUrl && (
                          <EuiText size='xs'>
                            <strong>Outro:</strong>
                          </EuiText>
                        )}
                        <EuiFilePicker id='outroUrl' display='default' multiple={false} initialPromptText={!ad.tvCommercialDetails!.outroUrl ? 'Drop outro here' : 'Replace outro by dropping new one here'} onChange={onOutroFileChange} isLoading={isUploadingOutro} />
                      </React.Fragment>
                    )}
                    <EuiSpacer size='m' />
                    {!initial.uploadedUrl && (
                      <FieldArray
                        name='sections'
                        render={() => (
                          <React.Fragment>
                            {props.values.sections &&
                              props.values.sections.length > 0 &&
                              props.values.sections.map((section: string, index: number) => (
                                <EuiFormRow key={`section${index}`} label={`Section ${index + 1}`} fullWidth isInvalid={getIn(props.touched, `sections[${index}]`) && !!getIn(props.errors, `sections[${index}]`)} error={getIn(props.errors, `sections[${index}]`)}>
                                  <EuiTextArea
                                    name={`sections[${index}]`}
                                    value={getIn(props.values, `sections[${index}]`).replace('\\n', '\n')}
                                    onChange={e => {
                                      props.setFieldValue(`sections[${index}]`, e.target.value.replace('\n', '\\n'))
                                    }}
                                    isInvalid={getIn(props.touched, `sections[${index}]`) && !!getIn(props.errors, `sections[${index}]`)}
                                    rows={2}
                                    fullWidth
                                  />
                                </EuiFormRow>
                              ))}
                          </React.Fragment>
                        )}
                      />
                    )}
                    <EuiSpacer />
                    <EuiButton isLoading={props.isSubmitting} fill type='submit' isDisabled={!canUpdate || isUploadingVideo}>
                      Save
                    </EuiButton>
                  </EuiForm>
                )}
              </Formik>
            </EuiFlexItem>
            <EuiFlexItem>
              {ad.tvCommercialDetails?.name && !initial.uploadedUrl && (
                <React.Fragment>
                  <EuiDescriptionList>
                    <EuiDescriptionListTitle>Name</EuiDescriptionListTitle>
                    <EuiDescriptionList>{ad.tvCommercialDetails?.name}</EuiDescriptionList>
                    {ad.tvCommercialDetails?.phone && !initial.uploadedUrl && (
                      <React.Fragment>
                        <EuiDescriptionListTitle>Phone</EuiDescriptionListTitle>
                        <EuiDescriptionList>{ad.tvCommercialDetails?.phone}</EuiDescriptionList>
                      </React.Fragment>
                    )}
                    {ad.tvCommercialDetails?.email && !initial.uploadedUrl && (
                      <React.Fragment>
                        <EuiDescriptionListTitle>Email</EuiDescriptionListTitle>
                        <EuiDescriptionList>{ad.tvCommercialDetails?.email}</EuiDescriptionList>
                      </React.Fragment>
                    )}
                  </EuiDescriptionList>
                </React.Fragment>
              )}
              {previewUrl && !initial.uploadedUrl && <video src={previewUrl} controls style={{ width: 640 }} />}
              {isExportingPreview && (
                <div style={{ width: 640, height: 360, backgroundColor: '#000' }}>
                  <EuiProgress />
                </div>
              )}
              <EuiSpacer size='m' />
              {!initial.uploadedUrl && (
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton size='s' onClick={generatePreview} fill isLoading={isGeneratingPreview}>
                      Generate Preview
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
              <EuiSpacer />
              {hasFinalUrl && (
                <React.Fragment>
                  {finalUrl && <video src={finalUrl} controls style={{ width: 640 }} />}
                  {isExportingFinal && (
                    <div style={{ width: 640, height: 360, backgroundColor: '#000' }}>
                      <EuiProgress />
                    </div>
                  )}
                  <EuiSpacer size='m' />
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <EuiButton size='s' onClick={regenerateFinal} fill isLoading={isGeneratingFinal} color='warning'>
                        Regenerate Final
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </React.Fragment>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AdTvDetailsTab
