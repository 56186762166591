import React, { useState } from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiLink, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiProgress } from '@elastic/eui'

import { BusinessTypeTextAssignment, useOpsClient } from 'api'
import config from 'app/config'

type PreviewCellType = {
  tvCommercialId: string
  assignment: BusinessTypeTextAssignment
}

const PreviewCell: React.FC<PreviewCellType> = ({ tvCommercialId, assignment }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isExportingPreview, setIsExportingPreview] = useState(false)
  const opsClient = useOpsClient()

  const doesVideoExist = (url: string) => {
    let http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    try {
      http.send()
      return http.status === 200
    } catch {
      return false
    }
  }

  let modal

  const closeModal = () => {
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)

    let selectedAnchorPhrases: { [code: string]: string } = {}
    let selectedGranularPhrases: { [code: string]: string } = {}

    assignment.selections.forEach(p => {
      selectedAnchorPhrases = Object.assign(selectedAnchorPhrases, p.selectedAnchorPhrases)
    })
    assignment.selections.forEach(p => {
      selectedGranularPhrases = Object.assign(selectedGranularPhrases, p.selectedGranularPhrases)
    })

    setIsExportingPreview(true)
    opsClient!
      .generateBusinessTypeTvCommercial(tvCommercialId, {
        businessTypeId: assignment.id,
        selectedAnchorPhrases,
        selectedGranularPhrases
      })
      .then(result => {
        const checkExist = setInterval(async function () {
          let url = `${config.VIDEOURL}${tvCommercialId}/${assignment.id}_sample.mp4`
          if (doesVideoExist(url)) {
            clearInterval(checkExist)
            setIsExportingPreview(false)
            setPreviewUrl(url)
          }
        }, 1000)
      })
  }

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask headerZindexLocation='above'>
        <EuiModal onClose={closeModal} style={{ width: 800, height: 600 }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h4>Preview Video</h4>
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>
                {previewUrl && <video src={previewUrl} controls style={{ width: 640 }} />}
                {isExportingPreview && (
                  <div style={{ width: 640, height: 360, backgroundColor: '#000' }}>
                    <EuiProgress />
                  </div>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <React.Fragment>
      <EuiLink onClick={showModal}>preview</EuiLink>
      {modal}
    </React.Fragment>
  )
}

export default PreviewCell
