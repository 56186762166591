import React, { useEffect, useState } from 'react'

import { EuiButton, EuiFormRow, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiSelect, EuiText } from '@elastic/eui'
import { EuiSelectOption } from '@elastic/eui/src/components/form/select'

import { Campaign, CampaignActiveStatus } from 'api'
import { CampaignSlot } from 'api/entities/Bundle'
import { useGetCampaignsWithoutBundlesForOrganizationQuery, useGetOrganizationQuery } from 'api/rtkQueryApi/opsApi/organizationsApi'

export interface IAssignCampaignToSlotModal {
  slot: CampaignSlot
  organizationId: string
  onClose?: () => void
  onSave?: (campaign: Campaign) => void
  saveLoading: boolean
}

export const AssignCampaignToSlotModal: React.FC<IAssignCampaignToSlotModal> = props => {
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()
  const organizationRequest = useGetOrganizationQuery(props.organizationId)
  const campaignsForOrgRequest = useGetCampaignsWithoutBundlesForOrganizationQuery(organizationRequest?.data?.organizationId ?? '', { skip: !organizationRequest?.data?.organizationId })

  const [campaignOptions, setCampaignOptions] = useState<EuiSelectOption[]>([])

  useEffect(() => {
    if (!campaignsForOrgRequest?.data || campaignsForOrgRequest.isLoading || campaignsForOrgRequest.isFetching) {
      return
    }
    const availableCampaigns = campaignsForOrgRequest!.data!.filter(camp => props.slot.campaignType === camp.campaignType)
    if (availableCampaigns && availableCampaigns.length > 0) {
      setSelectedCampaign(availableCampaigns[0])
      setCampaignOptions(availableCampaigns.map(camp => ({ value: camp.campaignId, text: camp.campaignName })))
    }
  }, [campaignsForOrgRequest.data, campaignsForOrgRequest.isLoading, campaignsForOrgRequest.isFetching])

  const onCloseModal = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const onSaveModal = () => {
    if (props.onSave && selectedCampaign) {
      props.onSave(selectedCampaign)
    }
  }

  const onCampaignSelected = (e: any) => {
    const campaign = campaignsForOrgRequest.data?.find(c => c.campaignId === e.target.value)
    if (campaign) {
      setSelectedCampaign(campaign)
    }
  }

  return (
    <EuiModal onClose={onCloseModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Assign Slot to Campaign</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFormRow label={'Slot Type'}>
          <EuiText>
            {props.slot.campaignType} Campaign {props.slot.isBonus ? '(Bonus)' : ''}
          </EuiText>
        </EuiFormRow>
        <EuiFormRow label={'Slot Budget'}>
          <EuiText>
            <b>${props.slot.budget}</b> every{' '}
          </EuiText>
        </EuiFormRow>
        <EuiFormRow label={'Assign to Campaign'} helpText={selectedCampaign?.activeStatus == CampaignActiveStatus.Active ? `This campaign will not use the ${selectedCampaign.bundleType}'s budget and frequency until the launch of its next flight.` : 'A flight will not launch until the campaign is activated.'}>
          <EuiSelect
            options={campaignOptions}
            value={selectedCampaign?.campaignId}
            onChange={e => {
              onCampaignSelected(e)
            }}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton onClick={onSaveModal} disabled={!campaignsForOrgRequest?.data || campaignsForOrgRequest?.data.length < 1} isLoading={props.saveLoading || campaignsForOrgRequest.isLoading || campaignsForOrgRequest.isFetching || organizationRequest.isLoading || organizationRequest.isFetching}>
            Confirm
          </EuiButton>
        </EuiFormRow>
      </EuiModalBody>
    </EuiModal>
  )
}
