import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EuiImage, EuiInMemoryTable, EuiLink, EuiSpacer } from '@elastic/eui'
import { EuiBasicTableColumn } from '@elastic/eui/src/components/basic_table/basic_table'

import { Ad, AdStatus, BidderAuditStatus, Campaign, useOpsClient } from 'api'
import { adsApi, useGetAdsQuery } from 'api/rtkQueryApi/opsApi/adsApi'
import { campaignsApi } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { RootState } from 'app/rootReducer'
import { UploadDisplayAdsFlyout } from 'components/AdCreation/UploadDisplayAdsFlyout'
import CampaignCreateBillboardAdFlyOut from 'features/customers/campaigns/CampaignCreateBillboardAdFlyOut'
import CampaignCreateNativeAdFlyOut from 'features/customers/campaigns/CampaignCreateNativeAdFlyOut'
import CampaignCreateTvAdFlyOut from 'features/customers/campaigns/CampaignCreateTvAdFlyOut'
import history from 'services/HistoryService'
import { getAdStatusReadable, insertSpaces } from 'utils/EnumToFriendly'

interface CampaignAdsTabParams {
  campaign: Campaign
}

const CampaignAdsTab: React.FC<CampaignAdsTabParams> = ({ campaign }) => {
  const dispatch = useDispatch()
  const opsClient = useOpsClient()
  const { userProfile } = useSelector((state: RootState) => state.app)
  const [showUploadDisplayAdsFlyout, setShowUploadDisplayAdsFlyout] = useState<boolean>(false)
  const { data: ads, isFetching: isFetchingAds } = useGetAdsQuery(campaign.campaignId)

  const renderAdImage = (a: Ad) => {
    switch (a.adType) {
      case 'Internet':
        return <EuiImage url={a.thumbnailUrl ? a.thumbnailUrl : a.displayAdDetails?.uploadedUrl!} size={41} alt='thumbnail' />
      case 'TV':
        return <EuiImage url={a.tvCommercialUploadedDetails?.thumbnailUrl ?? a.tvCommercialDetails?.thumbnailUrl ?? ''} size={41} alt='thumbnail' />
      case 'Billboard':
        return <EuiImage url={a.thumbnailUrl ? a.thumbnailUrl : a.displayAdDetails?.uploadedUrl!} size={41} alt='thumbnail' />
    }
  }

  const renderAdLink = (a: Ad) => {
    switch (a.adType) {
      case 'Internet':
        return <EuiLink onClick={() => history.push(`/customers/ads/${a.adId}`)}>{a.nativeDetails?.headline ? a.nativeDetails.headline : a.name}</EuiLink>
      case 'TV':
        return a.tvCommercialDetails?.tvCommercialId ? <EuiLink onClick={() => history.push(`/customers/ads/${a.adId}`)}>{a.tvCommercialDetails.tvCommercialName}</EuiLink> : a.tvCommercialUploadedDetails ? <EuiLink onClick={() => history.push(`/customers/ads/${a.adId}`)}>Customer uploaded</EuiLink> : <EuiLink onClick={() => history.push(`/customers/ads/${a.adId}`)}>{a.name}</EuiLink>
      case 'Billboard':
        return <EuiLink onClick={() => history.push(`/customers/ads/${a.adId}`)}>Customer uploaded</EuiLink>
    }
  }

  const columns: Array<EuiBasicTableColumn<Ad>> = [
    {
      name: '',
      render: (a: Ad) => renderAdImage(a),
      width: '49'
    },
    {
      name: 'Ad',
      render: (a: Ad) => renderAdLink(a)
    },
    {
      name: `Type`,
      render: (a: Ad) => a.adType
    },
    {
      name: `Status`,
      render: (a: Ad) => getAdStatusReadable(a.status)
    },
    ...(userProfile?.roles.includes('adcritter-ops-performance')
      ? [
          {
            name: `Bidder Audit Status`,
            render: (a: Ad) => (a.status === AdStatus.Live || a.status === AdStatus.PendingNetworkApproval ? insertSpaces(a.bidderAuditStatus) : insertSpaces(BidderAuditStatus.Unknown))
          }
        ]
      : [])
  ]

  const pagination = {
    initialPageSize: 50,
    pageSizeOptions: [10, 50, 100]
  }

  const onCreated = () => {
    if (opsClient) {
      dispatch(adsApi.util.invalidateTags([{ type: 'Ads', id: campaign.campaignId }]))
      dispatch(campaignsApi.util.invalidateTags([{ type: 'Campaign', id: campaign.campaignId }]))
    }
  }

  const onUploadDisplayAdsClick = () => {
    setShowUploadDisplayAdsFlyout(true)
  }

  const onCloseUploadDisplayAdsFlyout = () => {
    setShowUploadDisplayAdsFlyout(false)
  }

  return (
    <React.Fragment>
      {campaign.campaignType === 'Internet' && <React.Fragment>{ads?.every(ad => !ad.nativeDetails) ? <EuiLink onClick={onUploadDisplayAdsClick}>Upload Display Ads</EuiLink> : <CampaignCreateNativeAdFlyOut campaign={campaign} onCreated={onCreated} />}</React.Fragment>}
      {campaign.campaignType === 'Billboard' && <CampaignCreateBillboardAdFlyOut campaign={campaign} onCreated={onCreated} />}
      {campaign.campaignType === 'TV' && <CampaignCreateTvAdFlyOut campaign={campaign} onCreated={onCreated} />}
      <EuiSpacer />
      <EuiInMemoryTable loading={isFetchingAds} items={ads ?? []} columns={columns} pagination={pagination} tableLayout='fixed' />
      {showUploadDisplayAdsFlyout && <UploadDisplayAdsFlyout campaign={campaign} onClose={onCloseUploadDisplayAdsFlyout} defaultWebsiteLink={ads && ads.length > 0 ? ads[0].destinationUrl : ''} />}
    </React.Fragment>
  )
}

export default CampaignAdsTab
