import { Moment } from 'moment'
import moment from 'moment/moment'
import React, { FC, useCallback, useState } from 'react'

import dateMath from '@elastic/datemath'
import { EuiSuperDatePicker } from '@elastic/eui'
import { OnTimeChangeProps } from '@elastic/eui/src/components/date_picker/super_date_picker/super_date_picker'
import { DurationRange } from '@elastic/eui/src/components/date_picker/types'

export interface CustomFilterDate {
  start: Moment
  end: Moment
}

export const useAcSuperDatePickerState = (defaultStart?: Moment, defaultEnd?: Moment) => {
  const [customFilterDate, setCustomFilterDate] = useState<CustomFilterDate>({
    start: defaultStart ?? dateMath.parse('now-5y') ?? moment().add(-3, 'months'),
    end: defaultEnd ?? dateMath.parse('now') ?? moment()
  })

  const onTimeChange = useCallback(
    ({ start, end }: OnTimeChangeProps) => {
      const startMoment = dateMath.parse(start)

      if (!startMoment || !startMoment.isValid()) {
        throw new Error('Unable to parse start string')
      }

      const endMoment = dateMath.parse(end, { roundUp: true })
      if (!endMoment || !endMoment.isValid()) {
        throw new Error('Unable to parse end string')
      }

      setCustomFilterDate({
        start: startMoment,
        end: endMoment
      })
    },
    [setCustomFilterDate]
  )

  return {
    customFilterDate,
    onTimeChange
  }
}

export interface ACSuperDatePickerProps {
  customFilterDate: CustomFilterDate
  onTimeChange: (props: OnTimeChangeProps) => void
  customCommonDateRanges?: DurationRange[]
  useDefaultCustomCommonDateRanges?: boolean
}

const ACSuperDatePicker: FC<ACSuperDatePickerProps> = ({ customFilterDate, onTimeChange, customCommonDateRanges, useDefaultCustomCommonDateRanges }) => {
  let commonDateRanges = customCommonDateRanges

  if (!commonDateRanges && useDefaultCustomCommonDateRanges) {
    const currentYear = moment().year()

    commonDateRanges = [
      {
        label: 'This Month',
        start: 'now/M',
        end: 'now'
      },
      {
        label: 'Last Month',
        start: 'now-1M/M',
        end: 'now-1M/M'
      },
      {
        label: 'Last 3 Months',
        start: 'now-3M/M',
        end: 'now'
      },
      {
        label: 'Last 6 Months',
        start: 'now-6M/M',
        end: 'now'
      },
      {
        label: 'Quarter 1',
        start: `${currentYear}-01-01`,
        end: `${currentYear}-03-31`
      },
      {
        label: 'Quarter 2',
        start: `${currentYear}-04-01`,
        end: `${currentYear}-06-30`
      },
      {
        label: 'Quarter 3',
        start: `${currentYear}-07-01`,
        end: `${currentYear}-09-30`
      },
      {
        label: 'Quarter 4',
        start: `${currentYear}-10-01`,
        end: `${currentYear}-12-31`
      }
    ] as DurationRange[]
  }
  return <EuiSuperDatePicker start={customFilterDate.start.toString()} end={customFilterDate.end.toString()} onTimeChange={onTimeChange} commonlyUsedRanges={commonDateRanges} dateFormat='LL' timeFormat='' showUpdateButton={true} />
}

export default ACSuperDatePicker
