import { Ad, PendingAuditBillboardAd, PendingAuditInternetAd, PendingAuditTvCommercial } from 'api/entities/Ad'
import { Campaign } from 'api/entities/Campaign'
import { defaultTransformFn, opsApi } from 'api/rtkQueryApi/opsApi/opsApi'

const adsApiTags = opsApi.enhanceEndpoints({ addTagTypes: ['Ads', 'Ad', 'InternetPending', 'TvPending', 'BillboardPending'] })

const adAuditCacheSeconds = 300

export interface UploadDisplayAdRequest {
  accountId: string
  name: string
  destinationUrl: string
  companyName: string
  uploadedUrl: string
  htmlTag: string
  width: number
  height: number
  campaignIds: Array<string>
}

export const adsApi = adsApiTags.injectEndpoints({
  endpoints: build => ({
    getAds: build.query<Ad[], string>({
      query: campaignId => `ads?campaignId=${campaignId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, campaignId) => (result ? [...result.map(({ adId }) => ({ type: 'Ad' as const, id: adId })), { type: 'Ads', id: campaignId }] : [{ type: 'Ads', id: campaignId }])
    }),
    getAd: build.query<Ad, string | undefined>({
      query: id => `ads/${id}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, id) => [{ type: 'Ad', id }]
    }),
    refreshAdStatus: build.mutation<Ad, string>({
      query: adId => ({
        url: `ads/${adId}/refreshStatus`,
        method: 'GET'
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: (result, error, id) => [{ type: 'Ad', id }]
    }),
    getInternetPendingAudits: build.query<PendingAuditInternetAd[], { campaignId?: string }>({
      query: request => {
        let q = `ads/pending-audit/internet`
        if (request.campaignId && request.campaignId !== '') {
          q += `?campaignId=${request.campaignId}`
        }
        return q
      },
      transformResponse: defaultTransformFn,
      providesTags: (result, error, request) => [{ type: 'InternetPending', id: request.campaignId }],
      keepUnusedDataFor: adAuditCacheSeconds
    }),
    getInternetPendingAudit: build.query<PendingAuditInternetAd, string>({
      query: adId => `ads/pending-audit/details/internet/${adId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, adId) => [{ type: 'InternetPending', id: adId }]
    }),
    getTvPendingAudits: build.query<PendingAuditTvCommercial[], void>({
      query: () => `ads/pending-audit/tv`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'TvPending' }],
      keepUnusedDataFor: adAuditCacheSeconds
    }),
    getTvPendingAudit: build.query<PendingAuditTvCommercial, string>({
      query: adId => `ads/pending-audit/details/tv/${adId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, adId) => [{ type: 'TvPending', id: adId }]
    }),
    getBillboardPendingAudits: build.query<PendingAuditBillboardAd[], void>({
      query: () => `ads/pending-audit/billboard`,
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: 'BillboardPending' }],
      keepUnusedDataFor: adAuditCacheSeconds
    }),
    getBillboardPendingAudit: build.query<PendingAuditBillboardAd, string>({
      query: adId => `ads/pending-audit/details/billboard/${adId}`,
      transformResponse: defaultTransformFn,
      providesTags: (result, error, adId) => [{ type: 'BillboardPending', id: adId }]
    }),
    approveInternetAd: build.mutation<PendingAuditInternetAd, { adId: string }>({
      query: ({ adId }) => ({
        url: `ads/pending-audit/details/internet/${adId}/approve`,
        method: 'PUT',
        body: {}
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'InternetPending' }]
    }),
    disapproveInternetAd: build.mutation<PendingAuditInternetAd, { adId: string; reason: string }>({
      query: ({ adId, reason }) => ({
        url: `ads/pending-audit/details/internet/${adId}/disapprove`,
        method: 'PUT',
        body: { reason }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'InternetPending' }]
    }),
    approveBillboardAd: build.mutation<PendingAuditBillboardAd, { adId: string }>({
      query: ({ adId }) => ({
        url: `ads/pending-audit/details/billboard/${adId}/approve`,
        method: 'PUT',
        body: {}
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'BillboardPending' }]
    }),
    disapproveBillboardAd: build.mutation<PendingAuditBillboardAd, { adId: string; reason: string }>({
      query: ({ adId, reason }) => ({
        url: `ads/pending-audit/details/billboard/${adId}/disapprove`,
        method: 'PUT',
        body: { reason }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'BillboardPending' }]
    }),
    approveTvAd: build.mutation<PendingAuditTvCommercial, { adId: string; destinationUrl: string }>({
      query: ({ adId, destinationUrl }) => ({
        url: `ads/pending-audit/details/tv/${adId}/approve`,
        method: 'PUT',
        body: { destinationUrl }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'TvPending' }]
    }),
    disapproveTvAd: build.mutation<PendingAuditTvCommercial, { adId: string; reason: string }>({
      query: ({ adId, reason }) => ({
        url: `ads/pending-audit/details/tv/${adId}/disapprove`,
        method: 'PUT',
        body: { reason }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'TvPending' }]
    }),
    uploadBulkDisplayAds: build.mutation<Ad[], { accountId: string; campaignId: string; displayAds: UploadDisplayAdRequest[] }>({
      query: request => ({
        url: `ads/creatives/${request.accountId}/bulk/display`,
        method: 'POST',
        body: {
          campaignId: request.campaignId,
          displayAds: request.displayAds
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'InternetPending' }]
    }),
    convertToDisplayWithAds: build.mutation<Campaign, { campaignId: string; displayAds: UploadDisplayAdRequest[]; collage: UploadDisplayAdRequest }>({
      query: request => {
        return {
          url: `campaigns/${request.campaignId}/convertToDisplayWithAds`,
          method: 'POST',
          body: {
            campaignId: request.campaignId,
            displayAds: request.displayAds,
            collage: request.collage
          }
        }
      },
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'InternetPending' }]
    }),
    uploadDisplayAd: build.mutation<Ad, UploadDisplayAdRequest>({
      query: request => ({
        url: `ads/creatives/${request.accountId}/display`,
        method: 'POST',
        body: {
          destinationUrl: request.destinationUrl,
          companyName: request.companyName,
          uploadedUrl: request.uploadedUrl,
          htmlTag: request.htmlTag,
          width: request.width,
          height: request.height,
          name: request.name,
          campaignIds: request.campaignIds
        }
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: () => [{ type: 'InternetPending' }]
    })
  }),
  overrideExisting: false
})

export const {
  useUploadDisplayAdMutation,
  useUploadBulkDisplayAdsMutation,
  useGetBillboardPendingAuditQuery,
  useGetTvPendingAuditQuery,
  useGetInternetPendingAuditQuery,
  useApproveBillboardAdMutation,
  useApproveInternetAdMutation,
  useApproveTvAdMutation,
  useDisapproveBillboardAdMutation,
  useDisapproveInternetAdMutation,
  useDisapproveTvAdMutation,
  useGetAdsQuery,
  useGetAdQuery,
  useRefreshAdStatusMutation,
  useGetBillboardPendingAuditsQuery,
  useGetTvPendingAuditsQuery,
  useGetInternetPendingAuditsQuery,
  useConvertToDisplayWithAdsMutation
} = adsApi
